import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ProjectThumb from "../thumbs/ProjectThumb";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/material";
import { fetchGalleryProjects, getProjectCategories, fetchTags, fetchSearch, setQuery, clearSearchResults, fetchSearchMore } from "../../actions";
import { api_endpoint, site_name } from "../../config";
import FilterComponent from "./FilterComponent";
import TopNav from "../TopNav";
import ResultComponent from "./ResultComponent";
import { Typography } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { Ribbons } from "../marketing_pages/Ribbon";
import setTitle from "../../utils/setTitle";

const Page = styled.div`
  // position: fixed;
  // top: 52px;
  // left: 0;
  width: 100%;
  min-height: calc(100% + 1px);
  // max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  box-sizing: border-box;
  padding-top: ${(props) => props.ribbons ? (props.isMobile ? "120px" : "240px") : "48px"};
  padding-bottom: 48px;
  overflow-x: hidden;
  overflow-y: visible;
`
const DarkNav = styled.div`
  height: 49px;
  background-color: #008EFF;
  border-bottom: 3px solid #39e089;
`
const ResultContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  // margin-left: ${(props) => (props.isMobile ? "auto" : "26px")};
  // margin-right: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.isMobile ? "8px" : "8px")};
  flex-wrap: wrap;
  // width: ${(props) => (props.isMobile ? "302px" : "calc(100% - 54px)")};
  width: auto;
  // max-width: ${(props) => (props.isMobile ? "302px" : "calc(100% - 54px)")};
  box-sizing: border-box;
  justify-content: center;
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 32px 0;
  width: 100%;
`;

class DiscoverPage extends Component {
  constructor() {
    super();
    this.state = {
      NoContent: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    setTitle(`Discover | ${site_name}`)
    // document.querySelector('meta[name="description"]').setAttribute("content", 'Public projects from Made members');

    this.props.dispatch(clearSearchResults())
  }
  componentDidUpdate() {
    if (
      this.props.isMobile &&
      this.state.collapsed === false &&
      this.state.initial === true
    ) {
      this.setState({ collapsed: true });
      this.setState({ initial: false });
    }
  }

  loadMore(offset) {
    console.log("Offset! %d", offset)
    const { search, query, auth } = this.props;
    const newQuery = { ...query, offset: offset };
    this.props.dispatch(setQuery(newQuery));
    this.props.dispatch(fetchSearchMore(search.data.next, auth.isAuthenticated));
  }

  onPageScroll(e, loading, offset) {
    const { search } = this.props;
    if (loading)
      return;
    if (window.scrollY + e.target.clientHeight >= e.target.scrollHeight - 150) {
      if (search.data && search.data.count > search.data.results.length) {
        this.loadMore(offset);
      }
    }
  }

  render() {
    const { search, query, auth, isMobile, categories, tags } = this.props;
    const loading = search.loading && !search.error
    const empty = !search.loading && !search.error && search.data && search.data.count === 0;
    const results = search.data.results;
    return (
      <Page ribbons={this.props.ribbons} isMobile={isMobile} onScroll={e => this.onPageScroll(e, loading, results.length)}>

        <FilterComponent />
        <ResultContainer direction={query.type == "users" ? "column" : "row"}>
          {results.map((result) => {
            return (
              <ResultComponent type={query.type} data={result} key={result.id || result.name} />
            );
          })}
          {empty ? (
            <EmptyContainer>
              <HelpIcon style={{ fontSize: "72px", color: "rgba(0, 0, 0, 0.8)" }} />
              <Typography variant="h4" sx={{ color: "rgba(0, 0, 0, 0.8)" }}>No results</Typography>
              <Typography variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.8)" }}>Try refining your search.</Typography>
            </EmptyContainer>
          ) : ""}
        </ResultContainer>
        {loading ? (
          <EmptyContainer>
            <CircularProgress />
          </EmptyContainer>
        ) : null}
        {search.data.count > results.length && !loading && !search.error ? (
          <EmptyContainer>
            <Button variant="contained" disableElevation onClick={e => { this.loadMore(results.length) }}>More</Button>
          </EmptyContainer>
        ) : null}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  profile: state.profile,
  auth: state.auth,
  current_profile: state.profile.current_profile,
  current_profile_loading: state.profile.loading,
  search: state.fetched.search,
  tags: state.public.tags,
  categories: state.public.categories,
  isMobile: state.dimensions.window_dimensions.isMobile,
  reset: state.ui.resetProjectLink,
  query: state.fetched.query,
});
export default connect(mapStateToProps)(DiscoverPage);
