const obj2url = (obj) => {
    let queryString = ""
    if (obj) {
        queryString = "?"
        let and = false
        const entries = Object.entries(obj)
        console.log(obj)
        for (let i = 0; i < entries.length; i++) {
            const [k, v] = entries[i]
            if (v) {
                if (v === undefined || v === null || (Array.isArray(v) && v.length == 0) || Object.entries(v).length == 0 || v.length == 0) {
                    continue
                }
                if (and) {
                    queryString += "&"
                } else {
                    and = true;
                }
                if (Array.isArray(v)) {
                    and = false
                    for (let i = 0; i < v.length; i++) {
                        if (and) {
                            queryString += "&"
                        } else {
                            and = true;
                        }
                        queryString += `${encodeURIComponent(k)}=${encodeURIComponent(v[i])}`
                    }
                } else {
                    queryString += `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
                }
            }
        }
    }
    return queryString
}

export default obj2url