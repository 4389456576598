import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getFeed,
  loadMoreFeedItems,
} from "../actions/homeActions";
import CircularProgress from "@mui/material/CircularProgress";
import Update from "./elements/Update";
import Compare from "./elements/Compare";
import Text from "./elements/Text";
import Next from "./elements/Next";
import Question from "./elements/Question";
import Dimension from "./elements/Dimension";
import Button from "@mui/material/Button";
import PersonThumb from "./thumbs/PersonThumb";
import { Link } from 'react-router-dom';
import FirstUseBanner from "./FirstUseBanner";
import { api_endpoint, site_name } from "../config";
import { Box } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from "@mui/material";
import { setMenu } from "../actions/uiActions";
import { getMySubscriptions } from "../actions/authActions"
import { waitForAuth } from "../utils/waitForAuth";
import setTitle from "../utils/setTitle";
const Page = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #f2f2f2;
  box-sizing: border-box;
  padding-bottom: 48px;
  max-height: calc(100vh - 48px);
  box-sizing: border-box;
`
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
`;
const ProjectToolbar = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
 
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;
  justify-content: center;
  width: 100%;
`;
const ProjectNavActive = styled.div`
  color: #111;
`;
const ProjectNavInActive = styled.div`
  color: #b6b6b6;
`;
const SubscriptionSettings = styled.div`
margin-left: auto;
margin-right: ${(props) => props.isMobile ? "22px" : "35px"};
  color: #008EFF;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  cursor: pointer;
 
`;
const ProjectNavDash = styled.div`
  color: #111;
  width: 30px;
`;
const ProjectSpacer = styled.div`
  padding: ${(props) => props.isMobile ? "0px" : "24px"};
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 60px;
  cursor: pointer;
  text-decoration: none;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  box-sizing: border-box;

  &:hover {
    background-color: #e1e8f1;
  }

  
`;
const CenterButton = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
`;
const MoreButton = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
`;
const ElementCardLarge = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "100vw" : "400px")};
  height: ${(props) => (props.isMobile ? "100vw" : "400px")};
  
  background-color: #fff;
  border: ${(props) => props.isMobile ? "none" : "1px solid #fff"};
  box-shadow: ${(props) => props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"}; 
  // border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
    border: "2px solid #fff",
  boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3);",
`;
const ElementCard = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "70")}px;
  height: ${(props) => (props.isMobile ? "40" : "70")}px;
  background-color: #fff;
  border: ${(props) =>
    props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const ElementRow = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => (props.isMobile ? "8" : "15")}px;
  margin-bottom:${(props) => (props.isMobile ? "10" : "0")}px;
`;
const ProjectName = styled.div`
  display: flex;
  font-family: "Merriweather", serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 4px;
  margin-top: 8px;
  color: #111;
`;
const GroupName = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 121.5%;

  color: #2196f3;
`;
const PersonName = styled.div`
  // min-width: 300px;
  display: flex;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 121.5%;
  margin-bottom: 8px;
  color: #111;
  margin-top: 10px;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  padding: 20px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: url("/lamp.png");
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  z-index: 1000;
`;
const ProjectPreview = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // background: "#fff",


  // maxWidth: "calc(100vw - 48px)",
})
const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  margin-left: ${(props) => (props.isMobile ? "10px" : "0px")};
`;
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 64px;
  width: 100%;
  height: 100%;
  color: "rgba(0, 0, 0, 0.8)";
`;
const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 12px;
`

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectname: "",
    };
  }
  componentDidMount() {
    setTitle(`Home | ${site_name}`)
    document.body.style.backgroundColor = "#f2f2f2";
    waitForAuth().then(() => {
      this.props.dispatch(getFeed());
    }).catch(err => {
      console.error(err)
      window.location.pathname = "/login/"
    })
  }
  handleChangename = (event, newValue) => {
    //console.log(newValue);
    this.setState({ projectname: newValue });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  // newProject() {
  //   //console.log(this.state.projectname);
  //   this.props.dispatch(createProject(this.state.projectname));
  // }
  onPageScroll(e, loading, next) {
    if (loading)
      return;
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 150) {
      if (next) {
        this.loadMoreProjects(next);
      }
    }
  }
  subSettings() {
    this.props.dispatch(getMySubscriptions("projects"));

    this.props.dispatch(setMenu(true, 18));
  }
  loadMoreProjects = (link) => {
    this.props.dispatch(loadMoreFeedItems(link));
  };
  retry() {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
  render() {
    const { feed, loading, error, isMobile } = this.props;
    let NoContent = false;
    if (feed.allIDS.length === 0 && !loading) {
      NoContent = true;
    }
    if (error) {
      return (
        <ErrorContainer>
          <WarningIcon style={{ fontSize: "72px" }} />
          <ErrorMessage>
            <Typography variant="h4">Something went wrong</Typography>
            <Typography variant="subtitle1">We couldn't load the home. Please try again.</Typography>
          </ErrorMessage>
          <Button variant="contained" disableElevation onClick={e => { window.location.reload() }}>Retry</Button>
        </ErrorContainer>
      )
    }
    return (
      <Page onScroll={e => this.onPageScroll(e, loading, feed.next)}>
        <FirstUseBanner />
        <ProjectToolbar>

          <SubscriptionSettings onClick={e => { this.subSettings() }} isMobile={isMobile}>Subscription Settings</SubscriptionSettings>

        </ProjectToolbar>
        <Projectcontainer>
          {NoContent ? (
            <EmptyProject>
              <EmptyContainer>
                <EmptyTitle>Hello!</EmptyTitle>
                <EmptyInstruction>
                  This is your home page.<br />
                  Once you subscribe to <Link to="/discover?type=projects">Projects</Link>, <Link to="/discover?type=users">People</Link>, or <Link to="/discover?type=groups">Groups</Link><br />
                  you will see the updates to their projects here.
                  <br />
                  <br />
                  <Button disableElevation variant="contained" to="/discover" component={Link} sx={{ fontStyle: "normal" }}>Go To Discover</Button>
                </EmptyInstruction>
              </EmptyContainer>

            </EmptyProject>
          ) : (
            ""
          )}
          {feed.allIDS.map((projectid, index) => {
            const project = feed.byID[projectid];
            let linkurl;
            if (project.groups.length) {
              linkurl =
                "/project/" +
                project.id +
                "/" +
                project.name.replace(/\s+/g, "-").toLowerCase() +
                "/group/" +
                project.groups[0].id + "/";
            } else {
              linkurl =
                "/project/" +
                project.id +
                "/" +
                project.name.replace(/\s+/g, "-").toLowerCase() + "/";
            }
            let hero_image_url = false;
            let hero_image_urlB = false;
            let isPlayback = false
            if (project.hero.images) {
              if (project.hero.images.length === 0) {
                hero_image_url = false;
              } else {
                if (["video", "audio"].includes(project.hero.images[0].mime_type.split("/")[0])) {
                  isPlayback = true;
                  hero_image_url = api_endpoint + "/media/" + project.hero.images[0].id + "/file?size=512";
                } else {
                  hero_image_url = api_endpoint + "/media/" + project.hero.images[0].id + "/file?size=512";
                }

              }
            }
            if (project.hero.images.length === 2) {
              hero_image_urlB = api_endpoint + "/media/" + project.hero.images[1].id + "/file?size=512"
            }
            let lazyload = false
            if (index > 3) {
              lazyload = true
            }
            return (
              <Link to={linkurl}
                key={index}
                style={{ textDecoration: "none" }}
              >
                <ProjectSpacer

                  isMobile={isMobile}
                >
                  <ProjectPreview>
                    <ElementCardLarge isMobile={isMobile} cardwidth={400}>
                      {
                        {
                          "UP": (
                            <Update
                              image_url={hero_image_url}
                              isPlayback={isPlayback}
                              tag={project.hero.metadata ? (project.hero.metadata.label ? project.hero.metadata.label : null) : null}
                              VideoControls={true}
                              description={project.hero.description}
                              drawing={project.hero.metadata ? (project.hero.metadata.drawing ? project.hero.metadata.drawing : []) : []}
                              element={project.hero}
                              lazyload={lazyload}
                              show_full={project.hero.show_full_image}
                            />
                          ),
                          "CO": (
                            <Compare
                              selected={this.props.selected}
                              image_url={hero_image_url}
                              image_urlB={hero_image_urlB}

                              tag={project.hero.metadata ? (project.hero.metadata.label ? project.hero.metadata.label : null) : null}
                              description={project.hero.description}
                              link_url={project.hero.metadata ? (project.hero.metadata.link_url ? project.hero.metadata.link_url : "") : ""}
                              compare_type={project.hero.metadata ? (project.hero.metadata.compare_type ? project.hero.metadata.compare_type : "SS") : "SS"}
                              link_name={project.hero.metadata ? (project.hero.metadata.link_name ? project.hero.metadata.link_name : "") : ""}
                              drawing={project.hero.metadata ? (project.hero.metadata.drawing ? project.hero.metadata.drawing : []) : []}
                              image_order={project.hero.metadata ? (project.hero.metadata.image_order ? project.hero.metadata.image_order : []) : []}
                              element={project.hero}
                              lazyload={lazyload}
                            />
                          ),
                          "TE": (
                            <Text
                              selected={this.props.selected}
                              tag={project.hero.metadata ? (project.hero.metadata.label ? project.hero.metadata.label : null) : null}
                              description={project.hero.description}
                              link_url={project.hero.metadata ? (project.hero.metadata.link_url ? project.hero.metadata.link_url : "") : ""}
                              link_name={project.hero.metadata ? (project.hero.metadata.link_name ? project.hero.metadata.link_name : "") : ""}
                              textcolor={project.hero.metadata ? (project.hero.metadata.text_color ? project.hero.metadata.text_color : "") : ""}
                            />
                          ),
                          "DM": (
                            <Dimension
                              description={
                                project.hero.description
                              }
                              unit={
                                project.hero.metadata
                                  ? (project.hero.metadata.dimension_unit ? project.hero.metadata.dimension_unit : 'com')
                                  : "cm"
                              }
                              x={
                                project.hero.metadata
                                  ? (project.hero.metadata.dimension_x ? project.hero.metadata.dimension_x : 60)
                                  : 60
                              }
                              y={
                                project.hero.metadata
                                  ? (project.hero.metadata.dimension_y ? project.hero.metadata.dimension_y : 60)
                                  : 60
                              }
                              z={
                                project.hero.metadata
                                  ? (project.hero.metadata.dimension_z ? project.hero.metadata.dimension_z : 60)
                                  : 60
                              }
                            />
                          )
                        }[project.hero.element_type]
                      }
                    </ElementCardLarge>
                    <ProjectInfo isMobile={isMobile}>
                      <PersonThumb {...project.creator} size="inline" projectname={project.name} />

                    </ProjectInfo>
                  </ProjectPreview>

                  <ElementRow isMobile={isMobile}>
                    {project.elements.map((element, index) => {
                      let image_url, image_urlB;
                      let isPlayback = false

                      if (element.images.length === 0) {
                        image_url = false;
                      } else {
                        if (["video", "audio"].includes(element.images[0].mime_type.split("/")[0])) {
                          isPlayback = true;
                          image_url = api_endpoint + "/media/" + element.images[0].id + "/file?size=256";
                        } else {
                          image_url = api_endpoint + "/media/" + element.images[0].id + "/file?size=256"

                        }

                      }
                      if (element.images.length === 2) {
                        image_urlB = api_endpoint + "/media/" + element.images[1].id + "/file?size=256"
                      }
                      return (
                        <ElementCard
                          key={index}
                          isMobile={isMobile}
                          cardwidth={70}
                        >
                          {
                            {
                              "UP": (
                                <Update
                                  image_url={image_url}
                                  isPlayback={isPlayback}
                                  VideoControls={false}
                                  tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                                  description={element.description}
                                  drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                                  element={element}
                                  size={70}
                                  lazyload={lazyload}
                                  show_full={element.show_full_image}
                                />
                              ),
                              "CO": (
                                <Compare
                                  selected={this.props.selected}
                                  image_url={image_url}
                                  image_urlB={image_urlB}

                                  tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                                  description={element.description}
                                  link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                                  compare_type={element.metadata ? (element.metadata.compare_type ? element.metadata.compare_type : "SS") : "SS"}
                                  link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                                  drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                                  image_order={element.metadata ? (element.metadata.image_order ? element.metadata.image_order : []) : []}
                                  element={element}
                                  lazyload={lazyload}
                                />
                              ),
                              "TE": (
                                <Text
                                  selected={this.props.selected}
                                  tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                                  description={element.description}
                                  link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                                  link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                                  textcolor={element.metadata ? (element.metadata.text_color ? element.metadata.text_color : "") : ""}
                                />
                              ),
                              "DM": (
                                <Dimension
                                  selected={this.props.selected}
                                  description={element.description}
                                  unit={
                                    element.metadata ? (element.metadata.dimension_unit ? element.metadata.dimension_unit : "") : ""
                                  }
                                  x={element.metadata ? (element.metadata.dimension_x ? element.metadata.dimension_x : 60) : 60}
                                  y={element.metadata ? (element.metadata.dimension_y ? element.metadata.dimension_y : 60) : 60}
                                  z={element.metadata ? (element.metadata.dimension_z ? element.metadata.dimension_z : 60) : 60}
                                />
                              )
                            }[element.element_type]
                          }
                        </ElementCard>
                      );
                    })}
                  </ElementRow>
                </ProjectSpacer>
              </Link>
            );
          })}
          {loading ?
            <CircularProgress />
            : null}
          {feed.data && feed.data.length > 0 && feed.next && !loading ? (
            <CenterButton>
              <MoreButton>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.loadMoreProjects(feed.next);
                  }}
                >
                  Load More Projects...
                </Button>
              </MoreButton>
            </CenterButton>
          ) : (
            ""
          )}
        </Projectcontainer>

      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.public.feed_loading,
  feed: state.public.feed,
  error: state.public.feed_error,
  isMobile: state.dimensions.window_dimensions.isMobile,
});
export default connect(mapStateToProps)(Home);
