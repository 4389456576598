import React, { Component } from "react";
import { connect } from "react-redux";
import styled from '@emotion/styled'
import { api_endpoint } from "../../config";
import { Link } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import imgUrl from "../../utils/imgUrl";
import { resetProjectLink } from "../../actions/uiActions";
const StyledContainer = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 280px;
  min-height: 126px;
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  // border-radius: 63px;
  padding: 1px;
  background-color: #fff;
  display: flex;

  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const StyledContainerSmall = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 100%;
  min-height: 54px;
  // box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  // border-radius: 27px;
  padding: 1px;
  // background-color: #fff;
  display: flex;

  // &:hover {
  //   border: 1px solid #777;
  // }
  box-sizing: border-box;
`;
const StyledContainerExtraSmall = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 100%;
  min-height: 38px;
  // box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  // border-radius: 19px;
  padding: 0px;
  // background-color: #fff;
  display: flex;

  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const StyledContainerSmall2 = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 100%;
  min-height: 46px;
  // box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  // border-radius: 23px;
  padding: 0px;
  // background-color: #fff;
  display: flex;

  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const StyledContainerSmallC = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 44px;
  min-height: 44px;
  // box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  // border-radius: 22px;
  padding: 0px;
  // background-color: #fff;
  display: flex;

  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const LeftColumn = styled.div`
  // width: 10%;
  diplay: flex;
  flex-direction: column;
`;
const RightColumn = styled.div`
  width: 100%;

  text-align: left;
`;
const InlineRight = styled.div`
  width: 100%;
  max-width: calc(100vw - 55px);
display: flex;
  text-align: left;
  flex-direction: column;
`;
const RightColumnS = styled.div`
  width: calc(100% - 60px);
display: flex;
  text-align: left;
`;
const RightColumnL = styled.div`
  width: calc(100% - 108px);

  text-align: left;
`;
const UserName = styled.div`
  font-size: 20px;
  margin-top: 17px;
  line-height: 29px;
  font-family: Georgia, serif;
  font-size: 24px;
  color: #111;
  text-decoration: none;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 25px);
`;
const UserNameSmall = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  line-height: 29px;
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 500;
  color: #111;
  text-decoration: none;
  display: block;
  width: 100%;
   text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
const UserNameExtraSmall = styled.div`
  margin-top: 5px;
  margin-right: 20px;
  line-height: 29px;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-weight: 400;
  color: #111;
  text-decoration: none;
  display: flex;
  width: 100%;
`;
const UserNameSmall2 = styled.div`
  margin-top: 9px;
  margin-right: 20px;
  line-height: 29px;
  font-family: "Merriweather", serif;
  font-size: 14px;
  font-weight: 400;
  color: #111;
  text-decoration: none;
  display: flex;
  width: 100%;
`;
const UserNameSmallnew = styled.div`
  margin-top: 10px;
  margin-right: 20px;
  line-height: 29px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #383838;
`;
const UserNameSmallnewsecond = styled.div`
  margin-top: 3px;
  margin-right: 20px;
  line-height: 29px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  
  color: #C4C4C4;
`;
const UserBadgeSmall = styled.div`
      margin-left: 0px;
    margin-right: 10px;
    font-size: 25px;
    margin-top: 14px;
`;

const Location = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #383838;
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 39px);
`;
const AuthorImage = styled.div`
  width: 108px;
  height: 108px;
  border-radius: 54px;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 9px;
  margin-left: 9px;
  margin-right: 15px;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 4px;
  margin-left: 4px;
  margin-right: 15px;
`;
const AuthorImageSmall2 = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 10px;
`;
const AuthorImageSmall46 = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 3px;
  // margin-left: 3px;
  margin-right: 10px;
`;
const AuthorImageSmall3 = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 1px;
  margin-left: 1px;
  margin-right: 10px;
`;
const AuthorImageExtraSmall = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
  margin-top: 3px;
  margin-left: 3px;
  margin-right: 10px;
`;
const PersonInline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`
const PersonInlineImg = styled.div`
  min-width: 45px;
  min-height: 45px;
  margin-right: 6px;
  border-radius: 50%;
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}") #008EFF;
  background-position: center;
  background-size: cover;
`
const PersonInlineName = styled.span`
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 130%;


   text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

color: #C4C4C4;
  margin-top: 1px;
`
const ProjectInlineName = styled.span`
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 130%;

color: #383838;

  margin-top: 1px;
  
   text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

class PersonThumb extends Component {

  resetLink() {
    this.props.dispatch(resetProjectLink(true));
  }
  render() {
    let showBadge = false;
    if (this.props.role === "AD") {
      showBadge = true;
    }
    let linkurl = "/" + this.props.username + "/";
    if (this.props.groupid) {
      linkurl = linkurl + "group/" + this.props.groupid + "/";
    }
    let image_url
    if (this.props.src) {
      image_url = this.props.src
    } else if (this.props.avatar) {
      if ((typeof this.props.avatar) == "object") {
        image_url = imgUrl(this.props.avatar.id, 128)
      } else if ((typeof this.props.avatar) == "string") {
        image_url = imgUrl(this.props.avatar, 128);
      }
    } else {
      image_url = "/default_icon.png"
    }

    switch (this.props.size) {
      case "inline":
        return (
          <PersonInline  >
            <PersonInlineImg src={image_url} />
            <InlineRight>
              <ProjectInlineName>{this.props.projectname}</ProjectInlineName>
              <PersonInlineName>{this.props.fullname || this.props.username}</PersonInlineName>

            </InlineRight>
          </PersonInline>
        )
      case "xs":
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerExtraSmall noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageExtraSmall
                  src={image_url}
                ></AuthorImageExtraSmall>
              </LeftColumn>
              <RightColumn>
                <UserNameExtraSmall>
                  {this.props.username}{" "}
                  {showBadge ? (
                    <UserBadgeSmall>
                      <VerifiedUserIcon
                        style={{ fontSize: "inherit" }}
                        color="primary"
                      />
                    </UserBadgeSmall>
                  ) : (
                    ""
                  )}{" "}
                </UserNameExtraSmall>
              </RightColumn>
            </StyledContainerExtraSmall>
          </Link>
        );
      case "s_new":
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerSmall2 noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageSmall46
                  src={image_url}
                ></AuthorImageSmall46>
              </LeftColumn>
              <RightColumn>
                <UserNameSmallnew>
                  {this.props.username}

                </UserNameSmallnew>
                <UserNameSmallnewsecond>
                  {this.props.fullname}

                </UserNameSmallnewsecond>
              </RightColumn>
            </StyledContainerSmall2>
          </Link>
        );
      case "s2":
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerSmall2 noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageSmall2
                  src={image_url}
                ></AuthorImageSmall2>
              </LeftColumn>
              <RightColumn>
                <UserNameSmall2>
                  {this.props.username}{" "}
                  {showBadge ? (
                    <UserBadgeSmall>
                      <VerifiedUserIcon
                        style={{ fontSize: "inherit" }}
                        color="primary"
                      />
                    </UserBadgeSmall>
                  ) : (
                    ""
                  )}{" "}
                </UserNameSmall2>
              </RightColumn>
            </StyledContainerSmall2>
          </Link>
        );
      case "c":
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerSmallC noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageSmall3
                  src={image_url}
                ></AuthorImageSmall3>
              </LeftColumn>
              {/* <RightColumn>
                    <UserNameSmall2>
                      {this.props.username}{" "}
                      {showBadge ? (
                        <UserBadgeSmall>
                          <VerifiedUserIcon
                            style={{ fontSize: "inherit" }}
                            color="primary"
                          />
                        </UserBadgeSmall>
                      ) : (
                        ""
                      )}{" "}
                    </UserNameSmall2>
                  </RightColumn> */}
            </StyledContainerSmallC>
          </Link>
        );

      case "s":
        return (
          <Link onClick={() => {
            this.resetLink();
          }} style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerSmall noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageSmall src={image_url}></AuthorImageSmall>
              </LeftColumn>
              <RightColumnS>

                {showBadge ? (
                  <UserBadgeSmall>
                    <VerifiedUserIcon
                      style={{ fontSize: "inherit" }}
                      color="primary"
                    />
                  </UserBadgeSmall>
                ) : (
                  ""
                )}{" "}
                <UserNameSmall>
                  {this.props.fullname || this.props.username}

                </UserNameSmall>
              </RightColumnS>
            </StyledContainerSmall>
          </Link>
        );
      case "l":
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainer noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImage src={image_url}></AuthorImage>
              </LeftColumn>
              <RightColumnL>
                <UserName>
                  {this.props.username}{" "}
                  {showBadge ? (
                    <VerifiedUserIcon
                      style={{ marginLeft: "3px", fontSize: "18px" }}
                      color="primary"
                    />
                  ) : (
                    ""
                  )}{" "}
                </UserName>
                <Location>{this.props.fullname}</Location>
                <Location>{this.props.tag_line}</Location>
                <Location>{this.props.status}</Location>
              </RightColumnL>
            </StyledContainer>
          </Link>
        );
      default:
        return (
          <Link style={{ textDecoration: "none" }} to={linkurl}>
            <StyledContainerSmall noshadow={this.props.noshadow}>
              <LeftColumn>
                <AuthorImageSmall src={image_url}></AuthorImageSmall>
              </LeftColumn>
              <RightColumn>
                <UserName>
                  {this.props.username}{" "}
                  {showBadge ? (
                    <VerifiedUserIcon
                      style={{ marginLeft: "3px", fontSize: "18px" }}
                      color="primary"
                    />
                  ) : (
                    ""
                  )}{" "}
                </UserName>
                <Location>{this.props.fullname}</Location>
                <Location>{this.props.tag_line}</Location>
                <Location>{this.props.status}</Location>
              </RightColumn>
            </StyledContainerSmall>
          </Link>
        );
    }
  }
}


const mapStateToProps = (state) => ({

});
export default connect(mapStateToProps)(PersonThumb);

