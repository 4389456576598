import { CONSTANTS } from "../actions";

import axiosInstance from "../utils/axiosApi";
import { setMenu, updateProgress, timeoutError } from "../actions/uiActions";
import { api_endpoint } from "../config";
import analyticsInstance from "../utils/analytics";
export const createSubscription = (data) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PLAN_LOADING,

  });
  axiosInstance
    .post("subscription/create/", data)
    .then((result) => {
      dispatch(getCurrentPlan());
      // dispatch({
      //   type: CONSTANTS.REMOVE_SUB_LOADING,
      //   payload: result,
      // });

    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const getCurrentPlan = () => (dispatch) => {
  axiosInstance
    .get("subscription/")
    .then((result) => {
      console.log(result)
      dispatch({
        type: CONSTANTS.GET_PLAN,
        payload: result,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.GET_PLAN_ERROR,
        payload: err,
      });
    });
};
export const handleShowCheckout = (plan) => (dispatch) => {
  const data = { price: plan }
  analyticsInstance.track("begin_checkout", { plan })
  axiosInstance
    .post("subscription/checkout/", data)
    .then((result) => {
      console.log(result)
      window.location.href = result.data.redirect
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const handleShowPortal = () => (dispatch) => {


  axiosInstance
    .post("payment/portal/")
    .then((result) => {
      console.log(result)
      window.location.href = result.data.redirect
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const getProfileData =
  (username, groupid, publicprofile, isAuth) => (dispatch) => {

    if (!isAuth) {
      axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
    }
    dispatch({
      type: CONSTANTS.SET_PROFILE_LOADING,
      payload: "loading",
    });
    let get_url;
    if (publicprofile) {
      get_url = "users/by-username/" + username + "/?public=true";
    } else {
      if (groupid) {
        get_url = "users/by-username/" + username + "/?groupid=" + groupid;
      } else {
        get_url = "users/by-username/" + username + "/";
      }
    }
    axiosInstance
      .get(get_url)
      .then((result) => {
        if (result.data.count === 0) {
          // no such user
          window.location.href = "/";
        } else {
          dispatch({
            type: CONSTANTS.GET_CURRENT_PROFILE,
            payload: result.data.results[0],
          });

          dispatch(
            getProfilePages(result.data.results[0].id, groupid, publicprofile)
          );
        }

      })
      .catch((err) => {
        dispatch({
          type: CONSTANTS.ON_ERROR,
          payload: err,
        });
      });
  };
export const getProfilePages =
  (id, groupid, publicprofile, category, state) => (dispatch) => {
    let get_url;

    if (publicprofile) {
      get_url = "users/" + id + "/projects/?public=true&";
    } else {
      if (groupid) {
        get_url = "users/" + id + "/projects/?groupid=" + groupid + "&";
      } else {
        get_url = "users/" + id + "/projects/?";
      }
    }
    if (category) {
      get_url = get_url + "category=" + category + "&";
    }
    if (state) {
      get_url = get_url + "state=" + state + "&";
    }
    dispatch({
      type: CONSTANTS.SET_PROFILE_PAGES_LOADING,
      payload: "loading",
    });
    axiosInstance.get(get_url).then((res) => {
      dispatch({
        type: CONSTANTS.GET_CURRENT_PROFILE_PAGES,
        payload: { data: res.data, state: state },
      });
    });
  };
export const loadMoreProfilePages =
  (baseurl, groupid, publicprofile, category, state) => (dispatch) => {
    let get_url;

    if (publicprofile) {
      get_url = baseurl + "&public=true&";
    } else {
      if (groupid) {
        get_url = baseurl + "&groupid=" + groupid + "&";
      } else {
        get_url = baseurl + "&";
      }
    }
    if (category) {
      get_url = get_url + "category=" + category + "&";
    }
    if (state) {
      get_url = get_url + "state=" + state + "&";
    }
    dispatch({
      type: CONSTANTS.SET_PROFILE_PAGES_LOADING,
      payload: "loading",
    });
    axiosInstance.get(get_url).then((res) => {
      dispatch({
        type: CONSTANTS.LOAD_MORE_PROFILE_PAGES,
        payload: res.data,
      });
    });
  };
export const newFollow = (userid, groupid) => (dispatch) => {
  const data = {
    // invite_email: email.data.split(",")[0],
    organization: null,
    group: groupid,
  }
  axiosInstance
    .post("users/" + userid + "/subscribe/")
    .then((result) => {
      console.log("success")
      dispatch({
        type: CONSTANTS.SET_FOLLOW,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const newUnFollow = (userid, groupid) => (dispatch) => {
  const data = {
    // invite_email: email.data.split(",")[0],
    organization: null,
    group: groupid,
  }
  axiosInstance
    .delete("users/" + userid + "/subscribe/")
    .then((result) => {
      console.log("success")
      dispatch({
        type: CONSTANTS.SET_UNFOLLOW,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const getNotifications = () => (dispatch) => {
  axiosInstance
    .get("notifications/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_NOTIFICATIONS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const loadMoreNotifications = (link) => (dispatch) => {
  axiosInstance
    .get(link)
    .then((result) => {
      dispatch({
        type: CONSTANTS.LOAD_MORE_NOTIFICATIONS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const setNotificationsRead = (data) => (dispatch) => {
  axiosInstance
    .post("notifications/ack/", data)
    .then((result) => {
      dispatch(getNotifications());
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};

export const clearInvite = (data) => (dispatch) => {
  axiosInstance
    .post("invitations/reject/", data)
    .then((result) => {
      dispatch(getNotifications());
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};

export const acceptInvite = (data) => (dispatch) => {
  axiosInstance
    .post("invitations/accept/", data)
    .then((result) => {
      dispatch(getNotifications());
      window.location.href = "/groups/";
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const acceptFollowRequest = (part_id) => (dispatch) => {
  axiosInstance
    .patch("/participants/" + part_id + "/", { status: "AC" })
    .then((result) => {
      //console.log(result);
      dispatch(getNotifications());
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const rejectFollowRequest = (part_id) => (dispatch) => {
  axiosInstance
    .delete("/participants/" + part_id + "/")
    .then((result) => {
      //console.log(result);
      dispatch(getNotifications());
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const clearNotifications = (data) => (dispatch) => {
  axiosInstance
    .delete("notifications/", { data: data })
    .then((result) => {
      dispatch(getNotifications());
      analyticsInstance.track("clear_notifications")
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};

export const getMyProfileDataID = () => (dispatch) => {
  // dispatch({
  //   type: CONSTANTS.SET_PROFILE_LOADING,
  //   payload: "loading",
  // });
  axiosInstance
    .get("me/")
    .then((result) => {
      dispatch(getMyProjectsFollowing(result.data.user.id));
      dispatch({
        type: CONSTANTS.GET_MY_CURRENT_PROFILE,
        payload: result.data,
      });
      analyticsInstance.identify(result.data.user.id, {
        username: result.data.user.username,
        fullname: result.data.user.fullname,
        mobile: window.matchMedia("only screen and (max-width: 720px)").matches,
        language: navigator.language,
        ticket: result.data.user.ticket ? result.data.user.ticket.id : null,
        groups: result.data.user.groups.map(g => g.id),
      })
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};

export const getMyProjectsFollowing = (id) => (dispatch) => {
  // dispatch({
  //   type: CONSTANTS.SET_PROJECTS_FOLLOWING_LOADING,
  //   payload: "loading",
  // });
  // axiosInstance
  //   .get("users/" + id + "/following/")
  //   .then((result) => {
  //     dispatch({
  //       type: CONSTANTS.GET_MY_PROJECTS_FOLLOWING,
  //       payload: result.data,
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch({
  //       type: CONSTANTS.GET_ERRORS,
  //       payload: err,
  //     });
  //   });
};

export const getRecentProjects = (id) => (dispatch) => {
  axiosInstance.get("users/" + id + "/recentprojects/").then((res) => {
    //console.log(res.data);
    dispatch({
      type: CONSTANTS.GET_RECENT_PAGES,
      payload: res.data,
    });
  });
};
export const getElementStylesForMenu = (id) => (dispatch) => {
  axiosInstance.get("element-types/", { login: false }).then((res) => {
    dispatch({
      type: CONSTANTS.SET_ELEMENT_TYPES_MENU,
      payload: res.data,
    });
  });
};
export const getProjectCategories = (id) => (dispatch) => {
  axiosInstance.get("metadata/categories/", { login: false }).then((res) => {
    dispatch({
      type: CONSTANTS.GET_CATEGORIES,
      payload: res.data,
    });
  });
};
export const getMemberships = (id) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROFILE_GROUPS_LOADING,
    payload: "loading",
  });
  axiosInstance.get("users/" + id + "/groups/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_CURRENT_PROFILE_GROUPS,
      payload: res.data,
    });
  });
};
async function upload(file, dispatch) {
  let blob = file.slice(0, file.size, file.type);
  let split = file['name'].split('.');
  let filename = split[0];
  var extension = split.pop()
  console.log(split)

  if (filename.length > 90) {
    filename = filename.substring(0, 90);
  }

  let tempname = filename + '.' + extension;
  let newFile = new File([blob], tempname, { type: file.type })
  const formData = new FormData();
  formData.append("upload", newFile);
  formData.append("alt_text", "temp");
  dispatch(updateProgress(0, 1));
  return axiosInstance({
    method: "post",
    url: "media/",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: progressEvent => {
      dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
    }
  });
}
export const uploadSaveProfile = (id, data, imageRaw, bannerRaw) => async (dispatch) => {
  console.log(bannerRaw)
  dispatch(timeoutError(false));
  dispatch(setMenu(true, 5));

  if (imageRaw) {
    let avatar = await upload(imageRaw, dispatch);
    data.avatar = avatar.data.id;
  }
  if (bannerRaw) {
    let banner = await upload(bannerRaw, dispatch);
    data.banner = banner.data.id;
  }

  dispatch(saveProfile(id, data));
};

export const saveProfile = (id, data) => (dispatch) => {
  axiosInstance
    .put("/users/update/", data)
    .then((res) => {
      //console.log("saved it");
      //console.log(res.data);
      dispatch(getMyProfileDataID());
      dispatch(setMenu(false));
    })
    .catch((err) => {
      if (err.response) {
        //console.log(err.response);
        dispatch({
          type: CONSTANTS.ON_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(setMenu(false));
      }
    });
};
export const getProfileSubscribers = (id) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROFILE_SUBSCRIBERS_LOADING,
  });
  axiosInstance
    .get("users/" + id + "/subscribers/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_PROFILE_SUBSCRIBERS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const loadMoreProfileSubscribers = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {

    dispatch({
      type: CONSTANTS.LOAD_MORE_PROFILE_SUBSCRIBERS,
      payload: res.data,
    });
  });
};
export const getProfileSubscriptions = (id, type) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROFILE_SUBSCRIPTIONS_LOADING,
    payload: type
  });
  axiosInstance
    .get("users/" + id + "/subscriptions/" + type + "/")
    .then((result) => {
      result.data.sub_type = type
      dispatch({
        type: CONSTANTS.GET_PROFILE_SUBSCRIPTIONS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const loadMoreProfileSubscriptions = (link, type) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    res.data.sub_type = type
    dispatch({
      type: CONSTANTS.LOAD_MORE_PROFILE_SUBSCRIPTIONS,
      payload: res.data,
    });
  });
};
export const createSubscriptionGenericProfile = (type, id) => (dispatch) => {
  const data = { id: id, type: type }
  axiosInstance
    .post(`${api_endpoint}/${type}/${id}/subscribe/`)
    .then((res) =>
      dispatch({
        type: CONSTANTS.TOGGLE_SUB_PROFILE,
        payload: data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
}
export const removeSubscriptionGenericProfile = (type, id) => (dispatch) => {
  const data = { id: id, type: type }

  axiosInstance
    .delete(`${api_endpoint}/${type}/${id}/subscribe/`)
    .then((res) =>

      dispatch({
        type: CONSTANTS.TOGGLE_SUB_PROFILE,
        payload: data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
}