import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { getGroupPreview, joinGroup } from "../../actions/groupActions";
import styled from "styled-components";
import GroupThumb from "../thumbs/GroupThumb";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import imgUrl from "../../utils/imgUrl";
import { Ribbons } from "../marketing_pages/Ribbon";
import { setMenu } from "../../actions/uiActions";
import { captcha_site_key, site_name } from "../../config";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import setTitle from "../../utils/setTitle";
const StyledContainer = styled.div`
margin-bottom: 30px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 300px
  min-height: 300px;
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border: 1px solid #fff;
  border-radius: 0px;
  // padding: 1px;
  display: flex;
  flex-direction: column;
`;

const InfoTitleContainer = styled.div`
  display: flex;
  background: url("${(props) => props.src}"), url("/default_group.jpg") #ccc;
  background-position: center;
  // border-top-left-radius: 59px;
  // border-top-right-radius: 59px;
  background-size: cover;
  position: relative;
  min-height: 300px;
`;
const InfoTitleName = styled.div`
  // display: flex;

  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 700;

  text-align: left;
  width: calc(100% - 20px);
//   overflow-wrap: anywhere;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #394b54;
//   display: -webkit-box;
//   -webkit-line-clamp: 1;
//   -webkit-box-orient: vertical;
//   overflow: hidden;
//   text-overflow: ellipsis;
`;
const StyledloginContainer = styled.div`
  display: block;
  margin: calc(100vh / 6) auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const StyledloginDialog = styled.div`
  display: inline-block;
  margin: 0 auto 8.33333vh auto;
  // background-color: #F3F3F3;
  width: 300px;
  min-height: 120px;
  border-radius: 2px;
  // box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%), 0 0 2px 0 rgb(0 0 0 / 20%);
`;
const StyledHeaderText = styled.div`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #111;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;    
    width: 252px;
    background-color: #0073ec;
    color: white;
    height: 32px;
    border-radius: 2px;
  
    padding-right: 30px;
    padding-left: 30px;
    margin: 10px 24px 10px 24px;
  
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
    
}
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #f00073;
  margin-top: 5px;
`;
const TermsSection = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  max-width: 236px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;
const LoginSection = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #111;
  max-width: 236px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;
const LoginButton = styled.span`
 cursor: pointer;
  color: #0073ec;

`;
const LoginImage = styled.div`
  display: inline-block;
  margin: 60px auto 40px auto;

  width: 300px;
  min-height: 120px;
`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
`;
class RegisterGroup extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      password2: "",
      group_join_link: "",
      captcha: "",
      errors: {},
    };
    this._captcha = "";
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    this.props.setMenu(false);
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    const urlparts = window.location.pathname.split("/");

    this.setState({ group_join_link: urlparts[2] });
    //get group name here.
    this.props.getGroupPreview(urlparts[2]);
  }
  componentDidUpdate() {
    if (this.props.group_preview) { setTitle(`Invite to ${this.props.group_preview.name} | ${site_name}`) }
    // else { setTitle(`Invite to a group | ${site_name}`) }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.errors) {
  //     this.setState({
  //       errors: nextProps.errors,
  //     });
  //   }
  // }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  joinGroup = (e) => {

    this.props.joinGroup(this.state.group_join_link, this.props.group_preview.id);
  };
  showLogin = (e) => {
    this.props.setMenu(true, 13)
  };
  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      group_join_link: this.state.group_join_link,
      captcha: this._captcha,
    };
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    const { errors } = this.state;
    const { group_preview } = this.props;
    const group_image = imgUrl(group_preview.image, 512);
    if (this.props.auth.isAuthenticated) {
      return (
        <StyledloginContainer>
          <Ribbons />
          <LoginImage>
            {" "}
            <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img>
          </LoginImage>
          <StyledloginDialog>
            <StyledHeaderText>
              You are invited to join a group
            </StyledHeaderText>
            <StyledContainer>
              <InfoTitleContainer src={group_image}></InfoTitleContainer>
              <InfoTitleName title={group_preview.name}>
                {group_preview.name}
              </InfoTitleName>
            </StyledContainer>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              type="submit"
              onClick={(e) => this.joinGroup()}
              style={{ minWidth: "252px", marginTop: "10px" }}>
              Join Group
            </Button>
          </StyledloginDialog>
        </StyledloginContainer>
      );
    } else {
      return (
        <StyledloginContainer>
          <Ribbons />
          <LoginImage>
            {" "}
            <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img>
          </LoginImage>
          <StyledloginDialog>
            <StyledHeaderText>
              You are invited to join a group
            </StyledHeaderText>
            <StyledContainer>
              <InfoTitleContainer src={group_image}></InfoTitleContainer>
              <InfoTitleName title={group_preview.name}>
                {group_preview.name}
              </InfoTitleName>
            </StyledContainer>
            <StyledHeaderText>Register for a new account to join.</StyledHeaderText>
            <form noValidate onSubmit={this.onSubmit}>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="username"
                    label="Username"
                    variant="outlined"
                    value={this.state.username}
                    onChange={this.onChange}
                  />
                </FormControl>

                <StyledError>{errors.username}</StyledError>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="email"
                    label="Email"
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </FormControl>

                <StyledError>{errors.email}</StyledError>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </FormControl>

                <StyledError>{errors.password}</StyledError>
              </FormField>
              <StyledError>{errors.error}</StyledError>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{ minWidth: "252px", marginTop: "10px" }}>
                Sign up
              </Button>
              <GoogleReCaptcha onVerify={(token) => (this._captcha = token)} />
            </form>
            <TermsSection>
              By signing up, I agree to the Made{" "}
              <Link style={{ color: "#0073ec" }} to="/terms" target="_blank">
                Privacy Policy and Terms of Service.
              </Link>
            </TermsSection>
            <LoginSection>
              Already have an account? <LoginButton onClick={(e) => this.showLogin()}>login</LoginButton>

            </LoginSection>
          </StyledloginDialog>
        </StyledloginContainer>
      );
    }
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  group_preview: state.public.group_preview,
});

const Page = connect(mapStateToProps, {
  registerUser,
  setMenu,
  getGroupPreview,
  joinGroup
})(withRouter(RegisterGroup));

const render = () => (
  <GoogleReCaptchaProvider reCaptchaKey={captcha_site_key}>
    <Page />
  </GoogleReCaptchaProvider>
);

export default render;
