import React, { Component } from "react";
import { keyframes } from "@emotion/react";
// import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import { current_url, site_name } from "../config";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Element from "./Element";
import TextField from "@mui/material/TextField";
import GhostElement from "./elements/GhostElement";
import CompletedElement from "./elements/CompletedElement";
import ElementPopup from "./ElementPopup";
import ElementEdit from "./ElementEdit";
import ProjectPageEdit from "./ProjectPageEdit";
import ProjectChangeOrder from "./ProjectChangeOrder";
import ProjectCompare from "./ProjectCompare";
// import ProjectCompare from "./ProjectCompare2";
import ScrollContainer from "react-indiana-drag-scroll";
import Slider from "@mui/material/Slider";
import { useRef } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { setZoom } from "../actions/projectActions";
import { setDialogType } from "../actions/projectActions";
import {
  selectElement,
  getProjectContent,
  followProject,
  unfollowProject,
  loadMoreElements,
  getProjectSubscribers,
  loadMoreProjectSubscribers,
} from "../actions/projectActions";
import {
    getGroupData,
    getGroupProjects
  } from "../actions/groupActions";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SpacerElement from "./SpacerElement";
import monthYear from "../utils/monthYear";
import friendlyTimeCalc from "../utils/timeAgo";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircularProgress from "@mui/material/CircularProgress";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonThumb from "./thumbs/PersonThumb";
import FirstUseBanner from "./FirstUseBanner";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { api_endpoint } from "../config";
import MenuIcon from "@mui/icons-material/Menu";
import PublicIcon from "@mui/icons-material/Public";
import LinkIcon from "@mui/icons-material/Link";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { setMenu } from "../actions/uiActions";
import { Typography } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import HelpIcon from "@mui/icons-material/Help";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "../custom-icons/CloseIcon";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  // SectionHelpText,
  ButtonContainer,
  Dialog,
  TextColumn,
} from "./styles/MenuComponents.js";
import setTitle from "../utils/setTitle";

const StyledProjectPage = styled.div`
  height: ${(props) =>
    props.mobile
      ? "calc(100% - 205px)"
      : "calc(100% - 205px)"}; // the height of the top toolbar and bottom nav bar
  // height: calc(100% - 240px); // the height of the top toolbar and bottom nav bar
  //background-color: #e6d0ff;
`;
const ProjectToolBar = styled.div`
  height: 75px;
  // margin: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 25px;
  padding-top: 5px;
`;
const ProjectToolBarNarrow = styled.div`
  height: 50px;
  // margin: 10px;
  display: flex;
  flex-direction: row;
  // margin-top: 40px;
  margin-top: ${(props) => (props.cover ? "0px" : "50px")};
  padding-top: 5px;
`;
const ProjectToolBarSecond = styled.div`
  height: 50px;

  display: flex;
  flex-direction: row;
`;
const PTLeft = styled.div`
  display: flex;
  margin-right: auto;
  // background-color: #e6d0ff;
  width: calc(50% - 100px);
  margin-left: ${(props) => (props.isMobile ? "5px" : "18px")};
  align-items: center;
  height: 100%;
`;
const PTMid = styled.div`
  margin-left: auto;
  margin-right: auto;
  // background-color: #e6d0ff;
  min-width: 200px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #ccc;
`;
const PTRight = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: ${(props) => (props.isMobile ? "5px" : "22px")};
  width: calc(50% - 100px);
`;
const SliderControl = styled.div`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;
const SliderIcon = styled.div`
  margin-top: 5px;
  // margin-left: 10px;
  font-size: 20px;
  color: #757575;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.87);
  position: absolute;

  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const ElementPanel = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
const ElementPanelFull = styled.div`
  width: 100%;
  height: 100%;
`;
const MenuPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 350px;
`;
const MenuPanelNarrow = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
`;
const MenuPanelNarrow2 = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  width: 350px;
`;
const LoadMoreSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
`;

const ElementCard = styled.div`
  width: calc(100% - 105px);
  min-height: 200px;
  //   min-height: ${(props) =>
    props.cardheight ? props.cardheight : "300"}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 5px;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 4px;

  display: flex;
  flex-direction: column;
  padding: 32px;
`;
const InfoTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
`;
const InfoDescr = styled.div`
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;
const HamburgerTitle = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;

  color: #383838;
  display: flex;
`;
const InfoCat = styled.div`
  margin-top: 15px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
`;
const CatItemContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;
const CatItem = styled.div`
  border: 1px solid #ccc;
  height: 23px;
  border-radius: 14px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
`;
const ViewerItem = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  margin-top: 2px;
`;
const ViewerName = styled.div``;
const ViewerTime = styled.div`
  margin-left: auto;
  margin-top: 10px;
  margin-right: 32px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* or 16px */

  /* Mid-Gray */

  color: #c4c4c4;
`;
const InfoPerson = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 20px;
`;
const InfoDescription = styled.div`
  margin-top: 6px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  width: 100%;
  overflow-wrap: anywhere;
`;
const BottomNav = styled.div`
  width: 100%;
  height: 50px;
  // padding-bottom: 50px;
  padding-bottom: ${(props) =>
    props.cover && props.isMobile ? "50px" : "0px"};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const NavDot = styled.div`
  min-width: 11px;
  height: 11px;
  border-radius: 6px;
  // background-color: #111;
  border: 1px solid #c4c4c4;
  margin-right: 2px;
  margin-left: 2px;
  cursor: pointer;
`;

const TitleView = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  // font-weight: bold;
  color: #2196f3;
`;
const TitleViewBracket = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  // font-weight: bold;
  color: #383838;
`;

const ProjectTitleSection = styled.div`
  width: calc(100% - 125px);
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  margin-bottom: auto;
`;
const HamburgerMenu = styled.div`
  margin-right: ${(props) => (props.isMobile ? "10px" : "0px")};
`;
const ProjectTitleContainer = styled.div`
  display: flex;
  margin-top: 13px;
  margin-left: 10px;
  width: calc(100% - 50px);
`;
const ProjectTitle = styled.div`
  font-family: Merriweather, serif;
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-left: 19px;
  margin-top: 12px;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 19px);
`;
const ProjectDescription = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  margin-left: 19px;
  padding-right: 10px;
  padding-bottom: 10px;
  width: calc(100% - 19px);

  display: flex;
`;
const ProjectName = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  flex-shrink: 1;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const ProjectGroup = styled.div`
  max-width: 40%;
  margin-left: 4px;
  display: flex;
  flex-shrink: 1;
  white-space: nowrap;
`;
const CustomSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledMenuItemLabel = styled.div`
  margin-left: 10px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const StyledMenuItem = styled.div`
  display: flex;
  align-items: center;
`;

const MoreIcon = styled.div`
  margin-left: 00px;
  margin-top: -4px;
  font-size: 12px;
  margin-right: 10px;
`;
const MoreIconNarrow = styled.div`
  margin-left: auto;
  margin-top: -5px;
  font-size: 12px;
  margin-right: 12px;
`;
const MoreIconNarrowLeft = styled.div`
  margin-left: 10px;
  margin-top: -5px;
  font-size: 12px;
  display: flex;
`;
const MoreIconLeft = styled.div`
  margin-left: 10px;
  margin-top: 1px;
  font-size: 20px;
  color: #757575;
`;
const MoreArrow = styled.div`
  font-size: 14px;
  margin-top: 5px;
  color: #c4c4c4;
  margin-right: 0px;
`;
const StyledTimeLine = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: #f2f2f2;
`;
const CompletedSection = styled.div`
  height: ${(props) => (props.height ? props.height : "300")}px;
  background-color: #666;
`;
const CompletedSectionNarrow = styled.div`
  min-height: ${(props) => (props.height ? props.height : "300")}px;
  background-color: #666;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const CompletedSectionBottom = styled.div`
  min-height: 80px;
  width: 100%;
  background-color: #666;
  // position: absolute;
  margin-top: 40px;
  display: flex;
`;
const CompletedSectionBottomNarrow = styled.div`
  min-height: 80px;
  width: 100%;
  background-color: #666;
  margin-top: auto;
  // margin-top: 40px;
  display: flex;
`;
const HeroImage = styled.div`
  height: calc(100% - 200px);
  width: 100%;
  display: flex;
  padding-top: 80px;
`;
const HeroImageNarrowContainer = styled.div`
  min-height: calc(100% - 117px);
  width: 100%;
  display: flex;
  padding-top: 80px;
`;
const HeroImageNarrow = styled.div`
  // height: calc(100% - 300px);
  width: 100%;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
`;
const CompletedSectionDate = styled.div`
  color: #fff;
  margin-left: auto;
  font-family: "Roboto", sans-serif;
  margin-right: 20px;
  margin-top: 30px;
`;
const CompletedSectionTitle = styled.div`
  color: #fff;
  font-size: 20px;
  font-family: Merriweather, serif;
  margin-left: 20px;
  margin-top: 25px;
`;
const MetaDataSection = styled.div`
  background-color: #111;

  color: #fff;
  font-size: 14px;
  font-family: Merriweather, serif;
  padding-top: 35px;
  padding-bottom: 35px;
  display: flex;
`;
const CenteredArea = styled.div`
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;
const CenteredMetaArea = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "1000px")};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;
const MetaCategory = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: #a7a7a7;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;
const MetaDuration = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: #a7a7a7;
  font-weight: 400;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
`;
const MetaDescription = styled.div`
  font-size: 18px;
  font-family: Merriweather, serif;
  color: #a7a7a7;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
  overflow-wrap: anywhere;
`;
const MetaUser = styled.div`
  font-size: 18px;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-weight: 500;
  margin-left: 20px;
  margin-right: 20px;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Merriwether", serif;
  font-size: 36px;
  font-style: italic;
  color: #666;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  padding: 20px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: url("/lamp.png");
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.width < 980 ? "column" : "row")};
  // width: 100%;
  // margin-bottom: 10px;
  margin-left: auto;
  margin-right: 15px;
  padding-left: 5px;
`;
const DropdownContainerNarrow = styled.div`
  display: flex;

  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 10px;
`;
const SecondLineLabel = styled.div`
  // width: 40%;
  //     white-space: nowrap;
  // text-align: right;
  color: #aaa;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 1px;
`;
// const SectionLabel = styled.div`
//   font-family: Roboto, sans-serif;
//   font-size: 16px;
//   font-weight 400;
//   margin-left: 30px;
//   margin-top: 25px;
//   margin-bottom: 10px;
//   color: #757575;
//   text-align: left;
// `;
const MenuLine = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuLineLast = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuLineFirst = styled.div`
  height: 45px;
  padding-top: 5px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;
const DividerLine = styled.div`
  // height: 1px;
  border-bottom: 1px solid #f2f2f2;

  width: 100%;
`;
const MenuLineCommand = styled.div`
  height: 40px;
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;

const SectionHelpText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MenuLineInstruction = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 30px;

  color: #111;
`;
const MenuLineInstruction1 = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;

  color: #111;
`;
const MenuLineCommandLast = styled.div`
  height: 60px;
  padding-top: 15px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuIconDiv = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 13px;
  margin-left: 10px;
`;
const MenuLabel = styled.div`
max-width: 193px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: inherit;
`;
const MenuLabelSingle = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  margin-left: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 64px;
  width: 100%;
  height: 100%;
  color: "rgba(0, 0, 0, 0.8)";
`;
const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 12px;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ListBox = styled.div`
  width: 100%;
  // margin-bottom: ${(props) => (props.isMobile ? "30px" : "0px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
  padding-bottom: ${(props) => (props.isMobile ? "30px" : "10px")};
  overflow-y: auto;
  max-height: ${(props) => (props.isMobile ? "none" : "calc(100vh - 400px)")};
`;
const AnimationContainer = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  //
`;
const ElementContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  will-change: transform;
 
//   animation: ${(props) => Pan()} ${(props) => props.duration}s ease-in-out 1s
    forwards;
  // height: calc(100% - 50px);
    transform: translateX(0%);
  z-order: 1000;
//   opacity: 0;
margin-left:100vw;
`;
const AnimationContainer2 = styled.div`
  margin-top: 50px;
  display: flex;
  opacity: 1;
  transform:perspective(1000px) rotateY(0deg);
 will-change: transform;
//   animation: ${(props) => Rotate()} ${(props) =>
    props.duration}s ease-in-out 1s
    forwards;
`;
const MadeWith = styled.div`
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
background-color: #252525;
z-index: 1000;
cursor: none;
`;
const MadeImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 228px;
`;
const Madetext = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  color: #fff;
`;
const Madetext2 = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
   color: #fff;
`;
const MadePN = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  font-family: Merriweather, serif;

  font-style: normal;
  font-weight: 700;
 
text-align: center;
  font-size: 64px;
   color: #fff;
`;
const MadeUN = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top:20px;
  font-family: Roboto, sans-serif;

  font-style: normal;
  font-weight: 400;

text-align: center;
  font-size: 24px;
   color: #fff;
`;
const MadeTextBlock = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: calc(50vh - 150px);
  margin-bottom: auto;
position: relative;
`;
const MadeGN = styled.div`
  margin-left: auto;
  margin-right: auto;
  
  font-family: Roboto, sans-serif;

  font-style: normal;
  font-weight: 400;

text-align: center;
  font-size: 24px;
   color: #fff;
`;
const Madeurl = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;

  font-style: normal;
  font-weight: 400;
  margin-top: auto;
overflow-wrap: break-word;
text-align: center;
  font-size: 24px;
   color: #fff;
`;
export const Rotate = () => keyframes`
    0% {
      transform:perspective(1000px) rotateY(30deg);
    
    }
    
    100% {
      transform:perspective(1000px) rotateY(-30deg);
    
    }
`;
export const Pan = () => keyframes`
0% {
    transform: translateX(0px);
    opacity: 0;
  }
  5% {
   
    opacity: 1;
  }
  100% {
    transform: translateX(-102%);
    opacity: 1;
  }
`;
export const FadeIn = () => keyframes`
0% {
    opacity: 0;
  }
90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const AnimationFrame = styled.div`
  margin: auto;

  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #f2f2f2;
  position: relative;
`;
const AnimationOutside = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #f2f2f2;
  overflow: hidden;
  cursor: none;
`;
const AnimationInside = styled.div`
cursor: none;
margin: auto;
display: flex;
`;
const AnimationUI = styled.div`
    background-color: #f2f2f2;
    margin-right: 32px;
    border: 1px solid #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none
`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
`;
const toptoolbarheightMobile = 140;
const bottomnavbarheight = 60;
// let InViewArray = [];

let ScrollClick = false;
let ZoomClick = false;
// const TextAreaHeight = 130;
// const border = 32;

// document.addEventListener('gesturestart', e => e.preventDefault())
// document.addEventListener('gesturechange', e => e.preventDefault())

function easeInCubic(t, b, c, d) {
  return c * (t /= d) * t * t + b;
}
function easeInOutCubic(t, b, c, d) {
  if ((t /= d / 2) < 1) return (c / 2) * t * t * t + b;
  return (c / 2) * ((t -= 2) * t * t + 2) + b;
}

class ProjectSlideShow extends Component {
  tline = React.createRef();
  tline_sub = React.createRef();
  madewith = React.createRef();
  outsideframe = React.createRef();
  animationframe = React.createRef(); 

  constructor() {
    super();
    this.state = {
        getnextProject: true,
        isPlaying: false,
        currentgroup: false,
        nextProjectID: 0,
      showpopup: false,
      showpopupedit: false,
      showmenupopup: false,
      showorderpopup: false,
      showcomparepopup: false,
      showpopupsettings: false,
      showsharingsettings: false,
      showmobilemenu: false,
      showgroups: false,
      hiddenfeature: false,
      value: 80,
      card_height: 700,
      comment_height: 100,
      selected_element: -1,
      empty_project: false,
      InViewArray: [],
      initial: true,
      isOwner: false,
      project_url: "",
      groupid: false,
      visibility: "PR",
      snackOpen: false,
      snackMessage: "",
      previous_window_height: 0,
      not_shared: false,
      runAnimation: true,
      animationDuration: 8,
      animationFade: [
        { keyframe: 0, value: 0 },
        { keyframe: 10, value: 1 },
      ],
      animationPan: [
        { keyframe: 0, value: 0 },
        { keyframe: 100, value: 102 },
      ],
      animationRotate: [
        { keyframe: 0, value: 30 },
        { keyframe: 100, value: -30 },
      ],
      easeInEndFrame: 50,
      easeOutStartFrame: 70,
      titlescreen: 5,
      zoomin: 1,
      overlap: 0.6,
      rotstart: 0,
      rotend: 0,
      panstart: 0,
      panend: 125,
      perspective: 1000,
      subFilter: "viewers",
      showusername: false,
      motionblur: false
    };
    this.handleClosePopup = this.handleClosePopup.bind(this);
  }

  calcElementHeight = (containerheight, zoom) => {
    const { dimensions } = this.props;
   
    let CardHeight;
    let CommentHeight;
    if (dimensions.window_dimensions.isMobile) {
      if (zoom > 50) {
        const tempcardheight = Math.round(
          (containerheight - toptoolbarheightMobile - bottomnavbarheight) / 2
        );
        CardHeight =
          tempcardheight +
          ((containerheight - tempcardheight - 50) * (zoom - 50)) / 100;
        if (CardHeight - 25 > dimensions.window_dimensions.w) {
          CardHeight = dimensions.window_dimensions.w;
        }
      } else {
        CardHeight = Math.round(
          (containerheight - toptoolbarheightMobile - bottomnavbarheight) / 2
        );
      }
      CommentHeight =
        containerheight -
        toptoolbarheightMobile -
        bottomnavbarheight -
        CardHeight -
        150;
      // this height might need to be relative to the screenheight for mobile: looks good on iphone 8, but too long on iphone x
    } else {
      if (zoom > 50) {
        const tempcardheight = Math.round(
          (containerheight - toptoolbarheightMobile - bottomnavbarheight) / 2
        );
        CardHeight =
          tempcardheight +
          ((containerheight - tempcardheight - 50) * (zoom - 50)) / 100;
      } else {
        CardHeight = Math.round(
          (containerheight - toptoolbarheightMobile - bottomnavbarheight) / 2
        );
      }
      CommentHeight =
        containerheight -
        toptoolbarheightMobile -
        bottomnavbarheight -
        CardHeight -
        150;
    }

    // CommentHeight = (containerheight - toptoolbarheightMobile - bottomnavbarheight) - CardHeight-CommentAreaMargin;

    // if (containerheight <= 700){
    //   CardHeight = Math.round((containerheight - toptoolbarheight - bottomnavbarheight)* 0.9 )
    //   CommentHeight = containerheight - CardHeight -CommentAreaMargin
    //   if (CardHeight > containerwidth ){
    //     CardHeight = containerwidth
    //     CommentHeight = containerheight - Math.round((containerheight - toptoolbarheight - bottomnavbarheight)* 0.9 ) -CommentAreaMargin
    //   }

    // }

    this.setState({ card_height: 1500 });
    this.setState({ comment_height: 360 });
    this.props.dispatch(setZoom(zoom, CardHeight, CommentHeight));
  };
  //   t = Time - Amount of time that has passed since the beginning of the animation. Usually starts at 0 and is slowly increased using a game loop or other update function.
  // b = Beginning value - The starting point of the animation. Usually it's a static value, you can start at 0 for example.
  // c = Change in value - The amount of change needed to go from starting point to end point. It's also usually a static value.
  // d = Duration - Amount of time the animation will take. Usually a static value aswell.


  // animationFade:[{keyframe: 0, value: 0},{keyframe: 10, value: 1}],
  // animationPan:[{keyframe: 0, value: 0},{keyframe: 100, value: -100}],
  // animationRotate:[{keyframe: 0, value: 30},{keyframe: 100, value: -30}],
  // easeInEndFrame: 30,
  // easeOutStartFrame: 70,


  moveTimeline = (duration) => {
   
    let tline2 = this.tline.current;
    let tline_sub2 = this.tline_sub.current;
    let madewith2 = this.madewith.current;
    let outsideframe = this.outsideframe.current;
    let animationframe = this.animationframe.current;

    tline2.style.transform = null
  
    let start = Date.now();
  
  
    let PanStart = this.state.panstart;
    let PanEnd = this.state.panend;
    let dur = duration * 1000 + this.state.titlescreen * 1000;
    let titlescreen = this.state.titlescreen * 1000
   
    let zoomin = this.state.zoomin * 1000

   
    tline_sub2.style.transform =
      "translate3d(" + PanStart + "%, 0, 0px)";
    madewith2.style.opacity =1;

   
  
    const animateTimeline = (timestamp) => {
      let interval = Date.now() - start;
  
      let PanDiff;


      if (interval < zoomin) {
        outsideframe.style.backgroundColor = "#252525"
        animationframe.style.backgroundColor = "#252525"
        tline_sub2.style.opacity = 0
        madewith2.style.opacity = 1 * (interval / zoomin);
        
      }
      if (interval > titlescreen ) {
        madewith2.style.opacity = 1 - (1 * (interval - (titlescreen + zoomin)) / zoomin);

      }
      if (interval > titlescreen && interval  < dur  ) {
        outsideframe.style.backgroundColor = "#f2f2f2"
        animationframe.style.backgroundColor = "#f2f2f2"
        if (interval >  dur - zoomin  ){
            tline_sub2.style.opacity = 1 - (1 * (interval - (dur - zoomin)) / zoomin);
        }else{
            tline_sub2.style.opacity = 1 
        }
       
   
        PanDiff = (PanEnd - PanStart)*(interval - titlescreen) / (dur - titlescreen)
      
     
        tline_sub2.style.transform = "translate3d(-" + PanDiff + "%, 0, 0px)";
       


      }
      if (interval < dur) {
        requestAnimationFrame(animateTimeline);
      } else {
        this.GetNextProject();
        cancelAnimationFrame(timer);
      
      } // queue request for next frame
    }
    let timer = requestAnimationFrame(animateTimeline);

  };
  GetNextProject = () => {
    
    this.setState({ getnextProject: true });
    this.setState({ isPlaying: false });
  }
  onChangeDuration = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  showUsername = (e) => {
    this.setState({ showusername: e.target.checked });
  };
  showBlur = (e) => {
    this.setState({ motionblur: e.target.checked });
  };
  handlePlayAnimation = (e) => {
   // add duration here
    this.moveTimeline();
  };
  OnAnimationEdn = (e) => {
    console.log("end");
  };
  onChange = (event, newValue) => {
    // changing the window height should maybe change the zoom level

    // //console.log("oncgange",newValue,dimensions.window_dimensions.h)
    this.setState({ value: newValue });

    this.calcElementHeight(window.innerHeight, newValue);
    ZoomClick = true;
    this.setInView();
  };
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }
  componentDidMount() {
    const { dimensions } = this.props;

    // if (dimensions.window_dimensions.isMobile) {
    // }
    this.setState({ previous_window_height: dimensions.window_dimensions.h });
    this.setState({ showmenupopup: false });
    this.setState({ selected_element: -1 });
    this.setState({ inital: true });
    document.addEventListener("keydown", this.keyPress);

    const urlparts = window.location.pathname.split("/");
    const loc = urlparts[1];
    const projectId = urlparts[2];
    let elementid = false;
    let groupid = false;
    urlparts.forEach((part, index) => {
     
      if (part === "groupslideshow") {
        groupid = urlparts[index + 1];
      }
    });
    if (groupid) {
      this.setState({ groupid: groupid });
      this.setState({
        project_url:
          "/project/" + urlparts[2] + "/" + urlparts[3] + "/group/" + groupid + "/",
      });
    } else {
      // this.setState({ groupid: "1" });
      this.setState({
        project_url: "/project/" + urlparts[2] + "/" + urlparts[3] + "/",
      });
    }
    // console.log("groupid",groupid)
    if (loc === "groupslideshow") {
        this.setState({ currentgroup: groupid });
        this.props.dispatch(getGroupData(groupid));
        this.props.dispatch(getGroupProjects(groupid, false));
    
    }
   
   
   

    // document.body.style.backgroundColor = "#f2f2f2";
    // if (dimensions.window_dimensions.isMobile){
    //   this.calcElementHeight(window.innerHeight,75)
    // }else{

    // }
    if (window.matchMedia("only screen and (max-width: 760px)").matches) {
      this.calcElementHeight(window.innerHeight, 80);
      this.setState({ value: 80 });
    } else {
      this.calcElementHeight(window.innerHeight, 80);
      this.setState({ value: 80 });
    }
  }

  componentDidUpdate() {
    const { project_loading, project, dialog_type, dimensions, popup, projectgroups } =
      this.props;
    // console.log("updateeee", project_loading)
    // console.log(project)
    console.log(this.props.project.current_project.elements.allIDS.length)
    if (
        project_loading.project === false &&
        project_loading.elements === false
       
      ){

       
        if (this.state.isPlaying === false){
           
            let secondsperslide = 6
            let newduration = secondsperslide * this.props.project.current_project.elements.allIDS.length
            if (this.state.animationDuration !== newduration){
                this.setState({ animationDuration: newduration });
            }
            this.setState({ isPlaying: true });
            this.moveTimeline(newduration);
        }
      }
    if (this.state.getnextProject){
        if (projectgroups.projects_loading === false){
            this.setState({ getnextProject: false });

          
            this.props.dispatch(getProjectContent(projectgroups.projects.allIDS[this.state.nextProjectID], this.state.currentgroup));
            this.calcElementHeight(window.innerHeight, 80);
            this.setState({ value: 80 });
            if (this.state.nextProjectID < projectgroups.projects.allIDS.length - 1){
                this.setState({ nextProjectID: this.state.nextProjectID + 1 });
            }else{
                this.setState({ nextProjectID: 0 });
            }
           
            
        }
    }
    
    if (dimensions.window_dimensions.h !== this.state.previous_window_height) {
      
      this.calcElementHeight(window.innerHeight, this.state.value);
      this.setState({ previous_window_height: dimensions.window_dimensions.h });
    }
    if (this.props.elements.allIDS.length === 0) {
       
      if (this.state.empty_project === false) {
        this.setState({ empty_project: true });
      }
    } else {
      
       
      if (this.state.empty_project === true) {
        this.setState({ empty_project: false });
      }
    }

    if (popup === false) {
      // this.handleClosePopup();
    }
    //  if (this.state.groupid){
    //   console.log("hasid",this.props.project.current_project.groups)
    //   // url has group id, but project is not being shared.
    //   let found = false
    //   this.props.project.current_project.groups.allIDS.forEach((gd, index) => {
    //     if (gd === this.state.groupid){
    //       found = true
    //     }
    //   });
    //   if (!found){
    //     //redirect
    //     const urlparts = this.state.project_url.split("/");
    //     const url = "/project/" + urlparts[2] + "/" + urlparts[3]

    //     window.location.href = url
    //   }
    //  }
    if (this.state.initial && !project_loading.elements) {
       
      if (!this.state.groupid) {
        console.log("no group id");
        if (
          !this.props.project.current_project.public &&
          !this.props.project.current_project.share_link_enabled
        ) {
          if (this.props.project.current_project.groups.allIDS.length > 0) {
            this.setState({
              groupid: this.props.project.current_project.groups.allIDS[0],
            });
            const urlparts = this.state.project_url.split("/");
            console.log(urlparts);
            let newurl =
              "/project/" +
              urlparts[2] +
              "/" +
              urlparts[3] +
              "/group/" +
              this.props.project.current_project.groups.allIDS[0];
            this.setState({
              project_url: newurl,
            });
          }
        }
      }

      // if (this.state.groupid === "1") {
      //   console.log("groupid")
      //   if (this.props.project.current_project.groups.allIDS.length !== 0) {
      //     if (this.props.project.current_project.groups.allIDS.length > 1) {
      //       this.setState({ groupid: "2" });
      //     } else {
      //       this.setState({
      //         groupid: this.props.project.current_project.groups.allIDS[0],
      //       });
      //     }
      //   }
      // }
      if (
        this.props.project.current_project.creator.id ===
        this.props.auth_user.user_id
      ) {
        this.setState({ isOwner: true });
      } else {
        this.setState({ isOwner: false });
      }
      const { elements } = this.props;
      let initialselection = this.state.selected_element;
      if (elements.allIDS.length === 0) {
        this.setState({ empty_project: true });
      } else {
        this.setState({ empty_project: false });
        if (!project.current_project.cover) {
          initialselection = elements.allIDS.length - 1;
        }
      }
      let foundnone = true;
      // let lastdate;
      // let newdate = false;

      elements.allIDS.forEach((elementid, index) => {
        if (new Date(elements.byID[elementid].element_date) > new Date()) {
          if (foundnone) {
            initialselection = index - 1;
            foundnone = false;
          }
        }
      });

      this.selectElement(initialselection);

      this.setState({ initial: false });
      //   setTimeout(() => {
      //     this.timeline.current.getElement().scrollTo({
      //       top: 0,
      //       left: this.getScrollPosition(this.state.selected_element),
      //     });
      //   }, 1);
    }

    if (ZoomClick) {
      this.timeline.current.getElement().scrollTo({
        top: 0,
        left: this.getScrollPosition(this.state.selected_element),
      });
    }
    ZoomClick = false;
    const urlparts = window.location.pathname.split("/");
    const loc = urlparts[1];
    const el = urlparts[2];
    let elementid = false;
    // let groupid = false;
    urlparts.forEach((part, index) => {
      if (part === "element") {
        elementid = urlparts[index + 1];
      }
      // if (part === "group") {
      //   groupid = urlparts[index + 1];
      // }
    });

    if (elementid) {
      if (dialog_type === "edit") {
        if (this.state.showpopupedit === false) {
          this.setState({ showpopup: false });
          this.setState({ showpopupedit: true });

          // this.props.dispatch(selectElement(el));
        }
      }
      if (dialog_type === "element") {
        if (this.state.showpopup === false) {
          this.setState({ showpopup: true });
          this.setState({ showpopupedit: false });
          this.props.dispatch(selectElement(elementid));
        }
      }
    } else {
      if (loc !== "new") {
        if (
          this.state.showpopup === true ||
          this.state.showpopupedit === true
        ) {
          this.setState({ showpopup: false });
          this.setState({ showpopupedit: false });
          this.props.dispatch(setDialogType("element"));
        }
      }
    }

    switch (loc) {
      case "edit":
        if (this.state.showpopupedit === false) {
          this.setState({ showpopup: false });
          this.setState({ showpopupedit: true });
          this.setState({ showmenupopup: false });
          this.setState({ showorderpopup: false });
          this.props.dispatch(selectElement(el));
        }
        break;
      case "new":
        if (this.state.showpopupedit === false) {
          this.setState({ showpopup: false });
          this.setState({ showpopupedit: true });
          this.setState({ showorderpopup: false });
          this.setState({ showmenupopup: false });
        }
        break;
      // case "order":
      //   if (this.state.showorderpopup === false) {
      //     this.setState({ showpopup: false });
      //     this.setState({ showpopupedit: false });
      //     this.setState({ showorderpopup: true });
      //     this.setState({ showmenupopup: false });
      //   }
      //   break;
      // case "editproject":
      //   if (this.state.showpopupsettings === false) {
      //     this.setState({ showpopup: false });
      //     this.setState({ showpopupedit: false });
      //     this.setState({ showpopupsettings: true });
      //     this.setState({ showorderpopup: false });
      //     this.setState({ showmenupopup: false });
      //   }
      //   break;
      default:
        // if (this.state.showpopupsettings === true) {
        //   // this.setState({ showpopupedit: false });
        //   this.setState({ showpopupsettings: false });

        // }
        // if (this.state.showorderpopup === true) {
        //   // this.setState({ showpopupedit: false });
        //   this.setState({ showorderpopup: false });

        // }

        break;
    }
  }
  loadMoreElements = (link) => {
    this.props.dispatch(loadMoreElements(link));
  };
  keyPress = (e) => {
    // this doesn't seem to work in safari
    if (this.state.showpopup === false) {
      if (e.keyCode === 39) {
        // // this.navigateElement("next");
        // this.setState({ getnextProject: true });
        // this.setState({ isPlaying: false });
        // // if (this.state.nextProjectID < this.props.projectgroups.projects.allIDS.length - 1){
        // //     this.setState({ nextProjectID: this.state.nextProjectID + 1 });
        // // }else{
        // //     this.setState({ nextProjectID: 0 });
        // // }
      }
      if (e.keyCode === 37) {
        // this.navigateElement("previous");
      }
      if (e.ctrlKey && e.key === "z") {
        this.setState({ hiddenfeature: true });
      }
    }
  };
  handleClosePopup() {
    const { project } = this.props;
    console.log("close");
    if (
      this.state.showpopup === true ||
      this.state.showpopupedit === true ||
      this.state.showpopupsettings === true ||
      this.state.showsharingsettings === true ||
      this.state.showorderpopup === true ||
      this.state.showcomparepopup === true ||
      this.state.showgroups === true
    ) {
      this.setState({ showpopup: false });
      this.setState({ showpopupedit: false });
      this.setState({ showpopupsettings: false });
      this.setState({ showsharingsettings: false });
      this.setState({ showorderpopup: false });
      this.setState({ showcomparepopup: false });
      this.setState({ showgroups: false });
      // this.props.dispatch(popupState(null));
    }
    // const target =
    //   "/project/" +
    //   project.current_project.id +
    //   "/" +
    //   project.current_project.name;
    // this.props.history.replace(target);
  }
  handleShowGroups() {
    this.setState({ showgroups: true });
  }
  handleCloseMenu() {
    this.setState({ showmenupopup: false });
  }
  handleCloseMobileMenu() {
    this.setState({ showmobilemenu: false });
  }
  handleShowMobileMenu() {
    this.setState({ showmobilemenu: true });
  }
  shareProject() {
    if (this.props.project.current_project.unshared_groups) {
      this.handleShowGroups();
    } else {
      this.setState({ showsharingsettings: true });
    }
  }
  compareElements() {
    this.setState({ showcomparepopup: true });
  }
  handleShowMenu() {
    if (this.state.showmenupopup === false) {
      this.setState({ showmenupopup: true });
    } else {
      this.setState({ showmenupopup: false });
    }
  }
  handleLoginMenu(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.dispatch(setMenu(true, 13));
  }
  doNothing(e) {
    e.stopPropagation();
  }
  updateSelection(args) {
    // the one whose midpoint is closest to the window center should get the selection
    // there is an edge for the end points when the end point is not the closest, but should get selection
    // this function also runs after clicking navigation buttons

    if (!ScrollClick) {
      const { dimensions, elements } = this.props;

      let i;
      let scrollpos = 0;
      const MidDistance = args[0];
      for (i = 0; i < elements.allIDS.length - 1; i++) {
        const id = elements.allIDS[i];
        const width = elements.byID[id].width;

        if (
          MidDistance > scrollpos &&
          MidDistance < scrollpos + elements.byID[elements.allIDS[i + 1]].width
        ) {
          this.setState({ selected_element: i });
          if (dimensions.window_dimensions.isMobile) {
            // this.selectElement(i) // snapping of element to center of the screen on mobile
          }
          //
          this.setInView();
          return;
        }
        scrollpos = scrollpos + width;
      }
    }
    ScrollClick = false;
    this.setInView();
  }
  setInView() {
    // this determines which elements are currently visible within the viewport
    // this is slightly off, probably due to the width array having wrong values
    // let temparray = [];
    // const { dimensions, elements } = this.props;
    // const leftpos = this.timeline.current.getElement().scrollLeft;
    // const leftboundary = leftpos - dimensions.window_dimensions.w / 2;
    // const rightboundary = leftpos + dimensions.window_dimensions.w / 2;
    // let i;
    // let scrollpos = 0;
    // for (i = 0; i < elements.allIDS.length; i++) {
    //   const id = elements.allIDS[i];
    //   const width = elements.byID[id].width;
    //   scrollpos = scrollpos + width;
    //   if (scrollpos >= leftboundary && scrollpos - width < rightboundary) {
    //     temparray.push(i);
    //   }
    // }
    // this.setState({ InViewArray: temparray });
  }

  selectElement = (selection) => {
    // ScrollClick = true;
    // this.timeline.current
    //   .getElement()
    //   .scrollTo({ top: 0, left: this.getScrollPosition(selection) });
    // this.setState({ selected_element: selection });
  };
  newElement = () => {
    this.props.history.replace("/new/p1");
  };
  projectSettings = () => {
    this.setState({ showpopupsettings: true });
    // this.props.history.replace(
    //   "/editproject/" + this.props.project.current_project.id
    // );
  };
  changeOrder = () => {
    this.setState({ showorderpopup: true });
    // this.props.history.replace(
    //   "/order/" + this.props.project.current_project.id
    // );
  };
  followProject = () => {
    const { project, auth } = this.props;
    if (auth.isAuthenticated) {
      this.props.dispatch(followProject(project.current_project.id));
      this.setState({ snackOpen: true });
      this.setState({ snackMessage: "You are now following this project" });
    } else {
      this.props.dispatch(setMenu(true, 13));
    }
  };
  handleSubFilterChange(e) {
    this.setState({ subFilter: e.target.value });
    if (e.target.value === "subscribers") {
      this.props.dispatch(
        getProjectSubscribers(this.props.project.current_project.id)
      );
    }
  }
  loadMoreSubscribers = (link) => {
    this.props.dispatch(loadMoreProjectSubscribers(link));
  };

  unfollowProject = () => {
    const { project } = this.props;
    this.props.dispatch(unfollowProject(project.current_project.id));
    this.setState({ snackOpen: true });
    this.setState({
      snackMessage: "You are not following this project anymore",
    });
  };
  handleChangeGroup = (event) => {
    this.setState({ groupid: event.target.value });
  };
  getScrollPosition = (selection) => {
    const { elements } = this.props;
    let i;
    let scrollpos = 0;
    //console.log(this.state.empty_project)
    //console.log(selection)
    if (this.state.empty_project || selection === -1) {
      let minsize;
      if (this.props.dimensions.window_dimensions.isMobile) {
        minsize = 70;
      } else {
        minsize = 120;
      }

      let containerwidth;
      // let containerwidth = Math.round(
      //   minsize + ((card_height - minsize - 50) * zoomlevel) / 100
      // );
      if (this.state.value > 50) {
        containerwidth = Math.round(this.state.card_height - 25);
      } else {
        containerwidth = Math.round(
          minsize +
          ((this.state.card_height - minsize - 25) * this.state.value) / 50
        );
      }
      //console.log("kdsjbskdf: ", containerwidth)
      scrollpos = containerwidth / 2;
    } else {
      for (i = 0; i <= selection; i++) {
        const id = elements.allIDS[i];
        const width = elements.byID[id].width;

        if (selection === i) {
          scrollpos = scrollpos + width / 2;
        } else {
          scrollpos = scrollpos + width;
        }
      }
    }

    return scrollpos;
  };

  navigateElement = (type) => {
    switch (type) {
      case "start":
        this.navigateStart();
        break;
      case "previous":
        this.navigatePrevious();
        break;
      case "next":
        this.navigateNext();
        break;
      case "end":
        this.navigateEnd();
        break;
      default:
        break;
    }
  };

  navigateStart() {
    if (!this.state.empty_project) {
      this.timeline.current
        .getElement()
        .scrollTo({ top: 0, left: this.getScrollPosition(0) });
      this.setState({ selected_element: 0 });
      ScrollClick = true;
    }
  }

  navigatePrevious() {
    if (!this.state.empty_project) {
      let PrevSelection;
      if (this.state.selected_element > 0) {
        PrevSelection = this.state.selected_element - 1;
      } else {
        PrevSelection = 0;
      }

      this.timeline.current.getElement().scrollTo({
        top: 0,
        left: this.getScrollPosition(PrevSelection),
        behavior: "smooth",
      });
      this.setState({ selected_element: PrevSelection });
      ScrollClick = true;
    }
  }

  navigateNext() {
    const { elements } = this.props;
    // should add func here so it know if list is at the end and multiple items are showing on the screen
    if (!this.state.empty_project) {
      let NextSelection;
      if (this.state.selected_element < elements.allIDS.length - 1) {
        NextSelection = this.state.selected_element + 1;
      } else {
        NextSelection = elements.allIDS.length - 1;
      }
      this.timeline.current.getElement().scrollTo({
        top: 0,
        left: this.getScrollPosition(NextSelection),
        behavior: "smooth",
      });
      this.setState({ selected_element: NextSelection });

      ScrollClick = true;
    }
  }

  navigateEnd() {
    if (!this.state.empty_project) {
      const { elements } = this.props;
      this.timeline.current.getElement().scrollTo({
        top: 0,
        left: this.getScrollPosition(elements.allIDS.length - 1),
      });

      this.setState({ selected_element: elements.allIDS.length - 1 });
      ScrollClick = true;
    }
  }
  switchGroup(groupid) {
    const { project } = this.props;
    let target;
    if (groupid) {
      target =
        "/project/" +
        project.current_project.id +
        "/" +
        project.current_project.name +
        "/group/" +
        groupid + "/";
    } else {
      target =
        "/project/" +
        project.current_project.id +
        "/" +
        project.current_project.name + "/";
    }

    window.location.href = target;
  }
  switchSharingLink() {
    const { project } = this.props;

    const target =
      current_url +
      "/project/" +
      project.current_project.share_link +
      "/" +
      project.current_project.name;
    window.location.href = target;
  }
  copyLink() {
    const { project } = this.props;

    const target =
      current_url +
      "/project/" +
      project.current_project.share_link +
      "/" +
      project.current_project.name;

    navigator.clipboard.writeText(target);
  }
  goBack() {
    // eslint-disable-next-line no-restricted-globals
    history.back();
  }
  retry() {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
  handleDragOver(e) {
    e.preventDefault();
    console.log("drag", e);
  }
  handleDrop(e) {
    e.preventDefault();
    console.log("drop", e);
  }
  render() {
    const {
      project_loading,
      selected_element,
      dimensions,
      elements,
      project,
      subscribers,
      projectgroups
    } = this.props;
    let NoContent = true;
    if (elements.allIDS.length !== 0) {
      NoContent = false;
    }
    if (project.error) {
      const error = project.error;
      if (error.response && error.response.status === 404) {
        setTitle(`Not Found | ${site_name}`)
        return (
          <ErrorContainer>
            <HelpIcon style={{ fontSize: "72px" }} />
            <ErrorMessage>
              <Typography variant="h4">Project Not Found</Typography>
              <Typography variant="subtitle1">
                The project you are looking for does not exist or has been
                deleted.
              </Typography>
            </ErrorMessage>
            <Button
              variant="contained"
              disableElevation
              onClick={(e) => this.goBack()}>
              Back
            </Button>
          </ErrorContainer>
        );
      }
      if (error.response && [401, 403].includes(error.response.status)) {
        setTitle(`Access Denied | ${site_name}`)
        return (
          <ErrorContainer>
            <ReportIcon style={{ fontSize: "72px" }} />
            <ErrorMessage>
              <Typography variant="h4">Access Denied</Typography>
              <Typography variant="subtitle1">
                You don't have permission to view this project.
              </Typography>
              {this.props.auth.isAuthenticated ? null : (
                <Typography variant="subtitle1">
                  If you think this is wrong, try logging in.
                </Typography>
              )}
            </ErrorMessage>
            {this.props.auth.isAuthenticated ? (
              <Button
                variant="contained"
                disableElevation
                onClick={(e) => this.goBack()}>
                Back
              </Button>
            ) : (
              <Button
                variant="contained"
                disableElevation
                onClick={(e) => this.handleLoginMenu(e)}>
                Login
              </Button>
            )}
          </ErrorContainer>
        );
      }
      setTitle(`Error | ${site_name}`)
      return (
        <ErrorContainer>
          <WarningIcon style={{ fontSize: "72px" }} />
          <ErrorMessage>
            <Typography variant="h4">Something went wrong</Typography>
            <Typography variant="subtitle1">
              We couldn't load this project. Please try again.
            </Typography>
          </ErrorMessage>
          <Button
            variant="contained"
            disableElevation
            onClick={(e) => this.retry()}>
            Retry
          </Button>
        </ErrorContainer>
      );
    }
    // console.log(this.state.showsharingsettings)
    if (
      project_loading.project === false &&
      project_loading.elements === false
    ) {
      setTitle(`${project.current_project.name} | ${site_name}`)
      return (
        <AnimationOutside ref={this.outsideframe}>
           
          <AnimationInside>
          <MadeWith ref={this.madewith}>
                {/* <Madetext>Made</Madetext>
                <Madetext2>with</Madetext2>
                <MadeImage>
                  {" "}
                  <img
                    width="300px"
                    alt="logo"
                    src="/Logo Centered LARGE.png"></img>
                </MadeImage>   */}
                <MadeTextBlock>
                 <MadeGN>{projectgroups.name}</MadeGN>
                <MadePN>{project.current_project.name}</MadePN>
              
                <MadeUN>{project.current_project.creator.fullname ? project.current_project.creator.fullname : project.current_project.creator.username}</MadeUN>
                </MadeTextBlock>
             
                <Madeurl>https://asitsmade.com </Madeurl>
              </MadeWith>
            <AnimationUI>
              <IconButton
                title="Project Information"
                size="large"
                onClick={() => {
                  this.handlePlayAnimation();
                }}>
                <PlayCircleIcon style={{ fontSize: "30px", color: "#111" }} />
              </IconButton>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="animationDuration"
                    label="Animation Duration"
                    variant="outlined"
                    value={this.state.animationDuration}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              {/* <FormField>
            <FormControl fullWidth variant="outlined">
              <TextField
                style={{ background: "#fff", borderRadius: "3px" }}
                size="small"
                id="titlescreen"
                label="Title Screen Duration"
                variant="outlined"
                value={this.state.titlescreen}
                onChange={this.onChangeDuration}
              />
            </FormControl>
          </FormField> */}
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="overlap"
                    label="Title Screen Overlap"
                    variant="outlined"
                    value={this.state.overlap}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="zoomin"
                    label="Zoom in Duration"
                    variant="outlined"
                    value={this.state.zoomin}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="panstart"
                    label="Pan Start"
                    variant="outlined"
                    value={this.state.panstart}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="panend"
                    label="Pan End"
                    variant="outlined"
                    value={this.state.panend}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="rotstart"
                    label="Rotation Start"
                    variant="outlined"
                    value={this.state.rotstart}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth variant="outlined">
                  <TextField
                    style={{ background: "#fff", borderRadius: "3px" }}
                    size="small"
                    id="rotend"
                    label="Rotation End"
                    variant="outlined"
                    value={this.state.rotend}
                    onChange={this.onChangeDuration}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormGroup>
                  <FormControlLabel
                    // style={{ marginTop: "10px" }}
                    control={
                      <Checkbox
                        // style={{ marginTop: "-25px" }}
                        disableRipple
                        color="primary"
                        checked={this.state.showusername}
                        onChange={this.showUsername}
                        name="showusername"
                        id="showusername"
                      />
                    }
                    label="Show Username"
                  />
                  <FormControlLabel
                    // style={{ marginTop: "10px" }}
                    control={
                      <Checkbox
                        // style={{ marginTop: "-25px" }}
                        disableRipple
                        color="primary"
                        checked={this.state.motionblur}
                        onChange={this.showBlur}
                        name="motionblur"
                        id="showusername"
                      />
                    }
                    label="Motion Blur"
                  />
                </FormGroup>
              </FormField>
            </AnimationUI>
            <AnimationFrame ref={this.animationframe}>
           
              <AnimationContainer2
                duration={this.state.animationDuration}
                ref={this.tline}
                //  onWheel = {(e) => this.wheel(e)}
                className="tline">
                <ElementContainer2
                  ref={this.tline_sub}
                  className="tline_sub"
                  duration={this.state.animationDuration}>
                  {elements.next ? (
                    <LoadMoreSection cardheight={this.state.card_height}>
                      <Button
                        style={{
                          minWidth: "200px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.loadMoreElements(elements.next);
                        }}>
                        Load More Elements...
                      </Button>{" "}
                    </LoadMoreSection>
                  ) : (
                    ""
                  )}
                  {elements.allIDS.map((elementid, index) => {
                    let pos = "mid";
                    if (index === 0) {
                      pos = "first";
                    }
                    if (index === elements.allIDS.length - 1) {
                      pos = "last";
                    }
                    let sel = false;
                    if (index === this.state.selected_element) {
                      sel = true;
                    }
                    let lazyload = false;

                    // this.calcCardWidth(elements.byID[elementid].image_ratio, index, elements.byID[elementid].description, elements.byID[elementid].images, elements.byID[elementid].element_type)
                    return (
                      <Element
                        isOwner={this.state.isOwner}
                        pos={pos}
                        length={elements.allIDS.length}
                        // zoomlevel={this.state.value}
                        index={index}
                        id={elementid}
                        lazyload={lazyload}
                        project_url={this.state.project_url}
                        group_id={this.state.groupid}
                        allow_public_comments={
                          project.current_project.allow_public_comments
                        }
                        // comment_height = {this.state.comment_height}
                        selected={sel}
                        size={1000}
                        key={"element" + index}></Element>
                    );
                  })}
                  {project.current_project.project_state === "CO" ? (
                    <CompletedElement
                      projectid={project.current_project.id}
                      completed_at={
                        project.current_project.completed_at
                      }></CompletedElement>
                  ) : (
                    ""
                  )}
                </ElementContainer2>
              </AnimationContainer2>
             
            </AnimationFrame>
          </AnimationInside>
        </AnimationOutside>
      );
    } else {
      return (
        <div style={{ height: "100%", width: "100%", display: "flex", backgroundColor: "#252525" }}>
          {/* <CircularProgress style={{ margin: "auto" }} /> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  dimensions: state.dimensions,
  selected_element: state.project.selected_element,
  elements: state.project.current_project.elements,
  PositionArray: state.project.timeline.PositionArray,
  project_loading: state.project.loading,
  element_types: state.project.current_project.element_types,
  dialog_type: state.project.dialog_type,
  auth: state.auth,
  auth_user: state.auth.user,
  isMobile: state.dimensions.window_dimensions.isMobile,
  popup: state.project.popup,
  subscribers: state.project.current_project.subscribers,
  projectgroups: state.groups.current_group
  // following: state.auth.following
});
export default connect(mapStateToProps)(ProjectSlideShow);
