 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'HideAnnotations',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const HideAnnotations = (props) => {
  return (
      <SvgIcon {...props}>
   <path d="M2.5,4.4l6.3,6.3c-0.1,0.4-0.2,0.9-0.3,1.3H6.8c-0.4-1.2-1.5-2.1-2.8-2.1c-1.7,0-3,1.3-3,3s1.3,3,3,3
		c1.3,0,2.5-0.9,2.8-2.1h1.7c0.4,3.6,3.5,6.4,7.2,6.4c0.8,0,1.5-0.1,2.2-0.3l2.4,2.4l1.2-1.2L3.7,3.2L2.5,4.4z"/>
	<path d="M22.9,12.9c0-4-3.2-7.2-7.2-7.2c-2,0-3.8,0.8-5.1,2.2L20.7,18C22.1,16.7,22.9,14.9,22.9,12.9z"/>  </SvgIcon>
  );
};

export default HideAnnotations;