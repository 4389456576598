import { CONSTANTS } from "../actions";

const initialState = {
    // Query
    query: {},

    // Search data
    search: {
        loading: true,
        error: null,
        data: {
            count: 0,
            previus: null,
            next: null,
            results: [],
        },
        timestamp: new Date()
    },

    // Category data
    categories: {
        loading: true,
        error: null,
        data: {
            count: 0,
            previus: null,
            next: null,
            results: [],
        },
    },

    // Tags data
    tags: {
        loading: true,
        error: null,
        data: {
            count: 0,
            previus: null,
            next: null,
            results: [],
        },
    },
};

const methods = {
    // Query
    [CONSTANTS.SET_QUERY]: (state, action) => {
        return {
            ...state,
            query: action.payload,
        };
    },
    // Search Reducers
    [CONSTANTS.CLEAR_SEARCH_RESULTS]: (state, action) => {
        return {
            ...state,
            search: initialState.search
        };
    },
    [CONSTANTS.SET_SEARCH_LOADING]: (state, action) => {
        return {
            ...state,
            search: {
                ...state.search,
                loading: true,
            },
        };
    },
    [CONSTANTS.GET_SEARCH]: (state, action) => {
        const newData = action.payload;
        const ts = action.timestamp;

        if (ts <= state.search.timestamp) {
            return state;
        }

        let data = newData
        if (action.loadMore) {
            const results = state.search.data.results.concat(newData.results);
            data = {
                ...newData,
                results: results,
            }
        }

        return {
            ...state,
            search: {
                loading: false,
                error: null,
                data: data,
                timestamp: ts
            },
        };
    },

    // Categories Reducers
    [CONSTANTS.SET_CATEGORIES_LOADING]: (state, action) => {
        return {
            ...state,
            categories: initialState.categories
        };
    },
    [CONSTANTS.GET_CATEGORIES]: (state, action) => {
        return {
            ...state,
            categories: {
                loading: false,
                error: null,
                data: action.payload,
            }
        }
    }
}

export default function handle(state = initialState, action) {
    if (Object.keys(methods).includes(action.type)) {
        return methods[action.type](state, action)
    } else {
        return state
    }
}