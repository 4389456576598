import { CONSTANTS } from "../actions";
import { element_types_local } from "../config";
const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  myprofile: {
    show_first_use: false,
  },
  recentpages: [],
  notifications: {
    hideBadge: true,
    byID: {},
    allIDS: [],
    next: null,
    previous: null,
    count: 0,
  },
  element_types: element_types_local,
  project_categories: [],
  subscriptions: {
    projects: {
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
    },
    users: {
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
    },
    groups: {
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
    }
  },
  loading: true,
  validation_message: "",
  validation_state: "",
  reset_state: 0,
  ticket: null,
  plan: {},
  plan_details: {},
  plan_error: null
};

export default function userActions(state = initialState, action) {
  // TODO: These switch cases should be broken out into their own functions
  switch (action.type) {
    case CONSTANTS.UPDATE_TICKET:
      return {
        ...state,

        ticket: action.payload,
      };
    case CONSTANTS.SET_PLAN_LOADING:
      return {
        ...state,

        plan_details: { loading: true },
      };
    case CONSTANTS.REMOVE_PLAN_LOADING:
      return {
        ...state,

        plan_details: { loading: false },
      };
    case CONSTANTS.GET_PLAN:
      return {
        ...state,
        plan_details: action.payload.data,
      };
    case CONSTANTS.GET_PLAN_ERROR:
      return {
        ...state,
        plan_details: { loading: false },
        plan_error: action.payload,
      };
    case CONSTANTS.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    case CONSTANTS.SET_BANNER:
      // eslint-disable-next-line no-case-declarations
      let newprofile = { ...state.myprofile };
      newprofile.show_first_use = action.payload;
      return {
        ...state,
        myprofile: newprofile,
      };
    case CONSTANTS.GET_MY_CURRENT_PROFILE:
      return {
        ...state,
        loading: false,
        myprofile: action.payload.user,
        ticket: action.payload.ticket,
        usage: action.payload.usage,
        checks: action.payload.checks,
      };

    case CONSTANTS.GET_NOTIFICATIONS:
      //console.log(action.payload);
      // let newnotifications =  {...state.notifications};
      // eslint-disable-next-line no-case-declarations
      let newnotifications = {
        hideBadge: true,
        byID: {},
        allIDS: [],
        next: null,
        previous: null,
        count: 0,
      };
      //console.log(state.notifications);
      if (action.payload.results.length) {
        newnotifications.hideBadge = true;

        const Nots = action.payload.results;
        //console.log(Nots);
        Nots.forEach((Not, index) => {
          //console.log(index);
          if (Not.read === false) {
            newnotifications.hideBadge = false;
          }
          newnotifications.allIDS.push(Not.id);
          newnotifications.byID[Not.id] = Not;
        });
      } else {
        newnotifications.hideBadge = true;
        newnotifications.byID = {};
        newnotifications.allIDS = [];
      }
      newnotifications.next = action.payload.next;
      newnotifications.previous = action.payload.previous;
      newnotifications.count = action.payload.count;
      //console.log(newnotifications);
      return {
        ...state,
        notifications: newnotifications,
      };
    case CONSTANTS.LOAD_MORE_NOTIFICATIONS: {
      //console.log(action.payload);
      let newnotifications = { ...state.notifications };

      //console.log(state.notifications);
      if (action.payload.results.length) {
        newnotifications.hideBadge = true;

        const Nots = action.payload.results;
        //console.log(Nots);
        Nots.forEach((Not, index) => {
          //console.log(index);
          if (Not.read === false) {
            newnotifications.hideBadge = false;
          }
          newnotifications.allIDS.push(Not.id);
          newnotifications.byID[Not.id] = Not;
        });
      } else {
        newnotifications.hideBadge = true;
        newnotifications.byID = {};
        newnotifications.allIDS = [];
      }
      newnotifications.next = action.payload.next;
      newnotifications.previous = action.payload.previous;
      newnotifications.count = action.payload.count;
      //console.log(newnotifications);
      return {
        ...state,
        notifications: newnotifications,
      };
    }
    case CONSTANTS.SET_ELEMENT_TYPES_MENU:
      return {
        ...state,

        // element_types: action.payload.results,
      };
    case CONSTANTS.GET_RECENT_PAGES:
      return {
        ...state,

        recentpages: action.payload.results,
      };

    case CONSTANTS.SET_PROJECTS_FOLLOWING_LOADING:
      // eslint-disable-next-line no-case-declarations
      let new_following = { loading: true };

      return {
        ...state,

        following: new_following,
      };
    case CONSTANTS.GET_MY_PROJECTS_FOLLOWING: {
      let new_following = {
        byID: {},
        allIDS: [],
        count: action.payload.count,
        previous: action.payload.previous,
        next: action.payload.next,
        loading: false,
      };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_following.allIDS.push(project.id);
        new_following.byID[project.id] = project;
      });
      return {
        ...state,

        following: new_following,
      };
    }
    case CONSTANTS.LOAD_MORE_MY_PROJECTS_FOLLOWING: {
      //console.log("now loading more");
      let new_following = { ...state.projects };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_following.allIDS.push(project.id);
        new_following.byID[project.id] = project;
      });
      new_following.count = action.payload.count;
      new_following.previous = action.payload.previous;
      new_following.next = action.payload.next;
      return {
        ...state,
        following: new_following,
      };
    }
    case CONSTANTS.USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CONSTANTS.RESET_STATE:
      console.log("satet")
      return {
        ...state,
        reset_state: action.payload,
      };
    case CONSTANTS.GET_VALIDATION:
      return {
        ...state,
        validation_message: action.payload.email,
        validation_state: action.payload.state,
      };
    case CONSTANTS.SET_SUBSCRIPTIONS_LOADING: {
      const prop = action.payload
      let new_subscriptions = { ...state.subscriptions }
      new_subscriptions[prop] = { loading: true, }

      return {
        ...state,
        subscriptions: new_subscriptions
      }


    }

    // eslint-disable-next-line no-fallthrough
    case CONSTANTS.LOAD_MORE_SUBSCRIPTIONS: {
      const prop = action.payload.sub_type
      let new_subscriptions = { ...state.subscriptions }
      let prev_subs = state.subscriptions[prop].results;
      let new_subs = action.payload
      new_subs.results = prev_subs.concat(action.payload.results)
      new_subscriptions[prop] = { ...new_subs, loading: false, }

      return {
        ...state,
        subscriptions: new_subscriptions
      }


    }
    case CONSTANTS.GET_MY_SUBSCRIPTIONS: {
      const prop = action.payload.sub_type
      let new_subscriptions = { ...state.subscriptions }
      new_subscriptions[prop] = { ...action.payload, loading: false, }

      return {
        ...state,
        subscriptions: new_subscriptions
      }

    }
    case CONSTANTS.TOGGLE_SUBSCRIPTION: {
      console.log("toggle")
      const prop = action.payload.type
      let new_subscriptions = { ...state.subscriptions }

      let new_sub2 = [...state.subscriptions[prop].results]

      new_sub2.forEach((sub) => {
        if (action.payload.id === sub.id) {
          sub.subscribed = !sub.subscribed;
          return;
        }
      });
      return {
        ...state,

        subscriptions: new_subscriptions
      }



    }
    default:
      return state;
  }
}
