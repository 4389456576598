import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setBanner } from "../actions/uiActions";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
const Banner = styled.div`
  width: 100%;
  // min-height: 175px;
  // background-color: #383838;
  background: linear-gradient(180deg, #44A55E 0%, #2196F3 77.6%);
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  padding-top: 20px;
`;
const BannerContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 700px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  color: #383838;
  font-family: Merriweather;
  font-size: 30px;
`;
const BannerTitle = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  color: #fff;
  font-family: Merriweather;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
`;
const BannerDescr = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  color: #fff;
  font-style: italic;
  font-weight: 400;
  font-family: Merriweather;
  font-size: 18px;
  margin-bottom: 20px;
`;
const BannerButtons = styled.div`
  margin-left: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 250px;
`;
const Buttons = styled.div`
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: auto;
`;
const CloseButton = styled.div`
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: auto;
  margin-top: 10px;
`;
const Bannerleft = styled.div`
  margin-right: auto;
  margin-left: 20px;
  min-width: 250px;
  margin-top: auto;
`;

class FirstUseBanner extends Component {
  constructor() {
    super();
    this.state = {
      hidebanner: false,
      bannertype: 0,
    };
  }
  componentDidMount() {
    const urlparts = window.location.pathname.split("/");
    const loc = urlparts[1];
    switch (loc) {
      case "groups":
        this.setState({ bannertype: 1 });

        break;
      case "":
        this.setState({ bannertype: 2 });

        break;
      case "group":
        this.setState({ bannertype: 3 });

        break;
      case "project":
        this.setState({ bannertype: 4 });

        break;
      default:
        // user profile page
        this.setState({ bannertype: 0 });
        break;
    }
  }
  nextBanner(loc) {
    // window.location.href = loc;

    this.props.history.push(loc);
  }
  backBanner() {}
  dismissBanner() {
    const { username, email } = this.props;
    this.props.dispatch(setBanner(false, email, username));
  }
  doNothing(e) {
    e.stopPropagation();
  }

  render() {
    const { username, showBanner, isMobile } = this.props;
    const sampleproject = "/project/ZsMtwHhRYflMIXmQlPaQ7A/Example%20Project";
    const samplegroup =
      "/group/794bdb25a4204ba192980666cc56eea2/sequentially-alpha";
    if (showBanner) {
   return( <Banner isMobile={isMobile}>
    <Bannerleft></Bannerleft>
    <BannerContent>
      <BannerTitle>Welcome to Made.</BannerTitle>
      <BannerDescr>
       Thanks for signing up for a made account.
        <br />
        <br />
        <Link  to={"/support/"}  style={{ textDecoration: "none", color: "#fff" }}>Click here to read a high level overview of Made</Link>
        <br />
        <br />
        <Link  to={"/made/"}  style={{ textDecoration: "none", color: "#fff"}}>Click here to view some sample projects</Link>
       
      </BannerDescr>
    </BannerContent>{" "}
    <BannerButtons>
      <CloseButton>
         <IconButton
           style={{
           
         
            color: "#fff",
          }}
           onClick={() => {
             this.dismissBanner();
           }}
           size="large">
          <CloseRoundedIcon />
        </IconButton>
      </CloseButton>
      <Buttons>
       
        
        <Button
          disableElevation
          variant="contained"
          color="primary"
          style={{
            minWidth: "100px",
            backgroundColor: "#fff",
            color: "#383838",
          }}
          onClick={() => {
            this.dismissBanner();
          }}
        >
         Dismiss
        </Button>
      </Buttons>
    </BannerButtons>
  </Banner>)
    } else {
      return "";
    }
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.dimensions.window_dimensions.isMobile,
  username: state.auth.myprofile.username,
  email: state.auth.myprofile.email,
  showBanner: state.auth.myprofile.show_first_use,
});
export default connect(mapStateToProps)(withRouter(FirstUseBanner));
