import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setMenu, selectElementType } from "../../actions/uiActions";
import CommentContainer from "../CommentContainer";
import CommentNew from "../CommentNew";
import Update from "./Update";

import Next from "./Next";
import Question from "./Question";
import Dimension from "./Dimension";
import { updatePosition } from "../../actions/projectActions";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => props.vertical ? "0px" : "10px"};
`;
const ElementCardLineVertical = styled.div`
  width: calc(50% + 2px);
  height: 32px;
  border-right: 1px solid #008EFF;
 
`;
const TopContainer = styled.div`
  width: ${(props) => (props.containerwidth ? props.containerwidth : "300")}px;
  height: ${(props) => props.vertical ? "auto" : (props.cardheight ? props.cardheight + "px" : "300px")};
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;
const LineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  // background-color: #f2f2f2;
`;
const LineContainerLeft = styled.div`
  width: calc(
    50% + 1px
  ); // these added pixels are to try to align the dotted lines, but it is not working well when then line is more than 1px thick
  height: 100%;
  margin-left: -9px;
  // border-right: 1px solid #e3e3e3;
`;
const LineContainerRight = styled.div`
  width: 50%;
  height: 100%;
`;
const LineContainerTop = styled.div`
  width: 100%;
  height: 10px;

  border-bottom: 1px solid #008EFF;
`;
const BottomContainer = styled.div`
  width: 100%;
  // height: 300px;
`;
const ElementCard = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  background-color: #fff;
  border: ${(props) =>
    props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
    box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);
 
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const InnerContainer = styled.div`
  position: ${(props) =>
    props.vertical ? "relative" : "absolute"};;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;
const ElementCardLine = styled.div`
  width: calc(50% + 2px);
  height: 64px;
  
  margin-bottom: -10px;
`;
const ElementCardLineBottom = styled.div`
  margin-top: -45px;
  width: 50%;
  height: 60px;
  
`;
const DateBadge = styled.div`
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding: 8px;
  left: 5px;
  top: -5px;
  min-width: 60px;
  text-align: center;
  border-radius: 5px;

  background-color: #2196f3;
  color: #fff;
`;
const DateBadgeFirst = styled.div`
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  padding: 8px;
  right: 5px;
  top: -5px;
  min-width: 60px;
  text-align: center;
  border-radius: 5px;

  background-color: #2196f3;
`;
const LinkBadgeLeft = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  max-width: 35%;
  position: absolute;
  margin-left: 5px;
  margin-bottom: 37px;
  left: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;
const LinkBadge = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  max-width: 35%;
  position: absolute;
  margin-right: 8px;
  margin-bottom: 37px;
  right: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ElementBkg = styled.div`
 
  width: 100%;
  height: 100%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
//   cursor: pointer;
`;
const CompleteIcon = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 65px;
color: #F7CD00;
margin-left: auto;
 margin-right: auto;
`;
const CompetedTitle = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 18px;
color: #F7CD00;
margin-left: auto;
 margin-right: auto;
`;
const CompletedDate = styled.div`
display: flex;
flex-direction: column;
`;
const CompletedMonth = styled.div`
font-family: "Merriweather", serif;
font-weight: 900;
font-size: 24px;
color: #fff;
font-style: italic;
line-height: 60px;
margin-left: auto;
 margin-right: auto;
 margin-top: 30px;
`;
const CompletedDay = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 120px;
line-height: 100px;
color: #c4c4c4;
margin-left: auto;
 margin-right: auto;
`;
const CompletedYear = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 36px;
line-height: 60px;
letter-spacing: 0.37em;
color: #fff;
margin-left: auto;
 margin-right: auto;
 align-items: center;
text-align: center;
padding-left: 0.37em;
`;

const ButtonsContainer = styled.div`
margin-top: auto;
 margin-bottom: auto;
 display: flex;
 flex-direction: column;
 padding: 10px;
`;
const NewButton = styled.div`
    border-radius: 6px;
text-align: center;
padding: 10px;
 margin-left: auto;
 margin-right: auto;
  max-width: 200px;
//   min-height: 20px;
  background-color: #fff;
  margin-top: 10px;
  font-size: 14px;
 
`;
const PlusContainer = styled.div`
font-size: 30px;
 margin-left: auto;
 margin-right: auto;

 
`;
class CompletedElement extends Component {
  constructor() {
    super();
    this.state = {
      showEditIcon: false,
    };
  }
  newElementSelection = () => {
    // this.setState({ showElementTypes: true });
    // this.props.location = "/new/"+this.state.selectedprojectid
    this.props.dispatch(selectElementType(this.props.element_types.allIDS[0]));
    // this.props.dispatch(setMenu(false));
  
    this.props.history.push("/new/" + this.props.projectid);

    // this.props.history.push("/new/" + this.state.selectedprojectid);
  };

  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  setEditIcon = (newValue) => {
    this.setState({ showEditIcon: newValue });
  };
  handleEditElement = () => {};
  render() {
    const {
      zoomlevel,
      completed_at,
      element_types,
      isOwner,
      pos,
      id,
      element,
      card_height,
      dimensions,
      project_url,
      vertical,
          group_id,
    } = this.props;
    // console.log("isOwner" + isOwner)
    let minsize
    if (dimensions.window_dimensions.isMobile) {
      minsize = 70;
    } else {
      minsize = 120;
    }

    const cardmargin = 32;
    let containerwidth;
    
    if (zoomlevel > 50) {
      containerwidth = Math.round(card_height - 25);
    } else {
      containerwidth = Math.round(
        minsize + ((card_height - minsize - 25) * zoomlevel) / 50
      );
    }

    if (containerwidth < minsize) {
      // containerwidth = minsize/2
    }
    let cardwidth = containerwidth - cardmargin;
    if (vertical){
      containerwidth = 210
      cardwidth = 200
    }
    let showButton = true
    if (cardwidth < 160){
        showButton = false
    }
    var options = { month: "long" };
    const c_date = new Date(completed_at)
    const month = new Intl.DateTimeFormat("en-US", options).format(c_date);
    const year = c_date.getFullYear();
    const day = c_date.getDate();
    return (
      <ElementContainer vertical={vertical}>
        <div>
          <TopContainer
          vertical={vertical}
            cardheight={card_height}
            containerwidth={containerwidth}
          >
            <InnerContainer   vertical={vertical}>
             
                <ElementCard
                  cardwidth={cardwidth}
                //   onClick={() => {
                //     this.newElementSelection();
                //   }}
                >
                  <ElementBkg>
                      <ButtonsContainer>
                      {showButton ? ( <svg  style={{ position: "absolute", top: "5%", right: "5%", width: "15%", height: "15%" }} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EmojiEventsOutlinedIcon"><path fill="#F7CD00" d="M19 5h-2V3H7v2H5c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94.63 1.5 1.98 2.63 3.61 2.96V19H7v2h10v-2h-4v-3.1c1.63-.33 2.98-1.46 3.61-2.96C19.08 12.63 21 10.55 21 8V7c0-1.1-.9-2-2-2zM5 8V7h2v3.82C5.84 10.4 5 9.3 5 8zm7 6c-1.65 0-3-1.35-3-3V5h6v6c0 1.65-1.35 3-3 3zm7-6c0 1.3-.84 2.4-2 2.82V7h2v1z"></path></svg>
             ):("")}   
                                  {completed_at ? (
                                  <svg
                                  style={{ width: "100%", height: "100%" }}
                                  viewBox="0 0 800 800"
                                  // width="100%"
                                  // height="100%"
                                  xmlns="http://www.w3.org/2000/svg">
                                      
                                  <text
                                      letterSpacing="10"
                                      fontSize="40"
                                      fontFamily="Roboto"
                                      fill="#F7CD00"
                                      x="50%" y ="10%"
                                      dominantBaseline="middle" textAnchor="middle"
                                     >COMPLETE
                                  </text>
                                  <text
                                      letterSpacing="1.5"
                                      fontSize="50"
                                      fontStyle="italic"
                                      fontWeight="bold"
                                      fontFamily="Merriweather"
                                      fill="#fff"
                                      x="50%" y ="28%"
                                      dominantBaseline="middle" textAnchor="middle"
                                     >{month}
                                  </text>
                                  <text
                                      letterSpacing="1.5"
                                      fontSize="270"
                                      
                                      fill="#c4c4c4"
                                      x="50%" y ="52%"
                                      dominantBaseline="middle" textAnchor="middle"
                                     >{day}
                                  </text>
                                  <text
                                      letterSpacing="35"
                                      fontSize="75"
                                     
                                      fill="#fff"
                                      x="52%" y ="72%"
                                      dominantBaseline="middle" textAnchor="middle"
                                     >{year}
                                  </text>
                          </svg>

                         ):(
                            <svg
                            style={{ width: "100%", height: "100%" }}
                            viewBox="0 0 800 800"
                            // width="100%"
                            // height="100%"
                            xmlns="http://www.w3.org/2000/svg">
                            <text
                                letterSpacing="10"
                                fontSize="40"
                                fontFamily="Roboto"
                                fill="#F7CD00"
                                x="50%" y ="50%"
                                dominantBaseline="middle" textAnchor="middle"
                               >COMPLETE
                            </text>
                           
                    </svg>
                         )}
                    
                      
                     </ButtonsContainer>
                  </ElementBkg>
                </ElementCard>
           
                {vertical ? ("") :( <ElementCardLine></ElementCardLine>)}
            </InnerContainer>
          </TopContainer>
        </div>
        {vertical ? ( <ElementCardLineVertical></ElementCardLineVertical>):( <div> <LineContainer></LineContainer>
        <BottomContainer>
          <ElementCardLineBottom></ElementCardLineBottom>

          {/* <CommentContainer
            has_comments={element.has_comments}
            element_url={project_url + "/element/" + id}
            commentcount={commentcount}
            zerocomments={zerocomments}
            group_id={group_id}
            isOwner={isOwner}
            id={id}
            placement="timeline"
          ></CommentContainer> */}
        </BottomContainer></div>)}
       
      </ElementContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  dimensions: state.dimensions,
  zoomlevel: state.project.zoom.level,
  card_height: state.project.zoom.card_height,

  element_types: state.project.current_project.element_types,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(CompletedElement));
