import React, { Component } from "react";
import { api_endpoint } from "../config";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

import CommentContainer from "./CommentContainer";
import { selectElement } from "../actions/projectActions";
import { setDialogType } from "../actions/projectActions";
import { AnnotateCoordinates } from "../actions/uiActions";
import Dimension from "./elements/Dimension";
import Next from "./elements/Next";
import Text from "./elements/Text";
import Question from "./elements/Question";
import VideoPlayer from "./VideoPlayer";
import AudioPlayer from "./AudioPlayer";
import Draggable from "react-draggable";
import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import AnnotationSVG from "./ElementAnnotation";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import HideAnnotations from "../custom-icons/HideAnnotations";
import ShowAnnotations from "../custom-icons/ShowAnnotations";
import HideDrawing from "../custom-icons/HideDrawing";
import ShowDrawing from "../custom-icons/ShowDrawing";
import CloseIcon from "../custom-icons/CloseIcon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CompareIcon from "@mui/icons-material/Compare";
import ImageIcon from "@mui/icons-material/Image";

import Line from "./drawing/Line";
import Rect from "./drawing/Rect";
import Circ from "./drawing/Circ";
import SimpleGrid from "./drawing/Grid";
import GoldenGrid from "./drawing/GoldenGrid";
import PhiGrid from "./drawing/PhiGrid";
import AngleGrid from "./drawing/AngleGrid";

const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100% - 32px);
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "32px")};
  margin-top: ${(props) => (props.isMobile ? "-50px" : "32px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#fff" : "auto")};
`;
const ElementContainerDesktop = styled.div`
  width: 100%;
  height: calc(100% - 32px);
`;
const StyledG = styled.g`
  transform-box: fill-box;
  transform-origin: center center;
  transform: ${(props) => props.transformset};
`;
const ElementContainerWide = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-top: ${(props) => (props.isMobile ? "-50px" : "32px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#fff" : "auto")};
  margin-bottom: 10px;
  width: 100%;
  // min-width: 100%;
`;
const ElementContainerNarrow = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // margin-bottom: 10px;
  background-color: #fff;
  margin-left: px;
  width: 100%;
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
`;
const TopContainerNarrow = styled.div`
  width: 100%;
  // min-height: 100%;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const ArrowContainer = styled.div`
  display: flex;
  height: calc(100vh - 64px);
  // height: ${(props) => (props.arrowheight ? props.arrowheight : "300")}px;
`;

const ElementCard = styled.div`
  width: calc(100vw - 64px);
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: 32px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;
const ElementCardWide = styled.div`
  width: calc(100% - 64px);
  min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // margin-left: 5px;
  // margin-right: 5px;
  // margin-top: 4px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
`;
const WideBottom = styled.div`
  margin: 32px;
  height: 100%;
`;
const ElementCardNarrow = styled.div`
  width: calc(100% + 1px);
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  // min-height: calc(100% + 200px);
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 5px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: -50px;
  // margin-bottom: -50px;
  height: calc(100% + 50px);
  display: flex;
  flex-direction: column;
`;
const ElementLeft = styled.div`
  width: calc(100% - 288px);

  display: flex;
  flex-direction: column;
`;
const ElementRight = styled.div`
  display: flex;
  flex-direction: column;
  // border-left: 1px solid #f2f2f2;
  min-width: 288px;
  max-width: 288px;
  margin-left: 32px;
  max-height: 100%;
  // background-color: #ccc;
`;
const ElementDescriptionRight = styled.div`
font-family: 'Merriweather';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 200%;
 text-align: left;
 max-width: 288px;
 margin-top: 8px;
 margin-bottom: 8px;
//  border-top: 1px solid rgba(128,128,128,0.25);
//  border-bottom: 1px solid rgba(128,128,128,0.25);
//  max-height: 168px;
  // max-height: calc(50vh-80px);
  max-height: 50%;
    overflow-y: auto;
    overflow-x: clip;
    margin-bottom: -4px;
    flex-shrink: 1;
}
`;

const ElementImageAnnotation = styled.div`
  // box-sizing: border-box;
  height: 100%;
  margin-top: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-left: 0px;
  // border: 8px solid #fff;
  display: flex;
  flex-direction: column;
`;
const ImageContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? "auto" : "calc(100vh - 160px)")};

  background-color: ${(props) => (props.isMobile ? "#000" : "#000")};
  display: flex;
`;
const OtherContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? "auto" : "calc(100vh - 160px)")};

  background-color: ${(props) => (props.isMobile ? "#000" : "#f2f2f2")};
  display: flex;
`;
const AnnotationWrapper = styled.div`
  position: relative;
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const ElementImageAnnotationToolbar = styled.div`
  display: flex;
  min-height: 32px;
  width: 100%;
`;
const AnnotationLayer = styled.div`
  position: absolute;
  //  background-color: rgba(245,160,160,0.5);
  // width: ${(props) => props.w}px;
  // height: ${(props) => props.h}px;
  width: 100%;
  height: 100%;
  // width: 100%;
  // height: calc(100% - 4px);
  z-index: 1000;
  cursor: ${(props) => (props.annotationMode ? "crosshair" : "auto")};
`;
const Annotation = styled.div`
  position: absolute;
  left: calc(${(props) => props.x} - 70px);
  top: calc(${(props) => props.y} - 70px);
  opacity: 1;
  cursor: default;
  pointer-events: none;
`;

const DimensionContainer = styled.div`
  // box-sizing: border-box;

  width: ${(props) => props.w}px;
  height: ${(props) => props.w}px;
  margin: auto;
`;
const DimensionCenter = styled.div`
  // box-sizing: border-box;

  // border: 8px solid #fff;
  // margin-top: 60px;

  margin: auto;
  background-color: #d8d8d8;
`;
const ElementDescription = styled.div`
  font-family: "Alegreya", serif;
  // font-family: adobe-garamond-pro, serif;
  font-size: 18px;
  max-width: 800px;
  // height: 100%;
  padding: 20px;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
  color: #111;
`;

// const ElementCardLine = styled.div`
//   width: 50%;
//   height: 10px;

//   border-right: 2px dashed #e3e3e3;
// `;
const StyledIconButton = styled.div`
  font-size: 32px;
  margin-top: auto;
  margin-bottom: auto;
  color: #fff;
  min-width: 32px;
  max-height: 50px;
  cursor: pointer;
`;
const StyledIconButtonNarrow = styled.div`
  font-size: 30px;
  margin-top: 21px;
  margin-left: 16px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const CloseButtonNarrow = styled.div`
  font-size: 24px;
  margin-top: 5px;
  // margin-left: auto;
  margin-right: 5px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const EditButtonNarrow = styled.div`
  font-size: 18px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 20px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const EditButton = styled.div`
  font-size: 16px;
  margin-top: -10px;
  margin-left: auto;
  margin-right: 0px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const ExpandIcon = styled.div`
  font-size: 22px;
  margin-top: -10px;
  margin-left: -12px;
  margin-right: 10px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const LeftButtons = styled.div`
  display: flex;
  margin-top: 10px;
`;
const RightButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
  min-height: 32px;
`;
const RightButtonsWide = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
  min-height: 32px;
`;
const CloseButton = styled.div`
  font-size: 20px;
  margin-top: -13px;
  margin-left: 5px;
  margin-right: -13px;
  color: #111;
  min-width: 20px;
  // max-height: 20px;
  cursor: pointer;
`;
const CloseButtonWide = styled.div`
  font-size: 20px;
  margin-top: -13px;
  margin-left: 5px;
  margin-right: 52px;
  color: #111;
  min-width: 20px;
  // max-height: 20px;
  cursor: pointer;
`;
const ElementHeader = styled.div`
  display: flex;
`;
const ElementType = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  width: 100%;
`;
const BottomMobile = styled.div`
  margin: 32px;
`;
const SVGContent = styled.svg`
  z-index: 805;
  // mix-blend-mode: overlay;
  position: absolute;
  visibility: ${(props) => (props.hide ? "auto" : "hidden")};
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const MobileDescription = styled.div`
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  text-align: left;
  margin-bottom: 32px;
`;
const ElementEdit = styled.div`
  margin-left: auto;
  margin-top: -8px;
`;
const ComponentLeftWrapper = styled.div`
  margin-top: ${(props) => (props.isMobile ? "60px" : "auto")};
`;
const MobileHeader = styled.div`
  height: 60px;
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 10000;
  opacity: 0.9;
`;
const WideHeader = styled.div`
  height: 32px;
  width: 100%;
  margin-top: 32px;
  margin-left: 32px;
  top: 0;
  display: flex;
  z-index: 10000;
`;
const LinkBadge = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;

  // position: absolute;

  margin-top: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #e4e3e3;
  border-top: 1px solid #e4e3e3;

  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
`;
const PreviewContentCompare = styled.div`
  flex-direction: row;
  position: relative;

  background-color: #383838;
  width: 100%;
  height: ${(props) =>
    props.isMobile
      ? props.isOverlay === "OV"
        ? props.h
        : "auto"
      : props.expandImage
      ? props.isOverlay === "OV"
        ? props.h2
        : "auto"
      : "calc(100vh - 160px)"};
  background-color: #000;
  display: flex;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const CompareWrapper = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  // clip-path: ${(props) =>
    "polygon(0 0 , " + props.clip + "% 0, " + props.clip + "% 100%, 0 100%)"};
  // clip-path: ${(props) =>
    props.type === "SS"
      ? "none"
      : "polygon(0 0 , " +
        props.clip +
        "% 0, " +
        props.clip +
        "% 100%, 0 100%)"};;
`;
const CompareWrapperRight = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  clip-path: ${(props) =>
    "polygon(" +
    props.clip +
    "% 0 , 100% 0, 100% 100%, " +
    props.clip +
    "% 100%)"};
`;
const FirstImage = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.w};
  height: ${(props) => props.h};

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #ccc;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SVGWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;

const SecondImage = styled.div`
  position: relative;
  width: ${(props) => props.w};
  display: flex;
  height: ${(props) => props.h};
  transform: ${(props) =>
    "translateX(" +
    props.translate_x / 10 +
    "%) scale(" +
    (1 + props.scale / 100) +
    ") rotate(" +
    props.rotate +
    "deg) translateY(" +
    props.translate_y / 10 +
    "%)"};
  opacity: ${(props) => props.opacity / 100};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #f4f2f2;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const OverlayCorrection = styled.div`
  position: relative;
  width: ${(props) => props.w}px;
  display: flex;
  height: ${(props) => props.h}px;

  margin: auto;
  overflow: hidden;
`;
const NoImageSelected = styled.div`
  margin: auto;
`;
const ImageLabel = styled.div`
  font-family: Merriweather;
  font-size: 128px;
  font-weight: 400;
  line-height: 161px;
  letter-spacing: 0em;
  text-align: center;
  color: #dadada;
`;
const ImageButton = styled.div``;
const DividerLine = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  margin-top: auto;
  background-color: #d9d9d9;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const VSBadge = styled.div`
  font-family: Roboto;
  z-index: 1;
  line-height: 40px;

  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #383838;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  left: -20px;
  top: calc(50% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  background-color: #d9d9d9;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SliderHandleContainer = styled.div`
  height: 100%;
  width: 30px;
  z-index: 806;
  position: absolute;
  left: 50%;
  // background-color: #ffffff;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
`;
const CompareToggle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-12px")};

  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: 20px;
`;
const SliderCircle = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  place-content: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  pointer-events: auto;
  backdrop-filter: blur(7px);
  //     position: absolute;
  // top: 50%;
  margin-left: -14px;
  box-shadow: 0 0 7px rgba(0;0;0;.35);
  // transform: portrait ? rotate(90deg) : undefined;
`;
const SliderArrow2 = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;
  transform: rotate(180deg);
  color: #ffffff;
`;
const SliderArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;

  color: #ffffff;
`;
const SliderHandle = styled.div`
  // flexGrow: 1;
  height: 50%;
  width: 2px;
  background-color: #ffffff;
  pointer-events: auto;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

  margin-left: 14px;

  // position: absolute;
  // left: 50%;
`;
class ElementPopup extends Component {
  constructor() {
    super();
    this.svgRef = React.createRef();
    this.svgRefB = React.createRef();
    this.nodeRefA = React.createRef(null);
    this.bkgImage = React.createRef();
    this.myRef = React.createRef();
    this.state = {
      first: false,
      last: false,
      expandImage: false,
      touchLoc: 0,
      touchLocY: 0,
      showAnnotations: true,
      showDrawing: true,
      compareSxS: true,
      imageAHeight: 1,
      imageAWidth: 1,
      image_ratio: 1,
      imageBHeight: 1,
      imageBWidth: 1,
      image_ratioB: 1,
      nonImageSquare: 1,
      currentElementID: 1,
      objects: [],
      imageAloaded: false,
      imageBloaded: false,
      clip: 50,
      comparedrag: false,
      maxW: 0,
      scale_b: 0,
      translate_x_b: 0,
      translate_y_b: 0,
      rotate_b: 0,
      opacity_b: 100,
      comparison_type: "SS",
    };
    // this.alertMe = this.alertMe.bind(this);
  }
  HandleImageLoaded = (number) => {
    // console.log("loaded");
    if (number === 1) {
      this.setState({ imageAloaded: true });
    } else {
      this.setState({ imageBloaded: true });
    }
  };
  alertMe = () => {
    console.log("alert................");
  };
  onChangeToggleComparison = () => {
    if (this.state.comparison_type === "OV") {
      this.setState({ compareSxS: true });
      this.setState({ clip: 0 });
      this.setState({ comparison_type: "SS" });
    } else {
      this.setState({ compareSxS: false });
      this.setState({ clip: 50 });
      this.setState({ comparison_type: "OV" });
    }
  };
  handleDragSlider = (e, ui) => {
    let clip = (ui.x + this.state.maxW / 2) / (this.state.maxW / 100);
    // console.log(this.state.maxW, this.state.clip);
    this.setState({ clip: clip });
  };
  handleStartSlider = (e, ui) => {
    this.setState({ comparedrag: true });
  };
  handleStopSlider = (e, ui) => {
    this.setState({ comparedrag: false });
  };
  handleTouchStart(touchStartEvent) {
    //console.log(touchStartEvent.targetTouches[0].clientX);

    if (!this.state.comparedrag) {
      this.setState({ touchLoc: touchStartEvent.targetTouches[0].clientX });
      this.setState({ touchLocY: touchStartEvent.targetTouches[0].clientY });
    }
  }
  ImageLoaded({ target: img }) {
    console.log(img.offsetHeight);
    console.log(img.offsetWidth);
    this.setState({ image_ratio: img.offsetWidth / img.offsetHeight });
  }
  handleTouchEnd(touchEndEvent) {
    //console.log("end");
    //console.log(touchEndEvent.changedTouches[0].clientX - this.state.touchLoc);
    const { dimensions, annotationMode, element, element_ids } = this.props;

    let Nav = true;
    if (element.element_type === "CO" && this.state.comparison_type === "OV") {
      // Nav = false
    }
    if (this.state.comparedrag) {
      Nav = false;
    }
    if (
      Math.abs(touchEndEvent.changedTouches[0].clientY - this.state.touchLocY) <
      20
    ) {
      if (touchEndEvent.changedTouches[0].clientX - this.state.touchLoc > 50) {
        if (Nav) {
          this.navigateElement("previous");
        }
      } else {
        if (
          touchEndEvent.changedTouches[0].clientX - this.state.touchLoc <
          -50
        ) {
          if (Nav) {
            this.navigateElement("next");
          }
        }
      }

      this.setState({ touchLoc: 0 });
      this.setState({ touchLocY: 0 });
    }
  }
  handleClosePopup(target) {
    this.props.history.push(target);
  }
  handleClickNavigate(posX) {
    const { dimensions, annotationMode, element, element_ids } = this.props;

    let Nav = true;
    // if (element.element_type === "CO" && this.state.comparison_type === "OV") {
    //   Nav = false
    // }
    if (this.state.comparedrag) {
      Nav = false;
    }
    if (!annotationMode && Nav) {
      if (posX < dimensions.window_dimensions.w / 2 - 50) {
        // prev
        if (!this.state.first && element_ids.length > 1) {
          this.navigateElement("prev");
        }
      }
      if (posX > dimensions.window_dimensions.w / 2 + 50) {
        // prev
        if (!this.state.last && element_ids.length > 1) {
          this.navigateElement("next");
        }
      }
    }
  }
  handleEdit(id) {
    // this.props.history.push("/edit/" + id);
    this.props.dispatch(setDialogType("edit"));
  }
  expandImage() {
    const { element } = this.props;
    this.setState({ expandImage: true });
    if (element.element_type === "CO") {
      this.setImageDimensions();
      if (element.metadata.compare_type === "OV") {
        this.setState({ clip: 50 });
      }
    }
  }
  toggleAnnotations() {
    if (this.state.showAnnotations) {
      this.setState({ showAnnotations: false });
    } else {
      this.setState({ showAnnotations: true });
    }
  }
  toggleDrawing() {
    if (this.state.showDrawing) {
      this.setState({ showDrawing: false });
    } else {
      this.setState({ showDrawing: true });
    }
  }
  OpenImageNewTab(imageid) {
    const TargetUrl = api_endpoint + "/media/" + imageid + "/file";
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.setAttribute("crossOrigin", "");
    const svgimage = new Image();
    svgimage.crossOrigin = "anonymous";
    svgimage.setAttribute("crossOrigin", "");
    let imagesrc = TargetUrl;

    var svgElement = new XMLSerializer().serializeToString(this.svgRef.current);
    let loaded = 0;

    // image.src = imagesrc;
    function drawImages(showDrawing) {
      if (loaded === 2) {
        if (showDrawing) {
          let canvas = document.createElement("canvas");
          // Create canvas context.
          let ctx = canvas.getContext("2d");

          canvas.width = image.width;
          canvas.height = image.height;
          ctx.drawImage(image, 0, 0);

          ctx.drawImage(svgimage, 0, 0);

          var d = canvas.toDataURL("image/png");
          var link = document.createElement('a');
          link.download = 'Made_Image.png';
          link.href = d
          link.click();
         
        } else {

          var link = document.createElement('a');
          link.download = 'Made_Image.png';
          link.href = TargetUrl
          link.click();
          // var w = window.open("about:blank", "imagefromcanvas");
          // w.document.write(
          //   "<body>Right click on image to save<br/><img src='" +
          //     TargetUrl +
          //     "' alt='from canvas'/></body>"
          // );
        }
      }
    }
    image.onload = () => {
      loaded++;
      drawImages(this.state.showDrawing);
    };
    image.src = imagesrc;
    if (this.state.showDrawing) {
      svgimage.onload = () => {
        loaded++;
        drawImages(this.state.showDrawing);
      };
      svgimage.src =
        "data:image/svg+xml; charset=utf8, " + encodeURIComponent(svgElement);
    } else {
      loaded++;
    }

    // window.open(TargetUrl, '_blank');
  }
  annotateImage(e, imagedimensions) {
    if (this.props.annotationMode) {
      const xpos = (e.clientX - imagedimensions.left) / imagedimensions.width;
      const ypos = (e.clientY - imagedimensions.top) / imagedimensions.height;
      this.props.dispatch(AnnotateCoordinates(xpos, ypos));
      this.props.dispatch(AnnotateCoordinates(xpos, ypos));
    }
  }
  contractImage() {
    const { element } = this.props;
    this.setState({ expandImage: false });
    if (element.element_type === "CO") {
      this.setImageDimensions();
      if (element.metadata.compare_type === "OV") {
        this.setState({ clip: 50 });
      }
    }
  }
  keyPress = (e) => {
    if (e.keyCode === 39) {
      this.navigateElement("next");
    }
    if (e.keyCode === 37) {
      this.navigateElement("previous");
    }
  };
  setImageDimensions() {
    const { dimensions } = this.props;
    let maxW, maxH;

    if (dimensions.window_dimensions.isMobile) {
      maxW = dimensions.window_dimensions.w - 0;

      maxH = dimensions.window_dimensions.h * 100;
    } else {
      if (this.state.expandImage) {
        maxW = dimensions.window_dimensions.w - 80;
        maxH = dimensions.window_dimensions.h * 150;
      } else {
        maxW = dimensions.window_dimensions.w - 448;
        maxH = dimensions.window_dimensions.h - 150;
      }
    }
    if (this.state.compareSxS) {
      maxW = maxW / 2;
    }
    let image_w_a;
    let image_h_a;
    const image_r_a = this.state.image_ratio;
    let image_w_b;
    let image_h_b;
    const image_r_b = this.state.image_ratioB;

    // console.log("image_r_a",image_r_a)
    // console.log("image_r_b",image_r_b)
    if (image_r_a > 1) {
      // landscape
      if (maxW / image_r_a > maxH) {
        image_w_a = "auto";
        image_h_a = "100%";
      } else {
        image_w_a = "100%";
        image_h_a = "auto";
      }
    } else {
      // portrait

      if (maxH * image_r_a > maxW) {
        image_w_a = "100%";
        image_h_a = "auto";
      } else {
        image_w_a = "auto";
        image_h_a = "100%";
      }
    }
    // console.log("image dimensions")
    if (image_r_b < image_r_a) {
      // console.log("A")
      image_w_b = "auto";
      image_h_b = "100%";
    } else {
      // console.log("B")
      image_w_b = "100%";
      image_h_b = "auto";
    }
    // if (image_r_b > 1) {
    //   // landscape
    //   if (maxW / image_r_b > this.bkgImage.current.getBoundingClientRect().height) {
    //     image_w_b = "auto";
    //     image_h_b = "100%";
    //   } else {
    //     image_w_b = "100%";
    //     image_h_b = "auto";
    //   }
    // } else {
    //   // portrait
    //   if (maxH * image_r_b > this.bkgImage.current.getBoundingClientRect().width) {
    //     image_w_b = "100%";
    //     image_h_b = "auto";
    //   } else {
    //     image_w_b = "auto";
    //     image_h_b = "100%";
    //   }
    // }
    if (this.state.imageAHeight !== image_h_a) {
      this.setState({ imageAHeight: image_h_a });
    }
    if (this.state.imageAWidth !== image_w_a) {
      this.setState({ imageAWidth: image_w_a });
    }
    if (this.state.imageBHeight !== image_h_b) {
      this.setState({ imageBHeight: image_h_b });
    }
    if (this.state.imageBWidth !== image_w_b) {
      this.setState({ imageBWidth: image_w_b });
    }
    if (this.state.maxW !== maxW) {
      this.setState({ maxW: maxW });
    }
    if (this.bkgImage) {
      if (this.bkgImage.current) {
        if (
          this.state.overlayW !==
          this.bkgImage.current.getBoundingClientRect().width
        ) {
          this.setState({
            overlayW: this.bkgImage.current.getBoundingClientRect().width,
          });
        }
        if (
          this.state.overlayH !==
          this.bkgImage.current.getBoundingClientRect().height
        ) {
          this.setState({
            overlayH: this.bkgImage.current.getBoundingClientRect().height,
          });
        }
      }
    }
  }
  componentDidMount() {
    const { dimensions, element, id } = this.props;
    // console.log("id", id)
    this.setState({ currentElementID: element.id });
    if (dimensions.window_dimensions.w < 1025) {
      this.setState({ expandImage: true });
    }
    if (element.element_type === "CO") {
      if (element.metadata.compare_type === "SS") {
        this.setState({ compareSxS: true });
      } else {
        this.setState({ compareSxS: false });
      }
    } else {
      this.setState({ compareSxS: false });
    }

    if (element.metadata) {
      if (element.metadata.compare_type) {
        if (element.metadata.compare_type === "SS") {
          this.setState({ clip: 0 });
          this.setState({ compareSxS: true });
        } else {
          this.setState({ clip: 50 });
          this.setState({ compareSxS: false });
        }
      }
    }

    this.setState({
      comparison_type: element.metadata
        ? element.metadata.compare_type
          ? element.metadata.compare_type
          : "SS"
        : "SS",
    });
    this.setState({
      scale_b: element.metadata
        ? element.metadata.resize
          ? element.metadata.resize
          : 0
        : 0,
    });
    this.setState({
      translate_x_b: element.metadata
        ? element.metadata.horizontal
          ? element.metadata.horizontal
          : 0
        : 0,
    });
    this.setState({
      translate_y_b: element.metadata
        ? element.metadata.vertical
          ? element.metadata.vertical
          : 0
        : 0,
    });
    this.setState({
      rotate_b: element.metadata
        ? element.metadata.rotate
          ? element.metadata.rotate
          : 0
        : 0,
    });
    this.setState({
      opacity_b: element.metadata
        ? element.metadata.opacity
          ? element.metadata.opacity
          : 100
        : 100,
    });
    this.setState({
      objects: element.metadata
        ? element.metadata.drawing
          ? element.metadata.drawing
          : []
        : [],
    });

    document.addEventListener("keydown", this.keyPress);
    if (element.images.length > 0) {
      if (element.element_type === "CO" && element.metadata.image_order) {
        // use image order for src
        if (element.metadata.image_order[0] === element.images[0].id) {
          this.setState({
            imageAid: element.images[0].id,
          });
          this.setState({
            imageBid: element.images[1].id,
          });
        } else {
          this.setState({
            imageAid: element.images[1].id,
          });
          this.setState({
            imageBid: element.images[0].id,
          });
        }
        this.setState({
          image:
            api_endpoint +
            "/media/" +
            element.metadata.image_order[0] +
            "/file",
        });

        this.setState({
          image_largest:
            api_endpoint +
            "/media/" +
            element.metadata.image_order[0] +
            "/file",
        });

        const image = new Image();

        image.crossOrigin = "anonymous";
        image.setAttribute("crossOrigin", "");

        image.src =
          api_endpoint +
          "/media/" +
          element.metadata.image_order[0] +
          "/file?size=256";
        // image.src = '/testimages/1x/610.jpg'

        image.onload = () => {
          this.setState({ image_ratio: image.width / image.height });
          this.setImageDimensions();
        };

        this.setState({
          imageB:
            api_endpoint +
            "/media/" +
            element.metadata.image_order[1] +
            "/file",
        });

        this.setState({
          image_largestB:
            api_endpoint +
            "/media/" +
            element.metadata.image_order[1] +
            "/file",
        });

        const imageB = new Image();

        imageB.crossOrigin = "anonymous";
        imageB.setAttribute("crossOrigin", "");

        imageB.src =
          api_endpoint +
          "/media/" +
          element.metadata.image_order[1] +
          "/file?size=256";
        // image.src = '/testimages/1x/610.jpg'

        imageB.onload = () => {
          this.setState({ image_ratioB: imageB.width / imageB.height });
          this.setImageDimensions();
        };
      } else {
        // use first image only
        if (element.images[0].mime_type.split("/")[0] === "video") {
          this.setState({ isVideo: true });
          this.setState({
            image: api_endpoint + "/media/" + element.images[0].id + "/file",
          });
        } else {
          this.setState({
            image: api_endpoint + "/media/" + element.images[0].id + "/file",
          });
        }
        this.setState({
          image_largest:
            api_endpoint + "/media/" + element.images[0].id + "/file",
        });

        const image = new Image();

        image.crossOrigin = "anonymous";
        image.setAttribute("crossOrigin", "");

        image.src =
          api_endpoint + "/media/" + element.images[0].id + "/file?size=256";
        // image.src = '/testimages/1x/610.jpg'

        image.onload = () => {
          console.log("width:" + image.width);
          console.log("height:" + image.height);
          this.setState({ image_ratio: image.width / image.height });
          this.setImageDimensions();
        };
      }
    } else {
      // determine size for non image containers
      let sq;
      if (dimensions.window_dimensions.isMobile) {
        sq = dimensions.window_dimensions.w - 0;
      } else {
        sq = dimensions.window_dimensions.w - 448;
        if (sq > dimensions.window_dimensions.h - 160) {
          sq = dimensions.window_dimensions.h - 160;
        }
      }
      if (this.state.nonImageSquare !== sq) {
        // console.log("sq", sq);
        this.setState({ nonImageSquare: sq });
      }
    }
  }
  componentDidUpdate() {
    if (this.props.annotationMode) {
      if (this.state.showAnnotations === false) {
        this.setState({ showAnnotations: true });
      }
    }
    const { dimensions, element } = this.props;

    if (element.id !== this.state.currentElementID) {
      this.setState({
        objects: element.metadata
          ? element.metadata.drawing
            ? element.metadata.drawing
            : []
          : [],
      });
      this.setState({ currentElementID: element.id });
      if (element.element_type === "CO") {
        if (element.metadata.compare_type === "SS") {
          this.setState({ compareSxS: true });
        } else {
          this.setState({ compareSxS: false });
        }
      } else {
        this.setState({ compareSxS: false });
      }
      if (element.element_type === "CO") {
        if (element.metadata) {
          if (element.metadata.compare_type) {
            if (element.metadata.compare_type === "SS") {
              this.setState({ clip: 0 });
              this.setState({ compareSxS: true });
            } else {
              this.setState({ clip: 50 });
              this.setState({ compareSxS: false });
            }
          }
        }

        this.setState({
          comparison_type: element.metadata
            ? element.metadata.compare_type
              ? element.metadata.compare_type
              : "SS"
            : "SS",
        });
        this.setState({
          scale_b: element.metadata
            ? element.metadata.resize
              ? element.metadata.resize
              : 0
            : 0,
        });
        this.setState({
          translate_x_b: element.metadata
            ? element.metadata.horizontal
              ? element.metadata.horizontal
              : 0
            : 0,
        });
        this.setState({
          translate_y_b: element.metadata
            ? element.metadata.vertical
              ? element.metadata.vertical
              : 0
            : 0,
        });
        this.setState({
          rotate_b: element.metadata
            ? element.metadata.rotate
              ? element.metadata.rotate
              : 0
            : 0,
        });
        this.setState({
          opacity_b: element.metadata
            ? element.metadata.opacity
              ? element.metadata.opacity
              : 100
            : 100,
        });
        this.setState({
          objects: element.metadata
            ? element.metadata.drawing
              ? element.metadata.drawing
              : []
            : [],
        });
      }
      if (element.images.length > 0) {
        if (element.element_type === "CO" && element.metadata.image_order) {
          // use image order for src
          if (element.metadata.image_order[0] === element.images[0].id) {
            this.setState({
              imageAid: element.images[0].id,
            });
            this.setState({
              imageBid: element.images[1].id,
            });
          } else {
            this.setState({
              imageAid: element.images[1].id,
            });
            this.setState({
              imageBid: element.images[0].id,
            });
          }
          this.setState({
            image:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[0] +
              "/file",
          });

          this.setState({
            image_largest:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[0] +
              "/file",
          });

          const image = new Image();

          image.crossOrigin = "anonymous";
          image.setAttribute("crossOrigin", "");

          image.src =
            api_endpoint +
            "/media/" +
            element.metadata.image_order[0] +
            "/file?size=256";
          // image.src = '/testimages/1x/610.jpg'

          image.onload = () => {
            // console.log("width:" + image.width);
            // console.log("height:" + image.height);
            this.setState({ image_ratio: image.width / image.height });
            this.setImageDimensions();
          };

          this.setState({
            imageB:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[1] +
              "/file",
          });

          this.setState({
            image_largestB:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[1] +
              "/file",
          });

          const imageB = new Image();

          imageB.crossOrigin = "anonymous";
          imageB.setAttribute("crossOrigin", "");

          imageB.src =
            api_endpoint +
            "/media/" +
            element.metadata.image_order[1] +
            "/file?size=256";
          // image.src = '/testimages/1x/610.jpg'

          imageB.onload = () => {
            // console.log("width:" + imageB.width);
            // console.log("height:" + imageB.height);
            this.setState({ image_ratioB: imageB.width / imageB.height });
            this.setImageDimensions();
          };
        } else {
          // use first image only
          if (element.images[0].mime_type.split("/")[0] === "video") {
            this.setState({ isVideo: true });
            this.setState({
              image: api_endpoint + "/media/" + element.images[0].id + "/file",
            });
          } else {
            this.setState({
              image: api_endpoint + "/media/" + element.images[0].id + "/file",
            });
          }
          this.setState({
            image_largest:
              api_endpoint + "/media/" + element.images[0].id + "/file",
          });

          const image = new Image();

          image.crossOrigin = "anonymous";
          image.setAttribute("crossOrigin", "");

          image.src =
            api_endpoint + "/media/" + element.images[0].id + "/file?size=256";
          // image.src = '/testimages/1x/610.jpg'

          image.onload = () => {
            // console.log("width:" + image.width);
            // console.log("height:" + image.height);
            this.setState({ image_ratio: image.width / image.height });
            this.setImageDimensions();
          };
        }
      } else {
        // determine size for non image containers
        let sq;
        if (dimensions.window_dimensions.isMobile) {
          sq = dimensions.window_dimensions.w - 0;
        } else {
          sq = dimensions.window_dimensions.w - 448;
          if (sq > dimensions.window_dimensions.h - 160) {
            sq = dimensions.window_dimensions.h - 160;
          }
        }
        if (this.state.nonImageSquare !== sq) {
          console.log("sq", sq);
          this.setState({ nonImageSquare: sq });
        }
      }
    } else {
      if (element.images[0]) {
        // console.log(this.state.image_ratioB);
        this.setImageDimensions();
      } else {
        // determine size for non image containers
        let sq;
        if (dimensions.window_dimensions.isMobile) {
          sq = dimensions.window_dimensions.w - 0;
        } else {
          sq = dimensions.window_dimensions.w - 448;
          if (sq > dimensions.window_dimensions.h - 160) {
            sq = dimensions.window_dimensions.h - 160;
          }
        }
        if (this.state.nonImageSquare !== sq) {
          console.log("sq", sq);
          this.setState({ nonImageSquare: sq });
        }
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }
  navigateElement = (direction) => {
    const { id, element_ids } = this.props;
    const loc = element_ids.indexOf(id);
    this.setState({ showAnnotations: true });
    this.setState({ clip: 50 });
    let nextlink = "";
    let prevlink = "";
    if (loc === 0) {
      nextlink = element_ids[loc + 1];
      prevlink = "";
    } else {
      if (loc === element_ids.length - 1) {
        nextlink = "";
        prevlink = element_ids[loc - 1];
      } else {
        nextlink = element_ids[loc + 1];
        prevlink = element_ids[loc - 1];
      }
    }
    if (direction === "next") {
      if (nextlink !== "") {
        this.props.history.replace({ pathname: nextlink });
        this.props.dispatch(selectElement(nextlink));
      }
    } else {
      if (prevlink !== "") {
        this.props.history.replace({ pathname: prevlink });
        this.props.dispatch(selectElement(prevlink));
      }
    }
  };
  getElementType(element) {
    if (element.element_type === "UP") {
      if (element.images.length === 0) {
        return "Text";
      }
      let mtype = element.images[0].mime_type.split("/")[0];
      return mtype[0].toUpperCase() + mtype.slice(1);
    }
    return this.props.element_types.byID[element.element_type].name;
  }

  render() {
    const {
      id,
      element,
      element_ids,
      dimensions,
      element_types,
      project_url,
      isOwner,
      group_id,
      annotationMode,
      annotationCoordinates,
      comments,
      allow_public_comments,
      project_id,
      project_name,
    } = this.props;

    const loc = element_ids.indexOf(id);
    let first = false;
    let last = false;
    // let nextlink = "";
    // let prevlink = "";
    if (loc === 0) {
      first = true;
    }
    if (loc === element_ids.length - 1) {
      last = true;
    }
    const MaxImageRatio = 200; // beyond this crop the image
    const TextAreaWidth = 250;
    let AppliedRatio;
    if (element.image_ratio >= MaxImageRatio) {
      AppliedRatio = MaxImageRatio;
    } else {
      AppliedRatio = element.image_ratio;
    }
    const card_height = dimensions.window_dimensions.h - 200;
    const arrow_height = dimensions.window_dimensions.h - 200;
    const CardWidth = card_height * (AppliedRatio / 100) + TextAreaWidth;
    let link_url;
    let link_name;
    if (element.metadata) {
      if (element.metadata.link_url) {
        link_url = element.metadata.link_url;
      }
      if (element.metadata.link_name) {
        link_name = element.metadata.link_name;
      }
    }

    // let ImageHeight;
    // if (element.description) {
    //   ImageHeight = card_height - 120;
    // } else {
    //   ImageHeight = card_height - 15;
    // }
    // const ImageHeightNarrow = ((dimensions.window_dimensions.w - 128) * 3) / 4;
    // const ImageWidth = ImageHeight * (AppliedRatio / 100);

    let AnnotationNumber = 0;
    let hasImage = false;
    if (element.images[0]) {
      const type = element.images[0].mime_type.split("/")
      if (type[0] === "image"){
        hasImage = true;
      }
      
    }
    let hasLink = false;
    if (element.metadata) {
      if (element.metadata.link_url) {
        hasLink = true;
      }
    }

    let hasDescription = false;
    if (element.description) {
      hasDescription = true;
    }
    // console.log("render", element);
    let image_url;
    let image_urlB;
    if (element.images.length === 0) {
      image_url = false;
      image_urlB = false;
    } else {
      if (element.images[0]) {
        image_url =
          api_endpoint + "/media/" + element.images[0].id + "/file?size=1024";
      }
      if (element.images[1]) {
        image_urlB =
          api_endpoint + "/media/" + element.images[1].id + "/file?size=1024";
      }
    }
    let imagesrc = this.state.image;

    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    }
    let imagesrcB = this.state.imageB;

    if (this.state.imagePreviewB) {
      imagesrcB = this.state.imagePreviewB;
    }
    const viewboxA = "0 0 100 " + 100 / this.state.image_ratio;

    const viewboxB = "0 0 100 " + 100 / this.state.image_ratioB;
    let componentLeft = null;
    switch (element.element_type) {
      case "TE":
        componentLeft = (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}>
            {dimensions.window_dimensions.isMobile || this.state.expandImage ? (
              ""
            ) : (
              <ElementImageAnnotationToolbar>
                <ExpandIcon>
                  <Button
                    onClick={() => {
                      this.expandImage();
                    }}
                    size="small"
                    variant="toolbarbutton"
                    color="toolbarbutton"
                    startIcon={<SettingsOverscanIcon />}>
                    Expand Image
                  </Button>
                </ExpandIcon>
              </ElementImageAnnotationToolbar>
            )}

            <OtherContainer isMobile={dimensions.window_dimensions.isMobile}>
              <DimensionCenter>
                <DimensionContainer w={this.state.nonImageSquare}>
                  <Text
                    selected={this.props.selected}
                    tag={
                      element.metadata
                        ? element.metadata.label
                          ? element.metadata.label
                          : null
                        : null
                    }
                    description={element.description}
                    link_url={
                      element.metadata
                        ? element.metadata.link_url
                          ? element.metadata.link_url
                          : ""
                        : ""
                    }
                    link_name={
                      element.metadata
                        ? element.metadata.link_name
                          ? element.metadata.link_name
                          : ""
                        : ""
                    }
                    textcolor={
                      element.metadata
                        ? element.metadata.text_color
                          ? element.metadata.text_color
                          : ""
                        : ""
                    }
                  />
                </DimensionContainer>
              </DimensionCenter>
            </OtherContainer>
          </div>
        );
        break;

      case "TD":
        componentLeft = (
          <DimensionCenter>
            <DimensionContainer w={this.state.nonImageSquare}>
              <Next
                selected={this.props.selected}
                description={element.description}
              />
            </DimensionContainer>
          </DimensionCenter>
        );
        break;
      case "QN":
        componentLeft = (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}>
            {dimensions.window_dimensions.isMobile || this.state.expandImage ? (
              ""
            ) : (
              <ElementImageAnnotationToolbar>
                {" "}
                <ExpandIcon>
                  <Button
                    onClick={() => {
                      this.expandImage();
                    }}
                    size="small"
                    variant="toolbarbutton"
                    color="toolbarbutton"
                    startIcon={<SettingsOverscanIcon />}>
                    Expand Image
                  </Button>
                </ExpandIcon>
              </ElementImageAnnotationToolbar>
            )}

            <OtherContainer isMobile={dimensions.window_dimensions.isMobile}>
              <DimensionCenter>
                <DimensionContainer w={this.state.nonImageSquare}>
                  <Question
                    selected={this.props.selected}
                    description={element.description}
                  />
                </DimensionContainer>
              </DimensionCenter>
            </OtherContainer>
          </div>
        );

        break;

      case "DM":
        componentLeft = (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}>
            {dimensions.window_dimensions.isMobile || this.state.expandImage ? (
              ""
            ) : (
              <ElementImageAnnotationToolbar>
                <ExpandIcon>
                  <Button
                    onClick={() => {
                      this.expandImage();
                    }}
                    size="small"
                    variant="toolbarbutton"
                    color="toolbarbutton"
                    startIcon={<SettingsOverscanIcon />}>
                    Expand Image
                  </Button>
                </ExpandIcon>
              </ElementImageAnnotationToolbar>
            )}

            <OtherContainer isMobile={dimensions.window_dimensions.isMobile}>
              <DimensionCenter>
                <DimensionContainer w={this.state.nonImageSquare}>
                  <Dimension
                    selected={this.props.selected}
                    description={element.description}
                    unit={
                      element.metadata
                        ? element.metadata.dimension_unit
                          ? element.metadata.dimension_unit
                          : ""
                        : ""
                    }
                    x={
                      element.metadata
                        ? element.metadata.dimension_x
                          ? element.metadata.dimension_x
                          : 60
                        : 60
                    }
                    y={
                      element.metadata
                        ? element.metadata.dimension_y
                          ? element.metadata.dimension_y
                          : 60
                        : 60
                    }
                    z={
                      element.metadata
                        ? element.metadata.dimension_z
                          ? element.metadata.dimension_z
                          : 60
                        : 60
                    }
                  />
                </DimensionContainer>
              </DimensionCenter>
            </OtherContainer>
          </div>
        );
        break;

      case "CO":
        componentLeft = (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "auto",
            }}>
            {dimensions.window_dimensions.isMobile || this.state.expandImage ? (
              ""
            ) : (
              <ElementImageAnnotationToolbar>
                <ExpandIcon>
                  <Button
                    onClick={() => {
                      this.expandImage();
                    }}
                    size="small"
                    variant="toolbarbutton"
                    color="toolbarbutton"
                    startIcon={<SettingsOverscanIcon />}>
                    Expand Image
                  </Button>
                  <Button
                    onClick={() => {
                      this.onChangeToggleComparison();
                    }}
                    size="small"
                    variant="toolbarbutton"
                    color="toolbarbutton"
                    startIcon={
                      this.state.comparison_type === "SS" ? (
                        <CompareIcon fontSize="inherit" />
                      ) : (
                        <ImageIcon fontSize="inherit" />
                      )
                    }>
                    {this.state.comparison_type === "SS"
                      ? "Show As Overlay"
                      : "Show Side By Side"}
                  </Button>
                  {this.state.objects.length !== 0 ? (
                    <Button
                      onClick={() => {
                        this.toggleDrawing();
                      }}
                      size="small"
                      title="Toggle Drawings"
                      variant="toolbarbutton"
                      color="toolbarbutton"
                      startIcon={
                        this.state.showDrawing ? (
                          <HideDrawing fontSize="inherit" />
                        ) : (
                          <ShowDrawing fontSize="inherit" />
                        )
                      }>
                      {this.state.showDrawing
                        ? this.props.dimensions.window_dimensions.w > 980
                          ? "Hide Drawing"
                          : ""
                        : this.props.dimensions.window_dimensions.w > 980
                        ? "Show Drawing"
                        : ""}
                    </Button>
                  ) : (
                    ""
                  )}
                </ExpandIcon>
              </ElementImageAnnotationToolbar>
            )}

            <PreviewContentCompare
              isMobile={dimensions.window_dimensions.isMobile}
              expandImage={this.state.expandImage}
              isOverlay={this.state.comparison_type}
              h={dimensions.window_dimensions.w / this.state.image_ratio + "px"}
              h2={
                (dimensions.window_dimensions.w - 78) / this.state.image_ratio +
                "px"
              }>
              <CompareWrapper
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <FirstImage
                  w={this.state.imageAWidth}
                  h={this.state.imageAHeight}>
                  <div
                    style={{
                      width: this.state.imageAWidth,
                      height: this.state.imageAHeight,
                      position: "relative",
                    }}>
                    <SVGContent
                      viewBox={viewboxA}
                      //  height="100%" width="100%"
                      hide={this.state.showDrawing}
                      ref={this.svgRef}>
                      <rect
                        width="100%"
                        height="100%"
                        fillOpacity="0%"
                        fill="white"></rect>
                      {this.state.objects.map((o) => {
                        if (o.canvas === "A") {
                          if (o.type === "rect") {
                            return (
                              <Rect
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }

                          if (o.type === "line") {
                            return (
                              <Line
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                          if (o.type === "circ") {
                            return (
                              <Circ
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                          if (o.type === "golden") {
                            return (
                              <GoldenGrid
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                          if (o.type === "phi") {
                            return (
                              <PhiGrid
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                          if (o.type === "angle") {
                            return (
                              <AngleGrid
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                          if (o.type === "grid") {
                            return (
                              <SimpleGrid
                                editable={false}
                                svg={this.svgRef.current}
                                drawnow={this.state.imageAloaded}
                                key={o.id + "pu"}
                                obj={o}
                              />
                            );
                          }
                        }

                        return null;
                      })}
                    </SVGContent>

                    <img
                      alt=""
                      ref={this.bkgImage}
                      style={{
                        width: this.state.imageAWidth,
                        height: this.state.imageAHeight,
                        marginBottom: "-4px",
                      }}
                      onLoad={() => {
                        this.HandleImageLoaded(1);
                      }}
                      src={imagesrc}
                      draggable="false"
                    />
                  </div>
                </FirstImage>
              </CompareWrapper>
              {this.state.comparison_type === "SS" ? (
                <DividerLine>
                  <VSBadge>vs</VSBadge>
                </DividerLine>
              ) : (
                <Draggable
                  axis="x"
                  bounds={{
                    left: -this.state.maxW / 2,
                    top: 0,
                    right: this.state.maxW / 2,
                    bottom: 800,
                  }}
                  //  position={this.state.controlledPosition1}
                  onDrag={this.handleDragSlider}
                  onStop={this.handleStopSlider}
                  onStart={this.handleStartSlider}
                  nodeRef={this.nodeRefA}>
                  <SliderHandleContainer ref={this.nodeRefA}>
                    <SliderHandle id={"1"} />
                    <SliderCircle>
                      <SliderArrow />
                      <SliderArrow2 />
                    </SliderCircle>
                    <SliderHandle id={"1"} />
                  </SliderHandleContainer>
                </Draggable>
              )}

              <CompareWrapperRight
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <OverlayCorrection
                  w={this.state.overlayW}
                  h={this.state.overlayH}>
                  <SecondImage
                    scale={this.state.scale_b}
                    rotate={this.state.rotate_b}
                    opacity={this.state.opacity_b}
                    translate_x={this.state.translate_x_b}
                    translate_y={this.state.translate_y_b}
                    w={this.state.imageBWidth}
                    h={this.state.imageBHeight}>
                    <div
                      style={{
                        width: this.state.imageBWidth,
                        height: this.state.imageBHeight,
                        position: "relative",
                      }}>
                      <SVGContent
                        viewBox={viewboxB}
                        //  height="100%" width="100%"
                        hide={this.state.showDrawing}
                        ref={this.svgRefB}>
                        <rect
                          width="100%"
                          height="100%"
                          fillOpacity="0%"
                          fill="white"></rect>
                        {this.state.objects.map((o) => {
                          if (o.canvas === "B") {
                            if (o.type === "rect") {
                              return (
                                <Rect
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }

                            if (o.type === "line") {
                              return (
                                <Line
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                            if (o.type === "circ") {
                              return (
                                <Circ
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                            if (o.type === "golden") {
                              return (
                                <GoldenGrid
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                            if (o.type === "phi") {
                              return (
                                <PhiGrid
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                            if (o.type === "angle") {
                              return (
                                <AngleGrid
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                            if (o.type === "grid") {
                              return (
                                <SimpleGrid
                                  editable={false}
                                  svg={this.svgRefB.current}
                                  drawnow={this.state.imageAloaded}
                                  key={o.id + "pu"}
                                  obj={o}
                                />
                              );
                            }
                          }
                          return null;
                        })}
                      </SVGContent>

                      <img
                        alt=""
                        ref={this.bkgImageB}
                        style={{
                          width: this.state.imageBWidth,
                          height: this.state.imageBHeight,
                          marginBottom: "-4px",
                        }}
                        onLoad={() => {
                          this.HandleImageLoaded(2);
                        }}
                        src={imagesrcB}
                        draggable="false"
                      />
                    </div>
                  </SecondImage>
                </OverlayCorrection>
              </CompareWrapperRight>
            </PreviewContentCompare>
          </div>
        );
        break;
      case "UP":
      case "VI":
      case "MT":
      case "TL":
        componentLeft = (
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}>
            {element.element_type === "UP" && (
              <ElementImageAnnotation
                isMobile={dimensions.window_dimensions.isMobile}>
                {dimensions.window_dimensions.isMobile ||
                this.state.expandImage ? (
                  ""
                ) : (
                  <ElementImageAnnotationToolbar>
                    <ExpandIcon>
                      <Button
                        onClick={() => {
                          this.expandImage();
                        }}
                        title="Expand Image"
                        size="small"
                        variant="toolbarbutton"
                        color="toolbarbutton"
                        startIcon={<SettingsOverscanIcon />}>
                        {this.props.dimensions.window_dimensions.w > 800
                          ? "Expand Image"
                          : ""}
                      </Button>
                      {this.props.element.has_comments ? (
                        <Button
                          onClick={() => {
                            this.toggleAnnotations();
                          }}
                          size="small"
                          title="Toggle Annotations"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={
                            this.state.showAnnotations ? (
                              <HideAnnotations fontSize="inherit" />
                            ) : (
                              <ShowAnnotations fontSize="inherit" />
                            )
                          }>
                          {this.state.showAnnotations
                            ? this.props.dimensions.window_dimensions.w > 900
                              ? "Hide Annotations"
                              : ""
                            : this.props.dimensions.window_dimensions.w > 900
                            ? "Show Annotations"
                            : ""}
                        </Button>
                      ) : (
                        ""
                      )}

                      {this.state.objects.length !== 0 ? (
                        <Button
                          onClick={() => {
                            this.toggleDrawing();
                          }}
                          size="small"
                          title="Toggle Drawings"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={
                            this.state.showDrawing ? (
                              <HideDrawing fontSize="inherit" />
                            ) : (
                              <ShowDrawing fontSize="inherit" />
                            )
                          }>
                          {this.state.showDrawing
                            ? this.props.dimensions.window_dimensions.w > 980
                              ? "Hide Drawing"
                              : ""
                            : this.props.dimensions.window_dimensions.w > 980
                            ? "Show Drawing"
                            : ""}
                        </Button>
                      ) : (
                        ""
                      )}

                      <Button
                        size="small"
                        variant="toolbarbutton"
                        color="toolbarbutton"
                        onClick={() => {
                          this.OpenImageNewTab(element.images[0].id);
                        }}
                        startIcon={<DownloadOutlinedIcon />}>
                        {this.props.dimensions.window_dimensions.w > 1125
                          ? "Download Image"
                          : ""}
                      </Button>

                      {/* <IconButton
                        title="Expand Image"
                        style={{ color: "#111", fontSize: "16px" }}
                        onClick={() => {
                          this.expandImage();
                        }}
                        size="large">
                        <SettingsOverscanIcon fontSize="inherit" />
                      </IconButton> */}
                    </ExpandIcon>

                    {/* <EditButton>
                      {this.state.showAnnotations ? (
                        <IconButton
                          title="Hide Annotations"
                          style={{ color: "#111", fontSize: "16px" }}
                          onClick={() => {
                            this.toggleAnnotations();
                          }}
                          size="large">
                          <HideAnnotations fontSize="inherit" />
                        </IconButton>
                      ) : (
                        <IconButton
                          title="Show Annotations"
                          style={{ color: "#111", fontSize: "16px" }}
                          onClick={() => {
                            this.toggleAnnotations();
                          }}
                          size="large">
                          <ShowAnnotations fontSize="inherit" />
                        </IconButton>
                      )}
                      <a
                        title="Open Image in New Tab"
                        style={{ color: "#111" }}
                        target="_blank"
                        rel="noreferrer"
                        href={
                          api_endpoint +
                          "/media/" +
                          element.images[0].id +
                          "/file"
                        }>
                        <IconButton
                          style={{ color: "#111", fontSize: "16px" }}
                          size="large">
                          <DownloadOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </a>
                    </EditButton> */}
                  </ElementImageAnnotationToolbar>
                )}
                <ImageContainer
                  isMobile={
                    dimensions.window_dimensions.isMobile ||
                    this.state.expandImage
                  }>
                  {(() => {
                    const mtype = element.images[0].mime_type.split("/")[0];
                    // console.log(mtype);
                    switch (mtype) {
                      case "video":
                        return (
                          <VideoPlayer
                            src={
                              api_endpoint +
                              "/media/" +
                              element.images[0].id +
                              "/file"
                            }
                          />
                        );
                      case "audio":
                        return (
                          <AudioPlayer
                            src={
                              api_endpoint +
                              "/media/" +
                              element.images[0].id +
                              "/file"
                            }
                          />
                        );
                      default:
                        return (
                          <AnnotationWrapper
                            isMobile={
                              dimensions.window_dimensions.isMobile ||
                              this.state.expandImage
                            }
                            w={this.state.imageAWidth}
                            h={this.state.imageAHeight}>
                            {this.state.showAnnotations ? (
                              <AnnotationLayer
                                annotationMode={annotationMode}
                                ref={this.myRef}
                                onClick={(e) => {
                                  this.annotateImage(
                                    e,
                                    this.myRef.current.getBoundingClientRect()
                                  );
                                }}>
                                {comments &&
                                  comments.allIDS
                                    .filter(
                                      (comment) =>
                                        comments.byID[comment].x !== null &&
                                        comments.byID[comment].y !== null
                                    )
                                    .map((comment, index) => {
                                      switch (group_id) {
                                        case "1":
                                          if (
                                            comments.byID[comment].group ===
                                              null &&
                                            comments.byID[comment].public !==
                                              true
                                          ) {
                                            AnnotationNumber =
                                              AnnotationNumber + 1;
                                            return (
                                              <Annotation
                                                key={"ann" + index}
                                                x={
                                                  comments.byID[comment].x *
                                                    100 +
                                                  "%"
                                                }
                                                y={
                                                  comments.byID[comment].y *
                                                    100 +
                                                  "%"
                                                }>
                                                <AnnotationSVG
                                                  x={comments.byID[comment].x}
                                                  y={comments.byID[comment].y}
                                                  title={
                                                    comments.byID[comment]
                                                      .creator.username +
                                                    ": " +
                                                    comments.byID[comment]
                                                      .content
                                                  }
                                                  number={AnnotationNumber}
                                                  expandImage={
                                                    this.state.expandImage
                                                  }
                                                />
                                              </Annotation>
                                            );
                                          }
                                          break;
                                        case "2":
                                          console.log("group_id");
                                          if (
                                            comments.byID[comment].group !==
                                              null ||
                                            comments.byID[comment].public ===
                                              true
                                          ) {
                                            AnnotationNumber =
                                              AnnotationNumber + 1;
                                            return (
                                              <Annotation
                                                key={"ann" + index}
                                                x={
                                                  comments.byID[comment].x *
                                                    100 +
                                                  "%"
                                                }
                                                y={
                                                  comments.byID[comment].y *
                                                    100 +
                                                  "%"
                                                }>
                                                <AnnotationSVG
                                                  x={comments.byID[comment].x}
                                                  y={comments.byID[comment].y}
                                                  title={
                                                    comments.byID[comment]
                                                      .creator.username +
                                                    ": " +
                                                    comments.byID[comment]
                                                      .content
                                                  }
                                                  number={AnnotationNumber}
                                                  expandImage={
                                                    this.state.expandImage
                                                  }
                                                />
                                              </Annotation>
                                            );
                                          }
                                          break;
                                        default:
                                          if (
                                            comments.byID[comment].group ===
                                              group_id ||
                                            comments.byID[comment].public ===
                                              true
                                          ) {
                                            AnnotationNumber =
                                              AnnotationNumber + 1;
                                            return (
                                              <Annotation
                                                key={"ann" + index}
                                                x={
                                                  comments.byID[comment].x *
                                                    100 +
                                                  "%"
                                                }
                                                y={
                                                  comments.byID[comment].y *
                                                    100 +
                                                  "%"
                                                }>
                                                <AnnotationSVG
                                                  x={comments.byID[comment].x}
                                                  y={comments.byID[comment].y}
                                                  title={
                                                    comments.byID[comment]
                                                      .creator.username +
                                                    ": " +
                                                    comments.byID[comment]
                                                      .content
                                                  }
                                                  number={AnnotationNumber}
                                                  expandImage={
                                                    this.state.expandImage
                                                  }
                                                />
                                              </Annotation>
                                            );
                                          }
                                      }

                                      // return (
                                      //   <Annotation
                                      //     key={"ann" + index}
                                      //     x={comments.byID[comment].x * 100 + "%"}
                                      //     y={comments.byID[comment].y * 100 + "%"}
                                      //   >
                                      //     <AnnotationSVG
                                      //       x={comments.byID[comment].x}
                                      //       y={comments.byID[comment].y}
                                      //       title={
                                      //         comments.byID[comment].creator.username +
                                      //         ": " +
                                      //         comments.byID[comment].content
                                      //       }
                                      //       number={AnnotationNumber}
                                      //       expandImage={this.state.expandImage}
                                      //     />
                                      //   </Annotation>
                                      // );
                                    })}
                                {annotationCoordinates.x ? (
                                  <Annotation
                                    x={annotationCoordinates.x * 100 + "%"}
                                    y={annotationCoordinates.y * 100 + "%"}>
                                    <AnnotationSVG
                                      x={annotationCoordinates.x}
                                      y={annotationCoordinates.y}
                                      number={AnnotationNumber + 1}
                                      expandImage={this.state.expandImage}
                                    />
                                  </Annotation>
                                ) : (
                                  ""
                                )}
                              </AnnotationLayer>
                            ) : (
                              ""
                            )}

                            {this.state.objects && (
                              <SVGContent
                                viewBox={viewboxA}
                                //  height="100%" width="100%"
                                hide={this.state.showDrawing}
                                ref={this.svgRef}>
                                <rect
                                  width="100%"
                                  height="100%"
                                  fillOpacity="0%"
                                  fill="white"></rect>
                                {this.state.objects.map((o) => {
                                  if (o.canvas === "A") {
                                    if (o.type === "rect") {
                                      return (
                                        <Rect
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }

                                    if (o.type === "line") {
                                      return (
                                        <Line
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                    if (o.type === "circ") {
                                      return (
                                        <Circ
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                    if (o.type === "golden") {
                                      return (
                                        <GoldenGrid
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                    if (o.type === "phi") {
                                      return (
                                        <PhiGrid
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                    if (o.type === "angle") {
                                      return (
                                        <AngleGrid
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                    if (o.type === "grid") {
                                      return (
                                        <SimpleGrid
                                          editable={false}
                                          svg={this.svgRef.current}
                                          drawnow={this.state.imageAloaded}
                                          key={o.id + "pu"}
                                          obj={o}
                                        />
                                      );
                                    }
                                  }

                                  return null;
                                })}
                              </SVGContent>
                            )}

                            <img
                              alt={element.images[0].alt_text}
                              // onLoad={this.ImageLoaded}
                              style={{
                                width: this.state.imageAWidth,
                                height: this.state.imageAHeight,
                                marginBottom: "-4px",
                              }}
                              onLoad={() => {
                                this.HandleImageLoaded(1);
                              }}
                              src={image_url}
                            />
                          </AnnotationWrapper>
                        );
                    }
                  })()}
                </ImageContainer>
              </ElementImageAnnotation>
            )}
          </div>
        );
        break;
      default:
        componentLeft = <div></div>;
    }

    if (dimensions.window_dimensions.isMobile) {
      return (
        <ElementContainerNarrow
          id={"bm-" + id}
          cardwidth={CardWidth}
          onTouchStart={(touchStartEvent) =>
            this.handleTouchStart(touchStartEvent)
          }
          onTouchEnd={(touchEndEvent) => this.handleTouchEnd(touchEndEvent)}>
          <TopContainerNarrow cardheight={card_height}>
            <ElementCardNarrow cardheight={card_height}>
              <MobileHeader>
                {!first ? (
                  <StyledIconButtonNarrow
                    onClick={() => {
                      this.navigateElement("prev");
                    }}>
                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                  </StyledIconButtonNarrow>
                ) : (
                  <StyledIconButtonNarrow></StyledIconButtonNarrow>
                )}

                {!last ? (
                  <StyledIconButtonNarrow
                    onClick={() => {
                      this.navigateElement("next");
                    }}>
                    <NavigateNextRoundedIcon fontSize="inherit" />
                  </StyledIconButtonNarrow>
                ) : (
                  <StyledIconButtonNarrow></StyledIconButtonNarrow>
                )}
                {element.element_type === "UP" ? (
                  <RightButtons
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <EditButtonNarrow>
                      {this.props.element.has_comments ? (
                        <IconButton
                          title="Toggle Annotations"
                          style={{ color: "#111", fontSize: "16px" }}
                          onClick={() => {
                            this.toggleAnnotations();
                          }}
                          size="large">
                          {this.state.showAnnotations ? (
                            <HideAnnotations fontSize="inherit" />
                          ) : (
                            <ShowAnnotations fontSize="inherit" />
                          )}
                        </IconButton>
                      ) : (
                        ""
                      )}

                      {this.state.objects.length !== 0 ? (
                        <IconButton
                          title="Toggle Drawing"
                          style={{ color: "#111", fontSize: "16px" }}
                          onClick={() => {
                            this.toggleDrawing();
                          }}
                          size="large">
                          {this.state.showDrawing ? (
                            <HideDrawing fontSize="inherit" />
                          ) : (
                            <ShowDrawing fontSize="inherit" />
                          )}
                        </IconButton>
                      ) : (
                        ""
                      )}

                      <IconButton
                        onClick={() => {
                          this.OpenImageNewTab(element.images[0].id);
                        }}
                        style={{ color: "#111", fontSize: "16px" }}
                        size="large">
                        <DownloadOutlinedIcon fontSize="inherit" />
                      </IconButton>
                    </EditButtonNarrow>
                  </RightButtons>
                ) : (
                  ""
                )}
                {element.element_type === "CO" ? (
                  <RightButtons
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <EditButtonNarrow style={{ marginTop: "15px" }}>
                      <IconButton
                        style={{ color: "#111", fontSize: "16px" }}
                        onClick={() => {
                          this.onChangeToggleComparison();
                        }}
                        size="large">
                        {this.state.comparison_type === "SS" ? (
                          <CompareIcon fontSize="inherit" />
                        ) : (
                          <ImageIcon fontSize="inherit" />
                        )}
                      </IconButton>
                      {/* <Button
                        onClick={() => {
                          this.onChangeToggleComparison();
                        }}
                        size="small"
                        variant="toolbarbutton"
                        color="toolbarbutton"
                        startIcon={
                          this.state.comparison_type === "SS" ? (
                            <CompareIcon fontSize="inherit" />
                          ) : (
                            <ImageIcon fontSize="inherit" />
                          )
                        }>
                        {this.state.comparison_type === "SS"
                          ? "Show As Overlay"
                          : "Show Side By Side2"}
                      </Button> */}

                      {this.state.objects.length !== 0 ? (
                        <IconButton
                          title="Toggle Drawing"
                          style={{ color: "#111", fontSize: "16px" }}
                          onClick={() => {
                            this.toggleDrawing();
                          }}
                          size="large">
                          {this.state.showDrawing ? (
                            <HideDrawing fontSize="inherit" />
                          ) : (
                            <ShowDrawing fontSize="inherit" />
                          )}
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </EditButtonNarrow>
                  </RightButtons>
                ) : (
                  ""
                )}
                <RightButtons isMobile={dimensions.window_dimensions.isMobile}>
                  <CloseButtonNarrow>
                    <IconButton
                      onClick={() => {
                        this.handleClosePopup(project_url);
                      }}
                      size="large">
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </CloseButtonNarrow>
                </RightButtons>
              </MobileHeader>
              <ComponentLeftWrapper
                isMobile={dimensions.window_dimensions.isMobile}
                onClick={(e) => {
                  this.handleClickNavigate(e.clientX);
                }}>
                {componentLeft}
              </ComponentLeftWrapper>
              <BottomMobile>
                {(element.element_type === "UP" ||
                  element.element_type === "DM") &&
                element.description ? (
                  <MobileDescription>
                    {element.description.split("\n").map((line, index) => (
                      <div key={"line" + index}>{line}</div>
                    ))}
                  </MobileDescription>
                ) : (
                  ""
                )}

                <ElementType>
                  {this.getElementType(element)}{" "}
                  {isOwner ? (
                    <ElementEdit>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{
                          minWidth: "10px",
                          borderRadius: "16px",
                        }}
                        onClick={() => {
                          this.handleEdit(element.id);
                        }}>
                        Edit
                      </Button>
                    </ElementEdit>
                  ) : (
                    ""
                  )}
                </ElementType>

                {link_url ? (
                  <a
                    style={{ textDecoration: "none", color: "#111" }}
                    target="_blank"
                    rel="noreferrer"
                    href={link_url}>
                    <LinkBadge title={link_name ? link_name : "Link"}>
                      <InsertLinkIcon />
                      <LinkLabel>{link_name ? link_name : "Link"}</LinkLabel>
                    </LinkBadge>
                  </a>
                ) : (
                  ""
                )}

                <CommentContainer
                  newCommentAnnotationNumber={AnnotationNumber + 1}
                  element_type={element.element_type}
                  comments_per_group={element.comments_per_group}
                  hasImage={hasImage}
                  allow_public_comments={allow_public_comments}
                  group_id={group_id}
                  project_id={project_id}
                  project_name={project_name}
                  isOwner={isOwner}
                  id={id}
                  placement="popup"></CommentContainer>
              </BottomMobile>
            </ElementCardNarrow>
          </TopContainerNarrow>
        </ElementContainerNarrow>
      );
    } else {
      return (
        <ElementContainerDesktop>
          {!this.state.expandImage ? (
            <ElementContainer id={"bm-" + id} cardwidth={CardWidth}>
              <ArrowContainer
                onClick={() => {
                  this.navigateElement("prev");
                }}
                arrowheight={arrow_height}>
                {!first ? (
                  <StyledIconButton
                    onClick={() => {
                      this.navigateElement("prev");
                    }}>
                    <NavigateBeforeRoundedIcon fontSize="inherit" />
                  </StyledIconButton>
                ) : (
                  <StyledIconButton></StyledIconButton>
                )}
              </ArrowContainer>
              <ElementCard cardheight={card_height}>
                <ElementLeft>{componentLeft}</ElementLeft>
                <ElementRight>
                  <ElementHeader>
                    <RightButtons>
                      {/* {isOwner ? (
                          <Button
                            style={{ height: "30px", marginTop: "15px" }}
                            onClick={() => {
                              this.handleEdit(element.id);
                            }}
                            disableElevation
                            variant="contained"
                            color="primary"
                            startIcon={<EditIcon />}>
                            {" "}
                            Edit3
                          </Button>
                        ) : (
                          ""
                        )} */}

                      <CloseButton>
                        <IconButton
                          onClick={() => {
                            this.handleClosePopup(project_url);
                          }}>
                          <CloseIcon sx={{ fontSize: "22px" }} />
                        </IconButton>
                      </CloseButton>
                    </RightButtons>
                  </ElementHeader>
                  <ElementType>
                    {this.getElementType(element)}{" "}
                    {isOwner ? (
                      <ElementEdit>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{
                            minWidth: "10px",
                            borderRadius: "16px",
                          }}
                          onClick={() => {
                            this.handleEdit(element.id);
                          }}>
                          Edit
                        </Button>
                      </ElementEdit>
                    ) : (
                      ""
                    )}
                  </ElementType>
                  {link_url ? (
                    <a
                      style={{ textDecoration: "none", color: "#111" }}
                      target="_blank"
                      rel="noreferrer"
                      href={link_url}>
                      <LinkBadge title={link_name ? link_name : "Link"}>
                        <InsertLinkIcon />
                        <LinkLabel>{link_name ? link_name : "Link"}</LinkLabel>
                      </LinkBadge>
                    </a>
                  ) : (
                    ""
                  )}
                  {(element.element_type === "UP" ||
                    element.element_type === "DM" ||
                    element.element_type === "CO") &&
                  element.description ? (
                    <ElementDescriptionRight>
                      {element.description.split("\n").map((line, index) => (
                        <div key={"line" + index}>{line}</div>
                      ))}
                    </ElementDescriptionRight>
                  ) : (
                    ""
                  )}
                  <CommentContainer
                    container_height={window.innerHeight / 2 - 80}
                    newCommentAnnotationNumber={AnnotationNumber + 1}
                    group_id={group_id}
                    element_type={element.element_type}
                    comments_per_group={element.comments_per_group}
                    project_id={project_id}
                    project_name={project_name}
                    hasImage={hasImage}
                    allow_public_comments={allow_public_comments}
                    hasDescription={hasDescription}
                    hasLink={hasLink}
                    isOwner={isOwner}
                    id={id}
                    placement="popup"></CommentContainer>
                </ElementRight>
              </ElementCard>
              <ArrowContainer
                onClick={() => {
                  this.navigateElement("next");
                }}
                arrowheight={arrow_height}>
                {!last ? (
                  <StyledIconButton
                    onClick={() => {
                      this.navigateElement("next");
                    }}>
                    <NavigateNextRoundedIcon fontSize="inherit" />
                  </StyledIconButton>
                ) : (
                  <StyledIconButton></StyledIconButton>
                )}
              </ArrowContainer>
            </ElementContainer>
          ) : (
            <ElementContainerWide id={"bm-" + id} cardwidth={CardWidth}>
              <TopContainer cardheight={card_height}>
                <ArrowContainer
                  onClick={() => {
                    this.navigateElement("prev");
                  }}
                  arrowheight={arrow_height}>
                  {!first ? (
                    <StyledIconButton
                      onClick={() => {
                        this.navigateElement("prev");
                      }}>
                      <NavigateBeforeRoundedIcon fontSize="inherit" />
                    </StyledIconButton>
                  ) : (
                    <StyledIconButton></StyledIconButton>
                  )}
                </ArrowContainer>
                <ElementCardWide cardheight={card_height}>
                  <WideHeader>
                    <ExpandIcon>
                      <Button
                        onClick={() => {
                          this.contractImage();
                        }}
                        size="small"
                        variant="toolbarbutton"
                        color="toolbarbutton"
                        startIcon={<SettingsOverscanIcon />}>
                        Show Full Image
                      </Button>
                      {element.element_type === "CO" && (
                        <Button
                          onClick={() => {
                            this.onChangeToggleComparison();
                          }}
                          size="small"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={
                            this.state.comparison_type === "SS" ? (
                              <CompareIcon fontSize="inherit" />
                            ) : (
                              <ImageIcon fontSize="inherit" />
                            )
                          }>
                          {this.state.comparison_type === "SS"
                            ? "Show As Overlay"
                            : "Show Side By Side"}
                        </Button>
                      )}
                      {element.element_type === "UP" && element.has_comments && (
                        <Button
                          onClick={() => {
                            this.toggleAnnotations();
                          }}
                          size="small"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={
                            this.state.showAnnotations ? (
                              <HideAnnotations fontSize="inherit" />
                            ) : (
                              <ShowAnnotations fontSize="inherit" />
                            )
                          }>
                          {this.state.showAnnotations
                            ? "Hide Annotations"
                            : "Show Annotations"}
                        </Button>
                      )}
                      {this.state.objects.length !== 0 ? (
                        <Button
                          onClick={() => {
                            this.toggleDrawing();
                          }}
                          size="small"
                          title="Toggle Drawings"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={
                            this.state.showDrawing ? (
                              <HideDrawing fontSize="inherit" />
                            ) : (
                              <ShowDrawing fontSize="inherit" />
                            )
                          }>
                          {this.state.showDrawing
                            ? this.props.dimensions.window_dimensions.w > 980
                              ? "Hide Drawing"
                              : ""
                            : this.props.dimensions.window_dimensions.w > 980
                            ? "Show Drawing"
                            : ""}
                        </Button>
                      ) : (
                        ""
                      )}
                      {element.element_type === "UP" && (
                        <Button
                          onClick={() => {
                            this.OpenImageNewTab(element.images[0].id);
                          }}
                          size="small"
                          variant="toolbarbutton"
                          color="toolbarbutton"
                          startIcon={<DownloadOutlinedIcon />}>
                          {this.props.dimensions.window_dimensions.w > 1025
                            ? "Download Image"
                            : ""}
                        </Button>
                      )}
                    </ExpandIcon>

                    <RightButtonsWide>
                      <CloseButtonWide>
                        <IconButton
                          onClick={() => {
                            this.handleClosePopup(project_url);
                          }}>
                          <CloseIcon sx={{ fontSize: "22px" }} />
                        </IconButton>
                      </CloseButtonWide>
                    </RightButtonsWide>
                  </WideHeader>

                  {componentLeft}
                  <WideBottom>
                    {(element.element_type === "UP" ||
                      element.element_type === "DM") &&
                    element.description ? (
                      <MobileDescription>
                        {element.description.split("\n").map((line, index) => (
                          <div key={"line" + index}>{line}</div>
                        ))}
                      </MobileDescription>
                    ) : (
                      ""
                    )}
                    <ElementHeader>
                      <ElementType>
                        {this.getElementType(element)}{" "}
                        {isOwner ? (
                          <ElementEdit>
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              style={{
                                minWidth: "10px",
                                borderRadius: "16px",
                              }}
                              onClick={() => {
                                this.handleEdit(element.id);
                              }}>
                              Edit
                            </Button>
                          </ElementEdit>
                        ) : (
                          ""
                        )}
                      </ElementType>
                    </ElementHeader>
                    <CommentContainer
                      newCommentAnnotationNumber={AnnotationNumber + 1}
                      allow_public_comments={allow_public_comments}
                      group_id={group_id}
                      comments_per_group={element.comments_per_group}
                      project_id={project_id}
                      project_name={project_name}
                      element_type={element.element_type}
                      hasImage={hasImage}
                      isOwner={isOwner}
                      id={id}
                      placement="popup"></CommentContainer>
                  </WideBottom>
                </ElementCardWide>
                <ArrowContainer
                  onClick={() => {
                    this.navigateElement("next");
                  }}
                  arrowheight={arrow_height}>
                  {!last ? (
                    <StyledIconButton
                      onClick={() => {
                        this.navigateElement("next");
                      }}>
                      <NavigateNextRoundedIcon fontSize="inherit" />
                    </StyledIconButton>
                  ) : (
                    <StyledIconButton></StyledIconButton>
                  )}
                </ArrowContainer>
              </TopContainer>
            </ElementContainerWide>
          )}
        </ElementContainerDesktop>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  element: state.project.current_project.elements.byID[ownProps.id],
  project_id: state.project.current_project.id,
  project_name: state.project.current_project.name,
  element_ids: state.project.current_project.elements.allIDS,
  dimensions: state.dimensions,
  element_types: state.project.current_project.element_types,
  annotationMode: state.ui.annotationMode,
  annotationCoordinates: state.ui.annotationCoordinates,
  comments: state.project.current_project.elements.byID[ownProps.id].comments,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(ElementPopup);
