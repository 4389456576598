export const groupMemberStatus = {
    "AC": "Active",
    "IN": "Invited",
    "RE": "Requested",
};

export const groupMemberRole = {
    "AD": "Admin",
    "CO": "Contributor",
    "CM": "Commenter",
    "VI": "Viewer",
}