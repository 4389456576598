// import { createRoot } from 'react-dom/client';
// import React from "react";
// import App from "./components/App";
// const container = document.getElementById('root');
// const root = createRoot(container); // createRoot(container!) if you use TypeScript
// // root.render( <React.StrictMode><App  /> </React.StrictMode>,);
// root.render(<App/>);

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { AnalyticsProvider } from 'use-analytics'
import { BrowserRouter } from 'react-router-dom'
import analytics from "./utils/analytics.js";

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();