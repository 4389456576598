import React, { Component } from "react";
// import styled from "styled-components";

import { connect } from "react-redux";

class AnnotationSVG extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }

  render() {
    const { x, y, number, title, dimensions, expandImage } = this.props;

    let flipthreshold; // calculate where the annotation need to be flipped on the left and right side, because otherwise it falls off the screen
    if (expandImage) {
      flipthreshold = 50 / (dimensions.w - 130);
    } else {
      if (dimensions.isMobile) {
        flipthreshold = 50 / (dimensions.w - 15);
      } else {
        flipthreshold = 50 / 723;
      }
    }

    if (y > x) {
      if (y > -x + 1) {
        /* bottom */

        return (
          <svg style={{ pointerEvents: "none" }} height="140" width="140">
            <circle
              style={{ pointerEvents: "auto" }}
              cx="70"
              cy="105"
              r="12"
              fill="#2794f3"
            >
              {" "}
              <title>{title}</title>
            </circle>
            <circle cx="70" cy="70" r="3" fill="#2794f3" />
            <line x1="70" y1="70" x2="70" y2="105" stroke="#2794f3" />
            <text
              fontFamily="Roboto, sans-serif"
              fontSize="12px"
              fill="white"
              x="70"
              y="105"
              textAnchor="middle"
              dy=".3em"
            >
              {number}
            </text>
          </svg>
        );
      } else {
        /* left  */
        if (x < flipthreshold) {
          // flipped
          return (
            <svg style={{ pointerEvents: "none" }} height="140" width="140">
              <circle
                style={{ pointerEvents: "auto" }}
                cx="105"
                cy="70"
                r="12"
                fill="#2794f3"
              >
                <title>{title}</title>
              </circle>
              <circle cx="70" cy="70" r="3" fill="#2794f3" />
              <line x1="70" y1="70" x2="105" y2="70" stroke="#2794f3" />
              <text
                fontFamily="Roboto, sans-serif"
                fontSize="12px"
                fill="white"
                x="105"
                y="70"
                textAnchor="middle"
                dy=".3em"
              >
                {number}
              </text>
            </svg>
          );
        } else {
          return (
            // normal
            <svg style={{ pointerEvents: "none" }} height="140" width="140">
              <circle
                style={{ pointerEvents: "auto" }}
                cx="35"
                cy="70"
                r="12"
                fill="#2794f3"
              >
                {" "}
                <title>{title}</title>
              </circle>
              <circle cx="70" cy="70" r="3" fill="#2794f3" />
              <line x1="70" y1="70" x2="35" y2="70" stroke="#2794f3" />
              <text
                fontFamily="Roboto, sans-serif"
                fontSize="12px"
                fill="white"
                x="35"
                y="70"
                textAnchor="middle"
                dy=".3em"
              >
                {number}
              </text>
            </svg>
          );
        }
      }
    } else {
      if (y > -x + 1) {
        /*  right */
        if (x > 1 - flipthreshold) {
          //flipped
          return (
            <svg style={{ pointerEvents: "none" }} height="140" width="140">
              <circle
                style={{ pointerEvents: "auto" }}
                cx="35"
                cy="70"
                r="12"
                fill="#2794f3"
              >
                {" "}
                <title>{title}</title>
              </circle>
              <circle cx="70" cy="70" r="3" fill="#2794f3" />
              <line x1="70" y1="70" x2="35" y2="70" stroke="#2794f3" />
              <text
                fontFamily="Roboto, sans-serif"
                fontSize="12px"
                fill="white"
                x="35"
                y="70"
                textAnchor="middle"
                dy=".3em"
              >
                {number}
              </text>
            </svg>
          );
        } else {
          //normal
          return (
            <svg style={{ pointerEvents: "none" }} height="140" width="140">
              <circle
                style={{ pointerEvents: "auto" }}
                cx="105"
                cy="70"
                r="12"
                fill="#2794f3"
              >
                <title>{title}</title>
              </circle>
              <circle cx="70" cy="70" r="3" fill="#2794f3" />
              <line x1="70" y1="70" x2="105" y2="70" stroke="#2794f3" />
              <text
                fontFamily="Roboto, sans-serif"
                fontSize="12px"
                fill="white"
                x="105"
                y="70"
                textAnchor="middle"
                dy=".3em"
              >
                {number}
              </text>
            </svg>
          );
        }
      } else {
        /*  top */

        return (
          <svg style={{ pointerEvents: "none" }} height="140" width="140">
            <circle
              style={{ pointerEvents: "auto" }}
              cx="70"
              cy="35"
              r="12"
              fill="#2794f3"
            >
              {" "}
              <title>{title}</title>
            </circle>
            <circle cx="70" cy="70" r="3" fill="#2794f3" />
            <line x1="70" y1="70" x2="70" y2="35" stroke="#2794f3" />
            <text
              fontFamily="Roboto, sans-serif"
              fontSize="12px"
              fill="white"
              x="70"
              y="35"
              textAnchor="middle"
              dy=".3em"
            >
              {number}
            </text>
          </svg>
        );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  dimensions: state.dimensions.window_dimensions,
});
export default connect(mapStateToProps)(AnnotationSVG);
