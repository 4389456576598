import { CONSTANTS } from ".";
import axiosInstance from "../utils/axiosApi";
import { setMenu, updateProgress, timeoutError } from "../actions/uiActions";
import { getMyProjectsFollowing, getNotifications } from "./profileActions";
import { api_endpoint } from "../config";
import analyticsInstance from "../utils/analytics";

export const getElementComments = (id, groupid, public_project, mygroups) => (dispatch) => {
  // console.log("get comments")
  // console.log(id, groupid, public_project, mygroups)
  let query = ""
  if (groupid === "unshared") {
    query = "?unshared=True"
  } else if (groupid) {
    query = "?groupid=" + groupid
  } else {
    if (!public_project && mygroups.length > 0) {
      query = "?groupid=" + mygroups[0].id
    }
  }

  axiosInstance.get("elements/" + id + "/comments/" + query).then((res) => {
    dispatch({
      type: CONSTANTS.SET_ELEMENT_COMMENTS,
      payload: res.data,
    });
  });
};
export const getProjectContent = (id, groupid) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROJECT_PAGE_LOADING,
    payload: "loading",
  });
  axiosInstance.get("projects/" + id + "/").then((res) => {
    // dispatch(getElementStyles(id));
    let publicgroup = false
    if (res.data.public || res.data.share_link_enabled) {
      publicgroup = true
    }
    // dispatch(getMyGroups(id, groupid, publicgroup))
    dispatch(getProjectElements(id, groupid, publicgroup, res.data.shared_groups));
    dispatch({
      type: CONSTANTS.SET_PROJECT_PAGE_DETAILS,
      payload: res.data,
    });
    analyticsInstance.track("view_project", { id })

  });
  axiosInstance.post("projects/" + id + "/view").then((res) => {
    // dispatch(getElementStyles(id));
    // dispatch({
    //   type: CONSTANTS.SET_PROJECT_PAGE_DETAILS,
    //   payload: res.data,
    // });
  });
};
export const loadMoreComments = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_COMMENTS,
      payload: res.data,
    });
  });
};
export const loadMoreProjects = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_PROJECTS,
      payload: res.data,
    });
  });
};
export const loadMoreElements = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_ELEMENTS,
      payload: res.data,
    });
    res.data.results.forEach((e, index) => {
      if (e.has_comments) {
        dispatch(getElementComments(e.id));
      }
    });
  });
};
export const followProject = (id) => (dispatch) => {
  axiosInstance.post("projects/" + id + "/subscribe/").then((res) => {
    dispatch({
      type: CONSTANTS.CHANGE_FOLLOW_STATUS,
      payload: true,
    });
  });
};
export const unfollowProject = (id, userid) => (dispatch) => {
  axiosInstance.delete("projects/" + id + "/subscribe/").then((res) => {
    dispatch(getMyProjectsFollowing(userid));
    dispatch({
      type: CONSTANTS.CHANGE_FOLLOW_STATUS,
      payload: false,
    });
  });
};
export const saveProject = (id, data, project_url) => (dispatch) => {
  axiosInstance.put("projects/" + id + "/", data).then((res) => {
    dispatch(setMenu(false));
    //console.log("saved it");
    dispatch(getMyGroupsafterSave(id))
    dispatch({
      type: CONSTANTS.UPDATE_PROJECT_SETTINGS,
      payload: res.data,
    });
    // window.history.replaceState({}, "Sequentially", project_url);
  });
};

const getMyGroupsafterSave = (id) => (dispatch) => {
  axiosInstance.get("projects/" + id + "/mygroups/").then((res) => {
    let data = res.data.results.map(g => g.id)
    let currentPath = window.location.pathname.substring(1).split("/")
    if (currentPath[3] === "group") {
      console.log(data, currentPath[4])
      if (!data.includes(currentPath[4])) {
        console.log("not in group, redirect!")
        let target = currentPath.slice(0, 3).join("/")
        window.location.pathname = target
      }
    }
    dispatch({
      type: CONSTANTS.SET_PROJECT_PAGE_MYGROUPS,
      payload: res.data,
    });
  });
};
export const toggleSharingLink = (id) => (dispatch) => {
  axiosInstance.post("projects/" + id + "/share/").then((res) => {
    // dispatch(setMenu(false));
    console.log("sharelink", res.data);
    dispatch({
      type: CONSTANTS.UPDATE_SHARING_LINK,
      payload: res.data,
    });
    // window.history.replaceState({}, "Sequentially", project_url);
  });
};
export const newProject = (data) => (dispatch) => {
  axiosInstance.post("projects/", data).then((res) => {
    analyticsInstance.track("create_project", { id: res.data.id, title: data.title })
    dispatch(setMenu(false));
    dispatch({
      type: CONSTANTS.CREATE_NEW_PROJECT,
      payload: res.data,
    });
    window.location = "/project/" + res.data.id + "/" + res.data.name;
  });
};
export const deleteProject = (id) => (dispatch) => {
  axiosInstance.delete("projects/" + id + "/").then((res) => {
    analyticsInstance.track("delete_project", { id })
    //console.log("deleted it");
    window.location = "/";
  });
};
export const uploadSaveProject =
  (id, data, imageRaw, project_url) => (dispatch) => {
    axiosInstance
      .get("notifications/")
      .then((result) => {
        dispatch({
          type: CONSTANTS.GET_NOTIFICATIONS,
          payload: result.data,
        });

        dispatch(timeoutError(false));
        dispatch(setMenu(true, 5));
        dispatch(updateProgress(0, 1));
        if (imageRaw) {
          var file = imageRaw;
          var blob = file.slice(0, file.size, file.type);
          var split = file['name'].split('.');
          var filename = split[0];
          var extension = split.pop()
          console.log(split)

          if (filename.length > 90) {
            filename = filename.substring(0, 90);
          }

          var tempname = filename + '.' + extension;
          var newFile = new File([blob], tempname, { type: file.type })
          const formData = new FormData();
          formData.append("upload", newFile);
          formData.append("alt_text", "temp");
          axiosInstance({
            method: "post",
            url: "media/",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent) => {
              dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
            },
          })
            .then((response) => {
              //console.log("got it");
              //console.log(response.data);
              const imageid = response.data.id;
              data.custom_image = imageid;
              dispatch(saveProject(id, data, project_url));
            })
            .catch(function (response) {
              //handle error
              if (response === "timeout") {
                dispatch(timeoutError(true));
              }
            });
        }


      })
      .catch((err) => {
        dispatch({
          type: CONSTANTS.ON_ERROR,
          payload: err,
        });
      });

  };
export const uploadNewProject = (data, imageRaw) => (dispatch) => {
  axiosInstance
    .get("notifications/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_NOTIFICATIONS,
        payload: result.data,
      });

      dispatch(timeoutError(false));
      dispatch(setMenu(true, 5));
      dispatch(updateProgress(0, 1));
      if (imageRaw) {
        var file = imageRaw;
        var blob = file.slice(0, file.size, file.type);
        var split = file['name'].split('.');
        var filename = split[0];
        var extension = split.pop()
        console.log(split)

        if (filename.length > 90) {
          filename = filename.substring(0, 90);
        }


        var tempname = filename + '.' + extension;
        var newFile = new File([blob], tempname, { type: file.type })
        const formData = new FormData();
        formData.append("upload", newFile);
        formData.append("alt_text", "temp");
        axiosInstance({
          method: "post",
          url: "media/",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
          },
        })
          .then((response) => {
            //console.log("got it");
            //console.log(response.data);
            const imageid = response.data.id;
            data.custom_image = imageid;
            dispatch(newProject(data));
          })
          .catch(function (response) {
            //handle error

            if (response === "timeout") {
              dispatch(timeoutError(true));
            }
          });
      }


    })
    .catch((err) => {
      console.log(err)
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });



};
// export const getElementStyles = (id) => (dispatch) => {
//   axiosInstance.get("element-types/").then((res) => {
//     dispatch(getMyGroups(id));

//     dispatch({
//       type: CONSTANTS.SET_ELEMENT_TYPES,
//       payload: res.data,
//     });
//   });
// };
const getProjectElements = (id, groupid, public_project, mygroups) => (dispatch) => {

  axiosInstance.get("projects/" + id + "/elements/").then((res) => {
    dispatch({
      type: CONSTANTS.SET_PROJECT_PAGE_ELEMENTS,
      payload: res.data,
    });
    res.data.results.forEach((e, index) => {
      if (e.has_comments) {
        dispatch(getElementComments(e.id, groupid, public_project, mygroups));
      }
    });
  });
};

const getMyGroups = (id, groupid, public_project) => (dispatch) => {
  axiosInstance.get("projects/" + id + "/mygroups/").then((res) => {
    dispatch(getProjectElements(id, groupid, public_project, res.data));
    dispatch({
      type: CONSTANTS.SET_PROJECT_PAGE_MYGROUPS,
      payload: res.data,
    });
  }).catch(e => {
    // dispatch(getProjectElements(id, groupid, public_project, { results: [] }));
    // dispatch({
    //   type: CONSTANTS.SET_PROJECT_PAGE_MYGROUPS,
    //   payload: { results: [] },
    // });
  });
};
// const getProjectComments = (id) => (dispatch) => {

//   axiosInstance
//     .get("projects/" + id +"/comments/")
//     .then((res) => {
//       dispatch({
//         type: CONSTANTS.SET_PROJECT_PAGE_COMMENTS,
//         payload: res.data,
//       });
//     })

// };

export const setZoom = (level, card_height, comment_height) => {
  return {
    type: CONSTANTS.SET_ZOOM,
    payload: { level, card_height, comment_height },
  };
};
export const setPosition = (position_array, width_array) => {
  return {
    type: CONSTANTS.SET_POSITION,
    payload: { position_array, width_array },
  };
};

export const selectElement = (element) => {
  return {
    type: CONSTANTS.SELECT_ELEMENT,
    payload: { element },
  };
};
export const setDialogType = (type) => {
  return {
    type: CONSTANTS.SET_PROJECT_DIALOG_TYPE,
    payload: { type },
  };
};
export const updatePosition = (width, id) => {
  return {
    type: CONSTANTS.UPDATE_POSITION,
    payload: { width, id },
  };
};
export const setFriendlyDate = (date, newdate, id) => {
  return {
    type: CONSTANTS.SET_FRIENDLY_DATE,
    payload: { date, newdate, id },
  };
};
export const addComment = (data) => (dispatch) => {
  axiosInstance
    .post("comments/", data)
    .then((res) => {
      analyticsInstance.track("create_comment", { id: res.data.id, element: res.data.element, group: res.data.group, public: res.data.public })
      dispatch({
        type: CONSTANTS.ADD_COMMENT,
        payload: res.data,
      });
    })
    .catch(function (response) {
      //handle error
      //console.log(response);
    });
};
export const saveComment = (commentid, data) => (dispatch) => {
  axiosInstance
    .put("comments/" + commentid + "/", data)
    .then((res) => {
      //console.log(res.data);
      dispatch({
        type: CONSTANTS.SAVE_COMMENT,
        payload: res.data,
      });
    })
    .catch(function (response) {
      //handle error
      //console.log(response);
    });
};
export const deleteComment = (commentid, elementid) => (dispatch) => {
  const data = { commentid: commentid, element: elementid };
  axiosInstance
    .delete("comments/" + commentid + "/")
    .then((res) => {
      //console.log(res.data);
      dispatch({
        type: CONSTANTS.DELETE_COMMENT,
        payload: data,
      });
    })
    .catch(function (response) {
      //handle error
      //console.log(response);
    });
};

export const getSingleElement = (id) => (dispatch) => {
  axiosInstance
    .get("elements/" + id + "/")
    .then((res) => {
      analyticsInstance.track("view_element", { id })
      //console.log(res);
      const description = res.data.description;
      const element_date = res.data.element_date;
      const element_type = res.data.element_type;

      const metadata = res.data.metadata
      // const dimension_unit = res.data.dimension_unit;
      // const dimension_x = res.data.dimension_x;
      // const dimension_y = res.data.dimension_y;
      // const dimension_z = res.data.dimension_z;
      // const link_url = res.data.link_url;
      // const link_name = res.data.link_name;
      const images = res.data.images;
      const show_full_image = res.data.show_full_image
      dispatch({
        type: CONSTANTS.SAVE_ELEMENT,
        payload: {
          description,
          element_date,
          element_type,

          id,
          images,
          metadata,
          show_full_image
        },
      });
    })
    .catch(function (response) {
      //handle error
      //console.log(response);
    });
};
export const deleteElement = (id) => (dispatch) => {
  axiosInstance
    .delete("elements/" + id + "/")
    .then((res) => {
      //console.log(res);
      dispatch({
        type: CONSTANTS.DELETE_ELEMENT,
        payload: { id },
      });
    })
    .catch(function (response) {
      //handle error
      //console.log(response);
    });
};

// export const newImageSave =
//   (
//     data,
//     imageRaw,

//   ) =>
//   (dispatch) => {
//     axiosInstance
//       .get("notifications/")
//       .then((result) => {
//         dispatch({
//           type: CONSTANTS.GET_NOTIFICATIONS,
//           payload: result.data,
//         });
//         dispatch(timeoutError(false));
//         dispatch(setMenu(true, 5));
//         dispatch(updateProgress(0, 1));

//         if (imageRaw) {
//           var file = imageRaw;
//           var blob = file.slice(0, file.size, file.type); 
//           var split = file['name'].split('.');
//           var filename = split[0];
//           var extension = split[1];
//           console.log(split)

//           if (filename.length > 90) {
//             filename = filename.substring(0, 90);
//         }

//          var tempname = filename + '.' + extension;  
//           var newFile = new File([blob], tempname, {type: file.type})
//           const formData = new FormData();
//           formData.append("upload", newFile);
//           formData.append("alt_text", "temp");

//           console.log(formData)
//           axiosInstance({
//             method: "post",
//             url: "media/",
//             data: formData,
//             headers: { "Content-Type": "multipart/form-data" },
//             onUploadProgress: (progressEvent) => {
//               dispatch(
//                 updateProgress(progressEvent.loaded, progressEvent.total)
//               );
//             },
//           })
//             .then((response) => {
//               const imageid = response.data.id;
//               const imageurl = api_endpoint+"/media/"+response.data.id+"/file?size=512";
//               const alt_text = response.data.alt_text;
//               const thumb = response.data.thumb;

//               dispatch(
//                 saveElement(
//                   data,
//                   imageid,
//                   imageurl,
//                   alt_text,
//                   thumb,

//                 )
//               );
//             })
//             .catch(function (response) {
//               //handle error
//               if (response === "timeout") {
//                 dispatch(timeoutError(true));
//               }
//             });
//         }
//       })
//       .catch((err) => {
//         dispatch({
//           type: CONSTANTS.GET_ERRORS,
//           payload: err,
//         });
//       });
//   };

export const saveElement =
  (
    data,

  ) =>
    (dispatch) => {
      if (!data.images) {
        data.images = [];
      }



      axiosInstance
        .put("elements/" + data.id + "/", data)
        .then((res) => {
          dispatch(setMenu(false));
          dispatch(getSingleElement(data.id));

          // dispatch({
          //   type: CONSTANTS.SAVE_ELEMENT,
          //   payload: {description, element_date, element_type, id, dimension}
          // });
        })
        .catch(function (response) {
          //handle error
          //console.log(response);
        });
    };
export const saveOrder =
  (

    element_date,
    element_type,
    id,

  ) =>
    (dispatch) => {
      dispatch({
        type: CONSTANTS.SET_ELEMENT_ORDER,
        payload: {
          element_date,
          id,
        },
      });

      const data = {

        element_date: element_date,
        element_type: element_type,

      };

      axiosInstance
        .put("elements/" + id + "/", data)
        .then((res) => {
          dispatch(setMenu(false));
          dispatch(getSingleElement(id));

          // dispatch({
          //   type: CONSTANTS.SAVE_ELEMENT,
          //   payload: {description, element_date, element_type, id, dimension}
          // });
        })
        .catch(function (response) {
          //handle error
          //console.log(response);
        });
    };

export const uploadFiles = (formdata, files, type, image_order) => (dispatch) => {
  console.log("formdata", formdata)
  let complete = files.map(f => ({ loaded: 0, total: f.size }))
  dispatch(timeoutError(false));
  dispatch(setMenu(true, 5));
  dispatch(updateProgress(0, 1));
  const progress = (p, i) => {
    complete[i] = p
    console.log(p)
    const totalProgress = complete.reduce((progress, item) => {
      return {
        loaded: progress.loaded + item.loaded,
        total: progress.total + item.total
      }
    }, { loaded: 0, total: 0 })
    dispatch(
      updateProgress(totalProgress.loaded, totalProgress.total)

    )
  }

  const uploads = files.map((f, i) => {
    var file = f;
    var blob = file.slice(0, file.size, file.type);
    var split = file['name'].split('.');

    var filename = split[0];
    var extension = split.pop()

    console.log(split)

    if (filename.length > 90) {
      filename = filename.substring(0, 90);
    }


    var tempname = filename + '.' + extension;
    var newFile = new File([blob], tempname, { type: file.type })


    const formData = new FormData();
    formData.append("upload", newFile);
    formData.append("alt_text", "temp");
    console.log(newFile)
    console.log(formData)
    return axiosInstance({
      method: "post",
      url: "media/",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (p) => progress(p, i)
    })
    // return axiosInstance.post("media/", {
    //   headers: { "Content-Type": "multipart/form-data" },
    //   data: formData,
    //   onUploadProgress: (p) => progress(p, i)
    // })
  })

  Promise.all(uploads).then(responses => {
    const response = responses.map(r => r.data.id)
    console.log("formdata", formdata)
    console.log(responses)

    if (formdata.metadata) {
      console.log("metadata")
      if (formdata.metadata.image_order) {
        console.log("image_order", image_order)
        if (image_order[0] === 0 && image_order[1] === 0) {
          //update both
          console.log("both")
          formdata.metadata.image_order = response
          formdata.images = response

        } else {
          console.log("esle", image_order[0])
          if (image_order[0] === 0) {
            console.log("A", response[0], formdata.metadata.image_order, formdata.images)
            formdata.metadata.image_order[0] = response[0]
            formdata.images[0] = response[0]


            //update only A
          }
          if (image_order[1] === 0) {
            //update only B
            console.log("B")
            formdata.metadata.image_order[1] = response[0]
            formdata.images[1] = response[0]

          }

        }
      } else {
        formdata.images = response
      }
    } else {
      formdata.images = response
    }
    if (type === "new") {
      dispatch(
        newElement(
          formdata

        )
      );
    }
    if (type === "save") {
      dispatch(
        saveElement(
          formdata

        )
      );
    }
  }).catch((err) => {
    dispatch({
      type: CONSTANTS.ON_ERROR,
      payload: err,
    })
  })
}

// export const newImage =
//   (
//    data,
//     imageRaw,
//     imageRawB,
//   ) =>
//   (dispatch) => {
//     axiosInstance
//       .get("notifications/")
//       .then((result) => {
//         dispatch({
//           type: CONSTANTS.GET_NOTIFICATIONS,
//           payload: result.data,
//         });
//         dispatch(timeoutError(false));
//         dispatch(setMenu(true, 5));
//         dispatch(updateProgress(0, 1));
//         //console.log("image");
//         //console.log(projectid);
//         if (imageRaw) {

//           var file = imageRaw;
//           var blob = file.slice(0, file.size, file.type); 
//           var split = file['name'].split('.');
//           var filename = split[0];
//           var extension = split[1];
//           console.log(split)

//           if (filename.length > 90) {
//               filename = filename.substring(0, 90);
//           }


//          var tempname = filename + '.' + extension;  
//           var newFile = new File([blob], tempname, {type: file.type})

//           // console.log(imageRaw)
//           const formData = new FormData();
//           formData.append("upload", newFile);
//           formData.append("alt_text", "temp");

//           axiosInstance({
//             method: "post",
//             url: "media/",
//             data: formData,
//             headers: { "Content-Type": "multipart/form-data" },
//             onUploadProgress: (progressEvent) => {
//               dispatch(
//                 updateProgress(progressEvent.loaded, progressEvent.total)
//               );
//             },
//           })
//             .then((response) => {
//               console.log("got it2");
//               console.log(response.data);
//               console.log(api_endpoint+"/media/"+response.data.id+"/file?size=512");
//               const imageid = response.data.id;
//               const imageurl = api_endpoint+"/media/"+response.data.id+"/file?size=512";
//               const alt_text = response.data.alt_text;
//               const thumb = response.data.thumb;
//               const mime_type = response.data.mime_type;

//               dispatch(
//                 newElement(
//                   data,
//                   imageid,
//                   imageurl,
//                   alt_text,
//                   thumb,
//                   mime_type,

//                 )
//               );
//             })
//             .catch(function (error) {
//               //handle error
//               if (error === "timeout") {
//                 dispatch(timeoutError(true));
//               }
//             });
//         }
//       })
//       .catch((err) => {
//         dispatch({
//           type: CONSTANTS.GET_ERRORS,
//           payload: err,
//         });
//       });
//   };
export const newElement =
  (
    data,


  ) =>
    (dispatch) => {
      //console.log("newelement");
      // let images = [];

      // figure out whether this is selecting existing images or uploading
      if (!data.images) {
        data.images = []
      }


      console.log(data);
      axiosInstance
        .post("elements/", data)
        .then((res) => {
          axiosInstance
            .get("elements/" + res.data.id + "/")
            .then((res) => {
              console.log("new element")
              dispatch(setMenu(false));
              dispatch({
                type: CONSTANTS.NEW_ELEMENT,
                payload: res.data,
              });
              analyticsInstance.track("create_element", { id: res.data.id, project: res.data.project, element_type: res.data.element_type })
            })
            .catch(function (response) {
              //handle error
              //console.log(response);
            });

        })
        .catch(function (response) {
          //handle error
          //console.log(response);
        });
    };
export const getProjectSubscribers = (id) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROJECT_SUBSCRIBERS_LOADING,
  });
  axiosInstance
    .get("projects/" + id + "/subscribers/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_PROJECT_SUBSCRIBERS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const loadMoreProjectSubscribers = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {

    dispatch({
      type: CONSTANTS.LOAD_MORE_PROJECT_SUBSCRIBERS,
      payload: res.data,
    });
  });
};