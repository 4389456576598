 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const DrawCursor = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M7.2,6.5l8.9,5.6l-3.6,1l-0.7,0.2l-0.4,0.6l-2.2,3L7.2,6.5 M4.3,2.3l3.9,19.3l4.9-6.6l7.9-2.2L4.3,2.3L4.3,2.3z"/>
	
</SvgIcon>
  );
};

export default DrawCursor;

