import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ColumnMiddle } from "../styles/MenuComponents";
import { connect } from "react-redux";
import NavBar2 from "./navbar.js"
import { Ribbons } from "../marketing_pages/Ribbon.js";

import Footer from "../marketing_pages/Footer.js";
import { StyledBackGround, CenterColumn, Columns, LeftColumn } from "./styles"
import {
  ContentWrapper,
  HeaderSection,

} from "../marketing_pages/MarketingComponents";
import { site_name } from "../../config";
import setTitle from "../../utils/setTitle";

class Privacy extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    window.scrollTo(0, 0);
    setTitle(`Made Privacy | ${site_name}`);
  }

  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        {/* <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <StyledBackGround>

          <Columns isMobile={this.props.isMobile}>
            <LeftColumn isMobile={this.props.isMobile}>
              <NavBar2 />
            </LeftColumn>
            <CenterColumn>
              <h1>Privacy Policy</h1>

              <p>
                This privacy policy (Policy) describes the information we collect
                when you access or use our website{" "}
                <a href="https://www.asitsmade.com/">www.asitsmade.com</a>, and
                related services (collectively our “Services”) and how we use,
                disclose, and protect this information. It also tells you about
                rights and choices you may have with respect to your information,
                and how you can reach us to update your information or get answers
                to questions you may have about our privacy practices.
              </p>
              <p>
                Your access and use of our Services indicates your consent to the
                terms of this Policy and our Terms of Use.
              </p>
              <h3>Information We Collect</h3>
              <p>
                We collect information through the use of our Services as described
                below.
              </p>
              <p>
                <strong>Information You Provide</strong>
              </p>
              <p>
                To register you for the Services, we require you to provide us with
                certain information, including: (1) your name, your e-mail address,
                and your account password); and (2) payment information (such as
                your credit or debit card number, bank account routing number, and
                billing and shipping address). If you communicate with us by, for
                example, e-mail, telephone, online form, any information voluntarily
                provided in such communication may be collected.
              </p>
              <p>
                Note, when you make a purchase of our Services, our payment
                processor directly collects and processes the payment information
                that you provide, as necessary to complete your purchase.
              </p>

              <p>
                <strong>Invited Users</strong>
              </p>
              <p>
                You may choose to invite other individuals to use our Services. If
                you do, we will receive that individual's email address, and send
                that person an email inviting them to try our Services, to the
                extent permitted under applicable law. Inviting a colleague will
                require them to also accept this Policy and our &nbsp;
                <a href="https://www.asitsmade.com/terms" target="_blank">
                  Terms of Service
                </a>
                .
              </p>

              <p>
                <strong>Information We Receive from Third Parties</strong>
              </p>
              <p>
                In order to support your experience using the Service, we may also
                collect information about you from third party partners and combine
                it with other information we collect from you.
              </p>
              <p>
                <strong>Automatically Collected Information</strong>
              </p>
              <p>
                We track user activity (e.g. pages visited) and use cookies and
                other tracking technologies on our Services. Cookies are small text
                files that web servers place on your device; they are designed to
                store basic information and to help websites and applications
                recognize a browser. Cookies help us track and target the interests
                of our users to enhance their experience with our Services. We work
                with analytics providers such as Google Analytics, which use cookies
                and similar technologies to collect and analyze information about
                use of the Services and report on activities, trends, and
                demographics. You can learn about Google’s practices by going to{" "}
                <a href="https://www.google.com/policies/privacy/partners/">
                  https://www.google.com/policies/privacy/partners/
                </a>
                , and opt out of them by downloading the Google Analytics opt-out
                browser add-on, available at
                <a href="https://tools.google.com/dlpage/gaoptout">
                  {" "}
                  https://tools.google.com/dlpage/gaoptout
                </a>
                . For information about how to opt-out of certain use of cookies and
                other technologies, please see the Your Choices section below and
                refer to our{" "}
                <a href="https://www.asitsmade.com/cookies">
                  Cookies Notice
                </a>{" "}
                for more details.
              </p>
              <h3>How We Use Information</h3>
              <p>
                We may use the information we collect for various purposes,
                including:
              </p>
              <p>
                <strong>Providing &amp; Improving Our Services</strong>
              </p>
              <p>
                When you register for our Services, we use the information you
                provide to create and maintain your account. The information we
                collect also helps us improve, enhance, personalize, and promote our
                Services.
              </p>
              <p>
                <strong>Communications</strong>
              </p>
              <p>
                We will communicate with you if you’ve provided us the means to do
                so. For example, if you’ve provided your phone number, we may call
                you to discuss our Services, to the extent permitted under
                applicable law. If you’ve given us your email address, we may send
                you promotional email offers or email you about your use of the
                Services, to the extent permitted under applicable law. If you do
                not want to receive promotional emails from us, please indicate your
                preference by opting-out via the links provided in the emails. Note,
                that if you opt-out of receiving our promotional emails, you may
                still receive transactional emails from us.
              </p>
              <p>
                <strong>Legal</strong>
              </p>
              <p>
                We reserve the right to access, use, preserve, transfer, or
                disclose, at any time without notice to you, any information as
                reasonably necessary to: (1) comply with any applicable law,
                regulation, subpoena, or legal process, or respond to any
                governmental requests or regulatory investigation and to cooperate
                with law enforcement, if we believe such action is required or
                permitted by law; (2) enforce our this Policy or our{" "}
                <a href="https://www.asitsmade.com/terms">
                  Terms of Use
                </a>
                , including investigating any potential violations; (3) protect the
                safety, integrity, rights, or security of our users, our Services or
                equipment, or any third party; or (4) detect, prevent, or otherwise
                address fraud, security, or technical issues related to our Services
                or those of our business partners.
              </p>
              <p>
                <strong>Data Analyses</strong>
              </p>
              <p>
                We may also perform data analyses (including anonymization of
                personal information).
              </p>
              <p>

              </p>
              <p>
                <strong>Business Purposes</strong>
              </p>
              <p>
                We may process personal information in connection with providing or
                improving our service, partnerships or vendor relationships.
              </p>

              <h3>How We Disclose Information</h3>
              <p>
                We may disclose the information we collect, including in the
                following ways:
              </p>
              <p>
                <strong>The following information is publicly accessible:</strong>
              </p>
              <p>
                your profile, username, profile picture and about information. Any
                projects that have been set to be pubicly shared.
              </p>
              <p>
                <strong>Affiliates &amp; Partners</strong>
              </p>
              <p>
                We may share your information with any members of our group, which
                includes our affiliates, subsidiaries, and branch offices, to which
                it is reasonably necessary or desirable for us to disclose your
                information in order to carry out the above-mentioned information
                processing purposes.
              </p>
              <p>
                <strong>Service providers</strong>
              </p>
              <p>
                We employ service providers to perform tasks on our behalf when
                providing the Services. We do not authorize these service providers
                to use or disclose the data except as necessary to perform tasks on
                our behalf or comply with legal requirements. If you would like to
                review a list of the third party services used to support Made,
                please see our{" "}
                <a href="/processors">
                  subprocessors
                </a>{" "}
                list.
              </p>
              <p>

              </p>
              <p>
                <strong>Analytics</strong>
              </p>
              <p>
                In addition, our third party analytics vendors may set and access
                their own cookies, pixel tags and similar technologies on our
                Services and they may otherwise collect or have access to
                information about you which they may collect over time and across
                different websites, to provide us with aggregate information.
              </p>
              <p>

              </p>
              {/* <p>
            <strong>Advertising Partners</strong>
          </p>
          <p>
            We may work with third party advertising partners to show you ads that
            we think may interest you. These advertising partners may set and
            access their own cookies, pixel tags and similar technologies on the
            Service and they may otherwise collect or have access to information
            about you which they may collect over time and across different
            online services. If required under applicable law, we may request
            your consent to such collection and use of your information. Some of
            our advertising partners are members of the
            <a href="https://www.networkadvertising.org/choices/">
              {" "}
              Network Advertising Initiative
            </a>{" "}
            or the
            <a href="https://www.aboutads.info/choices/">
              {" "}
              Digital Advertising Alliance
            </a>
            . If you do not wish to receive our personalized ads, please contact
            us by sending an email to{" "}
            <a href="mailto:privacy@asitsmade.com">privacy@asitsmade.com</a>, or
            visit their opt-out pages to learn about how you may opt out of
            receiving personalized ads from member companies.
          </p>
          <p>
            <strong>
              <br />
            </strong>
          </p> */}
              <p>
                <strong>Business Transfers</strong>
              </p>
              <p>
                We may choose to buy or sell assets, and may share and/or transfer
                your information in connection with the evaluation of and entry into
                such transactions. Also, if our company (or our assets) are
                acquired, or if we go out of business, enter bankruptcy, or go
                through some other change of control, your information could be one
                of the assets transferred to or acquired by a third party.
              </p>
              <p>
                <strong>Legal/Protection</strong>
              </p>
              <p>
                We reserve the right to access, read, preserve, and disclose any
                information that we reasonably believe is necessary to comply with
                law or court order; enforce or apply this Policy or our Terms of Use
                and other agreements; or protect the rights, property, or safety of
                Made, our employees, our users, or others.
              </p>
              <p>
                <strong>Aggregate Data</strong>
              </p>
              <p>
                We may de-identify your information and process and share it in an
                anonymous and aggregated form for purposes in addition to those
                described above. For example, we may share aggregate reports about
                business metrics.
              </p>

              <h3>Your Rights and Choices</h3>
              <p>
                You may update your preferences, ask us to remove your information
                from our mailing lists, delete your account, submit a request to
                exercise your rights available under applicable law, or express your
                concern about the use of your information at any time. To do so,
                please contact us via the email address or phone number provided
                below in the Contact Us section. Additionally, you may choose not to
                provide information to us. However, if you do not provide
                information when requested, you may not be able to benefit from the
                Services as the information may be necessary to provide you with the
                Services or we may be legally required to collect it in the context
                of the Services. Please note that if you cease using the Service or
                we terminate your access to the Service in accordance with your
                Customer Agreement, you may no longer have the ability to access or
                update your information.
              </p>
              <p>
                <strong>Tracking</strong>
              </p>
              <p>
                Many web browsers allow you to manage your preferences relating to
                cookies. You may be able to adjust your browser to manage cookies
                using your browser’s preferences, however, if you choose to block
                cookies, doing so may impair the use of our Services.
              </p>
              <p>
                <strong>Non-US Residents Choices</strong>
              </p>
              <p>
                Under certain jurisdictions outside the US, we may obtain your
                opt-in consent at the time of collection for the processing of
                certain information for direct marketing purposes or to use cookies
                and similar tracking technologies. If we rely on consent for the
                processing of your information, you may have the right to withdraw
                your consent at any time and, when you do so, this will not affect
                the lawfulness of the processing before your consent withdrawal.
              </p>
              <p>
                Under certain jurisdictions, you may have the right to request
                access to and receive information about the information we maintain
                about you, to update and correct inaccuracies in your information,
                to restrict or to object to the processing of your information, have
                the information deleted, or to exercise your right to data
                portability, as appropriate. Those rights may be limited in some
                circumstances by local law requirements. In addition to the
                above-mentioned rights, you have the right to lodge a complaint with
                a competent supervisory authority subject to applicable law.
              </p>
              <p>
                The rights of access, correction, amendment and deletion are, in
                particular, provided under the Privacy Shield Frameworks.
              </p>
              <h3>Notice to California Residents</h3>
              <p>
                Under California Civil Code Sections 1798.83-1798.84, California
                residents are entitled to contact us to prevent disclosure of
                certain information to third parties for such third parties’ direct
                marketing purposes; in order to submit such a request, please
                contact us at support@asitsmade.com. California residents are
                entitled to ask us for a notice identifying the categories of
                information which we share with our affiliates and/or third parties
                for marketing purposes, and providing contact information for such
                affiliates and/or third parties. If you are a California resident
                and would like a copy of this notice, please submit a written
                request to support@asitsmade.com.
              </p>
              {/* <h3>Transborder Transfer</h3>
          <p>
            We may transfer or disclose personal information to recipients in
            countries outside of your country, including the United States,
            where we are headquartered. These countries may not have the same
            data protection laws as the country in which you initially provided
            the information. When we transfer or disclose your personal
            information to other countries, we will protect that information as
            described in this Privacy Notice. We have certified to the EU-U.S.
            Privacy Shield Framework and to the Swiss-U.S. Privacy Shield
            Framework to provide adequate safeguards for the transfer of
            personal information to the United States from the European Economic
            Area (“EEA”) and Switzerland. For more information, please see{" "}
            <a href="https://www.asitsmade.com/legal/privacy/privacy-shield#top">
              Made’s EU-U.S. Privacy Shield Policy
            </a>
            .
          </p> */}
              <h3>Security</h3>
              <p>
                We are committed to protecting your information. We seek to use
                reasonable organizational, technical, and administrative measures to
                protect information within our organization. We also take measures
                to delete your personal information or keep it in a form that does
                not permit identifying you when this information is no longer
                necessary for the purposes for which we process it or when you
                request their deletion.
              </p>
              <p>
                Unfortunately, no data transmission or storage system can be
                guaranteed to be 100% secure. Your password protects your user
                account, so you should use a unique and strong password, limit
                access to your computer and browser, and log out after having used
                our Services. If you have reason to believe that your interaction
                with us is no longer secure (for example, if you feel that the
                security of any account with us has been compromised), please
                immediately notify us of the problem by contacting us in accordance
                with the Contact Us section below.
              </p>
              <h3>Children</h3>
              <p>
                Our Services are not directed to children under the age of 13. We do
                not knowingly collect personal information from children under 13.
              </p>

              {/* <p>
            Sequentially LLC (operating as Made.) has subscribed to and will
            comply with the EU-U.S. Privacy Shield Framework and Swiss-U.S.
            Privacy Shield (the “Frameworks”) as set forth by the U.S.
            Department of Commerce regarding the processing of Personal
            Information (as defined below) that is transferred from the European
            Economic Area (“EEA”) and Switzerland to the United States,
            respectively. Made. has certified to the Department of Commerce that
            it adheres to the Privacy Shield Principles (the “Principles”).
            Made. created this Privacy Shield Policy to help you learn about how
            we handle Personal Information that is collected in the EEA and
            Switzerland and transferred to Made. in the U.S.
          </p>
          <p>
            If there is any conflict between this Policy and the Principles, the
            Principles will govern. To learn more about the Frameworks please
            visit{" "}
            <a href="https://www.privacyshield.gov/" target="_blank">
              www.privacyshield.gov
            </a>
            . You can view our certification at{" "}
            <a href="https://www.privacyshield.gov/list" target="_blank">
              https://www.privacyshield.gov/list
            </a>
          </p>
          <p>
            <a href="https://www.privacyshield.gov/list" target="_blank"></a>
            This Privacy Shield Policy supplements our Privacy Policy above.
            Capitalized terms used in this Privacy Shield Policy have the
            meaning given to them by our Privacy Policy, unless specifically
            defined in this Policy. This Privacy Shield Policy applies to Made.,
            which is subject to the investigatory and enforcement powers of the
            Federal Trade Commission.
          </p> */}
              <h3>How we Obtain Personal Information</h3>
              <p>
                We obtain and process Personal Information in different capacities.
              </p>
              <ul>
                <li>
                  As a data controller, we collect and process EEA Personal
                  Information directly from individuals, either via our publicly
                  available websites, including www.asitsmade.com, or in connection
                  with our customer, partner, and vendor relationships.
                </li>
              </ul>
              <p>
                We commit to subject to the Principles all Personal Information
                received from the EEA and Switzerland in reliance on the Frameworks.
              </p>
              <h3>
                Personal Information Received from the European Economic Area and
                Switzerland
              </h3>
              <p>
                We may receive from the EEA and Switzerland some or all of the
                information listed in our Privacy Policy. Some of that information may qualify as “personal information” or
                “personal data” (collectively, “Personal Information”) as defined in
                the Frameworks.
              </p>

              <h3>Data Integrity and Purpose Limitation</h3>
              <p>
                We may use the Personal Information we receive from the EEA and
                Switzerland for the purposes set forth in our Privacy Policy or as
                you may otherwise be notified. We take reasonable steps to ensure
                that the Personal Information we process is relevant and reliable
                for its intended use, accurate, complete, and current to the extent
                necessary for the purposes for which we use the Personal
                Information. We will not process Personal Information in a way that
                is incompatible with the purposes for which it has been collected or
                subsequently authorized by you. We will adhere to the Principles for
                as long as we retain the Personal Information collected under the
                Frameworks.
              </p>
              <p>
                When we process Personal Information on behalf of our enterprise
                customers, we process and retain Personal Information as necessary
                to provide our services to our customers, or as required or
                permitted under applicable law.
              </p>
              <h3>Onward Transfers</h3>
              <p>
                Our Privacy Policy describes the types of third parties that we may
                disclose your Personal information to, and the purposes of such
                disclosures. If we disclose your Personal Information to a third
                party acting as a data controller or as an agent, we will comply
                with, and protect the Personal Information as provided in, the
                Accountability for Onward Transfer Principle. We remain responsible
                for the processing of Personal Information received under the
                Frameworks and subsequently transferred to a third party acting as
                an agent if the agent processes such Personal Information in a
                manner inconsistent with the Principles, unless we prove that we are
                not responsible for the event giving rise to the damage.
              </p>
              <p>
                We may be required to disclose Personal Information in response to
                lawful requests by public authorities, including to meet national
                security or law enforcement requirements.
              </p>
              <h3>Choice</h3>
              <p>
                You may choose to change your Personal Information or cancel an
                account by contacting us using the contact details below. You can
                also unsubscribe from our marketing communications by following the
                instructions or unsubscribe mechanism in the e-mail message.
              </p>
              <p>
                If we intend to use your Personal Information for a purpose that is
                materially different from the purposes listed in this policy or our
                Privacy Policy, or if we intend to disclose it to a third party
                acting as a controller not previously identified, we will offer you
                the opportunity to opt-out of such uses and/or disclosures where it
                involves non-sensitive information or opt-in where sensitive
                information is involved.
              </p>
              <h3>Recourse and Enforcement</h3>
              <p>
                We commit to cooperate with EU data protection authorities (DPAs)
                and the Swiss Federal Data Protection and Information Commissioner
                (FDPIC) and comply with the advice given by such authorities with
                regard to human resources data transferred from the EU and
                Switzerland in the context of the employment relationship.
              </p>
              {/* <p>
            If you have any questions or concerns, please write to us at the
            address listed below. If you are an EEA or Swiss resident, we will
            investigate and attempt to resolve reasonable complaints and
            disputes regarding our use and disclosure of Personal Information in
            accordance with the Principles. If an issue cannot be resolved via
            our internal dispute resolution mechanism, EEA and Swiss individuals
            may contact or submit a complaint, at no cost, to the Council of
            Better Business Bureaus, our US-based third party dispute resolution
            provider. To find out more, go to{" "}
            <a href="https://www.bbb.org/eu-privacy-shield/for-eu-consumers">
              https://www.bbb.org/eu-privacy-shield/for-eu-consumers
            </a>
            . For residual complaints not fully or partially resolved by other
            means, you may be able to invoke binding arbitration before the
            Privacy Shield Panel as detailed in the{" "}
            <a href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction">
              Principles
            </a>
            .
          </p> */}

              <h3>Updates to this Policy</h3>
              <p>
                From time to time we may, in our discretion, make changes to this
                Policy. The “Last Updated” date at the top of this page indicates
                when this Privacy Policy was last revised. If we make material
                changes, we may notify you through the Services or by sending you an
                email or other communication. We encourage you to read this Privacy
                Policy periodically to stay up-to-date about our privacy practices.
                Your continued use of our Services after we have updated this Policy
                constitutes your acceptance of the changes.
              </p>
              <h3>Contact Us</h3>
              <p>
                If you have questions about this Policy please contact us.&nbsp;
              </p>
              <p>
                Sequentially LLC
                <br />
                info@asitsmade.com
                <br />
                Seattle
              </p>
              <p>Last Updated: January 25, 2023</p>

            </CenterColumn>
          </Columns>
        </StyledBackGround>
        <Footer />
      </ContentWrapper>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  isMobile: state.dimensions.window_dimensions.isMobile,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Privacy);
// export default withRouter(Privacy);
