import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setMenu } from "../actions/uiActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../custom-icons/CloseIcon";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import imgUrl from "../utils/imgUrl";
import { resetProjectLink } from "../actions/uiActions";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
    createSubscriptionGenericProfile,
    removeSubscriptionGenericProfile,
    getProfileSubscriptions,
    loadMoreProfileSubscriptions
  } from "../actions/profileActions";
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnCenter,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog
} from "./styles/MenuComponents.js";

const SubscriptionCard = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
 padding-top: 32px;
  padding-left: 32px;
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "351px")};
    width:  ${(props) => (props.isMobile ? "calc(100% - 32px)" : "100%")};
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "0px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const CircularImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const SubscriptionsDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  width: 100%;
`;
const SubName = styled.div`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
    text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 228px);
  display: block;

`;
const Description = styled.div`
  display: flex;
`;
const UnsubscribeButton = styled.div`
  display: flex;
`;

const GroupLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
class ProfilePageSubscriptions extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
     subFilter : "projects"
    };
  }
  handleSubFilterChange(e) {
    console.log("set filter");
    this.setState({ subFilter: e.target.value });
    this.props.dispatch(getProfileSubscriptions(this.props.profile_id, e.target.value));
  }
  resetLink(){
    this.props.dispatch(resetProjectLink(true));
    this.handleCloseDialog()
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    
   
    // this.props.history.replace(target);
  }
  
  loadMore = (link, type) => {
    this.props.dispatch(loadMoreProfileSubscriptions(link, type));
  };
 
  
  componentDidMount() {
    const { dimensions } = this.props;
   
   
  }
  componentDidUpdate() {
    const {
   
      dimensions,
    } = this.props;
   
  }
  unSubscribe(type, id) {
    console.log("unsubscribe");
    this.props.dispatch(removeSubscriptionGenericProfile(type, id));
  }
  Subscribe(type, id) {
    console.log("unsubscribe");
    this.props.dispatch(createSubscriptionGenericProfile(type, id));
  }
  render() {
    const {
     dimensions,
     subscriptions,
     authid
    } = this.props;
    
    console.log(this.props.subscribers)
    return (
      <div style={{ height: "100%", position: "relative" }}>
        
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={453} dialog_width={500}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Subscriptions
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialog();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
               

                <ColumnCenter directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                <ToggleButtonGroup
                 style={{
                    
                    borderRadius: "0px",
                    width: "100%"
                  }}
                                  color="primary"
                                  value={this.state.subFilter}
                                  exclusive
                                  onChange={(e) =>
                                    this.handleSubFilterChange(e)
                                  }
                                  aria-label="subscriptions"
                                  size="small"
                                  sx={{
                                   
                                   
                                  
                                  }}>
                                  <ToggleButton
                                    sx={{ width: "100%", borderRadius: "0px", borderLeft: "0px", justifyContent: "left", paddingLeft: "32px" }}
                                    value="projects">
                                    Projects
                                  </ToggleButton>
                                  <ToggleButton
                                    sx={{ width: "100%" }}
                                    value="users">
                                    People
                                  </ToggleButton>
                                  <ToggleButton
                                    sx={{ width: "100%", borderRadius: "0px", borderRight: "0px", justifyContent: "right", paddingRight: "32px" }}
                                    value="groups">
                                    Groups
                                  </ToggleButton>
                                </ToggleButtonGroup>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <SubContainer isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                    {this.state.subFilter === "projects" ? 
                                      subscriptions.projects.loading ? (
                                        <div
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}>
                                          <CircularProgress />
                                        </div>
                                      ) : 
                                        subscriptions.projects.results.map(
                                          (data, index) => {
                                            return (
                                              <div key={index}>
                                              <SubscriptionCard key={index}>
                                                <StyledLink
                                                  style={{
                                                    textDecoration: "none",
                                                    
                                                  }}
                                                  onClick={() => {
                                                    this.resetLink(
                                                    
                                                    );
                                                  }}
                                                  
                                                  to={
                                                    "/project/" +
                                                    data.id +
                                                    "/" +
                                                    data.name + "/"
                                                  }>
                                                  <ImageContainer
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        imgUrl(
                                                          data.image
                                                        ) +
                                                        ")",
                                                    }}
                                                  />
                                                </StyledLink>
                                                <SubName title={data.name}>
                                                  <StyledLink
                                                  onClick={() => {
                                                    this.resetLink(
                                                    
                                                    );
                                                  }}
                                                    style={{
                                                      textDecoration: "none",
                                                   
                                                    }}
                                                    to={
                                                      "/project/" +
                                                      data.id +
                                                      "/" +
                                                      data.name + "/"
                                                    }>
                                                   
                                                      {data.name}
                                                  
                                                  </StyledLink>
                                                  </SubName>
                                                  {/* {authid !== data.creator.id ? data.subscribed ? (<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px",
                                                  
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.unSubscribe(
                                                      "projects",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Unsubscribe
                                                </Button>):(<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px"
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.Subscribe(
                                                      "projects",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarOutlineIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Subscribe
                                                </Button>):("")} */}
                                               
                                               
                                              </SubscriptionCard>
                                              {index === subscriptions.projects.results.length - 1 && subscriptions.projects.next ? ( 
                                                        <Button
                                                          disableElevation
                                                          variant="contained"
                                                          color="primary"
                                                          onClick={() => {
                                                            this.loadMore(subscriptions.projects.next, "projects");
                                                          }}
                                                        >
                                                          Load More Subscriptions...
                                                        </Button>
                                                      ):("") }
                                              </div>
                                              
                                            );
                                          }
                                        )
                                      
                                        
                                    
                                   
                                    
                                       : (
                                      ""
                                    )}

                                    {this.state.subFilter === "users" ? (
                                      subscriptions.users.loading ? (
                                        <div
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}>
                                          <CircularProgress />
                                        </div>
                                      ) : (
                                        subscriptions.users.results.map(
                                          (data, index) => {
                                            return ( <div key={index}><SubscriptionCard key={index}>
                                              <StyledLink
                                              onClick={() => {
                                                this.resetLink(
                                                
                                                );
                                              }}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={"/" + data.username + "/"}>
                                              
                                                    <CircularImage
                                                        style={{
                                                          backgroundImage:
                                                            "url(" +
                                                            imgUrl(
                                                              data.avatar
                                                            ) +
                                                            ")",
                                                        }}
                                                        alt={`avatar for ${data.username}`}
                                                    />
               
                                              </StyledLink>
                                            
                                              <SubName  title={data.fullname || data.username}>
                                                <StyledLink
                                                onClick={() => {
                                                  this.resetLink(
                                                  
                                                  );
                                                }}
                                                  style={{
                                                    textDecoration: "none",
                                                    marginTop: "auto",
                                                    marginBottom: "auto"
                                                  }}
                                                  to={"/" + data.username + "/"}>
                                                 
                                                  {data.fullname || data.username}
                                                
                                                </StyledLink>
                                                </SubName>
                                                {/* {authid !== data.id ? data.subscribed ? (<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px",
                                                  
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.unSubscribe(
                                                      "users",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Unsubscribe
                                                </Button>):(<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px"
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.Subscribe(
                                                      "users",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarOutlineIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Subscribe
                                                </Button>):("")} */}
                                             
                                            </SubscriptionCard>
                                            {index === subscriptions.users.results.length - 1 && subscriptions.users.next ? ( 
                                              <Button
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  this.loadMore(subscriptions.users.next, "users");
                                                }}
                                              >
                                                Load More Subscriptions...
                                              </Button>
                                            ):("") }
                                            </div>
                                            )
                                            
                                          }
                                        )
                                      )
                                    ) : (
                                      ""
                                    )}
                                    {this.state.subFilter === "groups" ? (
                                      subscriptions.groups.loading ? (
                                        <div
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}>
                                          <CircularProgress />
                                        </div>
                                      ) : (
                                        subscriptions.groups.results.map(
                                          (data, index) => {
                                            return (
                                              <div key={index}>
                                              <SubscriptionCard >
                                                <StyledLink
                                                onClick={() => {
                                                  this.resetLink(
                                                  
                                                  );
                                                }}
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                  to={"/group/" + data.id + "/" + data.name + "/"}>
                                                  <ImageContainer
                                                    style={{
                                                      backgroundImage:
                                                        "url(" +
                                                        imgUrl(
                                                          data.image
                                                        ) +
                                                        ")",
                                                    }}
                                                  />
                                                </StyledLink>
                                              
                                                <SubName title={data.name}>
                                                  <StyledLink
                                                  onClick={() => {
                                                    this.resetLink(
                                                    
                                                    );
                                                  }}
                                                    style={{
                                                      textDecoration: "none",
                                                      marginTop: "auto",
                                                      marginBottom: "auto"
                                                    }}
                                                    to={"/group/" + data.id + "/" + data.name + "/"}>
                                                 
                                                      {data.name}
                                                  
                                                  </StyledLink>
                                                  </SubName>
                                                  {/* {data.subscribed ? (<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px",
                                                  
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.unSubscribe(
                                                      "groups",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Unsubscribe
                                                </Button>):(<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px"
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.Subscribe(
                                                      "groups",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarOutlineIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Subscribe
                                                </Button>)} */}
                                              
                                                </SubscriptionCard>
                                                {index === subscriptions.groups.results.length - 1 && subscriptions.groups.next ? ( 
                                              <Button
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  this.loadMore(subscriptions.groups.next, "groups");
                                                }}
                                              >
                                                Load More Subscriptions...
                                              </Button>
                                            ):("") }
                                            </div>
                                            )
                                          }
                                        )
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </SubContainer>
                                </ControlLine>
                </ColumnCenter>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
 
  dimensions: state.dimensions,
  subscriptions: state.profile.subscriptions,
  authid: state.auth.user.user_id,
  profile_id: state.profile.current_profile.id
});
export default connect(mapStateToProps)(withRouter(ProfilePageSubscriptions));
