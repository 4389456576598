import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import styled from "styled-components";
import { connect } from "react-redux";

import { clearNotifications } from "../actions/profileActions";
import {
  clearInvite,
  acceptInvite,
  loadMoreNotifications,
  acceptFollowRequest,
  rejectFollowRequest
} from "../actions/profileActions";

import { setMenu } from "../actions/uiActions";

import Button from "@mui/material/Button";
import friendlyTimeCalc from "../utils/timeAgo";
import { api_endpoint } from "../config";
const MenuLineNot = styled.div`
  // height: 50px;
  //   border-bottom: 1px solid #f2f2f2;
  border-bottom: ${(props) => (props.lastpos ? "none" : "1px solid #f2f2f2")};
  border-bottom-left-radius: ${(props) => (props.lastpos ? "6px" : "none")};
  border-bottom-right-radius: ${(props) => (props.lastpos ? "6px" : "none")};
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuLineNotLast = styled.div`
  // height: 50px;
  margin-left: 23px;
  margin-bottom: 24px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MoreButton = styled.div`
  width: 100%;
  display: flex;
  margin-left: 45px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
const MenuLabelNot = styled.div`
  font-size: 12px;

  padding: 10px;
  text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
`;
const SectionLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-left: 32px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #757575;
  text-align: left;
  display: flex;
`;
const ClearButton = styled.div`
  margin-left: auto;
  margin-top: -6px;
  margin-right: 10px;
`;
const AuthorImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: url("${(props) => props.src}") #008EFF;
  background-position: center;
  background-size: cover;
`;

const AuthorImageContainer = styled.div`
  height: 100%;
  margin-left: 12px;
  margin-top: 10px;
  margin-right: 2px;
`;
const SingleNotContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 44px;
`;

const NotTextContainer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  // margin-top: 8px;
  // margin-bottom: 8px;
  padding: 8px;
  color: #111;
  min-width: 250px;
`;
const DateStyle = styled.div`
  color: #8e8e8e;
  margin-top: 3px;
  display: flex;
`;
const ButtonSet = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
`;
class NotificationsList extends Component {
  constructor() {
    super();
    this.state = {};
  }

  clearNotifications = () => {
    const { notifications } = this.props;
    if (notifications.allIDS.length) {
      let NotList = { ids: [] };
      notifications.allIDS.forEach((Not) => {
        if (Not.n_type !== "I" && Not.n_type !== "IO") {
          NotList["ids"].push(Not);
        }
      });
      //console.log(NotList);
      this.props.dispatch(clearNotifications(NotList));
    }
    this.props.dispatch(setMenu(false));
  };
  loadMoreNotifications = (link) => {
    this.props.dispatch(loadMoreNotifications(link));
  };
  closeMenu = (linkurl) => {
    window.location.href = linkurl;
    this.props.dispatch(setMenu(false));
  };
  rejectInvite = (inviteid, notifid) => {
    // clear notification
    const NotList = { ids: [notifid] };
    this.props.dispatch(clearNotifications(NotList));
    // clear invite
    this.props.dispatch(clearInvite({ from_groups: false, id: inviteid }));
    this.props.dispatch(setMenu(false));
  };
  acceptInvite = (inviteid, notifid) => {
    // clear notification
    const NotList = { ids: [notifid] };
    this.props.dispatch(clearNotifications(NotList));
    // clear invite
    this.props.dispatch(acceptInvite({ from_groups: false, id: inviteid }));
    this.props.dispatch(setMenu(false));
  };
  acceptRequest = (part_id, notifid) => {
    const NotList = { ids: [notifid] };
    this.props.dispatch(clearNotifications(NotList));
    this.props.dispatch(acceptFollowRequest(part_id));
  };
  rejectRequest = (part_id, notifid) => {
    const NotList = { ids: [notifid] };
    this.props.dispatch(clearNotifications(NotList));
    this.props.dispatch(rejectFollowRequest(part_id));
  };
  render() {
    const { notifications } = this.props;

    // TODO: Maybe break out these notification types into their own components

    return (
      <div>
        <SectionLabel>
          Notifications
          {notifications.allIDS.length ? (
            <ClearButton>
              {" "}
              <Button
                onClick={() => {
                  this.clearNotifications();
                }}
              >
                Clear
              </Button>
            </ClearButton>
          ) : (
            ""
          )}
        </SectionLabel>
        {!notifications.allIDS.length ? (
          <MenuLineNotLast>
            <MenuLabelNot>No Notifications</MenuLabelNot>
          </MenuLineNotLast>
        ) : (
          <div>
            {
              // eslint-disable-next-line array-callback-return
              notifications.allIDS.map((notid, index) => {
                const not = notifications.byID[notid];
                let last = false;
                if (index === notifications.allIDS.length - 1) {
                  last = true;
                }
                let linkurl = "";

                switch (not.n_type) {
                  case "C":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      if (not.data.group) {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() +
                          "/group/" +
                          not.data.group.id +
                          "/element/" +
                          not.data.element + "/";
                      } else {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() +
                          "/element/" +
                          not.data.element + "/";
                      }

                      return (
                        <MenuLineNot
                          onClick={() => {
                            this.closeMenu(linkurl);
                          }}
                          key={index}
                          lastpos={last}
                        >
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"

                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator.username}</b> added a new comment
                              to project <b>{not.data.project.name}</b>{" "}
                              {not.data.group && "in group"}{" "}
                              <b>{not.data.group && not.data.group.name}</b>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  case "NP":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      if (not.data.group) {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() +
                          "/group/" +
                          not.data.group.id + "/";
                      } else {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() + "/";
                      }

                      return (
                        <MenuLineNot
                          onClick={() => {
                            this.closeMenu(linkurl);
                          }}
                          key={index}
                          lastpos={last}
                        >
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>

                              <b>{not.creator.username}</b> shared a new
                              project <b>{not.data.project.name}</b> {not.data.group ? (<span>in group <b>{not.data.group.name}</b></span>) : ("")}
                              <DateStyle>
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  case "NE":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      if (not.data.group) {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() +
                          // "/group/" +
                          // not.data.group.id +
                          "/element/" +
                          not.data.element.id + "/";
                      } else {
                        linkurl =
                          "/project/" +
                          not.data.project.id +
                          "/" +
                          not.data.project.name
                            .replace(/\s+/g, "-")
                            .toLowerCase() +
                          "/element/" +
                          not.data.element.id + "/";
                      }

                      return (
                        <MenuLineNot
                          onClick={() => {
                            this.closeMenu(linkurl);
                          }}
                          key={index}
                          lastpos={last}
                        >
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator.username}</b> created a new
                              element in project <b>{not.data.project.name}</b>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  case "I":
                    if (not.data.error) {
                      //  console.log(not.data.error)
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      return (
                        <MenuLineNot key={index} lastpos={last}>
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator &&
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator && not.creator.username}</b> has invited you to
                              group <b>{not.data.group.name}</b>
                              <ButtonSet>
                                <Button
                                  onClick={() => {
                                    this.rejectInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="secondary"
                                  style={{ minWidth: "100px" }}
                                >
                                  Ignore
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.acceptInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Join
                                </Button>
                              </ButtonSet>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;

                  case "IO":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      return (
                        <MenuLineNot key={index} lastpos={last}>
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator.username}</b> has invited you to
                              organization <b>{not.data.organization.name}</b>
                              <ButtonSet>
                                <Button
                                  onClick={() => {
                                    this.rejectInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="secondary"
                                  style={{ minWidth: "100px" }}
                                >
                                  Ignore
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.acceptInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Join
                                </Button>
                              </ButtonSet>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  case "FI":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      return (
                        <MenuLineNot key={index} lastpos={last}>
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator.username}</b> has invited you to follow.
                              <ButtonSet>
                                <Button
                                  onClick={() => {
                                    this.rejectInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="secondary"
                                  style={{ minWidth: "100px" }}
                                >
                                  Ignore
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.acceptInvite(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Accept
                                </Button>
                              </ButtonSet>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  case "FR":
                    if (not.data.error) {
                      this.props.dispatch(
                        clearNotifications({ ids: [not.id] })
                      );
                    } else {
                      return (
                        <MenuLineNot key={index} lastpos={last}>
                          <SingleNotContainer>
                            <AuthorImageContainer>
                              <AuthorImage
                                src={
                                  not.creator.avatar &&
                                  api_endpoint + "/media/" + not.creator.avatar.id + "/file?size=256"
                                }
                              ></AuthorImage>
                            </AuthorImageContainer>

                            <NotTextContainer>
                              <b>{not.creator.username}</b> has requested to follow you.
                              <ButtonSet>
                                <Button
                                  onClick={() => {
                                    this.rejectRequest(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="secondary"
                                  style={{ minWidth: "100px" }}
                                >
                                  Ignore
                                </Button>
                                <Button
                                  onClick={() => {
                                    this.acceptRequest(not.obj_1, not.id);
                                  }}
                                  disableElevation
                                  variant="contained"
                                  color="primary"
                                  style={{
                                    marginLeft: "auto",
                                    marginRight: "10px",
                                    minWidth: "100px",
                                  }}
                                >
                                  Accept
                                </Button>
                              </ButtonSet>
                              <DateStyle>
                                {" "}
                                {friendlyTimeCalc(not.created_at, "comment")}
                              </DateStyle>
                            </NotTextContainer>
                          </SingleNotContainer>
                        </MenuLineNot>
                      );
                    }
                    break;
                  default:
                }
              })
            }
            {notifications.next ? (
              <MenuLineNot lastpos={true}>
                <MoreButton>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.loadMoreNotifications(notifications.next);
                    }}
                  >
                    Load More Notifications...
                  </Button>
                </MoreButton>
              </MenuLineNot>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.auth.notifications,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(NotificationsList));
