import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveGroup,
  uploadSaveGroup,
  deleteGroup,
} from "../actions/groupActions";
import {
  getMyProfileDataID,
} from "../actions/profileActions";

import { sendInvite } from "../actions/groupActions";
import friendlyTimeCalc from "../utils/timeAgo";
import { sendOrgInvite } from "../actions/orgActions";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FormControl from "@mui/material/FormControl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setGroupSettings } from "../actions/uiActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import { addComment } from "../actions/projectActions";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { setMenu } from "../actions/uiActions";
import CloseIcon from "../custom-icons/CloseIcon";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Rating, { IconContainerProps } from "@mui/material/Rating";

import StarIcon from "@mui/icons-material/Star";
import CircleIcon from "@mui/icons-material/Circle";
import ToggleButton from "@mui/material/ToggleButton";
import Slider from "@mui/material/Slider";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";
import DeblurOutlinedIcon from "@mui/icons-material/DeblurOutlined";
import VrpanoOutlinedIcon from "@mui/icons-material/VrpanoOutlined";
import NaturePeopleOutlinedIcon from "@mui/icons-material/NaturePeopleOutlined";
import TextureOutlinedIcon from "@mui/icons-material/TextureOutlined";
import FilterDramaOutlinedIcon from "@mui/icons-material/FilterDramaOutlined";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import BrushOutlinedIcon from "@mui/icons-material/BrushOutlined";
import WallpaperOutlinedIcon from "@mui/icons-material/WallpaperOutlined";
import AllInclusiveOutlinedIcon from "@mui/icons-material/AllInclusiveOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import FaceIcon from '@mui/icons-material/Face';
import {
  ElementContainer,
  TopContainer,
  ElementCard,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton,
} from "./styles/MenuComponents.js";

// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;
// const StyledSelect = styled.select`
//   width: 150px;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   margin-right: 20px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const InviteInstruction = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  // letter-spacing: 0.00938em;
`;
const SearchResults = styled.div`
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
`;
const MemberList = styled.div`
  // height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: auto;
`;
const SearchLineItem = styled.div`
  width: 100%;
  height: 70px;
  // border-bottom: 1px solid #ccc;
  display: flex;
  margin-top: 0px;
`;
const PendingItem = styled.div`
  height: 50px;

  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  border-bottom: 1px solid #ccc;
  display: flex;
`;
const PendingItemEmail = styled.div`
  margin-top: 15px;
`;
const UserNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: ##000000de;
`;
const OtherNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
`;
const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 15px;
`;
const LeftColumn = styled.div`
  // width: 10%;
  diplay: flex;
  flex-direction: column;
`;
const NameColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumn = styled.div`
  width:100%
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnNarrow = styled.div`
  width: 100px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnWide = styled.div`
  min-width: 150px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const RightColumn = styled.div`
  // width: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "350px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnSingle = styled.div`
  padding-left: ${(props) => (props.isMobile ? "32px" : "32px")};
  padding-right: ${(props) => (props.isMobile ? "32px" : "32px")};
  padding-bottom: ${(props) => (props.isMobile ? "32px" : "32px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};
  padding-top: 5px;
  height: 100%;
  //   background-color: #ccc;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const LimitAlert = styled.div`
  width: 100%;
  color: #dd4d25;
  margin-top: ${(props) => (props.isMobile ? "32px" : "30px")};
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const EmailError = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #dd4d25;
  margin-top: 20px;
`;
const ControlLineBanner = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const MenuLineLast = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuLineFirst = styled.div`
  height: 45px;
  padding-top: 5px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;
const DividerLine = styled.div`
  // height: 1px;
  border-bottom: 1px solid #f2f2f2;

  width: 100%;
`;
const MenuLineCommand = styled.div`
  height: 60px;
  padding-top: 15px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuLineCommandLast = styled.div`
  height: 60px;
  padding-top: 15px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuIcon = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 11px;
  margin-left: 5px;
`;
const MenuLabel = styled.div`
max-width: 193px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
const MenuLabelSingle = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  margin-left: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  display: flex;
`;
const ControlContentMembers = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
`;

const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;

// const StyledButtonNew = styled.button`
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 150px;
//   background-color: #2cc0f4;
//   color: white;
//   height: 32px;
//   border-radius: 2px;

//   padding-right: 30px;
//   padding-left: 30px;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const AuthorImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
`;
const StyledElementPopup = styled.div`
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  height: calc(100% - 14px);
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;

const Alignlineitem = styled.div`
  margin-top: 10px;
  height: 42px;
  display: flex;
`;
const AlignSlider = styled.div`
  //   width: 200px;
`;
const AlignInput = styled.input`
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  width: 40px;
  height: 20px;
  margin-top: 2px;
  margin-left: auto;
  text-align: right;
`;
const AlignLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 4px;
  padding-left: 10px;
  width: 100px;
`;
const StyledTable = styled.table`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
`;
const StyledTD = styled.td`
  text-align: center;
  min-width: 25px;
`;
const TextUI = styled.div`
  line-height: 250%;
  margin-top: 10px;
`;
const TextUICompare = styled.div`
  line-height: 150%;
  margin-top: 10px;
`;
const ActiveText = styled.span`
  background-color: #e9f5fe;
  color: #008eff;
  cursor: pointer;
`;
const FeedbackInstruction = styled.div`
  font-size: 12px;
`;
const CommentDivider = styled.div`
  border-left: 1px solid #c4c4c4;
  min-height: 100%;
`;
const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#008EFF",
  },
  "& .MuiRating-iconHover": {
    color: "#6ea8ec",
  },
});
const MenuLine = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const OptionTitle = styled.div`
  // font-weight: bold;
  line-height: 1.5;
  margin-bottom: -10px;
`;

const OptionSubtitle = styled.div`
  font-size: 0.75rem;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.54);
`;
class FeedbackRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      panelW: 288,
      disablebutton: false,
      aifeedback: true,
      peoplefeedback: false,
      broadcast: true,
      project_state: "IN",
      feedback_type: 1,
      selected_focus: [0],
      completevalue: 2,
      completevaluehover: false,
      satvalue: 3,
      feedback_types: {
        1: {
          ui_name: "any",
          menu_name: "Any feedback",
          icon: <FeedbackOutlinedIcon />,
        },
        2: {
          ui_name: "positive",
          menu_name: "Positive feedback",
          icon: <SentimentVerySatisfiedIcon />,
        },
        3: {
          ui_name: "critical",
          menu_name: "Critical feedback",
          icon: <EmojiObjectsOutlinedIcon />,
        },
      },
      complete_stages: {
        1: {
          ui_name: "initial sketch",
          menu_name: "Initial sketch",
          icon: <DrawOutlinedIcon />,
        },
        2: {
          ui_name: "work in progress",
          menu_name: "Work in progress",
          icon: <ConstructionOutlinedIcon />,
        },
        3: {
          ui_name: "completed work",
          menu_name: "Completed work",
          icon: <DoneOutlineOutlinedIcon />,
        },
      },
      convert_stages: {
        "1 Star": 1,
        "2 Stars": 2,
        "3 Stars": 3,
        "4 Stars": 4,
        "5 Stars": 5,
      },
      focusareas: {
        0: {
          ui_name: "everything",
          menu_name: "Everything",
          icon: <AllInclusiveOutlinedIcon />,
          show: ["art", "compare"]
        },
        1: {
          ui_name: "technique",
          menu_name: "Technique",
          icon: <BrushOutlinedIcon />,
          show: ["art"]
        },
        2: {
          ui_name: "composition",
          menu_name: "Composition",
          icon: <WallpaperOutlinedIcon />,
          show: ["art"]
        },
        3: {
          ui_name: "color/value",
          menu_name: "Color and Value",
          icon: <ColorLensOutlinedIcon />,
          show: ["art", "compare"]
        },
        4: {
          ui_name: "edges",
          menu_name: "Edges",
          icon: <DeblurOutlinedIcon />,
          show: ["art"]
        },
        5: {
          ui_name: "light/shadow",
          menu_name: "Light and Shadow",
          icon: <Brightness6Icon />,
          show: ["art"]
        },
        6: {
          ui_name: "idea",
          menu_name: "Idea",
          icon: <PsychologyOutlinedIcon />,
          show: ["art"]
        },
        7: {
          ui_name: "texture",
          menu_name: "Texture",
          icon: <TextureOutlinedIcon />,
          show: ["art"]
        },

        8: {
          ui_name: "accuracy",
          menu_name: "Accuracy",
          icon: <ManageSearchOutlinedIcon />,
          show: ["art", "compare"]
        },
        9: {
          ui_name: "perspective",
          menu_name: "Perspective",
          icon: <VrpanoOutlinedIcon />,
          show: ["art", "compare"]
        },
        10: {
          ui_name: "mood/atmosphere",
          menu_name: "Mood and Atmosphere",
          icon: <FilterDramaOutlinedIcon />,
          show: ["art"]
        },
        11: {
          ui_name: "facial likeness",
          menu_name: "Facial Likeness",
          icon: <FaceIcon />,
          show: ["compare"]
        },
      },
    };
  }
  onChangeSectionNumber = (num) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });

    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) });
    } else {
      this.setState({ x2: -this.state.panelW });
    }
    switch (num) {
      case 1:
        this.setState({
          pastState: [
            this.state.fullname,
            this.state.tag_line,
            this.state.username,
            this.state.email,
          ],
        });
        break;
      case 2:
        this.setState({
          pastState: [this.state.subscribed, this.state.subscribedProduct],
        });
        break;
      case 3:

      default:
      // code block
    }
  };
  onSlideback = (state, bool) => {
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 1:
        this.setState({ fullname: this.state.pastState[0] });
        this.setState({ tag_line: this.state.pastState[1] });
        this.setState({ username: this.state.pastState[2] });
        this.setState({ email: this.state.pastState[3] });
        break;
      case 2:
        this.setState({ subscribed: this.state.pastState[0] });
        this.setState({ subscribedProduct: this.state.pastState[1] });
        break;
      case 3:
        break;

      default:
      // code block
    }
  };
  handleClosePopup() {
    document.body.style.overflow = "auto";
    this.props.dispatch(setMenu(false));
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentDidMount() {
    const { dimensions, next_try } = this.props;
    this.props.dispatch(getMyProfileDataID());
    if (next_try) {
      this.setState({ disablebutton: true });
    }
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
  }
  componentDidUpdate() {
    const { dimensions } = this.props;
    //console.log("update");

    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
  }
  setFeedbackType(type) {
    this.setState({ feedback_type: type });
    this.onSlideback();
  }
  setFocusArea(number) {
    let new_focus = this.state.selected_focus;

    if (number === 0) {
      if (this.state.selected_focus.includes(number)) {
        new_focus = [0];
      } else {
        new_focus = [0];
      }
    } else {
      if (this.state.selected_focus.includes(0)) {
        new_focus.splice(new_focus.indexOf(0), 1);
      }
      if (this.state.selected_focus.includes(number)) {
        new_focus.splice(new_focus.indexOf(number), 1);
      } else {
        new_focus.push(number);
      }
    }
    this.setState({ selected_focus: new_focus });
  }
  setWorkPhase(type) {
    this.setState({ completevalue: type });
    this.onSlideback();
  }
  onChangeRadio = (e) => {
    this.setState({ feedback_type: e.target.value });
  };
  onChangeAI = (e) => {
    this.setState({ aifeedback: e.target.checked });
  };
  onChangeBroadcast = (e) => {
    this.setState({ broadcast: e.target.checked });
  };
  setSatValue = (e) => {
    this.setState({ satvalue: e.target.value });
  };
  setCompleteValue = (e) => {
    this.setState({ completevalue: e.target.value });
  };
  setCompleteValueHover = (e) => {
    console.log("hover", e.target.innerText);
    if (
      this.state.completevaluehover !== e.target.innerText ||
      this.state.completevaluehover === false
    ) {
      this.setState({ completevaluehover: e.target.innerText });
    } else {
      this.setState({ completevaluehover: false });
    }
  };
  onFocusChange = (event, values) => {
    this.setState(
      {
        selected_focus: values,
      },
      () => {}
    );
  };
  handleAddComment = () => {
    const { selectedelement } = this.props;
    let areas = "";
    let concat_content =
      "I am requesting " +
      this.state.feedback_types[this.state.feedback_type].ui_name +
      " feedback on my " +
      this.state.complete_stages[this.state.completevalue].ui_name +
      " , focusing on ";

    this.state.selected_focus.map((num, index) => {
      if (this.state.selected_focus.length === 1) {
        concat_content = concat_content + this.state.focusareas[num].ui_name;
      } else {
        if (index === 0) {
          concat_content = concat_content + this.state.focusareas[num].ui_name;
        } else {
          if (index < this.state.selected_focus.length - 1) {
            concat_content =
              concat_content + ", " + this.state.focusareas[num].ui_name;
          } else {
            concat_content =
              concat_content + " and " + this.state.focusareas[num].ui_name;
          }
        }
      }
    });

    concat_content = concat_content + ".";
    if (selectedelement.element_type === "CO") {
      concat_content =
        "I am requesting to compare the two images and identify differences.";
    }
    let data = {
      content: concat_content,
      element: selectedelement.element,
      public: true,
      comment_type: "FR",
      ai: true,
      focus: this.state.selected_focus,
      sentiment: this.state.feedback_type,
      completion: this.state.completevalue,
    };
    if (selectedelement.group) {
      data.public = false;
    }
    if (selectedelement.group) {
      data.group = selectedelement.group;
    }

    if (this.state.broadcast) {
      data.public = true;
      data.broadcast = true;
      data.group = null;
    }

    console.log(data);

    this.props.dispatch(addComment(data));
    this.props.dispatch(setMenu(false));
  };
  render() {
    const { dimensions, selectedelement, next_try } = this.props;

    return (
      <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
        <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
          <Dialog
            isMobile={dimensions.window_dimensions.isMobile}
            dialog_height={"auto"}
            dialog_width={350}>
            <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
              <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                Request for AI Feedback
              </SettingsTitle>

              <CloseButtonNarrow
                isMobile={dimensions.window_dimensions.isMobile}
                onClick={() => {
                  this.handleClosePopup();
                }}>
                {" "}
                <IconButton>
                  <CloseIcon sx={{ fontSize: "22px" }} />
                </IconButton>
              </CloseButtonNarrow>
            </SettingsHeader>
            <SettingsColumns>
              <ColumnSingle isMobile={dimensions.window_dimensions.isMobile}>
                {this.state.disablebutton ? (""):("")}
                <CropPanel
                  w={this.state.panelW}
                  isMobile={dimensions.window_dimensions.isMobile}>
                  <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                    <PanelOne w={this.state.panelW}>
                      {this.state.paneltwo === 0 ? (
                        <div>
                          {/* <FormGroup style={{ marginBottom: 8 }}>
                            <div>
                              <OptionTitle>Request feedback from:</OptionTitle>
                              <OptionSubtitle>
                     
                    </OptionSubtitle>
                            </div>
                          </FormGroup> */}

                          {/* <FormGroup row style={{ paddingRight: "0" }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple
                                  color="primary"
                                  checked={this.state.aifeedback}
                                  onChange={(e) => {
                                    this.onChangeAI(e);
                                  }}
                                  name="AI"
                                />
                              }
                              label="AI Instructor"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disableRipple
                                  color="primary"
                                  disabled
                                  checked={false}
                                  onChange={(e) => {
                                    this.onChangePeople(e);
                                  }}
                                  name="People"
                                />
                              }
                              label="People (TBD)"
                            />
                          </FormGroup> */}
                          {selectedelement.element_type === "CO" ? (
                            <TextUICompare>
                              I am requesting to compare the two images and
                              identify differences, specifically focusing on{" "}
                              <ActiveText
                                onClick={() => {
                                  this.onChangeSectionNumber(4);
                                }}>
                                {this.state.selected_focus.map((num, index) => {
                                  if (this.state.selected_focus.length === 1) {
                                    return this.state.focusareas[num].ui_name;
                                  } else {
                                    if (index === 0) {
                                      return this.state.focusareas[num].ui_name;
                                    } else {
                                      if (
                                        index <
                                        this.state.selected_focus.length - 1
                                      ) {
                                        return (
                                          ", " +
                                          this.state.focusareas[num].ui_name
                                        );
                                      } else {
                                        return (
                                          " and " +
                                          this.state.focusareas[num].ui_name
                                        );
                                      }
                                    }
                                  }
                                })}
                              </ActiveText>
                            </TextUICompare>
                          ) : (
                            <TextUI>
                              I am requesting{" "}
                              <ActiveText
                                onClick={() => {
                                  this.onChangeSectionNumber(1);
                                }}>
                                {
                                  this.state.feedback_types[
                                    this.state.feedback_type
                                  ].ui_name
                                }
                              </ActiveText>{" "}
                              feedback on my{" "}
                              <ActiveText
                                onClick={() => {
                                  this.onChangeSectionNumber(2);
                                }}>
                                {
                                  this.state.complete_stages[
                                    this.state.completevalue
                                  ].ui_name
                                }
                              </ActiveText>
                              , focusing on{" "}
                              <ActiveText
                                onClick={() => {
                                  this.onChangeSectionNumber(3);
                                }}>
                                {this.state.selected_focus.map((num, index) => {
                                  if (this.state.selected_focus.length === 1) {
                                    return this.state.focusareas[num].ui_name;
                                  } else {
                                    if (index === 0) {
                                      return this.state.focusareas[num].ui_name;
                                    } else {
                                      if (
                                        index <
                                        this.state.selected_focus.length - 1
                                      ) {
                                        return (
                                          ", " +
                                          this.state.focusareas[num].ui_name
                                        );
                                      } else {
                                        return (
                                          " and " +
                                          this.state.focusareas[num].ui_name
                                        );
                                      }
                                    }
                                  }
                                })}
                              </ActiveText>
                              .
                            </TextUI>
                          )}
                          {selectedelement.groups > 0 ? (
                            <FormGroup
                              row
                              style={{ paddingRight: "0", marginTop: "10px" }}>
                              <FormControlLabel
                                style={{ fontSize: "12px" }}
                                control={
                                  <Checkbox
                                    disableRipple
                                    color="primary"
                                    checked={this.state.broadcast}
                                    onChange={(e) => {
                                      this.onChangeBroadcast(e);
                                    }}
                                    name="AI"
                                  />
                                }
                                label="Display in all groups and public"
                              />
                            </FormGroup>
                          ) : (
                            ""
                          )}
                          {next_try ? (
                            <LimitAlert>
                              Your limit for requesting AI feedback is 7 times per week. You can make another request on {new Date(
                                                next_try
                                              ).toLocaleString(
                                                                                    
                                              )}
                            </LimitAlert>
                          ):("")}
                          <StyledButtonContainer>
                            <StyledButton>
                              <Button
                                onClick={() => {
                                  this.handleAddComment();
                                }}
                                disableElevation
                                disabled={this.state.disablebutton}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "150px" }}>
                                Submit Request
                              </Button>
                            </StyledButton>
                          </StyledButtonContainer>
                        </div>
                      ) : (
                        ""
                      )}
                    </PanelOne>
                    <PanelTwo w={this.state.panelW}>
                      {
                        {
                          0: (
                            <SectionContent
                              w={this.state.panelW}></SectionContent>
                          ),
                          1: (
                            <SectionContent w={this.state.panelW}>
                              <SectionHeader
                                onClick={() => {
                                  this.onSlideback();
                                }}>
                                {" "}
                                <BackIcon>
                                  <ChevronLeftIcon fontSize="inherit" />
                                </BackIcon>{" "}
                                <SectionLabel2>
                                  Select the preferred feedback
                                </SectionLabel2>
                              </SectionHeader>

                              <ControlLine
                                isMobile={
                                  dimensions.window_dimensions.isMobile
                                }>
                                {Object.keys(this.state.feedback_types).map(
                                  (key) => {
                                    return (
                                      <MenuLine
                                        onClick={() => {
                                          this.setFeedbackType(key);
                                        }}>
                                        <MenuIcon>
                                          {this.state.feedback_types[key].icon}
                                        </MenuIcon>
                                        <MenuLabel>
                                          {
                                            this.state.feedback_types[key]
                                              .menu_name
                                          }
                                        </MenuLabel>
                                      </MenuLine>
                                    );
                                  }
                                )}
                              </ControlLine>
                              {/* <SectionHelpText>
                                  You will only receive notification emails when
                                  there are updates to your subscriptions.
                                </SectionHelpText> */}
                              <SectionFooter>
                                <ButtonContainer>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="black"
                                    style={{ minWidth: "80px" }}
                                    onClick={() => {
                                      this.onSlidebackCancel(2);
                                    }}>
                                    Cancel
                                  </Button>
                                  {/* <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button> */}
                                </ButtonContainer>
                              </SectionFooter>
                            </SectionContent>
                          ),
                          2: (
                            <SectionContent w={this.state.panelW}>
                              <SectionHeader
                                onClick={() => {
                                  this.onSlideback();
                                }}>
                                {" "}
                                <BackIcon>
                                  <ChevronLeftIcon fontSize="inherit" />
                                </BackIcon>{" "}
                                <SectionLabel2>
                                  Select the phase of the work
                                </SectionLabel2>
                              </SectionHeader>

                              <ControlLine
                                isMobile={
                                  dimensions.window_dimensions.isMobile
                                }>
                                {Object.keys(this.state.complete_stages).map(
                                  (key) => {
                                    return (
                                      <MenuLine
                                        onClick={() => {
                                          this.setWorkPhase(key);
                                        }}>
                                        <MenuIcon>
                                          {this.state.complete_stages[key].icon}
                                        </MenuIcon>
                                        <MenuLabel>
                                          {
                                            this.state.complete_stages[key]
                                              .menu_name
                                          }
                                        </MenuLabel>
                                      </MenuLine>
                                    );
                                  }
                                )}
                              </ControlLine>
                              {/* <SectionHelpText>
                                You will only receive notification emails when
                                there are updates to your subscriptions.
                              </SectionHelpText> */}
                              <SectionFooter>
                                <ButtonContainer>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="black"
                                    style={{ minWidth: "80px" }}
                                    onClick={() => {
                                      this.onSlidebackCancel(3);
                                    }}>
                                    Cancel
                                  </Button>
                                  {/* <Button
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      minWidth: "80px",
                                      marginLeft: "auto",
                                    }}
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    Apply
                                  </Button> */}
                                </ButtonContainer>
                              </SectionFooter>
                            </SectionContent>
                          ),
                          3: (
                            <SectionContent w={this.state.panelW}>
                              <SectionHeader
                                onClick={() => {
                                  this.onSlideback();
                                }}>
                                {" "}
                                <BackIcon>
                                  <ChevronLeftIcon fontSize="inherit" />
                                </BackIcon>{" "}
                                <SectionLabel2>
                                  Select the focus areas
                                </SectionLabel2>
                              </SectionHeader>

                              <ControlLine
                                isMobile={
                                  dimensions.window_dimensions.isMobile
                                }>
                                {Object.keys(this.state.focusareas).map(
                                  (key) => {
                                    if (this.state.focusareas[key].show.includes("art")){
                                      return (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disableRipple
                                              color="primary"
                                              checked={this.state.selected_focus.includes(
                                                parseInt(key)
                                              )}
                                              onChange={(e) => {
                                                this.setFocusArea(parseInt(key));
                                              }}
                                              name="AI"
                                            />
                                          }
                                          icon={this.state.focusareas[key].icon}
                                          label={
                                            <Box style={{ display: "flex" }}>
                                              {this.state.focusareas[key].icon}
                                              <div style={{ marginLeft: "10px" }}>
                                                {
                                                  this.state.focusareas[key]
                                                    .menu_name
                                                }
                                              </div>
                                            </Box>
                                          }
                                        />
                                      );
                                    }else{
                                      return ("")
                                    }
                                    
                                  }
                                )}
                              </ControlLine>
                              {/* <SectionHelpText>
                                  You will only receive notification emails when
                                  there are updates to your subscriptions.
                                </SectionHelpText> */}
                              <SectionFooter>
                                <ButtonContainer>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="black"
                                    style={{ minWidth: "80px" }}
                                    onClick={() => {
                                      this.onSlidebackCancel(2);
                                    }}>
                                    Cancel
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      minWidth: "80px",
                                      marginLeft: "auto",
                                    }}
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    Apply
                                  </Button>
                                </ButtonContainer>
                              </SectionFooter>
                            </SectionContent>
                          ),
                          4: (
                            <SectionContent w={this.state.panelW}>
                              <SectionHeader
                                onClick={() => {
                                  this.onSlideback();
                                }}>
                                {" "}
                                <BackIcon>
                                  <ChevronLeftIcon fontSize="inherit" />
                                </BackIcon>{" "}
                                <SectionLabel2>
                                  Select the focus areas
                                </SectionLabel2>
                              </SectionHeader>

                              <ControlLine
                                isMobile={
                                  dimensions.window_dimensions.isMobile
                                }>
                                {Object.keys(this.state.focusareas).map(
                                  (key) => {
                                    if (this.state.focusareas[key].show.includes("compare")){
                                      return (
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              disableRipple
                                              color="primary"
                                              checked={this.state.selected_focus.includes(
                                                parseInt(key)
                                              )}
                                              onChange={(e) => {
                                                this.setFocusArea(parseInt(key));
                                              }}
                                              name="AI"
                                            />
                                          }
                                          icon={this.state.focusareas[key].icon}
                                          label={
                                            <Box style={{ display: "flex" }}>
                                              {this.state.focusareas[key].icon}
                                              <div style={{ marginLeft: "10px" }}>
                                                {
                                                  this.state.focusareas[key]
                                                    .menu_name
                                                }
                                              </div>
                                            </Box>
                                          }
                                        />
                                      );
                                    }else{
                                      return ("")
                                    }
                                    
                                  }
                                )}
                              </ControlLine>
                              {/* <SectionHelpText>
                                  You will only receive notification emails when
                                  there are updates to your subscriptions.
                                </SectionHelpText> */}
                              <SectionFooter>
                                <ButtonContainer>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="black"
                                    style={{ minWidth: "80px" }}
                                    onClick={() => {
                                      this.onSlidebackCancel(4);
                                    }}>
                                    Cancel
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      minWidth: "80px",
                                      marginLeft: "auto",
                                    }}
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    Apply
                                  </Button>
                                </ButtonContainer>
                              </SectionFooter>
                            </SectionContent>
                          ),
                        }[this.state.paneltwo]
                      }
                    </PanelTwo>
                  </PanelContainer>
                </CropPanel>
              </ColumnSingle>
            </SettingsColumns>
          </Dialog>
        </TopContainer>
      </ElementContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,
  follow_group: state.auth.myprofile.followers_group,
  search_results: state.groups.search_results,
  groupid: state.groups.current_group.id,
  orgid: state.org.id,
  selectedelement: state.ui.selectedElement,
  next_try: state.auth.usage.ai_requests,
});
export default connect(mapStateToProps)(withRouter(FeedbackRequest));
