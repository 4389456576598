 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'ShowAnnotations',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const ShowAnnotations = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M15.7,5.6C12,5.6,8.9,8.4,8.5,12H6.8c-0.4-1.2-1.5-2.1-2.8-2.1c-1.7,0-3,1.3-3,3s1.3,3,3,3c1.3,0,2.5-0.9,2.8-2.1h1.7
	c0.4,3.6,3.5,6.4,7.2,6.4c4,0,7.2-3.2,7.2-7.2C22.9,8.9,19.7,5.6,15.7,5.6z"/>  </SvgIcon>
  );
};

export default ShowAnnotations;