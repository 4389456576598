import { CONSTANTS } from ".";
import analyticsInstance from "../utils/analytics";
import axiosInstance from "../utils/axiosApi";

export const setDimensions = (width, height, isMobile) => {
  return {
    type: CONSTANTS.SET_DIMENSIONS,
    payload: { width, height, isMobile },
  };
};
export const getFeed = () => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FEED_LOADING,
    payload: "loading",
  });
  axiosInstance.get("feed/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_FEED,
      payload: res.data,
    });
  }).catch(err => {
    dispatch({
      type: CONSTANTS.GET_FEED_ERROR,
      payload: err,
    });
  });
};
export const loadMoreFeedItems = (link) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FEED_LOADING,
    payload: "loading",
  });
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_FEED_ITEMS,
      payload: res.data,
    });
  });
};
export const getPublicProjects = (isAuth) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PUBLIC_PROJECTS_LOADING,
    payload: "loading",
  });
  if (!isAuth) {
    console.log("unauth")
    axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  }
  axiosInstance.get("projects/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_PUBLIC_PROJECTS,
      payload: res,
    });
  });
};

export const createProject = (name) => (dispatch) => {
  const data = {
    name: name,
    public: true,
  };
  axiosInstance.post("projects/", data).then((res) => {
    analyticsInstance.track("create_project", { id: res.data.id, title: data.title })
    dispatch({
      type: CONSTANTS.CREATE_NEW_PROJECT,
      payload: res.data,
    });
  });
};
