 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const DrawLine = (props) => {
  return (
      <SvgIcon {...props}>
    <rect x="0.5" y="11" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9812 11.9957)" width="22.9" height="2"/>
</SvgIcon>
  );
};

export default DrawLine;

