import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../actions/authActions";
import { uploadSaveProfile, saveProfile } from "../actions/profileActions";
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import marketing_urls from "../config/marketing_urls";
import Link from "@mui/icons-material/Link";
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
// //   width: 700px;
//   margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const ElementContainerNarrow = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
//   // margin-bottom: 10px;
//   background-color: #fff;
//   margin-left: -1px;
//   // min-width: 100%;
// `;
// const TopContainer = styled.div`
//     max-width: 900px;
//     width: ${(props) => (props.isMobile ? "100%" : "auto")};
//     height: 100%;
//     margin-left: auto;
//     margin-right: auto;
//     // margin-left: 0;
//     // margin-right: 0;
//     display: flex;
//     flex-direction: row;
// `;
// const ElementCard = styled.div`
//   width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
//   height: calc(100% - 30px);
//   background-color: #f2f2f2;
//   padding: 20px;
//   box-shadow: ${(props) => (props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)")};
//   border-radius: 5px;
//   margin-left: 5px;
//   margin-right: 5px;
//   margin-top: 4px;
//   display: flex;
//   flex-direction: column;
// `;
// const ColumnLeft = styled.div`
//   padding-left: ${(props) => (props.isMobile ? "0px" : "20px")};
//   padding-right: ${(props) => (props.isMobile ? "0px" : "20px")};
//   width: ${(props) => (props.isMobile ? "100%" : "450px")};

//   height: 100%;
//   //   background-color: #ccc;
// `;
// const ColumnRight = styled.div`
//   width: 350px;
//   //   height: 100%;

//   margin-left: 50px;
//   //   background-color: #dadada;
// `;
// const ControlLine = styled.div`
//   width: 100%;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
// `;
// const ElementCard = styled.div`
//   width: calc(100%-40px);
//   min-height: 100%;
//   background-color: #fff;
//   padding: 20px;

//   margin-left: 5px;
//   margin-right: 5px;
//   margin-top: 0px;
//   display: flex;
//   flex-direction: column;
// `;

// const ElementDescription = styled.div`
//   font-family: "Alegreya", serif;
//   // font-family: adobe-garamond-pro, serif;
//   font-size: 14px;
//   width: ${(props) => (props.textAreaWidth ? props.textAreaWidth : "200")}px;
//   // height: 100%;
//   padding: 20px;
//   // white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   text-decoration: none !important;
//   color: #111;
// `;

// const ElementCardLine = styled.div`
//   width: 50%;
//   height: 10px;

//   border-right: 2px dashed #e3e3e3;
// `;
// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

// const StyledInputTextArea = styled.input`
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   margin-top: 10px;
//   margin-bottom: 20px;
//   padding: 5px;
//   border: 1px solid #cccccc;
//   border-radius: 2px;
//   &:focus {
//     outline-color: #e5e5e5;
//   }
// `;

// const StyledButtonDelete = styled.button`
//     font-size: 16px;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     width: 150px;
//     background-color: #cd3131;
//     color: white;
//     height: 32px;
//     border-radius: 2px;

//     padding-right: 30px;
//     padding-left: 30px;
//     margin-top: 20px;

//     font-size: 14px;
//     text-align: center;
//     cursor: pointer;
//     border: none;

// }
// `;
// const StyledButton = styled.button`
//     font-size: 16px;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     width: 150px;
//     background-color: #2cc0f4;
//     color: white;
//     height: 32px;
//     border-radius: 2px;

//     padding-right: 30px;
//     padding-left: 30px;
//     margin-top: 20px;
//     margin-left: auto;
//     font-size: 14px;
//     text-align: center;
//     cursor: pointer;
//     border: none;

// }
// `;
// const StyledButtonContainer = styled.div`
//    display: flex;
//    flex-direction: row;
//    width: 100%;
//    padding-bottom: 60px;

// }
// `;
// const ImagePreviewContainer = styled.div`
//   height: 118px;
//   background-size: cover;
//   width: 206px;
//   background-position: 50%;
//   background-repeat: no-repeat;
//   background-size: cover;
// `;
// const ImageProgress = styled.div`
//   height: 118px;
//   background-color: rgba(255, 255, 255, 0.5);
//   width: 206px;
//   display: flex;
// `;
// const StyledDropTarget = styled.div`
//   border: 1px dashed #dddddd;
//   width: 100%;
//   margin-bottom: 10px;
//   text-align: left;

//   background-color: white;
//   vertical-align: middle;
//   display: flex;
//   justify-content: left;
//   align-items: center;
// `;
// const StyledDropTargetText = styled.div`
//   margin: 5px;
//   font-size: 12px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   letter-spacing: 0.00938em;
// `;
// const SettingsTitle = styled.div`
//   margin-top: ${(props) => (props.isMobile ? "10px" : "40px")};
//   font-size: 18px;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "40px")};
// `;
// const ControlLine = styled.div`
//   width: 100%;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
// `;
// const ControlLabel = styled.div`
//   width: 90px;
//   color: #787878;
//   font-size: 14px;
//   padding-top: 10px;
//   padding-bottom: 10px;
// `;
// const ControlContent = styled.div`
//   width: ${(props) => (props.isMobile ? "auto" : "calc(100% - 90px)")};
// `;
// const CoverImage = styled.div`
//   width: 100%;
//   height: 290px;
//   border: 1px solid #fff;
//   border-radius: 4px;
//   background: url("${(props) => props.src}");

//   background-position: center;
//   background-size: cover;
// `;
// const UploadButtons = styled.div`
//   margin-left: 100px;

//   margin-top: 78px;
//   width: 140px;
// `;
const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;
// const Ortext = styled.div`
//   text-align: center;
//   margin-top: 20px;
//   width: 100%;
// `;
// const UploadButton = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 140px;
//   background-color: rgba(255, 255, 255, 0.8);
//   color: #2196f3;
//   height: 28px;
//   border-radius: 2px;

//   padding-right: 10px;
//   padding-left: 10px;
//   padding-top: 10px;
//   margin-top: 20px;

//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
// const StyledElementPopup = styled.div`
//   width: 100%;
//   height: 100%;
//   background-color: rgba(1, 1, 1, 0.5);
//   position: absolute;
//   // pointer-events: none;
//   z-index: 2000;
//   overflow-y: auto;
//   overflow-x: hidden;
//   vertical-align: middle;

// `;
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
`;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: ${(props) => (props.isMobile ? "25px" : "5px")};
  margin-right: ${(props) => (props.isMobile ? "25px" : "5px")};
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

const StyledButtonDelete = styled.div`
   
    margin-top: 20px;
  
    
}
`;
const StyledButton = styled.div`
    
  
    
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
}
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "650px")};
  line-height: 150%;
  height: 100%;
  //   background-color: #ccc;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% - 90px)")};
  display: flex;
  flex-direction: column;
`;
const SettingsHeader = styled.div`
  display: flex;
`;
const SettingsColumns = styled.div`
  display: flex;
`;
const SettingsTitle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
`;
const CoverImage = styled.div`
  width: 300px;
  height: 300px;
  border: 1px solid #fff;
  border-radius: 150px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const DirectSpacer = styled.div`
  height: ${(props) => (props.isMobile ? "80px" : "150px")};
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const CloseButtonNarrow = styled.div`
  font-size: 20px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "0px" : "-15px")};
  color: #757575;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
class HelpPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    if (this.state.direct) {
      this.props.history.push("/");
    }
  }

  render() {
    const { dimensions, errors } = this.props;
    //console.log("render");
    // let imagesrc = this.state.image;
    // if (this.state.imagePreview) {
    //   imagesrc = this.state.imagePreview;
    // } else {
    //   if (this.state.selectedImage) {
    //     imagesrc = this.state.selectedImage;
    //   }
    // }
    let pageTitle = "Sequentially Explained";

    return (
      <div style={{ height: "100%" }}>
        {this.state.direct ? (
          <DirectSpacer isMobile={dimensions.window_dimensions.isMobile} />
        ) : (
          ""
        )}
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <ElementCard isMobile={dimensions.window_dimensions.isMobile}>
              <SettingsHeader>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  {pageTitle}
                </SettingsTitle>
                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialog();
                  }}>
                  {" "}
                  <IconButton fontSize="inherit" size="large">
                    <CloseRoundedIcon fontSize="inherit" />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns>
                <ColumnLeft isMobile={dimensions.window_dimensions.isMobile}>
                  <strong>Projects</strong>
                  <br />
                  In Sequentially you create projects in which you capture each
                  step of progress on a timeline. A progress element on the
                  timeline can have different types, for example: Text Update,
                  Image Update, To Do, Dimension, Material, Tool or Question.
               
                  <br />
                  <br />
                  Change the order of the elements by changing the date and
                  time. Or update multiple items in the re-order dialog.
                  <br />
                  <br />
                  A project can be in different states, such as To Do, In
                  Progress or Complete. When you complete a project, you set a
                  completion date and get the option to show a cover page above
                  the timeline.
                  <br />
                  <br />
                  A project is private (only you can see it) until you share it
                  with one or more groups.
                  <br />
                  <br />
                  <strong>Groups</strong>
                  <br />
                  A group is a small set of like-minded people around an
                  interest, for example oil painting, woodworking, or simply
                  friends and family.
                  <br />
                  <br />
                  As part of the Alpha test , we automatically added everyone to
                  the Sequentially Alpha group.
                  <br />
                  <br />
                  You can create new groups and invite people to join (during
                  the Alpha this is limited to people already in the Alpha).
                  Once you have joined a group, it will show up as a sharing
                  option in project settings.
                  <br />
                  <br />
                  As an Admin of a group, you can set different roles for
                  participants. A Viewer can just view the projects and
                  comments. A Commenter can also comment on elements, A
                  Contributor can also share projects with the group. An Admin
                  can invite and remove other members.
                  <br />
                  <br />
                  <strong>Comments</strong>
                  <br />
                  Comments on a project are specific to a group. When a project
                  has been shared in two groups, people from one group cannot
                  see comments in the other group. As the owner of a project you
                  can choose to see all comments across groups. You can comment
                  specifically to one group, comment to all groups (public) or
                  leave private notes.
                  <br />
                  <br />
                  <strong>Feed</strong>
                  <br />
                  The feed on the homepage shows recently updated projects from
                  groups that you are a member of and that you are following
                  (star). Initially this is just the Sequentially Alpha group.
                  You automatically follow groups that you join.
                  <br />
                  <br />
                  <strong>Following Projects and Notifications</strong>
                  <br />
                  If you follow a project, you get notified when a new element
                  gets added to that project, or when someone comments. You get
                  automatically notified when someone comments on your own
                  projects or when someone replies to one of your comments.
                  Notifications show up in with a red dot on the notifications
                  menu. Opening the menu marks the notification as read. If a
                  notification remains unread, we will include it in a update
                  email which gets sent out once a day.
                  <br />
                  <br />
                  <strong>Mobile Phone</strong>
                  <br />
                  You can use Sequentially on a mobile phone in the browser.
                  This is especially useful for quickly posting an update when you
                  complete a step in your project. You can either upload an
                  image or get direct access to the camera.
                  {/* <StyledButtonContainer>
                   
                    <StyledButton>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{ minWidth: "150px" }}
                        onClick={() => {
                          this.savePage();
                        }}
                      >
                        Don't Show At Launch
                      </Button>
                    </StyledButton>
                  </StyledButtonContainer> */}
                </ColumnLeft>
              </SettingsColumns>
            </ElementCard>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // userid: state.auth.user.user_id,

  dimensions: state.dimensions,
  errors: state.errors,
});
export default connect(mapStateToProps)(withRouter(HelpPage));
