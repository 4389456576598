import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { connect } from "react-redux";
import {Nav,NavItem} from "./styles"


class NavBar2 extends Component {
  constructor() {
    super();
    this.state = {};
  }
 

  render() {
    return (
     
        <Nav isMobile={this.props.isMobile}>
            <NavItem><Link style={{textDecoration:"none", color:"#111"}} to={"/terms/"}>Terms of Service</Link></NavItem>
            <NavItem><Link style={{textDecoration:"none", color:"#111"}} to={"/privacy/"}>Privacy Policy</Link></NavItem>
            <NavItem><Link style={{textDecoration:"none", color:"#111"}} to={"/processors/"}>Third Party Processors</Link></NavItem>
            <NavItem><Link style={{textDecoration:"none", color:"#111"}} to={"/cookies/"}>Cookie Notice</Link></NavItem>
            <NavItem><Link style={{textDecoration:"none", color:"#111"}} to={"/attributions/"}>Image Attributions</Link></NavItem>
        </Nav>
      
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
   
    isMobile: state.dimensions.window_dimensions.isMobile,
   
   
  });
export default connect(mapStateToProps)(NavBar2);

