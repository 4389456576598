const marketing_urls = {
    categories: [
        ["Oil Painting", "6cbd6b10dbfa43cfadcb9218e15069da"],
        ["Watercolor Painting", "db71e53021774e3ea333ff632c9d851c"],
        ["Acrylic Painting", "eUwC0HcRm9OKdghtPFnwkQ"],
        ["Digital Art", "uKFc61HUPyZSkana7_5RLQ"],
        ["Pencil Drawing", "VKmVciFEDJMMz7nwz8U8Zg"],
        ["Charcoal Drawing", "jRcAcFIRxF04HD_0zMNQpQ"],
        ["Pastels", "PukdqKoIKiXUs9qHKLx1vQ"],
    ],
    sample_projects:{
        allprojects: "made",
        martijn: "martijn",
        gavin: "gavin",
        aidan: "aidan",
        grids: "GTCB8vNxgzlZj3VZEbfncw",
        comments: "CLtG6q4VYVQomhhSJCgpsQ",
        thumbnail: "PHHJNOV3K_Modk_GmEaRLg",
        elements: "fLHSyXERAMo8DTBHf3PAwQ",
        drawing: "sFJcjT-qXywS3Qa9jrrk1w",
        compare: "H9ERiybS1COZcJyG05YZeg",
        drawgrid: "u0iXNHu37g26Lph1aM2vqA",
        sunflowers: "86E_isEi-qOeJ2ylzn2nEQ",
        portrait: "s3MEIKYJmJZYulqDuZKw8w",
        sidetable: "3NQADdpvB5vQabw3WteSnw",
        jkportrait: "BDlqfPrc-uJVRvjOU8fPBQ"
    }
}

export default marketing_urls