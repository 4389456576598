import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import marketing_urls from "../../config/marketing_urls";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";

import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  HeroImage,
  ThreeColumn,
  FeatureImage,
  FeatureImageLarge,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageLeftSectionLarge,
  ImageRightSection,
  ImageRightSectionLarge,
  LeftRightImage,
  LeftRightImageLarge,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
  PanContainer,
  PanImage,
  CenterColumnPan,
  PanLinkContainer,
  ContentSectionSample,
  SampleImageSection,
  SampleText,
  SampleImage,
  SampleSubTitle,
  SampleTextLeft
} from "./MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
class HomeMarketing extends Component {
  constructor() {
    super();
    this.state = {

    };

  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTitle(`${site_name} | About Projects`)
  }
  handleWaitMenu() {
    // console.log("wait");
    // this.props.dispatch(setMenu(true, 19));
    window.location.href = "/register/";
  }
  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection>
        <ContentSectionTop>
          <CenterColumnSingle isMobile={isMobile}>
            <CustomH1 style={{ color: "#16B973" }} isMobile={isMobile}>
              <ScrollBox isMobile={isMobile}>
                <ScrollList isMobile={isMobile}>
                  <ScrollItem>capture</ScrollItem>
                  <ScrollItem>create</ScrollItem>
                  <ScrollItem>track</ScrollItem>
                  <ScrollItem>capture</ScrollItem>
                </ScrollList>{" "}
              </ScrollBox>
              <H1Text style={{ paddingLeft: "0px", paddingRight: "0px" }}>&nbsp;as it's made</H1Text>
            </CustomH1>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
            <CustomP isMobile={isMobile}>
              {" "}
              Track your projects over time
            </CustomP>
          </CenterColumnSingle>
        </ContentSectionTop>
        <ContentSection color="transparent">
          <CenterColumnPan isMobile={isMobile}>
            <PanContainer isMobile={isMobile}>
              <PanImage>
                <img height="100%" src="/marketing_images/timeline.jpg" />
              </PanImage>
            </PanContainer>
            <PanLinkContainer>
              <Link target="_blank" to={"/project/" + marketing_urls.sample_projects.sunflowers + "/"} style={{ textDecoration: "none", color: "#008EFF" }}><OpenInNewIcon style={{ fontSize: "15px", marginBottom: "-2px" }} /> See this project in Made</Link>
            </PanLinkContainer>
          </CenterColumnPan>

        </ContentSection>

        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/project_feature_1.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#16B973" }}>Capture</FeatureTitle>
              <FeatureText>
                Create a private visual diary of your visual art project over time. Quickly upload images of
                your progress, visual art inspiration or reference materials. Create a log of all completed and ideas for future
                projects.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/projects_hero2.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#16B973" }}>Create </FeatureTitle>
              <FeatureText>
                Leverage creative tools such as image overlays and composition
                grids to improve your work. 
              </FeatureText>
            </ThreeColumn>

            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/project_feature_2.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#16B973" }}>Connect</FeatureTitle>
              <FeatureText>
                Create private communities to share the journey of your projects
                and get feedback from people you know and trust.
              </FeatureText>
            </ThreeColumn>
          </CenterColumn>
        </ContentSection>
        {/* <ContentSectionGradient>
          <CenterColumnSingle>
            <CenterHeader color="#fff">follow your passion</CenterHeader>
            <CenterList>
              <CategoryItem>Drawing</CategoryItem>
              <CategoryItem>Painting</CategoryItem>
              <CategoryItem>Woodworking</CategoryItem>
              <CategoryItem>Digital Art</CategoryItem>
              <CategoryItem>Ceramics</CategoryItem>
              <CategoryItem>Textiles</CategoryItem>
              <CategoryItem>Crafts</CategoryItem>
              <CategoryItem>Electronics</CategoryItem>
              <CategoryItem>DIY</CategoryItem>
            </CenterList>
          </CenterColumnSingle>
        </ContentSectionGradient> */}
        {/* <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#38DF88">Curious?</CenterHeader>
            <CenterText maxW="425">
               If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection> */}
        <ContentSectionImage src={'marketing_images/Rectangle 215.png'}>
          <ImageOverlay isMobile={isMobile}>One place for all <br />of your creative projects - <br />from start to finish</ImageOverlay>
        </ContentSectionImage>
        <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#16B973">Curious?</CenterHeader>
            <CenterText maxW="425">
              If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSection>
          <CenterColumnSingle>
            <ImageLeftSectionLarge isMobile={isMobile}>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/projects_6.png"
                />
              </LeftRightImageLarge>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#16B973" }}>Annotations</FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Commenting on an image is pretty straightforward. However, there are times when you want to reference a very specific part of an image and that can be tricky.
                  Made provides annotations so that you can quickly refer to an image or provide detailed feedback on someone else’s work.
                  <br />
                  <Link target="_blank" to={"/project/" + marketing_urls.sample_projects.comments} style={{ textDecoration: "none", color: "#008EFF" }}><OpenInNewIcon style={{ fontSize: "15px", marginBottom: "-2px" }} /> See an example</Link>

                </CenterText>
              </LeftRightText>
            </ImageLeftSectionLarge>
            <ImageRightSectionLarge isMobile={isMobile}>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#16B973" }}>Overlays</FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  It  is very common for artists to work from a reference image, but comparing your work with the reference image can be frustrating and time consuming.
                  Made allows you do do away with the manual work in tools such as photoshop and do direct comparisons using the built in overlay tool.
                  <br />
                  <Link target="_blank" to={"/project/" + marketing_urls.sample_projects.compare + "/"} style={{ textDecoration: "none", color: "#008EFF" }}><OpenInNewIcon style={{ fontSize: "15px", marginBottom: "-2px" }} /> See an example</Link>

                </CenterText>
              </LeftRightText>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/projects_7.png"
                />
              </LeftRightImageLarge>
            </ImageRightSectionLarge>
            <ImageLeftSectionLarge isMobile={isMobile}>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/projects_8.png"
                />
              </LeftRightImageLarge>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#16B973" }}>Composition Grids</FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Throughout the centuries artists have used grids to improve the quality of their final image.
                  Made provides a number of different grids to speed up the image transfer process, check accuracy, or improve overall composition.
                  <br />
                  <Link target="_blank" to={"/project/" + marketing_urls.sample_projects.grids + "/"} style={{ textDecoration: "none", color: "#008EFF" }}><OpenInNewIcon style={{ fontSize: "15px", marginBottom: "-2px" }} /> See an example</Link>

                </CenterText>
              </LeftRightText>
            </ImageLeftSectionLarge>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSectionSample>
          <CenterColumnSingle>
            <Link
              target="_blank"
              to={"/project/" + marketing_urls.sample_projects.portrait + "/"}
              style={{ textDecoration: "none" }}>
              <SampleImageSection isMobile={isMobile}>
                <SampleText isMobile={isMobile}>
                  <SampleSubTitle color="#383838" isMobile={isMobile}>
                    Sample Project
                  </SampleSubTitle>{" "}
                  <SampleTextLeft isMobile={isMobile}>
                    Check out this sample oil painting project in Made.
                  </SampleTextLeft>
                </SampleText>
                <SampleImage isMobile={isMobile}>

                  <img
                    style={{ width: "100%", marginBottom: "20px" }}
                    src="/marketing_images/sample2.png"
                  />
                </SampleImage>

              </SampleImageSection>
            </Link>
          </CenterColumnSingle>
        </ContentSectionSample>
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(HomeMarketing));
