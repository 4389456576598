import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../store";
import Register from "./auth/register";
import RegisterGroup from "./auth/registergroup";
import Login from "./auth/login";
import Passwordreset from "./auth/passwordreset";
import InitiatePasswordreset from "./auth/initiatepasswordreset";
import Terms from "./legal/terms";
import Privacy from "./legal/privacy";
import Processors from "./legal/processors";
import Cookies from "./legal/cookies";
import Verify from "./auth/verifyemail";
import Home from "./Home";
import MyProjects from "./MyProjects";
import ProjectPage from "./ProjectPage";
import ProjectVideoPage from "./ProjectVideoPage";
import ProjectSlideShow from "./ProjectSlideShow";
import ProjectPageEdit from "./ProjectPageEdit";
import ProfilePageEdit from "./ProfilePageEdit";
import ProfilePage from "./ProfilePage";
import GroupPage from "./GroupPage";
import OrganizationPage from "./OrganizationPage";
import Groups from "./Groups";
import TopNav from "./TopNav";
import TopNavMarketing from "./TopNavMarketing";
import Maintenance from "./auth/maintenance";
import GroupsMarketing from "./marketing_pages/GroupsMarketing";
import OrgsMarketing from "./marketing_pages/OrgsMarketing";
import HomeMarketing from "./marketing_pages/HomeMarketing";
import Support from "./marketing_pages/Support";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
// import { connect } from "react-redux";
// import styled from "styled-components";

// import axios from 'axios';
// import { apisuccess } from "../actions";

import jwt_decode from "jwt-decode";
import { logoutUser, setCurrentUser } from "../actions/authActions";
// import {  getProfileData } from "../actions/profileActions";
import PrivateRoute from "./private-routes/PrivateRoute";
import { setDimensions } from "../actions";
import Following from "./Following";
import DiscoverPage from "./discover/DiscoverPage";
import appTheme from "../config/theme";
import AlertBox from "./AlertBox";
import Debug from "./Debug";
import Marketing from "./Marketing";
import Subscriptions from "./Subscriptions";
import { Ribbons } from "./marketing_pages/Ribbon";
import { useAnalytics } from 'use-analytics'
import { captcha_site_key, debug } from "../config";
import ProjectsMarketing from "./marketing_pages/ProjectsMarketing";
import Attributions from "./legal/attributions";
import { tokenIsValid } from "../utils/waitForAuth";
import analyticsInstance from "../utils/analytics";
import HomeMarketingNew from "./marketing_pages/HomeMarketingNew";
import TopNavMarketing_black from "./TopNavMarketing_black";

// Check for token to keep user logged in
let isLoggedIn = false;
let rToken = localStorage.getItem("refresh_token")
try {
  isLoggedIn = tokenIsValid();
  if (isLoggedIn) {
    const refresh = jwt_decode(rToken);
    store.dispatch(setCurrentUser(refresh));
  } else {
    throw new Error("not logged in")
  }
} catch (err) {
  if (rToken) {
    store.dispatch(logoutUser())
  }
}
const theme = createTheme(appTheme);

const updateDimensions = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  //console.log(
  //   width,
  //   height,
  //   window.matchMedia("only screen and (max-width: 760px)").matches
  // );
  let isMobile = window.matchMedia(
    "only screen and (max-width: 720px)"
  ).matches;

  store.dispatch(setDimensions(width, height, isMobile));
};

window.addEventListener("resize", updateDimensions);
updateDimensions();

window.addEventListener("DOMContentLoaded", (event) => {
  document.querySelector("title").addEventListener("update", e => { analyticsInstance.page() })
})

const App = () => {
  const location = useLocation();
  const analytics = useAnalytics()

  // React.useEffect(() => {
  //   window.addEventListener("load", (event) => {
  //   }, { once: true });
  // }, [location, analytics])

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            {/* <TopNav /> */}
            <AlertBox />

            <Switch>
              {/* marketing pages routes */}
              {/* <Route exact path="/new_site" >
                <TopNavMarketing_black/>
                <HomeMarketingNew />
              </Route> */}
              {/* <Route exact path="/about" >
                <TopNavMarketing />
                <HomeMarketing />
              </Route>
              <Route exact path="/about_projects" >
                <TopNavMarketing />
                <ProjectsMarketing />
              </Route>
              <Route exact path="/about_groups" >
                <TopNavMarketing />
                <GroupsMarketing />
              </Route>
              <Route exact path="/about_organizations" >
                <TopNavMarketing />
                <OrgsMarketing />
              </Route> */}
              <Route exact path="/support" >
                <TopNavMarketing_black />
                <Support />
              </Route>
              <Route exact path="/discovery">
                <TopNavMarketing_black />
                {/* <Ribbons /> */}
                <DiscoverPage ribbons={false} />
              </Route>
              <Route exact path="/terms" >
                <TopNavMarketing_black />
                <Terms />
              </Route>
              <Route exact path="/attributions" >
                <TopNavMarketing_black />
                <Attributions />

              </Route>
              <Route exact path="/privacy" >
                <TopNavMarketing_black />
                <Privacy />
              </Route>
              <Route exact path="/cookies" >
                <TopNavMarketing_black />
                <Cookies />
              </Route>
              <Route exact path="/processors" >
                <TopNavMarketing_black />
                <Processors />
              </Route>
              <Route exact path="/maintenance" component={Maintenance} />




              {/* app routes */}
              <Route exact path="/verify" component={Verify} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/processors" >

              </Route>
              <Route exact path="/groupinvite/*"  >
                <TopNavMarketing_black minimal={true} />
                <RegisterGroup />
              </Route>
              <Route exact path="/login" component={Login} />
              <Route exact path="/passwordreset" component={Passwordreset} />
              <Route exact path="/resetpassword" component={InitiatePasswordreset} />
              <Route exact path="/project/*" render={(props) => (
                <>
                  <TopNav />
                  <ProjectPage {...props} />
                </>
              )} />
              <Route exact path="/projectvideo/*" render={(props) => (
                <>
                  {/* <TopNav /> */}
                  <ProjectVideoPage {...props} />
                </>
              )} />
               <Route exact path="/groupslideshow/*" render={(props) => (
                <>
                
                  <ProjectSlideShow {...props} />
                </>
              )} />
              <PrivateRoute exact path="/groups" >
                <TopNav />
                <Groups />
              </PrivateRoute>
              <PrivateRoute exact path="/home" component={(props) => (<><TopNav /><Home {...props} /></>)} />
              <Route exact path="/" ><><TopNavMarketing_black /><HomeMarketingNew /></></Route>
              <Route exact path="/group/*">
                <TopNav />
                <GroupPage />
              </Route>
              <PrivateRoute exact path="/organization/*" >
                <TopNav />
                <OrganizationPage />
              </PrivateRoute>
              <Route exact path="/discover" >
                <TopNav />
                <DiscoverPage />
              </Route>
              {/* <PrivateRoute
                exact
                path="/newproject/*"
                component={ProjectPageEdit}
              /> */}
              {/* <PrivateRoute exact path="/e/*" component={ProjectPage} /> */}
              {/* <PrivateRoute exact path="/edit/*" component={ProjectPage} /> */}

              {/* <PrivateRoute exact path="/order/*" component={ProjectPage} /> */}
              {/* <PrivateRoute exact path="/projects/" component={MyProjects} > */}
              <PrivateRoute exact path="/new/*">
                <TopNav />
                <ProjectPage />
              </PrivateRoute>
              <PrivateRoute exact path="/settings">
                <TopNav />
                <ProfilePageEdit />
              </PrivateRoute>
              {/* {debug ? <Route exact path="/debug" component={Debug} /> : null} */}
              <Route path="/:username"  >
                {/* this one should be last in the switch list */}
                <TopNav />
                <ProfilePage />
              </Route>

            </Switch>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}



// // function mapStateToProps(state) {
// //   return {
//   isMobile: state.dimensions.window_dimensions.isMobile//

// //   };
// // }

// export default connect(mapStateToProps)(App);
export default App;
