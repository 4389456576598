import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {removeMemberOrg, changeRoleOrg, getOrgParticipants} from "../actions/orgActions";
import {
  saveGroup,
  uploadSaveGroup,
  deleteGroup,
} from "../actions/groupActions";
import {
  addMember,
  
  
  sendInvite,
  getConnections,
  removeInvite,
  getPendingInvites,
} from "../actions/groupActions";
import CircularProgress from "@mui/material/CircularProgress";

import FormControl from "@mui/material/FormControl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setGroupSettings } from "../actions/uiActions";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import { api_endpoint } from "../config";
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: 10px;
  margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
`;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;
// const StyledSelect = styled.select`
//   width: 150px;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   margin-right: 20px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

const StyledButtonDelete = styled.div`
  
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButton = styled.div`
    
  
    
    padding-left: 30px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
}
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const InviteInstruction = styled.div`
  margin: 5px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  // letter-spacing: 0.00938em;
`;
const SearchResults = styled.div`
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
`;
const MemberList = styled.div`
  // height: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  overflow-y: auto;
 
`;
const LineItem = styled.div`
width: 100%;  
height: ${(props) => (props.isMobile ? "auto" : "70px")};
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  
  margin-top: 0px;
`;
const PendingItem = styled.div`
  height: 50px;

  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  border-bottom: 1px solid #ccc;
  display: flex;
`;
const PendingItemEmail = styled.div`
  margin-top: 15px;
`;
const UserNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: ##000000de;
`;
const OtherNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
`;
const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 15px;
`;
const LeftColumn = styled.div`
  // width: 10%;
  diplay: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.isMobile ? "auto" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
`;
const NameColumn = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
  margin-bottom: auto;
  margin-left: ${(props) => (props.isMobile ? "auto" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
`;
const MidColumn = styled.div`
  width:100%
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnNarrow = styled.div`
  width:100px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
  margin-bottom: auto;
  margin-left: ${(props) => (props.isMobile ? "auto" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
`;
const MidColumnWide = styled.div`
  min-width:150px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "20px")};
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
  margin-bottom: auto;
`;
const RightColumn = styled.div`
  // width: 100%;
  display: flex;
  margin-left: ${(props) => (props.isMobile ? "auto" : "10px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "10px")};
 
  margin-top: auto;
    margin-bottom: auto;
    text-align: ${(props) => (props.isMobile ? "center" : "left")};
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "00px")};
  width: ${(props) => (props.isMobile ? "100%" : "350px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnSingle = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "00px")};
  width: ${(props) => (props.isMobile ? "100%" : "600px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnRight = styled.div`
  width: 600px;
  //   height: 100%;

  margin-left: 50px;
  //   background-color: #dadada;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const ControlLineBanner = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  display: flex;
`;
const ControlContentMembers = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
`;
const SettingsHeader = styled.div`
  display: flex;
`;
const SettingsColumns = styled.div`
  display: flex;
`;

const SettingsTitle = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
`;
const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const CloseButtonNarrow = styled.div`
  font-size: 20px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "0px" : "-15px")};
  color: #757575;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
// const StyledButtonNew = styled.button`
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 150px;
//   background-color: #2cc0f4;
//   color: white;
//   height: 32px;
//   border-radius: 2px;

//   padding-right: 30px;
//   padding-left: 30px;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const AuthorImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
`;
const StyledElementPopup = styled.div`
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  height: calc(100% - 14px);
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const ColumnHeaders = styled.div`
    
//  background-color: #fff;
 min-height: 40px;
   border-bottom: 2px solid #ccc;
   font-weight: 500;
  
 font-family: "Roboto", "Helvetica", "Arial", sans-serif;
 font-size: 14px; 
 display: flex;
 flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
}
`;
const ColumnHeaderLeft = styled.div`
width: 100%;

margin-left: ${(props) => (props.isMobile ? "auto" : "12px")};
margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
padding-top: ${(props) => (props.isMobile ? "12px" : "12px")};
    text-align: ${(props) => (props.isMobile ? "center" : "left")};
}
`;
const ColumnHeaderRight4 = styled.div`
width: 150px;
text-align: ${(props) => (props.isMobile ? "center" : "left")};
margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
padding-top: ${(props) => (props.isMobile ? "auto" : "12px")};
}
`;
const ColumnHeaderRight3 = styled.div`
min-width: 150px;
text-align: ${(props) => (props.isMobile ? "center" : "left")};
margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
margin-right: ${(props) => (props.isMobile ? "auto" : "20px")};
padding-top: ${(props) => (props.isMobile ? "auto" : "12px")};
}
`;
const ColumnHeaderRight2 = styled.div`
width: 100px;
text-align: ${(props) => (props.isMobile ? "center" : "left")};
margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    
    margin-top: auto;
    margin-bottom: auto;
}
`;
const ColumnHeaderRight1 = styled.div`
    width: 100px;
    text-align: ${(props) => (props.isMobile ? "center" : "left")};
    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-top: ${(props) => (props.isMobile ? "auto" : "12px")};
    padding-bottom: ${(props) => (props.isMobile ? "12px" : "auto")};
}
`;
class OrgUserTable extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      id: "",
     
      members: [],
      admins: [],
      invited: [],
      checked: [],

      allmembers: [],

      showDeleteConfirmation: false,
     
    };
  }
  
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  
  handleChangeToggle = (value, part_id, type) => {
    //console.log(value);
    //console.log(part_id);

    this.props.dispatch(changeRoleOrg(value, part_id, type));
  };
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
  
  
  
  removeInvite = (id) => {
    this.props.dispatch(removeInvite(id));
  };
  handleShowDeleteConfirmation() {
    document.body.style.overflow = "hidden";
    this.setState({ showDeleteConfirmation: true });
  }
  
  
  
  removeUser(id, part_id) {
    this.props.dispatch(removeMemberOrg(id, part_id));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  componentDidMount() {
    const { dialogType, org, orgtable } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
   
    this.props.dispatch(getOrgParticipants(org.id));

    
      this.setState({ id: org.id });
   

    //   this.setState({ members: group.members });
    //   this.setState({ admins: group.admins });
    //   this.setState({ invited: group.invited });
    //   this.setState({ allmembers: group.allmembers });
 
  }
 
  
  handleToggle = (value) => () => {
    //console.log("toggle", value);
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };

  

  render() {
    const { dimensions, org, dialogType, isMobile } = this.props;

   return(
    <MemberList>
    <ColumnHeaders isMobile={isMobile}>
<ColumnHeaderLeft isMobile={isMobile}>Member</ColumnHeaderLeft>
<ColumnHeaderRight3 isMobile={isMobile}>Role</ColumnHeaderRight3>
<ColumnHeaderRight2 isMobile={isMobile}>Status</ColumnHeaderRight2>
<ColumnHeaderRight1 isMobile={isMobile}></ColumnHeaderRight1>
    </ColumnHeaders>    
   
   
     {org.participants.allIDS.map((id, index) => {
          const member = org.participants.byID[id];
          let verbose_status
          switch(member.status) {
             case "IN":
               verbose_status = "Invited"
               break;
             case "AC":
               verbose_status = "Active"
               break;
             case "RE":
               verbose_status = "Requested"
               break;  
             default:
               verbose_status = "undefined"
           }
          return(
            <LineItem isMobile={isMobile} key={index}>
              <LeftColumn isMobile={isMobile}>
                <AuthorImageSmall
                  src={member.avatar && api_endpoint+"/media/"+member.avatar.id+"/file?size=256" }
                ></AuthorImageSmall>
              </LeftColumn>
              <NameColumn isMobile={isMobile}>
                <NameContainer>
                  <UserNameSmall>
                    {member.username}{" "}
                  </UserNameSmall>
                  <OtherNameSmall>{member.email} </OtherNameSmall>
                  <OtherNameSmall>
                    {member.fullname}{" "}
                  </OtherNameSmall>
                </NameContainer>
              </NameColumn>
              <MidColumnWide isMobile={isMobile}>
              <FormControl
                id="hello"
                size="small"
                variant="outlined"
              >
                <Select
                
                  labelId="demo-simple-select-outlined-label"
                  id="mmmm"
                  value={member.role}
                  onChange={(e) => {
                    this.handleChangeToggle(
                      e.target.value,
                      member.part_id,
                      "participant"
                    );
                  }}
                >
                  <MenuItem value="AD">Admin</MenuItem>
                  <MenuItem value="CO">Contributor</MenuItem>
                 
                </Select>
              </FormControl>

              </MidColumnWide>
              <MidColumnNarrow isMobile={isMobile}>
                {verbose_status}
              </MidColumnNarrow>
              <RightColumn isMobile={isMobile}>
              <IconButton
                  onClick={() => {
                    this.removeUser(member.id, member.part_id);
                  }}
                  size="large">
                  <DeleteForeverIcon />
                </IconButton>
              </RightColumn>
            </LineItem>
          )
     })}
     </MemberList>
   )
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,
  org: state.org,
  allmembers: state.org.participants,
  dialogType: state.ui.groupDialogType,
  pending_invites: state.groups.pending_invites,
  search_results: state.groups.search_results,
  isMobile: state.dimensions.window_dimensions.isMobile,
});
export default connect(mapStateToProps)(withRouter(OrgUserTable));
