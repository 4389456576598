import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { setMenu } from "../../actions/uiActions";
import { captcha_site_key, site_name } from "../../config";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import setTitle from "../../utils/setTitle";

const StyledloginContainer = styled.div`
  display: block;
  margin: calc(100vh/6) auto 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const StyledloginDialog = styled.div`
  display: inline-block;
  margin: 0 auto 8.33333vh auto;
  // background-color: #F3F3F3;
  width: 300px;
  min-height: 120px;
  border-radius: 2px;
  // box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%), 0 0 2px 0 rgb(0 0 0 / 20%);
`;
const StyledHeaderText = styled.div`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #111;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;    
    width: 252px;
    background-color: #0073ec;
    color: white;
    height: 32px;
    border-radius: 2px;
  
    padding-right: 30px;
    padding-left: 30px;
    margin: 10px 24px 10px 24px;
  
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
    
}
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #f00073;
  margin-top: 5px;
`;
const TermsSection = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  max-width: 236px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
`;
const LoginImage = styled.div`
  display: inline-block;
  margin: 0 auto 40px auto;

  width: 300px;
  min-height: 120px;
`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
`;
class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      password2: "",
      captcha: "",
      errors: {},
    };
    this._captcha = ""
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    setTitle(`Register | ${site_name}`)
    this.props.setMenu(false)
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.errors) {
  //     this.setState({
  //       errors: nextProps.errors,
  //     });
  //   }
  // }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      username: this.state.username,
      email: this.state.email,
      password: this.state.password,
      captcha: this._captcha,
    };
    this.props.registerUser(newUser, this.props.history);
  };
  render() {
    const { errors } = this.state;
    return (
      <StyledloginContainer>
        <LoginImage>
          {" "}
          <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img>
        </LoginImage>
        <StyledloginDialog>
          <StyledHeaderText>Register for a new account</StyledHeaderText>
          <form noValidate onSubmit={this.onSubmit}>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: "3px" }}
                  size="small"
                  id="username"
                  label="Username"
                  variant="outlined"
                  value={this.state.username}
                  onChange={this.onChange}
                />
              </FormControl>

              <StyledError>{errors.username}</StyledError>
            </FormField>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: "3px" }}
                  size="small"
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </FormControl>

              <StyledError>{errors.email}</StyledError>
            </FormField>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: "3px" }}
                  size="small"
                  id="password"
                  label="Password"
                  variant="outlined"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </FormControl>

              <StyledError>{errors.password}</StyledError>
            </FormField>
            <StyledError>{errors.error}</StyledError>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              type="submit"
              style={{ minWidth: "252px", marginTop: "10px" }}>
              Sign up
            </Button>
            <GoogleReCaptcha onVerify={(token) => this._captcha = token} />
          </form>
          <TermsSection>
            By signing up, I agree to the Made{" "}
            <Link style={{ color: "#0073ec" }} to="/terms" target="_blank">
              Privacy Policy and Terms of Service.
            </Link>
          </TermsSection>
        </StyledloginDialog>
      </StyledloginContainer>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const Page = connect(mapStateToProps, { registerUser, setMenu })(withRouter(Register));

const render = () => (
  <GoogleReCaptchaProvider reCaptchaKey={captcha_site_key}>
    <Page />
  </GoogleReCaptchaProvider>
);

export default render;