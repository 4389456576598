 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const DrawCirc = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M12,4.8c4,0,7.2,3.2,7.2,7.2S16,19.2,12,19.2S4.8,16,4.8,12S8,4.8,12,4.8 M12,2.8c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2
	s9.2-4.1,9.2-9.2S17.1,2.8,12,2.8L12,2.8z"/>
</SvgIcon>
  );
};

export default DrawCirc;

