import { CONSTANTS } from "../actions";
import friendlyTimeCalc from "../utils/timeAgo";
import { api_endpoint, element_types_local } from "../config";

const initialState = {
  current_project: {
    id: "",
    name: "",
    description: "",
    project_state: "To Do",
    public: false,
    share_link: "",
    share_link_enabled: false,
    unshared_groups: null,
    creator: {},
    elements: {
      byID: {},
      allIDS: [],
    },
    viewers: [],
    groups: {
      byID: {},
      allIDS: [],
    },
    subscribers:{loading: true, results: [],next: null, previous: null, count: 0},
  },
  loading: {element: true, project: true, groups:  true},
  zoom: { level: 0, card_height: 300, comment_height: 300 },
  selected_element: "e1",
  dialog_type: "element",
  timeline: {
    selected_number: 1,
    update_widths: true,
    PositionArray: [],
    WidthArray: [],
    Elementwidths: {},
  },
  error: null,
  // popup: null
};

export default function projectReducer(state = initialState, action) {
  // TODO: Switch cases should be broken out into their own functions.
  switch (action.type) {

    case CONSTANTS.UPDATE_PROJECT_SETTINGS: {
      let tempimage;
      if (action.payload.image == null) {
        tempimage = "";
      } else {
        tempimage = api_endpoint + "/media/" + action.payload.image
      }
      let tempduration = null;
      if (action.payload.project_duration > 0) {
        tempduration = action.payload.project_duration;
      }
      console.log(action.payload)
      let new_current = {
        ...state.current_project,

        name: action.payload.name,
        description: action.payload.description,
        image: tempimage,
        custom_image: action.payload.custom_image,
        project_state: action.payload.project_state,
        tags: action.payload.tags,
        // category: action.payload.category,
        public: action.payload.public,
        allow_public_comments: action.payload.allow_public_comments,
        share_link: action.payload.share_link,
        share_link_enabled: action.payload.share_link_enabled,
        unshared_groups: action.payload.unshared_groups,

        cover: action.payload.cover,
        completed_at: action.payload.completed_at,
        project_duration: tempduration,
        total_elements: action.payload.total_elements,

      };
      return {
        ...state,
        current_project: new_current,
      };
    }
    case CONSTANTS.UPDATE_SHARING_LINK: {

      console.log(action.payload)
      let new_current = {
        ...state.current_project,
        share_link: action.payload.share_link,

      };
      return {
        ...state,
        current_project: new_current,
      };
    }
    case CONSTANTS.SET_PROJECT_PAGE_LOADING: {
      return {
        ...initialState,
        loading: {
          project: true,
          elements: true,
          groups: true
        }
      };
    }
    case CONSTANTS.CHANGE_FOLLOW_STATUS: {
      let new_current = { ...state.current_project };
      new_current.viewer_is_subscribed = action.payload;

      return {
        ...state,
        current_project: new_current,
      };
    }
    case CONSTANTS.ADD_COMMENT: {
      //console.log(action.payload);
      let new_current = { ...state.current_project };
      if (!new_current.elements.byID[action.payload.element].comments) {
        new_current.elements.byID[action.payload.element].comments = {
          byID: {},
          allIDS: [],
          roots: [],
          children: {}
        };
      } else {
        new_current.elements.byID[action.payload.element].comments = {
          ...new_current.elements.byID[action.payload.element].comments,
        };
      }
      new_current.elements.byID[action.payload.element].comments.byID[action.payload.id] = action.payload
      if (action.payload.parent === null) {
        new_current.elements.byID[action.payload.element].comments.roots.push(action.payload.id)
      } else {
        new_current.elements.byID[action.payload.element].comments.byID[action.payload.id].at_parent = new_current.elements.byID[action.payload.element].comments.byID[action.payload.parent].creator.username
        if (!new_current.elements.byID[action.payload.element].comments.children[action.payload.parent]) {
          new_current.elements.byID[action.payload.element].comments.children[action.payload.parent] = [action.payload.id];
        } else {
          new_current.elements.byID[action.payload.element].comments.children[action.payload.parent].push(action.payload.id);
        }
      }
      new_current.elements.byID[action.payload.element].comments.allIDS = []
      new_current.elements.byID[action.payload.element].comments.roots.forEach((commentid) => {

        var addFunc
        addFunc = function (id, indent) {
          const comment = new_current.elements.byID[action.payload.element].comments.byID[id]
          new_current.elements.byID[comment.element].comments.allIDS.push(comment.id);
          comment.show = true;
          comment.indent = indent;
          if (new_current.elements.byID[action.payload.element].comments.children[comment.id]) {
            new_current.elements.byID[action.payload.element].comments.children[comment.id].forEach((commentid2) => {
              addFunc(commentid2, 1)
            })
          }
        }
        addFunc(commentid, 0)
      })
      console.log(new_current.elements.byID[action.payload.element].comments)
      return {
        ...state,
        current_project: new_current,
      };
    }
    case CONSTANTS.SAVE_COMMENT: {
      //console.log(action.payload);
      let new_current = { ...state.current_project };
      new_current.elements.byID[action.payload.element].comments = {
        ...new_current.elements.byID[action.payload.element].comments,
      };
      const comment = action.payload;
      if (comment.parent) {
        comment.indent = 1;
      }
      comment.at_parent = new_current.elements.byID[action.payload.element].comments.byID[
        action.payload.id
      ].at_parent
      new_current.elements.byID[action.payload.element].comments.byID[
        action.payload.id
      ] = comment;
    
      return {
        ...state,
        current_project: new_current,
      };
    }
    case CONSTANTS.DELETE_COMMENT: {
      //console.log(action.payload);
      let new_current = { ...state.current_project };
      new_current.elements.byID[action.payload.element].comments = {
        ...new_current.elements.byID[action.payload.element].comments,
      };

      new_current.elements.byID[action.payload.element].comments.allIDS.forEach(
        (id, index) => {
          if (id === action.payload.commentid) {
            new_current.elements.byID[
              action.payload.element
            ].comments.allIDS.splice(index, 1);
            new_current.elements.byID[
              action.payload.element
            ].comments.roots.splice(index, 1);
          }
        }
      );
      delete new_current.elements.byID[action.payload.element].comments.byID[
        action.payload.commentid
      ];
      return {
        ...state,
        current_project: new_current,
      };
    }

    case CONSTANTS.SET_PROJECT_PAGE_DETAILS: {
      let tempimage;
      if (action.payload.error) {
        return {
          ...state,
          loading: {
            ...state.loading,
            project: false,
          },
          error: action.payload.error,
        };
      }
      if (action.payload.image == null) {
        tempimage = "";
      } else {
        tempimage = api_endpoint + "/media/" + action.payload.image.id
      }
      let tempduration = null;
      if (action.payload.project_duration > 0) {
        tempduration = action.payload.project_duration;
      }
      let newcurrent_project = {
        ...state.current_project,
        id: action.payload.id,
        name: action.payload.name,
        description: action.payload.description,
        image: tempimage,
        custom_image: action.payload.custom_image,
        project_state: action.payload.project_state,
        tags: action.payload.tags,
        category: action.payload.category,
        public: action.payload.public,
        allow_public_comments: action.payload.allow_public_comments,
        share_link: action.payload.share_link,
        share_link_enabled: action.payload.share_link_enabled,
        unshared_groups: action.payload.unshared_groups,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at,
        creator: action.payload.creator,
        cover: action.payload.cover,
        completed_at: action.payload.completed_at,
        project_duration: tempduration,
        total_elements: action.payload.total_elements,
        viewer_is_subscribed: action.payload.viewer_is_subscribed,
        views: action.payload.views,
        viewers: action.payload.viewers,
      };
      if (action.payload.updated_at > state.current_project.updated_at) {
        newcurrent_project = Object.assign(state.current_project, newcurrent_project);
        newcurrent_project.elements = state.current_project.elements;
        newcurrent_project.groups = state.current_project.groups;
      }
      let element_types = { byID: {}, allIDS: [] };
      const Types = element_types_local;
      Types.forEach((type) => {
        element_types.allIDS.push(type.id);
        element_types.byID[type.id] = {
          id: type.id,
          name: type.name,
          style: type.style,
        };
      });
      newcurrent_project.element_types = element_types
      let new_groups = { byID: {}, allIDS: [] };
      const Groups = action.payload.shared_groups;
      Groups.forEach((element) => {
        new_groups.allIDS.push(element.id);
        new_groups.byID[element.id] = {
          id: element.id,
          name: element.name,
          archived: element.archived,
          viewer_is_admin: element.viewer_is_admin,
          group_type: element.group_type,
          viewer_role: element.viewer_role,
        };
      });
      newcurrent_project.groups = new_groups
      return {
        ...state,
        loading: {
          ...state.loading,
          project: false,
        },
        current_project: newcurrent_project,
      };
    }
    case CONSTANTS.SET_ELEMENT_TYPES: {
      let element_types = { byID: {}, allIDS: [] };
      const Types = action.payload.results;
      Types.forEach((type) => {
        element_types.allIDS.push(type.id);
        element_types.byID[type.id] = {
          id: type.id,
          name: type.name,
          style: type.style,
        };
      });
      let newcurrent_project = state.current_project;
      newcurrent_project.element_types = element_types;
      return {
        ...state,
        current_project: newcurrent_project,
      };
    }
    case CONSTANTS.SET_PROJECT_PAGE_ELEMENTS: {
      let new_elements = { byID: {}, allIDS: [] };
      const Elements = action.payload.results;
      Elements.forEach((element) => {
        new_elements.allIDS.push(element.id);
        new_elements.byID[element.id] = {
          id: element.id,
          description: element.description,
          element_type: element.element_type,
          friendly_date: "",
          new_date: true,
          element_date: element.element_date,
          show_full_image: element.show_full_image,
          images: element.images,
          metadata: element.metadata,
          has_comments: element.has_comments,
          comments_per_group: element.comments_per_group,

        };
      });
      let new_current = { ...state.current_project };
      let lastdate_v;
      let newdate_v = false;
      new_elements.allIDS.forEach((elementid, index) => {

        const element_type_name =
          state.current_project.element_types.byID[
            new_elements.byID[elementid].element_type
          ].name;

        //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          new_elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );
          
        if (timeago === lastdate_v) {
          newdate_v = false;
          if (index === 1) {
            newdate_v = true;
          }
        } else {
          newdate_v = true;
        }
        lastdate_v = timeago;

        new_elements.byID[elementid].new_date_v = newdate_v;
        new_elements.byID[elementid].friendly_date = timeago;
      });
      new_elements.allIDS.reverse();
      let lastdate;
      let newdate = false;
      new_elements.allIDS.forEach((elementid, index) => {

        const element_type_name =
          state.current_project.element_types.byID[
            new_elements.byID[elementid].element_type
          ].name;

        //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          new_elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
          if (index === new_elements.allIDS.length - 1) {
            newdate = true;
          }
        } else {
          newdate = true;
        }
        lastdate = timeago;

        new_elements.byID[elementid].new_date = newdate;
        new_elements.byID[elementid].friendly_date = timeago;
      });
      new_elements.count = action.payload.count;
      new_elements.previous = action.payload.previous;
      new_elements.next = action.payload.next;
      new_current.elements = new_elements;
      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          elements: false,
        },
      };
    }
    case CONSTANTS.LOAD_MORE_ELEMENTS: {
      let new_elements = { ...state.current_project.elements };
      const Elements = action.payload.results;
      new_elements.allIDS.reverse();
      Elements.forEach((element) => {
        new_elements.allIDS.push(element.id);
        new_elements.byID[element.id] = {
          id: element.id,
          description: element.description,
          element_type: element.element_type,
          friendly_date: "",
          new_date: false,
          element_date: element.element_date,
          show_full_image: element.show_full_image,
          images: element.images,
          has_comments: element.has_comments,
          comments_per_group: element.comments_per_group,
          metadata: element.metadata,

        };
      });
      let new_current = { ...state.current_project };
      new_elements.allIDS.reverse();
      let lastdate;
      let newdate = false;
      new_elements.allIDS.forEach((elementid, index) => {
        const element_type_name =
          state.current_project.element_types.byID[
            new_elements.byID[elementid].element_type
          ].name;

        //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          new_elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
        } else {
          newdate = true;
        }
        lastdate = timeago;

        new_elements.byID[elementid].new_date = newdate;
        new_elements.byID[elementid].friendly_date = timeago;
      });
      new_elements.count = action.payload.count;
      new_elements.previous = action.payload.previous;
      new_elements.next = action.payload.next;
      new_current.elements = new_elements;
      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          elements: false,
        },
      };
    }
    case CONSTANTS.SET_PROJECT_PAGE_MYGROUPS: {
      let new_groups = { byID: {}, allIDS: [] };
      const Groups = action.payload.results;
      Groups.forEach((element) => {
        new_groups.allIDS.push(element.id);
        new_groups.byID[element.id] = {
          id: element.id,
          name: element.name,
          archived: element.archived,
          viewer_is_admin: element.viewer_is_admin,
          group_type: element.group_type,
          viewer_role: element.viewer_role,
        };
      });
      let new_current = { ...state.current_project };
      new_current.groups = new_groups;

      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          groups: false,
        },
      };
    }
    case CONSTANTS.SET_ELEMENT_COMMENTS: {
      let new_current = { ...state.current_project };
      const Comments = action.payload.results;
      Comments.reverse();
      let TempList = { byID: {}, roots: [], children: {} };
      Comments.forEach((comment) => {
        TempList.byID[comment.id] = comment;
        if (comment.parent === null) {
          // root comment
          TempList.roots.push(comment.id);
        } else {
          TempList.byID[comment.id].at_parent = TempList.byID[comment.parent].creator.username
          if (!TempList.children[comment.parent]) {
            TempList.children[comment.parent] = [comment.id];
          } else {
            TempList.children[comment.parent].push(comment.id);
          }
        }
      });

      TempList.roots.forEach((commentid) => {
        const comment = TempList.byID[commentid]
        if (!new_current.elements.byID[comment.element].comments) {
          new_current.elements.byID[comment.element].comments = {
            byID: TempList.byID,
            allIDS: [],
            roots: TempList.roots,
            children: TempList.children,
            count: action.payload.count,
            next: action.payload.next,
            previous: action.payload.previous,
          };
        }
        var addFunc
        addFunc = function (id, indent) {
          const comment = TempList.byID[id]
          new_current.elements.byID[comment.element].comments.allIDS.push(comment.id);
          comment.show = true;
          comment.indent = indent;
          // new_current.elements.byID[comment.element].comments.byID[comment.id] = comment;
          if (TempList.children[comment.id]) {
            TempList.children[comment.id].forEach((commentid2) => {
              addFunc(commentid2, 1)
            })
          }
        }
        addFunc(commentid, 0)
      });
      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          elements: false,
        },
      };
    }
    case CONSTANTS.LOAD_MORE_COMMENTS: {
      //console.log("dflindfinkdjfnlkdfnlgkdfn" + action.payload.results);
      let new_current = { ...state.current_project };

      const Comments = action.payload.results;
      let new_comments = {
        ...new_current.elements.byID[Comments[0].element].comments,
      };
      Comments.reverse();
      let TempList = { byID: {}, roots: [], children: {} };
      TempList.byID = { ...new_current.elements.byID[Comments[0].element].comments.byID }
      TempList.roots = [...new_current.elements.byID[Comments[0].element].comments.roots]
      TempList.children = { ...new_current.elements.byID[Comments[0].element].comments.children }
      console.log(TempList)
      Comments.forEach((comment) => {
        TempList.byID[comment.id] = comment;
        if (comment.parent === null) {
          // root comment
          TempList.roots.push(comment.id);
        } else {
          TempList.byID[comment.id].at_parent = TempList.byID[comment.parent].creator.username
          if (!TempList.children[comment.parent]) {
            TempList.children[comment.parent] = [comment.id];
          } else {
            TempList.children[comment.parent].push(comment.id);
          }
        }
      });
      console.log(TempList)
      console.log(Comments)
      new_current.elements.byID[Comments[0].element].comments = {
        byID: TempList.byID,
        allIDS: [],
        roots: TempList.roots,
        children: TempList.children,
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
      };
      TempList.roots.forEach((commentid) => {
        const comment = TempList.byID[commentid]

        var addFunc
        addFunc = function (id, indent) {
          const comment = TempList.byID[id]
          new_current.elements.byID[comment.element].comments.allIDS.push(comment.id);
          comment.show = true;
          comment.indent = indent;
          new_current.elements.byID[comment.element].comments.byID[comment.id] = comment;
          if (TempList.children[comment.id]) {
            TempList.children[comment.id].forEach((commentid2) => {
              addFunc(commentid2, 1)
            })
          }
        }
        addFunc(commentid, 0)
      });

      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          elements: false,
        },
      };
    }
    case CONSTANTS.SET_PROJECT_PAGE_COMMENTS: {
      let new_current = { ...state.current_project };
      const Comments = action.payload.results;
      Comments.forEach((comment) => {
        if (!new_current.elements.byID[comment.element].comments) {
          new_current.elements.byID[comment.element].comments = {
            byID: {},
            allIDS: [],
          };
        }
        new_current.elements.byID[comment.element].comments.allIDS.push(
          comment.id
        );
        new_current.elements.byID[comment.element].comments.byID[comment.id] =
          comment;
      });

      return {
        ...state,
        current_project: new_current,
        loading: {
          ...state.loading,
          elements: false,
        },
      };
    }
    case CONSTANTS.NEW_ELEMENT: {
      //console.log("new");
      //console.log(action.payload);
      let tempcurrentproject = { ...state.current_project };
      let new_images = [];
      let lastdate;
      let newdate = false;
      let tempelements = { ...tempcurrentproject.elements };
      if (action.payload.imageurl) {
        new_images = [
          {
            id: action.payload.imageid,
            upload: action.payload.imageurl,
            thumb: action.payload.thumb,
            thumbnails: { "largest": action.payload.imageurl },
            mime_type: action.payload.mime_type,
            alt_text: action.payload.alt_text,

          },
        ];
      }

      let tempelement = {
        id: action.payload.id,
        element_type: action.payload.element_type,
        friendly_date: "Now",
        new_date: true,
        element_date: action.payload.element_date,
        images: action.payload.images,
        description: action.payload.description,
        dimension: action.payload.dimension,
        has_comments: action.payload.hascomments,
        comments_per_group: action.payload.comments_per_group,
        metadata: action.payload.metadata,
        show_full_image: action.payload.show_full_image,
        width: 250,
      };
      if (!tempelements.byID[action.payload.id]) {
        tempelements.byID[action.payload.id] = tempelement;
        tempelements.allIDS.push(action.payload.id);
      }

      tempelements.allIDS.forEach((elementid, index) => {
        const element_type_name =
          state.current_project.element_types.byID[
            tempelements.byID[elementid].element_type
          ].name;

        //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          tempelements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
        } else {
          newdate = true;
        }
        lastdate = timeago;

        tempelements.byID[elementid].new_date = newdate;
        tempelements.byID[elementid].friendly_date = timeago;
      });

      let newALLIDS = []; // when the date changes, it should reorder the allIDS by date
      let sortlist = [];
      tempelements.allIDS.forEach((element, i) => {
        sortlist.push({
          id: element,
          val: new Date(tempelements.byID[element].element_date).getTime(),
        });
      });

      let newsortlist = sortlist.sort(function (a, b) {
        return a.val - b.val;
      });
      newsortlist.forEach((element, i) => {
        newALLIDS.push(element.id);
      });
      tempelements.allIDS = newALLIDS;

      tempcurrentproject.elements = tempelements;

      return {
        ...state,
        current_project: tempcurrentproject,
      };
    }
    case CONSTANTS.SAVE_ELEMENT: {
   
      let tempcurrentproject = { ...state.current_project };
      let tempelementID = {
        ...tempcurrentproject.elements.byID[action.payload.id],
      };
      tempelementID.description = action.payload.description;
   
      tempelementID.element_date = action.payload.element_date;
      tempelementID.metadata = action.payload.metadata;
      tempelementID.element_type = action.payload.element_type;
      tempelementID.images = action.payload.images;
      tempelementID.show_full_image = action.payload.show_full_image;
      tempcurrentproject.elements.byID[action.payload.id] = tempelementID;
      let newALLIDS = []; // when the date changes, it should reorder the allIDS by date
      let sortlist = [];
     
      tempcurrentproject.elements.allIDS.forEach((element, i) => {
       
        sortlist.push({
          id: element,
          val: new Date(tempcurrentproject.elements.byID[element].element_date).getTime(),
        });
      });
     
      let newsort = []
      newsort = sortlist.sort(function (a, b) {
      
        return a.val - b.val;
      });
     
      newsort.forEach((element, i) => {
        newALLIDS.push(element.id);
      });
      tempcurrentproject.elements.allIDS = newALLIDS;

      let lastdate;
      let newdate = false;
      tempcurrentproject.elements.allIDS.forEach((elementid, index) => {
        const element_type_name =
          state.current_project.element_types.byID[
            tempcurrentproject.elements.byID[elementid].element_type
          ].name;

        // //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          tempcurrentproject.elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
        } else {
          newdate = true;
        }
        lastdate = timeago;

        tempcurrentproject.elements.byID[elementid].new_date = newdate;
        tempcurrentproject.elements.byID[elementid].friendly_date = timeago;
      });
      return {
        ...state,
        current_project: tempcurrentproject,
      };
    }
    case CONSTANTS.SET_ELEMENT_ORDER: {
      // //console.log(action.payload);
      let tempcurrentproject = { ...state.current_project };
      let tempelementID = {
        ...tempcurrentproject.elements.byID[action.payload.id],
      };

      tempelementID.element_date = action.payload.element_date;

      tempcurrentproject.elements.byID[action.payload.id] = tempelementID;
      let newALLIDS = []; // when the date changes, it should reorder the allIDS by date
      let sortlist = [];
      tempcurrentproject.elements.allIDS.forEach((element, i) => {
     
        sortlist.push({
          id: element,
          val: new Date(tempcurrentproject.elements.byID[element].element_date).getTime(),
        });
      });
     
      let newsort = []
      newsort = sortlist.sort(function (a, b) {
      
        return a.val - b.val;
      });
     
      newsort.forEach((element, i) => {
        newALLIDS.push(element.id);
      });
      tempcurrentproject.elements.allIDS = newALLIDS;

      let lastdate;
      let newdate = false;
      tempcurrentproject.elements.allIDS.forEach((elementid, index) => {
        const element_type_name =
          state.current_project.element_types.byID[
            tempcurrentproject.elements.byID[elementid].element_type
          ].name;

        // //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          tempcurrentproject.elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
        } else {
          newdate = true;
        }
        lastdate = timeago;

        tempcurrentproject.elements.byID[elementid].new_date = newdate;
        tempcurrentproject.elements.byID[elementid].friendly_date = timeago;
      });
      return {
        ...state,
        current_project: tempcurrentproject,
      };
    }
    case CONSTANTS.DELETE_ELEMENT: {
      //console.log("delete");
      //console.log(action.payload);
      let tempcurrentproject = { ...state.current_project };
      delete tempcurrentproject.elements.byID[action.payload.id];
      tempcurrentproject.elements.allIDS.splice(
        tempcurrentproject.elements.allIDS.indexOf(action.payload.id),
        1
      );
      let lastdate;
      let newdate = false;
      tempcurrentproject.elements.allIDS.forEach((elementid, index) => {
        const element_type_name =
          state.current_project.element_types.byID[
            tempcurrentproject.elements.byID[elementid].element_type
          ].name;

        //console.log("friendlyTimeCalc");
        const timeago = friendlyTimeCalc(
          tempcurrentproject.elements.byID[elementid].element_date,
          element_type_name,
          state.current_project.project_state,
          state.current_project.completed_at
        );

        if (timeago === lastdate) {
          newdate = false;
        } else {
          newdate = true;
        }
        lastdate = timeago;

        tempcurrentproject.elements.byID[elementid].new_date = newdate;
        tempcurrentproject.elements.byID[elementid].friendly_date = timeago;
      });
      return {
        ...state,
        current_project: tempcurrentproject,
      };
    }
    case CONSTANTS.SET_FRIENDLY_DATE: {
      let tempcurrentproject = { ...state.current_project };
      tempcurrentproject.elements.byID[action.payload.id].friendly_date =
        action.payload.date;
      tempcurrentproject.elements.byID[action.payload.id].new_date =
        action.payload.newdate;
      return {
        ...state,
        current_project: tempcurrentproject,
      };
    }
    case CONSTANTS.UPDATE_POSITION:
      // eslint-disable-next-line no-case-declarations
      let tempcurrentproject = { ...state.current_project };
      tempcurrentproject.elements.byID[action.payload.id].width =
        action.payload.width;
      return {
        ...state,
        current_project: tempcurrentproject,
      };

    case CONSTANTS.SET_POSITION:
      //console.log(action.payload);
      return {
        ...state,

        timeline: {
          ...state.timeline,
          PositionArray: action.payload.position_array,
          WidthArray: action.payload.width_array,
        },
      };
    case CONSTANTS.SET_ZOOM:
      return {
        ...state,

        zoom: {
          level: action.payload.level,
          card_height: action.payload.card_height,
          comment_height: action.payload.comment_height,
        },
      };
    case CONSTANTS.SELECT_ELEMENT:
      return {
        ...state,

        selected_element: action.payload.element,
      };
    case CONSTANTS.SET_PROJECT_DIALOG_TYPE:
      return {
        ...state,

        dialog_type: action.payload.type,
      };
    case CONSTANTS.SET_PROJECT_DIALOG:
      return {
        ...state,
        popup: action.payload,
      }
      case CONSTANTS.SET_PROJECT_SUBSCRIBERS_LOADING:
        return {
          ...state,
          current_project: {...state.current_project,
            subscribers: {
              ...state.current_project.subscribers,
                loading: true,
            }
          }
         
        };
        case CONSTANTS.GET_PROJECT_SUBSCRIBERS:
       
        return {
          ...state,
          current_project: {...state.current_project,
            subscribers: {
              ...action.payload,
                loading: false,
            }
          }
         
        };
        case CONSTANTS.LOAD_MORE_PROJECT_SUBSCRIBERS:
       {
          let new_subscribers = {...state.current_project.subscribers}
          let prev_subs = new_subscribers.results;
          let new_subs = action.payload
          new_subs.results = prev_subs.concat(action.payload.results)
          new_subscribers = {...new_subs, loading: false,}
        return {
          ...state,
          current_project: {...state.current_project,
            subscribers: new_subscribers
          }
        
        };
      }  
    // case CONSTANTS.SET_MENU:
    //   return {
    //     ...state,
    //     popup: action.payload.state,
    //   };

    default:
      return state;
  }
}
