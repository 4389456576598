 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const Grid = (props) => {
  return (
      <SvgIcon {...props}>
 <g>
	<g>
		<line x1="8.7" y1="3" x2="8.7" y2="21"/>
		<rect x="7.7" y="3" width="2" height="18"/>
	</g>
	<g>
		<line x1="15.3" y1="3" x2="15.3" y2="21"/>
		<rect x="14.3" y="3" width="2" height="18"/>
	</g>
</g>
<g>
	<g>
		<line x1="21" y1="8.7" x2="3" y2="8.7"/>
		<rect x="3" y="7.7" width="18" height="2"/>
	</g>
	<g>
		<line x1="21" y1="15.3" x2="3" y2="15.3"/>
		<rect x="3" y="14.3" width="18" height="2"/>
	</g>
</g>
</SvgIcon>
  );
};

export default Grid;

