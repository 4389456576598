import Box from '@mui/material/Box';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';


const ProfileDetails = () => (
    <Box component="form" sx={{ display: "flex", flexDirection: "column", width: "100%", '& .MuiTextField-root': { marginBottom: 1, width: "100%" } }}>
        <TextField label="Full Name" fullWidth variant="outlined" />
        <TextField label="Username" fullWidth variant="outlined" />
        <TextField
            label="Tagline"
            multiline
            fullWidth
            rows={2}
            variant="outlined"
        />
    </Box >
)

export default ProfileDetails;