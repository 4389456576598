 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const Phi = (props) => {
  return (
      <SvgIcon {...props}>
<g>
	<g>
		<line x1="9.6" y1="3" x2="9.6" y2="21"/>
		<rect x="8.6" y="3" width="2" height="18"/>
	</g>
	<g>
		<line x1="14.4" y1="3" x2="14.4" y2="21"/>
		<rect x="13.4" y="3" width="2" height="18"/>
	</g>
</g>
<g>
	<g>
		<line x1="21" y1="9.6" x2="3" y2="9.6"/>
		<rect x="3" y="8.6" width="18" height="2"/>
	</g>
	<g>
		<line x1="21" y1="14.4" x2="3" y2="14.4"/>
		<rect x="3" y="13.4" width="18" height="2"/>
	</g>
</g>
</SvgIcon>
  );
};

export default Phi;

