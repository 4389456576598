import React, { Component } from "react";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import { api_endpoint } from "../config";
import { withRouter } from "react-router-dom";
import Compare from "./elements/Compare";
import Text from "./elements/Text";
import {
  newProject,
  uploadNewProject,
  saveProject,
  uploadSaveProject,
  deleteProject,
} from "../actions/projectActions";
import { getProjectCategories } from "../actions/profileActions";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { getProjectContent } from "../actions/projectActions";
import { getMemberships } from "../actions/profileActions";
import ProjectThumb from "./thumbs/ProjectThumb";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Autocomplete } from '@mui/material';
import Update from "./elements/Update";
import Next from "./elements/Next";
import CloseIcon from "../custom-icons/CloseIcon";
import Question from "./elements/Question";
import Dimension from "./elements/Dimension";
import { DragDropContext } from "react-beautiful-dnd";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { saveOrder } from "../actions/projectActions";
import { element } from "prop-types";
import {
 
  SettingsHeader,
  
  SettingsTitle,
  CloseButtonNarrow,

  
} from "./styles/MenuComponents.js";
const ImageSet = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;
const SingleImage = styled.div`
  padding: 3px;
  border: 1px solid #ccc;
  margin: 2px;
  height: 75px;
  cursor: pointer;
`;

const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;

const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-top: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "100%" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
  height: 100%;
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
 height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
  // padding: 50px;
`;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
  max-width: 660px;
  height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 100px)")}; 
  background-color: #fff;
  padding: 0px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border-radius: ${(props) => (props.isMobile ? "0px" : "5px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-top: ${(props) => (props.isMobile ? "0px" : "50px")};
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "50px")};
  display: flex;
  flex-direction: column;
  
`;

const StyledButtonDelete = styled.div`
   
    margin-top: 20px;
  
    
}
`;
const FormLabelCustom = styled.div`
   
   font-size: 14px;
   color: rgba(0, 0, 0, 0.54);
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  
    
}
`;
const StyledButton = styled.div`
    
  
    
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
}
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "15px")};
  width: ${(props) => (props.isMobile ? "100%" : "360px")};
  padding-top: 24px;
  height: 100%;
`;
const ColumnRight = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "270px")};
  //   height: 100%;

  padding-left: ${(props) => (props.isMobile ? "0px" : "15px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  //   background-color: #dadada;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% )")};
  display: flex;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 7px;
  margin-left: 20px;
  margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: -17px;
`;

const SettingsColumns = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-top: 1px solid #ccc;
   border-bottom: 1px solid #ccc;
  min-width: 303px;
  padding-bottom: 30px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // padding-left: 32px;
  // padding-right: 32px;

`;

const CoverImage = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  margin-top: 10px;
`;
const CoverImageSmall = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 8px;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButtonsCondensed = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-top: 3px;
  height: 90px;
}
`;
const Ortext = styled.div`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;
const OrtextC = styled.div`
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 0px;
  width: 100%;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;

const PopupDialog = styled.div`
  max-width: 300px;
  height: calc(100% - 30px);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const DialogInstruction = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  line-height: 20px;
  margin-bottom: 20px;
  margin-left: 32px;
  margin-right: 32px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
  padding-right: 20px;
  margin-top: 20px;
  margin-left: auto;
`;
const StyledDate = styled.div`
  width: calc(100% - 40px);

  margin-left: 10px;
`;
const SmallElement = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "100")}px;
  height: ${(props) => (props.isMobile ? "40" : "100")}px;
  background-color: #fff;
  border: 1px solid #ccc;

  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  //   display: flex;
  //   flex-direction: column;
  position: static;
`;
const ElementLine = styled.div`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  //   border-top: 1px solid #ccc;
  //   border-bottom: 1px solid #ccc;
  padding-top: 1px;
  padding-bottom: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  border: 1px solid rgba(255,255,255,0);;
  display: flex;
  &:hover {
    background-color: #fff;
    border: 1px solid #2196f3;
  }
`;
const ElementNumber = styled.div`
font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 36px;
line-height: 130%;

// width: 80px;
display: flex;
align-items: center;
text-align: center;
margin-left: 30px;; 
color: #C4C4C4;
`;
const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
padding-top: 40px;
padding-bottom: 20px;
`;
const GroupLine = styled.div`
  border-bottom: 1px solid #2196F3;
  width: 100%;

`;
const GroupText = styled.div`
  background-color: #2196F3;
  margin-left: auto;
  margin-right: auto;
  margin-top: -16px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  border-radius: 7px;
`;
const StyledDraghandle = styled.div`
  width: 95px;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-left: 0px;
  padding-top: 30px;
  
`;
const DragDot = styled.div`

width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 1px;
  margin-left: auto; 
  margin-right: 30px; 
`;
class ProjectChangeOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resetEdit: true,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeRadio = (e) => {
    this.setState({ project_state: e.target.value });
  };
  onChangeGroups = (e) => {
    //console.log(e.target.id);
    let tempmemberships = this.state.memberships;
    //console.log(tempmemberships);
    tempmemberships.forEach((membership) => {
      if (membership.id === e.target.id) {
        membership.checked = e.target.checked;
      }
    });
    this.setState({ memberships: tempmemberships });
  };
  onChangeRadioPublic = (e) => {
    this.setState({ public: e.target.value });
  };
  onChangeCover = (e) => {
    this.setState({ cover: e.target.checked });
  };

  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
    if (this.state.showDeleteConfirmation === true) {
      this.setState({ showDeleteConfirmation: false });
    }
  }
  handleCloseDialog(target) {
    console.log("clese")
    this.props.dispatch(setMenu(false));
    this.props.handleCloseRootPopup();
    // this.props.history.replace(target);
  }

  doNothing(e) {
    e.stopPropagation();
  }
  componentDidMount() {
    const { elements } = this.props;
   
  }
  componentDidUpdate() {}
  onDragStart = (result) => {
    const { type, draggableId, source } = result;
    //console.log(source.droppableId);
    this.setState({ resetEdit: false });
    //  this.props.dispatch(updatelines())
  };

  onDragEnd = (result, props) => {
    const { destination, source, draggableId, type } = result;
    const { elements, vertical } = this.props;
    //console.log(source);
    //console.log(destination);
    let groupedelements = {};
    let groups = [];
    let prevfriendly = "";
    if (vertical){
      elements.allIDS.reverse()
    }
    console.log(elements.allIDS[0]);
    elements.allIDS.map((elementid, index) => {
      const element = elements.byID[elementid];
      if (element.friendly_date !== prevfriendly) {
        prevfriendly = element.friendly_date;
        groupedelements[element.friendly_date] = [elementid];
        groups.push(element.friendly_date);
      } else {
        groupedelements[element.friendly_date].push(elementid);
      }
    });
    // console.log(groups);
    // console.log(groupedelements);
    const element = elements.byID[draggableId];
    this.setState({ resetEdit: true });
    let abovedroppedid = "h";
    
    let indexcorrection = 0;
    let directioncorrection = 0;
    let newtime;
    if (!result.destination) {
      return;
    }
    if (destination.droppableId === source.droppableId) {
      indexcorrection = 1;
      if (source.index > destination.index){
        directioncorrection = 1
      }
    }
    if (
      destination.index ===
      groupedelements[destination.droppableId].length - indexcorrection
    ) {
      //dropped in last position of sublist
    
      const abovedroppedid =
        groupedelements[destination.droppableId][
          destination.index + indexcorrection - 1
        ];
      const second_element_date = new Date(
        elements.byID[abovedroppedid].element_date
      );
      console.log(abovedroppedid);
      if (vertical){
        newtime = second_element_date.setMilliseconds(
          second_element_date.getMilliseconds() - 10
        );
      }else{
        newtime = second_element_date.setMilliseconds(
          second_element_date.getMilliseconds() + 10
        );
      }
     
      console.log(newtime);
    } else {
      if (destination.index === 0) {
        console.log("first");
        const abovedroppedid = groupedelements[destination.droppableId][0];
        const second_element_date = new Date(
          elements.byID[abovedroppedid].element_date
        );
        //console.log(abovedroppedid);
        if (vertical){
          console.log("vert")
          newtime = second_element_date.setMilliseconds(
            second_element_date.getMilliseconds() + 10
          );
        }else{
          newtime = second_element_date.setMilliseconds(
            second_element_date.getMilliseconds() - 10
          );
        }
       
        //console.log(newtime);
      } else {
        console.log("middle");

        const abovedroppedid =
          groupedelements[destination.droppableId][
            destination.index + indexcorrection - directioncorrection
          ];
        const belowdroppedid =
          groupedelements[destination.droppableId][
            destination.index + indexcorrection - directioncorrection - 1 
          ];
        const second_element_date = new Date(
          elements.byID[abovedroppedid].element_date
        );
        const first_element_date = new Date(
          elements.byID[belowdroppedid].element_date
        );
        //console.log(first_element_date.getTime(),second_element_date.getTime())
        //console.log(abovedroppedid);
       let diff = (first_element_date.getTime() + second_element_date.getTime()) / 2
     
       if (diff === second_element_date.getTime()){
       
         // the dropped item is dropped between two items with exactly the same date
         // increment diff with 2 milliseconds increment second on with 4 milliseconds
         diff = diff + 10
         let nextupdiff = diff + 10
         let updatearray= [[new Date(nextupdiff).toISOString(),elements.byID[abovedroppedid].element_type,abovedroppedid]] 
     
        // we should find next one inline, see if second is same or larger than that one, then increment that one by 2 milliseconds
         // and continue until done. 
        let currentindex = elements.allIDS.indexOf(abovedroppedid)
      
        let checknext = true
        while (checknext === true){
          const nextelementid = elements.allIDS[currentindex + 1]
       
          if (currentindex + 1 < elements.allIDS.length){
            if (new Date(elements.byID[nextelementid].element_date).getTime() <= nextupdiff ){
              nextupdiff = nextupdiff + 10
              if (element.id !== nextelementid){
                // don't update the one just being dragged
                updatearray.push([new Date(nextupdiff).toISOString(),elements.byID[nextelementid].element_type,nextelementid])
              }
             
              currentindex = currentindex + 1
            }else{
              checknext = false;
            }
          }else{
            checknext = false
          }
         
        }

      

         updatearray.forEach((el) => {
          this.props.dispatch(
            saveOrder(
              el[0],
             el[1],
             el[2],
            )
          );
        });
        

       

       }
        newtime = new Date(
         diff
        ).toISOString();
     
       
      }
     
    }
   

    const midpoint = new Date(newtime).toISOString();
    //console.log(midpoint);

    this.props.dispatch(
      saveOrder(
     
        midpoint,
        element.element_type,
        element.id,
       
      )
    );
  };

  render() {
    const {
      project,
      elements,
      dimensions,
      project_url,
      new_dialog,
      categories,
      element_types,
      vertical
    } = this.props;
    let groupedelements = {};
    let groups = [];
    let prevfriendly = "";
    if (vertical){
      elements.allIDS.reverse()
    }
    console.log(elements.allIDS[0]);
    elements.allIDS.map((elementid, index) => {
    
      const element = elements.byID[elementid];
      if (element.friendly_date !== prevfriendly) {
        prevfriendly = element.friendly_date;
        groupedelements[element.friendly_date] = [elementid];
        groups.push(element.friendly_date);
      } else {
        groupedelements[element.friendly_date].push(elementid);
      }
    });

    return (
      <div style={{ height: "100%" }}>
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <ElementCard isMobile={dimensions.window_dimensions.isMobile}>
            <SettingsHeader style={{marginTop: "32px"}} isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle  isMobile={dimensions.window_dimensions.isMobile}>
                  Re-Order elements
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialog(project_url);
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <DialogInstruction>
                Drag the elements below to reorder the timeline.
              </DialogInstruction>

              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  {groups.map((group, index) => {
                    return (
                      <div key={"group" + index}>
                        <GroupContainer>
                          <GroupLine></GroupLine>
                          <GroupText> {group}</GroupText>
                         </GroupContainer>
                        <Droppable droppableId={group}>
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                width: "100%",
                                backgroundColor: "#f2f2f2",
                              }}>
                              {groupedelements[group].map(
                                (elementid, index) => {
                                  const element = elements.byID[elementid];
                                  let image_url,image_urlB;
                                    let isVideo = false
                                    
                                    if (element.images.length === 0) {
                                      image_url = false;
                                    } else {
                                      if (element.images[0].mime_type.split("/")[0] === "video"){
                                        isVideo = true;
                                        image_url = api_endpoint+"/media/"+element.images[0].id+"/file";
                                      }else{
                                      image_url = api_endpoint+"/media/"+element.images[0].id+"/file?size=256"
                                        
                                      }
                                      
                                    }
                                    if (element.images.length === 2) {
                                      image_urlB = api_endpoint+"/media/"+element.images[1].id+"/file?size=256"
                                    } 
                                  return (
                                    <Draggable
                                      key={elementid}
                                      draggableId={elementid}
                                      index={index}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <ElementLine>
                                            <StyledDraghandle>
                                              <DragDot></DragDot>
                                              <DragDot></DragDot>
                                              <DragDot></DragDot>
                                            </StyledDraghandle>
                                            <SmallElement cardwidth={100}>
                                              {
                                                {
                                                  "UP": (
                                                    <Update
                                                      image_url={image_url}
                                                      isVideo={isVideo}
                                                      VideoControls={false}
                                                      tag={element.metadata ? (element.metadata.label ? element.metadata.label: null) : null}
                                                      description={element.description}
                                                      drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing: []) : []}
                                                      element = {element}
                                                    />
                                                  ),
                                                  "CO": (
                                                    <Compare
                                                      selected={this.props.selected}
                                                      image_url={image_url}
                                                      image_urlB={image_urlB}
                                                     
                                                      tag={element.metadata ? (element.metadata.label ? element.metadata.label: null) : null}
                                                      description={element.description}
                                                      link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url: "") : ""}
                                                      compare_type={element.metadata ? (element.metadata.compare_type ? element.metadata.compare_type: "SS") : "SS"}
                                                      link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name: "") : ""}
                                                      drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing: []) : []}
                                                      image_order={element.metadata ? (element.metadata.image_order ? element.metadata.image_order: []) : []}
                                                      element = {element}
                                                    />
                                                  ),
                                                  "TE": (
                                                    <Text
                                                      selected={this.props.selected}
                                                      tag={element.metadata ? (element.metadata.label ? element.metadata.label: null) : null}
                                                      description={element.description}
                                                      link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url: "") : ""}
                                                      link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name: "") : ""}
                                                      textcolor={element.metadata ? (element.metadata.text_color ? element.metadata.text_color: "") : ""}
                                                    />
                                                  ),
                                                  "DM": (
                                                    <Dimension
                                                      selected={this.props.selected}
                                                      description={element.description}
                                                      unit={
                                                        element.metadata  ? (element.metadata.dimension_unit ? element.metadata.dimension_unit : "") : ""
                                                      }
                                                      x={element.metadata ? (element.metadata.dimension_x ? element.metadata.dimension_x: 60) : 60}
                                                      y={element.metadata ? (element.metadata.dimension_y ? element.metadata.dimension_y: 60) : 60}
                                                      z={element.metadata ? (element.metadata.dimension_z ? element.metadata.dimension_z: 60) : 60}
                                                    />
                                                  )
                                                }[element.element_type]
                                              }
                                            </SmallElement>
                                            <ElementNumber>{elements.allIDS.indexOf(element.id) + 1}</ElementNumber>
                                          
                                          </ElementLine>
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    );
                  })}
                
                </DragDropContext>
              </SettingsColumns>

              <StyledButtonContainer >
                <StyledButton style={{marginBottom: "32px", marginRight: "32px", marginTop: "32px"}}>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "150px" }}
                    onClick={() => {
                      this.handleCloseDialog(project_url);
                    }}>
                    Done
                  </Button>
                </StyledButton>
              </StyledButtonContainer>
            </ElementCard>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  project_loading: state.project.loading,
  elements: state.project.current_project.elements,
  element_types: state.project.current_project.element_types,
  userid: state.auth.user.user_id,
  categories: state.auth.project_categories,
  groups: state.profile.groups,
  groups_loading: state.profile.groups_loading,
  dimensions: state.dimensions,
});
export default connect(mapStateToProps)(withRouter(ProjectChangeOrder));
