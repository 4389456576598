import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { api_endpoint } from "../config";
import { withRouter } from "react-router-dom";
import { keyframes, css } from "@emotion/react";
import {
  newProject,
  uploadNewProject,
  saveProject,
  toggleSharingLink,
  uploadSaveProject,
  deleteProject,
} from "../actions/projectActions";
import mimetype from "../config/mimetype";
import { getProjectCategories } from "../actions/uiActions";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { getProjectContent } from "../actions/projectActions";
import { getMemberships } from "../actions/profileActions";
import ProjectThumb from "./thumbs/ProjectThumb";


import { setMenu } from "../actions/uiActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete } from "@mui/material";
import { fetchCategories } from "../actions";
import CloseIcon from "../custom-icons/CloseIcon";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import TitleIcon from "@mui/icons-material/Title";
import InventoryIcon from '@mui/icons-material/Inventory';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import HdrWeakOutlinedIcon from "@mui/icons-material/HdrWeakOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { ThemeConsumer } from "styled-components";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton
} from "./styles/MenuComponents.js";


const SmallElement = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "100")}px;
  height: ${(props) => (props.isMobile ? "40" : "100")}px;
  background-color: #111;
  border: 1px solid #fff;

  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  //   display: flex;
  //   flex-direction: column;
  position: static;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${(props) => "url(" + props.src + ")"};
`;
const ImageSet = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;
const RadioHelp = styled.div`
  font-size: 12px;
  margin-left: 30px;
  margin-top: -10px;
  color: #757575;
`;
const GroupHelp = styled.div`
  font-size: 12px;

  color: #757575;
`;
const SingleImage = styled.div`
  padding: 3px;
  border: 1px solid #ccc;
  margin: 2px;
  height: 75px;
  cursor: pointer;
`;

const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;

const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;

 
  // max-height: 100%;
  // width: 100%;
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   //   width: 700px;
//   // margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "100%" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const TopContainer = styled.div`
//   // max-width: 900px;
//   width: ${(props) => (props.isMobile ? "100%" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "100vh" : "auto")};
//   height: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   // margin-left: 0;
//   // margin-right: 0;
//   display: flex;
//   flex-direction: row;
// `;


const ConfirmationCard = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  min-height: ${(props) => (props.isMobile ? "100%" : "auto")};

  background-color: #fff;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;


const FormLabelCustom = styled.div`
   
   font-size: 14px;
   color: rgba(0, 0, 0, 0.54);
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  
    
}
`;

const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
// const ColumnLeft = styled.div`
//   padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
//   padding-right: ${(props) => (props.isMobile ? "0px" : "15px")};
//   width: ${(props) => (props.isMobile ? "100%" : "360px")};
//   padding-top: 24px;
//   height: 100%;
// `;
// const ColumnRight = styled.div`
//   width: ${(props) => (props.isMobile ? "100%" : "270px")};
//   //   height: 100%;

//   padding-left: ${(props) => (props.isMobile ? "0px" : "15px")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
//   //   background-color: #dadada;
// `;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ShareLinkBox = styled.div`
 border: 1px solid #ccc;
 font-size: 0.75rem;
 margin-left: 30px;
 padding: 5px;
 word-break: break-all;
`;
const ShareLinkButtons = styled.div`

 margin-left: 30px;
 
`;
const ChipGroup = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% )")};
  display: flex;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
  // margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: -17px;
`;
// const SettingsHeader = styled.div`
//   display: flex;
// `;
// const SettingsColumns = styled.div`
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
//   min-height: ${(props) => (props.isMobile ? "calc(100vh - 133px)" : "auto")};
// `;


const CoverImage = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const CoverImageSmall = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 8px;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButtonsCondensed = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-top: 3px;
  height: 90px;
}
`;
const Ortext = styled.div`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;
const OrtextC = styled.div`
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 0px;
  width: 100%;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const ConfirmationWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: absolute;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;

const PopupDialog = styled.div`
   margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
 
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight2 = styled.div`

    margin-top: 20px;

    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    
}
`;
const StyledButtonLeft = styled.div`
  // margin-left: 20px;

  padding-right: ${(props) => (props.isMobile ? "none" : "30px")};
  margin-left: ${(props) => (props.isMobile ? "auto" : "none")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-top: 20px;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-top: 5px;
  margin-left: 15px;
`;

const StyledButtonRight = styled.div`
  // padding-right: 20px;
  margin-top: 20px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
`;

const OptionTitle = styled.div`
  // font-weight: bold;
  line-height: 1.5;
`;

const OptionSubtitle = styled.div`
  font-size: 0.75rem;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.54);
`;

const GroupSelect = styled.div`
  // margin-left: 30px;
  max-height: 33vh;
  overflow-y: auto;
`;

const GroupLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
class ProjectPageEdit extends Component {
  constructor(props) {
    super(props);
    this.savePage = this.savePage.bind(this);
    this.newPage = this.newPage.bind(this);
    this.state = {
      id: "",
      description: "",
      name: "",
      image: "",
      project_state: "TO",
      tags: [],
      newtag: "",
      groups: [],
      memberships: [],
      category: [],
      public: false,
      created_at: "",
      creator: {},
      updated_at: "",
      imageRaw: "",
      imagePreview: false,
      showImageProgress: false,
      initial: true,
      initialgroups: true,
      showpopup: false,
      selectedImage: "",
      selectedImageID: "",
      cover: false,
      completed_at: "",
      showDeleteConfirmation: false,
      nameerror: false,
      collapse: false,
      share_link: "",
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      panelW: 288,
      slide: false,
      x1: 0,
      x2: 0,
      pastState: "",
      custom_image_setting: "false",
      directUI: false,
      directShare: false,
      allow_public_comments: true,
      showSaveConfirmation: false,
      hasChanges: false
    };
  }
  onSlide = (state, bool) => {
    this.setState({ slide: true });
  };
  onChangeSectionNumber = (num) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) });
    } else {
      this.setState({ x2: -this.state.panelW });
    }

    switch (num) {
      case 1:
        this.setState({ pastState: this.state.name });
        break;
      case 2:
        this.setState({ pastState: this.state.description });
        break;
      case 3:
        this.setState({ pastState: this.state.category });
        break;
      case 4:
        this.setState({ pastState: this.state.tags });
        break;
      case 5:
        this.setState({ pastState: this.state.project_state });
        break;
      case 6:console.log(this.state.memberships)
        this.setState({ pastState:   [this.state.share_link_enabled,this.state.share_link,this.state.public,this.state.allow_public_comments,JSON.parse(JSON.stringify(this.state.memberships))] });
        break;
        case 7:
          this.setState({ pastState:[ this.state.custom_image_setting,this.state.image,this.state.imageRaw,this.state.selectedImageID,this.state.imagePreview] });
          break;  
      default:
      // code block
    }
  };
  onSlideback = (state, bool) => {
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    if (this.props.new_dialog) {
      this.setState({ directUI: false });
    }
    if (this.props.sharing_only) {
      this.setState({ directShare: false });
    }
    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  handleCloseSaveConfirmation(target) {
    this.props.history.push(target);
  }
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 1:
        this.setState({ name: this.state.pastState });
        break;
        case 2:
          this.setState({ description: this.state.pastState });
          break;
      case 7:
        this.setState({ custom_image_setting: this.state.pastState[0] });
        this.setState({ image: this.state.pastState[1] });
        this.setState({ imageRaw: this.state.pastState[2] });
        this.setState({ selectedImageID: this.state.pastState[3] });
        this.setState({ imagePreview: this.state.pastState[4] });
        break;
      case 3:
        this.setState({ category: this.state.pastState });
        break;
        case 4:
          this.setState({ tags: this.state.pastState });
          break;
          case 5:
            this.setState({ project_state: this.state.pastState });
            break;     
            case 6:console.log(this.state.pastState)
              this.setState({ share_link_enabled:this.state.pastState[0] });
              this.setState({ share_link: this.state.pastState[1] });
              this.setState({ public: this.state.pastState[2] });
              this.setState({ allow_public_comments: this.state.pastState[3] });
              this.setState({ memberships: this.state.pastState[4] });
            break;     
      default:
      // code block
    }
  };
  onChange = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: e.target.value });
  };
  handleDelete = (index) => {
    let newtags = [...this.state.tags]
    newtags.splice(index, 1)
    this.setState({ tags: newtags });
  }
  onChangeRadio = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ project_state: e.target.value });
  };
  onChangeRadioImage = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ custom_image_setting: e.target.value });
    if (e.target.value === "false") {
      // if switching ot dynamic, show most recent image
      const images_array = this.props.elements.allIDS.filter((elementid) => { return this.props.elements.byID[elementid].images.length > 0; })
      const last_id = images_array[images_array.length - 1]
      const image = this.props.elements.byID[last_id].images[0]
      const image_url = api_endpoint + "/media/" + image.id + "/file?size=512"
      console.log(image_url)
      this.setState({ image: image_url });
      this.setState({ imageRaw: "" });
      this.setState({ selectedImageID: "" });
      this.setState({ imagePreview: false });
    } else {
      //nn
    }
  };
  handleAddTag = (event, values) => {
    this.setState({ hasChanges: true });
    // parse if brackets
    switch (event.keyCode) {
      case 9: //tab
      case 13: //enter
      case 32: {
        //space

        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          console.log("keydown");

          let taglist = [...this.state.tags]
          taglist.push(event.target.value)
          this.setState({ newtag: "" });
          this.setState({ tags: taglist });
        }

        break;
      }
      default:
    }
  };
  onChangeGroups = (e) => {
    if (e.target.checked){
      if (this.props.auth.ticket){
        this.setState({ hasChanges: true });
        let tempmemberships = this.state.memberships;
        //console.log(tempmemberships);
        tempmemberships.forEach((membership) => {
          membership.editing = true;
          if (membership.id === e.target.id) {
            membership.checked = e.target.checked;
          }
        });
        this.setState({ memberships: tempmemberships });
      }else{
        this.props.dispatch(setMenu(true, 22));
      }
    }else{
      this.setState({ hasChanges: true });
      let tempmemberships = this.state.memberships;
      //console.log(tempmemberships);
      tempmemberships.forEach((membership) => {
        membership.editing = true;
        if (membership.id === e.target.id) {
          membership.checked = e.target.checked;
        }
      });
      this.setState({ memberships: tempmemberships });
    }



  
    
  };
  onChangeRadioPublic = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ visibility: e.target.value });

  };
  onChangeCover = (e) => {
    this.setState({ cover: e.target.checked });
  };
  onCatsChange = (event, values) => {
    this.setState({ hasChanges: true });
    this.setState(
      {
        category: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        //console.log(this.state.category);
      }
    );
    //console.log(this.state.category);
  };
  onChangePublic = (e) => {
   
    if (e.target.checked){
      if (this.props.auth.ticket){
        this.setState({ hasChanges: true });
        this.setState({ public: e.target.checked });
      }else{
        this.props.dispatch(setMenu(true, 22));
      }
    }else{
      this.setState({ hasChanges: true });
      this.setState({ public: e.target.checked });
    }
   
   
   
  };
  onChangePublicComments = (e) => {
    this.setState({ hasChanges: true });
    console.log("allo")
    this.setState({ allow_public_comments: e.target.checked });
  };
  onChangeSharing = (e) => {
    if (e.target.checked){
      if (this.props.auth.ticket){
        this.setState({ hasChanges: true });
    this.setState({ share_link_enabled: e.target.checked });
    if (e.target.checked && this.props.project.current_project.share_link === null) {
      this.props.dispatch(
        toggleSharingLink(
          this.state.id,

        )
      );
    }
      }else{
        this.props.dispatch(setMenu(true, 22));
      }
    }else{
      this.setState({ hasChanges: true });
      this.setState({ share_link_enabled: e.target.checked });
    }


   

  };
  changeSharingLink = (id) => {


    this.props.dispatch(
      toggleSharingLink(
        id,

      )
    );


  };
  copySharingLink = (e) => {
    var input = document.createElement("input");
    input.hidden = true;
    input.type = "text";
    input.value = `${window.location.origin}/project/${this.props.project.current_project.share_link}`;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
  };
  deletePage = () => {
    this.props.dispatch(deleteProject(this.state.id));
  };
  savePage = (close) => {
    //console.log("save project");
    const { project, project_url } = this.props;
    let selectedgroups = [];
    this.state.memberships.forEach((membership) => {
      if (membership.checked) {
        selectedgroups.push(membership.id);
      }
    });

    // eg: ["project", "{p_id}", "{p_name}"]
    // or: ["project", "{p_id}", "{p_name}", "group", "{g_id}"]
    let currentPath = window.location.pathname.substring(1).split("/")
    if (currentPath[3] === "group") {
      if (!project.current_project.groups.allIDS.includes(currentPath[4])) {
        let target = currentPath.slice(0, 3).join("/")
        window.history.push(target)
      }
    }

    let selected_categories = [];
    console.log(this.state.category)
    this.state.category.forEach((cat) => {
      selected_categories.push(cat.id);
    });
    let data;
    let completion_date;
    if (this.state.completed_at) {
      completion_date = new Date(
        this.state.completed_at + "T23:59:59.99"
      ).toISOString();
    } else {
      completion_date = "";
    }

    if (this.state.selectedImageID) {
      data = {
        description: this.state.description,
        custom_image: this.state.selectedImageID,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: completion_date,
        project_state: this.state.project_state,
        public: this.state.public,
        allow_public_comments: this.state.allow_public_comments,
        share_link_enabled: this.state.share_link_enabled,
        groups: selectedgroups,
        category: selected_categories,
        tags: this.state.tags
      };
    } else {
      data = {
        description: this.state.description,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: completion_date,
        project_state: this.state.project_state,
        public: this.state.public,
        allow_public_comments: this.state.allow_public_comments,
        share_link_enabled: this.state.share_link_enabled,
        groups: selectedgroups,
        category: selected_categories,
        tags: this.state.tags
      };
    }
    if (this.state.custom_image_setting === "false") {
      data.custom_image = null
    }
    if (this.state.completed_at === null || this.state.completed_at === "") {
      delete data.completed_at;
    }
    if (this.state.imagePreview) {
      // first upload and then save
      this.props.dispatch(
        uploadSaveProject(this.state.id, data, this.state.imageRaw, project_url)
      );
    } else {
      this.props.dispatch(saveProject(this.state.id, data, close));
    }
    this.handleCloseDialog()
  };
  newPage = () => {
    //console.log("new project");
    // const { project } = this.props;
    let selectedgroups = [];
    this.state.memberships.forEach((membership) => {
      if (membership.checked) {
        selectedgroups.push(membership.id);
      }
    });

    let selected_categories = [];
    if (this.state.category) {
      this.state.category.forEach((cat) => {
        selected_categories.push(cat.id);
      });
    }

    let data;
    if (this.state.selectedImageID) {
      data = {
        description: this.state.description,
        custom_image: this.state.selectedImageID,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: this.state.completed_at,
        project_state: this.state.project_state,
        public: this.state.public,
        share_link_enabled: this.state.share_link_enabled,
        groups: selectedgroups,
        category: selected_categories,
      };
    } else {
      data = {
        description: this.state.description,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: this.state.completed_at,
        project_state: this.state.project_state,
        public: this.state.public,
        share_link_enabled: this.state.share_link_enabled,
        groups: selectedgroups,
        category: selected_categories,
      };
    }
    if (this.state.custom_image_setting === "false") {
      data.custom_image = null
    }
    if (this.state.completed_at === null || this.state.completed_at === "") {
      delete data.completed_at;
    }
    if (data.name !== "") {
      if (this.state.imagePreview) {
        // first upload and then save
        this.props.dispatch(uploadNewProject(data, this.state.imageRaw));
      } else {
        //console.log(data);
        this.props.dispatch(newProject(data));
      }
    } else {
      this.setState({ nameerror: true });
    }

    // const target =
    //   "/project/" +
    //   project.current_project.id +
    //   "/" +
    //   project.current_project.name;

    // this.props.history.goBack() // should find a way to go to the project after creation
  };
  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
    if (this.state.showDeleteConfirmation === true) {
      this.setState({ showDeleteConfirmation: false });
    }
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    if (!this.props.new_dialog){
      this.props.handleCloseRootPopup();
    }
   
    // this.props.history.replace(target);
  }
  handleCloseDialogCheck(){
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      this.handleCloseDialog()
    }

  }
  handleSelectImage(url, id) {
    // there is a bug here when you upload image, then select from element and the upload the same image again.
    this.setState({ selectedImage: url });
    this.setState({ selectedImageID: id });
    this.setState({ imagePreview: false });
    this.setState({ imageRaw: "" });
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
  }
  setInitialGroupSelection(memberships, groups) {
    const { new_dialog } = this.props;
    console.log("memberships");
    //console.log(memberships, groups);
    let memberships_results = memberships.map((membership, index, object) => {
      //console.log(membership);
      membership.checked = false;
      if (!new_dialog) {
        if (groups) {
          groups.allIDS.forEach((group) => {
            if (membership.id === groups.byID[group].id) {
              membership.checked = true;
            }
          });
        }
      }else{
        if (this.props.shared_with_group){
          console.log("default check")
          if (membership.id === this.props.shared_with_group) {
            membership.checked = true;
          }
        }
      }
      // let isAdmin = false;
      // membership.admins.forEach((admin) => {
      //   if (userid === admin){
      //     isAdmin = true;
      //   }
      // })

      return membership;
    }).filter(g => !g.archived || g.checked).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    //console.log(memberships_results);
    this.setState({ memberships: memberships_results });
  }
  handleOpenPopup() {
    if (this.state.showpopup === false) {
      this.setState({ showpopup: true });
    }
  }
  handleShowConfirmation() {
    if (this.state.showDeleteConfirmation === false) {
      this.setState({ showDeleteConfirmation: true });
    }
  }
  handleExpand() {
    this.setState({ collapse: false });
  }
  handleCollapse() {
    this.setState({ collapse: true });
  }
  doNothing(e) {
    e.stopPropagation();
  }
  componentDidMount() {
    const { project, userid, new_dialog, dimensions } = this.props;
    console.log("grpup",this.props.shared_with_group)
    if (dimensions.window_dimensions.isMobile) {
      this.setState({ panelW: dimensions.window_dimensions.w - 64 });

    }
    const el = window.location.pathname.split("/")[2];
    this.props.dispatch(getMemberships(userid));
    this.props.dispatch(fetchCategories({}));
    if (this.props.sharing_only) {
      this.onChangeSectionNumber(6);
      this.setState({ directShare: true });


    }
    //console.log("new_dialog", new_dialog);
    let uniqueImageIDS = [];
    project.current_project.elements.allIDS.forEach((elementid) => {
      const element = project.current_project.elements.byID[elementid];
      if (element.images) {
        element.images.forEach((img) => {
          if (img.mime_type.split("/")[0] !== "video") {
            if (uniqueImageIDS.indexOf(img.id) === -1) {
              uniqueImageIDS.push(img.id);
            }
          }
        });
      }
    });
    this.setState({ uniqueImageIDS: uniqueImageIDS });
    if (new_dialog) {

      this.setState({ directUI: true });
      this.setState({ collapse: true });
      this.setState({ id: "" });
      this.setState({ form_new: true });
      this.setState({ description: "" });
      this.setState({ name: "" });
      this.setState({ image: "" });
      this.setState({ project_state: "TO" });
      this.setState({ tags: [] });
      this.setState({ category: [] });
     
      this.setState({ groups: {} });
      this.setState({ public: false });
      this.setState({ allow_public_comments: true });
      this.setState({ cover: false });
      this.setState({ completed_at: "" });
      this.setState({ visibility: "PR" });
      this.setState({ creator: this.props.creator });
      console.log(this.props.creator)
      this.onChangeSectionNumber(1);
    } else {
      if (project.current_project.custom_image) {
        this.setState({ custom_image_setting: "true" });
      } else {
        this.setState({ custom_image_setting: "false" });
      }
      if (!project.current_project.id) {
        this.props.dispatch(getProjectContent(el));
      }
      this.setState({ id: project.current_project.id });
      this.setState({ form_new: false });
      this.setState({ description: project.current_project.description });
      this.setState({ name: project.current_project.name });
      this.setState({ image: project.current_project.image + "/file?size=512" });
      this.setState({ project_state: project.current_project.project_state });
      this.setState({ tags: project.current_project.tags });
      this.setState({ category: project.current_project.category });
      this.setState({ groups: project.current_project.groups });
      this.setState({ public: project.current_project.public });
      this.setState({ allow_public_comments: project.current_project.allow_public_comments });
      this.setState({ share_link: project.current_project.share_link });
      this.setState({
        share_link_enabled: project.current_project.share_link_enabled,
      });
      this.setState({ created_at: project.current_project.created_at });
      this.setState({ updated_at: project.current_project.updated_at });
      this.setState({ creator: project.current_project.creator });
      this.setState({ cover: project.current_project.cover });
      this.setState({ visibility: project.current_project.visibility });
      if (project.current_project.completed_at) {
        const local_date = new Date(project.current_project.completed_at);
        const day = ("0" + local_date.getDate()).slice(-2);
        const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
        // let hour = local_date.getHours();
        // let min  = local_date.getMinutes();
        // hour = (hour < 10 ? "0" : "") + hour;
        // min = (min < 10 ? "0" : "") + min;
        const local_date_state =
          local_date.getFullYear() + "-" + month + "-" + day;
        //console.log("local_date_state" + local_date_state);
        this.setState({ completed_at: local_date_state });
      }
    }
  }
  componentDidUpdate() {
    const {
      project,
      project_loading,
      groups,
      groups_loading,
      new_dialog,
      dimensions,
    } = this.props;
    //console.log("update");
    //console.log(groups);

    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
    if (this.state.share_link !== project.current_project.share_link) {

      this.setState({ share_link: project.current_project.share_link });

    }
    if (this.state.initial && !project_loading) {
      //console.log("now");
      if (new_dialog) {
        this.setState({ directUI: true });
        this.setState({ id: "" });
        this.setState({ form_new: true });
        this.setState({ description: "" });
        this.setState({ name: "" });
        this.setState({ image: "" });
        this.setState({ project_state: "IN" });
        this.setState({ category: [] });
        this.setState({ tags: [] });
        this.setState({ groups: {} });
        this.setState({ public: false });
        this.setState({ allow_public_comments: true });
        this.setState({ cover: false });
        this.setState({ completed_at: "" });
        this.setState({ creator: this.props.creator });
      } else {
        if (project.current_project.custom_image) {
          this.setState({ custom_image_setting: "true" });
        } else {
          this.setState({ custom_image_setting: "false" });
        }
        this.setState({ id: project.current_project.id });
        this.setState({ form_new: false });
        this.setState({ description: project.current_project.description });
        this.setState({ name: project.current_project.name });
        this.setState({ image: project.current_project.image + "/file?size=512" });
        this.setState({ project_state: project.current_project.project_state });
        this.setState({ tags: project.current_project.tags });
        this.setState({ category: project.current_project.category });
        this.setState({ groups: project.current_project.groups });
        this.setState({ public: project.current_project.public });
        this.setState({ allow_public_comments: project.current_project.allow_public_comments });
        this.setState({ share_link: project.current_project.share_link });
        this.setState({
          share_link_enabled: project.current_project.share_link_enabled,
        });
        this.setState({ created_at: project.current_project.created_at });
        this.setState({ updated_at: project.current_project.updated_at });
        this.setState({ creator: project.current_project.creator });
        this.setInitialGroupSelection(groups, project.current_project.groups);
        this.setState({ cover: project.current_project.cover });
        if (project.current_project.completed_at) {
          const local_date = new Date(project.current_project.completed_at);
          const day = ("0" + local_date.getDate()).slice(-2);
          const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
          // let hour = local_date.getHours();
          // let min  = local_date.getMinutes();
          // hour = (hour < 10 ? "0" : "") + hour;
          // min = (min < 10 ? "0" : "") + min;
          const local_date_state =
            local_date.getFullYear() + "-" + month + "-" + day;
          this.setState({ completed_at: local_date_state });
        }
      }

      this.setState({ initial: false });
    }
    if (this.state.initialgroups && !groups_loading) {
      //console.log("nowwwww");
      this.setState({ initialgroups: false });
      this.setInitialGroupSelection(groups, project.current_project.groups);
    }
    //console.log(this.state.category);
  }
  handleImageChange = (e) => {
    this.setState({ selectedImage: "" });
    if (e.target.files.length) {
      this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
      this.setState({ imageRaw: e.target.files[0] });
    }
  };

  imageContainer() {
    // const { element } = this.props;
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <CoverImage src={imagesrc}>
          {this.state.form_new ? (
            <UploadButtonsSingle>
              <label htmlFor="upload-button">
                {" "}
                <UploadButton>Upload New</UploadButton>{" "}
              </label>
              <input
                type="file"
                id="upload-button"
                accept={mimetype.image}
                multiple={false}
                style={{ display: "none" }}
                onChange={this.handleImageChange}
              />
            </UploadButtonsSingle>
          ) : (
            <UploadButtons>
              <label htmlFor="upload-button">
                {" "}
                <UploadButton>Upload New</UploadButton>{" "}
              </label>
              <input
                type="file"
                id="upload-button"
                accept={mimetype.image}
                multiple={false}
                style={{ display: "none" }}
                onChange={this.handleImageChange}
              />

              <Ortext>or</Ortext>
              <UploadButton
                onClick={() => {
                  this.handleOpenPopup();
                }}>
                Pick from Element
              </UploadButton>
            </UploadButtons>
          )}
        </CoverImage>
      );
    }
  }
  imageContainerCondensed() {
    // const { element } = this.props;
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <ImageLine>
          <CoverImageSmall src={imagesrc}> </CoverImageSmall>
          {this.state.form_new ? (
            <UploadButtonsCondensed>
              <Button
                variant="contained"
                component="label"
                disableElevation
                color="primary"
                style={{ maxWidth: "100px", minWidth: "100px" }}>
                Upload
                <input
                  type="file"
                  id="upload-button"
                  accept={mimetype.image}
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={this.handleImageChange}
                />
              </Button>
            </UploadButtonsCondensed>
          ) : (
            <UploadButtonsCondensed>
              <Button
                variant="contained"
                component="label"
                disableElevation
                color="primary"
                style={{ maxWidth: "100px", minWidth: "60px" }}>
                Upload
                <input
                  type="file"
                  id="upload-button"
                  accept={mimetype.image}
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={this.handleImageChange}
                />
              </Button>

              <OrtextC>or</OrtextC>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                wrap="true"
                style={{
                  minWidth: "155px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                onClick={() => {
                  this.handleOpenPopup();
                }}>
                Pick from Element
              </Button>
            </UploadButtonsCondensed>
          )}
        </ImageLine>
      );
    }
  }
  render() {
    const {
      project,
      elements,
      dimensions,
      project_url,
      new_dialog,
      categories,
      categories_loading,
    } = this.props;
    let showCompleted = false;
    if (this.state.project_state === "CO") {
      showCompleted = true;
    }

    let imagesrc = this.state.image;

    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    console.log(imagesrc)
    let pageTitle = "Project Settings";
    let default_cat = this.state.category;
    if (new_dialog) {
      pageTitle = "New Project";
      default_cat = [];
    }

    return (
      <div style={{ height: "100%", position: "relative" }}>
          {this.state.showSaveConfirmation ? (
              <StyledElementPopup>
                <ElementPanel>
                  <ElementCardConfirmation
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <ConfirmationDescription>
                      This project has changes that have not been saved.
                    </ConfirmationDescription>
                    <ButtonGroup
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <StyledButtonLeft2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.handleCloseDialog();
                          }}
                          disableElevation
                          variant="outlined"
                          color="primary"
                          style={{ minWidth: "150px" }}>
                          Don't Save
                        </Button>
                      </StyledButtonLeft2>
                      <StyledButtonRight2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.savePage();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{ minWidth: "180px" }}>
                          Save Changes
                        </Button>
                      </StyledButtonRight2>
                    </ButtonGroup>
                  </ElementCardConfirmation>
                </ElementPanel>
              </StyledElementPopup>
            ) : (
              ""
            )}
        {this.state.showDeleteConfirmation ? (
          <ConfirmationWrapper>
            <ElementPanel isMobile={dimensions.window_dimensions.isMobile}>
              <ConfirmationCard isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsHeader>
                  <SettingsTitle
                    isMobile={dimensions.window_dimensions.isMobile}>
                    Do you really want to delete this project?
                  </SettingsTitle>
                  <CloseButtonNarrow
                    isMobile={dimensions.window_dimensions.isMobile}
                    onClick={() => {
                      this.handleClosePopup();
                    }}>
                    <IconButton>
                      <CloseIcon sx={{ fontSize: "22px" }} />
                    </IconButton>
                  </CloseButtonNarrow>

                </SettingsHeader>
                <ConfirmationDescription>
                  Deleting this project will delete all elements and comments and
                  cannot be undone.
                </ConfirmationDescription>
                <ButtonGroup isMobile={dimensions.window_dimensions.isMobile}>
                  <StyledButtonLeft isMobile={dimensions.window_dimensions.isMobile}>
                    <Button
                      onClick={() => {
                        this.handleClosePopup();
                      }}
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px" }}>
                      No
                    </Button>
                  </StyledButtonLeft>
                  <StyledButtonRight isMobile={dimensions.window_dimensions.isMobile}>
                    <Button
                      onClick={() => {
                        this.deletePage();
                      }}
                      disableElevation
                      variant="contained"
                      color="secondary"
                      style={{ minWidth: "150px" }}>
                      Yes, Delete Project
                    </Button>
                  </StyledButtonRight>
                </ButtonGroup>
              </ConfirmationCard>
            </ElementPanel>
          </ConfirmationWrapper>
        ) : (
          ""
        )}
        {this.state.showpopup ? (
          <StyledElementPopup
            onClick={() => {
              this.handleClosePopup();
            }}>
            <ElementPanel
              onClick={(e) => {
                this.doNothing(e);
              }}>
              <PopupDialog>
                <SettingsHeader>
                  <SettingsTitle
                    isMobile={dimensions.window_dimensions.isMobile}>
                    Select an Image
                  </SettingsTitle>
                  <CloseButtonNarrow
                    isMobile={dimensions.window_dimensions.isMobile}
                    onClick={() => {
                      this.handleClosePopup();
                    }}>
                    {" "}
                    <IconButton fontSize="inherit" size="large">
                      <CloseIcon sx={{ fontSize: "22px" }} />
                    </IconButton>
                  </CloseButtonNarrow>
                </SettingsHeader>
                <ImageSet>
                  {this.state.uniqueImageIDS.map((imgid, index2) => {
                    const image_url =
                      api_endpoint + "/media/" + imgid + "/file?size=256";
                    //console.log(image_url);

                    return (
                      <SmallElement
                        src={image_url}
                        key={"el" + index2}
                        cardwidth={100}
                        onClick={() => {
                          this.handleSelectImage(
                            api_endpoint +
                            "/media/" +
                            imgid +
                            "/file?size=256",
                            imgid
                          );
                        }}></SmallElement>

                    );
                  })}
                  {/* {elements.allIDS
                    .filter((elementid) => {
                      return elements.byID[elementid].images.length > 0;
                    })
                    .map((elementid, index) => {
                      return (
                        <div key={"el" + index}>
                          {elements.byID[elementid].images.map((image, i) => {
                            return (
                              <SingleImage
                                onClick={() => {
                                  this.handleSelectImage(
                                    api_endpoint +
                                    "/media/" +
                                    image.id +
                                    "/file?size=256",
                                    image.id
                                  );
                                }}
                                key={"image" + i}>
                                <img
                                  alt={image.alt_text}
                                  src={
                                    api_endpoint +
                                    "/media/" +
                                    image.id +
                                    "/file?size=256"
                                  }
                                  height="75px"></img>
                              </SingleImage>
                            );
                          })}
                        </div>
                      );
                    })} */}
                </ImageSet>
              </PopupDialog>
            </ElementPanel>
          </StyledElementPopup>
        ) : (
          ""
        )}
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={425} dialog_width={"auto"}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Project Settings
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialogCheck();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                {this.props.new_dialog && this.state.directUI ? ("") : (<ColumnMiddle isMobile={dimensions.window_dimensions.isMobile}>
                  <PreviewContent
                    isMobile={dimensions.window_dimensions.isMobile}>

                    <ThumbContent>
                      <ProjectThumb
                        name={this.state.name}
                        creator={this.state.creator}
                        id={this.state.id}
                        preview_only={true}
                        src={imagesrc}
                        directurl={true}
                        cat={this.state.category}
                        count={elements.allIDS.length}
                        image_override={this.state.imagePreview}
                        
                      />
                    </ThumbContent>
                  </PreviewContent>

                </ColumnMiddle>)}

                <ColumnRight directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                  <CropPanel
                    w={this.state.panelW}
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                      <PanelOne w={this.state.panelW}>
                        {this.state.paneltwo === 0 ? (
                          <div>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(1);
                              }}>
                              <SectionIcon>
                                <TitleIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Name</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(7);
                              }}>
                              <SectionIcon>
                                <InsertPhotoOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Project Image</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(2);
                              }}>
                              <SectionIcon>
                                <FontDownloadIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Description <SectionNumber>{this.state.description ? ("(✓)") : ("")}</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(3);
                              }}>
                              <SectionIcon>
                                <BookmarkBorderIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Categories <SectionNumber>({this.state.category.length})</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(4);
                              }}>
                              <SectionIcon>
                                <LabelOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Tags <SectionNumber>({this.state.tags.length})</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(5);
                              }}>
                              <SectionIcon>
                                <HdrWeakOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Project State  <SectionNumber>({this.state.project_state})</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(6);
                              }}>
                              <SectionIcon>
                                <ShareOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Sharing</SectionLabel>
                            </EditItem>
                          </div>
                        ) : (
                          ""
                        )}
                      </PanelOne>
                      <PanelTwo w={this.state.panelW}>
                        {
                          {
                            0: (
                              <SectionContent
                                w={this.state.panelW}></SectionContent>
                            ),
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Name</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="name"
                                      label={null}
                                      variant="outlined"
                                      value={this.state.name}
                                      onChange={this.onChange}
                                      size="small"
                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />
                                  </FormControl>
                                  {this.state.nameerror ? (
                                    <StyledError>Name is required</StyledError>
                                  ) : (
                                    ""
                                  )}
                                </ControlLine>

                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                {this.props.new_dialog && this.state.directUI ? (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      More Options
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.newPage();
                                      }}>
                                      Create
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>) : (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>)}

                              </SectionContent>
                            ),
                            7: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Project Image</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  style={{ marginBottom: "15px" }}
                                  isMobile={dimensions.window_dimensions.isMobile}>
                                  <FormControl component="fieldset">

                                    <RadioGroup

                                      value={this.state.custom_image_setting}
                                      onChange={this.onChangeRadioImage}>
                                      <FormControlLabel
                                        value="false"
                                        control={<Radio disableRipple color="primary" />}
                                        label="Dynamic (most recent)"
                                      />
                                      <FormControlLabel
                                        value="true"
                                        control={<Radio disableRipple color="primary" />}
                                        label="Fixed"
                                      />

                                    </RadioGroup>
                                  </FormControl>
                                </ControlLine>
                                {this.state.custom_image_setting === "true" ? (<div>{this.imageContainerCondensed()}</div>) : ("")}


                                <SectionHelpText>
                                  Dynamic, automatically selects the most recent element image as the project image. Fixed allows for selecting or uploading a specific image.
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(7);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            2: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Description</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="description"
                                      variant="outlined"
                                      label={null}
                                      multiline
                                      rows={3}
                                      inputProps={{ maxLength: 140 }}
                                      value={this.state.description}
                                      onChange={this.onChange}
                                      size="small"
                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />
                                  </FormControl>
                                </ControlLine>

                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(2);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            3: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Categories</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl
                                    style={{
                                      marginTop: "-15px",
                                      marginBottom: "-12px",
                                    }}
                                    fullWidth
                                    variant="outlined">
                                    <Autocomplete
                                      size="small"
                                      multiple
                                      options={categories}
                                      getOptionLabel={(option) => option.name}
                                      defaultValue={default_cat}
                                      onChange={this.onCatsChange}
                                      // onInputChange={this.onInputChange} //** on every input change hitting my api**
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label={null}
                                            placeholder={null}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{
                                              style: { fontSize: 14 },
                                            }}
                                          />
                                        );
                                      }}
                                    />
                                  </FormControl>
                                </ControlLine>

                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(3);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            4: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Tags</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="newtag"
                                      label={null}
                                      variant="outlined"
                                      value={this.state.newtag}
                                      onKeyDown={e => this.handleAddTag(e)}
                                      onChange={e => this.onChange(e)}
                                      size="small"

                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />

                                  </FormControl>

                                </ControlLine>
                                <ControlLine>
                                  <Stack direction="row" spacing={0.5} sx={{ flexWrap: "wrap" }}>


                                    {this.state.tags.map((tag, index) => {
                                      return (
                                        <Chip style={{ marginBottom: "5px" }} key={index + "tag"} variant="outlined" label={tag} onDelete={() => this.handleDelete(index)} />

                                      );
                                    })}
                                  </Stack>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(4);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            5: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Project State</SectionLabel2>
                                </SectionHeader>

                                <ControlLine
                                  style={{ marginBottom: "15px" }}
                                  isMobile={dimensions.window_dimensions.isMobile}>
                                  <FormControl component="fieldset">

                                    <RadioGroup

                                      value={this.state.project_state}
                                      onChange={this.onChangeRadio}>
                                      <FormControlLabel

                                        value="TO"
                                        control={<Radio disableRipple color="primary" />}
                                        label="To Do"
                                      />
                                      <FormControlLabel
                                        value="IN"
                                        control={<Radio disableRipple color="primary" />}
                                        label="In Progress"
                                      />
                                      <FormControlLabel
                                        value="CO"
                                        control={<Radio disableRipple color="primary" />}
                                        label="Complete"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </ControlLine>
                                {showCompleted ? (
                                  <div>
                                    <ControlLine
                                      style={{ marginBottom: "18px" }}
                                      isMobile={dimensions.window_dimensions.isMobile}>
                                      <FormControl fullWidth variant="outlined">
                                        <TextField
                                          id="completed_at"
                                          label="Completion Date"
                                          type="date"
                                          value={this.state.completed_at || ""}
                                          onChange={this.onChange}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      </FormControl>
                                    </ControlLine>
                                    <ControlLine
                                      isMobile={dimensions.window_dimensions.isMobile}>
                                      <FormControl component="fieldset">
                                        <FormGroup>
                                          <FormControlLabel
                                            // style={{ marginTop: "10px" }}
                                            control={
                                              <Checkbox
                                                // style={{ marginTop: "-25px" }}
                                                disableRipple
                                                color="primary"
                                                checked={this.state.cover}
                                                onChange={this.onChangeCover}
                                                name="cover"
                                                id="cover"
                                              />
                                            }
                                            label="Show Cover Page"
                                          />
                                        </FormGroup>
                                      </FormControl>
                                    </ControlLine>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(5);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            6: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Sharing</SectionLabel2>
                                </SectionHeader>

                                <ControlLine
                                  isMobile={dimensions.window_dimensions.isMobile}>
                                  <FormControl component="fieldset">
                                    <FormGroup style={{ marginBottom: 8 }}>

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disableRipple
                                            color="primary"
                                            checked={this.state.share_link_enabled || false}
                                            onChange={e => { this.onChangeSharing(e) }}
                                            style={{ marginBottom: "auto", paddingTop: 0 }}

                                            name="project__share_link_enabled"
                                            id="project__share_link_enabled"
                                            key="project__share_link_enabled"
                                          />
                                        }
                                        label={(
                                          <div>
                                            <OptionTitle>Sharing Link</OptionTitle>
                                            <OptionSubtitle>Anyone with the link can view and comment.</OptionSubtitle>
                                          </div>
                                        )}
                                      // style={{ width: "calc(100%-64px)" }}
                                      />
                                      {this.state.share_link && this.state.share_link_enabled ? (
                                        <div>
                                          <ShareLinkBox>
                                            {window.location.origin}/project/{project.current_project.share_link}
                                          </ShareLinkBox>
                                          <ShareLinkButtons>
                                            <Button
                                              disableElevation
                                              // variant="contained"
                                              color="primary"
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "none",
                                              }}
                                              size="small"
                                              onClick={(e) => {
                                                this.copySharingLink(e);
                                              }}
                                              startIcon={
                                                <ContentCopyIcon style={{ color: "#383838" }} />
                                              }>
                                              Copy Link
                                            </Button>
                                            <Button
                                              disableElevation
                                              // variant="contained"
                                              color="primary"
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "none",
                                              }}
                                              size="small"
                                              onClick={() => {
                                                this.changeSharingLink(this.state.id);
                                              }}
                                              startIcon={
                                                <RefreshIcon style={{ color: "#383838" }} />
                                              }>
                                              Refresh Link
                                            </Button>
                                          </ShareLinkButtons>
                                        </div>
                                      ) : ""}

                                    </FormGroup>
                                    <FormGroup style={{ marginBottom: 8 }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disableRipple
                                            color="primary"
                                            checked={this.state.public || false}
                                            onChange={e => { this.onChangePublic(e) }}
                                            style={{ marginBottom: "auto", paddingTop: 0 }}
                                            name="project__public"
                                            id="project__public"
                                            key="project__public"
                                          />
                                        }
                                        label={(
                                          <div>
                                            <OptionTitle>Public</OptionTitle>
                                            <OptionSubtitle>Anyone can find & view.</OptionSubtitle>
                                          </div>
                                        )}
                                      />
                                    </FormGroup>
                                    {this.state.public || this.state.share_link_enabled ? (<FormGroup style={{ marginBottom: 8 }}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disableRipple
                                            color="primary"
                                            checked={this.state.allow_public_comments}
                                            onChange={e => { this.onChangePublicComments(e) }}
                                            // style={{ marginBottom: "auto", paddingTop: 0 }}
                                            name="project__public__comments"
                                            id="project__public__comments"
                                            key="project__public__comments"
                                          />
                                        }
                                        label={(
                                          <div>
                                            <OptionTitle>Allow public viewers to comment</OptionTitle>

                                          </div>
                                        )}
                                      />
                                    </FormGroup>) : ("")}
                                    <GroupSelect>
                                      <FormGroup style={{ marginBottom: 8 }}>
                                        <div>
                                          <OptionTitle>Groups</OptionTitle>
                                          <OptionSubtitle>People in these groups can find, view and comment.</OptionSubtitle>
                                        </div>
                                      </FormGroup>
                                      <FormGroup style={{ paddingRight: "0" }}>
                                        {this.state.memberships.filter((g) => {
                                          return !g.archived
                                        }).map((group, index) => {
                                          return (
                                            <FormControlLabel
                                              key={index}
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={group.checked || false}
                                                  onChange={this.onChangeGroups}
                                                  name={group.name}
                                                  id={group.id}
                                                />
                                              }
                                              label={group.name}
                                            />
                                          );
                                        })}
                                      </FormGroup>
                                      {this.state.memberships.find((g) => { return g.archived }) !== undefined ? (
                                        <div>
                                          <FormGroup style={{ margin: "8px 0px" }}>
                                            <div>
                                              <OptionTitle>Archived Groups</OptionTitle>
                                              <OptionSubtitle>These groups are read-only. You can remove your project from the group, but you will be unable to add it back.</OptionSubtitle>
                                            </div>
                                          </FormGroup>
                                          <FormGroup style={{ paddingRight: "0" }}>
                                            {this.state.memberships.filter((g) => {
                                              return g.archived
                                            }).map((group, index) => {
                                              return (
                                                <FormControlLabel
                                                  key={index}
                                                  control={
                                                    <Checkbox
                                                      disableRipple
                                                      color="primary"
                                                      checked={group.checked || false}
                                                      onChange={this.onChangeGroups}
                                                      name={group.name}
                                                      id={group.id}
                                                    />
                                                  }
                                                  label={group.name}
                                                />
                                              );
                                            })}
                                          </FormGroup>
                                        </div>
                                      ) : null}
                                    </GroupSelect>
                                  </FormControl>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                {this.state.directShare ? (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      More Options
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.savePage();
                                      }}>
                                      Save
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>) : (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(6);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>)}


                              </SectionContent>
                            ),
                          }[this.state.paneltwo]
                        }
                      </PanelTwo>
                      {/* <PanelThree></PanelThree> */}
                    </PanelContainer>
                  </CropPanel>
                  {this.state.paneltwo !== 0 ? (
                    ""
                  ) : (
                    <div>

                      {this.props.new_dialog ? (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>

                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.newPage();
                            }}>
                            Create
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>) : (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>
                          <StyledButtonDelete>
                            <Button
                              disableElevation
                              variant="contained"
                              color="secondary"
                              style={{ minWidth: "128px" }}
                              onClick={() => {
                                this.handleShowConfirmation();
                              }}>
                              Delete
                            </Button>
                          </StyledButtonDelete>
                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.savePage();
                            }}>
                            Save
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>)}

                    </div>
                  )}
                </ColumnRight>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  project_loading: state.project.loading,
  elements: state.project.current_project.elements,
  userid: state.auth.user.user_id,
  creator: state.auth.myprofile,
  categories_loading: state.fetched.categories.loading,
  categories: state.fetched.categories.data.results,
  groups: state.profile.groups,
  groups_loading: state.profile.groups_loading,
  dimensions: state.dimensions,
  _public: state.public,
  share_link_enabled: state.share_link_enabled,
  auth: state.auth,
});
export default connect(mapStateToProps)(withRouter(ProjectPageEdit));
