export default class Alert {
    id: string;
    timestamp: number;
    duration: number;
    show: boolean;
    hide: boolean;
    severity: string;
    data: any;
    constructor(data: any, severity: string = "info", duration: number = 5000) {
        this.id = Math.random().toString(36).substring(2, 9);
        this.timestamp = Date.now();
        this.duration = duration;
        this.show = false;
        this.hide = false;
        this.severity = severity;
        this.data = data;
    }
}