import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";

import { Link } from "react-router-dom";

const ElementDescription = styled.div`
  width: 86%;
  margin: 7%;
  text-decoration: none !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
`;
const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #383838;

`;
const StyledFooter = styled.div`
  width: 100%;
//  height: ${(props) => (props.isMobile ? "50px" : "20px")};
 box-sizing: border-box;
 padding: 16px;
//  margin-top: 80px;
 background-color: #000;
 display: flex;
`;
const FooterItems = styled.div`
 margin-left: auto;
 margin-right: auto;
 color: #fff;
 font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
display: flex;
 flex-wrap: wrap;
  align-items: center;
  justify-content: center;
width: 80%;
`;
const WrapBreak = styled.div`
display: flex;
`;

class Footer extends Component {
  render() {
    // const { description } = this.props;

    return (
      <StyledFooter isMobile={this.props.isMobile}>
        <FooterItems>
          <WrapBreak> © Made {new Date().getFullYear()}&nbsp;|&nbsp;<a href="mailto:info@asitsmade.com" style={{ textDecoration: "none", color: "#fff" }}>Contact Us</a>{this.props.isMobile ? ("") : (String.fromCharCode(160) + "|" + String.fromCharCode(160))}
          </WrapBreak> <WrapBreak> <Link to="/terms/" style={{ textDecoration: "none", color: "#fff" }}>Terms</Link>&nbsp;|&nbsp;<Link to="/privacy/" style={{ textDecoration: "none", color: "#fff" }}>Privacy</Link>&nbsp;|&nbsp;
            <Link to="/support/" style={{ textDecoration: "none", color: "#fff" }}>Support</Link>
          </WrapBreak>
        </FooterItems>
      </StyledFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(Footer));
