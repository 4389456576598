import React, { Component } from "react";
import styled from "styled-components";
var pixelWidth = require("string-pixel-width");

const ElementDescription = styled.div`
  width: 86%;
  height: 86%;
  margin: 7%;
  text-decoration: none !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #6b6b6b;
  // border-radius: 1.5%;
  // margin: 2.5%;
`;

class Dimension extends Component {
  render() {
    const { unit, x, y, z } = this.props;
    const h = Number(y);
    const w = Number(x);
    const d = Number(z);
    let max;
    const measure_spacing = 30;
    const text_v_spacing = 20;
    const text_h_spacing = 10;
    const text_d_spacing = 40;
    const arrow_correction = 10;
    const fontSize = 14;
    // const unit = unit;
    let wd, hd, xd, yd, dd;

    if (d) {
      const straight = Math.sqrt((d * d) / 2) / 2;

      if (h >= w) {
        let correction = 0;
        if (h / d >= 3) {
          correction = 45;
        }
        const textmargin =
          correction +
          pixelWidth(h + " " + unit, { font: "Arial", size: fontSize }) +
          pixelWidth(d + " " + unit, { font: "Arial", size: fontSize });
        max = 380 - textmargin;
        hd = max * (h / (h + d));
        wd = (w / h) * hd;
        dd = (straight / h) * hd;
        xd = (400 - wd - dd) / 2;
        yd = (400 - hd + dd) / 2;
      } else {
        const textmargin =
          45 +
          pixelWidth(h + " " + unit, { font: "Arial", size: fontSize }) +
          pixelWidth(d + " " + unit, { font: "Arial", size: fontSize });
        max = 380 - textmargin;
        wd = max * (w / (w + d));
        hd = (h / w) * wd;
        dd = (straight / w) * wd;
        xd = (400 - wd - dd) / 2;
        yd = (400 - hd + dd) / 2;
      }
      // console.log(wd);
      return (
        <ElementContainer>
          <ElementDescription>
            <svg
              viewBox="0 0 400 400"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <marker
                  id="startarrow"
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="10 0, 10 7, 0 3.5" fill="#c1c1c1" />
                </marker>
                <marker
                  id="endarrow"
                  markerWidth="10"
                  markerHeight="7"
                  refX="0"
                  refY="3.5"
                  orient="auto"
                  markerUnits="strokeWidth"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill="#c1c1c1" />
                </marker>
              </defs>
              <path
                d={
                  "M " +
                  (xd + dd) +
                  "," +
                  (yd - dd) +
                  " V" +
                  (yd + hd - dd) +
                  " H" +
                  (xd + dd + wd)
                }
                style={{
                  fill: "none",
                  strokeWidth: 2,
                  stroke: "rgb(130,130,130)",
                }}
              />
              <path
                d={
                  "M " +
                  (xd + dd) +
                  "," +
                  (yd + hd - dd) +
                  " L" +
                  xd +
                  "," +
                  (yd + hd)
                }
                style={{
                  fill: "none",
                  strokeWidth: 2,
                  stroke: "rgb(130,130,130)",
                }}
              />

              <path
                d={"M " + xd + "," + yd + " H" + (xd + wd) + " V" + (yd + hd)}
                style={{
                  fill: "none",
                  strokeWidth: 2,
                  stroke: "rgb(255,255,255)",
                }}
              />
              <path
                d={
                  "M " +
                  (xd + wd) +
                  "," +
                  yd +
                  " L" +
                  (xd + wd + dd) +
                  "," +
                  (yd - dd)
                }
                style={{
                  fill: "none",
                  strokeWidth: 2,
                  stroke: "rgb(255,255,255)",
                }}
              />

              <path
                d={
                  "M " +
                  xd +
                  "," +
                  yd +
                  " V" +
                  (yd + hd) +
                  " H" +
                  (xd + wd) +
                  " L" +
                  (xd + wd + dd) +
                  "," +
                  (yd + hd - dd) +
                  " V" +
                  (yd - dd) +
                  " H" +
                  (xd + dd) +
                  " L" +
                  xd +
                  "," +
                  yd
                }
                style={{
                  fill: "none",
                  strokeWidth: 3,
                  stroke: "rgb(255,255,255)",
                }}
              />

              <line
                x1={xd + arrow_correction}
                y1={yd + hd + measure_spacing}
                x2={xd + wd - arrow_correction}
                y2={yd + hd + measure_spacing}
                stroke="#c1c1c1"
                strokeWidth="1"
                markerStart="url(#startarrow)"
                markerEnd="url(#endarrow)"
              />
              <line
                x1={xd - measure_spacing}
                y1={yd + arrow_correction}
                x2={xd - measure_spacing}
                y2={yd + hd - arrow_correction}
                stroke="#c1c1c1"
                strokeWidth="1"
                markerStart="url(#startarrow)"
                markerEnd="url(#endarrow)"
              />
              <line
                x1={xd + wd + measure_spacing + arrow_correction / 2}
                y1={yd + hd - arrow_correction / 2}
                x2={xd + wd + measure_spacing + dd - arrow_correction / 2}
                y2={yd + hd - dd + arrow_correction / 2}
                stroke="#c1c1c1"
                strokeWidth="1"
                markerStart="url(#startarrow)"
                markerEnd="url(#endarrow)"
              />
              <text
                fontSize={fontSize}
                fontFamily="Arial"
                x={
                  xd +
                  wd / 2 -
                  pixelWidth(w + " " + unit, {
                    font: "Arial",
                    size: fontSize,
                  }) /
                    2
                }
                y={yd + hd + measure_spacing + text_v_spacing}
                fill="#fff"
              >
                {w + " " + unit}{" "}
              </text>
              <text
                fontSize={fontSize}
                fontFamily="Arial"
                x={
                  xd -
                  measure_spacing -
                  text_h_spacing -
                  pixelWidth(h + " " + unit, { font: "Arial", size: fontSize })
                }
                y={yd + hd / 2 + fontSize / 2}
                fill="#fff"
              >
                {h + " " + unit}{" "}
              </text>
              <text
                fontSize={fontSize}
                fontFamily="Arial"
                x={xd + wd + dd / 2 + text_d_spacing}
                y={yd + hd - dd / 2 + fontSize / 2}
                fill="#fff"
              >
                {d + " " + unit}{" "}
              </text>
            </svg>
          </ElementDescription>
        </ElementContainer>
      );
    } else {
      if (h >= w) {
        const textmargin = pixelWidth(h + " " + unit, {
          font: "Arial",
          size: fontSize,
        });
        max = 260 - textmargin;
        hd = max;
        wd = (w / h) * max;
        xd = (400 - wd) / 2;
        yd = (400 - hd) / 2;
      } else {
        const textmargin = pixelWidth(h + " " + unit, {
          font: "Arial",
          size: fontSize,
        });
        max = 240 - textmargin;
        wd = max;
        hd = (h / w) * max;
        xd = (400 - wd) / 2;
        yd = (400 - hd) / 2;
      }
      return (
        <ElementContainer>
          <ElementDescription>
            <svg
              viewBox="0 0 400 400"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <marker
                  id="startarrow"
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto"
                >
                  <polygon points="10 0, 10 7, 0 3.5" fill="#c1c1c1" />
                </marker>
                <marker
                  id="endarrow"
                  markerWidth="10"
                  markerHeight="7"
                  refX="0"
                  refY="3.5"
                  orient="auto"
                  markerUnits="strokeWidth"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill="#c1c1c1" />
                </marker>
              </defs>
              <rect
                x={xd}
                y={yd}
                width={wd}
                height={hd}
                style={{
                  fill: "none",
                  strokeWidth: 4,
                  stroke: "rgb(255,255,255)",
                }}
              />
              <line
                x1={xd + arrow_correction}
                y1={yd + hd + measure_spacing}
                x2={xd + wd - arrow_correction}
                y2={yd + hd + measure_spacing}
                stroke="#c1c1c1"
                strokeWidth="1"
                markerStart="url(#startarrow)"
                markerEnd="url(#endarrow)"
              />
              <line
                x1={xd - measure_spacing}
                y1={yd + arrow_correction}
                x2={xd - measure_spacing}
                y2={yd + hd - arrow_correction}
                stroke="#c1c1c1"
                strokeWidth="1"
                markerStart="url(#startarrow)"
                markerEnd="url(#endarrow)"
              />
              <text
                fontSize={fontSize}
                fontFamily="Arial"
                x={
                  xd +
                  wd / 2 -
                  pixelWidth(w + " " + unit, {
                    font: "Arial",
                    size: fontSize,
                  }) /
                    2
                }
                y={yd + hd + measure_spacing + text_v_spacing}
                fill="#fff"
              >
                {w + " " + unit}{" "}
              </text>
              <text
                fontSize={fontSize}
                fontFamily="Arial"
                x={
                  xd -
                  measure_spacing -
                  text_h_spacing -
                  pixelWidth(h + " " + unit, { font: "Arial", size: fontSize })
                }
                y={yd + hd / 2 + fontSize / 2}
                fill="#fff"
              >
                {h + " " + unit}{" "}
              </text>
            </svg>
          </ElementDescription>
        </ElementContainer>
      );
    }
  }
}

export default Dimension;
