import React, { Component } from "react";
import styled from "@emotion/styled";
const StyledG = styled.g`
transform-box: fill-box;
transform-origin: center center;
transform:  ${(props) => (props.transformset)};
`;
class GoldenGrid extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
  

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let x, y, w, h;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
     
      const expand = obj.options.expand;
      let gridwidth, gridheight;

      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }
      if (expand) {
        x = 0;
        y = 0;
        w = 100;
        h = 100 / used_ratio;
        gridwidth = 100;
        gridheight = 100 / used_ratio;
      } else {
        gridwidth = w;
        gridheight = h;
      }

     
      const g_ratio = 1.618;
      const g_large = 1 / g_ratio;
      const g_small = 1 - 1 / g_ratio;
      const bezier_correction = 0.552284749831;
      let lines = [];
      let portrait = false
      let g_y = y + h;
      let g_x = x + w * g_large;
      let start = 0
      let divisions = 10;
      console.log(obj.options)
      if (obj.options.rotate === 90 || obj.options.rotate === 270 ){
        portrait = true
       
      }
      if (portrait){
        g_x = x + w ;
        g_y = y +h * g_large
        start = 1
        divisions = divisions + 1
      }
      
      let g_l1 = h;
      let g_l2 = -w;
      
      for (let i = start; i < divisions; i++) {
        if (i % 2 === 0) {
          const line = (
            <line
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              key={obj.id + "col" + i}
              x1={g_x}
              y1={g_y}
              x2={g_x}
              y2={g_y - g_l1}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
          );
          lines.push(line);
          const g_d =
            "M " +
            (g_x + g_l2 * g_large) +
            " " +
            g_y +
            " C " +
            (g_x + g_l2 * g_large) +
            " " +
            (g_y - g_l1 * bezier_correction) +
            " " +
            (g_x + g_l2 * g_large * bezier_correction) +
            " " +
            (g_y - g_l1) +
            " " +
            g_x +
            " " +
            (g_y - g_l1);

          const path = (
            <path
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              key={obj.id + "col2path" + i}
              d={g_d}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
              fill="none"
            />
          );
          lines.push(path);
          g_y = g_y - g_l1 * g_small;
          g_l2 = -g_l2 * g_small;
        } else {
          const line = (
            <line
            onMouseDown={this.handleMouseDownObj.bind(this, obj)}  
            key={obj.id + "col2" + i}
              x1={g_x}
              y1={g_y}
              x2={g_x + g_l2}
              y2={g_y}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
          );
          const g_d =
            "M " +
            g_x +
            " " +
            (g_y - g_l1 * g_large) +
            " C " +
            (g_x + g_l2 * bezier_correction) +
            " " +
            (g_y - g_l1 * g_large) +
            " " +
            (g_x + g_l2) +
            " " +
            (g_y - g_l1 * g_large * bezier_correction) +
            " " +
            (g_x + g_l2) +
            " " +
            g_y;
          const path = (
            <path
            onMouseDown={this.handleMouseDownObj.bind(this, obj)}
            key={obj.id + "col2path2" + i}
              d={g_d}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
              fill="none"
            />
          );
          lines.push(path);
          g_x = g_x + g_l2 * g_small;
          g_l1 = -g_l1 * g_small;
          lines.push(line);
        }
      }
      let transformset = ""
      if(obj.options.rotate === 0){
        if (obj.options.flip === 0){
          transformset = "scale(1,1) rotate(0deg);"
        }else{
          transformset = "scale(1,-1) rotate(0deg);"
        }
      }
      if(obj.options.rotate === 90){
        if (obj.options.flip === 0){
          transformset = "scale(-1,1) rotate(180deg);"
        }else{
          transformset = "scale(-1,-1) rotate(0deg);"
        }
      }
      if(obj.options.rotate === 180){
        if (obj.options.flip === 0){
          transformset = "scale(1,1) rotate(180deg);"
        }else{
          transformset = "scale(-1,1) rotate(0deg);"
        }
      }
      if(obj.options.rotate === 270){
        if (obj.options.flip === 0){
          transformset = "scale(1,-1) rotate(180deg);"
        }else{
          transformset = "scale(1,1) rotate(0deg);"
        }
      }
      return (
        <g key={obj.id}>
          <StyledG 
            transformset = {transformset}
          >
         
        
          {lines}
          </StyledG>
          {obj.id === objSelect ? (
            <g>
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
               
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
              <rect
               
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
                
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              >
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default GoldenGrid;
