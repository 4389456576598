import React, { Component } from "react";
import styled from "styled-components";

import calcSVG from "../../utils/svgText";

const ElementDescription = styled.div`
  width: 86%;
  margin: 7%;
  text-decoration: none !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #383838;

`;

class Next extends Component {
  render() {
    // const { description } = this.props;

    return (
      <ElementContainer>
        <ElementDescription>
          <svg
            viewBox="0 0 344 364"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* <g>{calcSVG(description, 344, 26, 39, 7, 24, "#fff", "center")}</g> */}
          </svg>
        </ElementDescription>
      </ElementContainer>
    );
  }
}

export default Next;
