import { height } from '@mui/system';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux'
import styled from "@emotion/styled";

const StyledSVG = styled.svg`
    width: 100%;
    height: ${(props) => props.maxHeight}px;
    max-height: ${(props) => props.maxHeight}px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    pointer-events: none;
    fill-rule:evenodd;
    clip-rule:evenodd;
    stroke-linejoin:round;
    stroke-miterlimit:2;
`

const StyledCanvas = styled.canvas`
    width: 100%;
    height: 240px;
    max-height: 240px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
`

function pathFrom(str) {
    return str.split(" ").map(e => {
        try {
            return Number(e)
        } catch (ex) {
            return e
        }
    })
}

function colorFrom(h, a) {
    const r = parseInt(h[1] + h[2], 16)
    const g = parseInt(h[3] + h[4], 16)
    const b = parseInt(h[5] + h[6], 16)
    if (a === undefined) { a = 1 }
    return [r, g, b, a];
}

const paths = [
    {
        a: {
            path: pathFrom("M 1024 -20 C 614 186 246 81 0 -20 L 0 202 L 1024 202 Z"),
            color: colorFrom("#0b62a7", 0.8)
        },
        b: {
            path: pathFrom("M 1024 0 C 544 0 435 0 0 0 L 0 48 L 1024 48 L 1024 12 Z"),
            color: colorFrom("#1e5062", 0.8)
        }
    },
    {
        a: {
            path: pathFrom("M 1024 20 C 586 187 224 105 0 20 L 0 242 L 1024 242 Z"),
            color: colorFrom("#197bca", 0.9)
        },
        b: {
            path: pathFrom("M 1024 0 C 544 0 435 0 0 0 L 0 48 L 1024 48 L 1024 12 Z"),
            color: colorFrom("#1e5062", 0.9)
        }
    },
    {
        a: {
            path: pathFrom("M 1024 70 C 517 234 334 78 0 80 L 0 222 L 1024 222 Z"),
            color: colorFrom("#008278", 0.9)
        },
        b: {
            path: pathFrom("M 1024 0 C 544 0 435 0 0 0 L 0 48 L 1024 48 L 1024 12 Z"),
            color: colorFrom("#008278", 0.9)
        }
    },
    // {
    //     a: {
    //         path: pathFrom("M 1024 320 C 365 394 297 266 0 320 L 0 442 L 1024 440 Z"),
    //         color: colorFrom("#52a7a8")
    //     },
    //     b: { 
    //         path: pathFrom("M 1024 0 C 472 0 464 0 0 0 L 0 48 L 1024 48 L 1024 21 Z"),
    //         color: colorFrom("#52a7a8")
    //     }
    // },
    {
        a: {
            path: pathFrom("M 1024 115 C 337 221 320 66 0 140 L 0 225 L 1024 225 Z"),
            color: colorFrom("#009988")
        },
        b: {
            path: pathFrom("M 1024 0 C 472 0 464 0 0 0 L 0 48 L 1024 48 L 1024 21 Z"),
            color: colorFrom("#009988")
        }
    },




    // {
    //     a: {
    //         path: pathFrom("M 0 69.888 C 627 439.7568 575 -145.152 1024 127.232 L 1024 347.2 L 0 347.2 Z"),
    //         color: colorFrom("#ff0000")
    //     },
    //     b: { 
    //         path: pathFrom("M 0 0 C 501 0 572 0 1024 0 L 1024 48 L 0 48 L 0 24 Z"),
    //         color: colorFrom("#a4b025")
    //     }
    // },

    {
        a: {
            path: pathFrom("M 0 85 C 671 288 639 -40 1024 145 L 1024 305 L 0 305 Z"),
            color: colorFrom("#00997f")
        },
        b: {
            path: pathFrom("M 0 0 C 424 1 582 1 1024 0 L 1024 48 L 0 48 L 0 33 Z"),
            color: colorFrom("#00997f")
        },
    },
    {
        a: {
            path: pathFrom("M 0 130 C 727 284 497 17 1024 160 L 1024 320 L 0 320 Z"),
            color: colorFrom("#009c60")
        },
        b: {
            path: pathFrom("M 0 0 C 424 1 582 1 1024 0 L 1024 48 L 0 48 L 0 33 Z"),
            color: colorFrom("#009c60")
        },
    },
    {
        a: {
            path: pathFrom("M 0 154 C 586 318 493 59 1024 184 L 1024 344 L 0 344 Z"),
            color: colorFrom("#29e185")
        },
        b: {
            path: pathFrom("M 0 0 C 424 1 582 1 1024 0 L 1024 48 L 0 48 L 0 33 Z"),
            color: colorFrom("#008EFF")
        },
    },
    {
        a: {
            path: pathFrom("M 0 184 C 473 326.4 497 97 1024 212 L 1024 -200 L 0 -200 Z"),
            color: colorFrom("#000000")
        },
        b: {
            path: pathFrom("M 0 48 C 512 48 512 48 1024 48 L 1024 0 L 0 0 Z"),
            color: colorFrom("#008EFF")
        },
    },

]

// a is open, b is collapsed
const paths_old = [
    {
        a: {
            path: pathFrom("M 1024 180 C 614 386 246 281 0 180 L 0 402 L 1024 402 Z"),
            color: colorFrom("#1e5062", 0.6)
        },
        b: {
            path: pathFrom("M 1024 51 C 539 51 433 51 1 51 L 0 51 L 1024 51 L 1024 51 Z"),
            color: colorFrom("#1e5062", 0.6)
        }
    },
    {
        a: {
            path: pathFrom("M 1024 220 C 586 387 224 305 0 220 L 0 442 L 1024 442 Z"),
            color: colorFrom("#1e5062", 0.8)
        },
        b: {
            path: pathFrom("M 1024 51 C 477 51 462 50 1 51 L 0 51 L 1024 51 L 1024 51 Z"),
            color: colorFrom("#1e5062", 0.8)
        }
    },
    {
        a: {
            path: pathFrom("M 1024 270 C 517 434 334 278 0 280 L 0 422 L 1024 422 Z"),
            color: colorFrom("#008278", 0.9)
        },
        b: {
            path: pathFrom("M 1024 51 C 477 51 462 50 1 51 L 0 51 L 1024 51 L 1024 51 Z"),
            color: colorFrom("#008278", 0.9)
        }
    },

    {
        a: {
            path: pathFrom("M 1024 315 C 337 421 320 266 0 340 L 0 425 L 1024 425 Z"),
            color: colorFrom("#009988")
        },
        b: {
            path: pathFrom("M 1024 51 C 477 51 462 50 1 51 L 0 51 L 1024 51 L 1024 51 Z"),
            color: colorFrom("#009988")
        }
    },

    {
        a: {
            path: pathFrom("M 0 315 C 762 490 559 159 1024 345 L 1024 505 L 0 505 Z"),
            color: colorFrom("#00997f")
        },
        b: {
            path: pathFrom("M 0 52 C 423 52 580 52 1023 50 L 1024 51 L 0 51 L -1 52 Z"),
            color: colorFrom("#00997f")
        },
    },
    {
        a: {
            path: pathFrom("M 0 330 C 727 484 497 217 1024 360 L 1024 520 L 0 520 Z"),
            color: colorFrom("#009c60")
        },
        b: {
            path: pathFrom("M 0 51 C 422 51 583 51 1023 50 L 1024 51 L 0 51 L 0 51 Z"),
            color: colorFrom("#009c60")
        },
    },
    // {
    //     a: {
    //         path: pathFrom("M 0 354 C 586 518 493 259 1024 384 L 1024 544 L 0 544 Z"),
    //         color: colorFrom("#29e185")
    //     },
    //     b: { 
    //         path: pathFrom("M 0 0 C 424 1 582 1 1024 0 L 1024 51 L 0 51 L 0 33 Z"),
    //         color: colorFrom("#008EFF")
    //     },
    // },
    {
        a: {
            path: pathFrom("M 0 384 C 473 526.4 497 297 1024 412 L 1024 0 L 0 0 Z"),
            color: colorFrom("#000000")
        },
        b: {
            path: pathFrom("M 0 51 C 512 51 512 51 1024 51 L 1024 0 L 0 0 Z"),
            color: colorFrom("#008EFF")
        },
    },

]

function lerp(a, b, t) {
    return a * (1 - t) + (b * t);
}

export function Ribbons({ }) {
    const svgRef = useRef()
    const shadow = (navigator.userAgent.toLowerCase().includes("chrome") || navigator.userAgent.toLowerCase().includes("webkit"));
    const [scrollVal, setScrollVal] = useState(0);
    const isMobile = useSelector((state) => state.dimensions.window_dimensions.isMobile)

    // maxHeight is size of ribbon when fully extended (eg: page just loaded)
    // minHeight is the height of the ribbon when collapsed (eg: scrolled to bottom of page)
    const maxHeight = isMobile ? 120 : 240
    const minHeight = 48

    useEffect(() => {
        const travel = maxHeight - minHeight

        const animatePath = ((val) => () => {
            if (!svgRef.current) { return; }
            for (let i = 0; i < paths.length; i++) {
                let path
                try {
                    path = svgRef.current.getElementById("l" + i)
                } catch (err) {
                    return;
                }
                const anim = paths[i]
                const mx = lerp(anim.a.path[1], anim.b.path[1], val)
                const my = lerp(anim.a.path[2], anim.b.path[2], val)
                const cx1 = lerp(anim.a.path[4], anim.b.path[4], val)
                const cy1 = lerp(anim.a.path[5], anim.b.path[5], val)
                const cx2 = lerp(anim.a.path[6], anim.b.path[6], val)
                const cy2 = lerp(anim.a.path[7], anim.b.path[7], val)
                const cx = lerp(anim.a.path[8], anim.b.path[8], val)
                const cy = lerp(anim.a.path[9], anim.b.path[9], val)
                const lx = lerp(anim.a.path[11], anim.b.path[11], val)
                const ly = lerp(anim.a.path[12], anim.b.path[12], val)
                const zx = lerp(anim.a.path[14], anim.b.path[14], val)
                const zy = lerp(anim.a.path[15], anim.b.path[15], val)
                const pathStr = `M ${mx} ${my} C ${cx1} ${cy1} ${cx2} ${cy2} ${cx} ${cy} L ${lx} ${ly} L ${zx} ${zy} Z`
                const r = lerp(anim.a.color[0], anim.b.color[0], val)
                const g = lerp(anim.a.color[1], anim.b.color[1], val)
                const b = lerp(anim.a.color[2], anim.b.color[2], val)
                const a = lerp(anim.a.color[3], anim.b.color[3], val)
                const colorStr = `rgb(${r},${g},${b})`
                path.setAttribute("d", pathStr)
                path.setAttribute("fill", colorStr)
                // path.setAttribute("opacity", a)
                if (i === paths.length - 1) {
                    let path2
                    try {
                        path2 = svgRef.current.getElementById(`l${i}-smooth`)
                    } catch (err) {
                        return;
                    }
                    path2.setAttribute("d", `M ${mx} ${my} C ${cx1} ${cy1} ${cx2} ${cy2} ${cx} ${cy}`)
                    const r2 = lerp(paths[i - 1].a.color[0], paths[i - 1].b.color[0], val)
                    const g2 = lerp(paths[i - 1].a.color[1], paths[i - 1].b.color[1], val)
                    const b2 = lerp(paths[i - 1].a.color[2], paths[i - 1].b.color[2], val)
                    path2.setAttribute("stroke", `rgb(${r2},${g2},${b2})`)
                    // if (!shadow) {
                    //     path2.setAttribute("stroke", `rgba(0, 0, 0, .25)`)
                    // } else {
                    //     path2.setAttribute("stroke", `rgb(${r2},${g2},${b2})`)
                    // }
                }
            }
            // svgRef.current.getElementById("background").setAttribute("height", lerp(maxHeight, minHeight, val))
            // svgRef.current.getElementById("shadow-offset").setAttribute("dy", lerp(4, 8, val))
        })

        const scrollHandler = (e) => {
            let top = window.scrollY
            let val = (1 - Math.min(Math.max(0, (travel - top) / travel), 1))
            setScrollVal(val)
            requestAnimationFrame(animatePath(val))
            // animatePath(val)
        }

        // const resizeHandler = (e) => {
        //     const imgAspect = 4/5
        //     svgRef.current.getElementById("background_img").setAttribute("height", svgRef.current.clientWidth / imgAspect )
        // }

        // resizeHandler()
        scrollHandler()

        document.addEventListener('scroll', scrollHandler)
        document.addEventListener('touchmove', scrollHandler)
        // window.addEventListener('resize', resizeHandler)
        return () => {
            document.removeEventListener('scroll', scrollHandler)
            document.removeEventListener('touchmove', scrollHandler)
            // window.removeEventListener('resize', resizeHandler)
        }
    }, [minHeight, maxHeight, svgRef])

    return (
        <StyledSVG ref={svgRef} width="100%" height="100%" maxHeight={lerp(maxHeight, 240, scrollVal)} viewBox="2 0 1021 240" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <defs>
                <filter id="shadow" x="0" y="-50%" width="200%" height="200%">
                    <feOffset id="shadow-offset" result="offOut" in="SourceAlpha" dx="0" dy="4" />
                    <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                    <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
            </defs>
            <g>
                <clipPath id="_clip1" shapeRendering="geometricPrecision">
                    <path id={`l${paths.length - 1}`} d="M 0 383 C 1036 591 2843 231 4096 441 L 4096 0 L 0 0 Z" clipRule="nonzero" />
                </clipPath>
                <g clipPath="url(#_clip1)" id="ribbon_group">
                    <rect id="background" x="0" y="0" width="1024" height="240" fill="#008EFF" />
                    {/* <image id="background_img" href="/desktop4.png" x="0" y="0" width="100%" preserveAspectRatio="none"></image> */}
                    {/* <rect x="0%" y="0%" height="100%" width="100%" fill="url(#pattern3)"/> */}
                    {/* <path id="l0" d="M 4096 160 C 2162 394 1729 26 0 220 L 0 512 L 4096 512 Z" fill="#095643" filter="url(#shadow)" />
                    <path id="l1" d="M 4096 220 C 1651 426 1841 75 0 294 L 0 512 L 4096 512 Z" fill="#00ffcc" filter="url(#shadow)" />
                    <path id="l2" d="M 0 242 C 2160 597 2299 8 4096 298 L 4096 512 L 0 512 Z" fill="#00ffcc" filter="url(#shadow)" />
                    <path id="l3" d="M 0 306 C 1682 644 2332 67 4096 362 L 4096 512 L 0 512 Z" fill="#00ffcc" filter="url(#shadow)" /> */}
                    {paths.slice(0, paths.length - 1).map((anim, index) => (
                        <path
                            id={`l${index}`}
                            key={index}
                            filter={shadow ? "url(#shadow)" : null}
                            stroke={shadow ? null : "rgba(0,0,0,.25)"}
                            strokeWidth={shadow ? null : 1}
                        />
                    ))}
                </g>
                <path id={`l${paths.length - 1}-smooth`} d="M 0 383 C 1036 591 2843 231 4096 441" fill="none" strokeWidth="3" />
            </g>
        </StyledSVG>
    );
}