import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
// import { createProject } from "../actions/homeActions";
import { api_endpoint } from "../config";
import AddIcon from "@mui/icons-material/Add";
import ProjectThumb from "./thumbs/ProjectThumb";
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 45px;
  flex-wrap: wrap;
  padding-bottom: 80px;
`;
const ProjectToolbar = styled.div`
  padding-left: 5px;
  height: 50px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
`;
const StyledInput = styled.input`
  text-align: left;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  // &:focus {
  //     outline-color: #e5e5e5;
  //   }
`;
const ProjectSpacer = styled.div`
  margin-right: 20px;
  margin-bottom: 20px;
`;
class MyProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectname: "",
    };
  }
  componentDidMount() {
    // this.props.dispatch(getProfileDataID(this.props.user_id));
  }
  handleChangename = (event, newValue) => {
    //console.log(newValue);
    this.setState({ projectname: newValue });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  // newProject() {
  //   //console.log(this.state.projectname);
  //   this.props.dispatch(createProject(this.state.projectname));
  // }
  render() {
    const { projects, loading } = this.props;
    if (loading === false) {
      return (
        <div>
          <ProjectToolbar>
            <StyledInput
              id="projectname"
              value={this.state.projectname}
              placeholder="Create new project"
              onChange={this.onChange}
              type="text"
            ></StyledInput>
            <AddIcon
              style={{ marginTop: "5px", fill: "#686868", cursor: "pointer" }}
              onClick={() => {
                this.newProject();
              }}
            ></AddIcon>
          </ProjectToolbar>
          <Projectcontainer>
            {projects.map((project, index) => (
              <ProjectSpacer key={index}>
                <ProjectThumb
                  cat={project.category}
                  count={project.total_elements}
                  creator={project.creator.username}
                  src={project.image ? api_endpoint+"/media/"+project.image.id+"/file?size=512" : ""}
                  name={project.name}
                  id={project.id}
                  link={true}
                />
              </ProjectSpacer>
            ))}
          </Projectcontainer>
        </div>
      );
    } else {
      return <p>loading...</p>;
    }
  }
}
const mapStateToProps = (state) => ({
  loading: state.public.projects_loading,
  projects: state.public.projects,
  user_id: state.auth.user.user_id,
});
export default connect(mapStateToProps)(MyProjects);
