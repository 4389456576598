import React, { Component } from "react";
import { Chip, chipClasses } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete'
import { connect } from "react-redux";
import styled from "styled-components";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FaceIcon from '@mui/icons-material/Face';
import PeopleIcon from '@mui/icons-material/People';
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import { fetchGalleryProjects, getProjectCategories, fetchTags, fetchSearch, setQuery, clearSearchResults } from "../../actions";
import { api_endpoint } from "../../config";
import { flexbox } from "@mui/system";
import theme from "../../config/theme";
import obj2url from "../../utils/obj2url";

// const FilterHolder = styled.div`
//   display: flex;
//   flex-direction: row;
//   margin-left: ${(props) => (props.isMobile ? "auto" : "36px")};
//   margin-right: ${(props) => (props.isMobile ? "auto" : "36px")};
//   margin-top: ${(props) => (props.isMobile ? "10px" : "22px")};
//   margin-bottom: 0;
//   flex-wrap: wrap;ellipsis
//   width: ${(props) => (props.isMobile ? "302px" : "auto")};
//   height: 42px;
//   overflow-x: scroll;
//   overflow-y: show;
//   height: auto;
//   padding-bottom: 0;
//   box-sizing: content-box;
//   align-items: center;
// `

const FilterHolder = styled.div`
  display: flex;
  flex-direction: column;
//   margin-left: ${(props) => (props.isMobile ? "auto" : "36px")};
//   margin-right: ${(props) => (props.isMobile ? "auto" : "36px")};
  margin-top: ${(props) => (props.isMobile ? "10px" : "22px")};
  margin-bottom: 0px;
  width: ${(props) => (props.isMobile ? "302px" : "auto")};
  height: auto;
  box-sizing: border-box;
  overflow-x: visible;
  overflow-y: visible;
  justify-content: center;
  align-items: center;
`

const CategoryList = styled(Stack)(({ theme }) => ({
    width: "100vw",
    maxWidth: "100vw",
    justifyContent: "center",
    flexWrap: "wrap",
    // overflowX: "scroll",
    // margin: "0px auto",
    // minHeight: "160px",
    // marginBottom: "66px",
    // background: "red",
    // height: "auto",
    // overflowX: "scroll",

    "-ms-overflow-style": "none",  /* IE and Edge */
    "scrollbar-width": "none",  /* Firefox */
    "&::-webkit-scrollbar": {
        display: "none",
    },
}))

const TypeChip = styled(Chip)(({ theme }) => ({
    color: "#394b54",
    fontWeight: "700",
    background: "#fff",
    fontSize: "16px",
    height: "36px",
    borderRadius: "24px",
    padding: "6px 10px",
    [`& .${chipClasses.icon}`]: {
        // color: "#f0f"
    },
    // ---------------
    // selected styles
    // ---------------
    "&.selected": {
        // border: "2px solid #000",
        background: "#394B54",
        color: "#fff",
    },
    [`&.selected .${chipClasses.icon}`]: {
        color: "#fff"
    }
}))

const Category = styled.div`
    display: flex;
    width: 160px;
    height: 160px;
    background-size: cover;
    background-color: #ddd;
    justify-content: center;
    align-items: end;
    border: 2px solid white;
    box-sizing: border-box;
    padding-bottom: 8px;
    flex-shrink: 0;
`

const SearchBox = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #ddd;
    padding: 4px;
    border-radius: 16px;
    justify-self: center;
    width: min(calc(100vw - 32px), 500px);
    margin: 16px;
    align-items: center;
`

const IconHolder = styled.div`
    padding-top: 2px;
    padding-left: 6px;
    padding-right: 6px;
`

const IconLabel = styled.div`
    display: flex;
    flex-direction: row;
    // max-height: 32px;
    margin-bottom: 0px; 
    margin-right: 12px; 
    margin-left: 4px;  
    padding-top: 0px;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 0px;
    align-items: center;
    font-size: 16px;
    font-family: roboto, sans-serif;
    font-weight: 500;
    color: #394b54;
    
`

const SearchHolder = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

`

const TypeFilter = styled.span`    
    display: flex;
    flex-direction: row;
`

const StyledInput = styled(InputBase)(({ theme }) => ({
    // padding: 'auto',
    width: '100%',
    border: "none",
    // '& input': {
    //     borderRadius: 0,
    //     backgroundColor: 'transparent',
    //     padding: 0,
    //     border: 'none',
    //     fontSize: 14,
    //     '&:focus': {
    //     },
    // },
}));

const StyledIconButton = styled(IconButton)((data) => {
    return ({
        margin: "0 4px",
        "&.selected": {
            background: "#fff",
            color: "#394b54",
            border: "1px",
            borderColor: "#394b54",
            borderRadius: "24px",
            "&:hover": {
                background: "#008EFF",
                color: "#ffffff"
            }
        }
    })
})

class FilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "projects",
            tags: { data: [] },
            categories: [],
            query: "",
            doSearch: false,
            queryUrlParsed: false,
        }
        this.keyboardTimeout = null
        // window.addEventListener('popstate', (event) => {
        //     if (window.location.pathname === "/discover") {
        //         this.setState({ ...event.state })
        //     }
        // });
    }

    onCategoryChange(evt, sel) {
        if (this.state.categories.includes(sel.id)) {
            this.setState({ categories: [] })
        } else {
            this.setState({ categories: [sel.id] })
        }
        this.setState()
        this.props.dispatch(fetchTags({ category: this.state.categories }))
        this.doSearch()
        return true
    }

    onTagsChange(evt, sel) {
        console.log("selected", sel)
        let data = sel.map(s => s.name ? s : { name: s })
        this.setState({ tags: { data: data.map(s => s.name) } })
        this.doSearch()
        return sel
    }

    onInputChange(evt) {
        this.setState({ query: evt.target.value })
        if (this.keyboardTimeout) {
            clearTimeout(this.keyboardTimeout)
        }
        this.keyboardTimeout = setTimeout(() => {
            this.doSearch()
        }, 500)
    }

    onKeyDown(evt) {
        switch (evt.keyCode) {
            case 9: //tab
            case 13: { //enter
                evt.preventDefault();
                evt.stopPropagation();
                if (this.keyboardTimeout) {
                    clearTimeout(this.keyboardTimeout)
                }
                if (evt.target.value.length > 0) {
                    console.log("keydown");
                    evt.target.blur();
                    evt.target.focus();
                    this.setState({
                        query: evt.target.value,
                    });
                    this.doSearch();
                }

                break;
            }
            default:
        }
    }

    onTypeFilterChange(type) {
        this.setState({ type: type })
        this.doSearch()
    }

    doSearch() {
        this.props.dispatch(clearSearchResults())
        this.setState({ doSearch: true })
    }

    _search() {
        const { tags, categories } = this.props
        let query = {}
        query.tags = this.state.tags.data
        query.category = this.state.categories
        query.query = this.state.query
        // console.log(query)
        this.props.dispatch(fetchSearch(this.state.type, query, this.props.auth.isAuthenticated));
        query.type = this.state.type
        this.props.dispatch(setQuery(query))
        let queryString = obj2url(query)
        const path = window.location.pathname
        window.history.replaceState({ query: queryString, discover: true }, "Search", `${path}${queryString}`)
    }

    parseQueryUrl(qs) {
        let query = {}
        for (const [key, val] of new URLSearchParams(qs || window.location.search)) {
            if (key === "category") {
                if (!query[key]) {
                    query[key] = []
                }
                query[key].push(val)
            }
            if (key === "tags") {
                if (!query[key]) {
                    query[key] = []
                }
                query[key].push(val)
            }
            if (key === "type" || key === "query") {
                query[key] = val
            }
        }

        if (query.category) {
            this.setState({ categories: query.category })
        }
        if (query.tags) {
            this.setState({ tags: { ...this.state.tags, data: query.tags } })
        }
        if (query.type) {
            this.setState({ type: query.type })
        }
        if (query.query) {
            this.setState({ query: query.query })
        }

        this.setState({ queryUrlParsed: true })

    }

    componentDidMount() {
        this.parseQueryUrl()
        this.props.dispatch(getProjectCategories())
        this.props.dispatch(fetchTags())
        this.doSearch()

        // window.addEventListener('popstate', (event) => {
        //     console.log(event)

        //     if (event.state.discover) {
        //         this.parseQueryUrl(event.origio)
        //         this.doSearch()
        //     }
        // });
    }

    componentDidUpdate(prevProps) {
        const {
            tags,
            categories
        } = this.props

        if (this.state.doSearch) {
            this._search()
            this.setState({ doSearch: false })
        }
    }

    render() {
        const {
            tags,
            categories,
            isMobile
        } = this.props
        return (
            <FilterHolder>
                <CategoryList
                    direction="row"
                    spacing={2}
                >
                    <Box />
                    {categories.data.filter(c => c.discoverable).map(c => {
                        return (
                            <Chip
                                key={c.id}
                                label={c.name}
                                style={{ marginTop: "8px" }}
                                color={this.state.categories.includes(c.id) ? "charcoal" : "opaque"}
                                onClick={e => { this.onCategoryChange(e, c) }}
                            />
                        )
                    })}
                    <Box />
                </CategoryList>
                <SearchHolder>
                    {/* <Stack direction="row" sx={{ marginTop: "32px", marginBottom: "20px", width: "480px", maxWidth: "calc(100vw - 32px)" }}> */}
                    <FormControl sx={{ margin: "20px 0", width: '640px', maxWidth: "calc(100vw - 32px)" }} variant="outlined">
                        {/* <InputLabel htmlFor="discover-search" sx={{ backgroundColor: "#fff", borderRadius: "8px", padding: "2px 8px", marginLeft: "-4px" }}>Search</InputLabel> */}
                        <OutlinedInput
                            id="discover-search"
                            placeholder="Search..."
                            sx={{ backgroundColor: "#fff", borderColor: "#394B54", borderRadius: "32px", padding: "0px 8px", boxSizing: "border-box" }}
                            onChange={(e) => { this.onInputChange(e) }}
                            value={this.state.query}
                            size="small"
                            endAdornment={
                                <InputAdornment position="end" sx={{ marginRight: "10px" }}>
                                    <IconButton
                                        aria-label="search"
                                        edge="end"
                                        color="charcoal"
                                        onClick={e => { this.doSearch() }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    {/* <IconButton aria-label="search" sx={{ margin: "0 4px" }} onClick={e => { this.doSearch() }}>
                            <SearchIcon fontSize="inherit" />
                        </IconButton> */}
                    {/* </Stack> */}
                    {/* <Autocomplete
                        // autoComplete
                        // autoHighlight
                        multiple
                        freeSolo
                        includeInputInList
                        // selectOnFocus
                        // clearOnBlur
                        // handleHomeEndKeys
                        // includeInputInList
                        id="cool-search"
                        sx={{ width: isMobile ? "95vw" : "600px", margin: "0 4px" }}
                        options={tags.data}
                        // filterOptions={(x) => x}
                        defaultValue={this.state.tags.data}
                        // value={this.state.tags.data}
                        getOptionLabel={(opt) => { return opt.name }}
                        onChange={(e, s) => { this.onTagsChange(e, s) }}
                        onInputChange={(e) => { this.onInputChange(e) }}
                        renderInput={(params) => {
                            let altParams = {
                                ...params, InputProps: {
                                    ...params.InputProps,
                                    disableunderline: "true",
                                    // endAdornment: (
                                    //     <InputAdornment position="end">
                                    //         <IconButton
                                    //             aria-label="search"
                                    //             edge="end"
                                    //         >
                                    //             <SearchIcon />
                                    //         </IconButton>
                                    //     </InputAdornment>
                                    // )
                                }
                            }
                            return (
                                <TextField
                                    // label="Search"
                                    placeholder="Search..."
                                    sx={{ marginTop: "32px", marginBottom: "20px", background: theme.palette.charcoal.contrastText, borderRadius: "6px" }}
                                    // sx={{ padding: "0 4px", margin: "0", border: "none" }}
                                    {...altParams}
                                />
                            )
                        }}
                        renderTags={(value, getTagProps) => {
                            console.log(value)
                            return value.map((option, index) => {
                                console.log("option", option)
                                return (<Chip variant="outlined" label={option.name || option} {...getTagProps({ index })} />)
                            })
                        }}

                    /> */}
                    <TypeFilter>
                        {/* <IconLabel>
                            <StyledIconButton
                                aria-label="projects"
                                className={this.state.type == "projects" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("project") }}
                            >
                                <CardTravelOutlinedIcon fontSize="inherit" />
                                <IconLabel>Projects</IconLabel>
                            </StyledIconButton>
                            </IconLabel>
                            <IconLabel>
                            <StyledIconButton
                                aria-label="people"
                                className={this.state.type == "users" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("user") }}
                            >
                                <FaceIcon fontSize="inherit" />
                                <IconLabel>People</IconLabel>
                            </StyledIconButton>
                            </IconLabel>

                            <IconLabel>
                            <StyledIconButton
                                aria-label="groups"
                                className={this.state.type == "groups" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("group") }}
                            >
                                <PeopleIcon fontSize="inherit" />
                                <IconLabel>Groups</IconLabel>
                            </StyledIconButton>
                            </IconLabel> */}
                        <Stack direction="row" spacing={isMobile ? 1 : 3}>
                            <TypeChip
                                aria-label="projects"
                                className={this.state.type == "projects" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("projects") }}
                                icon={isMobile && this.state.type != "projects" ? null : <CardTravelOutlinedIcon />}
                                label={isMobile && this.state.type != "projects" ? <CardTravelOutlinedIcon /> : "Projects"} />
                            <TypeChip
                                aria-label="people"
                                className={this.state.type == "users" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("users") }}
                                icon={isMobile && this.state.type != "users" ? null : <FaceIcon />}
                                label={isMobile && this.state.type != "users" ? <FaceIcon /> : "People"} />
                            <TypeChip
                                aria-label="groups"
                                className={this.state.type == "groups" ? "selected" : null}
                                onClick={e => { this.onTypeFilterChange("groups") }}
                                icon={isMobile && this.state.type != "groups" ? null : <PeopleIcon />}
                                label={isMobile && this.state.type != "groups" ? <PeopleIcon /> : "Groups"} />
                        </Stack>

                    </TypeFilter>
                </SearchHolder>
            </FilterHolder >
        )
    }

    // render() {
    //     const {
    //         tags,
    //         categories,
    //         isMobile
    //     } = this.props
    //     if (tags.loading || categories.loading) {
    //         return <FilterHolder></FilterHolder>
    //     } else {
    //         return (
    //             <FilterHolder>
    //                 <Autocomplete
    //                     disablePortal
    //                     id="category"
    //                     options={categories.data}
    //                     getOptionLabel={(option) => option.name}
    //                     sx={{ top: "auto", marginTop: "3px", width: 282, marginRight: isMobile ? "0" : "1em", overflow: "visible" }}
    //                     renderInput={(params) => <TextField {...params} variant="outlined" label="Category..." />}
    //                     size="small"
    //                     isOptionEqualToValue={(opt, val) => opt.id === val.id}
    //                     onChange={(evt, sel) => this.onCategoryChange(evt, sel)}
    //                 />
    //                 <Stack direction="row" spacing={1} sx={{ height: "100%", alignItems: "center" }}>
    //                     {Object.entries(tags.data).map(([key, value]) => {
    //                         return (
    //                             <Chip
    //                                 label={key}
    //                                 key={key}
    //                                 color={value ? "primary" : "default"}
    //                                 variant="outlined"
    //                                 onClick={(e) => this.onTagsChange(e, key)}
    //                             />
    //                         )
    //                     })}
    //                 </Stack>
    //             </FilterHolder>
    //         )
    //     }
    // }
}

const mapStateToProps = (state) => ({
    tags: state.public.tags,
    categories: state.public.categories,
    isMobile: state.dimensions.window_dimensions.isMobile,
    auth: state.auth,
});
export default connect(mapStateToProps)(FilterComponent);