 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'CloseIcon',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const CloseIcon = (props) => {
  return (
      <SvgIcon {...props}>
<path d="M16.2,5.5L12,9.7L7.8,5.5L5.5,7.8L9.7,12l-4.2,4.2l2.3,2.3l4.2-4.2l4.2,4.2l2.3-2.3L14.3,12l4.2-4.2L16.2,5.5z"/> </SvgIcon>
  );
};

export default CloseIcon;

