import element_types from './element_types'

export const element_types_local = element_types
export const show_waitlist = true
export const debug = window.location.hostname === "localhost" // !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export const site_name = debug ? "🧪Made" : "Made"

export const api_endpoint = debug ? "http://localhost:8000/api" : "https://asitsmade.com/api"
export const current_url = debug ? "http://localhost:8000" : "https://asitsmade.com"
export const captcha_site_key = debug ? process.env.REACT_APP_CAPTCHA_SITE_KEY : "6LcF_l4iAAAAAI0YnTwqltIjZH2jg6oyLL_Ne1Qc"
export const ga_measurement_id = process.env.REACT_APP_GA4_ID || "G-SX0QRRD0JS"
export const stripe_publish_key = debug ? "pk_test_51L47LyCWAQxpUIQCBuXbGU0CalTz0XlirDn15dX8fhAtIkZoMroN3C8QuM4Qkv4RTPEvBLjxB7N7Xs2dC5g7xToJ00gTNCyi9u" : "pk_live_51L47LyCWAQxpUIQCMqPCnEg1xdiUEhNeXllh8BhDTaAgjDZG9k3uRFSMuHmquSNKlvCYOI2mBt2lL3hAIhWUBdsM001XlJtdAF" 