import { CONSTANTS } from "../actions";

const initialState = {
  showMenu: false,
  menuType: "",
  default: "",
  showGroupSettings: false,
  selectedElementType: false,
  selectedElement: {element: false, group: false, element_type: false, groups: false},
  groupDialogType: "settings",
  annotationMode: false,
  annotationCoordinates: { x: false, y: false },
  resetProjectLink: false,
  uploadprogress: 0,
  timeouterror: false,
  genericerror: "",
  confirm_wait: false,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_MENU:
      console.log("setmenu reducer");
      return {
        ...state,
        showMenu: action.payload.state,
        menuType: action.payload.type,
        default: action.payload.defaultO,
        showpopupsettings: action.payload.showpopupsettings,
      };
    case CONSTANTS.SET_SELECTED_ELEMENT:
        return {
          ...state,
          selectedElement: {element: action.payload.element, group: action.payload.group, element_type: action.payload.element_type, groups: action.payload.groups} ,
        };
    case CONSTANTS.CONFIRM_WAITLIST:
      return {
        ...state,
        confirm_wait: true,
      };
    case CONSTANTS.SET_UPLOAD_PROGRESS:
      return {
        ...state,
        uploadprogress: action.payload.percentage,
      };
    case CONSTANTS.SET_TIMEOUT_ERROR:
      return {
        ...state,
        timeouterror: action.payload,
      };
    case CONSTANTS.SET_GENERIC_ERROR:
      console.log(action.payload);
      return {
        ...state,
        genericerror: action.payload,
      };
    case CONSTANTS.SET_GROUP_SETTINGS:
      return {
        ...state,
        showGroupSettings: action.payload.state,
        groupDialogType: action.payload.type,
      };
    case CONSTANTS.SELECT_ELEMENT_TYPE:
      return {
        ...state,
        selectedElementType: action.payload,
      };
    case CONSTANTS.SET_ANNOTATE_MODE:
      return {
        ...state,
        annotationMode: action.payload,
      };
    case CONSTANTS.RESET_PROJECT_LINK:
      return {
        ...state,
        resetProjectLink: action.payload,
      };
    case CONSTANTS.SET_ANNOTATE_COORDINATES:
      return {
        ...state,
        annotationCoordinates: { x: action.payload.x, y: action.payload.y },
      };
    default:
      return state;
  }
}
