import React, { Component } from "react";
import styled from "styled-components";
// import TextareaAutosize from "react-textarea-autosize";
import { addComment } from "../actions/projectActions";
import { connect } from "react-redux";
import ChatIcon from "@mui/icons-material/Chat";

import CommentInput from "./CommentInput";
import { Link } from "react-router-dom";
const SingleCommentContainer = styled.div`
  width: 100%;
`;

const MinimalComment = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  margin-left: auto;
  margin-right: auto;
 
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
 
  width: calc(100% - 15px);


  position: relative;
`;
const TopBar = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #dadada;
  background-color: #fff;
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
`;
const CommentLabel = styled.div`
  color: #111;
  margin: auto;
  margin-top: 9px;
  margin-left: 5px;
`;
const LeftButton = styled.div`
  display: flex;
  height: 32px;
  flex-direction: row;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 5px;
  padding-right: 6px;
  padding-top: 2px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: #efefef;
  }
`;
// const RightButton = styled.div`
// margin-left: auto;
//   margin-right: 3px;
//   margin-top: 3px;
//   margin-bottom: 3px;
//   // background-color: #efefef;
//   border-radius: 3px;
//   display: flex;
//   cursor: pointer;
//   flex-direction: row;
//   &:hover {
//     background-color:  #efefef;
//   }
// `;
// const PlusLabel = styled.div`
// margin-top: 11px;
// margin-right: 5px;
// margin-left: 5px;
// font-weight: 600;
// `;
// const AddDot = styled.div`
// border-radius: 3px;
// background-color: #e3e3e3;
// width: 26px;
// height: 26px;
// margin-top: 4px;
// margin-bottom: 4px;
// margin-right: 7px;
// `;
// const Dot = styled.div`
// border-radius: 5px;
// height: 10px;
// width: 10px;
// margin: auto;
// margin-top: 8px;
// background-color: #2196f3;
// `;
// const Buttonspacer = styled.div`
//  width: 10px;
// `;
// const StyledCommentContainer = styled.div`
//   width: 100%;
//   height: ${props => props.comment_height ? props.comment_height : "300"}px;
//   display: flex;
//   flex-direction: column;

// `;
const NewCommentHeader = styled.div`
  background-color: #fff;
  // height: 75px;
  width: calc(100% - 0px);
  margin-left: auto;
  margin-right: auto;
`;
const DividerLine = styled.div`
  border-top: 1px solid #e3e3e3;
`;
class CommentNew extends Component {
  constructor() {
    super();
    this.state = {
      showCommentCard: true,
      comment: "",
    };
  }
  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  setCommentCard = (newValue) => {
    if (this.state.showCommentCard === true) {
      this.setState({ showCommentCard: false });
    } else {
      this.setState({ showCommentCard: true });
    }
  };

  keyPress(e) {
    if (e.keyCode === 13) {
      this.handleAddComment();
      this.setState({ comment: "" });
      this.setCommentCard(false);
    }
  }

  handleChangeComment = (newValue) => {
    this.setState({ comment: newValue });
  };
  handleAddComment = () => {
    this.props.dispatch(addComment(this.state.comment, this.props.elementid));
  };
  render() {
    const {
      element_width,
      isOwner,
      elementid,
      group_id,
      element_url,
      comment_height,
      hasImage,
      element_type,
      allow_public_comments
    } = this.props;
    // const templabel = "Write new comment in ..."
   console.log("hasimage", hasImage)
    if (element_width < 210 || comment_height < 50) {
      return (
        <SingleCommentContainer>
          <MinimalComment>
            <TopBar>
              <Link to={element_url} style={{ textDecoration: "none" }}>
                <LeftButton>
                  <ChatIcon
                    fontSize="small"
                    style={{ color: "#2196f3", margin: "auto" }}
                  />
                  <CommentLabel></CommentLabel>
                </LeftButton>
              </Link>
              {/* <RightButton>
                 <PlusLabel>+</PlusLabel>
                 <AddDot><Dot></Dot></AddDot>
               </RightButton> */}
            </TopBar>
          </MinimalComment>
        </SingleCommentContainer>
      );
    } else {
      return (
        <SingleCommentContainer>
          <MinimalComment>
            {/* <TopBar>
              <LeftButton
                onClick={() => {
                  this.setCommentCard();
                }}
              >
                <ChatIcon
                  fontSize="small"
                  style={{ color: "#2196f3", margin: "auto" }}
                />
                <CommentLabel>Comment</CommentLabel>
              </LeftButton>
            
            </TopBar> */}
            {this.state.showCommentCard ? (
              <div>
               
                <NewCommentHeader>
                  <CommentInput
                    current_group={group_id}
                    element_id={elementid}
                    isOwner={isOwner}
                    hasImage = {hasImage}
                    editMode={false}
                    from_group={group_id}
                    element_type={element_type}
                    placement={"timeline"}
                    allow_public_comments={allow_public_comments}
                  />
                </NewCommentHeader>
              </div>
            ) : (
              ""
            )}
          </MinimalComment>
        </SingleCommentContainer>
      );
    }
  }
}
// const mapDispatchToProps = dispatch => ({
//   dispatch
// })
const mapStateToProps = (state, ownProps) => ({
  element_width:
    state.project.current_project.elements.byID[ownProps.elementid].width,
  groups: state.project.current_project.groups,
  // comments: state.project.current_project.elements.byID[ownProps.id].comments,
  comment_height: state.project.zoom.comment_height,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(CommentNew);
