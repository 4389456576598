import React, { Component } from "react";
import styled from "styled-components";
import calcSVG from "../../utils/svgText";

const ElementDescription = styled.div`
  width: 86%;
  margin: 7%;
  text-decoration: none !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => (props.textcolor ? props.textcolor : "#ccc")};

`;
const Tag = styled.div`
  width: 100%; 
  // height: 100%;turning this off makes it work on iOS, but resizes different on ios
  position: absolute;
  margin-right: ${(props) => (props.feedplacement ? "0" : "0")}px;
  margin-top: ${(props) => (props.feedplacement ? "0" : "0")}px;
  right: 0;
  top: 0;
  z-index: 901;
  // background-color: #ccc;
`;
// const QuestionIcon = styled.div`
//   sss
//   color: #111;
//   margin-left: auto;
//   margin-right: auto;

// `;
class Text extends Component {
  render() {
    const { description, textcolor, label, textalignment, tag, feedplacement } = this.props;
    let align
    switch (textalignment) {
      case "L":
        align = "left";
        break;
      case "C":
        align = "center";
        break;
      case "R":
        align = "right";
        break;
      default:
        align = "center"
        break;
    }
    let labelname = ""
    let labelcolor = ""
    switch (tag) {
      case "TL":
        labelname = "TOOL"
        labelcolor = "#EA1B65"
        break;
      case "MT":
        labelname = "MATERIAL"
        labelcolor = "#2196F3"
        break;
      case "TD":
        labelname = "TO DO"
        labelcolor = "#383838"
        break;
      case "QN":
        labelname = "QUESTION"
        labelcolor = "#f7cd00"
        break;
      default:
      // code block
    }
    // const fontcolor = (textcolor && textcolor != "#C4C4C4") ? "#fff" : "#000"
    return (
      <ElementContainer textcolor={textcolor}>
        {tag ? (
          <Tag feedplacement={feedplacement}>
            <svg
              style={{ width: "100%", height: "100%" }}
              viewBox="0 0 800 800"
              // width="100%"
              // height="100%"
              xmlns="http://www.w3.org/2000/svg">
              <path
                style={{
                  fill: labelcolor,
                }}
                d="M 600 0 L 680 0 L 840 160 L 840 240"
              />
              <text
                letterSpacing="1.5"
                fontSize="28"
                fontFamily="Arial"
                fill="#fff"
                textAnchor="middle"
                transform="translate(726,100) rotate(45)">
                {labelname}
              </text>
            </svg>
          </Tag>
        ) : (
          ""
        )}
        <ElementDescription>
          <svg
            viewBox="0 0 344 364"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* <path
              transform="scale(4),translate(30, 10)"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            ></path> */}
            <g>{calcSVG(description, 344, 26, 39, 7, 24, "#fff", align)}</g>
          </svg>
        </ElementDescription>
      </ElementContainer>
    );
  }
}

export default Text;
