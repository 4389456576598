import React, { Component } from "react";
import styled from "@emotion/styled";

class Rect extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
 

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    
    if (drawnow) {
      let x, y, w, h;
      let width = 1;
      let height = 1;
      let used_ratio = 1;
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      //  x: (clientX - left)/width*100, y: ((clientY - top)/height*100)/this.state.image_ratio };
      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }

      const size = Math.min(w, h);

      return (
        <g key={obj.id}>
          {obj.id === objSelect ? (
            <g>
              {" "}
              <rect
                x={x}
                y={y}
                width={ w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
              
              
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth="2%"
              />
               <rect
              
              
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
             
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
             
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
             
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
            
              >
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    }else{
      return("")
    }
  }
}

export default Rect;
