import React, { Component } from "react";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import Comment from "./Comment";
import CommentInput from "./CommentInput";
import ChatIcon from "@mui/icons-material/Chat";
import { loadMoreComments } from "../actions/projectActions";
import { current_url } from "../config";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const StyledCommentContainer = styled.div`
  width: 100%;
  // height: ${(props) => (props.comment_height ? props.comment_height : "300")}px;
  display: flex;
  flex-direction: column;
`;
const StyledCommentContainer2 = styled.div`
  width: 100%;
  // height: ${(props) => (props.comment_height ? props.comment_height : "300")}px;
  display: flex;
  flex-direction: column;
  margin-top: -11px;
`;
const StyledCommentContainerPopup = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
`;
const StyledCommentContainerPopup2 = styled.div`
  width: 100%;
  // height: 100%;
  display: flex;
  margin-top: -1px;
  flex-direction: column;
`;
const ScrollHideContainer = styled.div`
  position: relative;
  max-height: ${(props) =>
    props.vertical ? "none" : (props.comment_height ? props.comment_height + "px" : "300px")};
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 17px);
  // width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  border-left: 1px solid #C4C4C4;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 3px;
`;
const ScrollHideContainerNoShadow = styled.div`
  position: relative;

  overflow-x: hidden;
    overflow-y: auto;
  width: calc(100% - 2px);
  // width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  // background-color: #C4C4C4;  
  border-left: 1px solid #C4C4C4;
  border-right: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  border-radius: 0px;
  // max-height: calc(80vh - 350px);
  max-height: ${(props) => props.isMobile ? "none": props.isOwner ? props.hasDescription || props.hasLink ? (props.hasDescription ? (props.hasLink ? "calc(100vh - 566px)" : "calc(100vh - 518px)") : (props.hasLink ? "calc(100vh - 384px)" : "calc(100vh - 322px)")) : ("calc(100vh - 322px)") : props.hasDescription || props.hasLink ? (props.hasDescription ? (props.hasLink ? "calc(100vh - 522px)" : "calc(100vh - 474px)") : (props.hasLink ? "calc(100vh - 339px)" : "calc(100vh - 278px)")) : ("calc(100vh - 278px)")};
  // max-height: calc(50vh - 100px);
  height: 50%;
`;
const CommentCard = styled.div`
  // overflow-y: auto;

  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 15px;
  height: 100%;
`;
const OtherGroupComments = styled.div`

  padding-left: 16px;
  padding-top: 16px;
  margin-bottom: 16px;
  display: flex;
  cursor: pointer;
  calc(100% - 23px);
  padding-right: 15px;
  height: 100%;
  border-top: 1px solid #C4C4C4;
`;
const GroupNum = styled.span`

  font-weight: bold;
`;
const GroupName = styled.span`

  font-weight: bold;
`;
const CommentCardPopup = styled.div`
  overflow-y: scroll;

  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 15px;
  
`;
const MinimalComment = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  margin-left: auto;
  margin-right: auto;
  // margin-top: 4px;
  // margin-bottom: 6px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: calc(100% - 15px);
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 3px;

  position: relative;
`;
const MinimalCommentNoShadow = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  // width: calc(100% - 15px);

  border-radius: 3px;

  position: relative;
`;
const TopBar = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  border: 1px solid #dadada;
  display: flex;
  flex-direction: row;

  height: 40px;
  position: relative;
`;
const CommentLabel = styled.div`
  color: #111;
  margin: auto;
  margin-top: 9px;
  margin-left: 5px;
`;
const LeftButton = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  margin-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 5px;
  padding-right: 6px;
  padding-top: 2px;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: #efefef;
  }
`;
// const RightButtonSet = styled.div`
//   margin-left: auto;
//   display: flex;

//   flex-direction: row;
// `;
// const RightButton = styled.div`
//   margin-left: auto;
//   margin-right: 3px;
//   margin-top: 3px;
//   margin-bottom: 3px;
//   // background-color: #efefef;
//   border-radius: 3px;
//   display: flex;
//   cursor: pointer;
//   flex-direction: row;
//   &:hover {
//     background-color: #efefef;
//   }
// `;
// const Dots = styled.div`
//   // width: 20px;

//   margin-right: 3px;
//   margin-top: 3px;
//   margin-bottom: 3px;
//   padding-left: 2px;
//   padding-right: 5px;
//   // background-color: #efefef;
//   border-radius: 3px;
//   display: flex;
//   cursor: pointer;
//   flex-direction: row;
//   &:hover {
//     background-color: #efefef;
//   }
// `;
// const DotLabel = styled.div`
//   color: #111;
//   margin: auto;
//   margin-top: 11px;
//   margin-left: 5px;
//   // margin-right: 2px;
// `;
// const PlusLabel = styled.div`
//   margin-top: 11px;
//   margin-right: 5px;
//   margin-left: 5px;
//   font-weight: 600;
// `;
// const AddDot = styled.div`
//   border-radius: 3px;
//   background-color: #e3e3e3;
//   width: 26px;
//   height: 26px;
//   margin-top: 4px;
//   margin-bottom: 4px;
//   margin-right: 7px;
// `;
// const Dot = styled.div`
//   border-radius: 5px;
//   height: 10px;
//   width: 10px;
//   margin: auto;
//   margin-top: 8px;
//   background-color: #2196f3;
// `;
// const ColoredDot = styled.div`
//   border-radius: 5px;
//   height: 10px;
//   width: 10px;
//   margin: auto;
//   margin-top: 12px;
//   margin-left: 1px;
//   margin-right: 1px;
//   // background-color: #2196f3;
//   background-color: ${(props) => (props.color ? props.color : "#2196f3")};
// `;

const NewCommentContainer = styled.div`
  // width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
`;

const DividerLine = styled.div`
  border-top: 1px solid #e3e3e3;
`;

const MoreButton = styled.div`
  //  width: 100%;
  display: flex;
  padding-left: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ElementPopupContainer = styled.div`
  display: block;
  flex-shrink: 1;
`

class CommentContainer extends Component {
  constructor() {
    super();
    this.state = {
      showCommentCard: true,
      comment: "",
      selectedGroup: "1",
      selectedGroupName: "Private",
      availableGroups: [],
      initial: true,
      othergroups: true,
    };
  }
  switchGroup(groupid) {
    const { project_id, project_name, placement, id } = this.props;
    let element_section = ""
    if (placement ==="popup"){
      element_section="/element/"+id + "/"
    }
    let target;
    if (groupid !== "public") {
      target =
        "/project/" +
        project_id +
        "/" +
        project_name +
        "/group/" +
        groupid +
        element_section;
    } else {
      target =
        "/project/" +
        project_id +
        "/" +
        project_name +
        element_section;
    }

    window.location.href = target;
  }
  componentDidMount() {
    const { has_comments } = this.props;
    if (has_comments) {
      // this.props.dispatch(getElementComments(id));
    }
  }
  componentDidUpdate() {
    const { isOwner } = this.props;
    if (this.state.initial) {
      if (isOwner) {
        // let tempgroup = [...groups.];
        // tempgroup.push({ id: "1", name: "Private" });
        // tempgroup.push({ id: "2", name: "Public" });
        // this.setState({ availableGroups: tempgroup });
      } else {
        // let tempgroup = [...groups];
        // this.setState({ availableGroups: tempgroup });
        // this.setState({ selectedGroup: tempgroup[0].name });
      }

      this.setState({ initial: false });
    }

    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  setCommentCard = (newValue) => {
    if (this.state.showCommentCard === true) {
      this.setState({ showCommentCard: false });
    } else {
      this.setState({ showCommentCard: true });
    }
  };
  keyPress(e) {
    if (e.keyCode === 13) {
      this.myInp.blur();
      this.handleAddComment();
      this.setState({ comment: "" });
    }
  }
  handlePostButton = () => {
    this.myInp.blur();
    if (this.state.comment !== "") {
      this.handleAddComment();
      this.setState({ comment: "" });
    }
  };
  loadMoreComments = (link) => {
    this.props.dispatch(loadMoreComments(link));
  };
  // handleAddComment = () => {
  //   let groupid = false;
  //   if (this.state.selectedGroup !== "1" && this.state.selectedGroup !== "2") {
  //     groupid = this.state.selectedGroup;
  //   }

  //   // this.props.dispatch(addComment(this.state.comment, this.props.id, groupid));
  // };
  handleChangeComment = (newValue) => {
    this.setState({ comment: newValue });
  };
  handleChangeGroup = (event) => {
    this.setState({ selectedGroup: event.target.value });
  };
  render() {
    const {
      id,
      comments,
      comment_height,
      element_width,
      placement,
      isOwner,
      group_id,
      newCommentAnnotationNumber,
      element_type,
      hasImage,
      hasDescription,
      hasLink,
      commentcount,
      zerocomments,
      element_url,
      project_id,
      comments_per_group,
      allow_public_comments,
      vertical,
      dimensions
    } = this.props;
    console.log("hasD:",hasImage)
    // console.log("hasL:",hasLink)
    // let templabel;
    let AnnotationNumber = 0;
    // if (this.state.selectedGroup === "1") {
    //   templabel = "Write new private note...";
    // } else {
    //   templabel = "Write new comment in '" + groups.byID[this.state.selectedGroup].name + "'...";
    // }

    // console.log(comments)
    let OthercommentArray = []
   
    Object.keys(comments_per_group).forEach(key => {
      if (comments_per_group[key] !== 0){
        let groupid = group_id
        if (group_id === false){
          groupid = "public"
        }
        if (key !== groupid){
          let comments
          if (comments_per_group[key] === 1){
            comments = "comment"
          }else{
            comments = "comments"
          }
          let groupname
          if (key === "public"){
            groupname = "Public"
          }else{
            groupname = this.props.groups.byID[key].name
          }
          OthercommentArray.push({id: key, name: groupname, num: comments_per_group[key], comment: comments})
        }
      }
     
      
    });
    
    if (
      (element_width < 210 || comment_height < 50) &&
      placement === "timeline"
    ) {
      if (comments && !zerocomments) {
        return (
          <StyledCommentContainer comment_height={comment_height}>
            <MinimalComment>
              <TopBar>
                <Link to={element_url} style={{ textDecoration: "none" }}>
                  <LeftButton
                  >
                    <ChatIcon
                      fontSize="small"
                      style={{ color: "#2196f3", margin: "auto" }}
                    />
                    <CommentLabel>{commentcount} </CommentLabel>
                  </LeftButton>
                </Link>
                {/* <RightButton>
                  <PlusLabel>+</PlusLabel>
                  <AddDot>
                    <Dot></Dot>
                  </AddDot>
                </RightButton> */}
              </TopBar>
            </MinimalComment>
          </StyledCommentContainer>
        );
      } else {
        return <div></div>;
      }
    } else {
      if (placement === "timeline") {
        if (comments && comments.allIDS.length > 0) {
          return (
            <div>
              <MinimalComment>
            

                <div>

                  <NewCommentContainer>
                    <CommentInput
                      placement={placement}
                      current_group={group_id}
                      from_group={group_id}
                      element_id={id}
                      element_type={element_type}
                      isOwner={isOwner}
                      allow_public_comments={allow_public_comments}
                      hasImage={hasImage}
                      editMode={false}
                      newCommentAnnotationNumber={newCommentAnnotationNumber}
                    />
                  </NewCommentContainer>
                </div>

              </MinimalComment>
              {comments ? (
                <StyledCommentContainer comment_height={comment_height}>
                  <ScrollHideContainer vertical={vertical} comment_height={comment_height}>
                    
                    <CommentCard>
                      {comments.allIDS.map((comment, index) => {
                        return (
                          <Comment
                          comment_type={comments.byID[comment].comment_type}
                          ai={comments.byID[comment].ai}
                          answered={comments.byID[comment].answered}  
                          focus={comments.byID[comment].focus}
                          sentiment={comments.byID[comment].sentiment}
                          placement={placement}
                            key={"comment" + index}
                            isOwner={isOwner}
                            content={comments.byID[comment].content}
                            group={comments.byID[comment].group}
                            from_group={group_id}
                            element_id={id}
                            public_comment={comments.byID[comment].public}
                            author={
                              comments.byID[comment].creator.username
                            }
                            authorid={comments.byID[comment].creator.id}
                            avatar={comments.byID[comment].creator.avatar}
                            id={comments.byID[comment].id}
                            date={comments.byID[comment].updated_at}
                            date_created={comments.byID[comment].created_at}
                            indent={comments.byID[comment].indent}
                            x={comments.byID[comment].x}
                            at_parent={comments.byID[comment].at_parent}
                            broadcast={comments.byID[comment].broadcast}
                            allow_public_comments={allow_public_comments}
                            >
                            
                          </Comment>
                        );

                      })}
                      {comments.next ? (
                        <MoreButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.loadMoreComments(comments.next);
                            }}>
                            Load More Comments...
                          </Button>
                        </MoreButton>
                      ) : (
                        ""
                      )}
                      
                   
                    </CommentCard>
                   
                    {OthercommentArray.map((item, index) => {
                        return(
             <OtherGroupComments key={"com"+index} onClick={() => {
              this.switchGroup(item.id);
            }}>
              <div> <GroupNum>{item.num}</GroupNum>&nbsp;more {item.comment} in group <GroupName>{item.name}</GroupName></div>
            </OtherGroupComments> )
            })
           
            }
                  </ScrollHideContainer>
                </StyledCommentContainer>
              ) : (
               ""
              )}
            </div>
          );
        } else {
          return (
            <StyledCommentContainer2 comment_height={comment_height}>
            <ScrollHideContainer  vertical={vertical} comment_height={comment_height}>
            {OthercommentArray.map((item, index) => {
                        return(
             <OtherGroupComments key={"com"+index} onClick={() => {
              this.switchGroup(item.id);
            }}>
              <div> <GroupNum>{item.num}</GroupNum>&nbsp;more {item.comment} in group <GroupName>{item.name}</GroupName></div>
            </OtherGroupComments> )
            })
           
            }
            
                    
                  </ScrollHideContainer>
                </StyledCommentContainer2>
          )
         
        }
      } else {
        // comments inside an element popup
        return (
          <ElementPopupContainer>
            <MinimalCommentNoShadow>
              {/* <TopBar>
                <LeftButton
                  onClick={() => {
                    this.setCommentCard();
                  }}>
                  <ChatIcon
                    fontSize="small"
                    style={{ color: "#2196f3", margin: "auto" }}
                  />
                  <CommentLabel>{commentcount}</CommentLabel>
                </LeftButton>
                <RightButtonSet>
                
                </RightButtonSet>
              </TopBar> */}
              {this.state.showCommentCard ? (
                <div>
                  {/* <DividerLine></DividerLine> */}
                  <NewCommentContainer>
                    <CommentInput
                      placement={placement}
                      element_type={element_type}
                      hasImage={hasImage}
                      current_group={group_id}
                      from_group={group_id}
                      element_id={id}
                      isOwner={isOwner}
                 
                      editMode={false}
                      allow_public_comments={allow_public_comments}
                      newCommentAnnotationNumber={newCommentAnnotationNumber}
                    />
                  </NewCommentContainer>
                </div>
              ) : (
                ""
              )}
            </MinimalCommentNoShadow>
            {comments && !zerocomments ? (
              <StyledCommentContainerPopup comment_height={comment_height}>
                <ScrollHideContainerNoShadow  isOwner={isOwner} isMobile={dimensions.window_dimensions.isMobile} hasLink={hasLink} hasDescription={hasDescription} comment_height="auto">
              
                  <CommentCardPopup>
                    
                    {comments.allIDS.map((comment, index) => {
                      let Annotation = false;
                      switch (group_id) {
                        case "1":
                          // if groupid is 1 then only private comments
                          console.log("private");

                          if (
                            comments.byID[comment].group === null &&
                            comments.byID[comment].public !== true
                          ) {
                            if (
                              comments.byID[comment].x !== null &&
                              comments.byID[comment].y !== null
                            ) {
                              AnnotationNumber = AnnotationNumber + 1;
                              Annotation = AnnotationNumber;
                            }
                            return (
                              <Comment
                                comment_type={comments.byID[comment].comment_type}
                                ai={comments.byID[comment].ai}
                                answered={comments.byID[comment].answered}
                                focus={comments.byID[comment].focus}
                                sentiment={comments.byID[comment].sentiment}
                                placement={placement}
                                key={"comment" + index}
                                isOwner={isOwner}
                                content={comments.byID[comment].content}
                                group={comments.byID[comment].group}
                                from_group={group_id}
                                element_id={id}
                                public_comment={comments.byID[comment].public}
                                author={comments.byID[comment].creator.username}
                                authorid={comments.byID[comment].creator.id}
                                avatar={comments.byID[comment].creator.avatar}
                                id={comments.byID[comment].id}
                                date={comments.byID[comment].updated_at}
                                date_created={comments.byID[comment].created_at}
                                indent={comments.byID[comment].indent}
                                annotation={Annotation}
                                broadcast={comments.byID[comment].broadcast}
                                at_parent={comments.byID[comment].at_parent}></Comment>
                            );
                          }

                          break;
                        case "2":
                          // if group id is 2, return all comments
                          console.log("all groups");

                          if (
                            comments.byID[comment].group !== null ||
                            comments.byID[comment].public === true
                          ) {
                            if (
                              comments.byID[comment].x !== null &&
                              comments.byID[comment].y !== null
                            ) {
                              AnnotationNumber = AnnotationNumber + 1;
                              Annotation = AnnotationNumber;
                            }
                            return (
                              <Comment
                              comment_type={comments.byID[comment].comment_type}
                              ai={comments.byID[comment].ai}
                              answered={comments.byID[comment].answered}
                              focus={comments.byID[comment].focus}
                              sentiment={comments.byID[comment].sentiment}
                              placement={placement}
                                key={"comment" + index}
                                isOwner={isOwner}
                                content={comments.byID[comment].content}
                                group={comments.byID[comment].group}
                                from_group={group_id}
                                element_id={id}
                                public_comment={comments.byID[comment].public}
                                author={comments.byID[comment].creator.username}
                                authorid={comments.byID[comment].creator.id}
                                avatar={comments.byID[comment].creator.avatar}
                                id={comments.byID[comment].id}
                                date={comments.byID[comment].updated_at}
                                date_created={comments.byID[comment].created_at}
                                indent={comments.byID[comment].indent}
                                annotation={Annotation}
                                broadcast={comments.byID[comment].broadcast}
                                at_parent={comments.byID[comment].at_parent}></Comment>
                            );
                          }

                          break;
                        case "unshared":
                          if (
                            comments.byID[comment].x !== null &&
                            comments.byID[comment].y !== null
                          ) {
                            AnnotationNumber = AnnotationNumber + 1;
                            Annotation = AnnotationNumber;
                          }
                          return (
                            <Comment
                            comment_type={comments.byID[comment].comment_type}
                            ai={comments.byID[comment].ai}
                            answered={comments.byID[comment].answered}
                            focus={comments.byID[comment].focus}
                            sentiment={comments.byID[comment].sentiment}
                              placement={placement}
                              key={"comment" + index}
                              isOwner={isOwner}
                              content={comments.byID[comment].content}
                              group={comments.byID[comment].group}
                              from_group={group_id}
                              element_id={id}
                              public_comment={comments.byID[comment].public}
                              author={comments.byID[comment].creator.username}
                              authorid={comments.byID[comment].creator.id}
                              avatar={comments.byID[comment].creator.avatar}
                              id={comments.byID[comment].id}
                              date={comments.byID[comment].updated_at}
                              date_created={comments.byID[comment].created_at}
                              indent={comments.byID[comment].indent}
                              annotation={Annotation}
                              broadcast={comments.byID[comment].broadcast}
                              at_parent={comments.byID[comment].at_parent}></Comment>
                          );
                        default:
                          console.log("group");
                          // else return specific group comments



                          if (
                            comments.byID[comment].group === group_id ||
                            comments.byID[comment].public === true
                          ) {
                            if (
                              comments.byID[comment].x !== null &&
                              comments.byID[comment].y !== null
                            ) {
                              AnnotationNumber = AnnotationNumber + 1;
                              Annotation = AnnotationNumber;
                            }
                            return (
                              <Comment
                              comment_type={comments.byID[comment].comment_type}
                              ai={comments.byID[comment].ai}
                              answered={comments.byID[comment].answered}
                              focus={comments.byID[comment].focus}
                              sentiment={comments.byID[comment].sentiment}
                                placement={placement}
                                key={"comment" + index}
                                isOwner={isOwner}
                                content={comments.byID[comment].content}
                                group={comments.byID[comment].group}
                                from_group={group_id}
                                element_id={id}
                                public_comment={comments.byID[comment].public}
                                author={comments.byID[comment].creator.username}
                                authorid={comments.byID[comment].creator.id}
                                avatar={comments.byID[comment].creator.avatar}
                                id={comments.byID[comment].id}
                                date={comments.byID[comment].updated_at}
                                date_created={comments.byID[comment].created_at}
                                indent={comments.byID[comment].indent}
                                annotation={Annotation}
                                broadcast={comments.byID[comment].broadcast}
                                at_parent={comments.byID[comment].at_parent}>
                                
                              </Comment>
                            );
                          }

                      }



                    })}
                  </CommentCardPopup>
                  {OthercommentArray.map((item, index) => {
                        return(
             <OtherGroupComments key={"com"+index} onClick={() => {
              this.switchGroup(item.id);
            }}>
              <div> <GroupNum>{item.num}</GroupNum>&nbsp;more {item.comment} in group <GroupName>{item.name}</GroupName></div>
            </OtherGroupComments> )
            })
           
            }
                </ScrollHideContainerNoShadow>
              </StyledCommentContainerPopup>
            ) : (
             
                <StyledCommentContainerPopup2 comment_height={comment_height}>
                <ScrollHideContainerNoShadow  isOwner={isOwner} isMobile={dimensions.window_dimensions.isMobile}  hasLink={hasLink} hasDescription={hasDescription} comment_height="auto">
                {OthercommentArray.map((item, index) => {
                            return(
                 <OtherGroupComments key={"com"+index} onClick={() => {
                  this.switchGroup(item.id);
                }}>
                  <div> <GroupNum>{item.num}</GroupNum>&nbsp;more {item.comment} in group <GroupName>{item.name}</GroupName></div>
                </OtherGroupComments> )
                })
               
                }
                
                        
                      </ScrollHideContainerNoShadow >
                    </StyledCommentContainerPopup2>
              
            )}
          </ElementPopupContainer>
        );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  dimensions: state.dimensions,
  element_width: state.project.current_project.elements.byID[ownProps.id].width,
  comments: state.project.current_project.elements.byID[ownProps.id].comments,
  comment_height: state.project.zoom.comment_height,
  groups: state.project.current_project.groups,
  // group_id: state.groups.current_group.id
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(CommentContainer);
