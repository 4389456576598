import styled from "@emotion/styled";


export const StyledBackGround = styled.div`
  display: flex;
  min-height: 100%;
  margin-top: 20px;
  margin-bottom: 50px;
`;
export const Columns = styled.div`
width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const CenterColumn = styled.div`
  max-width: 700px;
 
  background-color: #fff;
  padding: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 50px;
`;
export const LeftColumn = styled.div`
  width: 200px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "none")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
 
  padding: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
`;


export const Nav = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavItem = styled.div`
  width: 200px;

  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 24px;
`;
