 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const FlipHor = (props) => {
  return (
      <SvgIcon {...props}>
<path d="M3,15v2h2v-2H3z M15,19v2h2v-2H15z M19,3H5C3.9,3,3,3.9,3,5v4h2V5h14v4h2V5C21,3.9,20.1,3,19,3z M21,19h-2v2
	C20.1,21,21,20.1,21,19z M1,11v2h22v-2H1z M7,19v2h2v-2H7z M19,15v2h2v-2H19z M11,19v2h2v-2H11z M3,19c0,1.1,0.9,2,2,2v-2H3z"/> </SvgIcon>
  );
};

export default FlipHor;

