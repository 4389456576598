import { CONSTANTS } from "../actions";
import { showError } from "../actions/alertActions";

const initialState = [];

const methods = {
    [CONSTANTS.CLEAR_ALL_ALERTS]: (state, action) => {
        return initialState
    },
    [CONSTANTS.ON_ALERT]: (state, action) => {
        return [...state, action.payload]
    },
    [CONSTANTS.SET_ALERT_VISIBILITY]: (state, action) => {
        const idx = state.findIndex((alert) => alert.id === action.payload.id);
        if (idx >= 0) {
            const data = {
                ...state[idx],
                show: action.payload.show,
                hide: !action.payload.show
            }
            return [...state.slice(0, idx), data, ...state.slice(idx + 1)]
        } else {
            console.warn("Alert not found: " + action.payload)
        }
        return state
    },
    [CONSTANTS.CLEAR_ALERT]: (state, action) => {
        const idx = state.findIndex((alert) => alert.id === action.payload.id);
        if (idx >= 0) {
            return [...state.slice(0, idx), ...state.slice(idx + 1)]
        } else {
            console.warn("Alert not found: " + action.payload)
        }
        return state
    },
    [CONSTANTS.GET_ERROR]: (state, action) => {
        showError(action.payload);
        return state
    },
    [CONSTANTS.ON_ERROR]: (state, action) => {
        showError(action.payload);
        return state
    },
}

export default function alerts(state = initialState, action) {
    if (Object.keys(methods).includes(action.type)) {
        return methods[action.type](state, action)
    } else {
        return state
    }
}
