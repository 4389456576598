import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getProfileData,
  getProfilePages,
  loadMoreProfilePages,
  newFollow,
  newUnFollow,
  getProfileSubscribers,
  getProfileSubscriptions
} from "../actions/profileActions";

import { ElementCard, TopContainer } from "./styles/MenuComponents";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import Button from "@mui/material/Button";
import ProjectThumb from "./thumbs/ProjectThumb";
import monthYear from "../utils/monthYear";
import { resetProjectLink } from "../actions/uiActions";
import { setMenu } from "../actions/uiActions";
import { Link } from "react-router-dom";
import FirstUseBanner from "./FirstUseBanner";
import { api_endpoint } from "../config";
import theme from "../config/theme";
import imgUrl from "../utils/imgUrl";
import { Stack } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import PersonThumb from "./thumbs/PersonThumb";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { site_name } from "../config";
import setTitle from "../utils/setTitle";

const Page = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #f2f2f2;
  box-sizing: border-box;
  // padding-bottom: 48px;
  max-height: calc(100vh - 48px);
  box-sizing: border-box;
`
const StyledHeader = styled.div`
  padding-left: 15px;
  padding-top: 3px;
  height: 210px;
  background-color: #ff00ff;
  background: url("${(props) => props.src ? props.src : "/profile_header3.jpg"}") #ccc;
  background-size: cover;
  background-position: center;
  display: flex;
`;
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-top: ${(props) => (props.isMobile ? "10px" : "20px")};
  flex-wrap: wrap;
  width: ${(props) => (props.isMobile ? "302px" : "auto")};
  padding-bottom: 50px;
`;
// const EmptyInstruction2 = styled.div`
//   font-family: Roboto, sans-serif;
//   font-weight: 700;
//   text-align: center;
//   color: #ccc;
//   font-size: 20px;
//   line-height: 200%;
//   margin-left: auto;
//   margin-right: auto;
// `;
// const ResetCommand = styled.div`
//   font-family: Roboto, sans-serif;
//   font-weight: 400;
//   text-align: center;
//   color: #ccc;
//   font-size: 14px;
//   line-height: 200%;
//   margin-left: auto;
//   margin-right: auto;
//   cursor: pointer;
// `;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.6);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const MenuPanelNarrow = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
`;
// const MenuPanel = styled.div`
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: auto;
//   margin-bottom: auto;
//   z-index: 100000;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);

//   width: 350px;
// `;
// const ElementCard = styled.div`
//   width: 300px;
//   min-height: 400px;
//   //   min-height: ${(props) =>
//     props.cardheight ? props.cardheight : "300"}px;
//   background-color: #fff;
//   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
//   border-radius: 5px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 4px;
//   display: flex;
//   flex-direction: row;
//   padding-bottom: 20px;
// `;
const FilterMenu = styled.div`
  width: 300px;
  min-height: 280px;
  //   min-height: ${(props) =>
    props.cardheight ? props.cardheight : "300"}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const MenuLine = styled.div`
  color: ${props => props.selected ? "#008EFF" : 'inherit'};
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuIcon = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 13px;
  margin-left: 30px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 700;
`;
const MenuLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #394b54;
`;
const MenuLabelNoIcon = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
  margin-left: 30px;
`;
const SectionLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 700;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 12px;
  color: #757575;
  text-align: left;
`;
const BottomPanel = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  width: 100%;
`;
const ShowHide = styled.div`
 
  display: ${(props) => (props.hide ? "none" : "block")};

 
`;
const LeftInfoBar = styled.div`
  min-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  max-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  height: 25%;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  background-color: sx={{color: theme.palette.black.dark}};
  margin-left: ${(props) => (props.isMobile ? "25px" : "16px")};
  border-radius: 0px;
  margin-top:22px;
  margin-bottom: ${(props) => (props.isMobile ? "15px" : "15px")};
  z-index: 100;
  padding-bottom: 20px;
`;
// const LeftInfoBar = styled.div`
//   min-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "317px")};
//   max-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "317px")};
//   height: 25%;
//   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
//   background-color: #fff;
//   margin-left: ${(props) => (props.isMobile ? "25px" : "36px")};
//   border-radius: 62px;
//   margin-top: -62px;
//   margin-bottom: ${(props) => (props.isMobile ? "25px" : "15px")};
//   z-index: 100;
//   padding-bottom: 20px;
// `;
const InfoTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  // margin-left: 10px;
  margin-top: 10px;
`;
const InfoTitleAvatar = styled.div`
  min-width: 115px;
  max-width: 115px;
  height: 115px;
  border-radius: 50%;
  border: 6px solid #ffffff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  
  background: url("${(props) => props.src ? props.src : "/default_icon.png"}"), linear-gradient(0deg, rgba(56,223,136,1) 0%, rgba(24,145,252,1) 100%);
  background-position: center;
  // background: rgb(24,145,252);
  // background: linear-gradient(0deg, rgba(24,145,252,1) 0%, rgba(56,223,136,1) 100%);

  background-size: cover;
  margin-left: auto;
  margin-right: auto;
 
`;
const InfoTitleRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
 
`;
const InfoTitleName = styled.div`
  display: flex;
 
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: auto;
  margin-top: auto;
  overflow-wrap: anywhere;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
const InfoTitleUserName = styled.div`
  display: flex;
 
  font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
margin-top: 10px;

  overflow-wrap: anywhere;
  text-align: center;
color: #6D6D6D;
 margin-left: auto;
  margin-right: auto;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: 18px;
  margin-right: ${(props) => (props.isMobile ? "18px" : "none")};
 
  margin-top: 32px; 
  
`;
const InfoHeader = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
  //color: #FF00FF;
  &:hover {
    color: #3d3d3d;
  }
`;
const CatItemContainer = styled.div`
  display: flex;
  margin-top: 10px;

  flex-wrap: wrap;
`;
const CatItem = styled.div`
  // border: 1px solid #ccc;
  height: 23px;
  border-radius: 14px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #383838;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
  }
`;
const GroupItem = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  padding-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  // &:hover {
  //   background-color: #f1f1f1;
  // }
`;
const InfoCommandProject = styled.div`
  display: flex;
  width: 100%;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandTextProject = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  margin-top: 0px;
  margin-left: 10px;
`;
const InfoCommandNumber = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 700;
  color: #111;
  margin-top: 0px;
  // margin-left: 10px;
`;
const InfoAboutText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 161.5%;
  color: #6D6D6D;
   text-align: ${(props) => (props.isMobile ? "center" : "left")};
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3; /* number of lines to show */
  // -webkit-box-orient: vertical;
`;
const InfoEst = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  color: #757575;
`;
const InfoExpandContainer = styled.div`
  display: flex;
`;
const InfoCollapseContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
const InfoExpand = styled.div`
  margin-left: auto;
  margin-right: auto;
`;
const RightSection = styled.div`
  margin-top: ${(props) => (props.isMobile ? "0px" : "42px")};
  width: 100%;
  z-index: 10;
`;
const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const PageTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#757575" : "#757575")};
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 40px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "40px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "30px")};
  display: flex;
`;
const BaseTitle = styled.div`
  color: ${(props) =>
    props.isMobile ? "#757575" : "#757575"};
  font-weight: 400;
`;
const SubTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#111" : "#111")};
  cursor: pointer;
`;
const InfoCommand = styled.div`
  display: flex;
  width: 100%;
  font-size: 25px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandText = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #2196f3;
  margin-top: 4px;
 
`;
const RightButtons = styled.div`
  margin-left: auto;
  margin-top: -5px;
  margin-right: 0px;
  line-height: 32px;
`;
const MobileButtons = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
 
 
`;
const Dash = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: ${(props) => (props.isMobile ? "#757575" : "#757575")};

  margin-left: 8px;
  margin-right: 8px;
`;
const Aboutcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-right: 30px;
  margin-top: 50px;
  margin-bottom: 90px;
  flex-wrap: wrap;
  line-height: 30px;
`;
const ProjectSpacer = styled.div`
  margin-top: 16px;
   margin-bottom: 16px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "16px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "16px")};
`;
const GroupNavBarContainer = styled.div`
  display: flex;
  //color: #FF00FF;
  flex-direction: column;
`;
// const GroupNavBar = styled.div`
//   height: 60px;
//   // background-color: #ccc;
//   display: flex;
//   font-size: 14px;
//   font-family: Roboto, sans-serif;
//   font-weight: 400;
//   color: #b6b6b6;
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 20px;
// `;
// const GroupNavItem = styled.div`
//   margin-top: auto;
//   text-transform: uppercase;
//   // margin-right: 20px;
//   color: ${(props) => (props.sel ? "#111" : "#b6b6b6")};
//   font-weight: ${(props) => (props.sel ? "600" : "400")};
//   cursor: pointer;
// `;
const SponsorSection = styled.div`
  display: flex;
`;
const SponsorLeft = styled.div`
  display: flex;
  background-image: url('${(props) => props.src}');
  background-color: ${(props) => props.src ? "transparent" : "#ccc"};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
`;
const SponsorRight = styled.div`
width: calc(100% - 40px);
display: flex;
padding-left: 10px;
padding-top: 10px;
`;
const SponsorName = styled.div`
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size: 16px;
color: #383838;
`;
const SponsorPage = styled.div`
    color: #008EFF;
    margin-top: 2px;
    margin-left: 6px;
    font-size: 16px;
`;
const MenuBox = styled.div`
    margin: auto;
    background: white;
    display: flex;
    width: 250px;
    padding: 24px;
    flex-direction: column;
`
const MoreButton = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
`;
const ContentSection = styled.div`
 overflow-hidden;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  padding: 10px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: ${(props) =>
    props.isMobile ? "none" : "url('/lamp.png')"};
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;
const ElementPanel = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
class ProfilePage extends Component {
  constructor() {
    super();
    this.state = {
      project_state: "ALL",
      project_category: "ALL",
      selectedProject: true,
      selectedMember: false,
      selectedDiscussion: false,
      selectedAbout: false,
      initial: true,
      collapsed: false,
      showstatepopup: false,
      showcatpopup: false,
      NoContent: false,
      showsubscribers: true,
    };
  }
  componentDidMount() {
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    const urlparts = window.location.pathname.split("/");
    const username = urlparts[1];
    let groupid = false;
    let publicprofile = false;
    // let category = false;
    // let state = false;
    // if (this.state.project_category !== "ALL"){
    //   category = this.state.project_category
    // }
    // if (this.state.project_state != "ALL"){
    //   state = this.state.project_state
    // }
    urlparts.forEach((part, index) => {
      if (part === "public") {
        publicprofile = true;
      }
      if (part === "group") {
        groupid = urlparts[index + 1];
      }
    });
    this.props.dispatch(getProfileData(username, groupid, publicprofile, this.props.auth.isAuthenticated));
  }
  componentDidUpdate() {
    // const {
    //   current_profile_loading,
    //   current_profile,
    //   isMobile,
    //   projects,
    //   auth,
    // } = this.props;

    if (this.props.reset) {
      const urlparts = window.location.pathname.split("/");
      const username = urlparts[1];
      let groupid = false;
      let publicprofile = false;
      this.props.dispatch(resetProjectLink(false));
      this.props.dispatch(getProfileData(username, groupid, publicprofile, this.props.auth.isAuthenticated));
    }

    if (
      this.props.isMobile &&
      this.state.collapsed === false &&
      this.state.initial === true
    ) {
      this.setState({ collapsed: true });
      this.setState({ initial: false });
    }
  }
  loadMoreProjects = (link) => {
    const urlparts = window.location.pathname.split("/");

    let groupid = false;
    let publicprofile = false;
    let category = false;
    let state = false;
    if (this.state.project_category !== "ALL") {
      category = this.state.project_category;
    }
    if (this.state.project_state !== "ALL") {
      state = this.state.project_state;
    }
    urlparts.forEach((part, index) => {
      if (part === "public") {
        publicprofile = true;
      }
      if (part === "group") {
        groupid = urlparts[index + 1];
      }
    });
    //console.log(category);
    this.props.dispatch(
      loadMoreProfilePages(link, groupid, publicprofile, category, state)
    );
  };
  handleGetProjects(cat, project_state) {
    const urlparts = window.location.pathname.split("/");

    let groupid = false;
    let publicprofile = false;
    let category = false;
    let state = false;
    if (cat !== "ALL") {
      category = cat;
    }
    if (project_state !== "ALL") {
      state = project_state;
    }
    urlparts.forEach((part, index) => {
      if (part === "public") {
        publicprofile = true;
      }
      if (part === "group") {
        groupid = urlparts[index + 1];
      }
    });
    //console.log(category);
    this.props.dispatch(
      getProfilePages(
        this.props.current_profile.id,
        groupid,
        publicprofile,
        category,
        state
      )
    );
  }
  handleprojectState(state) {
    this.setState({ project_state: state });
    this.setState({ selectedProject: true });
    this.handleGetProjects(this.state.project_category, state);
  }
  handleshowStatePopup() {
    document.body.style.overflow = "hidden";
    this.setState({ showstatepopup: true });
  }
  handleshowCatPopup() {
    document.body.style.overflow = "hidden";
    this.setState({ showcatpopup: true });
  }

  handleCloseMenu() {
    document.body.style.overflow = "auto";
    this.setState({ showstatepopup: false });
    this.setState({ showcatpopup: false });
  }
  handleNewProject() {
    if (!this.props.auth.ticket && this.props.auth.usage.projects >= 1) {
      this.props.dispatch(setMenu(true, 22));
    } else {
      this.props.dispatch(setMenu(true, 6));
    }

  }
  handleNewFollow() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.ticket) {
        this.props.dispatch(newFollow(this.props.current_profile.id, this.props.current_profile.followers_group));
      } else {
        this.props.dispatch(setMenu(true, 22));
      }

    } else {
      this.props.dispatch(setMenu(true, 13));
    }
  }

  handleNewUnFollow() {
    this.props.dispatch(newUnFollow(this.props.current_profile.id, this.props.current_profile.followers_group));
  }
  handleShowSubscribers() {
    this.props.dispatch(getProfileSubscribers(this.props.current_profile.id));
    this.props.dispatch(setMenu(true, 16));
  }
  handleShowSubscriptions() {
    this.props.dispatch(getProfileSubscriptions(this.props.current_profile.id, "projects"));
    this.props.dispatch(setMenu(true, 17));
  }
  doNothing(e) {
    e.stopPropagation();
  }
  handleprojectCat(state) {
    this.setState({ project_category: state });
    this.setState({ selectedProject: true });
    this.handleGetProjects(state, this.state.project_state);
  }
  handleResetFilters() {
    this.setState({ project_category: "ALL" });
    this.setState({ project_state: "ALL" });
    this.setState({ selectedProject: true });
    this.handleGetProjects("ALL", "ALL");
  }
  handleExpandInfo() {
    this.setState({ collapsed: false });
  }
  handleCollapseInfo() {
    this.setState({ collapsed: true });
  }
  changeNav = (tab) => {
    this.setState({ selectedProject: false });
    this.setState({ selectedMember: false });
    this.setState({ selectedDiscussion: false });
    this.setState({ selectedAbout: false });
    switch (tab) {
      case "project":
        this.setState({ selectedProject: true });
        this.setState({ project_state: "ALL" });
        break;
      case "member":
        this.setState({ selectedMember: true });
        break;
      case "discussion":
        this.setState({ selectedDiscussion: true });
        break;
      case "about":
        this.setState({ selectedAbout: true });
        break;
      default:
        this.setState({ selectedProject: true });
    }
  };

  render() {
    const {
      current_profile_loading,
      current_profile,
      isMobile,
      projects,
      auth,
      dimensions
    } = this.props;
    if (!current_profile_loading && current_profile) {
      setTitle(`${current_profile.fullname || current_profile.username} | ${site_name}`)
    }
    let imagesource = "/default_icon.png";
    let group = [];
    let isOwner = false;

    let NoContent = false;
    // let NoFilterContent = false;
    //console.log(projects.no_results);
    let emptyText = "";
    let emptyText2 = "";
    let emptyText3 = "";
    if (!current_profile_loading && projects.allIDS.length === 0) {
      NoContent = true;
      if (projects.no_results === 1) {
        if (current_profile.id === auth.myprofile.id) {
          emptyText = "You have no projects.";
          emptyText2 = "Click the \"New Project\" button to get started!";
          emptyText3 = ""
        } else {
          emptyText = "Oh, that empty feeling!";
          emptyText2 = "It seems like this person doesn't";
          emptyText3 = "have any projects you can view.";
        }
      }
      if (projects.no_results === 2) {
        emptyText = "There are no projects in this filter set";
        emptyText2 = "";
        emptyText3 = "";
      }
    }
    // if (!projects.no_results && projects.allIDS.length === 0){
    //   NoFilterContent = true
    // }
    const error = auth.plan_error;
    let hidesubs = false
    if (current_profile.hide_subscriptions) {
      hidesubs = true
    }

    if (current_profile.id === auth.user.user_id) {
      isOwner = true;
      hidesubs = false
    }
    if (current_profile.avatar) {
      imagesource = api_endpoint + "/media/" + current_profile.avatar.id + "/file?size=256";
    }
    if (current_profile_loading !== false) {
      return (
        <Page>
          <div style={{ height: "100vh", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </div>
        </Page>
      );
    } else {
      const established = monthYear(current_profile.created_at);
      let subtitle = "All States";

      switch (this.state.project_state) {
        case "ALL":
          subtitle = "All States";
          break;
        case "IN":
          subtitle = "In Progress";
          break;
        case "TO":
          subtitle = "Planned";
          break;
        case "CO":
          subtitle = "Completed";
          break;
        default:
        // code block
      }
      let subtitle2 = "All Categories";
      switch (this.state.project_category) {
        case "ALL":
          subtitle2 = "All Categories";
          break;
        default:
          subtitle2 = current_profile.categories.find(
            (cat) => cat.id === this.state.project_category
          ).name;
          break;
        // code block
      }
      const urlparts = window.location.pathname.split("/");

      let groupid = false;

      urlparts.forEach((part, index) => {
        // if (part === "public") {
        //   publicprofile = true;
        // }
        if (part === "group") {
          groupid = urlparts[index + 1];
        }
      });
      let banner;
      if (current_profile.banner) {
        banner = imgUrl(current_profile.banner.id, 2048);
      }
      // console.log(this.state.project_state === "ALL")
      return (
        <Page>
          {/* <FirstUseBanner /> */}

          {this.state.showstatepopup ? (
            <div>
              <StyledElementPopup
                onClick={e => this.handleCloseMenu()}
              >
                <MenuPanelNarrow
                  onClick={() => {
                    this.handleCloseMenu();
                  }}
                >
                  <FilterMenu>
                    <SectionLabel>Select a Project State</SectionLabel>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      <MenuIcon></MenuIcon>
                      <MenuLabel>Show All</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("IN");
                      }}
                    >
                      <MenuIcon>{current_profile.total_inprogress}</MenuIcon>
                      <MenuLabel>In Progress</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("TO");
                      }}
                    >
                      <MenuIcon>{current_profile.total_planned}</MenuIcon>
                      <MenuLabel>Planned</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("CO");
                      }}
                    >
                      <MenuIcon>{current_profile.total_completed}</MenuIcon>
                      <MenuLabel>Completed</MenuLabel>
                    </MenuLine>
                  </FilterMenu>
                </MenuPanelNarrow>
              </StyledElementPopup>
            </div>
          ) : (
            ""
          )}
          {this.state.showcatpopup ? (
            <div>
              <StyledElementPopup
                onClick={() => {
                  this.handleCloseMenu();
                }}
              >
                <MenuPanelNarrow
                  onClick={() => {
                    this.handleCloseMenu();
                  }}
                >
                  <FilterMenu>
                    <SectionLabel>Select a Project Category</SectionLabel>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectCat("ALL");
                      }}
                    >
                      <MenuLabelNoIcon>Show All</MenuLabelNoIcon>
                    </MenuLine>
                    {current_profile.categories.map((cat, index) => {
                      return (
                        <MenuLine
                          key={"cat2" + index}
                          onClick={() => {
                            this.handleprojectCat(cat.id);
                          }}
                        >
                          <MenuLabelNoIcon>{cat.name}</MenuLabelNoIcon>
                        </MenuLine>
                      );
                    })}
                  </FilterMenu>
                </MenuPanelNarrow>
              </StyledElementPopup>
            </div>
          ) : (
            ""
          )}
          <ContentSection>
            <StyledHeader src={banner}></StyledHeader>
            <BottomPanel isMobile={isMobile}>
              <LeftInfoBar isMobile={isMobile}>
                <InfoTitleContainer>
                  <InfoTitleAvatar src={imagesource}> </InfoTitleAvatar>


                  <InfoTitleRight>
                    <InfoTitleName>{current_profile.fullname ? (current_profile.fullname) : (current_profile.username)}</InfoTitleName>
                    {current_profile.fullname ? (<InfoTitleUserName>{current_profile.username}</InfoTitleUserName>) : ("")}

                  </InfoTitleRight>
                  <InfoContainer isMobile={isMobile}>
                    <InfoAboutText isMobile={isMobile}>

                      {current_profile.tag_line}
                    </InfoAboutText>
                  </InfoContainer>
                  {isMobile ? (<MobileButtons>
                    {isOwner ? (
                      <div>
                        {<Button
                          style={{ width: "136px" }}
                          onClick={() => {
                            this.handleNewProject();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                        >
                          NEW PROJECT
                        </Button>}
                      </div>
                    ) : (
                      ""
                    )}
                    {!isOwner && current_profile.viewer_is_subscribed ? (
                      <div>
                        <Button
                          style={{ width: "136px" }}
                          onClick={() => {
                            this.handleNewUnFollow();
                          }}
                          disableElevation
                          variant="outlined"
                          color="primary"
                        >
                          SUBSCRIBED
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                    {!isOwner && !current_profile.viewer_is_subscribed ? (
                      <div>
                        <Button
                          style={{ width: "136px" }}
                          onClick={() => {
                            this.handleNewFollow();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                        >
                          SUBSCRIBE
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}


                  </MobileButtons>) : ("")}
                </InfoTitleContainer>
                {this.state.collapsed ? (
                  <GroupNavBarContainer>
                    <InfoExpandContainer>
                      <InfoExpand>
                        <IconButton
                          title="Settings"
                          size="small"
                          onClick={() => {
                            this.handleExpandInfo();
                          }}
                        >
                          <ExpandMoreOutlinedIcon fontSize="20px" />{" "}
                        </IconButton>
                      </InfoExpand>
                    </InfoExpandContainer>
                  </GroupNavBarContainer>
                ) : (
                  ""
                )}
                <ShowHide hide={this.state.collapsed}>

                  {current_profile.sponsor ? (
                    <InfoContainer>
                      <InfoHeader>
                        Sponsor
                      </InfoHeader>
                      <InfoAboutText>
                        <SponsorSection>
                          <SponsorLeft src={current_profile.sponsor.logo ? imgUrl(current_profile.sponsor.logo, "original") : ""}></SponsorLeft>
                          <SponsorRight>
                            <SponsorName>{current_profile.sponsor.name}</SponsorName>
                            {/* <SponsorPage><Link style={{ color: "#008EFF", textDecoration: "none" }} to={"/organization/" + current_profile.sponsor.id + "/"} target="_blank"><OpenInNewIcon fontSize="inherit" /></Link></SponsorPage> */}
                          </SponsorRight>
                        </SponsorSection>
                      </InfoAboutText>
                    </InfoContainer>
                  ) : ""}

                  <InfoContainer>
                    <InfoHeader
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      Projects
                    </InfoHeader>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("IN");
                      }}
                    >
                      <InfoCommandNumber>
                        {current_profile.total_inprogress}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>
                        In Progress
                      </InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("TO");
                      }}
                    >
                      <InfoCommandNumber>
                        {" "}
                        {current_profile.total_planned}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>Planned</InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("CO");
                      }}
                    >
                      <InfoCommandNumber>
                        {current_profile.total_completed}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>
                        Completed
                      </InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      <InfoCommandTextProject style={{ marginLeft: "0px" }}>
                        Show All
                      </InfoCommandTextProject>
                    </InfoCommandProject>
                  </InfoContainer>
                  {current_profile.groups.length ? (
                    <InfoContainer>
                      <InfoHeader>

                        Public Groups

                      </InfoHeader>
                      {current_profile.groups.map((group, index) => {
                        return (
                          <Link
                            key={"groups" + index}
                            style={{ textDecoration: "none" }}
                            to={"/group/" + group.id + "/"}
                          >
                            <GroupItem>
                              {group.name}
                            </GroupItem>
                          </Link>
                        );
                      })}
                    </InfoContainer>
                  ) : (
                    ""
                  )}

                  <InfoContainer>
                    <InfoHeader
                      onClick={() => {
                        this.handleprojectCat("ALL");
                      }}
                    >
                      Categories
                    </InfoHeader>
                    <CatItemContainer>
                      {current_profile.categories.map((cat, index) => {
                        return (
                          <CatItem
                            key={"cat" + index}
                            onClick={() => {
                              this.handleprojectCat(cat.id);
                            }}
                          >
                            {cat.name}
                          </CatItem>
                        );
                      })}
                      <CatItem
                        onClick={() => {
                          this.handleprojectCat("ALL");
                        }}
                      >
                        All Categories
                      </CatItem>
                    </CatItemContainer>
                  </InfoContainer>
                  {current_profile && current_profile.subscribers && current_profile.subscribers.length && !hidesubs ? (
                    <InfoContainer>
                      <InfoHeader onClick={() => {
                        this.handleShowSubscribers();
                      }}
                      >

                        Subscribers ({current_profile.total_subscribers})

                      </InfoHeader>
                      {current_profile.subscribers.map((sub, index) => {
                        return (
                          <PersonThumb key={"sub" + index} {...sub} size="s" />
                        );
                      })}

                    </InfoContainer>

                  ) : (
                    ""
                  )}
                  {!hidesubs ? (
                    <InfoContainer>
                      <InfoHeader
                        onClick={() => {
                          this.handleShowSubscriptions("projects");
                        }}
                      >
                        Subscriptions
                      </InfoHeader>
                      <InfoCommandProject
                        onClick={() => {
                          this.handleShowSubscriptions("projects");
                        }}
                      >
                        <InfoCommandNumber>
                          {current_profile.total_subscriptions_projects}
                        </InfoCommandNumber>
                        <InfoCommandTextProject>
                          {current_profile.total_subscriptions_projects === 1 ? ("Project") : ("Projects")}
                        </InfoCommandTextProject>
                      </InfoCommandProject>
                      <InfoCommandProject
                        onClick={() => {
                          this.handleShowSubscriptions("projects");
                        }}
                      >
                        <InfoCommandNumber>
                          {" "}
                          {current_profile.total_subscriptions_users}
                        </InfoCommandNumber>
                        <InfoCommandTextProject> {current_profile.total_subscriptions_users === 1 ? ("Person") : ("People")}</InfoCommandTextProject>
                      </InfoCommandProject>
                      <InfoCommandProject
                        onClick={() => {
                          this.handleShowSubscriptions("projects");
                        }}
                      >
                        <InfoCommandNumber>
                          {current_profile.total_subscriptions_groups}
                        </InfoCommandNumber>
                        <InfoCommandTextProject>
                          {current_profile.total_subscriptions_groups === 1 ? ("Group") : ("Groups")}
                        </InfoCommandTextProject>
                      </InfoCommandProject>

                    </InfoContainer>
                  ) : null}
                  {/* {group.viewer_is_admin ? (<InfoContainer>
             <InfoCommand
               onClick={() => {
                 this.handleShowMenu("people");
               }}>
               <PeopleIcon fontSize="inherit" />{" "}
               <InfoCommandText>Member Settings</InfoCommandText>
             </InfoCommand>
             <InfoCommand
               onClick={() => {
                 this.handleShowMenu("settings");
               }}>
               <SettingsIcon fontSize="inherit" />{" "}
               <InfoCommandText>Group Settings</InfoCommandText>
             </InfoCommand>
           </InfoContainer>):("")} */}

                  <InfoContainer>
                    <InfoEst>Member since {established}</InfoEst>
                  </InfoContainer>
                  {isMobile ? (
                    <InfoCollapseContainer>
                      <InfoExpand>
                        <IconButton
                          title="Settings"
                          size="small"
                          onClick={() => {
                            this.handleCollapseInfo();
                          }}
                        >
                          <ExpandLessOutlinedIcon fontSize="20px" />{" "}
                        </IconButton>
                      </InfoExpand>
                    </InfoCollapseContainer>
                  ) : (
                    ""
                  )}
                </ShowHide>
              </LeftInfoBar>
              <RightSection isMobile={isMobile}>
                {this.state.selectedProject ? (
                  <SectionContainer>
                    <PageTitle isMobile={isMobile}>
                      <BaseTitle isMobile={isMobile}>View:</BaseTitle>
                      <Dash isMobile={isMobile}></Dash>
                      <SubTitle
                        isMobile={isMobile}
                        onClick={(e) => {
                          this.handleshowStatePopup();
                        }}
                      >
                        {subtitle}
                      </SubTitle>
                      <Dash isMobile={isMobile}>|</Dash>
                      <SubTitle
                        isMobile={isMobile}
                        onClick={(e) => {
                          this.handleshowCatPopup();
                        }}
                      >
                        {subtitle2}
                      </SubTitle>
                      {!isMobile ? (<RightButtons>
                        {/* <Button
                    style={{ width: "136px" }}
                    onClick={() => {this.handleNewProject()}}
                    disableElevation
                    variant="contained"
                    color="primary">
                    NEW PROJECT2
                  </Button> */}

                        {isOwner ? (
                          <RightButtons>
                            {<Button
                              style={{ width: "136px" }}
                              onClick={() => {
                                this.handleNewProject();
                              }}
                              disableElevation
                              variant="contained"
                              // color="primary"
                              sx={{ bgcolor: theme.palette.primary.main }}
                            // sx={{fontSize: theme.components.MuiButton.variants.style.bottombutton}}
                            >
                              NEW PROJECT
                            </Button>}
                          </RightButtons>
                        ) : (
                          ""
                        )}
                        {!isOwner && current_profile.viewer_is_subscribed ? (
                          <RightButtons>
                            <Button
                              style={{ width: "136px" }}
                              onClick={() => {
                                this.handleNewUnFollow();
                              }}
                              disableElevation
                              variant="outlined"
                              color="primary"
                            >
                              SUBSCRIBED
                            </Button>
                          </RightButtons>
                        ) : (
                          ""
                        )}
                        {!isOwner && !current_profile.viewer_is_subscribed ? (
                          <RightButtons>
                            <Button
                              style={{ width: "136px" }}
                              onClick={() => {
                                this.handleNewFollow();
                              }}
                              disableElevation
                              variant="contained"
                              color="primary"
                            >
                              SUBSCRIBE
                            </Button>
                          </RightButtons>
                        ) : (
                          ""
                        )}

                      </RightButtons>) : ("")}
                    </PageTitle>

                    <Projectcontainer isMobile={isMobile}>
                      {NoContent ? (
                        <EmptyProject>
                          <EmptyContainer>
                            <EmptyTitle>{emptyText}</EmptyTitle>
                            <EmptyInstruction>
                              {emptyText2}
                              <br />
                              {emptyText3}
                            </EmptyInstruction>
                            {/* <ResetCommand onClick={(e) => {
                      this.handleResetFilters();
                    }}>Reset All Filters</ResetCommand> */}
                          </EmptyContainer>

                        </EmptyProject>
                      ) : (
                        ""
                      )}

                      {projects.allIDS.map((project, index) => {
                        const data = projects.byID[project];
                        return (
                          <ProjectSpacer isMobile={isMobile} key={"p" + index}>
                            <ProjectThumb {...data}
                              group={groupid}
                              count={projects.byID[project].total_elements}
                              link={true}
                              myproject={true}
                              cat={projects.byID[project].category}
                            />
                          </ProjectSpacer>
                        );
                      })}
                      {projects.next ? (
                        <MoreButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.loadMoreProjects(projects.next);
                            }}
                          >
                            Load More Projects...
                          </Button>
                        </MoreButton>
                      ) : (
                        ""
                      )}
                    </Projectcontainer>
                  </SectionContainer>
                ) : (
                  ""
                )}
                {this.state.selectedMember ? (
                  <SectionContainer>
                    <PageTitle isMobile={isMobile}>Groups</PageTitle>
                    <Projectcontainer isMobile={isMobile}></Projectcontainer>
                  </SectionContainer>
                ) : (
                  ""
                )}
                {this.state.selectedAbout ? (
                  <SectionContainer>
                    <PageTitle isMobile={isMobile}>About</PageTitle>
                    <Aboutcontainer>{group.description}</Aboutcontainer>
                  </SectionContainer>
                ) : (
                  ""
                )}
              </RightSection>
            </BottomPanel>
          </ContentSection>
        </Page >
      );
    }
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  profile: state.profile,
  auth: state.auth,
  current_profile: state.profile.current_profile,
  current_profile_loading: state.profile.loading,
  projects: state.profile.projects,
  isMobile: state.dimensions.window_dimensions.isMobile,
  reset: state.ui.resetProjectLink,
});
export default connect(mapStateToProps)(ProfilePage);
