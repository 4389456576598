import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { setMenu, selectElementType } from "../../actions/uiActions";
import CommentContainer from "../CommentContainer";
import CommentNew from "../CommentNew";
import Update from "./Update";

import Next from "./Next";
import Question from "./Question";
import Dimension from "./Dimension";
import { updatePosition } from "../../actions/projectActions";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${(props) => props.vertical ? "0px" : "10px"};
`;
const TopContainer = styled.div`
  width: ${(props) => (props.containerwidth ? props.containerwidth : "300")}px;
  height: ${(props) => props.vertical ? "auto" : (props.cardheight ? props.cardheight + "px" : "300px")};
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;
const LineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  // background-color: #f2f2f2;
`;
const LineContainerLeft = styled.div`
  width: calc(
    50% + 1px
  ); // these added pixels are to try to align the dotted lines, but it is not working well when then line is more than 1px thick
  height: 100%;
  margin-left: -9px;
  // border-right: 1px solid #e3e3e3;
`;
const LineContainerRight = styled.div`
  width: 50%;
  height: 100%;
`;
const LineContainerTop = styled.div`
  width: 100%;
  height: 10px;

  border-bottom: 1px solid #008eff;
`;
const BottomContainer = styled.div`
  width: 100%;
  // height: 300px;
`;
const ElementCard = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  background-color: #fff;
  border: ${(props) =>
    props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
    box-shadow: 0px 0px 16px rgb(0 0 0 / 30%);

  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const InnerContainer = styled.div`
  position: ${(props) =>
    props.vertical ? "relative" : "absolute"};;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;
const ElementCardLine = styled.div`
  width: calc(50% + 2px);
  height: 64px;

  margin-bottom: -10px;
`;
const ElementCardLineBottom = styled.div`
  margin-top: -45px;
  width: 50%;
  height: 60px;
`;
const DateBadge = styled.div`
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  padding: 8px;
  left: 5px;
  top: -5px;
  min-width: 60px;
  text-align: center;
  border-radius: 5px;

  background-color: #2196f3;
  color: #fff;
`;
const DateBadgeFirst = styled.div`
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  padding: 8px;
  right: 5px;
  top: -5px;
  min-width: 60px;
  text-align: center;
  border-radius: 5px;

  background-color: #2196f3;
`;
const LinkBadgeLeft = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  max-width: 35%;
  position: absolute;
  margin-left: 5px;
  margin-bottom: 37px;
  left: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;
const LinkBadge = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  max-width: 35%;
  position: absolute;
  margin-right: 8px;
  margin-bottom: 37px;
  right: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ElementBkg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #dadada;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const ButtonsContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const NewButton = styled.div`
  border-radius: 16px;
  text-align: center;
  padding: 8px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  //   min-height: 20px;
  background-color: #008EFF;
  margin-top: 10px;
  font-size: 14px;
  color: #fff
`;
const PlusContainer = styled.div`
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
`;
const ElementCardLineVertical = styled.div`
  width: calc(50% + 2px);
  height: 32px;
  border-right: 1px solid #008EFF;
 
`;
class GhostElement extends Component {
  constructor() {
    super();
    this.state = {
      showEditIcon: false,
    };
  }
  newElementSelection = () => {
    this.props.dispatch(setMenu(true, 1));
    // // this.setState({ showElementTypes: true });
    // // this.props.location = "/new/"+this.state.selectedprojectid
    // this.props.dispatch(selectElementType(this.props.element_types.allIDS[0]));
    // // this.props.dispatch(setMenu(false));

    // this.props.history.push("/new/" + this.props.projectid);

    // this.props.history.push("/new/" + this.state.selectedprojectid);
  };

  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  setEditIcon = (newValue) => {
    this.setState({ showEditIcon: newValue });
  };
  handleEditElement = () => {};
  render() {
    const {
      zoomlevel,
      element_types,
      isOwner,
      pos,
      id,
      element,
      card_height,
      dimensions,
      project_url,
      vertical,
      group_id,
    } = this.props;
    // console.log("isOwner" + isOwner)
    let minsize;
    if (dimensions.window_dimensions.isMobile) {
      minsize = 70;
    } else {
      minsize = 120;
    }

    const cardmargin = 32;
    let containerwidth;

    if (zoomlevel > 50) {
      containerwidth = Math.round(card_height - 25);
    } else {
      containerwidth = Math.round(
        minsize + ((card_height - minsize - 25) * zoomlevel) / 50
      );
    }

    if (containerwidth < minsize) {
      // containerwidth = minsize/2
    }
    let cardwidth = containerwidth - cardmargin;

    let showButton = true;
    if (cardwidth < 160) {
      showButton = false;
    }
    if (vertical){
      containerwidth = 160
      cardwidth = 150
    }
    return (
      <ElementContainer  vertical={vertical}>
        <div>
          <TopContainer
          vertical={vertical}
            cardheight={card_height}
            containerwidth={containerwidth}>
            <InnerContainer vertical={vertical}>
              <ElementCard
                cardwidth={cardwidth}
                onClick={() => {
                  this.newElementSelection();
                }}>
                <ElementBkg>
                  <ButtonsContainer>
                    <PlusContainer title="Create New Element">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </PlusContainer>
                    {showButton ? (
                      <NewButton>NEW ELEMENT</NewButton>
                    ) : (
                      ""
                    )}
                  </ButtonsContainer>
                </ElementBkg>
              </ElementCard>

              {vertical ? ("") :( <ElementCardLine></ElementCardLine>)}
            </InnerContainer>
          </TopContainer>
        </div>
        {vertical ? ( <ElementCardLineVertical></ElementCardLineVertical>):( <div> <LineContainer></LineContainer>
        <BottomContainer>
          <ElementCardLineBottom></ElementCardLineBottom>

          {/* <CommentContainer
            has_comments={element.has_comments}
            element_url={project_url + "/element/" + id}
            commentcount={commentcount}
            zerocomments={zerocomments}
            group_id={group_id}
            isOwner={isOwner}
            id={id}
            placement="timeline"
          ></CommentContainer> */}
        </BottomContainer></div>)}
       
      
      </ElementContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  dimensions: state.dimensions,
  zoomlevel: state.project.zoom.level,
  card_height: state.project.zoom.card_height,

  element_types: state.project.current_project.element_types,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(GhostElement));
