import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getGroups,
  createGroup,
  loadMoreGroups,
} from "../actions/groupActions";
import GroupThumb from "./thumbs/GroupThumb";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { setMenu } from "../actions/uiActions";
import MenuIcon from '@mui/icons-material/Menu';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import HelpIcon from '@mui/icons-material/Help';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import { Typography } from "@mui/material";
import theme from "../config/theme";
import { site_name } from "../config";
import setTitle from "../utils/setTitle";
const Page = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #f2f2f2;
  box-sizing: border-box;
  padding-top: 24px;
  padding-bottom: 48px;
  max-height: calc(100vh - 50px);
  box-sizing: border-box;
`
const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-top: ${(props) => (props.isMobile ? "10px" : "10px")};
  flex-wrap: wrap;
  width: ${(props) => (props.isMobile ? "332px" : "auto")};
  padding-bottom: 50px;
  justify-content: center;
`;
// const ProjectToolbar = styled.div`
//   padding-left: 5px;
//   height: 50px;
//   padding: 10px;
//   display: flex;
//   flex-direction: row;
//   padding-top: 60px;
// `;
// const RightButtons = styled.div`
//   margin-left: auto;
//   margin-right: 18px; ;
// `;
const ProjectSpacer = styled.div`
  margin: 10px;
`;
const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin-right: 32px;
  margin-left:32px;
  // float: right;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  // justify-content: end;
  // font-size: 46px;
`;

const MenuContainer = styled.div`
// margin-left: 32px;
// float: right;
// flex-direction: row;
// justify-content: end;
padding-top:8px;
// background-color: #ff00ff;
// font-size: 46px;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: center;
  padding: 20px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: ${(props) =>
    props.isMobile ? "none" : "url('/lamp.png')"};
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // margin-top: 64px;
  width: 100%;
  height: 100%;
  color: "rgba(0, 0, 0, 0.8)";
`;
const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 12px;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Dialog = styled.div`
  background-color: #fff;
  font-family: Roboto, sans-serif;
  padding: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  width: 150px;

`
const InfoLine = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
`;

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details_open: false,
    }
  }
  componentDidMount() {
    this.props.dispatch(getGroups("ALL", "CO", this.props.archived));

    document.body.style.backgroundColor = "#F6F6F6";
  }
  onPageScroll(e, loading, next) {
    if (loading)
      return;
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 150) {
      if (next) {
        this.loadMoreGroups(next);
      }
    }
  }
  loadMoreGroups = (link) => {
    this.props.dispatch(loadMoreGroups(link));
  };
  handleShowNewGroup = () => {
    if (!this.props.auth.ticket) {
      this.props.dispatch(setMenu(true, 22));
    } else {
      this.props.dispatch(setMenu(true, 4));
    }

  }
  newGroup() {
    this.props.dispatch(createGroup(this.state.groupname));
  }
  showDetails = () => {
    this.setState({ details_open: true });
  }
  closeDetails = () => {
    this.setState({ details_open: false });
  }
  render() {
    const { groups, loading, isMobile, error } = this.props;
    if (!loading) {
      setTitle(`Groups | ${site_name}`)
    }
    let NoContent = false;
    if (groups.allIDS.length === 0 && !loading) {
      NoContent = true;
    }
    if (error) {
      return (
        <ErrorContainer>
          <WarningIcon style={{ fontSize: "72px" }} />
          <ErrorMessage>
            <Typography variant="h4">Something went wrong</Typography>
            <Typography variant="subtitle1">We couldn't load your groups. Please try again.</Typography>
          </ErrorMessage>
          <Button variant="contained" disableElevation onClick={e => { window.location.reload() }}>Retry</Button>
        </ErrorContainer>
      )
    }
    return (
      <Page onScroll={e => this.onPageScroll(e, loading, groups.next)}>
        <ButtonContainer>
          <MenuContainer><MenuIcon
            fontSize="large"
            sx={{ color: theme.palette.charcoal.main, cursor: "pointer" }}
            onClick={() => this.showDetails()}
          >
          </MenuIcon></MenuContainer>
          <Button
            style={{ width: "136px", marginTop: "10px", height: "32px", paddingTop: "7px", }}
            onClick={() => {
              this.handleShowNewGroup();
            }}
            disableElevation
            variant="contained"
            color="primary"
          >
            NEW GROUP
          </Button>

        </ButtonContainer>
        <SectionContainer>
          <GroupContainer isMobile={isMobile}>
            {NoContent ? (
              <EmptyProject>
                <EmptyContainer>
                  <EmptyTitle>No Groups Yet!</EmptyTitle>
                  <EmptyInstruction>
                    When you create, or get invited to a group, they will show here.
                  </EmptyInstruction>
                </EmptyContainer>
              </EmptyProject>
            ) : ""}
            {groups.allIDS.map((group, index) => (
              <ProjectSpacer key={index}>
                <GroupThumb
                  created_at={groups.byID[group].created_at}
                  total_members={groups.byID[group].total_members}
                  total_projects={
                    groups.byID[group].total_inprogress +
                    groups.byID[group].total_planned +
                    groups.byID[group].total_completed
                  }
                  creator={groups.byID[group].creator}
                  src={
                    groups.byID[group].image
                      ? groups.byID[group].image
                      : ""
                  }
                  description={groups.byID[group].description}
                  name={groups.byID[group].name}
                  id={group}
                  link={groups.byID[group].viewer_status === "AC"}
                  viewer_status={groups.byID[group].viewer_status}
                />
              </ProjectSpacer>
            ))}
          </GroupContainer>
        </SectionContainer>
        <Bottom>
          {loading ? (
            <CircularProgress />
          ) : ""}
          {groups.next && !loading ? (
            <div>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                sx={{ marginTop: "16px" }}
                onClick={() => {
                  this.loadMoreGroups(groups.next);
                }}
              >
                Load More Groups...
              </Button>
            </div>
          ) : ""}
        </Bottom>
        {this.state.details_open ? (
          <Overlay onClick={e => this.closeDetails()}>
            <Dialog>
              <h1 style={{ marginTop: 0 }}>Groups</h1>
              <InfoLine><b>{Object.values(groups.byID).filter(g => g.viewer_status === "AC").length}</b> Active</InfoLine>
              <InfoLine><b>{Object.values(groups.byID).filter(g => g.viewer_status === "IN").length}</b> Invites</InfoLine>
              <InfoLine><b>{Object.values(groups.byID).filter(g => g.viewer_status === "RE").length}</b> Requests</InfoLine>
              {this.props.archived ? (
                <Button variant="contained" onClick={e => window.location.href = "/groups"}>View active groups</Button>
              ) : (
                <Button variant="contained" onClick={e => window.location.href = "/groups/archive"}>View archived groups</Button>
              )}
            </Dialog>
          </Overlay>
        ) : ""}
      </Page>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.groups.groups_loading,
  groups: state.groups.groups,
  isMobile: state.dimensions.window_dimensions.isMobile,
  error: state.groups.error,
  auth: state.auth,
});
export default connect(mapStateToProps)(Groups);
