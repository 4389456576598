import React, { Component } from "react";
import styled from "@emotion/styled";

class PhiGrid extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
 

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let x, y, w, h;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
     
      const expand = obj.options.expand;
      let gridwidth, gridheight;

      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }
      if (expand) {
        x = 0;
        y = 0;
        w = 100;
        h = 100 / used_ratio;
        gridwidth = 100;
        gridheight = 100 / used_ratio;
      } else {
        gridwidth = w;
        gridheight = h;
      }

     const x1 = x + 1*gridwidth/2.618
     const x2 = x + 1.618*gridwidth/2.618
     const y1 = y + 1*gridheight/2.618
     const y2 = y + 1.618*gridheight/2.618
      const g_ratio = 1.618;
    
      return (
        <g key={obj.id}>
          <g
          onMouseDown={this.handleMouseDownObj.bind(this, obj)}
          >
         
        
          <line
          
                x1={x1}
                y1={y}
                x2={x1}
                y2={y + h}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
             <line
                x1={x2}
                y1={y}
                x2={x2}
                y2={y + h}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
             <line
                x1={x}
                y1={y1}
                x2={x + w}
                y2={y1}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
             <line
                x1={x}
                y1={y2}
                x2={x + w}
                y2={y2}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
          </g>
          {obj.id === objSelect ? (
            <g>
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
               
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
              <rect
               
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
             >
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default PhiGrid;
