import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveGroup,
  uploadSaveGroup,
  deleteGroup,
  getGroupParticipants,
  acceptRequest,
  rejectRequest,
  loadMoreGroupProjects,
  removeProjectsFromGroup
} from "../actions/groupActions";
import {
  addMember,
  removeMember,
  changeRole,
  sendInvite,
  getConnections,
  removeInvite,
  getPendingInvites,
  getAllParticipants,
  loadMoreParticipants,
  toggleJoinLink
} from "../actions/groupActions";
import mimetype from "../config/mimetype";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import GroupUserTable from "./GroupUserTable";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setGroupSettings } from "../actions/uiActions";
import GroupThumb from "./thumbs/GroupThumb";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import { Button, FormLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import InputLabel from "@mui/material/InputLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from '@mui/icons-material/Check';
import { api_endpoint } from "../config";
import CloseIcon from "../custom-icons/CloseIcon";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import TitleIcon from "@mui/icons-material/Title";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import HdrWeakOutlinedIcon from "@mui/icons-material/HdrWeakOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityIcon from '@mui/icons-material/Visibility';
import imgUrl from "../utils/imgUrl";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ArchiveIcon from "@mui/icons-material/Archive";
import Menu, { MenuProps } from "@mui/material/Menu";
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  PanelThree,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton
} from "./styles/MenuComponents.js";

import { groupMemberStatus, groupMemberRole } from "../config/shorthand";

const OptionTitle = styled.div`
  // font-weight: bold;
  line-height: 1.5;
`;

const OptionSubtitle = styled.div`
  font-size: 0.75rem;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.54);
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "340px")};
    width: calc(100% - 18px);
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 8px;
`;
const SubContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "340px")};
    width: calc(100% - 18px);
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 8px;
`;
const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
  // margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "-17px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
`;
// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;
// const StyledSelect = styled.select`
//   width: 150px;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   margin-right: 20px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;


const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const InviteInstruction = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  // letter-spacing: 0.00938em;
`;
const SearchResults = styled.div`
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
`;
const MemberList = styled.div`
  // height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: auto;
`;
const SearchLineItem = styled.div`
width: 100%;  
height: 70px;
  // border-bottom: 1px solid #ccc;
  display: flex;
  margin-top: 0px;
`;
const PendingItem = styled.div`
  height: 50px;

  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  border-bottom: 1px solid #ccc;
  display: flex;
`;
const PendingItemEmail = styled.div`
  margin-top: 15px;
`;
const UserNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: ##000000de;
`;
const OtherNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
`;
const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 12px;
 
  margin-right: 15px;
`;
const ParticipantLine = styled.div`
  // width: 10%;
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
 
`;
const ParticipantLabel = styled.div`
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: ##000000de;
 
`;
const ParticipantContent = styled.div`
  display: flex;
  flex-direction: column;
`
const LeftColumn = styled.div`
  // width: 10%;
  display: flex;
  flex-direction: column;
`;
const CheckBoxContainer = styled.div`
border-top: 1px solid rgb(224, 224, 224);
border-bottom: 1px solid rgb(224, 224, 224);
 display: flex;
 flex-direction: column;
height: 300px;
 max-height: 300px;
 overflow-y: auto;
 width: 350px;
`;
const Checkboxes = styled.div`
 padding: 32px;
 display: flex;
 flex-direction: column;

`;
const CheckLabel = styled.div`
  display: flex;
`;

const CheckName = styled.div`
margin-left: 8px;
margin-top: 3px;
`;

const CheckImage = styled.div`
background-image: url(${(props) => props.src}), url('/default_project.jpg');
height: 30px;
width: 30px;
background-position: center;
background-size: cover;
`
const NameColumn = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumn = styled.div`
  width:100%
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnNarrow = styled.div`
  width:100px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnWide = styled.div`
  min-width:150px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const RightColumn = styled.div`
  // width: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
    margin-bottom: auto;
  text-align: left;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "350px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnSingle = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ParticipantControlLine = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? "10px" : "20px")};
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  display: flex;
  
`;
const ParticipantCol1 = styled.div`
  width: 60px;

  
`;
const ParticipantCol2 = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  
`;
const EmailError = styled.div`
font-size: 14px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 400;
line-height: 1.5;
color: #dd4d25;
margin-top: 20px;
`;
const ControlLineBanner = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  display: flex;
`;
const ControlContentMembers = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const BannerPreview = styled.div`
  display: block;
  width: 100%;
  height: 192px;
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 32px;
`
const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-top: 5px;
  margin-left: 15px;
`;

// const StyledButtonNew = styled.button`
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 150px;
//   background-color: #2cc0f4;
//   color: white;
//   height: 32px;
//   border-radius: 2px;

//   padding-right: 30px;
//   padding-left: 30px;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const AuthorImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
`;
const StyledElementPopup = styled.div`
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  height: calc(100% - 14px);
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledTable = styled.table`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
 
`;
const StyledTD = styled.td`
  text-align: center; 
  min-width: 50px;
 
`;
const ShareLinkBox = styled.div`
 border: 1px solid #ccc;
 font-size: 0.75rem;
 margin-left: 30px;
 padding: 5px;
 word-break: break-all;
`;
const ShareLinkButtons = styled.div`

 margin-left: 30px;
 
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  // min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: min(400px, 95vw);
  height: auto;
  z-index: 2000;
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
`;
const StyledButtonRight2 = styled.div`
    margin-top: 20px;
    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
`;
class GroupEdit extends Component {
  constructor(props) {
    super(props);
    this.savePage = this.saveGroup.bind(this);
    this.state = {
      id: "",
      description: "",
      group_type: "",
      archived: false,
      public: false,
      allow_join_requests: false,
      allow_join_auto: false,
      image: "",
      form_new: true,
      imageRaw: "",
      imagePreview: false,
      showImageProgress: false,
      members: [],
      admins: [],
      invited: [],
      checked: [],
      manageusers: true,
      allmembers: [],
      inviteusername: "",
      join_link_enabled: false,
      join_link: "",
      email: "",
      search: "",
      showArchiveConfirmation: false,
      showDeleteConfirmation: false,
      showInviteUI: true,
      emails: [],
      lastquery: "1",
      defaultrole: "CO",
      role_info: false,
      org: "private",
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      panelW: 288,
      slide: false,
      x1: 0,
      x2: 0,
      pastState: "",
      custom_image_setting: "false",
      directUI: false,
      directShare: false,
      current_role: "",
      verbose_status: "",
      remove_participant: false,
      request_status: null,
      memberFilter: "AC",
      showMoreOptions: false,
      showSaveConfirmation: false,
      hasChanges: false,
      selected_participant: {},
      projects: { allIDS: [], byID: {} }
    };
    this.moreOptionsButton = React.createRef();
  }
  onSlide = (state, bool) => {
    this.setState({ slide: true });
  };

  onChangeParticipant = (result) => {
    console.log(result)
    this.setState({ panelthree: result.id });
    this.setState({ current_role: result.role });
    const selected = {
      username: result.user ? result.user.fullname || result.user.username : result.invite_email,
      fullname: result.user && result.user.fullname,
      src: result.user && result.user.avatar && api_endpoint + "/media/" + result.user.avatar.id + "/file?size=256",
      id: result.id,
      part_id: result.id
    }
    this.setState({ selected_participant: selected });
    let verbose_status;
    switch (result.status) {
      case "IN":
        verbose_status = "Invited";
        break;
      case "AC":
        verbose_status = "Active";
        break;
      case "RE":
        verbose_status = "Requested";
        break;
      default:
        verbose_status = "undefined";
    }
    this.setState({ verbose_status: verbose_status });
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x1: -(this.props.dimensions.window_dimensions.w - 64) });
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) * 2 });
    } else {
      this.setState({ x1: -this.state.panelW });
      this.setState({ x2: -(this.state.panelW) * 2 });
    }
  }
  onChangeSectionNumber = (num, tab) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) });
    } else {
      this.setState({ x2: -this.state.panelW });
    }

    switch (num) {
      case 1:
        this.setState({ pastState: [this.state.name, this.state.description] });

        break;
      case 2:
        this.setState({ pastState: [this.state.public, this.state.allow_join_requests, this.state.join_link_enabled, this.state.allow_join_auto] });
        break;
      case 3:
        this.setState({ pastState: [this.state.imagePreview, this.state.imageRaw] });
        break;
      case 4:

        this.props.dispatch(getAllParticipants(this.props.group.id, tab));

      default:
      // code block
    }
  };
  onSlidebackParticipant = (state, bool) => {

    this.setState({ x1: -this.state.panelW * 2 });
    this.setState({ x2: -this.state.panelW });

    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  onSlideback = (state, bool) => {
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    if (this.props.new_dialog) {
      this.setState({ directUI: false });
    }
    if (this.props.sharing_only) {
      this.setState({ directShare: false });
    }
    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  handleChangeToggle = (value, part_id, type) => {
    //console.log(value);
    //console.log(part_id);

    this.props.dispatch(changeRole(value, part_id, type));
  };
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 1:
        this.setState({ name: this.state.pastState[0] });
        this.setState({ description: this.state.pastState[1] });
        break;
      case 2:
        this.setState({ public: this.state.pastState[0] });
        this.setState({ allow_join_requests: this.state.pastState[1] });
        this.setState({ join_link_enabled: this.state.pastState[2] });
        this.setState({ allow_join_auto: this.state.pastState[3] });
        break;
      case 3:
        this.setState({ imagePreview: this.state.pastState[0] });
        this.setState({ imageRaw: this.state.pastState[1] });
        break;
      default:
      // code block
    }
  };
  loadMore = (link, type) => {
    this.props.dispatch(loadMoreParticipants(link, type));
  };
  SelectProject(id, index) {
    console.log("select")
    let temp_projects = this.state.projects
    if (temp_projects.byID[id].checked) {
      temp_projects.byID[id].checked = false
    } else {
      temp_projects.byID[id].checked = true
    }


    this.setState({ projects: temp_projects });
  }
  loadMoreProjects(link) {

    this.props.dispatch(loadMoreGroupProjects(link));
  }
  handleClosePopup() {
    document.body.style.overflow = "auto";
    this.props.dispatch(setGroupSettings(false));
  }
  handleCloseDialogCheck() {
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      document.body.style.overflow = "auto";
      this.props.dispatch(setGroupSettings(false));
    }

  }
  handleChangeToggleOrg = (e) => {
    this.setState({ org: e.target.value });
  };

  onChangeJoin = (e) => {
    // this.setState({ project: { ...this.state.project, public: e.target.value } });
    this.setState({ hasChanges: true });
    this.setState({ allow_join_requests: e.target.checked });
  };
  onChangeJoinAuto = (e) => {
    // this.setState({ project: { ...this.state.project, public: e.target.value } });
    this.setState({ hasChanges: true });
    this.setState({ allow_join_auto: e.target.checked });
  };
  onChangeRemove = (e) => {
    // this.setState({ project: { ...this.state.project, public: e.target.value } });

    this.setState({ remove_participant: e.target.checked });
  };
  onChange = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: e.target.value });
  };
  isEmailValid = (email) => {
    console.log(email)
    // return(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    return (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(email))

  }

  handleChangeRole = (value) => {
    //console.log(value);
    //console.log(part_id);

    this.setState({ current_role: value });
  };
  handleChangeToggleGroupType = (event) => {
    this.setState({ hasChanges: true });
    //console.log(event.target.value);
    //console.log(event.target.id);
    this.setState({ group_type: event.target.value });
  };
  handleChangeDescription = (newValue) => {
    this.setState({ hasChanges: true });
    this.setState({ description: newValue });
  };
  handleChangeDate = (newValue) => {
    this.setState({ date: newValue });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleRoleInfo = () => {

    if (this.state.role_info) {
      this.setState({ role_info: false });
    } else {
      this.setState({ role_info: true });
    }

  };
  onEmailChange = (event, values) => {
    console.log("onEmailChange")
    event.preventDefault();
    event.stopPropagation();
    console.log(values)
    console.log(this.state.emails)
    this.setState(
      {
        emails: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.

      }
    );
  };
  handleKeyDown = (event, values) => {
    // parse if brackets
    switch (event.keyCode) {
      case 9: //tab
      case 188: //comma
      case 13: //enter
      case 32: { //space

        event.preventDefault();
        // event.stopPropagation();
        if (event.target.value.length > 0) {
          console.log("keydown")
          event.target.blur()
          event.target.focus()
          const result_string = event.target.value + ',none,none'
          const new_email = { 'id': 2, 'data': result_string }
          this.setState(
            {
              emails: [...this.state.emails, new_email],
            });
          console.log(this.state.emails)

        }

        break;
      }
      default:
    }
  };
  handlePaste = (event, values) => {
    event.preventDefault();
    event.stopPropagation();
    // look for emi colon
    // look for enters
    //look for tabs
    let email_data = (event.clipboardData.getData('Text')) // remove spaces
    console.log(email_data)
    email_data = email_data.replace(/(?:\r\n|\r|\n)/g, ','); // replace carriage returns, line breaks etc
    email_data = email_data.replace(/;/g, ','); // replace semicolon ;
    email_data = email_data.replace(/\s/g, '');

    const emails = email_data.split(",");
    console.log(emails)
    let newemails = [...this.state.emails]
    emails.forEach((email, i) => {
      const result_string = email + ',none,none'
      const new_email = { 'id': 2, 'data': result_string }
      newemails.push(new_email)
    });
    this.setState(
      {
        emails: newemails,
      });
  };
  handleBlur = (event, values) => {
    console.log("blur: " + event.target.value)
    if (event.target.value.length > 0) {
      console.log("blurring")

      const result_string = event.target.value + ',none,none'
      const new_email = { 'id': 2, 'data': result_string }
      this.setState(
        {
          emails: [...this.state.emails, new_email],
        });
      console.log(this.state.emails)

    }
    //  

  };
  sendInvites = () => {

    this.state.emails.forEach((email, i) => {
      if (i < 20) {
        if (this.isEmailValid(email.data.split(",")[0])) {
          const data = {
            invite_email: email.data.split(",")[0],
            group: this.props.group.id,
            organization: null,
            status: "IN",
            role: this.state.defaultrole
          };
          this.props.dispatch(sendInvite(data));
          this.setState({ emails: [] });
          document.body.style.overflow = "auto";

        }
      }

    });
    this.props.dispatch(setGroupSettings(false));

  };
  removeInvite = (id) => {
    this.props.dispatch(removeInvite(id));
  };
  handleShowDeleteConfirmation() {
    this.handleShowOptionsMenu(false)
    this.setState({ showDeleteConfirmation: true });
  }
  handleShowArchiveConfirmation() {
    this.handleShowOptionsMenu(false)
    this.setState({ showArchiveConfirmation: true });
  }
  onChangeSearch = (value) => {
    // this.setState({ [e.target.id]: e.target.value });
    // const data = e.target.value;
    console.log(this.props.search_results)
    if (!this.props.search_results.length) {
      console.log("empty")
      this.setState({ lastquery: value });
    }
    console.log(value)
    console.log(this.state.lastquery)
    if (value.slice(0, -1) === this.state.lastquery & !this.props.search_results.length) {
      this.setState({ lastquery: value });
    } else {
      this.props.dispatch(getConnections(value));
      console.log("search")
    }



  };
  deleteGroup = () => {
    this.props.dispatch(deleteGroup(this.state.id));
  };
  newGroup = () => {
    // this.props.dispatch(deleteProject(this.state.id));
  };
  saveGroup = (input) => {
    //console.log("save group", this.state.id);
    // const { allmembers } = this.props;
    // let memberids = [];
    // let adminids = [];
    // let invitedids = [];
    // this.state.members.forEach((member, i) => {
    //     memberids.push(member.id)
    // });

    // this.state.admins.forEach((member, i) => {
    //     adminids.push(member.id)
    // });

    // this.state.invited.forEach((member, i) => {
    //     invitedids.push(member.id)
    // });
    // allmembers.forEach((member) => {
    //   if (member.status === "admin") {
    //     adminids.push(member.id);
    //   }
    //   if (member.status === "member") {
    //     memberids.push(member.id);
    //   }
    //   if (member.status === "invited") {
    //     invitedids.push(member.id);
    //   }
    // });


    const data = {
      description: this.state.description,
      name: this.state.name,
      group_type: this.state.group_type,
      archived: this.state.archived
    };
    if (this.state.public === "true" || this.state.public === true) {
      data.public = true
    } else {
      data.public = false
    }
    if (this.state.allow_join_requests === true || this.state.allow_join_requests === "true") {
      data.allow_join_requests = true
    } else {
      data.allow_join_requests = false
    }
    if (this.state.allow_join_auto === true || this.state.allow_join_auto === "true") {
      data.allow_join_auto = true
    } else {
      data.allow_join_auto = false
    }
    if (this.state.join_link_enabled === true || this.state.join_link_enabled === "true") {
      data.join_link_enabled = true
    } else {
      data.join_link_enabled = false
    }
    if (this.state.org !== "private") {
      data.organization = this.state.org
    }
    if (input && input.archived) {
      data.archived = true;
    }
    if (this.state.imagePreview) {
      // first upload and then save
      this.props.dispatch(
        uploadSaveGroup(this.state.id, data, this.state.imageRaw)
      );
    } else {
      this.props.dispatch(saveGroup(this.state.id, data));
    }
    document.body.style.overflow = "auto";
    this.props.dispatch(setGroupSettings(false));
  };

  removeUser(id, part_id) {
    this.props.dispatch(removeMember(id, part_id, this.state.memberFilter));
    // this.props.dispatch(getAllParticipants(this.props.group.id, this.state.memberFilter));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  onChangeSharing = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ join_link_enabled: e.target.checked });
    if (e.target.checked && this.props.group.join_link === null) {
      this.props.dispatch(
        toggleJoinLink(
          this.state.id,

        )
      );
    }

  };
  changeSharingLink = (id) => {


    this.props.dispatch(
      toggleJoinLink(
        id,

      )
    );


  };
  copySharingLink = (e) => {
    var input = document.createElement("input");
    input.hidden = true;
    input.type = "text";
    input.value = `${window.location.origin}/groupinvite/${this.props.group.join_link}`;
    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
  };
  componentDidUpdate() {
    const { group } = this.props;
    if (this.state.join_link !== group.join_link) {

      this.setState({ join_link: group.join_link });

    }
    console.log(group.projects.allIDS.length, this.state.projects)
    if (group.projects.allIDS.length !== this.state.projects.allIDS.length) {
      console.log("update")
      this.SetInitialSelection(group.projects)
    }

  }
  componentDidMount() {
    const { dialogType, group, dimensions } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }

    let imagesource = "../../header.jpg";
    if (group.image) {
      imagesource = api_endpoint + "/media/" + group.image.id + "/file?size=256";


    }
    console.log(group.organization)
    if (dialogType === "people") {
      this.onChangeSectionNumber(4, "AC");
    }
    if (dialogType === "people_request") {
      this.onChangeSectionNumber(4, "RE");
      this.setState({ memberFilter: "RE" });
    }

    if (dialogType === "people" || dialogType === "settings" || dialogType === "people_request") {
      console.log(group)
      this.setState({ id: group.id });
      this.setState({ form_new: false });
      this.setState({ description: group.description });
      this.setState({ public: group.public });
      this.SetInitialSelection(group.projects)
      this.setState({ allow_join_requests: group.allow_join_requests });
      this.setState({ allow_join_auto: group.allow_join_auto });
      this.setState({ join_link_enabled: group.join_link_enabled });
      this.setState({ join_link: group.join_link });
      this.setState({ group_type: group.group_type });
      this.setState({ name: group.name });
      this.setState({ image: imagesource });
      this.setState({ members: group.members });
      this.setState({ admins: group.admins });
      this.setState({ invited: group.invited });
      this.setState({ allmembers: group.allmembers });
      if (group.organization) {
        this.setState({ org: group.organization });
      }

    } else {
      this.setState({ id: "" });
      this.setState({ form_new: false });
      this.setState({ description: "" });
      this.setState({ name: "" });
      this.setState({ public: false });
      this.setState({ allow_join_requests: false });
      this.setState({ allow_join_auto: false });
      this.setState({ image: imagesource });
      this.setState({ members: [] });
      this.setState({ admins: [] });
      this.setState({ invited: [] });
    }
  }
  SetInitialSelection(projects) {
    console.log("initial")
    let tempprojects = { ...projects, allIDS: [...projects.allIDS], byID: { ...projects.byID } }
    tempprojects.allIDS.map(
      (id, index) => {



        tempprojects.byID[id].checked = false
      })
    this.setState({ projects: tempprojects });

  }
  onRemoveProjects = (e) => {
    console.log("remove")
    let selectedprojects = [];
    let temp_projects = this.state.projects
    this.state.projects.allIDS.forEach((id, index) => {
      const project = this.state.projects.byID[id]
      if (project.checked) {
        temp_projects.allIDS.splice(index, 1)
        selectedprojects.push(id);
      }
    });
    this.setState({ projects: temp_projects });
    this.props.dispatch(removeProjectsFromGroup(this.props.group.id, selectedprojects));
    this.onSlideback();
  };
  onChangeRadioPublic = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ public: e.target.value });
  };
  onChangeRadioRequest = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ request_status: e.target.value });
  };
  handleImageChange = (e) => {
    if (e.target.files.length) {
      // //console.log(e.target.files[0])
      this.setState({ hasChanges: true });
      this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
      this.setState({ imageRaw: e.target.files[0] });
    }
  };
  handleToggle = (value) => () => {
    //console.log("toggle", value);
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };
  handleMemberFilterChange(e) {
    this.setState({ memberFilter: e.target.value });
    this.props.dispatch(getAllParticipants(this.props.group.id, e.target.value));
  }
  applyParticipant(part_id) {
    if (this.state.request_status === null) {
      //nothing
    } else {
      if (this.state.request_status === "RE") {
        //remove user
        this.props.dispatch(
          rejectRequest(part_id)
        );
      } else {
        this.props.dispatch(
          acceptRequest(part_id)
        );
      }
    }

    this.props.dispatch(
      changeRole(this.state.current_role, part_id, this.state.memberFilter)
    );
    // this.props.dispatch(getAllParticipants(this.props.group.id, this.state.memberFilter));
    this.onSlidebackParticipant();
  }
  handleCloseConfirmation() {
    this.setState({ showDeleteConfirmation: false, showArchiveConfirmation: false })
  }
  handleShowOptionsMenu = (show) => {
    this.setState({ showMoreOptions: show });
  };
  archiveGroup() {
    this.saveGroup({ archived: true });
  }
  imageContainer() {
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <CoverImage src={imagesrc}>
          <UploadButtons>
            <label htmlFor="upload-button">
              {" "}
              <UploadButton>Upload New</UploadButton>{" "}
            </label>
            <input
              type="file"
              id="upload-button"
              accept={mimetype.image}
              multiple={false}
              style={{ display: "none" }}
              onChange={this.handleImageChange}
            />
          </UploadButtons>
        </CoverImage>
      );
    }
  }

  filterParticipants = (participants) => {
    return participants.allIDS.map(id => participants.byID[id]).filter(m => m.status === this.state.memberFilter).sort((a, b) => b.username < a.username)
  }

  render() {
    const { dimensions, group, dialogType, participants } = this.props;
    let email_error = ""
    let max_invites = ""
    this.state.emails.forEach((email, i) => {
      if (!this.isEmailValid(email.data.split(",")[0])) {
        email_error = "Please enter a valid email."
      }
      if (i > 19) {
        max_invites = "The maximum number of invites in one batch is 20."
      }
    })
    let image;
    if (group.image) {
      image = imgUrl(group.image.id, 512)
    }
    const open = Boolean(this.state.anchorEl);
    if (this.state.showArchiveConfirmation) {
      return (
        <ElementContainer>
          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              <h3>Do you really want to archive this group?</h3>
              <br />
              Archiving this group will hide it from your groups page, and no new projects can be shared to it.
              People with the link to the group can still view it, but not add projects or comments.
              You will not be able to undo this.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleCloseConfirmation();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  No
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={e => {
                    this.archiveGroup();
                  }}
                  disableElevation
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "180px" }}>
                  Yes, Archive Group
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>
        </ElementContainer>
      )
    }
    if (this.state.showDeleteConfirmation) {
      return (
        <ElementContainer>
          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              <h3>Do you really want to delete this group?</h3>
              <br />
              Deleting this group will also unshare all on projects from this group, and
              cannot be undone.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleCloseConfirmation();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  No
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.deleteGroup();
                  }}
                  disableElevation
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "180px" }}>
                  Yes, Delete Group
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>
        </ElementContainer>
      )
    }
    if (this.state.showSaveConfirmation) {
      return (
        <ElementContainer>

          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              This group has changes that have not been saved.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleClosePopup();
                  }}
                  disableElevation
                  variant="outlined"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  Don't Save
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.savePage();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "180px" }}>
                  Save Changes
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>

        </ElementContainer>
      )
    }

    return (
      <div style={{ height: "100%", position: "relative" }}>
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={425} dialog_width={690}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Group Settings
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialogCheck();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                {this.props.new_dialog && this.state.directUI ? ("") : (<ColumnMiddle isMobile={dimensions.window_dimensions.isMobile}>
                  <PreviewContent
                    isMobile={dimensions.window_dimensions.isMobile}>

                    <ThumbContent isMobile={dimensions.window_dimensions.isMobile}>
                      <GroupThumb
                        isSmall={true}
                        created_at={group.created_at}
                        total_members={group.total_members}
                        total_projects={
                          group.total_inprogress +
                          group.total_planned +
                          group.total_completed
                        }
                        creator={group.creator}

                        src={group.image
                          ? group.image
                          : ""}
                        description={group.description}
                        name={group.name}
                        image_override={this.state.imagePreview}
                        id={group.id}
                        link={false}
                        viewer_status={group.viewer_status}
                      />
                    </ThumbContent>
                  </PreviewContent>

                </ColumnMiddle>)}

                <ColumnRight directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                  <CropPanel
                    w={this.state.panelW}
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                      <PanelOne w={this.state.panelW}>
                        {this.state.paneltwo === 0 ? (
                          <div>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(1);
                              }}>
                              <SectionIcon>
                                <InfoIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Group Details</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(2);
                              }}>
                              <SectionIcon>
                                <VisibilityIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Sharing</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(3);
                              }}>
                              <SectionIcon>
                                <InsertPhotoOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Group Image <SectionNumber>{this.state.description ? ("(✓)") : ("")}</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(5,);

                              }}>
                              <SectionIcon>
                                <NotInterestedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Remove Projects from Group</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(4, "AC");
                                this.setState({ memberFilter: "AC" })
                              }}>
                              <SectionIcon>
                                <GroupAddIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Manage Participants <SectionNumber>({group.total_members})</SectionNumber></SectionLabel>
                            </EditItem>
                            {group.allow_join_requests ? (
                              <EditItem
                                onClick={() => {
                                  this.onChangeSectionNumber(4, "RE");
                                  this.setState({ memberFilter: "RE" })
                                }}>
                                <SectionIcon>
                                  <GroupAddIcon fontSize="inherit" />
                                </SectionIcon>
                                <SectionLabel>Join Requests <SectionNumber>({group.total_requests})</SectionNumber></SectionLabel>
                              </EditItem>
                            ) : null}
                          </div>
                        ) : (
                          ""
                        )}
                      </PanelOne>
                      <PanelTwo w={this.state.panelW}>
                        {
                          {
                            0: (
                              <SectionContent
                                w={this.state.panelW}></SectionContent>
                            ),
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Group Details</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="name"
                                      label={null}
                                      variant="outlined"
                                      value={this.state.name}
                                      onChange={this.onChange}
                                      size="small"
                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />
                                  </FormControl>
                                  {this.state.nameerror ? (
                                    <StyledError>Name is required</StyledError>
                                  ) : (
                                    ""
                                  )}
                                </ControlLine>
                                <ControlLine
                                  isMobile={dimensions.window_dimensions.isMobile}
                                >
                                  <FormControl size="small" fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      InputLabelProps={{ style: { fontSize: 14 } }}
                                      id="description"
                                      rows={3}
                                      label="About"
                                      variant="outlined"
                                      multiline
                                      value={this.state.description}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                {this.props.new_dialog && this.state.directUI ? (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      More Options
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.newPage();
                                      }}>
                                      Create
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>) : (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>)}

                              </SectionContent>
                            ),
                            2: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Sharing</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  style={{ marginBottom: "15px" }}
                                  isMobile={dimensions.window_dimensions.isMobile}>
                                  <FormControl component="fieldset">

                                    <RadioGroup

                                      value={this.state.public}
                                      onChange={this.onChangeRadioPublic}>
                                      <FormControlLabel
                                        style={{ marginBottom: 8 }}
                                        value={false}
                                        control={<Radio
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary" />}
                                        label={(
                                          <div>
                                            <OptionTitle>Private Group</OptionTitle>
                                            <OptionSubtitle>Private Groups are not visible to non-members and require a direct invitation to join.</OptionSubtitle>
                                          </div>
                                        )}
                                      />
                                      <FormControlLabel
                                        style={{ marginBottom: 8 }}
                                        value={true}
                                        control={<Radio
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary" />}
                                        label={(
                                          <div>
                                            <OptionTitle>Public Group</OptionTitle>
                                            <OptionSubtitle>Public Groups are visible to non-members and non-members can request access.</OptionSubtitle>
                                          </div>
                                        )}
                                      />

                                    </RadioGroup>
                                    {this.state.public === "true" || this.state.public === true ? (
                                    <div>
                                    <FormControlLabel
                                      style={{ marginLeft: 18 }}
                                      control={
                                        <Checkbox
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          disabled= {this.state.allow_join_auto}
                                          checked={this.state.allow_join_requests || false}
                                          onChange={this.onChangeJoin}
                                          name="allow_join_requests"
                                          id="allow_join_requests"
                                        />
                                      }
                                      label="Allow requests to join the group."
                                    />
                                    <FormControlLabel
                                      style={{ marginLeft: 18 }}
                                      control={
                                        <Checkbox
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={this.state.allow_join_auto || false}
                                          onChange={this.onChangeJoinAuto}
                                          name="allow_join_auto"
                                          id="allow_join_auto"
                                        />
                                      }
                                      label="Allow anyone to join automatically."
                                    />
                                    </div>
                                    ) : ("")}



                                  </FormControl>
                                </ControlLine>
                                                               

                                <ControlLine>
                                <FormControl component="fieldset">
                                    <FormGroup style={{ marginBottom: 8 }}>

                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            disableRipple
                                            color="primary"
                                            checked={this.state.join_link_enabled || false}
                                            onChange={e => { this.onChangeSharing(e) }}
                                            style={{ marginBottom: "auto", paddingTop: 0 }}

                                            name="group__join_link_enabled"
                                            id="group__join_link_enabled"
                                            key="group__join_link_enabled"
                                          />
                                        }
                                        label={(
                                          <div>
                                            <OptionTitle>Join Link</OptionTitle>
                                            <OptionSubtitle>Anyone with the link can join the group.</OptionSubtitle>
                                          </div>
                                        )}
                                      // style={{ width: "calc(100%-64px)" }}
                                      />
                                      {this.state.join_link && this.state.join_link_enabled ? (
                                        <div>
                                          <ShareLinkBox>
                                            {window.location.origin}/groupinvite/{group.join_link}
                                          </ShareLinkBox>
                                          <ShareLinkButtons>
                                            <Button
                                              disableElevation
                                              // variant="contained"
                                              color="primary"
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "none",
                                              }}
                                              size="small"
                                              onClick={(e) => {
                                                this.copySharingLink(e);
                                              }}
                                              startIcon={
                                                <ContentCopyIcon style={{ color: "#383838" }} />
                                              }>
                                              Copy Link
                                            </Button>
                                            <Button
                                              disableElevation
                                              // variant="contained"
                                              color="primary"
                                              style={{
                                                fontSize: "14px",
                                                textTransform: "none",
                                              }}
                                              size="small"
                                              onClick={() => {
                                                this.changeSharingLink(this.state.id);
                                              }}
                                              startIcon={
                                                <RefreshIcon style={{ color: "#383838" }} />
                                              }>
                                              Refresh Link
                                            </Button>
                                          </ShareLinkButtons>
                                        </div>
                                      ) : ""}

                                    </FormGroup>
                                  </FormControl>
                                  </ControlLine>      


                                <SectionHelpText>
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(2);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            3: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Group Image</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <Column>

                                    <Button variant="contained" color="primary" disableElevation component="label">
                                      <input
                                        type="file"
                                        id="upload-avatar-button"
                                        accept="image/*"
                                        multiple={false}
                                        hidden
                                        onChange={e => this.handleImageChange(e)}
                                      />
                                      Change Group Image
                                    </Button>
                                  </Column>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(3);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            4: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Manage Participants</SectionLabel2>
                                </SectionHeader>
                                <ToggleButtonGroup
                                  color="primary"
                                  value={this.state.memberFilter}
                                  exclusive
                                  onChange={e => this.handleMemberFilterChange(e)}
                                  aria-label="member status"
                                  size="small"
                                  sx={{ width: "100%", marginTop: -1 }}
                                >
                                  <ToggleButton sx={{ width: "100%", borderRadius: "0px" }} value="AC">Active</ToggleButton>
                                  <ToggleButton sx={{ width: "100%", borderRadius: "0px" }} value="IN">Invited</ToggleButton>
                                  {group.allow_join_requests ? <ToggleButton sx={{ width: "100%", borderRadius: "0px" }} value="RE">Requested</ToggleButton> : null}
                                </ToggleButtonGroup>
                                <SubContainer isMobile={dimensions.window_dimensions.isMobile}>
                                  {participants[this.state.memberFilter].results.map((result, index) => {

                                    return (
                                      <ParticipantLine key={index} onClick={() => {
                                        this.onChangeParticipant(result);
                                      }}>
                                        <LeftColumn isMobile={dimensions.window_dimensions.isMobile}>

                                          <AuthorImageSmall
                                            src={
                                              result.user && result.user.avatar && api_endpoint + "/media/" + result.user.avatar.id + "/file?size=256"
                                            }></AuthorImageSmall>

                                        </LeftColumn>
                                        <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                          <NameContainer>
                                            <UserNameSmall>{result.user ? result.user.fullname || result.user.username : result.invite_email} </UserNameSmall>
                                            {result.user && result.user.fullname ? <OtherNameSmall>{result.user.username} </OtherNameSmall> : null}
                                            <OtherNameSmall>{groupMemberRole[result.role]}</OtherNameSmall>
                                          </NameContainer>
                                        </NameColumn>


                                      </ParticipantLine>
                                    )
                                  })}

                                  {participants[this.state.memberFilter].results.length === 0 ? (
                                    <Box sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "64px",
                                      color: "rgba(32, 32, 32, 0.5)"
                                    }}>
                                      No members found.
                                    </Box>
                                  ) : null}
                                  {participants[this.state.memberFilter].next ? (
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.loadMore(participants[this.state.memberFilter].next, this.state.memberFilter);
                                      }}
                                    >
                                      Load More Participants...
                                    </Button>
                                  ) : ("")}
                                </SubContainer>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    {/* <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(3);
                                      }}>
                                      Cancel
                                    </Button> */}
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Back
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            5: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Remove Projects from Group</SectionLabel2>
                                </SectionHeader>

                                <SubContainer2 isMobile={dimensions.window_dimensions.isMobile}>
                                  {this.state.projects.allIDS.map(
                                    (id, index) => {
                                      const project = this.state.projects.byID[id]
                                      return (
                                        <FormControlLabel
                                          style={{ marginBottom: "8px" }}
                                          key={index}
                                          control={
                                            <Checkbox
                                              disableRipple
                                              color="primary"
                                              checked={project.checked}
                                              onChange={e => { this.SelectProject(project.id, index) }}


                                              name=""
                                              id=""
                                              key=""
                                            />
                                          }
                                          label={(
                                            <CheckLabel>
                                              <CheckImage src={project.image && imgUrl(project.image.id, 256)} />
                                              <CheckName>{project.name}</CheckName>

                                            </CheckLabel>
                                          )}
                                        // style={{ width: "calc(100%-64px)" }}
                                        />


                                      )
                                    })

                                  }


                                  {this.state.projects.next ? (
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.loadMoreProjects(this.state.projects.next);
                                      }}
                                    >
                                      Load More Projects...
                                    </Button>
                                  ) : ("")}

                                </SubContainer2>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(5);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onRemoveProjects();
                                      }}>
                                      Remove
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                          }[this.state.paneltwo]
                        }
                      </PanelTwo>
                      <PanelThree w={this.state.panelW}>
                        <SectionContent w={this.state.panelW}>
                          <SectionHeader
                            onClick={() => {
                              this.onSlidebackParticipant();
                            }}>
                            {" "}
                            <BackIcon>
                              <ChevronLeftIcon fontSize="inherit" />
                            </BackIcon>{" "}
                            <SectionLabel2>Participant Details</SectionLabel2>
                          </SectionHeader>
                          <ControlLine
                            isMobile={
                              dimensions.window_dimensions.isMobile
                            }>
                            {this.state.panelthree ? (
                              <ParticipantContent>
                                <ParticipantLine >
                                  <LeftColumn isMobile={dimensions.window_dimensions.isMobile}>

                                    <AuthorImageSmall
                                      src={this.state.selected_participant.src

                                      }></AuthorImageSmall>

                                  </LeftColumn>
                                  <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                    <NameContainer>
                                      <UserNameSmall>{this.state.selected_participant.username} </UserNameSmall>
                                      <OtherNameSmall>{this.state.selected_participant.email} </OtherNameSmall>
                                      <OtherNameSmall>{this.state.selected_participant.fullname} </OtherNameSmall>
                                    </NameContainer>
                                  </NameColumn>


                                </ParticipantLine>
                                <ParticipantControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>

                                  <ParticipantCol1 style={{ paddingTop: "10px" }}>  <ParticipantLabel> Role:</ParticipantLabel></ParticipantCol1> <ParticipantCol2>
                                    <FormControl style={{ width: "100%" }} id="hello" size="small" variant="outlined">
                                      <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="mmmm"

                                        value={this.state.current_role}
                                        onChange={(e) => {
                                          this.handleChangeRole(
                                            e.target.value,
                                          );
                                        }}>
                                        <MenuItem value="AD">Admin</MenuItem>
                                        <MenuItem value="CO">Contributor</MenuItem>
                                        <MenuItem value="CM">Commenter</MenuItem>
                                        <MenuItem value="VI">Viewer</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </ParticipantCol2>
                                </ParticipantControlLine>
                                <ParticipantControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <ParticipantCol1>  <ParticipantLabel> Status:</ParticipantLabel></ParticipantCol1>
                                  <ParticipantCol2> {this.state.verbose_status}
                                    {this.state.verbose_status === "Active" ? (<FormControlLabel
                                      style={{ marginTop: "5px" }}
                                      control={
                                        <Checkbox
                                          // style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={this.state.remove_participant || false}
                                          onChange={this.onChangeRemove}
                                          name="remove_participant"
                                          id="remove_participant"
                                        />
                                      }
                                      label="Remove participant"
                                    />) : ("")}
                                    {this.state.verbose_status === "Invited" ? (<FormControlLabel
                                      style={{ marginTop: "5px" }}
                                      control={
                                        <Checkbox
                                          // style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={this.state.remove_participant || false}
                                          onChange={this.onChangeRemove}
                                          name="remove_participant"
                                          id="remove_participant"
                                        />
                                      }
                                      label="Remove invitation"
                                    />) : ("")}
                                    {this.state.verbose_status === "Requested" ? (<FormControl component="fieldset">

                                      <RadioGroup

                                        value={this.state.request_status}
                                        onChange={this.onChangeRadioRequest}>
                                        <FormControlLabel

                                          value="AC"
                                          control={<Radio

                                            color="primary" />}
                                          label="Accept Request"
                                        />
                                        <FormControlLabel

                                          value="RE"
                                          control={<Radio

                                            color="primary" />}
                                          label="Reject Request"
                                        />

                                      </RadioGroup>




                                    </FormControl>) : ("")}


                                  </ParticipantCol2>


                                </ParticipantControlLine>
                                <ParticipantControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <ParticipantCol1> </ParticipantCol1>
                                  <ParticipantCol2>

                                    {/* <Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.applyParticipant();
                                      }}>
                                      Remove
                                    </Button> */}
                                  </ParticipantCol2>


                                </ParticipantControlLine>
                              </ParticipantContent>
                            ) : ("")}

                          </ControlLine>
                          <ControlLine
                            isMobile={dimensions.window_dimensions.isMobile}
                          >

                          </ControlLine>
                          {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                          <SectionFooter>
                            <ButtonContainer>
                              <Button
                                disableElevation
                                variant="contained"
                                color="black"
                                style={{ minWidth: "80px" }}
                                onClick={() => {
                                  this.onSlidebackParticipant(1);
                                }}>
                                Cancel
                              </Button>
                              {this.state.remove_participant ? (<Button
                                disableElevation
                                variant="contained"
                                color="secondary"
                                style={{
                                  minWidth: "80px",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  this.removeUser(this.state.selected_participant.id, this.state.selected_participant.part_id);
                                  this.onSlidebackParticipant(1);
                                }}>
                                Remove
                              </Button>) : (<Button
                                disableElevation
                                variant="contained"
                                color="primary"
                                style={{
                                  minWidth: "80px",
                                  marginLeft: "auto",
                                }}
                                onClick={() => {
                                  this.applyParticipant(this.state.selected_participant.id);
                                }}>
                                Apply
                              </Button>)}

                            </ButtonContainer>
                          </SectionFooter>

                        </SectionContent>
                      </PanelThree>
                    </PanelContainer>
                  </CropPanel>
                  {this.state.paneltwo !== 0 ? (
                    ""
                  ) : (
                    <div>

                      {this.props.new_dialog ? (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>

                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.newPage();
                            }}>
                            Create
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>) : (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>
                          <StyledButtonDelete>
                            <Button
                              ref={this.moreOptionsButton}
                              disableElevation
                              id="basic-button"
                              variant="contained"
                              color="black"
                              aria-controls={this.state.showMoreOptions ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={this.state.showMoreOptions ? "true" : undefined}
                              onClick={e => this.handleShowOptionsMenu(true)}
                              endIcon={<KeyboardArrowDownIcon />}
                              style={{ minWidth: "128px" }}>
                              Options
                            </Button>
                            <Menu
                              id="basic-menu"
                              anchorEl={this.moreOptionsButton.current}
                              open={this.state.showMoreOptions}
                              onClose={e => this.handleShowOptionsMenu(false)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}>
                              <MenuItem
                                onClick={e => this.handleShowArchiveConfirmation(e)}
                                disableRipple>
                                <ArchiveIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Archive
                              </MenuItem>
                              <MenuItem
                                onClick={e => this.handleShowDeleteConfirmation(e)}
                                disableRipple>
                                <DeleteForeverIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Delete
                              </MenuItem>
                            </Menu>
                          </StyledButtonDelete>
                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.savePage();
                            }}>
                            Save
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>)}

                    </div>
                  )}
                </ColumnRight>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer >
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,
  group: state.groups.current_group,
  participants: state.groups.manage_participants,
  dialogType: state.ui.groupDialogType,
  pending_invites: state.groups.pending_invites,
  search_results: state.groups.search_results,
  organizations: state.auth.myprofile.organizations,
});
export default connect(mapStateToProps)(withRouter(GroupEdit));
