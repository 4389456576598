import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { initiateReset } from "../../actions/authActions";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
const StyledloginContainer = styled.div`
  display: block;
  margin: 16.66667vh auto 16.66667vh auto;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledloginDialog = styled.div`
  display: inline-block;
  margin: 0 auto 8.33333vh auto;
  // background-color: #F3F3F3;
  width: 300px;
  min-height: 120px;
  border-radius: 2px;
  // box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%), 0 0 2px 0 rgb(0 0 0 / 20%);
`;
const LoginImage = styled.div`
  display: inline-block;
  margin: 0 auto 40px auto;

  width: 300px;
  min-height: 120px;

`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
   width: 252px;
  margin: 24px 24px 10px 24px;
  
`;
const StyledInputPwd = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 252px;
  background-color: #0073ec;
  color: white;
  height: 32px;
  border-radius: 2px;

  padding-right: 30px;
  padding-left: 30px;
  margin: 10px 24px 10px 24px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const Styledtext = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-bottom: 19px;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;
const StyledHeaderText = styled.div`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #111;
  padding-left: 20px;
  padding-right: 20px;
`;
class InitiatePasswordreset extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      emailerror: "",
      token: "",
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    console.log(token)
    this.setState({ token: token });
    setTitle("Password Reset | " + site_name)
  }
  componentDidUpdate(nextProps) {
    if (this.props.auth.user.username) {
      this.props.history.push("/" + this.props.auth.user.username); // push user to profile page when they login
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return true
    return re.test(String(email).toLowerCase());
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  // onSubmit = (e) => {
  //  if (this.validateEmail(this.state.username)){
  //   this.setState({ emailerror:""});
  //   e.preventDefault();
  //   const userData = {
  //     email: this.state.username,
  //     password: this.state.password,
  //   };
  //   console.log("email")
  //   this.props.loginUser(userData, this.props.history);
  //  }else{
  //   console.log("no_email")
  //   e.preventDefault();
  //   this.setState({ emailerror:"Please use email to login."});
  //   return false;
  //  }


  // };
  onSubmit = (e) => {

    e.preventDefault();
    const userData = {
      user: this.state.email
    };
    console.log("reset")
    this.props.initiateReset(userData, this.props.history);



  };
  render() {
    const { errors } = this.state;
    const { reset_state } = this.props;
    switch (reset_state) {
      case 1:
        return (
          <StyledloginContainer>
            <LoginImage> <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img></LoginImage>
            <StyledHeaderText>

              Please check your inbox or spam folder for a reset link.
            </StyledHeaderText>

          </StyledloginContainer>
        );

      default:
        return (
          <StyledloginContainer>
            <LoginImage> <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img></LoginImage>
            <StyledHeaderText>

              Please enter your email address and we’ll send you a link to reset your password.
            </StyledHeaderText>
            <StyledloginDialog>
              <form noValidate onSubmit={this.onSubmit}>
                <FormField>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      style={{ background: "#fff", borderRadius: '3px' }}
                      size="small"
                      id="email"
                      label="Email address"
                      variant="outlined"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                  </FormControl>

                </FormField>
                {/* <FormField>
                       <FormControl fullWidth variant="outlined">
                                <TextField
                                   style= {{background: "#fff", borderRadius: '3px'}}
                                  size="small"
                                  id="password"
                                  variant="outlined"
                                  label="New Password"
                                  type="password"
                                  value={this.state.password}
                                  onChange={this.onChange}
                                />
                              </FormControl>
                              </FormField> */}
                <StyledError>{errors.email}</StyledError>

                <StyledError>{errors.password}</StyledError>
                <StyledError>{errors.detail}</StyledError>
                <StyledError>{this.state.emailerror}</StyledError>
                <Button
                  disableElevation
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ minWidth: "252px", marginTop: '10px' }}

                >
                  Send
                </Button>


              </form>
            </StyledloginDialog>
          </StyledloginContainer>
        );

    }

  }
}
// Login.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  reset_state: state.auth.reset_state,
});
export default connect(mapStateToProps, { initiateReset })(InitiatePasswordreset);
