import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ColumnMiddle } from "../styles/MenuComponents";
import { connect } from "react-redux";
import NavBar2 from "./navbar.js"
import { Ribbons } from "../marketing_pages/Ribbon.js";

import Footer from "../marketing_pages/Footer.js";
import { StyledBackGround, CenterColumn, Columns, LeftColumn } from "./styles"
import {
  ContentWrapper,
  HeaderSection,

} from "../marketing_pages/MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
class Processors extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    window.scrollTo(0, 0);
    setTitle(`Made Processors | ${site_name}`)
  }

  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        {/* <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <StyledBackGround>

          <Columns isMobile={this.props.isMobile}>
            <LeftColumn isMobile={this.props.isMobile}>
              <NavBar2 />
            </LeftColumn>
            <CenterColumn>
              <h1>Third Party Processors</h1>

              <p>
                We need to rely on third-party services to operate our business and
                provide our customers the Services available at www.asitsmade.com.
                These subprocessors may process certain customer data on our behalf.
              </p>
              <p>
                We reserve the right to modify our list of third party subprocessors
                at any time; please continue to reference this list to see our
                current vendors.
              </p>
              <h3>Current Infrastructure Subprocessors</h3>
              <p>
                Made may use the following subprocessors to host our Customer Data
                or provide other infrastructure that helps deliver our Services:
              </p>
              <ul>
                <li>
                  <strong>Amazon Web Services, Inc.</strong> - Cloud Service
                  Provider based in the United States
                </li>
              </ul>
              <p>
                <br />
              </p>
              <h3>Other Current Subprocessors</h3>
              <p>
                Made may use the following subprocessors to perform other
                Service functions:
              </p>
              <ul>
                <li>
                  <strong>Google Inc.</strong> - Cloud-based document collaboration
                  &amp; storage based in the United States<strong></strong>
                </li>
                <li>
                  <strong>Microsoft Inc. </strong>- Email provider based in the
                  United States{" "}
                </li>
                <li>
                  <strong>Twillio Sendgrid</strong> - Cloud-based Email Delivery
                  Services based in the United States{" "}
                </li>
              </ul>
              <p>Last Updated: January 25, 2023</p>


            </CenterColumn>
          </Columns>
        </StyledBackGround>
        <Footer />
      </ContentWrapper>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  isMobile: state.dimensions.window_dimensions.isMobile,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Processors);
// export default withRouter(Privacy);
