import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";

import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";
import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  HeroImage,
  ThreeColumn,
  FeatureImage,
  FeatureImageLarge,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageLeftSectionLarge,
  ImageRightSection,
  ImageRightSectionLarge,
  LeftRightImage,
  LeftRightImageLarge,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
} from "./MarketingComponents";
import { site_name } from "../../config";
import setTitle from "../../utils/setTitle";

class HomeMarketing extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTitle(`${site_name} | About Organizations`)
  }
  handleWaitMenu() {
    // console.log("wait");
    // this.props.dispatch(setMenu(true, 19));
    window.location.href = "/register/";
  }
  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection>
        <ContentSectionTop>
          <CenterColumnSingle isMobile={isMobile}>
            <CustomH1 style={{ color: "#A05DF8" }} isMobile={isMobile}>
              <ScrollBox isMobile={isMobile}>
                <ScrollList isMobile={isMobile}>
                  <ScrollItem>learn</ScrollItem>
                  <ScrollItem>follow</ScrollItem>
                  <ScrollItem>teach</ScrollItem>
                  <ScrollItem>learn</ScrollItem>
                </ScrollList>{" "}
              </ScrollBox>
              <H1Text style={{ paddingLeft: "0px", paddingRight: "0px" }}>&nbsp;as it's made</H1Text>
            </CustomH1>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
            <CustomP isMobile={isMobile}>
              {" "}
              A platform for collaborative learning
            </CustomP>
          </CenterColumnSingle>
        </ContentSectionTop>
        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <HeroImage>
              <img
                style={{ width: "100%" }}
                src="/marketing_images/groups_1.jpg"
              />
            </HeroImage>
          </CenterColumn>
        </ContentSection>

        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/groups_3.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#A05DF8" }}>
                made for administrators
              </FeatureTitle>
              <FeatureText>
                Create a single online destination for coursework and
                curriculum. Leverage the publicly shared projects to create a
                showcase for the work being created.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/groups_2.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#A05DF8" }}>
                made for
                <br />
                teachers
              </FeatureTitle>
              <FeatureText>
                Use Made to create class templates, store and distribute
                learning materials such as handouts. Use groups to provide
                feedback to students in private setting.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/groups_4.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#A05DF8" }}>
                made for
                <br />
                students
              </FeatureTitle>
              <FeatureText>
                Connect with fellow students, observe their approach to
                creativity, work in unison on shared assignments. Use the
                comments to request feedback.
              </FeatureText>
            </ThreeColumn>
          </CenterColumn>
        </ContentSection>
        {/* <ContentSectionGradient>
          <CenterColumnSingle>
            <CenterHeader color="#fff">follow your passion</CenterHeader>
            <CenterList>
              <CategoryItem>Drawing</CategoryItem>
              <CategoryItem>Painting</CategoryItem>
              <CategoryItem>Woodworking</CategoryItem>
              <CategoryItem>Digital Art</CategoryItem>
              <CategoryItem>Ceramics</CategoryItem>
              <CategoryItem>Textiles</CategoryItem>
              <CategoryItem>Crafts</CategoryItem>
              <CategoryItem>Electronics</CategoryItem>
              <CategoryItem>DIY</CategoryItem>
            </CenterList>
          </CenterColumnSingle>
        </ContentSectionGradient> */}
        {/* <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#38DF88">Curious?</CenterHeader>
            <CenterText maxW="425">
               If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection> */}
        <ContentSectionImage src={"marketing_images/Rectangle_217.jpg"}>
          <ImageOverlay isMobile={isMobile}>
            A collaborative workspace <br />
            where creativity thrives
          </ImageOverlay>
        </ContentSectionImage>
        <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#A05DF8">Curious?</CenterHeader>
            <CenterText maxW="425">
              If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSection>
          <CenterColumnSingle>
            <ImageLeftSectionLarge isMobile={isMobile}>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/groups_5.png"
                />
              </LeftRightImageLarge>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#A05DF8" }}>
                  A dynamic showcase
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Creating a portfolio of an organization’s creative output can
                  be nightmare with the constant need for securing permissions,
                  documenting output, and updating content. Made makes it easy
                  by automatically rotating in the latest updates, all managed
                  by the students themselves.
                </CenterText>
              </LeftRightText>
            </ImageLeftSectionLarge>
            <ImageRightSectionLarge isMobile={isMobile}>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#A05DF8" }}>
                  A unified experience
                </FeatureSubTitle>{" "}
                <CenterText maxW={300}>
                  Emails, web sites, social networks and paper handouts are just
                  a few of the channels a teachers needs to juggle in order
                  to communicate and manage their student base. Made allows to do away with most of these and provide a single place for all class content.
                </CenterText>
              </LeftRightText>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/groups_6.png"
                />
              </LeftRightImageLarge>
            </ImageRightSectionLarge>
            <ImageLeftSectionLarge isMobile={isMobile}>
              <LeftRightImageLarge isMobile={isMobile}>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "-4px" }}
                  src="/marketing_images/groups_7.png"
                />
              </LeftRightImageLarge>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#A05DF8" }}>
                  Share the process of learning
                </FeatureSubTitle>{" "}
                <CenterText maxW={370}>
                  It can be difficult for a prospective student to get a sense
                  of a class based solely on a description. When students share
                  updates, it helps tell a story - the principles being learned,
                  how they are applied and how a piece evolves over time.
                </CenterText>
              </LeftRightText>
            </ImageLeftSectionLarge>
          </CenterColumnSingle>
        </ContentSection>
        {/* <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterImage color="#008eff">
              <img
                style={{ width: "100%", mixBlendMode: "multiply" }}
                src="/marketing_images/people.png"
              />
            </CenterImage>
            <CenterHeader color="#008eff">
              <a name="Story">Our Story</a>{" "}
            </CenterHeader>
            <CenterText>
              Hi!
              <br />
              <br />
              We are Martijn, Gavin and Aidan, product designers and developers
              <br />
              who like to do creative projects in our spare time. <br />
              <br />
              Over the past couple of years, we became frustrated with
              traditional social media as a platform for sharing our work. In
              response, we designed a platform that celebrated the creative
              journey, allowing us to share the process behind the project.
              <br />
              <br />
              And so we created Made.
            </CenterText>
          </CenterColumnSingle>
        </ContentSectionSingle> */}
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(HomeMarketing));
