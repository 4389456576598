import { CONSTANTS } from "../actions";
import axiosInstance from "../utils/axiosApi";
import obj2url from "../utils/obj2url";

export const newFeedback = (data) => (dispatch) => {
  axiosInstance
    .post("feedback/", data)
    .then((result) => {
      //console.log("send");
      dispatch(setMenu(false, 5));
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const setBanner = (state, email, username) => (dispatch) => {
  const data = { show_first_use: state, email: email, username: username };
  console.log(data)
  axiosInstance
    .put("/users/update/", data)
    .then((res) => {
      //console.log("saved it");
      dispatch({
        type: CONSTANTS.SET_BANNER,
        payload: state,
      });
    })
    .catch((err) => {
      if (err.response) {
        //console.log(err.response);
        dispatch({
          type: CONSTANTS.ON_ERROR,
          payload: err.response.data,
        });
      } else {
        dispatch(setMenu(false));
      }
    });
};
export const setMenu = (state, type, defaultO) => {
  console.log("set menu", state, type, defaultO)

  return {
    type: CONSTANTS.SET_MENU,
    payload: { state: state, type: type, defaultO: defaultO },
  };

};
export const setSelectedElement = (id, groupid, element_type, groups) => {
 

  return {
    type: CONSTANTS.SET_SELECTED_ELEMENT,
    payload: { element: id, group: groupid, element_type: element_type, groups },
  };

};

export const setGroupSettings = (state, type) => {
  return {
    type: CONSTANTS.SET_GROUP_SETTINGS,
    payload: { state, type },
  };
};
export const selectElementType = (typeid) => {
  return {
    type: CONSTANTS.SELECT_ELEMENT_TYPE,
    payload: typeid,
  };
};
export const AnnotateMode = (state) => {
  return {
    type: CONSTANTS.SET_ANNOTATE_MODE,
    payload: state,
  };
};
export const resetProjectLink = (state) => {
  return {
    type: CONSTANTS.RESET_PROJECT_LINK,
    payload: state,
  };
};
export const AnnotateCoordinates = (x, y) => {
  return {
    type: CONSTANTS.SET_ANNOTATE_COORDINATES,
    payload: { x, y },
  };
};
export const updateProgress = (loaded, total) => {
  //console.log(loaded);
  //console.log(total);
  const percentage = Math.round(loaded / total * 100)
  return {
    type: CONSTANTS.SET_UPLOAD_PROGRESS,
    payload: { percentage },
  };
};
export const getProjectCategories = (filters) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_CATEGORIES_LOADING,
    payload: "loading",
  });
  axiosInstance.get("metadata/categories/" + obj2url(filters), { login: false }).then((res) => {
    dispatch({
      type: CONSTANTS.GET_CATEGORIES,
      payload: res.data,
    });
  });
};
export const timeoutError = (state) => {

  return {
    type: CONSTANTS.SET_TIMEOUT_ERROR,
    payload: state,
  };
};
export const genericError = (message) => {

  console.log(message)
  return {
    type: CONSTANTS.SET_GENERIC_ERROR,
    payload: message,
  };

};