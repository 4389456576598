import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ColumnMiddle } from "../styles/MenuComponents";
import { connect } from "react-redux";
import NavBar2 from "./navbar.js"
import { Ribbons } from "../marketing_pages/Ribbon.js";

import Footer from "../marketing_pages/Footer.js";
import { StyledBackGround, CenterColumn, Columns, LeftColumn } from "./styles"
import {
  ContentWrapper,
  HeaderSection,

} from "../marketing_pages/MarketingComponents";
import { site_name } from "../../config";
import setTitle from "../../utils/setTitle";

const A = styled.a`
  color: #008EFF;
`

class Attributions extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    window.scrollTo(0, 0);
    setTitle(`Attributions | ${site_name}`)
  }

  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
       
        {/* <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <StyledBackGround>

          <Columns isMobile={this.props.isMobile}>
            <LeftColumn isMobile={this.props.isMobile}>
              <NavBar2 />
            </LeftColumn>
            <CenterColumn>
              <h1>Image Attributions</h1>


              <p>
                Title: "Listening to mentor with attention"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/listening-mentor-with-attention_5399616.htm#query=woodworkingclass&position=0&from_view=search&track=ais">Image by pressfoto</A> on Freepik

              </p>
              <p>
                Title: "Various carpentry tool top view copy space"
                <br />
                Source: Image by <A href="https://www.freepik.com/free-photo/various-carpentry-tool-top-view-copy-space_9699420.htm#&position=10&from_view=undefined">Freepik</A>
                <br />
              </p>
              <p>
                Title: "Multiethnic group of students learning to draw sketch together in art class"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/multiethnic-group-students-learning-draw-sketch-together-art-class-using-pencil-canvas-create-vase-model-personal-growth-workshop-drawing-skills-development_29781163.htm#&position=24&from_view=undefined">Image by DCStudio</A> on Freepik
                <br />
              </p>
              <p>
                Title: "Multiethnic group of students learning to draw sketch together in art class"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/empty-artistic-space-studio-with-nobody-it-ready-students-attend-drawing-class-school-classroom-equipped-with-painting-canvas-drawing-professional-tools-creative-concept_24527295.htm#&position=6&from_view=undefined">Image by DCStudio</A> on Freepik
                <br />
              </p>
              <p>
                Title: "Two creative designers working in a project together "
                <br />
                Source: <A href="https://www.freepik.com/free-photo/two-creative-designers-working-project-together-sharing-new-ideas-workplace-business-team-work-concept_10928870.htm#&position=2&from_view=undefined">Image by mego-studio</A> on Freepik
                <br />
              </p>
              <p>
                Title: "Lady in black dress and eyeglasses looks into camera in office"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/lady-black-dress-eyeglasses-looks-into-camera-office_13013870.htm#&position=19&from_view=undefined">Image by lookstudio</A> on Freepik
                <br />
              </p>          <p>
                Title: "Old man and woman working on drawing together"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/old-man-woman-working-drawing-together_18313351.htm#&position=21&from_view=undefined">Image by DCStudio</A> on Freepik
                <br />
              </p>
              <p>
                Title: "Portrait of elderly student drawing vase on white canvas sketching model"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/portrait-elderly-student-drawing-vase-white-canvas-sketching-model-using-graphic-pencil-creativity-studio-diverse-team-attenting-art-course-developing-new-artistic-skill-personal-growth_27624909.htm#&position=22&from_view=undefined">Image by DCStudio</A> on Freepik
                <br />
              </p>          <p>
                Title: "Designer illustration in wheelchair holding phone taking photo"
                <br />
                Source: <A href="https://www.freepik.com/free-photo/designer-illustration-wheelchair-holding-phone-taking-photo-personal-drawing-working-creative-masterpiece-art-atelier-artist-drawing-vase-using-professional-graphic-pencil_23941974.htm#&position=32&from_view=undefined">Image by DCStudio</A> on Freepik
                <br />
              </p>
              <p>
                Title: "Composition of stationery supplies in pink color"
                <br />
                Source: Image by <A href="https://www.freepik.com/free-photo/composition-stationery-supplies-pink-color_4783519.htm#&position=38&from_view=undefined">Freepik</A>
                <br />
              </p>
              <p>
                Title: "Facebook avatars collection"
                <br />
                Source: Image by <A href="https://www.freepik.com/free-vector/facebook-avatars-collection_794373.htm#&position=0&from_view=undefined">Freepik</A>
                <br />
              </p>


            </CenterColumn>
          </Columns>
        </StyledBackGround>
        <Footer />
      </ContentWrapper>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  isMobile: state.dimensions.window_dimensions.isMobile,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Attributions);
// export default withRouter(Privacy);


