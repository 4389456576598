import React, { Component } from "react";
import { api_endpoint } from "../config";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

import CommentContainer from "./CommentContainer";
import { selectElement } from "../actions/projectActions";
import { setDialogType } from "../actions/projectActions";
import { AnnotateCoordinates } from "../actions/uiActions";
import Dimension from "./elements/Dimension";
import Next from "./elements/Next";
import Question from "./elements/Question";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import SettingsOverscanIcon from "@mui/icons-material/SettingsOverscan";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AnnotationSVG from "./ElementAnnotation";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import HideAnnotations from "../custom-icons/HideAnnotations";
import ShowAnnotations from "../custom-icons/ShowAnnotations";
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: absolute;
  
  left: 50%;
  transform: translate(-50%, -0%);
  margin-bottom: 10px;
  width: calc(100% - 60px);
  max-width: 1200px;
  // min-width: 100%;
`;
const ElementContainerWide = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: absolute;
  
  left: 50%;
  transform: translate(-50%, -0%);
  margin-bottom: 10px;
  width: 100%;
  // min-width: 100%;
`;
const ElementContainerNarrow = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // margin-bottom: 10px;
  background-color: #fff;
  margin-left: -1px;
  width: 100%;
`;
const TopContainer = styled.div`
  width: 100%;
//   min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  display: flex;
  flex-direction: row;
`;
const TopContainerNarrow = styled.div`
  width: 100%;
  // min-height: 100%;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: row;
`;
const ArrowContainer = styled.div`
  display: flex;
  height: ${(props) => (props.arrowheight ? props.arrowheight : "300")}px;
`;

const ElementCard = styled.div`
  width: calc(100% - 50px);
  min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
`;
const ElementCardWide = styled.div`
  width: 100%;
//   min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
//   border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;
const ElementCardNarrow = styled.div`
  width: calc(100% + 1px);
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  // min-height: calc(100% + 200px);
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 5px;
  margin-left: -1px;
  margin-right: 0px;
//   margin-top: -50px;
  // margin-bottom: -50px;
  height: calc(100% + 50px);
  display: flex;
  flex-direction: column;
`;
const ElementLeft = styled.div`
  width: calc(100% - 350px);

  display: flex;
  flex-direction: column;
`;
const ElementRight = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 1px solid #f2f2f2;
  width: 350px;
`;

const ElementImageAnnotation = styled.div`
  // box-sizing: border-box;
  border-radius: 10px;
//   border: 8px solid #fff;
  display: flex;
  flex-direction: column;
//   width: calc(100% - 16px);
`;
const ElementImageAnnotationToolbar = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
`;
const AnnotationLayer = styled.div`
  position: absolute;
  //  background-color: rgba(245,160,160,0.5);
  width: 100%;
  height: calc(100% - 4px);
  z-index: 1000;
  cursor: ${(props) => (props.annotationMode ? "crosshair" : "auto")};
`;
const Annotation = styled.div`
  position: absolute;
  left: calc(${(props) => props.x} - 70px);
  top: calc(${(props) => props.y} - 70px);
  opacity: 1;
  cursor: default;
  pointer-events: none;
`;

const DimensionContainer = styled.div`
  // box-sizing: border-box;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const DimensionCenter = styled.div`
  // box-sizing: border-box;
  border-radius: 10px;
  border: 8px solid #fff;

  width: calc(100% - 16px);

  // background-color: #d8d8d8;
`;
const ElementDescription = styled.div`
  font-family: "Alegreya", serif;
  // font-family: adobe-garamond-pro, serif;
  font-size: 18px;
  max-width: 800px;
  // height: 100%;
  padding: 20px;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
  color: #111;
`;

const StyledImage = styled.img`
max-width: ${(props) => (props.optimizewidth ? "100%" : "none")};
max-height: ${(props) => (props.optimizewidth ? "none" : props.imgheight+"px")};

`;
const StyledIconButton = styled.div`
  font-size: 50px;
  margin-top: auto;
  margin-bottom: auto;
  color: #fff;
  min-width: 50px;
  max-height: 50px;
  cursor: pointer;
`;
const StyledIconButtonNarrow = styled.div`
  font-size: 30px;
  margin-top: 18px;
  margin-left: 16px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const CloseButtonNarrow = styled.div`
  font-size: 24px;
  margin-top: -10px;
  // margin-left: auto;
  margin-right: -5px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const EditButtonNarrow = styled.div`
  font-size: 18px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 20px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const EditButton = styled.div`
  font-size: 16px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: 10px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const ExpandIcon = styled.div`
  font-size: 22px;
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 10px;
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const LeftButtons = styled.div`
  display: flex;
  margin-top: 10px;
`;
const RightButtons = styled.div`
  display: flex;
  margin-left: auto;
  margin-top: ${(props) => (props.isMobile ? "5px" : "auto")};
 
`;
const CloseButton = styled.div`
  font-size: 24px;
  margin-top: 6px;
  margin-left: 5px;;
  margin-right: 10px;
  color: #111;
  min-width: 20px;
  // max-height: 20px;
  cursor: pointer;
`;
const ElementHeader = styled.div`
  display: flex;
`;
const ElementType = styled.div`
  font-size: 18px;
  margin-left: 18px;
  margin-top: 18px;
  color: #111;
`;
const MobileHeader = styled.div`
  height: 40px;
  width: 100%;
  background-color: #fff;
//   position: sticky;
//   top: 0;
  display: flex;
  z-index: 10000;
  opacity: 0.9;
`;
const WideHeader = styled.div`
  height: 50px;
  width: 100%;

  top: 0;
  display: flex;
  z-index: 10000;
`;
const LinkBadge = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  width: calc(100% - 25px);
  // position: absolute;
  margin-left: 10px;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: 1px solid #e4e3e3;
  border-top: 1px solid #e4e3e3;

  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
`;
class ElementCompare extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.state = {
      first: false,
      last: false,
      expandImage: true,
      touchLoc: 0,
      touchLocY: 0,
      showAnnotations: false
    };
  }
  handleTouchStart(touchStartEvent) {
    //console.log(touchStartEvent.targetTouches[0].clientX);

    this.setState({ touchLoc: touchStartEvent.targetTouches[0].clientX });
    this.setState({ touchLocY: touchStartEvent.targetTouches[0].clientY });
  }
  handleTouchEnd(touchEndEvent) {
    //console.log("end");
    //console.log(touchEndEvent.changedTouches[0].clientX - this.state.touchLoc);
    if (Math.abs(touchEndEvent.changedTouches[0].clientY - this.state.touchLocY) < 20) {

      
      if (touchEndEvent.changedTouches[0].clientX - this.state.touchLoc > 50) {
        this.navigateElement("previous");
      } else {
        if (touchEndEvent.changedTouches[0].clientX - this.state.touchLoc < -50) {
          this.navigateElement("next");
        }
      }

      this.setState({ touchLoc: 0 });
      this.setState({ touchLocY: 0 });
    }
  }
  handleClosePopup(target) {
    this.props.history.push(target);
  }
  handleClickNavigate(posX){
    const {
      dimensions,
    } = this.props;
    if (posX < dimensions.window_dimensions.w /2){
      // prev
      if (!this.state.first){
        this.navigateElement("prev");
      }
    }else{
      //next
      if (!this.state.last){
        this.navigateElement("next");
      }
    }
   
  }
  handleEdit(id) {
    // this.props.history.push("/edit/" + id);
    this.props.dispatch(setDialogType("edit"));
  }
  expandImage() {
    this.setState({ expandImage: true });
  }
  toggleAnnotations() {
    if(this.state.showAnnotations){
      this.setState({ showAnnotations: false });
    }else{
      this.setState({ showAnnotations: true });
    }
    
  }
  annotateImage(e, imagedimensions) {

    if (this.props.annotationMode) {
      const xpos = (e.clientX - imagedimensions.left) / imagedimensions.width;
      const ypos = (e.clientY - imagedimensions.top) / imagedimensions.height;
      this.props.dispatch(AnnotateCoordinates(xpos, ypos));
      this.props.dispatch(AnnotateCoordinates(xpos, ypos));
    }
  }
  contractImage() {
    this.setState({ expandImage: false });
  }
  keyPress = (e) => {
    if (e.keyCode === 39) {
      this.navigateElement("next");
    }
    if (e.keyCode === 37) {
      this.navigateElement("previous");
    }
  };
  componentDidMount() {
    const {
    
      dimensions,
     
    } = this.props;
    if (dimensions.window_dimensions.w < 1025){
      this.setState({ expandImage: true });
    }
    document.addEventListener("keydown", this.keyPress);
    console.log(this.props.group_id)
  }
  componentDidUpdate(){
    if (this.props.annotationMode){
      if (this.state.showAnnotations === false){
        this.setState({ showAnnotations: true });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }
  navigateElement = (direction) => {
    const { id, element_ids } = this.props;
    const loc = element_ids.indexOf(id);
    this.setState({ showAnnotations: true });
    let nextlink = "";
    let prevlink = "";
    if (loc === 0) {
      nextlink = element_ids[loc + 1];
      prevlink = "";
    } else {
      if (loc === element_ids.length - 1) {
        nextlink = "";
        prevlink = element_ids[loc - 1];
      } else {
        nextlink = element_ids[loc + 1];
        prevlink = element_ids[loc - 1];
      }
    }
    if (direction === "next") {
      if (nextlink !== "") {
        this.props.history.push(nextlink);
        this.props.dispatch(selectElement(nextlink));
      }
    } else {
      if (prevlink !== "") {
        this.props.history.push(prevlink);
        this.props.dispatch(selectElement(prevlink));
      }
    }
  };
  render() {
    const {
      id,
      element,
      element_ids,
      dimensions,
      element_types,
      project_url,
      isOwner,
      group_id,
      annotationMode,
      annotationCoordinates,
      comments,
    } = this.props;
    const loc = element_ids.indexOf(id);
    let first = false;
    let last = false;
    // let nextlink = "";
    // let prevlink = "";
    if (loc === 0) {
      first = true;
    }
    if (loc === element_ids.length - 1) {
      last = true;
    }
    const MaxImageRatio = 200; // beyond this crop the image
    const TextAreaWidth = 250;
    let AppliedRatio;
    if (element.image_ratio >= MaxImageRatio) {
      AppliedRatio = MaxImageRatio;
    } else {
      AppliedRatio = element.image_ratio;
    }
    const card_height = dimensions.window_dimensions.h - 100;
    const arrow_height = dimensions.window_dimensions.h - 100;
    const CardWidth = card_height * (AppliedRatio / 100) + TextAreaWidth;

    // let ImageHeight;
    // if (element.description) {
    //   ImageHeight = card_height - 120;
    // } else {
    //   ImageHeight = card_height - 15;
    // }
    // const ImageHeightNarrow = ((dimensions.window_dimensions.w - 128) * 3) / 4;
    // const ImageWidth = ImageHeight * (AppliedRatio / 100);

    let AnnotationNumber = 0;
    let hasImage = false;
    if (element.images[0]) {
      hasImage = true;
    }
    let image_url;
   
    if (element.images.length === 0) {
      image_url = false;
    } else {
        
      image_url = api_endpoint+"/media/"+element.images[0].id+"/file?size=1024";
    
     
    }

    let componentLeft = null;
    switch (element_types.byID[element.element_type].style) {
      case "9":
        componentLeft = (
          <DimensionCenter>
            <DimensionContainer>
              <Next
                selected={this.props.selected}
                description={element.description}
              />
            </DimensionContainer>
          </DimensionCenter>
        );
        break;
      case "6":
        componentLeft = (
          <DimensionCenter>
            <DimensionContainer>
              <Question
                selected={this.props.selected}
                description={element.description}
              />
            </DimensionContainer>
          </DimensionCenter>
        );
        break;
      case "3":
        componentLeft = (
          <div>
            <ElementImageAnnotation>
              {dimensions.window_dimensions.isMobile ||
              this.state.expandImage ? (
                ""
              ) : (
                <ElementImageAnnotationToolbar>
                  <ExpandIcon
                     
                     >
                       <IconButton
                         style={{ color: "#111", fontSize: '16px' }}
                         onClick={() => {
                           this.expandImage();
                         }}
                         size="large">
                       <SettingsOverscanIcon fontSize="inherit" />
                       </IconButton>
                     </ExpandIcon>
                </ElementImageAnnotationToolbar>
              )}
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                }}
              >
                {this.state.showAnnotations ? (   <AnnotationLayer
                  annotationMode={annotationMode}
                  ref={this.myRef}
                  onClick={(e) => {
                    this.annotateImage(
                      e,
                      this.myRef.current.getBoundingClientRect()
                    );
                  }}
                >
                  {comments &&
                    comments.allIDS
                      .filter(
                        (comment) =>
                          comments.byID[comment].x !== null &&
                          comments.byID[comment].y !== null
                      )
                      .map((comment, index) => {
                        
                        switch(group_id) {
                          case "1":
                       
                            if (comments.byID[comment].group === null && comments.byID[comment].public !== true) {
                              AnnotationNumber = AnnotationNumber + 1;
                              return (
                                <Annotation
                                  key={"ann" + index}
                                  x={comments.byID[comment].x * 100 + "%"}
                                  y={comments.byID[comment].y * 100 + "%"}
                                >
                                  <AnnotationSVG
                                    x={comments.byID[comment].x}
                                    y={comments.byID[comment].y}
                                    title={
                                      comments.byID[comment].creator.username +
                                      ": " +
                                      comments.byID[comment].content
                                    }
                                    number={AnnotationNumber}
                                    expandImage={this.state.expandImage}
                                  />
                                </Annotation>
                              );
                              
                            }
                            break;
                          case "2":
                            console.log("group_id")
                            if (comments.byID[comment].group !== null ||
                              comments.byID[comment].public === true) {
                              AnnotationNumber = AnnotationNumber + 1;
                              return (
                                <Annotation
                                  key={"ann" + index}
                                  x={comments.byID[comment].x * 100 + "%"}
                                  y={comments.byID[comment].y * 100 + "%"}
                                >
                                  <AnnotationSVG
                                    x={comments.byID[comment].x}
                                    y={comments.byID[comment].y}
                                    title={
                                      comments.byID[comment].creator.username +
                                      ": " +
                                      comments.byID[comment].content
                                    }
                                    number={AnnotationNumber}
                                    expandImage={this.state.expandImage}
                                  />
                                </Annotation>
                              );
                              
                            }
                            break;
                          default:
                            if (comments.byID[comment].group === group_id || comments.byID[comment].public === true) {
                              AnnotationNumber = AnnotationNumber + 1;
                              return (
                                <Annotation
                                  key={"ann" + index}
                                  x={comments.byID[comment].x * 100 + "%"}
                                  y={comments.byID[comment].y * 100 + "%"}
                                >
                                  <AnnotationSVG
                                    x={comments.byID[comment].x}
                                    y={comments.byID[comment].y}
                                    title={
                                      comments.byID[comment].creator.username +
                                      ": " +
                                      comments.byID[comment].content
                                    }
                                    number={AnnotationNumber}
                                    expandImage={this.state.expandImage}
                                  />
                                </Annotation>
                              );
                              
                            }
                        }
                        
                        // if (group_id !== "1") {
                        //   if (comments.byID[comment].group === group_id) {
                        //     AnnotationNumber = AnnotationNumber + 1;
                        //     return (
                        //       <Annotation
                        //         key={"ann" + index}
                        //         x={comments.byID[comment].x * 100 + "%"}
                        //         y={comments.byID[comment].y * 100 + "%"}
                        //       >
                        //         <AnnotationSVG
                        //           x={comments.byID[comment].x}
                        //           y={comments.byID[comment].y}
                        //           title={
                        //             comments.byID[comment].creator.username +
                        //             ": " +
                        //             comments.byID[comment].content
                        //           }
                        //           number={AnnotationNumber}
                        //           expandImage={this.state.expandImage}
                        //         />
                        //       </Annotation>
                        //     );
                            
                        //   }
                          
                        // }else{
                        //   if (comments.byID[comment].group === null) {
                        //     AnnotationNumber = AnnotationNumber + 1;
                        //     return (
                        //       <Annotation
                        //         key={"ann" + index}
                        //         x={comments.byID[comment].x * 100 + "%"}
                        //         y={comments.byID[comment].y * 100 + "%"}
                        //       >
                        //         <AnnotationSVG
                        //           x={comments.byID[comment].x}
                        //           y={comments.byID[comment].y}
                        //           title={
                        //             comments.byID[comment].creator.username +
                        //             ": " +
                        //             comments.byID[comment].content
                        //           }
                        //           number={AnnotationNumber}
                        //           expandImage={this.state.expandImage}
                        //         />
                        //       </Annotation>
                        //     );
                            
                        //   }
                          
                        // }
                        
                      })}
                  {annotationCoordinates.x ? (
                    <Annotation
                      x={annotationCoordinates.x * 100 + "%"}
                      y={annotationCoordinates.y * 100 + "%"}
                    >
                      <AnnotationSVG
                        x={annotationCoordinates.x}
                        y={annotationCoordinates.y}
                        number={AnnotationNumber + 1}
                        expandImage={this.state.expandImage}
                      />
                    </Annotation>
                  ) : (
                    ""
                  )}
                </AnnotationLayer>):("")}
             
                <DimensionCenter>
                  <DimensionContainer>
                  <Dimension
                          selected={this.props.selected}
                          description={element.description}
                          unit={
                            element.metadata  ? (element.metadata.dimension_unit ? element.metadata.dimension_unit : "") : ""
                          }
                          x={element.metadata ? (element.metadata.dimension_x ? element.metadata.dimension_x: 60) : 60}
                          y={element.metadata ? (element.metadata.dimension_y ? element.metadata.dimension_y: 60) : 60}
                          z={element.metadata ? (element.metadata.dimension_z ? element.metadata.dimension_z: 60) : 60}
                        />
                  </DimensionContainer>
                </DimensionCenter>
              </div>
            </ElementImageAnnotation>

            {element.description ? (
              <ElementDescription>{element.description}</ElementDescription>
            ) : (
              ""
            )}
          </div>
        );
        break;
      case "4":
      case "2":
      case "5":
        componentLeft = (
       
             <StyledImage
                  alt={element.images[0].alt_text}
                
                  src={image_url}
                  optimizewidth ={this.props.optimizewidth}  
                  imgheight = {this.props.dimensions.window_dimensions.h - 400}
                  />
             
        );
        break;
      default:
        componentLeft = <div></div>;
    }

    if (dimensions.window_dimensions.isMobile) {
      return (
        <ElementContainerNarrow
          id={"bm-" + id}
          cardwidth={CardWidth}
          onTouchStart={(touchStartEvent) =>
            this.handleTouchStart(touchStartEvent)
          }
          onTouchEnd={(touchEndEvent) => this.handleTouchEnd(touchEndEvent)}
        >
          <TopContainerNarrow cardheight={card_height}>
            <ElementCardNarrow cardheight={card_height}>
             
              <div onClick={(e) => {
                        this.handleClickNavigate(e.clientX);
                      }}>
              {componentLeft}
              </div>
             
            </ElementCardNarrow>
          </TopContainerNarrow>
        </ElementContainerNarrow>
      );
    } else {
      return (
        <div style={{width: "100%"}}>
   
     
              <TopContainer cardheight={card_height}>
             
                <ElementCardWide cardheight={card_height}>
              

                  {componentLeft}

                 
                </ElementCardWide>
               
              </TopContainer>
           
        
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  element: state.project.current_project.elements.byID[ownProps.id],
  element_ids: state.project.current_project.elements.allIDS,
  dimensions: state.dimensions,
  element_types: state.project.current_project.element_types,
  annotationMode: state.ui.annotationMode,
  annotationCoordinates: state.ui.annotationCoordinates,
  comments: state.project.current_project.elements.byID[ownProps.id].comments,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(ElementCompare);
