import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";
import marketing_urls from "../../config/marketing_urls";
import ScrollContainer from "react-indiana-drag-scroll";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  ImageFull,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  FocusAreas,
  FocusArea,
  ArrowRight,
  InfoBox,
  VertSpacer,
  CenterColumnImage,
  CenterColumnText,
  CenterButton,
  CenterHeaderNew,
  CenterHeaderH2,
  CenterHeaderH2Small,
  CenterColumnImageMobile,
  CenterTextSmall,
  FeatureTitleH2,
  FeatureTextP,
  CenterTextNew,
  CenterTextWide,
  FocusMobile,
  LinkText,
  HeroImage,
  ThreeColumn,
  InfoBoxMobile,
  FeatureImage,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageRightSection,
  LeftRightImage,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
  HeroImageFade,
  FadeImage,
  PanLinkContainer,
  CenterColumnPan,
  ContentSectionSample,
  SampleImageSection,
  SampleText,
  SampleImage,
  SampleSubTitle,
  SampleTextLeft,
  CenterColumnThree,
  ButtonSection,
  ButtonContainer
} from "./MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
class HomeMarketing extends Component {
  constructor() {
    
    super();
    this.timeline = React.createRef();
    this.infoBox = React.createRef();
    this.state = {
      top: 48,
      toparrow: 75,
      box: 1,
      current_box: 0,
      info_content:
        "AI Feedback on Technique: In terms of technique, the brushwork is confident and energetic, which adds to the dynamic feel of the painting.",
      focus_content: {
        1: {height: 182, content: "AI Feedback on Technique: In terms of technique, the brushwork is confident and energetic, which adds to the dynamic feel of the painting."},
        2: {height: 182, content: "AI Feedback on Composition: The composition is well balanced, with the mountain providing a strong focal point and the trees framing the scene effectively."},
        3: {height: 182, content: "AI Feedback on Color and Value: The use of vibrant colors in the landscape is commendable, creating a lively and inviting atmosphere."},
        4: {height: 182, content: "AI Feedback on Edges: You might explore varying the edges more, softening some areas to increase the depth of field and focus attention on key elements of the composition."},
        5: {height: 182, content: "AI Feedback on Light and Shadow: The light and shadow play across the mountain could be further developed to create a more three-dimensional form and enhance the sense of volume."},
        6: {height: 182, content: "AI Feedback on Idea: The artist's idea to include a variety of elements, such as the foreground flora and the distant mountain, adds complexity and interest to the composition."},
        7: {height: 182, content: "AI Feedback on Texture: The texture of the paint application is effective in conveying the roughness of the natural terrain, adding depth to the scene."},
        8: {height: 182, content: "AI Feedback on Accuracy: Consider refining the reflections in the water to more accurately mirror the environment, enhancing the painting's realism."},
        9: {height: 182, content: "AI Feedback on Perspective: In terms of perspective, the reflection in the water could be refined to more accurately mirror the environment, enhancing the realism and depth of the scene."},
        10: {height: 182, content: "AI Feedback on Mood and Atmosphere: The mood and atmosphere are inviting and serene, which is a testament to your skill in handling the overall tone of the piece."},
      },
    };
  }
  componentDidMount() {
    const urlparts = window.location.hash;
    console.log(urlparts);
    if (urlparts === "#Story") {
      console.log("bottom");
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);
    } else {
      console.log("top");
      window.scrollTo(0, 0);
    }
    setTitle(`${site_name} | About`);
  }
  handleScroll(num){
    const { isMobile, w } = this.props;
   
    let shift = 0
    if (num>0){
      if (num === 1){
        shift = num*w-95
      }else{
        shift = w-95 + (num-1)*(w-70)
      }
    }
   
    this.timeline.current.getElement().scrollTo({
      top: 0,
      left: shift ,
      behavior: "smooth",
    });
    this.setState({ current_box: num });
  }
  navigateElement(direction){
    if (direction === "next"){
      if (this.state.current_box < 9){
      this.handleScroll(this.state.current_box + 1)
      }
    }else{
      if (this.state.current_box > 0){
        this.handleScroll(this.state.current_box - 1)
      }
      
    }
  }
  handleWaitMenu() {
    // console.log("wait");
    // this.props.dispatch(setMenu(true, 19));
    window.location.href = "/register/";
  }
  handleArea(num) {
    const { isMobile, w } = this.props;
    const ratio = 0.57
   
    let spacing = 48
    
    if (w < 990){
      spacing = 32
    }
    let topspacing = num * spacing
    console.log()
    if (topspacing + this.state.focus_content[num].height > w*ratio - 30){
      topspacing = w*ratio - this.state.focus_content[num].height - 30
    }
    this.setState({ top: topspacing });
    this.setState({ toparrow: 25 + spacing*num });
    this.setState({ info_content: this.state.focus_content[num].content });
   
  }
  render() {
    const { isMobile, w } = this.props;

    return (
      <ContentWrapper>
        <ContentSection color="transparent">
          <CenterColumnImage isMobile={isMobile}>
            <ImageFull>
              <img
              alt="Image of female painter in paint studio"
                style={{ width: "100%" }}
                src="/marketing_images/Made Marketing3.jpg"
              />
            </ImageFull>
          </CenterColumnImage>
        </ContentSection>
        <ContentSection color="transparent">
          <CenterColumnText color="#fff">
            <CenterHeaderNew isMobile={isMobile} color="#000">
              Modern Software.<wbr></wbr> Made for Artists
            </CenterHeaderNew>
            <CenterTextNew isMobile={isMobile}>
            Welcome to Made, a suite of artist’s tools designed to help you improve your craft, showcase your work, and document your creative journey.
Made uses AI to analyze your artwork and deliver feedback that is grounded in the foundational principles of the visual arts, helping you get better, faster.
 
              <br />
              <br />
              Curious?
              <br />
              <br />
              <LinkText href="/discovery/">See it in action</LinkText>

              <br />
              <br />
              <Button
               disableElevation
               href="/register/"
               variant="contained"
               color="primary"
              >create an account</Button>
             
              {/* <br />
              <br />
              The price for an individual Made subscription is $4.99 monthly or
              $49.99 yearly.
              <br />
              <br />
              For organisation pricing, please{" "}
              <a
                href="mailto:info@asitsmade.com"
                style={{ textDecoration: "none", color: "#008eff" }}>
                contact us.
              </a> */}
            </CenterTextNew>
          </CenterColumnText>
        </ContentSection>
        <ContentSection color="transparent">
          {isMobile? (
          <CenterColumnImageMobile>
          <CenterColumnImage
            style={{ paddingBottom: "0px", position: "relative" }}
            isMobile={isMobile}>
            <ImageFull>
              <img
                alt="Painting of a mountain landscape"
                style={{ width: "100%" }}
                src="/marketing_images/image96.jpg"
              />
            </ImageFull>
            </CenterColumnImage>
            <FocusMobile>
            <ScrollContainer
                style={{ overflowX: "auto", paddingTop: "10px",width: "100%" }}
              className="timeline"
              ref={this.timeline}

             >
<div style={{ display: "inline-flex", flexDirection: "row", paddingLeft: "10px" }}>
<InfoBoxMobile onClick={() => {
                  this.handleScroll(0);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[1].content}</InfoBoxMobile> 
<InfoBoxMobile  onClick={() => {
                  this.handleScroll(1);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[2].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(2);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[3].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(3);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[4].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(4);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[5].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(5);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[6].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(6);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[7].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(7);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[8].content}</InfoBoxMobile> 
<InfoBoxMobile  onClick={() => {
                  this.handleScroll(8);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[9].content}</InfoBoxMobile> 
<InfoBoxMobile onClick={() => {
                  this.handleScroll(9);
                }} w={w} show={"visible"} top={this.state.top}>{this.state.focus_content[10].content}</InfoBoxMobile> 
  </div>

              </ScrollContainer>
              <ButtonSection>
        <ButtonContainer>
           <IconButton
                        title="previous"
                        size="small"
                        style={{
                          fontSize: "20px",
                          marginRight: "10px",
                          marginLeft: "5px",
                        }}
                        onClick={() => {
                          this.navigateElement("previous");
                        }}>
                        <ArrowBackIosIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        title="next"
                        size="small"
                        style={{
                          fontSize: "20px",
                          marginRight: "5px",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          this.navigateElement("next");
                        }}>
                        <ArrowForwardIosIcon fontSize="inherit" />
                      </IconButton>
                      </ButtonContainer>
                      </ButtonSection>
            </FocusMobile></CenterColumnImageMobile>):(<CenterColumnImage
            style={{ paddingBottom: "0px", position: "relative" }}
            isMobile={isMobile}>
            <ImageFull>
              <img
                 alt="Painting of a mountain landscape"
             
                style={{ width: "100%" }}
                src="/marketing_images/image96.jpg"
              />
            </ImageFull>
            <FocusAreas isMobile={isMobile} w={w}>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(1);
                }}
                onClick={() => {
                  this.handleArea(1);
                }}>
                Technique
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(2);
                }}
                onClick={() => {
                  this.handleArea(2);
                }}>
                Composition
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(3);
                }}
                onClick={() => {
                  this.handleArea(3);
                }}>
                Color & Value
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(4);
                }}
                onClick={() => {
                  this.handleArea(4);
                }}>
                Edges
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(5);
                }}
                onClick={() => {
                  this.handleArea(5);
                }}>
                Light & Shadow
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(6);
                }}
                
                onClick={() => {
                  this.handleArea(6);
                }}>
                Idea
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(7);
                }}
                onClick={() => {
                  this.handleArea(7);
                }}>
                Texture
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(8);
                }}
                onClick={() => {
                  this.handleArea(8);
                }}>
                Accuracy
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(9);
                }}
                onClick={() => {
                  this.handleArea(9);
                }}>
                Perspective
              </FocusArea>
              <FocusArea
                onMouseOver={() => {
                  this.handleArea(10);
                }}
                onClick={() => {
                  this.handleArea(10);
                }}>
                Mood & Atmosphere
              </FocusArea>
            </FocusAreas>
            <ArrowRight w={w} toparrow={this.state.toparrow}></ArrowRight>
            <InfoBox   ref={this.infoBox} w={w} show={this.state.box === 1 ? ("visible"):("hidden")} top={this.state.top}>{this.state.info_content}</InfoBox>
            {/* <InfoBox top={this.state.top}>{this.state.info_content}</InfoBox> */}
          </CenterColumnImage>)}
          
        </ContentSection>
        {/* <ContentSection color="transparent">
          <CenterColumnText color="#fff"></CenterColumnText>
        </ContentSection> */}

        <ContentSection color="transparent">
          <CenterColumnText color="#fff">
            <CenterHeaderH2  style={{ paddingTop: "12px" }} isMobile={isMobile} color="#000">
              Feedback powered by AI
            </CenterHeaderH2>
            <CenterColumnThree color="#fff" isMobile={isMobile}>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>
                  Tap into <br />
                  Extensive Knowledge
                </FeatureTitleH2>

                <FeatureTextP>
                  The internet offers a deep well of knowledge for artists. Our
                  AI guide taps into the knowledge of artists and instructors to
                  provide you with clear, actionable feedback.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>
                  Sharing, <br />
                  without the anxiety
                </FeatureTitleH2>

                <FeatureTextP>
                  It can be intimidating to share your work with strangers. Our
                  AI Guide allows you to get the feedback you need without the
                  anxiety that comes with requesting peer feedback.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>
                  Timely
                  <br />
                  Feedback
                </FeatureTitleH2>

                <FeatureTextP>
                  Get feedback when it is most helpful - while the project is
                  still in progress. When you share your work in progress, you
                  have time to make adjustments early.
                </FeatureTextP>
              </ThreeColumn>
            </CenterColumnThree>
          </CenterColumnText>
        </ContentSection>

        <ContentSection color="transparent">
          <CenterColumnImage isMobile={isMobile}>
            <ImageFull>
              <img
               alt="Painting of a still life with grids and annotations"
                style={{ width: "100%" }}
                src="/marketing_images/new_compare.jpg"
              />
            </ImageFull>
          </CenterColumnImage>
        </ContentSection>
        <ContentSection color="transparent">
          <CenterColumnText color="#fff">
            <CenterHeaderH2 isMobile={isMobile} color="#000">
              Online Tools to Help you Improve
            </CenterHeaderH2>
            <CenterColumnThree color="#fff" isMobile={isMobile}>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>Overlays</FeatureTitleH2>

                <FeatureTextP>
                  It is very common for artists to work from a reference image,
                  but comparing our own work with the reference image can be
                  frustrating and time consuming. <br/><br/>Made allows you do do away
                  with the manual work in tools such as photoshop and do direct
                  comparisons using the built in overlay tool.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>Annotations</FeatureTitleH2>

                <FeatureTextP>
                  Commenting on an image is pretty straightforward. However,
                  there are times when you want to reference a very specific
                  part of an image and that can be tricky. <br/><br/>Made provides
                  annotations so that you can quickly refer to an image or
                  provide detailed feedback on someone else’s work.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>
                  Composition Grids
                </FeatureTitleH2>

                <FeatureTextP>
                  Throughout the centuries artists have used grids to improve
                  the quality of their final image.<br/><br/> Made provides a number of
                  different grids to speed up the image transfer process, check
                  accuracy, or improve overall composition.
                </FeatureTextP>
              </ThreeColumn>
            </CenterColumnThree>
          </CenterColumnText>
        </ContentSection>
        <ContentSection>
          <CenterColumnText>
            <CenterHeaderH2    style={{ paddingBottom: "20px" }} isMobile={isMobile} color="#000">
              Track Your Artistic Journey
            </CenterHeaderH2>
            <ImageFull>
              <img
               alt="Four paintings of a man showing the progression"
                style={{ width: "100%" }}
                src="/marketing_images/journey.jpg"
              />
            </ImageFull>
            <CenterTextWide style={{ paddingBottom: "30px" }} isMobile={isMobile}>
              It’s not the destination, but the journey that is most important.{" "}
              <br />
              Made allows you to document all aspects of your individual
              projects on a visual timeline so that you can document the
              evolution of a piece. As you create more projects, Made allows you
              to see your development as an artist over time, with all of your
              work in one place.
            </CenterTextWide>
          </CenterColumnText>
        </ContentSection>
        <ContentSection color="transparent">
          <CenterColumnImage isMobile={isMobile}>
            <ImageFull>
              <img
               alt="Portrait of a woman in an art gallery"
                style={{ width: "100%" }}
                src="/marketing_images/Made Marketing1.jpg"
              />
            </ImageFull>
          </CenterColumnImage>
        </ContentSection>
        <ContentSection color="transparent">
          <CenterColumnText color="#fff">
            <CenterHeaderH2 isMobile={isMobile} color="#000">
              Join a Community of Fellow Artists
            </CenterHeaderH2>
            <CenterColumnThree color="#fff" isMobile={isMobile}>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>Create</FeatureTitleH2>

                <FeatureTextP>
                Keep track of all your visual projects in one place. Quickly upload images of your progress and maintain a private visual diary of your progress over time.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>Connect</FeatureTitleH2>

                <FeatureTextP>
                Create private communities to share the journey of your projects and get feedback from people you know and trust.
                </FeatureTextP>
              </ThreeColumn>
              <ThreeColumn isMobile={isMobile}>
                <FeatureTitleH2 isMobile={isMobile}>Discover</FeatureTitleH2>

                <FeatureTextP>
                Join the creative journey of other artists by following their progress. 
                You can gain art inspiration, learn new painting techniques, and offer feedback to support their work.
                </FeatureTextP>
              </ThreeColumn>
            </CenterColumnThree>
            <CenterButton>
            <Button
               disableElevation
               href="/register/"
               variant="contained"
              
               color="primary"
               style={{maxWidth: '150px',minWidth: '150px'}}
              >Sign Up</Button>
              </CenterButton>
          </CenterColumnText>
        </ContentSection>
        <ContentSection>
          <CenterColumnText color="#f1f1f1">
            <CenterHeaderH2Small style={{paddingTop: "35px"}} isMobile={isMobile} color="#383838">
              See it in Action
            </CenterHeaderH2Small>
            <ImageFull>
              <img
               alt="Time line showing the progression of a sunflower painting"
                style={{ width: "100%" }}
                src="/marketing_images/image94.png"
              />
            </ImageFull>
            <CenterTextSmall isMobile={isMobile}>
              The best way to get a feel for Made is to try it for yourself.{" "}
              <br />
              Check out{" "}
              <LinkText
                href={
                  "/project/" + marketing_urls.sample_projects.sunflowers + "/"
                }>
                this sample project
              </LinkText>
            </CenterTextSmall>
          </CenterColumnText>
        </ContentSection>
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
  w: state.dimensions.window_dimensions.w,
});

export default connect(mapStateToProps)(withRouter(HomeMarketing));
