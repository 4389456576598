 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const Diagonal = (props) => {
  return (
      <SvgIcon {...props}>
<path d="M19,5v14H5V5H19 M21,3H3v18h18V3L21,3z"/>
<rect x="0.6" y="10.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.9445 11.9221)" width="22.7" height="2"/>
<rect x="15.2" y="10.8" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.714 16.1936)" width="2" height="10.7"/>
</SvgIcon>
  );
};

export default Diagonal;

