import React, { Component } from "react";

// const Spacer = styled.div`
//   min-width: 300px;
//  background-color: #ccc;

// `;

class SpacerElement extends Component {
  componentDidMount() {}

  render() {
    const { width } = this.props;
    let spacerwidth;
    if (width) {
      spacerwidth = Math.round(width / 2);
    } else {
      spacerwidth = Math.round(window.innerWidth / 2);
    }

    return <div style={{ minWidth: spacerwidth }}></div>;
  }
}

export default SpacerElement;
