import { CONSTANTS } from "../actions";

const initialState = {
  current_profile: {},
  projects: {
    byID: {},
    allIDS: [],
    count: 0,
    previous: "",
    next: "",
    no_results: 0,
  },
  subscribers:{loading: true, results: [],next: null, previous: null, count: 0},
  subscriptions:{projects: {loading: true, results: [],next: null, previous: null, count: 0},users: {loading: true, results: [],next: null, previous: null, count: 0},groups: {loading: true, results: [],next: null, previous: null, count: 0}},
  groups: [],
  groups_loading: true,
 
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.GET_CURRENT_PROFILE:
      if (action.payload) {
        return {
          ...state,
          current_profile: { ...action.payload },
          loading: false,
        };
      } else {
        return {
          ...state,

          loading: true,
        };
      }
      case CONSTANTS.SET_FOLLOW:{
      let newprofile = {...state.current_profile}
      newprofile.viewer_is_subscribed = true
      return {
        ...state,

       current_profile: newprofile
      };
    }
    case CONSTANTS.SET_UNFOLLOW:{
      let newprofile = {...state.current_profile}
      newprofile.viewer_is_subscribed = false
     
      return {
        ...state,

       current_profile: newprofile
      };
    }
    case CONSTANTS.SET_PROFILE_LOADING:
      return {
        ...state,
        projects: {
          byID: {},
          allIDS: [],
          count: 0,
          previous: "",
          next: "",
          no_results: 0,
        },
        loading: true,
      };
    case CONSTANTS.GET_CURRENT_PROFILE_PAGES: {
      let new_projects = {
        byID: {},
        allIDS: [],
        count: action.payload.data.count,
        previous: action.payload.data.previous,
        next: action.payload.data.next,
        no_results: 0,
      };
      const Projects = action.payload.data.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      //console.log(action.payload.state);
      if (action.payload.data.results.length === 0) {
        new_projects.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_projects.no_results = 1;
      }
      return {
        ...state,
        projects: { ...new_projects },
        projects_loading: false,
      };
    }
    case CONSTANTS.LOAD_MORE_PROFILE_PAGES: {
      //console.log("now loading more");
      let new_projects = { ...state.projects };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      new_projects.count = action.payload.count;
      new_projects.previous = action.payload.previous;
      new_projects.next = action.payload.next;
      return {
        ...state,
        projects: { ...new_projects },
        projects_loading: false,
      };
    }

    case CONSTANTS.LOAD_MORE_PROJECTS: {
      let new_projects = { ...state.projects };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      new_projects.count = action.payload.count;
      new_projects.previous = action.payload.previous;
      new_projects.next = action.payload.next;

      return {
        ...state,
        projects: new_projects,
        projects_loading: false,
      };
    }
    case CONSTANTS.SET_PROFILE_GROUPS_LOADING:
      return {
        ...state,

        groups_loading: true,
      };
    case CONSTANTS.GET_CURRENT_PROFILE_GROUPS:
      return {
        ...state,
        groups_loading: false,
        groups: [...action.payload.results],
      };
    case CONSTANTS.SET_PROFILE_PAGES_LOADING:
      return {
        ...state,

        projects_loading: true,
      };
      case CONSTANTS.SET_PROFILE_SUBSCRIBERS_LOADING:
        return {
          ...state,
  
          subscribers: {
            ...state.subscribers,
              loading: true,
          }
        };
        case CONSTANTS.GET_PROFILE_SUBSCRIBERS:
       
        return {
          ...state,
  
          subscribers: {
            ...action.payload,
              loading: false,
          }
        };
        case CONSTANTS.LOAD_MORE_PROFILE_SUBSCRIBERS:
       {
          let new_subscribers = {...state.subscribers}
          let prev_subs = new_subscribers.results;
          let new_subs = action.payload
          new_subs.results = prev_subs.concat(action.payload.results)
          new_subscribers = {...new_subs, loading: false,}
        return {
          ...state,
  
          subscribers: new_subscribers
        };
      }
        case CONSTANTS.TOGGLE_SUB_PROFILE:{
          console.log("toggle")
          const prop = action.payload.type
          let new_subscriptions = {...state.subscriptions}
          let new_sub = [...state.subscribers.results]
          let new_sub2 = [...state.subscriptions[prop].results]
          new_sub.forEach((sub) => {
           if (action.payload.id === sub.id){
            sub.subscribed = !sub.subscribed;
            return;
           }
          });
          new_sub2.forEach((sub) => {
            if (action.payload.id === sub.id){
             sub.subscribed = !sub.subscribed;
             return;
            }
           });
            return {
              ...state,
              subscribers: {...state.subscribers, results: new_sub},
              subscriptions: new_subscriptions
            }


         
        }
        break;
        case CONSTANTS.GET_PROFILE_SUBSCRIPTIONS:{
          const prop = action.payload.sub_type
          let new_subscriptions = {...state.subscriptions}
          new_subscriptions[prop] = {...action.payload, loading: false,}
          
            return {
              ...state,
              subscriptions: new_subscriptions
            }
          
        }
      
        case CONSTANTS.SET_PROFILE_SUBSCRIPTIONS_LOADING:{
          console.log("set")
          const prop = action.payload
          let new_subscriptions = {...state.subscriptions}
          new_subscriptions[prop] = {loading: true,}
          
            return {
              ...state,
              subscriptions: new_subscriptions
            }
          
        }
        case CONSTANTS.LOAD_MORE_PROFILE_SUBSCRIPTIONS:{
          const prop = action.payload.sub_type
          let new_subscriptions = {...state.subscriptions}
          let prev_subs = state.subscriptions[prop].results;
          let new_subs = action.payload
          new_subs.results = prev_subs.concat(action.payload.results)
          new_subscriptions[prop] = {...new_subs, loading: false,}
          
            return {
              ...state,
              subscriptions: new_subscriptions
            }
  
         
        }
    default:
      return state;
  }
}
