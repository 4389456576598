import * as React from 'react';
import styled from "styled-components";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import { api_endpoint } from '../../config';
import ProfileDetails from './ProfileDetails';

const Overlay = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
})

const Container = styled(Box)({
    width: "min(100vw, 600px)",
    height: "auto",
    backgroundColor: "white",
    padding: "1rem",
})

const Header = styled(Box)({
    display: "flex",
    width: "100%",
    height: "auto",
    flexDirection: "row",
})

const Footer = styled(Stack)({
    justifyContent: "flex-end",
})

const PreviewContainer = styled(Box)({
    width: "50%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
})

const AttributeContainer = styled(Box)({
    width: "50%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
})


const EditContainer = styled(Box)`
    display: flex;
    flex-direction: ${(props: any) => props.isMobile ? "column" : "row"};
    margin: 1rem 0;
`

const _EditProfile = (props: any) => {
    if (!props.profile.username) {
        return null;
    }
    let image_url = api_endpoint + "/media/" + props.profile.avatar.id + "/file?size=256";
    return (
        <EditContainer>
            <PreviewContainer>
                <img src={image_url} style={{ borderRadius: "50%" }} alt="avatar" />
                <Typography variant="h5">{props.profile.fullname || props.profile.username}</Typography>
                {props.profile.fullname ? <Typography variant="body1">{props.profile.username}</Typography> : null}
            </PreviewContainer>
            <AttributeContainer>
                <ProfileDetails />
            </AttributeContainer>
        </EditContainer>

    )
}

const mapStateToProps = (state) => ({
    // userid: state.auth.user.user_id,
    profile: state.auth.myprofile,
    subscription: state.auth.plan,
    loading: state.auth.loading,
    dimensions: state.dimensions,
    errors: state.errors,
    sub_loading: state.auth.plan.loading,
});

const EditProfile = connect(mapStateToProps)(_EditProfile);

const Setup = () => {
    const [page, setPage] = React.useState(0);

    return (
        <Overlay>
            <Container>
                <Header>
                    <Typography variant="h3" sx={{ width: "100%" }}>Create an account</Typography>
                    <IconButton aria-label="close" onClick={console.log}>
                        <Close />
                    </IconButton>
                </Header>
                <EditProfile />
                <Footer direction="row" spacing={1}>
                    <Button variant="contained" color="secondary">Back</Button>
                    <Button variant="contained" color="primary">Next</Button>
                </Footer>
            </Container>
        </Overlay>
    )
}

export default Setup;