import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import { getSubscriptions, removeSubscriptionGeneric } from "../actions";
import { Button } from "@mui/material";
import imgUrl from "../utils/imgUrl";
import CircularProgress from "@mui/material/CircularProgress";
import projectReducer from "../reducers/projectReducer";
import setTitle from "../utils/setTitle.js";
import { site_name } from "../config";


const Projectcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width 100%;
  flex-wrap: wrap;
  padding-bottom: 80px;
`;
const ProjectToolbar = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  text-align: center;
`;
const ProjectNavActive = styled.div`
  color: #111;
`;
const ProjectNavInActive = styled.div`
  color: #b6b6b6;
`;
const ProjectNavDash = styled.div`
  color: #111;
  width: 30px;
`;
const ProjectSpacer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  padding-bottom: 50px;
  cursor: pointer;

  &:hover {
    background-color: #e1e8f1;
  }
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const SubscriptionCard = styled(Box)({
    width: "440px",
    maxWidth: "calc(100vw - 24px)",
    height: "200px",
    padding: "0px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3);",
    display: "flex",
    flexDirection: "row",
    textDecoration: "none",
})

const ImageContainer = styled(Box)({
    width: "200px",
    height: "200px",
    maxWidth: "35vmin",
    maxHeight: "35vmin",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#EEE",
    flexShrink: 0,
})

const CircularImage = styled.img({
    width: "128px",
    height: "128px",
    maxWidth: "35vmin",
    maxHeight: "35vmin",
    borderRadius: "50%",
    backgroundColor: "#CCC"
})

const SubscriptionsDetails = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "18px",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    boxSizing: "border-box",
})

const Name = styled.div`
    font-family: "Merriweather", serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 8px;
    text-overlow: ellipsis;
`

const Description = styled.div`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
    flex-shrink: 1;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
`

function UnsubscribeButton({ type, id }) {
    const dispatch = useDispatch();

    function unsubscribe(e) {
        e.preventDefault();
        e.stopPropagation();
        dispatch(removeSubscriptionGeneric(type, id));
    }

    return (
        <ButtonContainer>
            <Button
                variant="contained"
                onClick={unsubscribe}
                sx={{
                    marginTop: "auto",
                    marginBottom: "0",
                    marginLeft: "auto",
                    marginRight: "0",
                }}
            >
                Unsubscribe
            </Button>
        </ButtonContainer>
    );
}

function UserSubscription({ data }) {
    setTitle(`Subscriptions | ${site_name}`)
    return (
        <StyledLink style={{ textDecoration: "none" }} to={"/" + data.username + "/"} >
            <SubscriptionCard>
                <ImageContainer>
                    <CircularImage
                        src={imgUrl(data.avatar, 128)}
                        alt={`avatar for ${data.username}`}
                    />
                </ImageContainer>
                <SubscriptionsDetails>
                    <Name>{data.fullname || data.username}</Name>
                    <Description>{data.description}</Description>
                    <UnsubscribeButton type="users" id={data.id} />
                </SubscriptionsDetails>
            </SubscriptionCard>
        </StyledLink>
    );
}

function ProjectSubscription({ data }) {
    return (
        <StyledLink style={{ textDecoration: "none" }} to={"/projects/" + data.id + "/" + data.name + "/"}>
            <SubscriptionCard>
                <ImageContainer style={{ backgroundImage: "url(" + imgUrl(data.image) + ")" }} />
                <SubscriptionsDetails>
                    <Name>{data.name}</Name>
                    <Description>{data.description}</Description>
                    <UnsubscribeButton type="projects" id={data.id} />
                </SubscriptionsDetails>
            </SubscriptionCard>
        </StyledLink>
    )
}

function GroupSubscription({ data }) {
    return (
        <StyledLink style={{ textDecoration: "none" }} to={"/groups/" + data.id + "/" + data.name + "/"}>
            <SubscriptionCard>
                <ImageContainer style={{ backgroundImage: "url(" + imgUrl(data.image) + ")" }} />
                <SubscriptionsDetails>
                    <Name>{data.name}</Name>
                    <Description>{data.description}</Description>
                    <UnsubscribeButton type="groups" id={data.id} />
                </SubscriptionsDetails>
            </SubscriptionCard>
        </StyledLink>
    )
}

export default function Subscriptions() {
    const subscriptions = useSelector((state: any) => state.auth.subscriptions);
    const [init, setInit] = React.useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (!init) {
            setInit(true);
            dispatch(getSubscriptions())
        }
    }, [init, setInit, dispatch]);

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", minHeight: "100%", backgroundColor: "#f6f6f6" }}>
            <ProjectToolbar>
                <Link style={{ textDecoration: "none" }} to="/">
                    <ProjectNavInActive>FEED</ProjectNavInActive>
                </Link>
                <ProjectNavDash>-</ProjectNavDash>{" "}
                <ProjectNavActive>SUBSCRIPTIONS</ProjectNavActive>{" "}
            </ProjectToolbar>
            <Stack spacing={2} direction="column" sx={{ marginBottom: "64px" }}>
                {subscriptions.results.map((subscription: any) => {
                    switch (subscription.type) {
                        case "User":
                            return <UserSubscription data={subscription.data} key={subscription.data.id} />
                        case "Project":
                            return <ProjectSubscription data={subscription.data} key={subscription.data.id} />
                        case "Group":
                            return <GroupSubscription data={subscription.data} key={subscription.data.id} />
                        default:
                            return null
                    }
                })}
                {subscriptions.loading ?
                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </div>
                    : null}
                {subscriptions.count === 0 && !subscriptions.loading ?
                    <div style={{ marginTop: "20px", marginBottom: "20px", fontSize: "18px", color: "#111" }}>You have no subscriptions.</div>
                    : null}
            </Stack>
        </div>
    )
}