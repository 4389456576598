 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'ShowDrawing',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const ShowDrawing = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M19.2,10.9h-6.8V4.1h6.8V10.9 M21.2,12.9V2.1H10.4v10.8H21.2L21.2,12.9z"/>
<path d="M8.1,12.9c2,0,3.7,1.7,3.7,3.7s-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7S6.1,12.9,8.1,12.9 M8.1,10.9c-3.1,0-5.7,2.6-5.7,5.7
	s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S11.3,10.9,8.1,10.9L8.1,10.9z"/>  </SvgIcon>
  );
};

export default ShowDrawing;