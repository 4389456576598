import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
export const Example = styled.div`
    width: 128px;
    height: 128px;
    background-color: #ff00ff;
`;

export const Example2 = styled.div`
    width: 128px;
    height: 128px;
    background-color: #00ffff;
`;

export const ExampleWithProps = styled.div`
    width: 128px;
    height: 128px;
    background-color: ${(props) => props.color};
`;

export const ElementContainer = styled.div`
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
//   width: 700px;
// margin-bottom: 10px;
// margin-top: ${(props) => (props.isMobile ? "0px" : "auto")};
// margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
// margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
// min-height: ${(props) => (props.isMobile ? "100%" : "auto")};
background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};

`;

export const TopContainer = styled.div`
// max-width: 900px;
width: ${(props) => (props.isMobile ? "100%" : "auto")};
min-height: ${(props) => (props.isMobile ? "100vh" : "auto")};
// height: 100%;
margin-left: auto;
margin-right: auto;
// margin-left: 0;
// margin-right: 0;
display: flex;
flex-direction: row;
`;

export const ElementCard = styled.div`
width: ${(props) => (props.isMobile ? "100%" : "auto")};
min-height: ${(props) => (props.isMobile ? "100%" : "auto")};

background-color: #fff;
box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
padding: ${(props) => (props.isMobile ? "32px" : "32px")};
padding-left: ${(props) => (props.isMobile ? "32px" : "32px")};
padding: ${(props) => (props.isMobile ? "32px" : "32px")};
display: flex;
flex-direction: column;
align-items: stretch;
`;

export const SettingsHeader = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const SettingsColumns = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const TextColumn = styled.div`
width: ${(props) => (props.isMobile ? "auto" : "300px")};
  padding-left: ${(props) => (props.isMobile ? "32px" : "auto")};
  padding-right: ${(props) => (props.isMobile ? "32px" : "auto")};
`;
export const PaddingContainer = styled.div`

  padding: 32px;
`;
export const ColumnMiddle = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: ${(props) => (props.isMobile ? "auto" : "100%")};
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.isMobile ? "none" : "1")};
  padding-left: ${(props) => (props.isMobile ? "0px" : "32px")};
  padding-bottom: ${(props) => (props.isMobile ? "0px" : "32px")};
  // margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  // background-color: #dd4d25;
`;
export const ColumnRight = styled.div`
  min-width: ${(props) => (props.isMobile ? "calc(100% - 64px)" : "288px")};

  padding-top: ${(props) => (props.isMobile ? "32px" : "0px")};
  padding-bottom: ${(props) => (props.isMobile ? "32px" : "32px")};
  padding-right: ${(props) => (props.isMobile ? "32px" : "32px")};
  margin-left: ${(props) => (props.isMobile || props.directUI ? "32px" : "32px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};

  display: flex;
  flex-direction: column;
`;
export const ColumnCenter = styled.div`
  min-width: ${(props) => (props.isMobile ? "100%" : "100%")};

  padding-top: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-left: ${(props) => (props.isMobile || props.directUI ? "0px" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};

  display: flex;
  flex-direction: column;
`;
export const CropPanel = styled.div`
  overflow: hidden;
  max-width: ${(props) => props.w}px;
  max-height: ${(props) => (props.isMobile ? "auto" : "calc(100vh - 225px)")};
  display: flex;
`;
export const slidein = (x1, x2) => keyframes`
     
      0% {
        transform: translateX(${x1}px);
      }
      100% {
        transform:  translateX(${x2}px);
      }
   
`;

export const PanelContainer = styled.div`
  animation: ${(props) => slidein(props.x1, props.x2)} 0.2s both;

  // margin-left: -288px;
  display: flex;
  // width: 933px;
`;
export const PanelOne = styled.div`
  min-width: ${(props) => props.w}px;
  max-width: ${(props) => props.w}px;
  height: 100%;
  // background-color: #ccc;
`;
export const PanelTwo = styled.div`
  min-width: ${(props) => props.w}px;
  max-width: ${(props) => props.w}px;
 
  height: 100%;
  // background-color: #ddd;
  overflow-y: auto;
`;
export const PanelThree = styled.div`
  min-width: ${(props) => props.w}px;
  max-width: ${(props) => props.w}px;
 
  height: 100%;
  // background-color: #ddd;
  overflow-y: auto;
`;

export const SettingsTitle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-size: 16px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 700;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "32px" : "32px")};
`;

export const CloseButtonNarrow = styled.div`
font-size: 20px;
color: #757575;
margin-top: ${(props) => (props.isMobile ? "-15px" : "-17px")};
margin-left: auto;
margin-right: ${(props) => (props.isMobile ? "17px" : "17px")};

min-width: 20px;
max-height: 20px;
cursor: pointer;
`;


export const EditItem = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 18px;
`;
export const SectionIcon = styled.div`
  margin-left: 0px;
  font-size: 24px;
`;
export const BackIcon = styled.div`
  margin-left: -9px;
  font-size: 32px;
`;
export const SectionLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  margin-left: 10px;
  color: #111;
`;
export const SectionNumber = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #cacaca;
`;
export const SectionLabel2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;

  color: #111;
`;
export const SectionContent = styled.div`
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  max-width: ${(props) => props.w}px;
  
`;
export const SectionHeader = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 8px;
`;
export const SectionHelpText = styled.div`
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
`;
export const SectionFooter = styled.div`
  margin-top: 4px;
`;

export const ButtonContainer = styled.div`
width: 100%;
margin-top: 18px;
display: flex;
justify-content: space-between;
`;


export const Dialog = styled.div`
width: ${(props) => (props.isMobile ? "100%" : (props.dialog_width ? props.dialog_width + "px" : "660px"))};
min-height: ${(props) => (props.isMobile ? "auto" : (props.dialog_height ? props.dialog_height + "px" : "455px"))};

background-color: #fff;
box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
padding-top: ${(props) => (props.isMobile ? "32px" : "32px")};
// padding-bottom: ${(props) => (props.isMobile ? "32px" : "32px")};
// padding-left: ${(props) => (props.isMobile ? "32px" : "32px")};
display: flex;
flex-direction: column;
align-items: stretch;
overflow: hidden;
`;
export const StyledButtonContainer = styled.div`
display: flex;
flex-direction: row;
width:  ${(props) => props.w}px;
margin-top: auto;
}
`;
export const StyledLeftButtons = styled.div`
   
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    margin-bottom: ${(props) => (props.isMobile ? "32px" : "0px")};
}
`;
export const StyledButtonDelete = styled.div`
   
    margin-top: 20px;
  
    
}
`;
export const StyledButton = styled.div`
    
  
    
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;