import React, { Component } from "react";
import { api_endpoint } from "../../config";
import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import styled from '@emotion/styled'
import PeopleIcon from "@mui/icons-material/People";
import { Link } from "react-router-dom";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import Button from "@mui/material/Button";
import { clearInvite, acceptInvite } from "../../actions/profileActions";
import imgUrl from "../../utils/imgUrl";
const StyledContainer = styled.div`
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: ${(props) =>
    props.isSmall ?"300px" : "360px"};
  min-height: ${(props) =>
    props.isSmall ? "300px" : "360px"};
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border: 1px solid #fff;
  border-radius: 0px;
  // padding: 1px;
  display: flex;
  flex-direction: column;
`;

const InfoTitleContainer = styled.div`
  display: flex;
  background: url("${(props) => props.src}"), url('/default_group.jpg') #ccc;
  background-position: center;
  // border-top-left-radius: 59px;
  // border-top-right-radius: 59px;
  background-size: cover;
  position: relative;
  min-height: ${(props) =>
    props.isSmall ? "253px" : "313px"};
`;
const InfoTitleName = styled.div`
  // display: flex;

  font-family: "Merriweather", serif;
  font-size: 18px;
  font-weight: 700;

  text-align: left;
  width: calc(100% - 20px);
  overflow-wrap: anywhere;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 16px;
  margin-bottom: 4px;
  color: #394b54;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
// const InfoAboutText = styled.div`
//   display: flex;

//   font-family: "Roboto", serif;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 161.5%;
//   color: #6d6d6d;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 5;
//   -webkit-box-orient: vertical;
//   margin-left: 15px;
//   margin-right: 15px;
//   margin-top: 14px;
// `;
const InfoNumbers = styled.div`
  display: flex;
  width: 100%;
  color: #394b54;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 12px;
`;
const LeftNumber = styled.div`
  margin-left: 15px;
  display: flex;
  color: #111;
`;
const RightNumber = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 45px;
  color: #111;
`;
const NumberLabel = styled.div`
  margin-left: 5px;
  margin-top: 6px;
  color: #394b54;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: auto;
`;
// const InfoEst = styled.div`
//   font-family: "Roboto", serif;
//   font-size: 12px;
//   font-weight: 300;
//   font-style: italic;
//   text-align: center;
//   width: 100%;
//   color: #757575;
// `;
const ButtonSet = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
`;
const InfoInstruction = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
`;
class GroupThumb extends Component {
  rejectInvite = () => {
    // clear invite
    this.props.dispatch(
      clearInvite({ from_groups: true, group: this.props.id })
    );
  };
  acceptInvite = () => {
    this.props.dispatch(
      acceptInvite({ from_groups: true, group: this.props.id })
    );
  };
  render() {
    let memberlabel;
    let projectpabel;
    if (this.props.total_members === 1) {
      memberlabel = this.props.total_members + " member";
    } else {
      memberlabel = this.props.total_members + " members";
    }
    if (this.props.total_projects === 1) {
      projectpabel = this.props.total_projects + " project";
    } else {
      projectpabel = this.props.total_projects + " projects";
    }
    let image_url = ""
   
    if (this.props.image_override) {
      image_url = this.props.image_override;
    } else if (this.props.src) {
      image_url = api_endpoint+"/media/"+this.props.src.id+"/file?size=512";
    } else if (this.props.image) {
      try {
        image_url = imgUrl(this.props.image.id || this.props.image, 512);
      } catch (err) {
        image_url = imgUrl(this.props.image, 512);
      }
    } else {
      image_url = null;
    }
    if (this.props.viewer_status === "IN") {
      return (
        <StyledContainer>
          <InfoTitleContainer isSmall={this.props.isSmall} src={image_url}></InfoTitleContainer>
          <InfoTitleName title={this.props.name}>
            {this.props.name}
          </InfoTitleName>
          <InfoNumbers>
            <LeftNumber>
              <PeopleIcon />
              <NumberLabel>{memberlabel}</NumberLabel>
            </LeftNumber>
            <RightNumber>
              <CardTravelOutlinedIcon />
              <NumberLabel>{projectpabel}</NumberLabel>
            </RightNumber>
          </InfoNumbers>
          <InfoInstruction>
            You have been invited to join this group
          </InfoInstruction>
          <ButtonSet>
            <Button
              onClick={() => {
                this.rejectInvite();
              }}
              disableElevation
              variant="contained"
              color="secondary"
              style={{ minWidth: "100px" }}
            >
              Ignore
            </Button>
            <Button
              onClick={() => {
                this.acceptInvite();
              }}
              disableElevation
              variant="contained"
              color="primary"
              style={{
                marginLeft: "auto",
                marginRight: "10px",
                minWidth: "100px",
              }}
            >
              Join
            </Button>
          </ButtonSet>
          <InfoContainer>
            {/* <InfoEst>Formed {monthYear(this.props.created_at)}</InfoEst> */}
          </InfoContainer>
        </StyledContainer>
      );
    } else {
      return (
        <Link
          style={{ textDecoration: "none" }}
          to={
            "/group/" +
            this.props.id +
            "/" +
            this.props.name.replace(/\s+/g, "-").toLowerCase() + "/"
          }
        >
          <StyledContainer isSmall={this.props.isSmall}>
            <InfoTitleContainer isSmall={this.props.isSmall} src={image_url}></InfoTitleContainer>
            <InfoTitleName title={this.props.name}>
              {this.props.name}
            </InfoTitleName>
            <InfoNumbers>
              <LeftNumber>
                <PeopleIcon />
                <NumberLabel>{memberlabel}</NumberLabel>
              </LeftNumber>
              <RightNumber>
                <CardTravelOutlinedIcon />
                <NumberLabel>{projectpabel}</NumberLabel>
              </RightNumber>
            </InfoNumbers>

            <InfoContainer>
              {/* <InfoEst>Formed {monthYear(this.props.created_at)}</InfoEst> */}
            </InfoContainer>
          </StyledContainer>
        </Link>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(GroupThumb));
