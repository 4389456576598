import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { validateEmail, resendEmail } from "../../actions/authActions";
import styled from '@emotion/styled'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
const StyledloginContainer = styled.div`
  display: block;
  margin: 16.66667vh auto 16.66667vh auto;
  text-align: center;
`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
  margin-left: auto;
  margin-right: auto;
`;
const StyledHeaderText = styled.div`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #111;
`;

const StyledInput = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;    
    width: 252px;
    background-color: #0073ec;
    color: white;
    height: 32px;
    border-radius: 2px;
  
    padding-right: 30px;
    padding-left: 30px;
    margin: 10px 24px 10px 24px;
  
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
    
}
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;
const LoginImage = styled.div`
  display: inline-block;
  margin: 0 auto 40px auto;

  width: 300px;
  min-height: 120px;

`;
class Maintenance extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
    };
  }

  componentDidMount() {
    setTitle("Maintenance | " + site_name)
  }

  render() {

    return (
      <StyledloginContainer>
        <LoginImage> <img width="250px" alt="logo" src="/Logo Centered LARGE.png"></img></LoginImage>
        <StyledHeaderText>
          The Sequentially site is down for maintenance.
          <br />

          <br />
          <br />
        </StyledHeaderText>

      </StyledloginContainer>
    );

  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  validation_message: state.auth.validation_message,
  validation_state: state.auth.validation_state,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps)(
  withRouter(Maintenance)
);
