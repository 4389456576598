import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { api_endpoint } from "../config";
import { withRouter } from "react-router-dom";
import { keyframes, css } from "@emotion/react";
import { getProjectsforGroup, saveProjectsToGroup, loadmoreProjectsforGroup } from "../actions/groupActions";
import {
  newProject,
  uploadNewProject,
  saveProject,
  toggleSharingLink,
  uploadSaveProject,
  deleteProject,
} from "../actions/projectActions";
import mimetype from "../config/mimetype";
import { getProjectCategories } from "../actions/uiActions";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { getProjectContent } from "../actions/projectActions";
import { getMemberships } from "../actions/profileActions";
import ProjectThumb from "./thumbs/ProjectThumb";


import { setMenu } from "../actions/uiActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { Autocomplete } from "@mui/material";
import { fetchCategories } from "../actions";
import CloseIcon from "../custom-icons/CloseIcon";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import TitleIcon from "@mui/icons-material/Title";
import InventoryIcon from '@mui/icons-material/Inventory';
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import HdrWeakOutlinedIcon from "@mui/icons-material/HdrWeakOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { ThemeConsumer } from "styled-components";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import imgUrl from "../utils/imgUrl";
import {
    ElementContainer,
    TopContainer,
    SettingsHeader,
    SettingsColumns,
    ColumnMiddle,
    ColumnCenter,
    CropPanel,
    PanelContainer,
    PanelOne,
    PanelTwo,
    SettingsTitle,
    CloseButtonNarrow,
    EditItem,
    SectionIcon,
    SectionLabel,
    SectionNumber,
    SectionContent,
    SectionHeader,
    BackIcon,
    SectionLabel2,
    SectionFooter,
    SectionHelpText,
    ButtonContainer,
    StyledButtonContainer,
    StyledLeftButtons,
    StyledButtonDelete,
    StyledButton,
    Dialog
  } from "./styles/MenuComponents.js";


const SmallElement = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "100")}px;
  height: ${(props) => (props.isMobile ? "40" : "100")}px;
  background-color: #111;
  border: 1px solid #fff;

  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  //   display: flex;
  //   flex-direction: column;
  position: static;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${(props) => "url(" + props.src + ")"};
`;
const ImageSet = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;
const RadioHelp = styled.div`
  font-size: 12px;
  margin-left: 30px;
  margin-top: -10px;
  color: #757575;
`;
const GroupHelp = styled.div`
  font-size: 12px;

  color: #757575;
`;
const SingleImage = styled.div`
  padding: 3px;
  border: 1px solid #ccc;
  margin: 2px;
  height: 75px;
  cursor: pointer;
`;

const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;

const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;

 
  // max-height: 100%;
  // width: 100%;
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   //   width: 700px;
//   // margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "100%" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const TopContainer = styled.div`
//   // max-width: 900px;
//   width: ${(props) => (props.isMobile ? "100%" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "100vh" : "auto")};
//   height: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   // margin-left: 0;
//   // margin-right: 0;
//   display: flex;
//   flex-direction: row;
// `;


const ConfirmationCard = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  min-height: ${(props) => (props.isMobile ? "100%" : "auto")};

  background-color: #fff;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;


const FormLabelCustom = styled.div`
   
   font-size: 14px;
   color: rgba(0, 0, 0, 0.54);
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  
    
}
`;

const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
// const ColumnLeft = styled.div`
//   padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
//   padding-right: ${(props) => (props.isMobile ? "0px" : "15px")};
//   width: ${(props) => (props.isMobile ? "100%" : "360px")};
//   padding-top: 24px;
//   height: 100%;
// `;
// const ColumnRight = styled.div`
//   width: ${(props) => (props.isMobile ? "100%" : "270px")};
//   //   height: 100%;

//   padding-left: ${(props) => (props.isMobile ? "0px" : "15px")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
//   //   background-color: #dadada;
// `;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ShareLinkBox = styled.div`
 border: 1px solid #ccc;
 font-size: 0.75rem;
 margin-left: 30px;
 padding: 5px;
 word-break: break-all;
`;
const ShareLinkButtons = styled.div`

 margin-left: 30px;
 
`;
const ChipGroup = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% )")};
  display: flex;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
  // margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: -17px;
`;
// const SettingsHeader = styled.div`
//   display: flex;
// `;
// const SettingsColumns = styled.div`
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
//   min-height: ${(props) => (props.isMobile ? "calc(100vh - 133px)" : "auto")};
// `;


const CoverImage = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const CoverImageSmall = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 8px;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButtonsCondensed = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-top: 3px;
  height: 90px;
}
`;
const Ortext = styled.div`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;
const OrtextC = styled.div`
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 0px;
  width: 100%;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const ConfirmationWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: absolute;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;

const PopupDialog = styled.div`
   margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
 
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight2 = styled.div`

    margin-top: 20px;

    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    
}
`;
const StyledButtonLeft = styled.div`
  // margin-left: 20px;

  padding-right: ${(props) => (props.isMobile ? "none" : "30px")};
  margin-left: ${(props) => (props.isMobile ? "auto" : "none")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-top: 20px;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-top: 5px;
  margin-left: 15px;
`;

const StyledButtonRight = styled.div`
  // padding-right: 20px;
  margin-top: 20px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
`;

const PaddingDiv = styled.div`
margin-top: auto;
 padding-left: 32px;
 padding-right: 32px;
 padding-bottom: 32px;
`;
const CheckBoxContainer = styled.div`
border-top: 1px solid rgb(224, 224, 224);
border-bottom: 1px solid rgb(224, 224, 224);
 display: flex;
 flex-direction: column;
height: 300px;
 max-height: 300px;
 overflow-y: auto;
 width: 350px;
`;
const Checkboxes = styled.div`
 padding: 32px;
 display: flex;
 flex-direction: column;

`;
const NoProjects = styled.div`
 padding: 32px;
 display: flex;
 flex-direction: column;

`;
const CheckLabel = styled.div`
  display: flex;
`;

const CheckName = styled.div`
margin-left: 8px;
margin-top: 3px;
`;

const CheckImage = styled.div`
background-image: url(${(props) => props.src}), url('/default_project.jpg');
height: 30px;
width: 30px;
background-position: center;
background-size: cover;
`
class GroupProjectAdd extends Component {
  constructor(props) {
    super(props);
    this.savePage = this.savePage.bind(this);
   
    this.state = {
        projects_loading: true,
      projects: {}
    };
  }
 
  savePage = (close) => {
    //console.log("save project");

    let selectedprojects = [];
    this.state.projects.results.forEach((project) => {
      
      if (project.checked) {
        selectedprojects.push(project.id);
      }
    });

    
      this.props.dispatch(saveProjectsToGroup(this.props.groupid, selectedprojects));
   
  
   
     
   
    this.handleCloseDialog()
  };
 
  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
    if (this.state.showDeleteConfirmation === true) {
      this.setState({ showDeleteConfirmation: false });
    }
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
   
   
    // this.props.history.replace(target);
  }
  handleCloseDialogCheck(){
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      this.handleCloseDialog()
    }

  }
  
 
  
  doNothing(e) {
    e.stopPropagation();
  }
  componentDidMount() {
    this.props.dispatch(getProjectsforGroup(this.props.auth.myprofile.id, this.props.groupid));
  }
  componentDidUpdate() {
    const {
      projects,
      projects_loading,
     
      dimensions,
    } = this.props;
    //console.log("update");
    //console.log(groups);
   
    if (projects_loading) {
      if (!this.state.projects_loading){
        this.setState({ projects_loading: true });
      }
    //   this.setState({ projects_loading: projects_loading });
    }else{
        if (this.state.projects_loading ){
            this.SetInitialSelection(this.props.projects)
           
        }
        
    }

  }
  SetInitialSelection(projects){
    projects.results.map(
        (project, index) => {
           
           
           
            project.checked = false
        })
        this.setState({ projects: projects }); 
        this.setState({ projects_loading: false });
  }
  SelectProject(id, index){
  
    let temp_projects = this.state.projects
    temp_projects.results[index].checked = true
   
    this.setState({ projects: temp_projects });
  }
  loadMore(link){
  
    this.props.dispatch(loadmoreProjectsforGroup(link));
  }
 
  render() {
    const {
     
      dimensions,
     
     
     
    } = this.props;
   

    return (
      <div style={{ height: "100%", position: "relative" }}>
         
       
       
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={425} dialog_width={"auto"}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Share projects with this group
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialogCheck();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
              <ColumnCenter directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                  <CheckBoxContainer>
                    <Checkboxes>
                  {this.state.projects_loading ? (
                                        <div
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}>
                                          <CircularProgress />
                                        </div>
                                      ) : (
                                        this.state.projects.results.map(
                                            (project, index) => {
                                             
                                            
                                              return (
                                                <FormControlLabel
                                                style={{ marginBottom: "8px"}}
                                                key={index}
                                                control={
                                                  <Checkbox
                                                    disableRipple
                                                    color="primary"
                                                    checked={project.checked}
                                                    onChange={e => { this.SelectProject(project.id, index) }}
                                                   
        
                                                    name=""
                                                    id=""
                                                    key=""
                                                  />
                                                }
                                                label={(
                                                  <CheckLabel>
                                                    <CheckImage  src={project.image && imgUrl(project.image.id, 256)}/>
                                                    <CheckName>{project.name}</CheckName>
                                                   
                                                  </CheckLabel>
                                                )}
                                              // style={{ width: "calc(100%-64px)" }}
                                              />


                                              )})
                                                              )}
                            </Checkboxes>
                            {this.state.projects.results && this.state.projects.results.length === 0 ? (<NoProjects>There are no projects to share with this group</NoProjects>):("")}        
                     
                            {this.state.projects.next ? ( 
                                              <Button
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  this.loadMore(this.state.projects.next);
                                                }}
                                              >
                                                Load More Projects...
                                              </Button>
                                            ):("") }
                    </CheckBoxContainer>
               <PaddingDiv>
              <StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>
                          <StyledButtonDelete>
                            <Button
                              disableElevation
                              variant="contained"
                              color="charcoal"
                              style={{ minWidth: "128px" }}
                              onClick={() => {
                                this.handleCloseDialogCheck();
                              }}>
                              Cancel
                            </Button>
                          </StyledButtonDelete>
                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.savePage();
                            }}>
                            Add Projects
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>
                      </PaddingDiv>   
              </ColumnCenter>
             
             
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  projects: state.groups.projects_for_groups.projects,
  projects_loading: state.groups.projects_for_groups.loading,
  project: state.project,
  project_loading: state.project.loading,
  elements: state.project.current_project.elements,
  userid: state.auth.user.user_id,
  creator: state.auth.myprofile,
  auth: state.auth,
  categories_loading: state.fetched.categories.loading,
  categories: state.fetched.categories.data.results,
  groups: state.profile.groups,
  groupid: state.groups.current_group.id,
  groups_loading: state.profile.groups_loading,
  dimensions: state.dimensions,
  _public: state.public,
  share_link_enabled: state.share_link_enabled,
});
export default connect(mapStateToProps)(withRouter(GroupProjectAdd));
