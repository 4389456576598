import * as React from 'react';
import styled from "styled-components";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { clearAlert, setAlertVisibility } from "../actions/alertActions";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { debug } from '../config'


export default function AlertBox() {
    const dispatch = useDispatch();
    const alerts = useSelector((state: any) => state.alerts);

    return (
        <Stack
            sx={{
                width: 'min(100vw, 400px)',
                maxHeight: 'calc(100vh - 64px)',
                position: 'fixed',
                top: '64px',
                right: '36px',
                zIndex: '100000',
                overflow: 'auto',
            }}
            spacing={1}
            direction="column"
        >
            {alerts.map(a => {
                const data = a.data;
                let title, message, file;
                if (data instanceof Error) {
                    title = data["title"] || data.name || "An error occurred.";
                    message = data.message;
                    // file = `${data.fileName}:${data.lineNumber}`;
                } else if (data instanceof Object) {
                    title = data.title || "Alert";
                    message = data.message || "[no content]"
                } else {
                    title = a.severity.charAt(0).toUpperCase() + a.severity.slice(1);
                    message = data;
                }

                if (Date.now() - a.timestamp > (a.duration + 500)) {
                    return null
                } else if (!a.show && !a.hide) {
                    setTimeout(() => {
                        dispatch(setAlertVisibility(a.id, true));
                    }, 1);
                }
                return (
                    <Collapse in={a.show} key={a.id}>
                        <Alert
                            // key={a.id}
                            severity={a.severity}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        dispatch(setAlertVisibility(a.id, false));
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{
                                mb: 1,
                                border: '2px solid rgba(0, 0, 0, 0.5)',
                            }}
                        >
                            {title ? (
                                <AlertTitle>
                                    {title}
                                </AlertTitle>
                            ) : null}
                            {message}
                            {debug && file ? (
                                <code>file</code>
                            ) : null}
                        </Alert>
                        {/* <Box
                            sx={{
                                width: (Date.now() - a.timestamp) / a.timeout,
                                height: '4px',
                                bgcolor: 'rgba(16, 16, 16, 0.8)',
                                position: 'relative',
                                bottom: '0px',
                                left: '0px',
                            }}
                        ></Box> */}
                    </Collapse>
                )
            })}
        </Stack >
    )
}
