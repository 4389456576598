import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import * as alertActions from "../actions/alertActions";
import { Typography } from '@mui/material';
import ProfileSetup from './ProfileSetup/ProfileSetup';
import React, { useState, useEffect } from 'react';
import { useAnalytics } from "use-analytics";
import styled from "@emotion/styled";
import {
    Example,
    Example2,
    ExampleWithProps
} from "./styles/MenuComponents.js";

import Video from "./VideoPlayer.js";
import { Ribbons } from './marketing_pages/Ribbon';

const VideoContainer = styled.div`
    display: flex;
    width: 720px;
    height: 480px;
`


const Debug = (props) => {
    const dispatch = useDispatch();
    const analytics = useAnalytics();

    const [lerp, setLerp] = useState(1);

    const handleClick = (s) => {
        dispatch(alertActions.showAlert("This is a test alert", s));
    };

    const handleErrorClick = () => {
        try {
            throw new Error("This is a test error");
        } catch (error) {
            dispatch(alertActions.showError(error, "error"));
        }
    };

    const handleAnalyticsClick = (e) => {
        analytics.track('test', {
            text: e.target.innerText,
            debug_mode: true
        })
    };

    return (
        <React.Fragment>
            
            <Ribbons val={lerp} />
            {/* <ProfileSetup /> */}
            <div style={{padding: "0px 0px", paddingTop: "260px", backgroundColor: "#f5f5f5", display: "flex", justifyContent: "center"}}>
                {/* <Typography sx={{fontFamily: 'Roboto Slab'}} variant='h1' gutterBottom align='center' color='#ffffff'>learn as it's made</Typography> */}
                {/* <img align="center" width="2400px" alt="logo" src="/desktop.png"></img> */}
                <img align="center" width="1448px" alt="logo" src="/page3.png"></img>
                {/* <img width="1448px" alt="logo" src="/Metal_Alpha.png"></img> */}
                {/* <pre><code>{JSON.stringify(props, null, 2)}</code></pre> */}
                </div>
            
        </React.Fragment>

    );
}

export default Debug;