import React, { Component } from "react";
import styled from "styled-components";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setMenu } from "../../actions/uiActions";
import { api_endpoint } from "../../config";
import PersonThumb from "./PersonThumb";
import imgUrl from "../../utils/imgUrl";

const sizes = {
  small: { x: "192px", y: "192px" },
  default: { x: "252px", y: "314px" },
  large: { x: "320px", y: "calc(320px + 58px)" }, // 58px is the height of the title
}

const ProjectLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  
  cursor: ${(props) => props.preview_only === "true" ? "default" : "pointer"};
 
`;
const StyledContainer = styled.div`
  //   margin: 10px;
  // position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: ${(props) => props.size ? sizes[props.size].x : sizes.default};
  min-height: ${(props) => props.size ? sizes[props.size].y : sizes.default};
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 10px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  border-radius: 0px;
  padding: 1px;
  display: flex;
  // cursor: pointer;
  flex-direction: column;
  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const StyledContainerPreview = styled.div`
  //   margin: 10px;

  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: 280px;
  height: 280px;
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  // border: 1px solid #dddddd;
  border-radius: 0px;
  padding: 1px;
  display: flex;
  flex-direction: column;
  // &:hover {
  //   border: 1px solid #777;
  // }
`;
const StyledVisual = styled.div`
  margin: 0%;
  width: 100%;
  height: 80%;
  border-radius: 1px;
  //   background-color: #ccc;
  background: url("${(props) => props.src}"), url('/default_project.jpg') #ccc;

  background-position: center;
  background-size: cover;
`;

const PreviewPic = styled.div`
  background-color: #ccc;
  background-image: url(${(props) => props.src}), url('/default_project.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  box-sizing: border-box;
`;
// background-image: ${(props) =>
//   props.src
//     ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0.6 ) 100%), url("' +
//     props.src +
//     '")'
//     : "#ccc"};

const ProjectLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px 8px;
  color: #fff;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: space-between;
  flex-grow: 1;
`;
const Categories = styled.div`
  font-size: 11px;
  color: #fff;
  width: 100%;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
`;
const ProjectLabelBottom = styled.div`
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  // padding-left: 2.5%;
  // padding-right: 2.5%;
  // justify-content: center;
  align-items: center;
  // padding-top: 6.5%;
  padding: 8px 12px;
  box-sizing: border-box;
`;
const ProjectLabelBottomSingle = styled.div`
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  // padding-left: 2.5%;
  // padding-right: 2.5%;
  // justify-content: center;
  align-items: center;
  // padding-top: 6.5%;
  padding: 20px 10px;
  box-sizing: border-box;
`;
const NameAndAuthor = styled.div`
  display: flex;
  flex-direction: column; 
  margin-left: 12px;
  max-width: calc(100% - 64px);
`
const LeftColumn = styled.div`
  width: 50%;
  diplay: flex;
  flex-direction: column;
`;
const RightColumn = styled.div`
  // width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;
const ProjectName = styled.div`
  font-family: "Merriweather", serif;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  font-size: 16px;
  // margin-bottom: 2px;
  color: #383838;
  text-decoration: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;
const ProjectNameSingle = styled.div`
  font-family: "Merriweather", serif;
 
  font-weight: bold;
  font-size: 18px;
  // margin-bottom: 2px;
  color: #383838;
  text-decoration: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
`;
const UserAvatar = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #008EFF;
  background-image: ${(props) => props.src ? 'url("' + props.src + '")' : "url('/default_icon.png')"};
  background-position: center;
  background-size: cover;
`
const UserName = styled.div`
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #888;
`;
const Tags = styled.div`
  font-size: 11px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-top: 2px;
  color: #4a8de0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const ElementNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.66);
  padding: 2px 6px;
  border-radius: 12px;
`;
const CategoryName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  background-color: rgba(0, 0, 0, 0.66);
  padding: 3px 8px;
  border-radius: 12px;
  margin-right: 4px;
`;
class ProjectThumb extends Component {
  handleLoginMenu() {
    this.props.dispatch(setMenu(true, 13));
  }
  render() {
    const { creator } = this.props;
    let linkurl = "";
    //console.log(this.props.group);
    if (this.props.group) {
      linkurl =
        "/project/" +
        this.props.id +
        "/" +
        this.props.name.replace(/\s+/g, "-").toLowerCase() +
        "/group/" +
        this.props.group +
        "/";
    } else {
      linkurl =
        "/project/" +
        this.props.id +
        "/" +
        this.props.name.replace(/\s+/g, "-").toLowerCase() +
        "/";
    }
    // //console.log(this.props.cat)
    let cat_list = "";
    let cat_data = []
    if (this.props.cat) {
      cat_data = this.props.cat
    } else if (this.props.category) {
      cat_data = this.props.category
    }
    if (cat_data && cat_data.length > 0) {
      cat_data.forEach((cat, index) => {
        if (index === 0) {
          cat_list = cat.name;
        } else {
          cat_list = cat_list + ", " + cat.name;
        }
      });
    }
    let image_url;
    if (this.props.image_override) {
      image_url = this.props.image_override;
    } else if (this.props.src) {
      image_url = this.props.src;
    } else if (this.props.image) {
      try {
        image_url = imgUrl(this.props.image.id || this.props.image, 512);
      } catch (err) {
        image_url = imgUrl(this.props.image, 512);
      }
    } else {
      image_url = null;
    }

    let avatar_url;
    if (creator && creator.avatar) {
      if (creator.avatar.id) {
        avatar_url = imgUrl(creator.avatar.id, 256);
      } else {
        avatar_url = imgUrl(creator.avatar, 256);
      }
    }

    // let creator = null;
    // if (this.props.creator) {
    //   creator = this.props.creator.fullname || this.props.creator.username;
    // }
    // console.log(this.props.src)

    let linkAttrs = {
      to: linkurl,
    };

    // AUTHDIALOG: if not logged in, show login dialog
    if (!this.props.isAuth) {
      // linkAttrs.onClick = this.handleLoginMenu.bind(this);
      // linkAttrs.to = "#";
    }

    if (this.props.nolink) {
      linkAttrs.to = "#";
    }

    if (this.props.preview_only) {
      linkAttrs = {
        to: "#",
      };
    }

    return (
      <ProjectLink {...linkAttrs} preview_only={String(this.props.preview_only)}>
        <StyledContainer noshadow={this.props.noshadow} size={this.props.size || "default"}>
          <PreviewPic src={image_url}>
            <ProjectLabel>
              {typeof this.props.count === "number" ? (
                <ElementNumber>
                  {this.props.count}
                  <PhotoLibraryIcon
                    fontSize="inherit"
                    style={{
                      marginLeft: "3px",
                      fill: "#fff",
                    }}
                  />
                </ElementNumber>
              ) : null}
              <Categories>{cat_data.map((cat, index) => (<CategoryName key={"cat"+index}>{cat.name}</CategoryName>))}</Categories>
            </ProjectLabel>
          </PreviewPic>
          {/* <StyledVisual src={image_url}></StyledVisual> */}
          {this.props.size === "small" || this.props.myproject ? (
            <ProjectLabelBottomSingle>
              <ProjectNameSingle>{this.props.name}</ProjectNameSingle>
            </ProjectLabelBottomSingle>
          ) : (
            <ProjectLabelBottom>
              <UserAvatar src={avatar_url} />
              <NameAndAuthor>
                <ProjectName>{this.props.name}</ProjectName>
                <UserName>{creator.fullname || creator.username}</UserName>
              </NameAndAuthor>
            </ProjectLabelBottom>
          )}
        </StyledContainer>
      </ProjectLink>
    );


    //   if (!this.props.preview_only) {
    //     return (
    //       <div>
    //         {" "}
    //         {this.props.isAuth ? (
    //           <ProjectLink to={linkurl}>
    //             <StyledContainer noshadow={this.props.noshadow}>
    //               <PreviewPic src={image_url}>
    //                 <ProjectLabel>
    //                   <LeftColumn>
    //                     {this.props.myproject ? (
    //                       ""
    //                     ) : (
    //                       <UserName>{creator}</UserName>
    //                     )}
    //                   </LeftColumn>
    //                   <RightColumn>
    //                     <ElementNumber>
    //                       {this.props.count}
    //                       <PhotoLibraryIcon
    //                         fontSize="inherit"
    //                         style={{
    //                           marginLeft: "3px",
    //                           marginBottom: "-1px",
    //                           fill: "#fff",
    //                         }}
    //                       />
    //                     </ElementNumber>
    //                   </RightColumn>
    //                 </ProjectLabel>
    //               </PreviewPic>
    //               {/* <StyledVisual src={image_url}></StyledVisual> */}
    //               <ProjectLabelBottom>
    //                 <Tags>{cat_list}</Tags>
    //                 <ProjectName>{this.props.name}</ProjectName>
    //                 <PersonThumb {...this.props.creator} size="inline" />
    //               </ProjectLabelBottom>
    //             </StyledContainer>
    //           </ProjectLink>
    //         ) : (
    //           <StyledContainer
    //             onClick={() => {
    //               this.handleLoginMenu();
    //             }}
    //             noshadow={this.props.noshadow}>
    //             <PreviewPic src={image_url}>
    //               <ProjectLabel>
    //                 <LeftColumn>
    //                   <UserName>{creator}</UserName>
    //                 </LeftColumn>
    //                 <RightColumn>
    //                   <ElementNumber>
    //                     {this.props.count}
    //                     <PhotoLibraryIcon
    //                       fontSize="inherit"
    //                       style={{
    //                         marginLeft: "3px",
    //                         marginBottom: "-1px",
    //                         fill: "#fff",
    //                       }}
    //                     />
    //                   </ElementNumber>
    //                 </RightColumn>
    //               </ProjectLabel>
    //             </PreviewPic>
    //             {/* <StyledVisual src={image_url}></StyledVisual> */}
    //             <ProjectLabelBottom>
    //               <Tags>{cat_list}</Tags>
    //               <ProjectName>{this.props.name}</ProjectName>
    //               <PersonThumb {...this.props.creator} size="inline" />
    //             </ProjectLabelBottom>
    //           </StyledContainer>
    //         )}
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <StyledContainerPreview>
    //         <PreviewPic src={this.props.src}>
    //           <ProjectLabel>
    //             <LeftColumn>
    //               <UserName>{creator}</UserName>
    //             </LeftColumn>
    //             <RightColumn>
    //               <ElementNumber>
    //                 {this.props.count}
    //                 <PhotoLibraryIcon
    //                   fontSize="inherit"
    //                   style={{
    //                     marginLeft: "3px",
    //                     marginBottom: "-1px",
    //                     fill: "#fff",
    //                   }}
    //                 />
    //               </ElementNumber>
    //             </RightColumn>
    //           </ProjectLabel>
    //         </PreviewPic>
    //         {/* <StyledVisual src={image_url}></StyledVisual> */}
    //         <ProjectLabelBottom>
    //           <Tags>{cat_list}</Tags>
    //           <ProjectName>{this.props.name}</ProjectName>
    //           <PersonThumb {...this.props.creator} size="inline" />
    //         </ProjectLabelBottom>
    //       </StyledContainerPreview>
    //     );
    //   }
  }
}
const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(ProjectThumb);
