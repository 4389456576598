import React, { Component } from "react";


class Circ extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
 

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let x, y, w, h;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
       const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }

      const r = Math.min(h / 2, w / 2);
      const rx = w / 2;
      const ry = h / 2;
     
      return (
        <g key={obj.id}>
          {obj.id === objSelect ? (
            <g>
              <ellipse
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
               
                cx={x + rx}
                cy={y + ry}
                rx={rx}
                ry={ry}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
              <ellipse
                cx={x + rx}
                cy={y + ry}
                rx={rx}
                ry={ry}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={ w}
                height={h}
                fill="none"
                stroke="#2196F3"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x}
                y={y}
                width={ w}
                height={ h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth="2%"
              />
              <rect
              
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
            
              >
              <ellipse
                cx={x + rx}
                cy={y + ry}
                rx={rx}
                ry={ry}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <ellipse
                cx={x + rx}
                cy={y + ry}
                rx={rx}
                ry={ry}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default Circ;
