import React, { Component } from "react";
import styled from "styled-components";
import friendlyTimeCalc from "../utils/timeAgo";
import { connect } from "react-redux";
import CommentInput from "./CommentInput";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from '@mui/icons-material/Save';
import {
  saveOrder
} from "../actions/projectActions";
const SingleCommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const AuthorImage = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
`;
const AnnotationNumber = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: #2794f3;
  text-align: center;
  line-height: 24px;
`;
const CommentTextContainer = styled.div`
  min-width: 100px;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
`;
const AuthorImageContainer = styled.div`
  height: 100%;
  margin-left: 12px;
  margin-top: 20px;
  margin-right: 10px;
`;
const CommentEditMode = styled.div`
  width: 100%;
  margin-top: 5px;
`;
const AuthorStyle = styled.div`
  font-weight: 500;
`;
const DateStyle = styled.div`
  color: #8e8e8e;
  margin-top: 3px;
  display: flex;
`;
const EditButton = styled.div`
  color: #8e8e8e;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
`;
const ContentStyle = styled.div`
  margin-top: 3px;
  display: flex;
`;
const TimeSpacer = styled.div`
  width: 20px;
  height: 20px;
`;
const EditControls = styled.div`
  display: flex;
`;
const ReadControls = styled.div`
  display: flex;
 
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size: 14px;

`;
const ReadDate = styled.div`
  margin-top: 15px;
`;
const StyledFields = styled.div`
  width: 300px;
  display: flex;
  margin-top: 12px;
`;
class ProjectChangeDateElement extends Component {
  constructor() {
    super();
    this.state = {
      element: {},
      editMode: false,
      date: "",
      time: "",
      combined: ""
    };
    this.handleEditMode = this.handleEditMode.bind(this);
  }

  componentDidMount() {
    const {
      element
      } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
    const local_date = new Date(element.element_date);
    const day = ("0" + local_date.getDate()).slice(-2);
    const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
    let hour = local_date.getHours();
    let min = local_date.getMinutes();
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    const local_date_state =
      local_date.getFullYear() + "-" + month + "-" + day;
    const local_time_state = hour + ":" + min;
    const elementdate = month + "/" + day + "/"+local_date.getFullYear() + " " + local_time_state
    this.setState({ combined: elementdate });
    this.setState({ date: local_date_state });
    this.setState({ time: local_time_state });
    this.setState({ element: element });
  }
  static getDerivedStateFromProps(props, state) {

    if (props.element !== state.element) {
      const element = props.element
      const local_date = new Date(element.element_date);
      const day = ("0" + local_date.getDate()).slice(-2);
      const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
      let hour = local_date.getHours();
      let min = local_date.getMinutes();
      hour = (hour < 10 ? "0" : "") + hour;
      min = (min < 10 ? "0" : "") + min;
      const local_date_state =
        local_date.getFullYear() + "-" + month + "-" + day;
      const local_time_state = hour + ":" + min;
      const elementdate = month + "/" + day + "/"+local_date.getFullYear() + " " + local_time_state
      // let switchMode = false
      // if (!props.allowedit && this.state.editMode){

      // }else{

      // }
      return {
        element: props.element,
        date: local_date_state,
        time: local_time_state,
        combined: elementdate,
        editMode: false
      };
    }
    return null;
  }
  handleEditMode(state) {
    this.setState({ editMode: state });
    // if (this.state.editMode){
    //   this.setState({ editMode: false });
    // }else{

    // }
  }
  handleSave(state) {
    const {
      element,
     
      } = this.props;
    this.setState({ editMode: false });
    const date = new Date(this.state.date + "T" + this.state.time).toISOString();
    this.props.dispatch(
      saveOrder(
          element.description,
          date,
          element.element_type,
          element.id,
          element.dimension_unit,
          element.dimension_x,
          element.dimension_y,
          element.dimension_z,
          element.link_url,
          element.link_name
      )
    );
  }
  handleChangeDate = (newValue) => {
    //console.log(this.state.date);
    //console.log(newValue);
    this.setState({ date: newValue });
  };
  handleChangeTime = (newValue) => {
    //console.log(newValue);
    this.setState({ time: newValue });
  };
  render() {
    const {
    element,
    editmode
    } = this.props;

    const local_date = new Date(element.element_date);
    const day = ("0" + local_date.getDate()).slice(-2);
    const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
    let hour = local_date.getHours();
    let min = local_date.getMinutes();
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    const local_date_state =
        month + "/" + day + "/"+local_date.getFullYear();
    const local_time_state = hour + ":" + min;
  
    const elementdate = local_date_state + " " + local_time_state

    if (this.state.editMode) {
      return (
        <EditControls>
          <StyledFields>
           <FormControl fullWidth variant="outlined">
        <TextField
          size="small"
          hiddenLabel="true"
          id="element_date"
         //  label="Date"
          type="date"
          value={this.state.date || ""}
          onChange={(event) =>
            this.handleChangeDate(event.target.value)
          }
         //  InputLabelProps={{
         //    disabled: true,
         //  }}
        />
      </FormControl>
    
      <TimeSpacer
       
      />
      <FormControl fullWidth variant="outlined">
        <TextField
          size="small"
          id="element_time"
         //  label="Time"
          type="time"
          value={this.state.time || ""}
          onChange={(event) =>
            this.handleChangeTime(event.target.value)
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FormControl>
      </StyledFields>
      <IconButton
        style={{marginTop: "4px", marginLeft: "auto",marginRight: "4px"}}
        onClick={() => {
          this.handleSave(true);
        }}
        size="large">
                   {" "}
                   <SaveIcon style={{fontSize: "16px"}}/>
                 </IconButton>
      </EditControls>
      );
    } else {
      return (
        <ReadControls>
          <ReadDate>  {this.state.combined}</ReadDate>
     
                <IconButton
                  style={{marginTop: "4px", marginLeft: "auto",marginRight: "4px"}}
                  onClick={() => {
                    this.handleEditMode(true);
                  }}
                  size="large">
                  {" "}
                  <EditIcon style={{fontSize: "16px"}}/>
                </IconButton>
                </ReadControls>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(ProjectChangeDateElement);
