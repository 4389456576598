import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";
import marketing_urls from "../../config/marketing_urls";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  HeroImage,
  ThreeColumn,
  FeatureImage,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageRightSection,
  LeftRightImage,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
  HeroImageFade,
  FadeImage,
  PanLinkContainer,
  CenterColumnPan,
  ContentSectionSample,
  SampleImageSection,
  SampleText,
  SampleImage,
  SampleSubTitle,
  SampleTextLeft
} from "./MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
class HomeMarketing extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    const urlparts = window.location.hash
    console.log(urlparts)
    if (urlparts === "#Story") {
      console.log("bottom")
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 100);

    } else {
      console.log("top")
      window.scrollTo(0, 0);
    }
    setTitle(`${site_name} | About`)
  }
  handleWaitMenu() {
    // console.log("wait");
    // this.props.dispatch(setMenu(true, 19));
    window.location.href = "/register/";
  }
  render() {
    const { isMobile } = this.props;

    return (
      <ContentWrapper>
        <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection>
        <ContentSectionTop>
          <CenterColumnSingle isMobile={isMobile}>
            <CustomH1 isMobile={isMobile} >
              <ScrollBox isMobile={isMobile}>
                <ScrollList isMobile={isMobile}>
                  <ScrollItem>discover</ScrollItem>
                  <ScrollItem>capture</ScrollItem>
                  <ScrollItem>connect</ScrollItem>
                  <ScrollItem>discover</ScrollItem>
                </ScrollList>{" "}
              </ScrollBox>
              <H1Text style={{ paddingLeft: "0px", paddingRight: "0px" }}>&nbsp;as it's made</H1Text>
            </CustomH1>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
            <CustomP isMobile={isMobile}>
              {" "}
              Software tools for visual artists
            </CustomP>
          </CenterColumnSingle>
        </ContentSectionTop>
        <ContentSection color="transparent">
          <CenterColumnPan isMobile={isMobile}>
            {/* <HeroImage>
              <img style={{ width: "100%" }} src="/marketing_images/hero2.png" />
            </HeroImage> */}
            <HeroImageFade>
              <FadeImage
                src="/marketing_images/hero_series4.jpg"
                delay="12"></FadeImage>
              <FadeImage
                src="/marketing_images/hero_series3.jpg"
                delay="9"></FadeImage>
              <FadeImage
                src="/marketing_images/hero_series2.jpg"
                delay="6"></FadeImage>
              <FadeImage
                src="/marketing_images/hero_series1.jpg"
                delay="3"></FadeImage>
              <FadeImage
                src="/marketing_images/hero_series0.jpg"
                delay="0"></FadeImage>
            </HeroImageFade>
            <PanLinkContainer>
              <Link
                target="_blank"
                to={"/project/" + marketing_urls.sample_projects.portrait + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                <OpenInNewIcon
                  style={{ fontSize: "15px", marginBottom: "-2px" }}
                />{" "}
                See this project in Made
              </Link>
            </PanLinkContainer>
          </CenterColumnPan>
        </ContentSection>

        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <ThreeColumn isMobile={isMobile}>
              <Link to="/discovery/">
                <FeatureImage isMobile={isMobile}>
                  <img
                    style={{ width: "100%" }}
                    src="/marketing_images/feature1.jpg"
                  />
                </FeatureImage>
              </Link>
              <Link to="/discovery/" style={{ textDecoration: "none" }}>
                <FeatureTitle>Discover</FeatureTitle>
              </Link>
              <FeatureText>
                Join the creative journey of other artists by
                following their progress. You can gain art inspiration, learn new
                painting techniques, and offer feedback to support their work.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <Link to="/about_projects/">
                <FeatureImage isMobile={isMobile}>
                  <img
                    style={{ width: "100%" }}
                    src="/marketing_images/feature2.jpg"
                  />
                </FeatureImage>
              </Link>
              <Link to="/about_projects/" style={{ textDecoration: "none" }}>
                <FeatureTitle>Capture</FeatureTitle>
              </Link>
              <FeatureText>
                Keep track of all your visual projects in one place. Quickly upload
                images of your progress and maintain a private visual diary of my progress over time.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <Link to="/about_groups/">
                <FeatureImage isMobile={isMobile}>
                  <img
                    style={{ width: "100%" }}
                    src="/marketing_images/feature3.jpg"
                  />
                </FeatureImage>
              </Link>
              <Link to="/about_groups/" style={{ textDecoration: "none" }}>
                <FeatureTitle>Connect</FeatureTitle>
              </Link>
              <FeatureText>
                Create private communities to share the journey of your projects
                and get feedback from people you know and trust.
              </FeatureText>
            </ThreeColumn>
          </CenterColumn>
        </ContentSection>
        <ContentSectionGradient>
          <CenterColumnSingle>
            <CenterHeader color="#fff">follow your passion</CenterHeader>
            <CenterList>
              {marketing_urls.categories.map((cat, index) => {
                return (
                  <Link
                    key={"cat" + index}
                    to={"/discovery/?category=" + cat[1]}
                    style={{ textDecoration: "none", color: "#fff" }}>
                    <CategoryItem>{cat[0]}</CategoryItem>
                  </Link>
                );
              })}
            </CenterList>
          </CenterColumnSingle>
        </ContentSectionGradient>
        <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterHeader color="#008eff">Pricing</CenterHeader>
            <CenterText>
              Made is currently free and in beta. Sign Up to access the beta.
              <br />
              <br />
              Made will launch as a subscription-based service. By subscribing to our service you will help support our ongoing
              innovation. We will not sell or aggregate your data into
              advertisement profiles.
              {/* <br />
              <br />
              The price for an individual Made subscription is $4.99 monthly or
              $49.99 yearly.
              <br />
              <br />
              For organisation pricing, please{" "}
              <a
                href="mailto:info@asitsmade.com"
                style={{ textDecoration: "none", color: "#008eff" }}>
                contact us.
              </a> */}
            </CenterText>
          </CenterColumnSingle>
        </ContentSectionSingle>
        <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterImage color="#008eff">
              <img
                style={{ width: "100%", mixBlendMode: "multiply" }}
                src="/marketing_images/illustration_palette.png"
              />
            </CenterImage>
          </CenterColumnSingle>
        </ContentSectionSingle>
        <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#38DF88">Curious?</CenterHeader>
            <CenterText maxW="425">
              If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection>

        <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterImage color="#008eff">
              <img
                style={{ width: "100%", mixBlendMode: "multiply", marginBottom: "60px" }}
                src="/marketing_images/illustration_brushes.png"
              />
            </CenterImage>
          </CenterColumnSingle>
        </ContentSectionSingle>

        <ContentSectionImage src={"marketing_images/Rectangle_219.jpg"}>
          <ImageOverlay isMobile={isMobile}>
            Gain creative insights by tracking the evolution of a project - from
            idea to completion
          </ImageOverlay>
        </ContentSectionImage>
        <ContentSection>
          <CenterColumnSingle>
            <ImageLeftSection isMobile={isMobile}>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "20px" }}
                  src="/marketing_images/image 33.png"
                />
              </LeftRightImage>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle color="#008eff">Get unstuck!</FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                 Use Made's software tools to compare your artwork to the reference photo.
                </CenterText>
              </LeftRightText>
            </ImageLeftSection>
            <ImageRightSection isMobile={isMobile}>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle color="#008eff">
                  Value Maps
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Analyze reference material by creating image outlines, gray scale value maps, or black and white notans
                </CenterText>
              </LeftRightText>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "70%", marginBottom: "20px" }}
                  src="/marketing_images/illustration_eraser.png"
                />
              </LeftRightImage>
            </ImageRightSection>
            <ImageLeftSection isMobile={isMobile}>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "50%", marginBottom: "20px" }}
                  src="/marketing_images/illustration_small_bottle.png"
                />
              </LeftRightImage>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle color="#008eff">
                  Composition
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Design great visual art by leveraging composition overlays, such as rules of thirds, golden ratio, phi grid or dynamic perspective armatures.
                </CenterText>
              </LeftRightText>
            </ImageLeftSection>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSectionSample>
          <CenterColumnSingle>
            <Link
              target="_blank"
              to={"/project/" + marketing_urls.sample_projects.jkportrait + "/"}
              style={{ textDecoration: "none" }}>
              <SampleImageSection isMobile={isMobile}>
                <SampleText isMobile={isMobile}>
                  <SampleSubTitle color="#383838" isMobile={isMobile}>
                    Sample Project
                  </SampleSubTitle>{" "}
                  <SampleTextLeft isMobile={isMobile}>
                    Check out this sample painting <br /> project in Made.
                  </SampleTextLeft>
                </SampleText>
                <SampleImage isMobile={isMobile}>

                  <img
                    style={{ width: "100%", marginBottom: "20px" }}
                    src="/marketing_images/sample4.png"
                  />
                </SampleImage>

              </SampleImageSection>
            </Link>
          </CenterColumnSingle>
        </ContentSectionSample>
        <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterImage color="#008eff">
              <img
                style={{ width: "100%", mixBlendMode: "multiply" }}
                src="/marketing_images/people.png"
              />
            </CenterImage>
            <CenterHeader color="#008eff">
              <a name="Story">Our Story</a>{" "}
            </CenterHeader>
            <CenterText>
              Hi!
              <br />
              <br />
              We are
              <Link
                target="_blank"
                to={"/" + marketing_urls.sample_projects.martijn + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                {" "}
                Martijn
              </Link>
              ,{" "}
              <Link
                target="_blank"
                to={"/" + marketing_urls.sample_projects.gavin + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                {" "}
                Gavin{" "}
              </Link>
              and
              <Link
                target="_blank"
                to={"/" + marketing_urls.sample_projects.aidan + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                {" "}
                Aidan
              </Link>
              , product designers and developers
              <br />
              who like to do creative projects in our spare time. <br />
              <br />
              Over the past couple of years, we became frustrated with
              traditional social media as a platform for sharing our work. In
              response, we designed a platform that celebrated the creative
              journey, allowing us to share the process behind the project.
              <br />
              <br />
              And so we created Made.
              <br />
              Made is an independent bootstrapped company without any outside
              funding.
              <br />
              <br />
            </CenterText>
          </CenterColumnSingle>
        </ContentSectionSingle>
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(HomeMarketing));
