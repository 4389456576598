import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
const MaxWidthMade = 1448
export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 45px;
  background-color: #f6f6f6;
`;
export const ContentSection = styled.div`
  width: 100%;

  display: flex;
  background-color: ${(props) => props.color};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const ContentSectionTop = styled.div`
  width: 100%;

  display: flex;
  background-color: ${(props) => props.color};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

`;
export const ContentSectionSingle = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  background-color: ${(props) => props.color};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const ContentSectionGradient = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  background: linear-gradient(180deg, #44A55E 0%, #2196F3 77.6%);
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const ContentSectionSample = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-top: 30px;
  background-color: #e1e1e1;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const ContentSectionImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // background-image: url("/marketing_images/Rectangle 215.png") ;
  background-image: ${(props) => "url('" + props.src + "')"} ;
  background-color: #cccccc;
  height: 600px; 
  background-position: center; 
  background-repeat: no-repeat;
  background-size: cover; 
  margin-top: 0px;
`;
export const ImageOverlay = styled.div`
  max-width: 820px;
  width: 80%;
 margin: auto;
 padding: ${(props) => props.isMobile ? "20px" : "40px"};
 background-color: rgb(0,0,0,0.5);
 color: #fff;
 font-family: sofia-pro-soft,sans-serif;
font-style: normal;
font-weight: 700;
font-size: ${(props) => props.isMobile ? "30px" : "56px"};
line-height: 120%;

align-items: center;
text-align: center;

box-sizing: border-box;

color: #FFFFFF;
`;
export const HeaderSection = styled.div`
  // margin-top: 250px;
  width: 100%;
  display: flex;
 
  height: ${(props) => (props.isMobile ? "144px" : "240px")};
`;
export const LeftColumn = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};

  display: flex;
  flex-direction: column;
  // background-color: #03eba3;
`;
export const RightColumn = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};

  display: flex;
  // background-color: #18bed8;
`;
export const ThreeColumn = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "33.3%")};
  display: flex;
  // background-color: #18bed8;
  flex-direction: column;
`;
export const FeatureIcon = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-size: 200px;
  color: #383838;
`;
export const FAQColumn = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 800px;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 50px;
color: #383838;
   padding-left: 32px;
  padding-right: 32px;
  font-family: 'Merriweather';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 153.5%;
color: #383838;
margin-top: 10px;
margin-bottom: 20px;
`;
export const FAQHeader = styled.div`
    margin-top: 20px;
    margin-bottom: 5px;
    color: #008eff;
    font-size: 24px;
    line-height: 120%;
    text-decoration: none;
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
   
`;
export const FAQSubHeader = styled.div`
    margin-top: 16px;
    margin-bottom: 3px;
    color: #383838;
    font-size: 18px;
    line-height: 120%;
    text-decoration: none;
    font-family: sofia-pro-soft, sans-serif;
    font-weight: 700;
   
`;
export const FAQParagraph = styled.div`

font-family: 'Merriweather';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 153.5%;
color: #383838;
margin-top: 10px;
margin-bottom: 20px;
`;
export const FeatureImage = styled.div`
  margin-left: auto;
  margin-right: auto;
   margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  display: flex;
  max-width: 80%;
   image-rendering: crisp-edges;
  
  background-color: #ccc;
  border: 8px solid #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
`;
export const FeatureImageLarge = styled.div`
  margin-left: auto;
  margin-right: auto;
   margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
 
  height: 100%;
  background-color: #ccc;
  border: 8px solid #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
`;

export const FeatureTitle = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 40px;
color: #008eff;
  font-size: 36px;
  line-height: 120%;
text-decoration: none;
  font-family: sofia-pro-soft, sans-serif;
  font-weight: 700;
  max-width: 80%;
  text-align: center;
  margin-top: 0px;
`;
export const FeatureTitleH2 = styled.h2`
color: #383838;
text-align: center;
font-family: Gothic A1;
font-size:  ${(props) => props.isMobile ? "20px" : "24px"};
font-style: normal;
font-weight: 800;
line-height: 140%; /* 33.6px */
`;
export const FeatureSubTitle = styled.div`
  margin-left: auto;
  margin-right: auto;
color: ${(props) => (props.color ? props.color : "#008eff")};
  font-size: 36px;
  line-height: 40px;

  font-family: sofia-pro-soft, sans-serif;
  font-weight: 700;
  max-width: 80%;
  text-align: center;
  margin-top: 20px;
`;
export const FeatureText = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 153.5%;
  max-width: 80%;
  text-align: center;
  margin-top: -20px;
  margin-bottom: 40px;
`;
export const FeatureTextP = styled.p`
  margin-left: auto;
  margin-right: auto;
 color: #000;

text-align: center;
font-family: Merriweather;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 153.5%; /* 21.49px */
  max-width: 80%;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 40px;
`;

export const CenterColumn = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // min-width: 75%;
  max-width: 1050px;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 50px;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
   padding-left: 32px;
  padding-right: 32px;
  background-color: ${(props) => (props.color ? "#fff" : "none")};
`;
export const CenterColumnThree = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // min-width: 75%;
   max-width: ${(MaxWidthMade +"px")};
  z-index: 1;
  padding-top: 15px;
  padding-bottom: ${(props) => (props.isMobile ? "0px" : "10px")}; 
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
   padding-left: 32px;
  padding-right: 32px;
  background-color: ${(props) => (props.color ? "#fff" : "none")};
`;
export const FocusAreas = styled.div`
 position: absolute;
 background: rgba(0, 0, 0, 0.58);
 height: 100%;
 width: ${(props) => (props.w < 990 ? "235px" : "355px")};  
font-size: ${(props) => (props.isMobile ? "10px" : props.w < 990 ? "16px" : "24px")};  
 display: flex;
 flex-direction: column;
 padding-top: 45px;
 padding-left: 32px;
`;
export const FocusArea = styled.div`
  color: #fff;
  font-family: Gothic A1;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  line-height: 203.5%; /* 48.84px */
   &:hover {
    @media (pointer: fine) {
      font-weight: 800;
      
    }

`;
export const ArrowRight = styled.div`


  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  
  border-left: 10px solid rgba(0, 0, 0, 0.58);;
  position: absolute;
  left: ${(props) => (props.w < 990? "267px" : "387px")};
  top: ${(props) => (props.toparrow ? props.toparrow + "px" : "75px")};

`
export const InfoBox = styled.div`
position: absolute;
background: rgba(0, 0, 0, 0.58);
left: ${(props) => (props.w < 990? "290px" : "410px")}; ;
top: ${(props) => (props.top ? props.top + "px" : "75px")};
width: 355px;
color: #fff;
font-family: Gothic A1;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 200%; 

padding: 10px;

`;
export const InfoBoxMobile = styled.div`

background: rgba(0, 0, 0, 0.8);

width: ${(props) => ((props.w-130)+"px")}; ;
color: #fff;
font-family: Gothic A1;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 200%; 

padding: 20px;
border-radius: 5px;
 margin: 10px;
`;
export const FocusMobile = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  // min-height: 600px;
  max-width: ${(MaxWidthMade +"px")};
  z-index: 1;

  padding-bottom: 20px;
  background-color: #ccc;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};

`;
export const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 10px;
  margin-right: 0px;
  display: flex;
  width: 100px;
 

`;
export const ButtonSection = styled.div`

  display: flex;
  width: 100%;
 

`;
export const CenterColumnImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: ${(MaxWidthMade +"px")};
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 30px;
  background-color: #fff;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};

`;
export const CenterColumnImageMobile = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: ${(MaxWidthMade +"px")};
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 30px;
  background-color: #fff;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};

`;
export const CenterColumnText = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  max-width: ${(MaxWidthMade +"px")};
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: ${(props) => (props.color ? props.color  : "#fff")};
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};

`;
export const CenterColumnPan = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  min-width: 75%;
  max-width: 1000px;
  z-index: 1;
  padding-top: 5px;
  padding-bottom: 50px;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
   padding-left: 32px;
  padding-right: 32px;
`;
export const HeroImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // width: 80%;
  max-width: 800px;
  // height: 800px;
  background-color: #ccc;
  border: 8px solid #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
 
`;

export const ImageFull = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 100%;
  // max-width: 1000px;
  // height: 800px;
  background-color: #ccc;
 
 
`;
export const PanContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 80%;
  max-width: 1000px;
  height: ${(props) => (props.isMobile ? "300px" : "584px")};
  background-color: #f2f2f2;
  border: 8px solid #fff;
  overflow: hidden;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
 
`;
export const PanLinkContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  width: 80%;
  max-width: 1000px;
 
 
`;
export const PanImage = styled.div`
  animation: ${(props) => Pan()} 60s ease-in infinite;
 
`;
export const Pan = () => keyframes`
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-100%);
    }
`;
export const Fade = () => keyframes`
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    34% {
      opacity: 0;
    }
    88% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

export const HeroImageFade = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // width: 80%;
  max-width: 1000px;
  // height: 800px;
  background-color: #ccc;
  border: 8px solid #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
  position: relative;
  display: block;
  width: 100%;
  // max-width: 700px;
  height: 0;
    padding-top: 68%;
 
`;
export const FadeImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
 
  background-image: ${(props) => ("url('" + props.src + "')")};
  animation: ${(props) => Fade()} 18s infinite;
  animation-delay: ${(props) => (props.delay + "s")};
`
export const CenterColumnSingle = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  // min-width: 1000px;
  max-width: 1000px;
  z-index: 1;
  flex-direction: column;
  padding-top: ${(props) => (props.isMobile ? "0px" : "50px")};
  background-color: ${(props) => (props.color ? props.color : "none")};
  padding-bottom: 5px;
`;
export const CustomH1 = styled.h1`
  font-family: sofia-pro-soft, sans-serif;
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-direction:  ${(props) => (props.isMobile ? "column" : "row")};
  font-style: normal;
  color: #008EFF;
  margin-top: 10px;
  margin-bottom: 5px;
  // font-weight: 400;
  font-size: ${(props) => (props.isMobile ? "50px" : "74px")};
    margin-left: auto;
  margin-right: auto;
`;
export const H1Text = styled.div`
    padding-left: 20px;
    padding-right: 20px;
`;
export const ScrollBox = styled.div`
  height:  ${(props) => (props.isMobile ? "67px" : "120px")};
  overflow: hidden;
`;
export const VertSpacer = styled.div`
  height:  50px;
  max-width: ${(MaxWidthMade +"px")};
  background-color: #fff;

`;
export const ScrollList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.isMobile ? "center" : "flex-end"};
  animation: ${(props) => scrollUp()} 6s ease-in-out infinite;
`;

export const scrollUp = () => keyframes`
      0% {
        transform: translateY(0);
      }
      29% {
        transform: translateY(0);
      }
      33.33% {
        transform:  translateY(-25%);
      }
      62.33% {
        transform:  translateY(-25%);
      }
      66.66% {
        transform:  translateY(-50%);
      }
      95.66% {
        transform:  translateY(-50%);
      }
      100% {
        transform:  translateY(-75%);
      }
`;

export const ScrollItem = styled.div`
  
  color: #383838;
`;
export const GreenSpan = styled.span`
  color: #3c8c54;
  
`;
export const CenterImage = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
  max-width: 460px;
 background-color: rgb(245, 245, 245);
 margin-top: 20px;
    margin-bottom: 20px;
`;
export const ImageLeftSection = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
display: flex;
 margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const SampleImageSection = styled.div`
      margin-left: auto;
  margin-right: auto;
  width: 100%;
display: flex;
 margin-top: 20px;
    margin-bottom: 35px;
    max-width: 1000px;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const SampleImage = styled.div`
    width: 300px;
     margin-left: ${(props) => (props.isMobile ? "auto" : "none")};
     margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
`;
export const SampleText = styled.div`
    // width: 100%;
    margin-right: ${(props) => (props.isMobile ? "none" : "25px")};
   text-align: ${(props) => (props.isMobile ? "center" : "right")};
`;
export const SampleSubTitle = styled.div`

color: ${(props) => (props.color ? props.color : "#383838")};
  font-size: 36px;
  line-height: 52px;

  font-family: sofia-pro-soft, sans-serif;
  font-weight: 700;
 
  text-align: ${(props) => (props.isMobile ? "center" : "right")};
  margin-top: 15px;
`;
export const SampleTextLeft = styled.div`
   
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 153.5%;
    color: #383838;
    margin-top: 4px;
    margin-bottom: 20px;
`;

export const ImageLeftSectionLarge = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 100%;
display: flex;
 margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
export const ImageRightSection = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
display: flex;
 margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
`;
export const ImageRightSectionLarge = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 100%;
display: flex;
 margin-top: 20px;
    margin-bottom: 20px;
    max-width: 800px;
    flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
`;
export const LeftRightImage = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LeftRightImageLarge = styled.div`
    width: 100%;
    // background-color: #ccc;
    ${(props) => props.isMobile ? "" : "border: 8px solid #fff"};
    ${(props) => props.isMobile ? "" : "filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3))"};
    // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
    box-sizing: border-box;
`;

export const LeftRightText = styled.div`
    min-width: ${(props) => (props.isMobile ? "100%" : "325px")};
    margin: auto;
`;

export const CenterText = styled.div`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
  max-width: ${(props) => (props.maxW ? props.maxW + "px" : "900px")};
font-family: 'Merriweather';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 153.5%;
/* or 31px */

text-align: center;

/* Dark-Gray */

color: #383838;
margin-top: 20px;
    margin-bottom: 20px;

`;
export const CenterTextNew = styled.p`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
   color: #000;

text-align: center;
font-family: Gothic A1;
font-size: ${(props) => (props.isMobile ? "16px" : "20px")};
font-style: normal;
font-weight: 600;
line-height: 153.5%; /* 30.7px */
  max-width: ${(props) => (props.maxW ? props.maxW + "px" : "600px")};
  font-family: Gothic A1, sans-serif;

padding-left: 20px;
    padding-right: 20px;

margin-top: 20px;
    margin-bottom: 20px;

`;
export const CenterTextSmall = styled.p`
      margin-left: auto;
  margin-right: auto;
   width: 80%;
   color: #000;

   text-align: center;
   font-family: Merriweather, serif;
   font-size: 14px;
   font-style: normal;
   font-weight: 400;
   line-height: 153.5%; /* 21.49px */
font-size: ${(props) => (props.isMobile ? "14px" : "16px")};

  max-width: ${(props) => (props.maxW ? props.maxW + "px" : "600px")};


padding-left: 20px;
    padding-right: 20px;

margin-top: 20px;
    margin-bottom: 45px;

`;
export const CenterTextWide = styled.p`
      margin-left: auto;
  margin-right: auto;
  //  width: 80%;
   color: #383838;

text-align: center;
font-family: Gothic A1;
font-size: ${(props) => (props.isMobile ? "16px" : "20px")};
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
  max-width: ${(props) => (props.maxW ? props.maxW + "px" : "900px")};
  font-family: Gothic A1, sans-serif;

padding-left: 20px;
    padding-right: 20px;

margin-top: 20px;
    margin-bottom: 20px;
  
`;
export const CenterButton = styled.div`
      margin-left: auto;
  margin-right: auto;
 
  max-width: ${(props) => (props.maxW ? props.maxW + "px" : "900px")};

padding-left: 20px;
    padding-right: 20px;

margin-top: 20px;
    margin-bottom: 50px;
  
`;
export const CenterHeader = styled.div`
  font-size: 56px;
  line-height: 1.25;
  letter-spacing: 0.03em;
  font-family: sofia-pro-soft; sans-serif;

  font-weight: 700;
  color: ${(props) => props.color};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 00px;
    margin-bottom: 0px;
`;
export const CenterHeaderNew = styled.h1`
  font-size: ${(props) => (props.isMobile ? "32px" : "48px")};

  letter-spacing: 0.03em;
  font-family: Merriweather; serif;

  font-style: normal;
font-weight: 300;
line-height: 153.5%; /* 73.68px */
  color: ${(props) => props.color};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 00px;
    margin-bottom: 0px;
`;
export const CenterHeaderH2 = styled.h2`
  font-size: ${(props) => (props.isMobile ? "32px" : "48px")};

  letter-spacing: 0.03em;
  font-family: Merriweather; serif;

  font-style: normal;
font-weight: 300;
line-height: 153.5%; /* 73.68px */
  color: ${(props) => props.color};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 00px;
    margin-bottom: 0px;
`;
export const CenterHeaderH2Small = styled.h2`
  font-size: ${(props) => (props.isMobile ? "32px" : "48px")};
 
  text-align: center;
  font-family: Gothic A1;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 275%; /* 66px *//
  color: ${(props) => props.color};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0em;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 00px;
    margin-bottom: 0px;
`;
export const CenterSmall = styled.div`
  font-size: 18px;
  line-height: 2.5;
  letter-spacing: 0.03em;
  font-family: Roboto, sans-serif;

  font-weight: 700;
  color: ${(props) => props.color};
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0em;
  text-align: center;
`;
export const CenterList = styled.div`
  display: flex;
  max-width: 800px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
`;
export const CategoryItem = styled.div`
  border: 2px solid #fff;
  border-radius: 40px;
  // height: 80px;
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 28px;
color: #fff;
padding-top: 20px;
padding-bottom: 20px;
padding-left: 40px;
padding-right: 40px;
margin: 10px;
`;
export const CustomP = styled.p`
  font-size: ${(props) => (props.isMobile ? "24px" : "32px")};
  line-height: 1.45;

  font-family: Merriweather, sans-serif;
  font-style: italic;
  font-weight: 400;

    margin-top: ${(props) => (props.isMobile ? "40px" : "58px")};
  text-align: center;
  color: #111;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;
export const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
`;
export const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-top: 5px;
`;
export const CalltoAction = styled.div`
 
 margin: auto;
`;
export const LinkText = styled.a`
 text-decoration: none;
 color: #2196F3;
`;
export const BottomSpacer = styled.div`
  height: 50px;
`;
export const BottomCurve = styled.div`
  position: absolute;
  inset: 0px 0px -2px;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1440' height='128' viewBox='0 0 1440 128'%3E%3Cpath class='header-wave' fill='%23F2F2F2' d='M0 471 L1440 471 L1440 386.338583 C1331.33333 357.446194 1239 343 1163 343 C821.995497 343 821.995497 463.944882 426 463.944882 C262.447846 463.944882 120.447846 438.076115 0 386.338583 L0 471 Z' transform='translate(0 -343)'/%3E%3C/svg%3E");
  background-position: center bottom;
  background-size: 101%;
  background-repeat: no-repeat;
`;
