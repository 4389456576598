import { CONSTANTS } from "../actions";

const initialState = {
  groups_loading: true,
  org_loading: true,
  part_loading: true,
  groups: { byID: {}, allIDS: [] },
  participants: { byID: {}, allIDS: [] },
  projects: { byID: {}, allIDS: [] },
  manage_participants: {
    AC: {
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
    },
    IN: {
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
    }
  },
  pending_invites: [],
  search_results: [],
  id: 1,
  name: "Sequential Art",
  description: "",
  created_at: "2022-02-08T18:32:53.513153Z",
  image: null,
  logo: null,
  cover: null,
  link_url: null,
  link_name: null,
  total_members: 0,
  viewer_is_admin: false,
  viewer_status: "",
  seats: 0,
  seats_filled: 0
};

export default function orgReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.GET_ALL_ORG_PARTICIPANTS: {

      const prop = action.payload.sub_type
      let new_participants = { ...state.manage_participants }
      new_participants[prop] = { ...action.payload, loading: false, }

      return {
        ...state,
        manage_participants: new_participants
      }

    }
    case CONSTANTS.LOAD_MORE_ORG_PARTICIPANTS: {

      const prop = action.payload.sub_type
      console.log(prop)
      let new_participants = { ...state.manage_participants }
      let prev_subs = state.manage_participants[prop].results;
      let new_subs = action.payload
      new_subs.results = prev_subs.concat(action.payload.results)
      new_participants[prop] = { ...new_subs, loading: false, }

      return {
        ...state,
        manage_participants: new_participants
      }

    }

    case CONSTANTS.GET_ORGPARTICIPANTS: {
      //console.log("group participants");


      let new_participants = {
        byID: {},
        allIDS: [],
        count: action.payload.count,
        previous: action.payload.previous,
        next: action.payload.next,
      };
      const People = action.payload.results;
      People.forEach((person) => {
        new_participants.allIDS.push(person.id);
        new_participants.byID[person.id] = person.user;
        new_participants.byID[person.id].role = person.role;
        new_participants.byID[person.id].part_id = person.id;
        new_participants.byID[person.id].status = person.status;
      });



      return {
        ...state,
        participants: new_participants,
        part_loading: false,
      };
    }
    case CONSTANTS.SET_PENDING_ORG_INVITE: {
      const new_total = state.total_members + 1
      return {
        ...state,
        total_members: new_total,

      }
    }
    case CONSTANTS.REMOVE_ORG_MEMBER: {
      console.log("remove org member", action.payload.type)
      let new_participants = { ...state.manage_participants }
      let prev_subs = state.manage_participants[action.payload.type];
      console.log(prev_subs)
      const new_total = state.total_members - 1
      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload.part_id) {
          prev_subs.results.splice(index, 1);
          return;
        }
      })
      new_participants[action.payload.type] = { ...prev_subs, loading: false, }

      return {
        ...state,
        total_members: new_total,
        manage_participants: new_participants
      }
    }
    case CONSTANTS.CHANGE_ORG_ROLE_INVITE: {
      let new_participants = { ...state.manage_participants }
      let prev_subs = state.manage_participants[action.payload.type];

      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload.part_id) {
          result.role = action.payload.role
          return;
        }
      })
      new_participants[action.payload.type] = { ...prev_subs, loading: false, }

      return {
        ...state,
        manage_participants: new_participants
      }
    }
    case CONSTANTS.SET_ORG_LOADING: {
      return { ...state, org_loading: true };
    }
    case CONSTANTS.SET_ORGGROUPS_LOADING: {
      return { ...state, groups_loading: true };
    }
    case CONSTANTS.SET_ORGPARTICIPANTS: {
      return { ...state, part_loading: true };
    }
    case CONSTANTS.GET_ORGGROUPS_DATA: {
      let new_groups = { byID: {}, allIDS: [], no_results: 0, };
      console.log(action.payload)
      const Groups = action.payload.data.results;
      Groups.forEach((group) => {
        new_groups.allIDS.push(group.id);
        new_groups.byID[group.id] = group;
      });
      new_groups.count = action.payload.count;
      new_groups.previous = action.payload.data.previous;
      new_groups.next = action.payload.data.next;
      if (action.payload.data.results.length === 0) {
        new_groups.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_groups.no_results = 1;
      }

      return { ...state, groups: new_groups, groups_loading: false };
    }
    case CONSTANTS.GET_MORE_GROUPS: {
      let new_groups = { ...state.groups };
      const Groups = action.payload.results;
      Groups.forEach((group) => {
        new_groups.allIDS.push(group.id);
        new_groups.byID[group.id] = group;
      });
      new_groups.count = action.payload.count;
      new_groups.previous = action.payload.previous;
      new_groups.next = action.payload.next;
      new_groups.total_active = action.payload.total_active;
      new_groups.total_invited = action.payload.total_invited;
      new_groups.total_requested = action.payload.total_requested;
      new_groups.total_planned = action.payload.total_planned;
      new_groups.total_completed = action.payload.total_completed;

      return { ...state, groups: new_groups, groups_loading: false };
    }
    case CONSTANTS.GET_ORG_DATA: {
      let new_org = { ...state }
      new_org.org_loading = false
      new_org.id = action.payload.id
      new_org.name = action.payload.name
      new_org.tag_line = action.payload.tag_line
      new_org.description = action.payload.description
      new_org.created_at = action.payload.created_at
      new_org.image = action.payload.image
      new_org.logo = action.payload.logo
      new_org.cover = action.payload.cover
      new_org.link_url = action.payload.link_url
      new_org.link_name = action.payload.link_name
      new_org.total_members = action.payload.total_members
      new_org.viewer_is_admin = action.payload.viewer_is_admin
      new_org.viewer_status = action.payload.viewer_status
      new_org.seats = action.payload.seats
      new_org.seats_filled = action.payload.seats_filled
      return { ...new_org };
    }
    case CONSTANTS.GET_ORGPROJECTS_DATA: {
      console.log("Hello! Got org project data: ", action.payload.data.results)
      let projects = { byID: {}, allIDS: [] }
      action.payload.data.results.forEach((project) => {
        projects.byID[project.id] = project
        projects.allIDS.push(project.id)
      })
      return { ...state, projects: projects }
    }
    case CONSTANTS.SET_GROUPPROJECTS_LOADING: {
      let new_current_group = { ...state.current_group };
      //console.log(new_current_group);
      new_current_group.projects_loading = true;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.GET_GROUPPROJECTS_DATA: {
      //console.log("group projects");
      let new_current_group = { ...state.current_group };

      let new_projects = {
        byID: {},
        allIDS: [],
        count: action.payload.data.count,
        previous: action.payload.data.previous,
        next: action.payload.data.next,
        no_results: 0,
      };
      const Groups = action.payload.data.results;
      Groups.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      //console.log(action.payload.state);
      if (action.payload.data.results.length === 0) {
        new_projects.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_projects.no_results = 1;
      }
      new_current_group.projects = new_projects;
      new_current_group.projects_loading = false;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.LOAD_MORE_GROUPPROJECTS: {
      //console.log("group projects");
      let new_current_group = { ...state.current_group };

      let new_projects = { ...new_current_group.projects };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      new_projects.count = action.payload.count;
      new_projects.previous = action.payload.previous;
      new_projects.next = action.payload.next;
      new_current_group.projects = new_projects;
      new_current_group.projects_loading = false;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.CREATE_NEW_GROUP: {
      let newgroups = { ...state.groups };
      newgroups.allIDS.push(action.payload.id);
      newgroups.byID[action.payload.id] = action.payload;
      return { ...state, groups: newgroups };
    }
    default:
      return state;
  }
}
