import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { logoutUser } from "../actions/authActions";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { uploadFiles } from "../actions/projectActions";
import {
  getRecentProjects,
  getElementStylesForMenu,

} from "../actions/profileActions";
import { clearNotifications } from "../actions/profileActions";
import { setMenu, selectElementType, genericError } from "../actions/uiActions";
import ProjectPageEdit from "./ProjectPageEdit";
import FeedBackDialog from "./FeedBackDialog";
import ProfilePageEdit from "./ProfilePageEdit";
import HelpPage from "./HelpPage";
import GroupPageEdit from "./GroupPageEdit";
import NotificationsList from "./NotificationsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import SlideshowOutlinedIcon from '@mui/icons-material/SlideshowOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import CompareOutlinedIcon from '@mui/icons-material/CompareOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { getProjectContent } from "../actions/projectActions";
import Login from "./auth/login";
import CircularProgress from "@mui/material/CircularProgress";
import GlobalInvite from "./GlobalInvite";
import FeedbackRequest from "./FeedbackRequest";
import element_types from '../config/element_types'
import mimetype from "../config/mimetype";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Waitlist from "./auth/waitlist"
import ProfilePageSubscribers from "./ProfilePageSubscribers";
import ProfilePageSubscriptions from "./ProfilePageSubscriptions";
import FeedPageSubscriptions from "./FeedPageSubscriptions";
import GroupProjectAdd from "./GroupProjectAdd";

const ElementContainerNarrow = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;

  // margin-bottom: 10px;
`;

const TopContainerNarrow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
`;
const MenuPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 1001;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: ${(props) => (props.isMobile ? "100%" : "auto")};
`;
const MenuPanelNotification = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 55px;
  right: 27px;
  // transform: translate(-50%, -50%);

  // width: 350px;
  max-height: 100%;
`;
const MenuPanelProfileMenu = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 55px;
  right: 10px;
  // transform: translate(-50%, -50%);

  // width: 350px;
  max-height: 100%;
`;
const ElementCardNotification = styled.div`
  width: calc(100% - 40px);
  width: 300px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  &:after {
    z-index: -10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    // bottom: 0;
    top: 0x;
    right: 30px;
    box-sizing: border-box;

    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  }
  &:before {
    z-index: 10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    top: 0x;
    right: 30px;
    box-sizing: border-box;

    border: 7px solid black;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(135deg);
  }
`;
const ElementCardProfileMenu = styled.div`
  width: calc(100% - 40px);
  width: 200px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  &:after {
    z-index: -10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    // bottom: 0;
    top: 0x;
    right: 13px;
    box-sizing: border-box;

    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  }
  &:before {
    z-index: 10;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 0;
    top: 0x;
    right: 13px;
    box-sizing: border-box;

    border: 7px solid black;
    border-color: transparent transparent #fff #fff;

    transform-origin: 0 0;
    transform: rotate(135deg);
  }
`;
const ElementCard = styled.div`
  width: calc(100% - 40px);
  width: 300px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  // &:after {
  //   z-index: -10;
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0;
  //   // bottom: 0;
  //   top: 0x;
  //   right:20px;
  //   box-sizing: border-box;

  //   border: 7px solid #fff;
  //   border-color: transparent transparent #fff #fff;

  //   transform-origin: 0 0;
  //   transform: rotate(135deg);

  //   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // }
  // &:before {
  //   z-index: 10;
  //   content: "";
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   margin-left: 0;
  //   top: 0x;
  //   right:20px;
  //   box-sizing: border-box;

  //   border: 7px solid black;
  //   border-color: transparent transparent #fff #fff;

  //   transform-origin: 0 0;
  //   transform: rotate(135deg);
  // }
`;
const NewDialog = styled.div`
  width: ${(props) => (props.isMobile ? props.width + "px" : "auto")};
  // margin-top: ${(props) => (props.isMobile ? "40px" : "auto")};
  //  margin-left: ${(props) => (props.isMobile ? "-1px" : "-295px;")};
`;
const LoginDialog = styled.div`
  width: ${(props) => (props.isMobile ? props.width + "px" : "auto")};
  margin-top: ${(props) => (props.isMobile ? "40px" : "auto")};
  //  margin-left: ${(props) => (props.isMobile ? "-1px" : "-295px;")};
  // height: 00px;
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 4px;
`;
const AlertDialog = styled.div`
  width: 300px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 200%;
  padding: 32px;
`;

const MenuLine = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuLineLast = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuLineFirst = styled.div`
  height: 45px;
  padding-top: 5px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
`;
const DividerLine = styled.div`
  // height: 1px;
  border-bottom: 1px solid #f2f2f2;

  width: 100%;
`;
const MenuLineCommand = styled.div`
  height: 60px;
  padding-top: 15px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuLineCommandLast = styled.div`
  height: 60px;
  padding-top: 15px;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
`;
const MenuIcon = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 13px;
  margin-left: 30px;
`;
const MenuLabel = styled.div`
max-width: 193px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
const MenuLabelSingle = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  margin-left: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
const SectionLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #757575;
  text-align: left;
`;
const NoProjectText = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #9a9a9a;
  text-align: left;
  font-style: italic;
`;
const TimeOutError = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 12px;
  font-weight 400;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #9a9a9a;
  text-align: center;
  font-style: italic;
`;
class UberMenu extends Component {
  constructor() {
    super();
    this.state = {
      showElementTypes: false,
      showTopMenu: false,
      showAllRecent: false,
      showNewProject: false,
      showNewGroup: false,
      selectedprojectid: false,
      shared_with_group: null
    };
  }

  newElement = (projectid) => {
    // const { history, id } = this.props;
    this.setState({ showElementTypes: true });
    this.setState({ showTopMenu: false });
    this.setState({ selectedprojectid: projectid });
    // this.props.history.push("/new/p1");
  };
  clearNotifications = () => {
    const { notifications } = this.props;
    if (notifications.data.length) {
      let NotList = { ids: [] };
      notifications.data.forEach((Not) => {
        NotList["ids"].push(Not.id);
      });
      console.log(NotList);
      this.props.dispatch(clearNotifications(NotList));
    }
    this.props.dispatch(setMenu(false));
  };
  newProject = (group) => {
    // this.props.dispatch(setMenu(false));
    if (group){
      this.setState({ shared_with_group: group });
    }
    if (!this.props.auth.ticket && this.props.auth.usage.projects >= 1){
      this.props.dispatch(setMenu(true, 22));
    }else{
      this.props.dispatch(setMenu(true, 6));
    }
   
    // this.setState({ showElementTypes: false });
    // this.setState({ showTopMenu: false });
    // this.setState({ showNewProject: true });
    // this.props.history.push("/newproject/");
  };
  addtogroup = (group) => {
    // this.props.dispatch(setMenu(false));
    
    this.props.dispatch(setMenu(true, 21));
    // this.setState({ showElementTypes: false });
    // this.setState({ showTopMenu: false });
    // this.setState({ showNewProject: true });
    // this.props.history.push("/newproject/");
  };
  handleShowMenu(state, type) {
    this.props.dispatch(setMenu(state, type));

  }
  logOut = () => {
    this.props.dispatch(logoutUser());
  };
  handleShowHelp = () => {
    this.props.dispatch(setMenu(false));
    window.location.href = "/support/";
  };
  handleShowAbout = () => {
    this.props.dispatch(setMenu(false));
    window.location.href = "/about/";
  };
  showTerms = () => {
    this.props.dispatch(setMenu(false));
    window.location.href = "/terms/";
  };
  selectOtherProject = () => {
    // this.props.dispatch(setMenu(false));
    this.setState({ showTopMenu: true });
    this.setState({ showNewProject: false });
    this.setState({ showElementTypes: false });
    // this.props.history.push("/newproject/");
  };
  showAllRecent = () => {
    // this.props.dispatch(setMenu(false));
    this.setState({ showAllRecent: true });

    // this.props.history.push("/newproject/");
  };
  validateFile = (file) => {
    var video = document.createElement("video");
    video.preload = "metadata";

    // video.onloadedmetadata = function() {

    // }
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      // console.log(Number(video.duration))
      this.setState({ video_duration: video.duration });
      if (Number(video.duration) > 10) {
        console.log(video.duration);
      }
    };

    video.src = URL.createObjectURL(file);
  };
  openFilePicker = (type) => {

    var f = document.createElement("input");
    f.style.display = "none";
    f.type = "file";
    f.name = "file";
    if (type === "UP") {
      f.accept = mimetype.image;
    } else if (type === "VI") {
      f.accept = mimetype.video;
    } else if (type === "AU") {
      f.accept = mimetype.audio;
    } else if (type === "ME") {
      f.accept = mimetype.video + "," + mimetype.audio
    } else {
      f.accept = mimetype.any;
    }
    f.multiple = true;
    f.click();
    document.body.appendChild(f)
    f.addEventListener("change", (e) => {
      this.handleFilePicked(e);
      document.body.removeChild(f)

    });
  };
  handleFilePicked = (e) => {
    const f = e.target;
    const type = e.target.files[0].type.split("/")[0];
    const maxfiles = 10;
   
    if (f.files.length > maxfiles) {
      this.props.dispatch(
        genericError(
          "The maximum number of files to upload at once is " + maxfiles + "."
        )
      );
      this.props.dispatch(setMenu(true, 14));
    } else {
      for (let i = 0; i < f.files.length; i++) {
        let file = f.files[i];
     
        const local_date = new Date(file.lastModified);

        const data = {
          element_date: local_date,
          element_type: "UP",
          project: this.state.selectedprojectid,
        };
        const urlparts = window.location.pathname.split("/");
        const loc = urlparts[1];
        const el = urlparts[2];
        let newproject = false;
        if (loc === "project") {
          if (el === this.state.selectedprojectid) {
            newproject = false;
          } else {
            newproject = true;
          }
        } else {
          newproject = true;
        }
        if (type === "image" || type === "audio") {
          if (newproject) {
            this.props.dispatch(
              getProjectContent(this.state.selectedprojectid)
            );
            this.props.history.push(
              "/project/" + this.state.selectedprojectid + "/"
            );
          }

          this.props.dispatch(uploadFiles(data, [file], "new"));
        } else {
          if (type === "video") {
            console.log("video");
            var vid = document.createElement("video");
            vid.preload = "metadata";

            // video.onloadedmetadata = function() {

            // }
            vid.onloadedmetadata = (e) => {
              let video = e.target;
              window.URL.revokeObjectURL(video.src);
              // console.log(Number(video.duration))
              this.setState({ video_duration: video.duration });
              console.log(video)
              console.log(file)
              if (Number(video.duration) > 120) {
                console.log("error:", video.duration);
                this.props.dispatch(
                  genericError(
                    "the duration of the selected video is more than the allowed 120 seconds"
                  )
                );
                this.props.dispatch(setMenu(true, 14));
              } else {
                if (Number(file.size) > 50000000) {
                  console.log("error:", video.duration);
                  this.props.dispatch(
                    genericError(
                      "the size the selected video is more than the allowed 50 MB"
                    )
                  );
                  this.props.dispatch(setMenu(true, 14));
                    }else{
                if (newproject) {
                  this.props.dispatch(
                    getProjectContent(this.state.selectedprojectid)
                  );
                  this.props.history.push(
                    "/project/" + this.state.selectedprojectid + "/"
                  );
                }

                this.props.dispatch(uploadFiles(data, [file], "new"));
              }
            }
            };

            vid.src = URL.createObjectURL(file);
          }
        }
      }
    }
  };
  newElementSelection = (typeid) => {
    // this.setState({ showElementTypes: true });
    // this.props.location = "/new/"+this.state.selectedprojectid
    this.props.dispatch(selectElementType(typeid));
    this.props.dispatch(setMenu(false));
    const urlparts = window.location.pathname.split("/");
    const loc = urlparts[1];
    const el = urlparts[2];
    if (loc === "project") {
      if (el === this.state.selectedprojectid) {
        this.props.history.push("/new/" + this.state.selectedprojectid);
      } else {
        this.props.dispatch(getProjectContent(this.state.selectedprojectid));
        this.props.history.push("/new/" + this.state.selectedprojectid);
      }
    } else {
      this.props.history.push("/new/" + this.state.selectedprojectid);
    }

    // this.props.history.push("/new/" + this.state.selectedprojectid);
  };
  doNothing(e) {
    e.stopPropagation();
    e.preventDefault();
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
    const { current_creator, myid, menuType } = this.props;
    const loc = window.location.pathname.split("/")[1];
    const el = window.location.pathname.split("/")[2];
    if (loc === "project") {
      if (current_creator === myid) {
        this.setState({ showElementTypes: true });
        this.setState({ showTopMenu: false });
        this.setState({ selectedprojectid: el });
      } else {
        this.setState({ showElementTypes: false });
        this.setState({ showTopMenu: true });
      }
    } else {
      this.setState({ showElementTypes: false });
      this.setState({ showTopMenu: true });
    }
    if (menuType === 1) {
      this.props.dispatch(getRecentProjects(this.props.user_id));
      // this.props.dispatch(getElementStylesForMenu(this.props.user_id));
    }

  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  render() {
    const {
      recentprojects,
      dimensions,
      menuType,
      progress,
      timeouterror,
      genericerror,
    } = this.props;

    switch (menuType) {
      case 1:
        return (
          <MenuPanel onClick={(e) => {
            this.doNothing(e);
          }}>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                {this.state.showElementTypes ? (
                  <ElementCard>
                    <SectionLabel>Select Element Type to Add</SectionLabel>

                    {element_types.map((element, index) => {
                      switch (element.id) {
                        case "UP":
                          return (
                            <MenuLine
                              onClick={() => {
                                this.openFilePicker("UP");
                              }}
                              key={"element" + index}>
                              <MenuIcon>
                                <ImageOutlinedIcon fontSize="inherit" />
                              </MenuIcon>
                              <MenuLabel>{element.name}</MenuLabel>
                            </MenuLine>
                          );
                        // case "VI":
                        //   return (
                        //     <MenuLine
                        //       onClick={() => {
                        //         this.openFilePicker("VI");
                        //       }}
                        //       key={"element" + index}>
                        //       <MenuIcon>
                        //         <SlideshowOutlinedIcon fontSize="inherit" />
                        //       </MenuIcon>
                        //       <MenuLabel>{element.name}</MenuLabel>
                        //     </MenuLine>
                        //   );
                        // case "AU":
                        //   return (
                        //     <MenuLine
                        //       onClick={() => {
                        //         this.openFilePicker("AU");
                        //       }}
                        //       key={"element" + index}>
                        //       <MenuIcon>
                        //         <VolumeUpIcon fontSize="inherit" />
                        //       </MenuIcon>
                        //       <MenuLabel>{element.name}</MenuLabel>
                        //     </MenuLine>
                        //   );
                        case "ME":
                          return (
                            <MenuLine
                              onClick={() => {
                                this.openFilePicker("ME");
                              }}
                              key={"element" + index}>
                              <MenuIcon>
                                <PlayCircleOutlineIcon fontSize="inherit" />
                              </MenuIcon>
                              <MenuLabel>{element.name}</MenuLabel>
                            </MenuLine>
                          );
                        case "DM":
                          return (
                            <MenuLine
                              onClick={() => {
                                this.newElementSelection(element.id);
                              }}
                              key={"element" + index}>
                              <MenuIcon>
                                <SquareFootOutlinedIcon fontSize="inherit" />
                              </MenuIcon>
                              <MenuLabel>{element.name}</MenuLabel>
                            </MenuLine>
                          );
                        case "CO":
                          return (
                            <MenuLine
                              onClick={() => {
                                this.newElementSelection(element.id);
                              }}
                              key={"element" + index}>
                              <MenuIcon>
                                <CompareOutlinedIcon fontSize="inherit" />
                              </MenuIcon>
                              <MenuLabel>{element.name}</MenuLabel>
                            </MenuLine>
                          );
                        default:
                          return (
                            <MenuLine
                              onClick={() => {
                                this.newElementSelection(element.id);
                              }}
                              key={"element" + index}>
                              <MenuIcon>
                                <TextFieldsOutlinedIcon fontSize="inherit" />
                              </MenuIcon>
                              <MenuLabel>{element.name}</MenuLabel>
                            </MenuLine>
                          );
                      }
                    })}
                    <DividerLine />
                    <MenuLineCommand
                      onClick={() => {
                        this.selectOtherProject();
                      }}>
                      <MenuIcon>
                        <ArrowForwardIosIcon fontSize="inherit" />
                      </MenuIcon>
                      <MenuLabel>Select other Recent Project</MenuLabel>
                    </MenuLineCommand>
                    <DividerLine />
                    <MenuLineCommandLast
                      onClick={() => {
                        this.newProject();
                      }}>
                      <MenuIcon>
                        <CardTravelOutlinedIcon fontSize="inherit" />
                      </MenuIcon>
                      <MenuLabel>Create New Project</MenuLabel>
                    </MenuLineCommandLast>
                  </ElementCard>
                ) : (
                  ""
                )}
                {this.state.showTopMenu ? (
                  <ElementCard>
                    <SectionLabel>Add To Recent Project</SectionLabel>
                    {recentprojects.length ? (
                      ""
                    ) : (
                      <NoProjectText>no recent projects yet</NoProjectText>
                    )}
                    {recentprojects
                      .slice(
                        0,
                        this.state.showAllRecent ? recentprojects.length : 5
                      )
                      .map((project, index) => {
                        return (
                          <MenuLine
                            key={index}
                            onClick={() => {
                              this.newElement(project.id);
                            }}>
                            <MenuIcon>
                              <ArrowForwardIosIcon fontSize="inherit" />
                            </MenuIcon>
                            <MenuLabel title={project.name}>
                              {project.name}
                            </MenuLabel>
                          </MenuLine>
                        );
                      })}
                    {this.state.showAllRecent || !recentprojects.length ? (
                      ""
                    ) : (
                      <MenuLine
                        onClick={() => {
                          this.showAllRecent();
                        }}>
                        <MenuIcon></MenuIcon>
                        <MenuLabel>More...</MenuLabel>
                      </MenuLine>
                    )}

                    <DividerLine />
                    <MenuLineCommandLast
                      onClick={() => {
                        this.newProject();
                      }}>
                      <MenuIcon>
                        <CardTravelOutlinedIcon fontSize="inherit" />
                      </MenuIcon>
                      <MenuLabel>Create New Project</MenuLabel>
                    </MenuLineCommandLast>
                  </ElementCard>
                ) : (
                  ""
                )}
                {this.state.showNewProject ? (
                  <NewDialog
                    isMobile={dimensions.window_dimensions.isMobile}
                    width={dimensions.window_dimensions.w}>
                    <ProjectPageEdit
                      project_url={window.location.pathname}
                      new_dialog={true}></ProjectPageEdit>
                  </NewDialog>
                ) : (
                  ""
                )}
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 2:
        return (
          <div
            style={{ width: "100%", display: "flex" }}

          >

            <MenuPanel isMobile={dimensions.window_dimensions.isMobile}>

              <NewDialog
                isMobile={dimensions.window_dimensions.isMobile}
                width={dimensions.window_dimensions.w}>
                <ProfilePageEdit />
              </NewDialog>

            </MenuPanel>
          </div>
        );
      case 15:
        return (
          <div
            style={{ width: "100%", display: "flex" }}

          >

            <MenuPanel isMobile={dimensions.window_dimensions.isMobile}>

              <NewDialog
                isMobile={dimensions.window_dimensions.isMobile}
                width={dimensions.window_dimensions.w}>
                <ProfilePageEdit subscription_direct={true} />
              </NewDialog>

            </MenuPanel>
          </div>
        );
      case 3:
        return (
          <MenuPanelNotification>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <ElementCardNotification>
                  <NotificationsList />
                </ElementCardNotification>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanelNotification>
        );
      case 4:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog isMobile={dimensions.window_dimensions.isMobile}>
                  <GroupPageEdit />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 5:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <AlertDialog>
                  <CircularProgress
                    variant="determinate"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "10px",
                    }}
                    value={progress}
                  />
                  Uploading File ({progress} %)
                  {timeouterror ? (
                    <TimeOutError>
                      There is a problem with the network.
                      <br />
                      Please refresh the page and try again.
                    </TimeOutError>
                  ) : (
                    ""
                  )}
                </AlertDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 6:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <ProjectPageEdit
                    project_url={window.location.pathname}
                    new_dialog={true}
                    shared_with_group={this.state.shared_with_group}
                    ></ProjectPageEdit>
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 7:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  onClick={(e) => {
                    this.doNothing(e);
                  }}
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <FeedBackDialog
                    page_url={window.location.pathname}></FeedBackDialog>
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 8:
        return (
          <MenuPanelProfileMenu
            onClick={(e) => {
              this.doNothing(e);
            }}
          >
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <ElementCardProfileMenu>
                  <MenuLineFirst
                    onClick={() => {
                      this.handleShowMenu(true, 2);
                    }}>
                    <MenuLabelSingle>Profile Settings</MenuLabelSingle>
                  </MenuLineFirst>
                  <MenuLine
                    onClick={() => {
                      this.handleShowHelp();
                    }}>
                    <MenuLabelSingle>Made Help</MenuLabelSingle>
                  </MenuLine>
                  <MenuLine
                    onClick={() => {
                      this.handleShowMenu(true, 7);
                    }}>
                    <MenuLabelSingle>Send Feedback</MenuLabelSingle>
                  </MenuLine>
                  <MenuLine
                    onClick={() => {
                      this.showTerms();
                    }}>
                    <MenuLabelSingle>Terms</MenuLabelSingle>
                  </MenuLine>
                  <MenuLine
                    onClick={() => {
                      this.handleShowAbout();
                    }}>
                    <MenuLabelSingle>About</MenuLabelSingle>
                  </MenuLine>
                  <MenuLineLast
                    onClick={() => {
                      this.logOut();
                    }}>
                    <MenuLabelSingle>Sign Out</MenuLabelSingle>
                  </MenuLineLast>
                </ElementCardProfileMenu>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanelProfileMenu>
        );
      case 9:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <HelpPage />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 10:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <GlobalInvite dialogType={"followers"} />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 11:
        return (
          <MenuPanel onClick={(e) => {
            this.doNothing(e);
          }}>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <GlobalInvite dialogType={"group"} />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 12:
        return (
          <MenuPanel onClick={(e) => {
            this.doNothing(e);
          }}>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <GlobalInvite dialogType={"org"} />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 13:
        return (
          <MenuPanel onClick={(e) => {
            this.doNothing(e);
          }}>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <LoginDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <Login isDialog={true} />
                </LoginDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 14:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <AlertDialog>
                  There was an error
                  <br /> {genericerror}
                </AlertDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
     
      case 16:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <ProfilePageSubscribers />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 17:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <ProfilePageSubscriptions />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
      case 18:
        return (
          <MenuPanel>
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <FeedPageSubscriptions />
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
        );
        case 19:
          return (
            <MenuPanel
            style={{backgroundColor: "transparent"}}
              onClick={(e) => {
                this.doNothing(e);
              }}
            >
              <ElementContainerNarrow >
                <TopContainerNarrow>
                  <LoginDialog
                    style={{margin: "10px"}}
                    isMobile={dimensions.window_dimensions.isMobile}
                    width={dimensions.window_dimensions.w}>
                    <Waitlist isDialog={true} />
                  </LoginDialog>
                </TopContainerNarrow>
              </ElementContainerNarrow>
            </MenuPanel>
          );  
          case 20:
          return (
            <MenuPanel onClick={(e) => {
              this.doNothing(e);
            }}>
              <ElementContainerNarrow>
                <TopContainerNarrow>
                 
                    <ElementCard>
                      {/* <SectionLabel>Select Element Type to Add</SectionLabel> */}
  
                     
                      <DividerLine />
                      <MenuLineCommand
                        onClick={() => {
                          this.addtogroup();
                        }}>
                        <MenuIcon>
                          <ArrowForwardIosIcon fontSize="inherit" />
                        </MenuIcon>
                        <MenuLabel>Select Existing Project</MenuLabel>
                      </MenuLineCommand>
                      <DividerLine />
                      <MenuLineCommandLast
                        onClick={() => {
                          this.newProject(this.props.current_group);
                        }}>
                        <MenuIcon>
                          <CardTravelOutlinedIcon fontSize="inherit" />
                        </MenuIcon>
                        <MenuLabel>Create New Project</MenuLabel>
                      </MenuLineCommandLast>
                    </ElementCard>
                
                
                </TopContainerNarrow>
              </ElementContainerNarrow>
            </MenuPanel>
          );  
          case 21:
            return (
              <MenuPanel >
            <ElementContainerNarrow>
              <TopContainerNarrow>
                <NewDialog
                 
                  isMobile={dimensions.window_dimensions.isMobile}
                  width={dimensions.window_dimensions.w}>
                  <GroupProjectAdd />
                   
                 
                </NewDialog>
              </TopContainerNarrow>
            </ElementContainerNarrow>
          </MenuPanel>
            );  
            case 22:
              return (
                <div
                  style={{ width: "100%", display: "flex" }}
      
                >
      
                  <MenuPanel isMobile={dimensions.window_dimensions.isMobile}>
      
                    <NewDialog
                      isMobile={dimensions.window_dimensions.isMobile}
                      width={dimensions.window_dimensions.w}>
                      <ProfilePageEdit showplan={true} />
                    </NewDialog>
      
                  </MenuPanel>
                </div>
              );
              case 23:
                return (
                  <MenuPanel>
                    <ElementContainerNarrow>
                      <TopContainerNarrow>
                        <NewDialog
                          isMobile={dimensions.window_dimensions.isMobile}
                          width={dimensions.window_dimensions.w}>
                        <FeedbackRequest/>
                        </NewDialog>
                      </TopContainerNarrow>
                    </ElementContainerNarrow>
                  </MenuPanel>
                );
              default:
        // code block
        return null
    }
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,
  recentprojects: state.auth.recentpages,
  notifications: state.auth.notifications,
  current_creator: state.project.current_project.creator.id,
  current_group: state.groups.current_group.id,
  myid: state.auth.user.user_id,
  menuType: state.ui.menuType,
  progress: state.ui.uploadprogress,
  timeouterror: state.ui.timeouterror,
  genericerror: state.ui.genericerror,
  auth: state.auth,
  // profile_id: state.profile.current_profile.id
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(UberMenu));
