import React, { Component, useMemo } from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  logoutUser,
  updateTicket
} from "../actions/authActions";
import {
  uploadSaveProfile,
  saveProfile,
  createSubscription,
  getCurrentPlan,
  handleShowCheckout,
  handleShowPortal,

} from "../actions/profileActions";
import { resetProjectLink } from "../actions/uiActions";
import mimetype from "../config/mimetype";
import CircularProgress from "@mui/material/CircularProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { api_endpoint } from "../config";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import CloseIcon from "../custom-icons/CloseIcon";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PanoramaHorizontalIcon from "@mui/icons-material/PanoramaHorizontal";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SellIcon from "@mui/icons-material/Sell";
import PaidIcon from "@mui/icons-material/Paid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import StarIcon from "@mui/icons-material/Star";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";
import {
  ElementContainer,
  TopContainer,
  ElementCard,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  PanelThree,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton
} from "./styles/MenuComponents.js";

// import {
//   useStripe,
//   useElements,
//   CardElement,
//   PaymentElement,
//   ElementsConsumer,
//   Elements,
//   CardNumberElement,
//   CardExpiryElement,
//   CardCvcElement,
// } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import SubscribeForm from "./auth/SubscribeForm";
import axiosInstance from "../utils/axiosApi";
import imgUrl from "../utils/imgUrl";

const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;


const StyledError = styled.div`
  margin-top: 4px;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};

  height: 100%;
  //   background-color: #ccc;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% - 90px)")};
  display: flex;
  flex-direction: column;
`;

const SectionGroupHeader = styled.div`
  display: flex;
  color: #757575;
  font-size: 14px;
  margin-bottom: 13px;
`;

const CoverImage = styled.div`
  width: ${(props) => (props.isMobile ? props.width - 96 + "px" : "273px")};
  height: ${(props) => (props.isMobile ? props.width - 96 + "px" : "273px")};

  border: 10px solid #f2f2f2;
  border-radius: ${(props) =>
    props.isMobile ? (props.width - 44) / 2 + "px" : "176px"};
  background: url("${(props) => props.src}");
  background-color: #ccc;
  position: relative;
  background-position: center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.25));
`;
const UploadButtons = styled.div`
  // margin: auto;
  // width: 140px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 4px;
`;
const DirectSpacer = styled.div`
  height: ${(props) => (props.isMobile ? "80px" : "150px")};
`;
// const UploadButton = styled(Button)`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 140px;
//   background-color: rgba(255, 255, 255, 0.8);
//   color: #2196f3;
//   height: 28px;
//   border-radius: 2px;

//   padding-right: 10px;
//   padding-left: 10px;
//   padding-top: 10px;
//   margin-top: 20px;

//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: #2196f3;
  color: #fff;
  border-radius: 16px;

  margin-left: auto;
  margin-right: auto;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 8px 4px;
  text-transform: capitalize;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  min-height: 100%;
  border-right: 1px solid #f2f2f2;
`;
const Tab = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  font-weight: ${(props) => (props.Selected ? "600" : "400")};
`;
const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => (props.isMobile ? "0px" : "30px")};
  min-height: 760px;
  min-width: ${(props) => (props.isMobile ? "100%" : "450px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};
`;
const TopInstruction = styled.div`
  font-family: Merriweather, serif;
  font-size: 18px;
  // font-weight: bold;
  color: #111;
  margin-top: 30px;
  margin-bottom: 20px;
`;
const PlanInstruction = styled.div`
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  // font-weight: bold;
  color: #383838;

  line-height: 24px;
`;
const PlanHeader = styled.div`
margin-top: 20px;
margin-bottom: 10px;
text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #383838;

  line-height: 24px;
`;
const Benefits = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 25px;
`;
const Choices = styled.div`
  align: center;
`;
const FieldBorder = styled.div`
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 2px;
`;
const ChoiceBorder = styled.div`
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 2px;
`;
const FieldLabel = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #ccc;
  padding-top: 10px;
  padding-bottom: 2px;
`;
const FieldButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const NamePreview = styled.div`
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  /* Dark-Gray */

  color: #383838;
  margin-top: 32px;
  width: 100%;
  display: block;
`;
const UsernamePreview = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  color: #404040;
  display: block;
  align-items: center;
  text-align: center;
  display: none;
`;
const TagLinePreview = styled.p`
  display: block;
  font-family: "Roboto";
  font-size: 14px;
  text-align: center;
  margin: 8px 0;
  color: #606060;
`;
const BannerPreview = styled.div`
  display: block;
  width: 100%;
  height: 192px;
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 32px;
`;
const inputStyle = {
  color: "#111",
  fontWeight: "500",
  fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
  fontSize: "16px",

  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#fce883",
  },
  "::placeholder": {
    color: "#ccc",
  },
};
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;

  // max-height: 100%;
  // width: 100%;
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight2 = styled.div`
  margin-top: 20px;

  margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "30px")};
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "340px")};
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    padding: 8px;
`;
const SubscriptionCard = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const CircularImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const SubscriptionsDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;
const SubName = styled.div`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
`;
const Description = styled.div`
  display: flex;
`;
const UnsubscribeButton = styled.div`
  display: flex;
`;
const SponsorSection = styled.div`
  display: flex;
`;
const SponsorLeft = styled.div`
  display: flex;
  background-image: url('${(props) => props.src}');
  background-color: ${(props) => props.src ? "transparent" : "#ccc"};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
`;
const SponsorRight = styled.div`
width: calc(100% - 40px);
display: flex;
padding-left: 10px;
padding-top: 10px;
`;
const SponsorName = styled.div`
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size: 16px;
`;
const SponsorPage = styled.div`
    color: #008EFF;
    margin-top: 3px;
    margin-left: auto;
    font-size: 16px;
`;
const SponsorContainer = styled.div`
border: 1px solid #ccc;
    max-height:  ${(props) => (props.isMobile ? "auto" : "300px")};
    overflow-y: auto;
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 20px;
`;
// const InjectedCheckoutForm = (plan) => (
//   <ElementsConsumer>
//     {({ stripe, elements }) => (
//       <SubscribeForm plan={plan} stripe={stripe} elements={elements} />
//     )}
//   </ElementsConsumer>
// );
class ProfilePageEdit extends Component {
  constructor(props) {
    super(props);
    this.savePage = this.savePage.bind(this);

    this.state = {
      id: "",
      username: "",
      email: "",
      image: "",
      fullname: "",
      tag_line: "",
      imageRaw: "",
      subscribed: true,
      subscribedProduct: true,
      imagePreview: false,
      showImageProgress: false,
      initial: true,
      direct: false,
      plan_selection: 3,
      show_card_info: false,
      pk: "",
      cs: "",
      hiddenfeature: true,
      sub_ready: false,
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      panelW: 288,
      slide: false,
      x1: 0,
      x2: 0,
      pastState: "",
      banner: null,
      bannerRaw: null,
      bannerPreview: null,
      hasChanges: false,
      showSaveConfirmation: false,
      subFilter: "projects",
      hide_subscriptions: false,
      hide_profile: false,
      ticket: null,
      ticket_loading: true,
      change_plan: null,
      ticket_number: "",
      ticket_number_error: "",
      sponsor_selection: "",
      sponsor_selection_error: "",
      sponsors: [],
      sponsors_loading: true,
    };
  }
  onSlide = (state, bool) => {
    this.setState({ slide: true });
  };
  onChangeSection3Number = (result) => {
    console.log(result)
    this.setState({ panelthree: result });

    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x1: -(this.props.dimensions.window_dimensions.w - 64) });
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) * 2 });
    } else {
      this.setState({ x1: -this.state.panelW });
      this.setState({ x2: -(this.state.panelW) * 2 });
    }
  }
  onSlidebackSection3 = (state, bool) => {

    this.setState({ x1: -this.state.panelW * 2 });
    this.setState({ x2: -this.state.panelW });

  };
  handleChangeSponsor = (e) => {
    // this.setState({ hasChanges: true });
    this.setState({ sponsor_selection: e.target.value });
  };
  SignUpSponsor = () => {
    this.setState({ ticket_loading: true })
    axiosInstance.post("/me/ticket/sponsor/", { key: this.state.sponsor_selection }).then(res => {
      this.props.dispatch(
        updateTicket(
          res.data
        )
      );
      this.setState({ ticket: res.data, ticket_loading: false })
      this.onSlidebackSection3();
    }).catch(err => {
      let errText = "An error occured."
      try {
        errText = err.response.data.detail
      } catch (e) {
        console.error(err)
        console.error(e)
      }
      this.setState({ sponsor_selection_error: errText })
    })
  };
  SignUpWithTicket = () => {
    this.setState({ ticket_loading: true, ticket_number_error: "" })
    axiosInstance.post("/me/ticket/code/", { key: this.state.ticket_number }).then(res => {
      this.props.dispatch(
        updateTicket(
          res.data
        )
      );
      this.setState({ ticket: res.data, ticket_loading: false })
      this.onSlidebackSection3();
    }).catch(err => {
      let errText = "An error occured."
      try {
        if (err.response.status == 404) {
          errText = "Invalid ticket number."
        } else {
          errText = err.response.data.detail
        }
      } catch (e) {
        console.error(err)
        console.error(e)
      }
      this.setState({ ticket_number_error: errText })
    })
  };
  onChangeSectionNumber = (num) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });

    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) });
    } else {
      this.setState({ x2: -this.state.panelW });
    }
    switch (num) {
      case 1:
        this.setState({
          pastState: [
            this.state.fullname,
            this.state.tag_line,
            this.state.username,
            this.state.email,
          ],
        });
        break;
      case 2:
        this.setState({
          pastState: [this.state.subscribed, this.state.subscribedProduct],
        });
        break;
      case 3:
        if (!this.state.ticket) {
          this.getSponsorList();
        }
      case 4:
        this.setState({
          pastState: [
            this.state.image,
            this.state.imageRaw,
            this.state.imagePreview,
          ],
        });
        break;
      case 5:
        this.setState({
          pastState: [this.state.bannerPreview, this.state.bannerRaw],
        });
        break;

      default:
      // code block
    }
  };
  onSlideback = (state, bool) => {
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 1:
        this.setState({ fullname: this.state.pastState[0] });
        this.setState({ tag_line: this.state.pastState[1] });
        this.setState({ username: this.state.pastState[2] });
        this.setState({ email: this.state.pastState[3] });
        break;
      case 2:
        this.setState({ subscribed: this.state.pastState[0] });
        this.setState({ subscribedProduct: this.state.pastState[1] });
        break;
      case 3:
        break;
      case 4:
        this.setState({ image: this.state.pastState[0] });
        this.setState({ imageRaw: this.state.pastState[1] });
        this.setState({ imagePreview: this.state.pastState[2] });

        break;
      case 5:
        this.setState({ bannerPreview: this.state.pastState[0] });
        this.setState({ bannerRaw: this.state.pastState[1] });

        break;
      default:
      // code block
    }
  };
  onChange = (e) => {
    this.setState({ hasChanges: true });
    console.log(`${e.target.id}: ${e.target.value}`);
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeSubscribed = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ subscribed: !this.state.subscribed });
  };
  onChangePrivacySubscribers = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ hide_subscriptions: !this.state.hide_subscriptions });
  };
  onChangeHideProfile = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ hide_profile: !this.state.hide_profile });
  };
  onChangeSubscribedProduct = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ subscribedProduct: !this.state.subscribedProduct });
  };
  onChangeRadio = (e) => {
    this.setState({ project_state: e.target.value });
  };
  logOut = () => {
    this.props.dispatch(logoutUser());
  };
  onChangeGroups = (e) => {
    //console.log(e.target.id);
    let tempmemberships = this.state.memberships;
    //console.log(tempmemberships);
    tempmemberships.forEach((membership) => {
      if (membership.id === e.target.id) {
        membership.checked = e.target.checked;
      }
    });
    this.setState({ memberships: tempmemberships });
  };
  onChangeRadioPublic = (e) => {
    this.setState({ public: e.target.value });
  };
  handleSubmit = () => {
    console.log("submit");
  };
  handleChangePlan = (e) => {
    this.setState({ plan_selection: e.target.value });
    this.setState({ show_card_info: true });
  };
  deletePage = () => { };
  savePage = () => {
    //console.log(this.state.subscribed);

    let data;

    data = {
      fullname: this.state.fullname,
      tag_line: this.state.tag_line,
      username: this.state.username,
      email: this.state.email,
      email_subscribed: this.state.subscribed,
      email_subscribed_marketing: this.state.subscribedProduct,
      hide_subscriptions: this.state.hide_subscriptions,
      hide_profile: this.state.hide_profile,
    };

    if (this.state.imagePreview || this.state.bannerPreview) {
      // first upload and then save
      this.props.dispatch(
        uploadSaveProfile(
          this.state.id,
          data,
          this.state.imageRaw,
          this.state.bannerRaw
        )
      );
    } else {
      this.props.dispatch(saveProfile(this.state.id, data));
    }
    if (this.state.direct) {
      this.props.history.push("/");
    }
  };

  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
  }
  cancelSub() {
    this.setState({ ticket_number: "", ticket_number_error: "", sponsor_selection: "", sponsor_selection_error: "" })
  }

  changeSub() {
    this.props.dispatch(handleShowPortal());
  }
  resetLink() {
    this.props.dispatch(resetProjectLink(true));
    this.handleCloseDialog()
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    if (this.state.direct) {
      this.props.history.push("/");
    }
  }
  handleCloseDialogCheck() {
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      this.props.dispatch(setMenu(false));
      if (this.state.direct) {
        this.props.history.push("/");
      }
    }
  }
  handleSelectImage(url, id) {
    // there is a bug here when you upload image, then select from element and the upload the same image again.
    this.setState({ selectedImage: url });
    this.setState({ selectedImageID: id });
    this.setState({ imagePreview: false });
    this.setState({ imageRaw: "" });
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
  }
  setInitialGroupSelection(memberships, groups) {
    //console.log(memberships);
    memberships.forEach((membership) => {
      //console.log(membership);
      membership.checked = false;
      if (groups) {
        groups.forEach((group) => {
          if (membership.id === group.id) {
            membership.checked = true;
          }
        });
      }
    });
    //console.log(memberships);
    this.setState({ memberships: memberships });
  }
  handleOpenPopup() {
    if (this.state.showpopup === false) {
      this.setState({ showpopup: true });
    }
  }
  doNothing(e) {
    e.stopPropagation();
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }
  keyPress = (e) => {
    // this doesn't seem to work in safari

    if (e.ctrlKey && e.key === "z") {
      this.setState({ hiddenfeature: true });
    }
  };
  componentDidMount() {
    // document.addEventListener("keydown", this.keyPress);
    const { dimensions, showplan } = this.props;

    const url = window.location.pathname;
    if (url === "/settings") {
      this.setState({ direct: true });
    }
    const urlparts = window.location.search;
    console.log(urlparts);
    const status = urlparts.split("=");
    if (status[0] === "?status") {

      if (status[1] === "ticket") {
        this.onChangeSectionNumber(3);
        this.onChangeSection3Number(1);
      } else {
        this.onChangeSectionNumber(3);
      }
    }
    if (showplan) {
      this.onChangeSectionNumber(3);
    }
    const { profile } = this.props;
    // this.props.dispatch(getCurrentPlan());
    this.getTicket();
    console.log("m");
    this.setState({
      id: profile.id,
      username: profile.username,
      fullname: profile.fullname,
      tag_line: profile.tag_line,
      subscribed: profile.email_subscribed,
      subscribedProduct: profile.email_subscribed_marketing,
      email: profile.email,
      hide_profile: profile.hide_profile,
      hide_subscriptions: profile.hide_subscriptions
    });
    if (profile.avatar) {
      this.setState({
        image: api_endpoint + "/media/" + profile.avatar.id + "/file?size=512",
      });
    }
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
  }
  componentDidUpdate() {
    const { profile, loading, subscription, dimensions } = this.props;
    //console.log("update");
    if (Object.keys(subscription).length) {
      if (this.state.sub_ready === false) {
        this.setState({ sub_ready: true });
      }
    }
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
    if (this.state.initial && !loading) {
      //console.log("now");
      //console.log(profile);
      this.setState({ id: profile.id });
      this.setState({ username: profile.username });
      this.setState({ fullname: profile.fullname });
      this.setState({ tag_line: profile.tag_line });
      this.setState({ subscribed: profile.email_subscribed });
      this.setState({ subscribedProduct: profile.email_subscribed_marketing });
      this.setState({ email: profile.email });
      this.setState({ hide_profile: profile.hide_profile });
      this.setState({ hide_subscriptions: profile.hide_subscriptions });
      if (profile.avatar) {
        this.setState({
          image:
            api_endpoint + "/media/" + profile.avatar.id + "/file?size=512",
        });
      }
      this.setState({ initial: false });
    }
  }
  handleAvatarChange = (e) => {
    console.log("avatar");
    this.setState({ selectedImage: "" });
    if (e.target.files.length) {
      this.setState({ hasChanges: true });
      this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
      this.setState({ imageRaw: e.target.files[0] });
    }
  };

  handleBannerChange = (e) => {
    this.setState({ selectedImage: "" });
    if (e.target.files.length) {
      this.setState({ hasChanges: true });
      this.setState({ bannerPreview: URL.createObjectURL(e.target.files[0]) });
      this.setState({ bannerRaw: e.target.files[0] });
    }
  };
  showCheckout = () => {
    // console.log("hello")
    this.props.dispatch(handleShowCheckout(this.state.plan_selection));
  };
  getTicket = () => {
    this.setState({ ticket_loading: true })
    axiosInstance.get("/me/ticket/").then(res => this.setState({ ticket: res.data, ticket_loading: false }))
  }
  getSponsorList = () => {
    this.setState({ sponsors_loading: true })
    axiosInstance.get("/sponsors/").then(res => this.setState({ sponsors: res.data.results, sponsors_loading: false }))
  }
  deleteTicket = () => {
    this.setState({ ticket: null, ticket_loading: false })
    axiosInstance.delete("/me/ticket/").then(res => {
      this.props.dispatch(
        updateTicket(
          null
        )
      );
      this.setState({ ticket: null, ticket_loading: false })
    })
  }
  changePlan = () => {
    this.setState({ change_plan: this.state.ticket })
    this.setState({ ticket: null, ticket_loading: false });
    this.getSponsorList();
  }
  onPlanEditSlideback = () => {
    if (this.state.change_plan) {
      this.setState({ ticket: this.state.change_plan, ticket_loading: false, change_plan: null });
      this.getTicket();
    } else {
      this.onSlideback();
    }
  }
  formatCurrency(amount, currency) {
    switch (currency) {
      case "usd":
        return "$" + amount / 100
      case "eur":
        return "€" + amount / 100
      default:
        return `${amount / 100} ${currency}`
    }
  }

  render() {
    const { dimensions, errors, subscription, plan_error, subscriptions } =
      this.props;
    const { ticket } = this.state;
    //console.log("render");
    // let imagesrc = this.state.image;
    // if (this.state.imagePreview) {
    //   imagesrc = this.state.imagePreview;
    // } else {
    //   if (this.state.selectedImage) {
    //     imagesrc = this.state.selectedImage;
    //   }
    // }
    let pageTitle = "Profile Settings";
    // let current_plan = "Free, limited";

    // switch (this.props.profile.plan) {
    //   case "FRE":
    //     current_plan = "Limited, Free";
    //     break;
    //   case "PRO":
    //     current_plan = "Unlimited, Monthly";
    //     break;
    //   case "ORG":
    //     current_plan = "Orgnization Plan, Monthly";
    //     break;
    //   case "UNK":
    //     current_plan = "Limited, Free";
    //     break;
    //   default:
    //   // code block
    // }
    let banner;
    if (this.props.profile.banner) {
      banner = imgUrl(this.props.profile.banner.id, 512);
    }
    // if (this.state.pk) {
    //   const stripePromise = loadStripe(this.state.pk);

    return (
      <div style={{ height: "100%" }}>
        {this.state.showSaveConfirmation ? (
          <StyledElementPopup>
            <ElementPanel>
              <ElementCardConfirmation
                isMobile={dimensions.window_dimensions.isMobile}>
                <ConfirmationDescription>
                  This profile has changes that have not been saved.
                </ConfirmationDescription>
                <ButtonGroup isMobile={dimensions.window_dimensions.isMobile}>
                  <StyledButtonLeft2
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <Button
                      onClick={() => {
                        this.handleCloseDialog();
                      }}
                      disableElevation
                      variant="outlined"
                      color="primary"
                      style={{ minWidth: "150px" }}>
                      Don't Save
                    </Button>
                  </StyledButtonLeft2>
                  <StyledButtonRight2
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <Button
                      onClick={() => {
                        this.savePage();
                      }}
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "180px" }}>
                      Save Changes
                    </Button>
                  </StyledButtonRight2>
                </ButtonGroup>
              </ElementCardConfirmation>
            </ElementPanel>
          </StyledElementPopup>
        ) : (
          ""
        )}
        {this.state.direct ? (
          <DirectSpacer isMobile={dimensions.window_dimensions.isMobile} />
        ) : (
          ""
        )}
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog
              isMobile={dimensions.window_dimensions.isMobile}
              dialog_height={550}
              dialog_width={675}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Profile Settings
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialogCheck();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                <ColumnMiddle isMobile={dimensions.window_dimensions.isMobile}>
                  <CoverImage
                    isMobile={this.props.dimensions.window_dimensions.isMobile}
                    width={this.props.dimensions.window_dimensions.w}
                    src={this.state.imagePreview || this.state.image}
                  />
                  <NamePreview>
                    {this.state.fullname || this.state.username}
                  </NamePreview>
                  {this.state.fullname ? (
                    <UsernamePreview>{this.state.username}</UsernamePreview>
                  ) : null}
                  <TagLinePreview>{this.state.tag_line}</TagLinePreview>
                </ColumnMiddle>
                <ColumnRight isMobile={dimensions.window_dimensions.isMobile}>
                  <CropPanel
                    w={this.state.panelW}
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                      <PanelOne w={this.state.panelW}>
                        {this.state.paneltwo === 0 ? (
                          <div>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(1);
                              }}>
                              <SectionIcon>
                                <AccountCircleIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Profile</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(6);

                              }}>
                              <SectionIcon>
                                <VisibilityIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Privacy</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(4);
                              }}>
                              <SectionIcon>
                                <AddAPhotoIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>
                                Change Profile Picture
                              </SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(5);
                              }}>
                              <SectionIcon>
                                <PanoramaHorizontalIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Change Banner</SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(2);
                              }}>
                              <SectionIcon>
                                <NotificationsIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Notifications</SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangeSectionNumber(3);
                              }}>
                              <SectionIcon>
                                <PaidIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Plan</SectionLabel>
                            </EditItem>
                          </div>
                        ) : (
                          ""
                        )}
                      </PanelOne>
                      <PanelTwo w={this.state.panelW}>
                        {
                          {
                            0: (
                              <SectionContent
                                w={this.state.panelW}></SectionContent>
                            ),
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Profile</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="fullname"
                                      label="Name"
                                      variant="outlined"
                                      value={this.state.fullname || ""}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="tag_line"
                                      multiline
                                      rows={3}
                                      label="About"
                                      variant="outlined"
                                      value={this.state.tag_line || ""}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="username"
                                      label="User Name"
                                      variant="outlined"
                                      value={this.state.username || ""}
                                      onChange={this.onChange}
                                    />
                                    <StyledError>{errors.username}</StyledError>
                                  </FormControl>
                                </ControlLine>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="email"
                                      label="Email"
                                      variant="outlined"
                                      value={this.state.email || ""}
                                      onChange={this.onChange}
                                    />
                                    <StyledError>{errors.email}</StyledError>
                                  </FormControl>
                                </ControlLine>

                                <SectionHelpText></SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            2: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Notifications</SectionLabel2>
                                </SectionHeader>

                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl component="fieldset">
                                    <FormGroup>
                                      <FormControlLabel
                                        // style={{ marginTop: "10px" }}
                                        onClick={(e) =>
                                          this.onChangeSubscribed()
                                        }
                                        control={
                                          <Checkbox
                                            style={{ marginBottom: "auto", paddingTop: 0 }}
                                            disableRipple
                                            color="primary"
                                            checked={this.state.subscribed}
                                            onChange={console.log}
                                            name="subscribed"
                                            id="subscribed"
                                          />
                                        }
                                        label="Subscribed to daily notification emails"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </ControlLine>

                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl component="fieldset">
                                    <FormGroup>
                                      <FormControlLabel
                                        // style={{ marginTop: "10px" }}
                                        onClick={(e) =>
                                          this.onChangeSubscribedProduct()
                                        }
                                        control={
                                          <Checkbox
                                            disableRipple
                                            style={{ marginBottom: "auto", paddingTop: 0 }}
                                            color="primary"
                                            checked={
                                              this.state.subscribedProduct
                                            }
                                            name="subscribed"
                                            id="subscribed"
                                          />
                                        }
                                        label="Subscribed to emails about updates to Sequentially"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </ControlLine>

                                <SectionHelpText>
                                  You will only receive notification emails when
                                  there are updates to your subscriptions.
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(2);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            3: (this.state.ticket ? (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Plan</SectionLabel2>
                                </SectionHeader>
                                <SectionContent>
                                  {this.state.ticket.sponsor ? (
                                    <div>
                                      <PlanInstruction><p>You have a sponsored account.</p></PlanInstruction>
                                      {this.state.ticket.expire_at ? (
                                        <PlanInstruction>
                                          <p>Your ticket expires on&nbsp;
                                            <strong>
                                              {new Date(
                                                this.state.ticket.expire_at
                                              ).toLocaleDateString(
                                                "en-us",
                                                {
                                                  // weekday: "long",
                                                  year: "numeric",
                                                  month: "short",
                                                  day: "numeric",
                                                }
                                              )}
                                            </strong>
                                            .
                                          </p>
                                        </PlanInstruction>
                                      ) : ""}
                                      <SponsorSection>
                                        <SponsorLeft src={this.state.ticket.sponsor.logo ? this.state.ticket.sponsor.logo.upload : ""}></SponsorLeft>
                                        <SponsorRight>
                                          <SponsorName>{this.state.ticket.sponsor.name}</SponsorName>
                                          <SponsorPage><Link style={{ color: "#008EFF", textDecoration: "none" }} to={"/organization/" + this.state.ticket.sponsor.id + "/"} target="_blank"><OpenInNewIcon fontSize="inherit" /></Link></SponsorPage>

                                        </SponsorRight>

                                      </SponsorSection>
                                    </div>
                                  ) : ""}
                                  {this.state.ticket.subscription_details ? (<PlanInstruction>
                                    <p>You are paying {this.state.ticket.subscription_details.plan.interval}ly for Made.</p>
                                    {this.state.ticket.subscription_details.cancel_at_period_end ? (
                                      <p>Your plan will end on{" "}
                                        <strong>
                                          {new Date(
                                            subscription.renew_date
                                          ).toLocaleDateString(
                                            "en-us",
                                            {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                            }
                                          )}
                                        </strong>.
                                      </p>
                                    ) : (
                                      <p>You will be charged <strong>{this.formatCurrency(ticket.subscription_details.plan.amount, ticket.subscription_details.plan.currency)}</strong> on{" "}
                                        <strong>
                                          {new Date(
                                            ticket.expire_at
                                          ).toLocaleDateString(
                                            "en-us",
                                            {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                            }
                                          )}
                                        </strong>.
                                      </p>
                                    )}
                                  </PlanInstruction>) : ""}
                                </SectionContent>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{
                                        minWidth: "80px",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Back
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onChangeSection3Number(4);
                                      }}>
                                      Cancel Plan
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ) : (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPlanEditSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Plan</SectionLabel2>
                                </SectionHeader>
                                <Column>
                                  <PlanInstruction>You are currently on a limited plan, which allows only 1 project, no sharing, no groups and no public comments. <br /><br />There are two ways to sign up for an unlimited Made Account:</PlanInstruction>
                                  <PlanHeader>Free Sponsored Plan</PlanHeader>
                                  <EditItem
                                    onClick={() => {
                                      this.onChangeSection3Number(2);

                                    }}>
                                    <SectionIcon>
                                      <VolunteerActivismIcon fontSize="inherit" />
                                    </SectionIcon>
                                    <SectionLabel>Find a sponsor</SectionLabel>
                                  </EditItem>

                                  <EditItem
                                    onClick={() => {
                                      this.onChangeSection3Number(1);
                                    }}>
                                    <SectionIcon>
                                      <ConfirmationNumberIcon fontSize="inherit" />
                                    </SectionIcon>
                                    <SectionLabel>I have received a ticket number</SectionLabel>
                                  </EditItem>

                                  <PlanHeader>Personal Subscription</PlanHeader>
                                  <EditItem
                                    onClick={() => {
                                      this.onChangeSection3Number(3);

                                    }}>
                                    <SectionIcon>
                                      <CreditCardIcon fontSize="inherit" />
                                    </SectionIcon>
                                    <SectionLabel>Sign up with a credit card</SectionLabel>
                                  </EditItem>


                                </Column>
                                <SectionFooter>
                                  <ButtonContainer>

                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPlanEditSlideback();
                                      }}>
                                      Back
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            )),
                            4: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>
                                    Change Profile Picture
                                  </SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <Column>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                      component="label">
                                      Upload Image
                                      <input
                                        type="file"
                                        id="upload-avatar-button"
                                        accept={mimetype.image}
                                        multiple={false}
                                        style={{ display: "none" }}
                                        onChange={(e) =>
                                          this.handleAvatarChange(e)
                                        }
                                      />
                                    </Button>
                                  </Column>
                                </ControlLine>

                                <SectionHelpText>
                                  Pick an image you feel represents you. The
                                  reccomended size is 256x256 pixels.
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(4);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            5: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Change Banner</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <Column>
                                    <BannerPreview
                                      src={
                                        this.state.bannerPreview || banner
                                      }></BannerPreview>
                                    {/* <label htmlFor="upload-banner-button">
                                      {" "}
                                      <UploadButton>UPLOAD BANNER</UploadButton>{" "}
                                    </label> */}
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      disableElevation
                                      component="label">
                                      <input
                                        type="file"
                                        id="upload-banner-button"
                                        accept={mimetype.image}
                                        multiple={false}
                                        hidden
                                        onChange={(e) =>
                                          this.handleBannerChange(e)
                                        }
                                      />
                                      Upload Banner
                                    </Button>
                                  </Column>
                                </ControlLine>

                                <SectionHelpText>
                                  This image will be shown at the top of your
                                  profile. The reccomended size is 2560x720
                                  pixels.
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(5);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            6: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Privacy</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl component="fieldset">
                                    <FormGroup>
                                      <FormControlLabel
                                        // style={{ marginTop: "10px" }}
                                        onClick={(e) =>
                                          this.onChangePrivacySubscribers()
                                        }
                                        control={
                                          <Checkbox
                                            disableRipple
                                            // style={{ marginTop: "-25px" }}
                                            color="primary"
                                            checked={
                                              this.state.hide_subscriptions
                                            }
                                            style={{ marginBottom: "auto", paddingTop: 0 }}
                                            name="privacysubscribed"
                                            id="privacysubscribed"
                                          />
                                        }
                                        label="Hide my subscriptions and subscribers from my public profile."
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </ControlLine>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl component="fieldset">
                                    <FormGroup>
                                      <FormControlLabel
                                        // style={{ marginTop: "10px" }}
                                        onClick={(e) =>
                                          this.onChangeHideProfile()
                                        }
                                        control={
                                          <Checkbox
                                            disableRipple
                                            // style={{ marginTop: "-25px" }}
                                            color="primary"
                                            checked={
                                              this.state.hide_profile
                                            }
                                            style={{ marginBottom: "auto", paddingTop: 0 }}
                                            name="hideprofile"
                                            id="hideprofile"
                                          />
                                        }
                                        label="Hide my profile from search results on the Discover page."
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </ControlLine>
                                <SectionHelpText>People can always find your profile by typing the URL in the browser.</SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(6);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onSlideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                          }[this.state.paneltwo]
                        }
                      </PanelTwo>
                      <PanelThree w={this.state.panelW}>
                        {
                          {
                            0: (
                              <SectionContent
                                w={this.state.panelW}></SectionContent>
                            ),
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.cancelSub();
                                    this.onSlidebackSection3();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Enter Ticket Number</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="ticket_number"
                                      label="Ticket Number"
                                      variant="outlined"
                                      value={this.state.ticket_number || ""}
                                      onChange={this.onChange}
                                    />
                                    <StyledError>{this.state.ticket_number_error}</StyledError>
                                  </FormControl>
                                </ControlLine>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.cancelSub();
                                        this.onSlidebackSection3();
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.SignUpWithTicket();
                                      }}>
                                      Sign Up
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>

                              </SectionContent>
                            ),
                            2: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.cancelSub();
                                    this.onSlidebackSection3();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Find a sponsor</SectionLabel2>
                                </SectionHeader>
                                <PlanInstruction>A sponsored plan is free and will show the sponsor logo alongside your account. The sponsored plan is for a limited timeframe and the sponsor can revoke the plan based on terms violation.</PlanInstruction>
                                <SponsorContainer isMobile={
                                  dimensions.window_dimensions.isMobile
                                }>
                                  <Choices>
                                    <FormControl fullWidth>
                                      <RadioGroup
                                        value={
                                          this.state
                                            .sponsor_selection
                                        }
                                        onChange={
                                          this.handleChangeSponsor
                                        }>
                                        {this.state.sponsors.map((sponsor, index) => {

                                          return (

                                            <FormControlLabel
                                              key={"sponsor" + index}
                                              style={{
                                                widht: "100%",

                                                borderRadius: "3px",
                                                marginTop: "10px",
                                                marginRight: "0px",
                                              }}
                                              value={sponsor.id}
                                              control={
                                                <Radio
                                                  disableRipple
                                                />
                                              }
                                              label={(
                                                <SponsorSection>
                                                  <SponsorLeft src={sponsor.logo ? sponsor.logo.upload : ""}></SponsorLeft>
                                                  <SponsorRight>
                                                    <SponsorName>{sponsor.name}</SponsorName>
                                                    <SponsorPage><Link style={{ color: "#008EFF", textDecoration: "none" }} to={"/organization/" + sponsor.id + "/"} target="_blank"><OpenInNewIcon fontSize="inherit" /></Link></SponsorPage>

                                                  </SponsorRight>

                                                </SponsorSection>
                                              )}
                                            />
                                          )
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </Choices>
                                </SponsorContainer>
                                <StyledError>{this.state.sponsor_selection_error}</StyledError>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.cancelSub();
                                        this.onSlidebackSection3();
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.SignUpSponsor();
                                      }}>
                                      Sign Up
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>

                              </SectionContent>
                            ),
                            3: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlidebackSection3();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Personal Subscription</SectionLabel2>
                                </SectionHeader>
                                <div>
                                  <SettingsColumns>
                                    {this.props.sub_loading ? (
                                      <div>
                                        {" "}
                                        <CircularProgress
                                          size="1rem"
                                          style={{ margin: "auto" }}
                                        />{" "}
                                        Setting up your subscription
                                      </div>
                                    ) : (
                                      <div>
                                        <TopInstruction>
                                          Get unlimited access to
                                          everything on Made
                                        </TopInstruction>
                                        <Benefits>
                                          Cancel Anytime<br></br>
                                          Unlimited Projects<br></br>
                                          Unlimited Groups<br></br>
                                          Share Projects Publicly
                                          <br></br>
                                          Make Public Comments<br></br>
                                          100GB Storage<br></br>
                                        </Benefits>
                                        <Choices>
                                          <FormControl fullWidth>
                                            <RadioGroup
                                              value={
                                                this.state
                                                  .plan_selection
                                              }
                                              onChange={
                                                this.handleChangePlan
                                              }>
                                              <FormControlLabel
                                                style={{
                                                  widht: "100%",

                                                  borderRadius: "3px",
                                                  marginTop: "10px",
                                                  marginRight: "0px",
                                                }}
                                                value="price_1L49NlCWAQxpUIQCSrZbobVI"
                                                control={
                                                  <Radio
                                                    disableRipple
                                                  />
                                                }
                                                label="Monthly ($4.99 USD/Month)"
                                              />
                                              <FormControlLabel
                                                style={{
                                                  widht: "100%",

                                                  borderRadius: "3px",
                                                  marginTop: "10px",
                                                  marginRight: "0px",
                                                }}
                                                value="price_1L7jheCWAQxpUIQCjHAw9JiH"
                                                control={
                                                  <Radio
                                                    disableRipple
                                                  />
                                                }
                                                label="Yearly ($49.99 USD/Month)"
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </Choices>
                                      </div>
                                    )}
                                  </SettingsColumns>
                                  <SectionFooter>
                                    {subscription.active ? (
                                      <ButtonContainer>
                                        <Button
                                          disableElevation
                                          variant="contained"
                                          color="black"
                                          style={{ minWidth: "80px" }}
                                          onClick={() => {
                                            this.onSlidebackSection3();
                                          }}>
                                          Cancel
                                        </Button>
                                        <Button
                                          disableElevation
                                          variant="contained"
                                          color="primary"
                                          style={{
                                            minWidth: "80px",
                                            marginLeft: "auto",
                                          }}
                                          onClick={() => {
                                            this.changeSub();
                                          }}>
                                          Change Subscription
                                        </Button>
                                      </ButtonContainer>
                                    ) : (
                                      <ButtonContainer>
                                        <Button
                                          disableElevation
                                          variant="contained"
                                          color="black"
                                          style={{ minWidth: "80px" }}
                                          onClick={() => {
                                            this.onSlidebackSection3(3);
                                          }}>
                                          Cancel
                                        </Button>
                                        <Button
                                          disableElevation
                                          variant="contained"
                                          disabled={
                                            !this.state.show_card_info
                                          }
                                          color="primary"
                                          style={{
                                            minWidth: "80px",
                                            marginLeft: "auto",
                                          }}
                                          onClick={() => {
                                            this.showCheckout();
                                          }}>
                                          Subscribe
                                        </Button>
                                      </ButtonContainer>
                                    )}
                                  </SectionFooter>
                                </div>
                              </SectionContent>
                            ),
                            4: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onSlidebackSection3();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Cancel Plan</SectionLabel2>
                                </SectionHeader>
                                <PlanInstruction>Are you sure you want to cancel your plan? You will be unable to create new projects{(this.state.ticket && this.state.ticket.sponsor) ? ", and you may not be able to get your free ticket back from this sponsor." : "."}</PlanInstruction>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackSection3();
                                      }}>
                                      No
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.deleteTicket();
                                        this.onSlidebackSection3();
                                      }}>
                                      Yes
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>

                              </SectionContent>
                            )
                          }[this.state.panelthree]
                        }
                      </PanelThree>
                    </PanelContainer>
                  </CropPanel>
                  {this.state.paneltwo !== 0 ? (
                    ""
                  ) : (
                    <StyledButtonContainer w={this.state.panelW}>
                      <StyledLeftButtons
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <StyledButtonDelete>
                          <Button
                            disableElevation
                            variant="contained"
                            color="secondary"
                            style={{ minWidth: "128px" }}
                            onClick={() => {
                              this.logOut();
                            }}>
                            Log Out
                          </Button>
                        </StyledButtonDelete>
                      </StyledLeftButtons>

                      <StyledButton>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{
                            minWidth: "128px",
                            borderRadius: "16px",
                          }}
                          onClick={() => {
                            this.savePage();
                          }}>
                          Save
                        </Button>
                      </StyledButton>
                    </StyledButtonContainer>
                  )}
                </ColumnRight>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer>
      </div >
    );
    // } else {
    //   return <div></div>;
    // }
  }
}

const mapStateToProps = (state) => ({
  // userid: state.auth.user.user_id,
  profile: state.auth.myprofile,
  subscription: state.auth.plan_details,
  subscriptions: state.auth.subscriptions,
  loading: state.auth.loading,
  dimensions: state.dimensions,
  errors: state.errors,
  ticket: state.auth.ticket,
});
export default connect(mapStateToProps)(withRouter(ProfilePageEdit));
