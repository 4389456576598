import { CONSTANTS } from ".";
import axiosInstance from "../utils/axiosApi";
import { loading, success, failure } from "./uiActions";
import obj2url from "../utils/obj2url";
import analyticsInstance from "../utils/analytics";


export const setQuery = (query) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_QUERY,
        payload: query,
    });
}

export const clearSearchResults = () => (dispatch) => {
    dispatch({
        type: CONSTANTS.CLEAR_SEARCH_RESULTS,
    });
}

export const fetchSearch = (type, filters, login) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_SEARCH_LOADING,
        payload: "loading",
    });
    const ts = new Date();
    analyticsInstance.track("search", { type, query: filters })
    axiosInstance.get("search/" + type + "/" + obj2url(filters), { login }).then((res) => {
        dispatch({
            type: CONSTANTS.GET_SEARCH,
            payload: res.data,
            timestamp: ts,
            loadMore: false
        });
    });
}

export const fetchSearchMore = (url, login) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_SEARCH_LOADING,
        payload: "loading",
    });
    const ts = new Date();
    axiosInstance.get(url, { login }).then((res) => {
        dispatch({
            type: CONSTANTS.GET_SEARCH,
            payload: res.data,
            timestamp: ts,
            loadMore: true
        });
    });
}

export const fetchTags = (filters) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_TAGS_LOADING,
        payload: "loading",
    });
    axiosInstance.get("metadata/tags/" + obj2url(filters), { login: false }).then((res) => {
        dispatch({
            type: CONSTANTS.GET_TAGS,
            payload: res.data,
        });
    });
};

export const fetchCategories = (filters) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_CATEGORIES_LOADING,
        payload: "loading",
    });
    axiosInstance.get("metadata/categories/" + obj2url(filters), { login: false }).then((res) => {
        dispatch({
            type: CONSTANTS.GET_CATEGORIES,
            payload: res.data,
        });
    });
}