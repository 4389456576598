import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveGroup,
  uploadSaveGroup,
  deleteGroup,
  getGroupParticipants,
  acceptRequest,
  rejectRequest
} from "../actions/groupActions";
import {
  saveOrg,
  uploadSaveOrg,

} from "../actions/orgActions";
import {
  addMember,
  removeMember,
  changeRole,
  sendInvite,
  getConnections,
  removeInvite,
  getPendingInvites,
  getAllParticipants,
  loadMoreParticipants
} from "../actions/groupActions";
import {
  changeRoleOrg,
  removeMemberOrg,
  getAllOrgParticipants,
  loadMoreOrgParticipants
} from "../actions/orgActions";
import mimetype from "../config/mimetype";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import GroupUserTable from "./GroupUserTable";
import FormControl from "@mui/material/FormControl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setGroupSettings } from "../actions/uiActions";
import GroupThumb from "./thumbs/GroupThumb";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import { Button, FormLabel, Stack } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import InputLabel from "@mui/material/InputLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from '@mui/icons-material/Check';
import { api_endpoint } from "../config";
import CloseIcon from "../custom-icons/CloseIcon";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import TitleIcon from "@mui/icons-material/Title";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import HdrWeakOutlinedIcon from "@mui/icons-material/HdrWeakOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityIcon from '@mui/icons-material/Visibility';
import imgUrl from "../utils/imgUrl";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArchiveIcon from "@mui/icons-material/Archive";
import Menu, { MenuProps } from "@mui/material/Menu";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  PanelThree,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton
} from "./styles/MenuComponents.js";
import SubjectIcon from '@mui/icons-material/Subject';
import { groupMemberStatus, groupMemberRole } from "../config/shorthand";
import axiosInstance from "../utils/axiosApi";
import PersonThumb from "./thumbs/PersonThumb";
import friendlyTimeCalc from "../utils/timeAgo";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InputAdornment from '@mui/material/InputAdornment';


const OptionTitle = styled.div`
  // font-weight: bold;
  line-height: 1.5;
`;

const OptionSubtitle = styled.div`
  font-size: 0.75rem;
  opacity: 0.8;
  color: rgba(0, 0, 0, 0.54);
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "340px")};
    width: calc(100% - 18px);
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    ${(props) => props.top ? "border-top: 1px solid #e0e0e0" : ""};
    padding: 8px;
`;
const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 16px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
  // margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "-17px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "none")};
`;
// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;
// const StyledSelect = styled.select`
//   width: 150px;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   margin-right: 20px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;


const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const InviteInstruction = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  // letter-spacing: 0.00938em;
`;
const SearchResults = styled.div`
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
`;
const MemberList = styled.div`
  // height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: auto;
`;
const SearchLineItem = styled.div`
width: 100%;  
height: 70px;
  // border-bottom: 1px solid #ccc;
  display: flex;
  margin-top: 0px;
`;
const PendingItem = styled.div`
  height: 50px;

  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  border-bottom: 1px solid #ccc;
  display: flex;
`;
const PendingItemEmail = styled.div`
  margin-top: 15px;
`;
const DisplayNameSmall = styled.div`
  display: flex;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  // line-height: 1.5;
  margin-bottom: 3px;
  color: ##000000de;
  
  &:last-child {
    margin-bottom: 0;
  }
`;
const UserNameSmall = styled.div`
  display: inline-flex;
  font-size: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  // line-height: 1.5;
  color: #666666;

  ${DisplayNameSmall} & {
    align-self: center;
  }
`;
const TitleNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  // line-height: 1.5;
  color: ##000000de;
`;
const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src || '/default_icon.png'}") #008EFF;
  background-position: center;
  background-size: cover; 
  margin-right: 12px;
  flex-shrink: 0;
`;
const ParticipantLine = styled.div`
  // width: 10%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
 
`;
const ParticipantLabel = styled.div`
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    color: ##000000de;
 
`;
const ParticipantContent = styled.div`
  display: flex;
  flex-direction: column;
`
const LeftColumn = styled.div`
  // width: 10%;
  display: flex;
  flex-direction: column;
`;
const NameColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 2px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const LinkError = styled.div`
font-size: 12px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 400;
text-decoration: none;
color: #0073ec;
margin-top: 5px;
}
`;
const MidColumn = styled.div`
  width:100%
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnNarrow = styled.div`
  width:100px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnWide = styled.div`
  min-width:150px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const RightColumn = styled.div`
  // width: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
    margin-bottom: auto;
  text-align: left;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "350px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnSingle = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ParticipantControlLine = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? "10px" : "20px")};
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  display: flex;
  
`;
const ParticipantCol1 = styled.div`
  width: 60px;

  
`;
const ParticipantCol2 = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  
`;
const EmailError = styled.div`
font-size: 14px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 400;
line-height: 1.5;
color: #dd4d25;
margin-top: 20px;
`;
const ControlLineBanner = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  display: flex;
`;
const ControlContentMembers = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const BannerPreview = styled.div`
  display: block;
  width: 100%;
  height: 50px;
  background: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: 16px;
`
const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-top: 5px;
  margin-left: 15px;
`;

// const StyledButtonNew = styled.button`
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 150px;
//   background-color: #2cc0f4;
//   color: white;
//   height: 32px;
//   border-radius: 2px;

//   padding-right: 30px;
//   padding-left: 30px;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const AuthorImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
`;
const StyledElementPopup = styled.div`
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  height: calc(100% - 14px);
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledTable = styled.table`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
 
`;
const StyledTD = styled.td`
  text-align: center; 
  min-width: 50px;
 
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  // min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: min(400px, 95vw);
  height: auto;
  z-index: 2000;
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
`;
const StyledButtonRight2 = styled.div`
    margin-top: 20px;
    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
`;
const ViewUserPage = styled.a`
    width: 24px;
    height: 24px;
    color: #008EFF;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const OrgLogo = styled.img`
  width: 95%;
  max-height: 100%;
`

const friendlyTimeDistance = (date) => {
  if (!date) {
    return "never"
  }

  let today = new Date();

  const target = new Date(date);

  // console.log(project_state)
  // console.log(new Date("2021-04-26T20:17:53.462599Z"))

  const time = Math.abs(today - target);
  // If there are years
  let humanTime;
  let units;
  if (time > 1000 * 60 * 60 * 24 * 365) {
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
    units = "year";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // If there are months
  else if (time > 1000 * 60 * 60 * 24 * 30) {
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
    units = "month";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // If there are weeks
  else if (time > 1000 * 60 * 60 * 24 * 7) {
    humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
    units = "week";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // If there are days
  else if (time > 1000 * 60 * 60 * 24) {
    humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
    units = "day";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // If there are hours
  else if (time > 1000 * 60 * 60) {
    humanTime = parseInt(time / (1000 * 60 * 60), 10);
    units = "hour";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // If there are minutes
  else if (time > 1000 * 60) {
    humanTime = parseInt(time / (1000 * 60), 10);
    units = "minute";
    if (humanTime !== 1) {
      units = units + "s";
    }
  }

  // Otherwise, use seconds
  else {
    humanTime = parseInt(time / 1000, 10);
    units = "seconds";
  }
  var timeUnits;
  if (target > today) {
    timeUnits = "in " + humanTime + " " + units;
  } else {
    if (
      units === "seconds" ||
      units === "second"
    ) {
      timeUnits = "< 1 minute ago";
    } else {
      timeUnits = humanTime + " " + units + " ago";
    }
  }

  return timeUnits;
};

class OrgEdit extends Component {
  constructor(props) {
    super(props);
    // this.savePage = this.saveGroup.bind(this);
    this.state = {
      id: "",
      description: "",
      group_type: "",
      archived: false,
      public: false,
      allow_join_requests: false,
      image: "",
      logo: "",
      cover: "",
      link_url: "",
      link_name: "",
      form_new: true,
      imageRaw: "",
      imagePreview: false,
      lgogRaw: "",
      logoPreview: false,
      coverRaw: "",
      coverPreview: false,
      showImageProgress: false,
      members: [],
      admins: [],
      invited: [],
      checked: [],
      manageusers: true,
      allmembers: [],
      inviteusername: "",
      email: "",
      search: "",
      showArchiveConfirmation: false,
      showDeleteConfirmation: false,
      showInviteUI: true,
      emails: [],
      lastquery: "1",
      defaultrole: "CO",
      role_info: false,
      org: "private",
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      panelW: 288,
      slide: false,
      x1: 0,
      x2: 0,
      pastState: "",
      custom_image_setting: "false",
      directUI: false,
      directShare: false,
      current_role: "",
      verbose_status: "",
      remove_participant: false,
      request_status: null,
      memberFilter: "AC",
      showMoreOptions: false,
      showSaveConfirmation: false,
      hasChanges: false,
      selected_participant: {},
      tickets: [],
      ticketsLoading: true,
      selected_ticket: null,
      remove_ticket: false,
      ticket_update_error: "",
    };
    this.moreOptionsButton = React.createRef();
  }
  onSlide = (state, bool) => {
    this.setState({ slide: true });
  };

  onChangeParticipant = (result) => {
    console.log(result)
    this.setState({ current_role: result.role });
    const selected = {
      username: result.user ? result.user.username : result.invite_email,
      fullname: result.user && result.user.fullname,
      src: result.user && result.user.avatar && api_endpoint + "/media/" + result.user.avatar.id + "/file?size=256",
      avatar: result.user.avatar,
      id: result.id,
      part_id: result.id
    }
    this.setState({ selected_participant: selected });
    let verbose_status;
    switch (result.status) {
      case "IN":
        verbose_status = "Invited";
        break;
      case "AC":
        verbose_status = "Active";
        break;
      case "RE":
        verbose_status = "Requested";
        break;
      default:
        verbose_status = "undefined";
    }
    this.setState({ verbose_status: verbose_status });
    this.onChangePanel3Number(1);
  }
  onChangeTicket(ticket) {
    this.setState({ selected_ticket: ticket });
    this.onChangePanel3Number(2);
  }
  onChangePanel2Number = (num, tab) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) });
    } else {
      this.setState({ x2: -this.state.panelW });
    }

    switch (num) {
      case 1:
        this.setState({ pastState: [this.state.name, this.state.description] });

        break;
      case 2:
        this.setState({ pastState: [this.state.public, this.state.allow_join_requests] });
        break;
      case 3:
        this.setState({ pastState: [this.state.imagePreview, this.state.imageRaw] });
        break;
      case 4:

        this.props.dispatch(getAllOrgParticipants(this.props.org.id, tab));

      default:
      // code block
    }
  };
  onChangePanel3Number = (num) => {
    this.setState({ panelthree: num });
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({ x1: -(this.props.dimensions.window_dimensions.w - 64) });
      this.setState({ x2: -(this.props.dimensions.window_dimensions.w - 64) * 2 });
    } else {
      this.setState({ x1: -this.state.panelW });
      this.setState({ x2: -(this.state.panelW) * 2 });
    }
  }
  onPanel2Slideback = (state, bool) => {
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    if (this.props.new_dialog) {
      this.setState({ directUI: false });
    }
    if (this.props.sharing_only) {
      this.setState({ directShare: false });
    }
    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  onPanel3Slideback = (state, bool) => {
    this.setState({ x1: -this.state.panelW * 2 });
    this.setState({ x2: -this.state.panelW });

    // validate
    // if (
    //   this.isValidHttpUrl(this.state.link_url) ||
    //   this.state.link_url === ""
    // ) {
    //   this.setState({ paneltwo: 0 });
    //   this.setState({ x1: -this.state.panelW });
    //   this.setState({ x2: 0 });
    // } else {
    //   this.setState({ showLinkError: true });
    // }
  };
  handleChangeToggle = (value, part_id, type) => {
    //console.log(value);
    //console.log(part_id);

    this.props.dispatch(changeRole(value, part_id, type));
  };
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 1:
        this.setState({ name: this.state.pastState[0] });
        this.setState({ description: this.state.pastState[1] });
        break;
      case 2:
        this.setState({ public: this.state.pastState[0] });
        this.setState({ allow_join_requests: this.state.pastState[1] });
        break;
      case 3:
        this.setState({ imagePreview: this.state.pastState[0] });
        this.setState({ imageRaw: this.state.pastState[1] });
        break;
      default:
      // code block
    }
  };
  loadMore = (link, type) => {
    this.props.dispatch(loadMoreOrgParticipants(link, type));
  };

  handleClosePopup() {
    document.body.style.overflow = "auto";
    this.props.dispatch(setGroupSettings(false));
  }
  handleCloseDialogCheck() {
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      document.body.style.overflow = "auto";

      this.props.handleCloseInvite();

    }

  }
  handleChangeToggleOrg = (e) => {
    this.setState({ org: e.target.value });
  };

  onChangeJoin = (e) => {
    // this.setState({ project: { ...this.state.project, public: e.target.value } });
    this.setState({ hasChanges: true });
    this.setState({ allow_join_requests: e.target.checked });
  };
  onChangeRemoveParticipant = (e) => {
    this.setState({ remove_participant: e.target.checked });
  };
  onChangeRemoveTicket = (e) => {
    this.setState({ remove_ticket: e.target.checked });
  };
  onChange = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: e.target.value || e.target.checked });
  };
  onToggle = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: !this.state[[e.target.id]] });
  };
  isEmailValid = (email) => {
    console.log(email)
    // return(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    return (/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(email))

  }

  handleChangeRole = (value) => {
    //console.log(value);
    //console.log(part_id);

    this.setState({ current_role: value });
  };
  handleChangeToggleGroupType = (event) => {
    this.setState({ hasChanges: true });
    //console.log(event.target.value);
    //console.log(event.target.id);
    this.setState({ group_type: event.target.value });
  };
  handleChangeDescription = (newValue) => {
    this.setState({ hasChanges: true });
    this.setState({ description: newValue });
  };
  handleChangeDate = (newValue) => {
    this.setState({ date: newValue });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleRoleInfo = () => {

    if (this.state.role_info) {
      this.setState({ role_info: false });
    } else {
      this.setState({ role_info: true });
    }

  };
  onEmailChange = (event, values) => {
    console.log("onEmailChange")
    event.preventDefault();
    event.stopPropagation();
    console.log(values)
    console.log(this.state.emails)
    this.setState(
      {
        emails: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.

      }
    );
  };
  handleKeyDown = (event, values) => {
    // parse if brackets
    switch (event.keyCode) {
      case 9: //tab
      case 188: //comma
      case 13: //enter
      case 32: { //space

        event.preventDefault();
        // event.stopPropagation();
        if (event.target.value.length > 0) {
          console.log("keydown")
          event.target.blur()
          event.target.focus()
          const result_string = event.target.value + ',none,none'
          const new_email = { 'id': 2, 'data': result_string }
          this.setState(
            {
              emails: [...this.state.emails, new_email],
            });
          console.log(this.state.emails)

        }

        break;
      }
      default:
    }
  };
  handlePaste = (event, values) => {
    event.preventDefault();
    event.stopPropagation();
    // look for emi colon
    // look for enters
    //look for tabs
    let email_data = (event.clipboardData.getData('Text')) // remove spaces
    console.log(email_data)
    email_data = email_data.replace(/(?:\r\n|\r|\n)/g, ','); // replace carriage returns, line breaks etc
    email_data = email_data.replace(/;/g, ','); // replace semicolon ;
    email_data = email_data.replace(/\s/g, '');

    const emails = email_data.split(",");
    console.log(emails)
    let newemails = [...this.state.emails]
    emails.forEach((email, i) => {
      const result_string = email + ',none,none'
      const new_email = { 'id': 2, 'data': result_string }
      newemails.push(new_email)
    });
    this.setState(
      {
        emails: newemails,
      });
  };
  handleBlur = (event, values) => {
    console.log("blur: " + event.target.value)
    if (event.target.value.length > 0) {
      console.log("blurring")

      const result_string = event.target.value + ',none,none'
      const new_email = { 'id': 2, 'data': result_string }
      this.setState(
        {
          emails: [...this.state.emails, new_email],
        });
      console.log(this.state.emails)

    }
    //  

  };
  sendInvites = () => {

    this.state.emails.forEach((email, i) => {
      if (i < 20) {
        if (this.isEmailValid(email.data.split(",")[0])) {
          const data = {
            invite_email: email.data.split(",")[0],
            group: this.props.group.id,
            organization: null,
            status: "IN",
            role: this.state.defaultrole
          };
          this.props.dispatch(sendInvite(data));
          this.setState({ emails: [] });
          document.body.style.overflow = "auto";

        }
      }

    });
    this.props.dispatch(setGroupSettings(false));

  };
  removeInvite = (id) => {
    this.props.dispatch(removeInvite(id));
  };
  handleShowDeleteConfirmation() {
    this.handleShowOptionsMenu(false)
    this.setState({ showDeleteConfirmation: true });
  }
  handleShowArchiveConfirmation() {
    this.handleShowOptionsMenu(false)
    this.setState({ showArchiveConfirmation: true });
  }
  onChangeSearch = (value) => {
    // this.setState({ [e.target.id]: e.target.value });
    // const data = e.target.value;
    console.log(this.props.search_results)
    if (!this.props.search_results.length) {
      console.log("empty")
      this.setState({ lastquery: value });
    }
    console.log(value)
    console.log(this.state.lastquery)
    if (value.slice(0, -1) === this.state.lastquery & !this.props.search_results.length) {
      this.setState({ lastquery: value });
    } else {
      this.props.dispatch(getConnections(value));
      console.log("search")
    }



  };
  deleteGroup = () => {
    this.props.dispatch(deleteGroup(this.state.id));
  };
  newGroup = () => {
    // this.props.dispatch(deleteProject(this.state.id));
  };
  savePage = (input) => {

    const data = {
      description: this.state.description,
      name: this.state.name,
      link_url: this.state.link_url,
      link_name: this.state.link_name,
      is_sponsor: this.state.is_sponsor,
      ticket_code: this.state.ticket_code_enabled ? this.state.ticket_code : null,
      ticket_terms: this.state.ticket_terms
    };
    if (this.state.public === "true" || this.state.public === true) {
      data.public = true
    } else {
      data.public = false
    }
    if (this.state.imagePreview || this.state.logoRaw || this.state.coverRaw) {
      // first upload and then save
      this.props.dispatch(
        uploadSaveOrg(
          this.state.id,
          data,
          this.state.imageRaw,
          this.state.logoRaw,
          this.state.coverRaw
        )
      );
    } else {
      this.props.dispatch(saveOrg(this.state.id, data));
    }
    document.body.style.overflow = "auto";
    this.props.handleCloseInvite();
  };

  removeUser(id, part_id) {
    this.props.dispatch(removeMemberOrg(id, part_id, this.state.memberFilter));
    // this.props.dispatch(getAllParticipants(this.props.group.id, this.state.memberFilter));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  applyTicket(ticket) {
    let body = {
      sponsor_expire_at: ticket.sponsor_expire ? ticket.sponsor_expire_at : null,
    }
    axiosInstance.put(`/tickets/${ticket.id}/`, body).then(res => {
      this.getTickets();
      this.onPanel3Slideback(1);
    })
  }
  removeTicket(id) {
    axiosInstance.delete(`/tickets/${id}/`).then(res => {
      this.getTickets();
      this.onPanel3Slideback(1);
    })
  }
  componentDidMount() {
    const { dialogType, org, dimensions } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }

    let imagesource = "../../header.jpg";
    if (org.image) {
      imagesource = api_endpoint + "/media/" + org.image.id + "/file?size=256";
    }
    let imagesourcelogo = "";
    if (org.logo) {
      imagesourcelogo = api_endpoint + "/media/" + org.logo.id + "/file?size=256";
    }
    let imagesourcecover = "";
    if (org.cover) {
      // need to add multiple pages here
      imagesourcecover = api_endpoint + "/media/" + org.cover.id + "/file?size=256";
    }

    if (dialogType === "settings") {

      this.setState({ id: org.id });
      this.setState({ form_new: false });
      this.setState({ description: org.description });

      this.setState({ public: org.public });

      this.setState({ name: org.name });
      this.setState({ image: imagesource });
      this.setState({ logo: imagesourcelogo });
      this.setState({ cover: imagesourcecover });
      this.setState({ link_url: org.link_url });
      this.setState({ link_name: org.link_name });

      // this.setState({ members: group.members });
      // this.setState({ admins: group.admins });
      // this.setState({ invited: group.invited });
      // this.setState({ allmembers: group.allmembers });


    } else {
      this.setState({ id: "" });
      this.setState({ form_new: false });
      this.setState({ description: "" });
      this.setState({ name: "" });
      this.setState({ public: false });

      this.setState({ image: imagesource });
      this.setState({ logo: imagesourcelogo });
      this.setState({ cover: imagesourcecover });
      this.setState({ link_url: "" });
      this.setState({ link_name: "" });
      this.setState({ members: [] });
      this.setState({ admins: [] });
      this.setState({ invited: [] });
    }
    this.getTickets()
  }
  onChangeRadioPublic = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ public: e.target.value });
  };
  onChangeRadioRequest = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ request_status: e.target.value });
  };
  handleImageChange = (e, type) => {
    if (e.target.files.length) {
      if (type === "banner") {
        this.setState({ hasChanges: true });
        this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
        this.setState({ imageRaw: e.target.files[0] });
      }
      if (type === "logo") {
        this.setState({ hasChanges: true });
        this.setState({ logoPreview: URL.createObjectURL(e.target.files[0]) });
        this.setState({ logoRaw: e.target.files[0] });
      }
      if (type === "cover") {
        this.setState({ hasChanges: true });
        this.setState({ coverPreview: URL.createObjectURL(e.target.files[0]) });
        this.setState({ coverRaw: e.target.files[0] });
      }

    }
  };
  handleToggle = (value) => () => {
    //console.log("toggle", value);
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };
  handleMemberFilterChange(e) {
    this.setState({ memberFilter: e.target.value });
    this.props.dispatch(getAllOrgParticipants(this.props.org.id, e.target.value));
  }
  applyParticipant(part_id) {
    if (this.state.request_status === null) {
      //nothing
    } else {
      if (this.state.request_status === "RE") {
        //remove user
        this.props.dispatch(
          rejectRequest(part_id)
        );
      } else {
        this.props.dispatch(
          acceptRequest(part_id)
        );
      }
    }

    this.props.dispatch(
      changeRoleOrg(this.state.current_role, part_id, this.state.memberFilter)
    );
    // this.props.dispatch(getAllParticipants(this.props.group.id, this.state.memberFilter));
    this.onPanel3Slideback();
  }
  handleCloseConfirmation() {
    this.setState({ showDeleteConfirmation: false, showArchiveConfirmation: false })
  }
  handleShowOptionsMenu = (show) => {
    this.setState({ showMoreOptions: show });
  };
  archiveGroup() {
    this.saveGroup({ archived: true });
  }
  imageContainer() {
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <CoverImage src={imagesrc}>
          <UploadButtons>
            <label htmlFor="upload-button">
              {" "}
              <UploadButton>Upload New</UploadButton>{" "}
            </label>
            <input
              type="file"
              id="upload-button"
              accept={mimetype.image}
              multiple={false}
              style={{ display: "none" }}
              onChange={this.handleImageChange}
            />
          </UploadButtons>
        </CoverImage>
      );
    }
  }

  filterParticipants = (participants) => {
    return participants.allIDS.map(id => participants.byID[id]).filter(m => m.status === this.state.memberFilter).sort((a, b) => b.username < a.username)
  }

  getTickets() {
    axiosInstance.get(`organizations/${this.props.org.id}/tickets/`).then(res => {
      let tickets = res.data.results.map(t => {
        t.sponsor_expire = t.sponsor_expire_at != null
        if (t.sponsor_expire) {
          t.sponsor_expire_at = new Date(t.sponsor_expire_at).toISOString()
        } else {
          t.sponsor_expire_at = this.offsetDateByDays(t.created_at, 90)
        }
        return t
      });
      this.setState({ tickets: tickets })
      this.setState({ ticketsLoading: false })
    })
  }

  offsetDateByDays = (date, days) => {
    let d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
  }

  onChangeTicketWillExpire(e) {
    let expire_date = new Date(this.state.selected_ticket.sponsor_expire_at ? this.state.selected_ticket.sponsor_expire_at : this.offsetDateByDays(this.state.selected_ticket.created_at, 90));
    console.log(expire_date)
    console.log(expire_date.toISOString())

    this.setState({
      selected_ticket: {
        ...this.state.selected_ticket,
        sponsor_expire: !this.state.selected_ticket.sponsor_expire,
        sponsor_expire_at: expire_date.toISOString()
      }
    })
  }

  render() {
    const { dimensions, org, dialogType, participants } = this.props;
    const { selected_participant, selected_ticket } = this.state;
    let email_error = ""
    let max_invites = ""
    this.state.emails.forEach((email, i) => {
      if (!this.isEmailValid(email.data.split(",")[0])) {
        email_error = "Please enter a valid email."
      }
      if (i > 19) {
        max_invites = "The maximum number of invites in one batch is 20."
      }
    })
    let image;
    if (org.image) {
      image = imgUrl(org.image.id, 512)
    }
    const open = Boolean(this.state.anchorEl);
    let banner;
    if (org.image) {
      banner = imgUrl(org.image.id, 512);
    }
    let logo;
    if (org.logo) {
      logo = imgUrl(org.logo.id, 512);
    }
    let cover;
    if (org.cover) {
      cover = imgUrl(org.cover.id, 512);
    }
    if (this.state.showArchiveConfirmation) {
      return (
        <ElementContainer>
          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              <h3>Do you really want to archive this group?</h3>
              <br />
              Archiving this group will hide it from your groups page, and no new projects can be shared to it.
              People with the link to the group can still view it, but not add projects or comments.
              You will not be able to undo this.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleCloseConfirmation();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  No
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={e => {
                    this.archiveGroup();
                  }}
                  disableElevation
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "180px" }}>
                  Yes, Archive Group
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>
        </ElementContainer>
      )
    }
    if (this.state.showDeleteConfirmation) {
      return (
        <ElementContainer>
          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              <h3>Do you really want to delete this group?</h3>
              <br />
              Deleting this group will also unshare all on projects from this group, and
              cannot be undone.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleCloseConfirmation();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  No
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.deleteGroup();
                  }}
                  disableElevation
                  variant="contained"
                  color="secondary"
                  style={{ minWidth: "180px" }}>
                  Yes, Delete Group
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>
        </ElementContainer>
      )
    }
    if (this.state.showSaveConfirmation) {
      return (
        <ElementContainer>

          <ElementCardConfirmation
            isMobile={dimensions.window_dimensions.isMobile}>
            <ConfirmationDescription>
              This group has changes that have not been saved.
            </ConfirmationDescription>
            <ButtonGroup
              isMobile={dimensions.window_dimensions.isMobile}>
              <StyledButtonLeft2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.handleClosePopup();
                  }}
                  disableElevation
                  variant="outlined"
                  color="primary"
                  style={{ minWidth: "150px" }}>
                  Don't Save
                </Button>
              </StyledButtonLeft2>
              <StyledButtonRight2
                isMobile={dimensions.window_dimensions.isMobile}>
                <Button
                  onClick={() => {
                    this.savePage();
                  }}
                  disableElevation
                  variant="contained"
                  color="primary"
                  style={{ minWidth: "180px" }}>
                  Save Changes
                </Button>
              </StyledButtonRight2>
            </ButtonGroup>
          </ElementCardConfirmation>

        </ElementContainer>
      )
    }

    return (
      <div style={{ height: "100%", position: "relative" }}>
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={425} dialog_width={690}>
              <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Group Settings
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialogCheck();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                {this.props.new_dialog && this.state.directUI ? ("") : (<ColumnMiddle isMobile={dimensions.window_dimensions.isMobile}>
                  <PreviewContent
                    isMobile={dimensions.window_dimensions.isMobile}>

                    <ThumbContent isMobile={dimensions.window_dimensions.isMobile}>
                      {org.logo ? <OrgLogo src={imgUrl(org.logo.id, "original")} /> : ""}
                      {/* <GroupThumb
                        isSmall={true}
                        created_at={group.created_at}
                        total_members={group.total_members}
                        total_projects={
                          group.total_inprogress +
                          group.total_planned +
                          group.total_completed
                        }
                        creator={group.creator}
                       
                        src={group.image
                          ? group.image
                          : ""}
                        description={group.description}
                        name={group.name}
                        image_override={this.state.imagePreview}
                        id={group.id}
                        link={false}
                        viewer_status={group.viewer_status}
                      /> */}
                    </ThumbContent>
                  </PreviewContent>

                </ColumnMiddle>)}

                <ColumnRight directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                  <CropPanel
                    w={this.state.panelW}
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                      <PanelOne w={this.state.panelW}>
                        {this.state.paneltwo === 0 ? (
                          <div>
                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(1);
                              }}>
                              <SectionIcon>
                                <InfoIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Organization Details</SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(3);
                              }}>
                              <SectionIcon>
                                <InsertPhotoOutlinedIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Organization Images <SectionNumber>{this.state.description ? ("(✓)") : ("")}</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(5);
                              }}>
                              <SectionIcon>
                                <SubjectIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>About Text <SectionNumber>{this.state.description ? ("(✓)") : ("")}</SectionNumber></SectionLabel>
                            </EditItem>
                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(2);
                              }}>
                              <SectionIcon>
                                <VisibilityIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Privacy Settings</SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(4, "AC");
                                this.setState({ memberFilter: "AC" })
                              }}>
                              <SectionIcon>
                                <GroupAddIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Manage Staff <SectionNumber>({org.total_members})</SectionNumber></SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(6);
                              }}>
                              <SectionIcon>
                                <ConfirmationNumberIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Manage Tickets <SectionNumber>({org.seats_filled}/{org.seats})</SectionNumber></SectionLabel>
                            </EditItem>

                            <EditItem
                              onClick={() => {
                                this.onChangePanel2Number(7);
                              }}>
                              <SectionIcon>
                                <VolunteerActivismIcon fontSize="inherit" />
                              </SectionIcon>
                              <SectionLabel>Sponsorship Settings</SectionLabel>
                            </EditItem>

                          </div>
                        ) : (
                          ""
                        )}
                      </PanelOne>
                      <PanelTwo w={this.state.panelW}>
                        {
                          {
                            0: (
                              <SectionContent
                                w={this.state.panelW}></SectionContent>
                            ),
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Group Details</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="name"
                                      label="Name"
                                      variant="outlined"
                                      value={this.state.name}
                                      onChange={this.onChange}
                                      size="small"
                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />
                                  </FormControl>
                                  {this.state.nameerror ? (
                                    <StyledError>Name is required</StyledError>
                                  ) : (
                                    ""
                                  )}
                                </ControlLine>
                                <ControlLine>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="link_url"
                                      label="Link (url)"
                                      variant="outlined"
                                      value={this.state.link_url}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                  {this.state.showLinkError ? (
                                    <LinkError>
                                      Please enter a valid link, e.g. starting
                                      with http:// or https://
                                    </LinkError>
                                  ) : (
                                    ""
                                  )}
                                </ControlLine>

                                <ControlLine>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="link_name"
                                      label="Link Name (optional)"
                                      variant="outlined"
                                      value={this.state.link_name}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>

                                {this.state.hasLink ? (<Button
                                  onClick={() => {
                                    this.clearLink();
                                  }}
                                  // size="small"
                                  disableElevation
                                  variant="contained"
                                  color="error"
                                  startIcon={<DeleteForeverIcon />}>
                                  Clear Link
                                </Button>) : ("")}


                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                {this.props.new_dialog && this.state.directUI ? (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      More Options
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.newPage();
                                      }}>
                                      Create
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>) : (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>)}

                              </SectionContent>
                            ),
                            2: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Privacy Settings</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  style={{ marginBottom: "15px" }}
                                  isMobile={dimensions.window_dimensions.isMobile}>
                                  <FormControl component="fieldset">

                                    <RadioGroup

                                      value={this.state.public}
                                      onChange={this.onChangeRadioPublic}>
                                      <FormControlLabel
                                        style={{ marginBottom: 8 }}
                                        value={false}
                                        control={<Radio
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary" />}
                                        label={(
                                          <div>
                                            <OptionTitle>Private Group</OptionTitle>
                                            <OptionSubtitle>Private Groups are not visible to non-members and require a direct invitation to join.</OptionSubtitle>
                                          </div>
                                        )}
                                      />
                                      <FormControlLabel
                                        style={{ marginBottom: 8 }}
                                        value={true}
                                        control={<Radio
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary" />}
                                        label={(
                                          <div>
                                            <OptionTitle>Public Group</OptionTitle>
                                            <OptionSubtitle>Public Groups are visible to non-members and non-members can request access.</OptionSubtitle>
                                          </div>
                                        )}
                                      />

                                    </RadioGroup>
                                    {this.state.public === "true" || this.state.public === true ? (<FormControlLabel
                                      style={{ marginLeft: 18 }}
                                      control={
                                        <Checkbox
                                          style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={this.state.allow_join_requests || false}
                                          onChange={this.onChangeJoin}
                                          name="allow_join_requests"
                                          id="allow_join_requests"
                                        />
                                      }
                                      label="Allow requests to join the group."
                                    />) : ("")}



                                  </FormControl>
                                </ControlLine>



                                <SectionHelpText>
                                </SectionHelpText>
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(2);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            3: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Group Image</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <Column>
                                    <BannerPreview
                                      src={
                                        this.state.imagePreview || banner
                                      }></BannerPreview>
                                    <Button variant="contained" color="primary" disableElevation component="label">
                                      <input
                                        type="file"
                                        id="upload-avatar-button"
                                        accept="image/*"
                                        multiple={false}
                                        hidden
                                        onChange={e => this.handleImageChange(e, "banner")}
                                      />
                                      Change Banner
                                    </Button>
                                  </Column>
                                  <Column>
                                    <BannerPreview
                                      src={
                                        this.state.logoPreview || logo
                                      }></BannerPreview>
                                    <Button variant="contained" color="primary" disableElevation component="label">
                                      <input
                                        type="file"
                                        id="upload-avatar-button"
                                        accept="image/*"
                                        multiple={false}
                                        hidden
                                        onChange={e => this.handleImageChange(e, "logo")}
                                      />
                                      Change Logo
                                    </Button>
                                  </Column>
                                  <Column>
                                    <BannerPreview
                                      src={
                                        this.state.logoPreview || cover
                                      }></BannerPreview>
                                    <Button variant="contained" color="primary" disableElevation component="label">
                                      <input
                                        type="file"
                                        id="upload-avatar-button"
                                        accept="image/*"
                                        multiple={true}
                                        hidden
                                        onChange={e => this.handleImageChange(e, "cover")}
                                      />
                                      Add About Page Images
                                    </Button>
                                  </Column>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(3);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            4: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Manage Staff</SectionLabel2>
                                </SectionHeader>
                                <ToggleButtonGroup
                                  color="primary"
                                  value={this.state.memberFilter}
                                  exclusive
                                  onChange={e => this.handleMemberFilterChange(e)}
                                  aria-label="member status"
                                  size="small"
                                  sx={{ width: "100%", marginTop: -1 }}
                                >
                                  <ToggleButton sx={{ width: "100%", borderRadius: "0px" }} value="AC">Active</ToggleButton>
                                  <ToggleButton sx={{ width: "100%", borderRadius: "0px" }} value="IN">Invited</ToggleButton>

                                </ToggleButtonGroup>
                                <SubContainer isMobile={dimensions.window_dimensions.isMobile}>
                                  {participants[this.state.memberFilter].results.map((result, index) => {

                                    return (
                                      <ParticipantLine key={index} onClick={() => {
                                        this.onChangeParticipant(result);
                                      }}>
                                        <AuthorImageSmall
                                          src={
                                            result.user && result.user.avatar && imgUrl(result.user.avatar, 256)
                                          } />
                                        <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                          <DisplayNameSmall>
                                            {result.user ? result.user.fullname || result.user.username : result.invite_email}
                                            {result.user && result.user.fullname ? <UserNameSmall>&nbsp;({result.user.username})</UserNameSmall> : null}
                                          </DisplayNameSmall>
                                          <TitleNameSmall>{groupMemberRole[result.role]}</TitleNameSmall>
                                        </NameColumn>
                                      </ParticipantLine>
                                    )
                                  })}

                                  {participants[this.state.memberFilter].results.length === 0 ? (
                                    <Box sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "64px",
                                      color: "rgba(32, 32, 32, 0.5)"
                                    }}>
                                      No members found.
                                    </Box>
                                  ) : null}
                                  {participants[this.state.memberFilter].next ? (
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.loadMore(participants[this.state.memberFilter].next, this.state.memberFilter);
                                      }}
                                    >
                                      Load More Participants...
                                    </Button>
                                  ) : ("")}
                                </SubContainer>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    {/* <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(3);
                                      }}>
                                      Cancel
                                    </Button> */}
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Back
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            5: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>About Text</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={dimensions.window_dimensions.isMobile}
                                >
                                  <FormControl size="small" fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      InputLabelProps={{ style: { fontSize: 14 } }}
                                      id="description"
                                      rows={10}
                                      label="About"
                                      variant="outlined"
                                      multiline
                                      value={this.state.description}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>
                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(5);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>
                              </SectionContent>
                            ),
                            6: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Manage Tickets</SectionLabel2>
                                </SectionHeader>
                                <span style={{ marginTop: 0 }}>
                                  {this.state.ticketsLoading ? "loading..." : `Used ${org.seats_filled} of ${org.seats} tickets.`}
                                </span>
                                <SubContainer isMobile={dimensions.window_dimensions.isMobile} top={true}>
                                  {this.state.tickets.map((ticket, index) => {
                                    let user = ticket.user
                                    return (
                                      <ParticipantLine key={index} onClick={e => this.onChangeTicket(ticket)}>
                                        <AuthorImageSmall
                                          src={
                                            ticket.user && imgUrl(ticket.user.avatar, 256)
                                          } />
                                        <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                          <DisplayNameSmall>
                                            {ticket.user ? ticket.user.fullname || ticket.user.username : ticket.invite_email}
                                            {ticket.user && ticket.user.fullname ? <UserNameSmall>&nbsp;({ticket.user.username})</UserNameSmall> : null}
                                          </DisplayNameSmall>
                                          {ticket.sponsor_expire ? (
                                            <TitleNameSmall>
                                              Expires on{" "}
                                              <strong>
                                                {
                                                  new Date(
                                                    ticket.sponsor_expire_at
                                                  ).toLocaleDateString(
                                                    "en-us",
                                                    {
                                                      year: "numeric",
                                                      month: "short",
                                                      day: "numeric",
                                                    }
                                                  )
                                                }
                                              </strong>
                                              .
                                            </TitleNameSmall>
                                          ) : (
                                            <TitleNameSmall>
                                              No expiration date.
                                            </TitleNameSmall>
                                          )}
                                        </NameColumn>
                                      </ParticipantLine>
                                    )
                                  })}

                                  {this.state.tickets.length === 0 ? (
                                    <Box sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",
                                      height: "64px",
                                      color: "rgba(32, 32, 32, 0.5)"
                                    }}>
                                      No members found.
                                    </Box>
                                  ) : null}
                                  {/* {participants[this.state.memberFilter].next ? (
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      onClick={() => {
                                        this.loadMore(participants[this.state.memberFilter].next, this.state.memberFilter);
                                      }}
                                    >
                                      Load More Participants...
                                    </Button>
                                  ) : ("")} */}
                                </SubContainer>
                              </SectionContent>
                            ),
                            7: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel2Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Sponsorship Settings</SectionLabel2>
                                </SectionHeader>
                                <FormControlLabel
                                  style={{}}
                                  control={
                                    <Checkbox
                                      // style={{ marginBottom: "auto", paddingTop: 0 }}
                                      color="primary"
                                      checked={this.state.is_sponsor || false}
                                      onChange={e => this.onToggle(e)}
                                      name="is_sponsor"
                                      id="is_sponsor"
                                    />
                                  }
                                  label="Show in Public Sponsor List"
                                />
                                <FormControlLabel
                                  style={{ marginBottom: "12px" }}
                                  control={
                                    <Checkbox
                                      // style={{ marginBottom: "auto", paddingTop: 0 }}
                                      color="primary"
                                      checked={this.state.ticket_code_enabled || false}
                                      onChange={e => this.onToggle(e)}
                                      name="ticket_code_enabled"
                                      id="ticket_code_enabled"
                                    />
                                  }
                                  label="Use Ticket Number"
                                />
                                {this.state.ticket_code_enabled ? (
                                  <ControlLine>
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        id="ticket_code"
                                        label="Ticket Number"
                                        variant="outlined"
                                        value={this.state.ticket_code}
                                        onChange={e => this.onChange(e)}
                                        size="small"
                                        InputLabelProps={{
                                          style: { fontSize: 14 },
                                        }}
                                      />
                                    </FormControl>
                                    {this.state.ticket_code_error ? (
                                      <StyledError>{this.state.ticket_code_error}</StyledError>
                                    ) : (
                                      ""
                                    )}
                                  </ControlLine>
                                ) : ""}
                                <ControlLine>
                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      id="ticket_default_duration"
                                      label="Default Ticket Duration"
                                      variant="outlined"
                                      value={this.state.ticket_default_duration}
                                      onChange={e => this.onChange(e)}
                                      size="small"
                                      type="number"
                                      InputProps={{
                                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                      }}
                                      InputLabelProps={{
                                        style: { fontSize: 14 },
                                      }}
                                    />
                                  </FormControl>
                                  {this.state.ticket_code_error ? (
                                    <StyledError>{this.state.ticket_code_error}</StyledError>
                                  ) : (
                                    ""
                                  )}
                                </ControlLine>
                                <ControlLine
                                  isMobile={dimensions.window_dimensions.isMobile}
                                >
                                  <FormControl size="small" fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      InputLabelProps={{ style: { fontSize: 14 } }}
                                      id="ticket_terms"
                                      rows={5}
                                      label="Ticket Terms"
                                      variant="outlined"
                                      multiline
                                      value={this.state.ticket_terms}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                </ControlLine>

                                {/* <SectionHelpText>
                                  Add a description to provide more information
                                  about this element. The maximum number of
                                  characters is 140.
                                </SectionHelpText> */}
                                {this.props.new_dialog && this.state.directUI ? (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      More Options
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.newPage();
                                      }}>
                                      Create
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>) : (<SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onSlidebackCancel(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.onPanel2Slideback();
                                      }}>
                                      Apply
                                    </Button>
                                  </ButtonContainer>
                                </SectionFooter>)}

                              </SectionContent>
                            )
                          }[this.state.paneltwo]
                        }
                      </PanelTwo>
                      <PanelThree w={this.state.panelW}>
                        {
                          {
                            1: (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel3Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Participant Details</SectionLabel2>
                                </SectionHeader>
                                <ControlLine
                                  isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
                                  <ParticipantContent>
                                    <ParticipantLine>
                                      <AuthorImageSmall src={imgUrl(selected_participant.avatar, 256)} />
                                      <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                        <DisplayNameSmall>
                                          {selected_participant ? selected_participant.fullname || selected_participant.username : selected_participant.invite_email}
                                        </DisplayNameSmall>
                                        {selected_participant && selected_participant.fullname ? <UserNameSmall>{selected_participant.username}</UserNameSmall> : null}
                                      </NameColumn>
                                      <ViewUserPage href={"/" + selected_participant.username} target="_blank">
                                        <OpenInNewIcon fontSize="inherit" />
                                      </ViewUserPage>
                                    </ParticipantLine>
                                    <ParticipantControlLine
                                      isMobile={
                                        dimensions.window_dimensions.isMobile
                                      }>

                                      <ParticipantCol1 style={{ paddingTop: "10px" }}>  <ParticipantLabel> Role:</ParticipantLabel></ParticipantCol1> <ParticipantCol2>
                                        <FormControl style={{ width: "100%" }} id="hello" size="small" variant="outlined">
                                          <Select
                                            labelId="demo-simple-select-outlined-label"
                                            id="mmmm"

                                            value={this.state.current_role}
                                            onChange={(e) => {
                                              this.handleChangeRole(
                                                e.target.value,
                                              );
                                            }}>
                                            <MenuItem value="AD">Admin</MenuItem>
                                            <MenuItem value="CO">Contributor</MenuItem>

                                          </Select>
                                        </FormControl>
                                      </ParticipantCol2>
                                    </ParticipantControlLine>
                                    <ParticipantControlLine
                                      isMobile={
                                        dimensions.window_dimensions.isMobile
                                      }>
                                      <ParticipantCol1>  <ParticipantLabel> Status:</ParticipantLabel></ParticipantCol1>
                                      <ParticipantCol2> {this.state.verbose_status}
                                        {this.state.verbose_status === "Active" ? (<FormControlLabel
                                          style={{ marginTop: "5px" }}
                                          control={
                                            <Checkbox
                                              // style={{ marginBottom: "auto", paddingTop: 0 }}
                                              color="primary"
                                              checked={this.state.remove_participant || false}
                                              onChange={this.onChangeRemoveParticipant}
                                              disabled={this.props.myprofile.id == selected_participant.id}
                                              name="remove_participant"
                                              id="remove_participant"
                                            />
                                          }
                                          label="Remove participant"
                                        />) : ("")}
                                        {this.state.verbose_status === "Invited" ? (<FormControlLabel
                                          style={{ marginTop: "5px" }}
                                          control={
                                            <Checkbox
                                              // style={{ marginBottom: "auto", paddingTop: 0 }}
                                              color="primary"
                                              checked={this.state.remove_participant || false}
                                              onChange={this.onChangeRemoveParticipant}
                                              name="remove_participant"
                                              id="remove_participant"
                                            />
                                          }
                                          label="Remove invitation"
                                        />) : ("")}


                                      </ParticipantCol2>


                                    </ParticipantControlLine>
                                    <ParticipantControlLine
                                      isMobile={
                                        dimensions.window_dimensions.isMobile
                                      }>
                                      <ParticipantCol1> </ParticipantCol1>
                                      <ParticipantCol2>

                                        {/* <Button
                                          disableElevation
                                          variant="contained"
                                          color="secondary"
                                          style={{
                                            minWidth: "80px",
                                            marginLeft: "auto",
                                          }}
                                          onClick={() => {
                                            this.applyParticipant();
                                          }}>
                                          Remove
                                        </Button> */}
                                      </ParticipantCol2>


                                    </ParticipantControlLine>
                                  </ParticipantContent>
                                </ControlLine>
                                <ControlLine
                                  isMobile={dimensions.window_dimensions.isMobile}
                                >

                                </ControlLine>
                                {/* <SectionHelpText>
                                      Add a description to provide more information
                                      about this element. The maximum number of
                                      characters is 140.
                                    </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onPanel3Slideback(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    {this.state.remove_participant ? (<Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.removeUser(this.state.selected_participant.id, this.state.selected_participant.part_id);
                                        this.onPanel3Slideback(1);
                                      }}>
                                      Remove
                                    </Button>) : (<Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.applyParticipant(this.state.selected_participant.id);
                                      }}>
                                      Apply
                                    </Button>)}

                                  </ButtonContainer>
                                </SectionFooter>

                              </SectionContent>
                            ),
                            2: selected_ticket ? (
                              <SectionContent w={this.state.panelW}>
                                <SectionHeader
                                  onClick={() => {
                                    this.onPanel3Slideback();
                                  }}>
                                  {" "}
                                  <BackIcon>
                                    <ChevronLeftIcon fontSize="inherit" />
                                  </BackIcon>{" "}
                                  <SectionLabel2>Ticket Details</SectionLabel2>
                                </SectionHeader>
                                <ParticipantContent>
                                  <ParticipantLine>
                                    <AuthorImageSmall src={imgUrl(selected_ticket.user.avatar, 256)} />
                                    <NameColumn isMobile={dimensions.window_dimensions.isMobile}>
                                      <DisplayNameSmall>{selected_ticket.user.fullname || selected_ticket.user.username} </DisplayNameSmall>
                                      {selected_ticket.user.fullname ? (<UserNameSmall>{selected_ticket.user.username}</UserNameSmall>) : null}
                                    </NameColumn>
                                    <ViewUserPage href={"/" + selected_ticket.user.username} target="_blank">
                                      <OpenInNewIcon fontSize="inherit" />
                                    </ViewUserPage>
                                  </ParticipantLine>
                                  <FormControl>
                                    <FormControlLabel
                                      style={{ marginTop: "5px" }}
                                      control={
                                        <Checkbox
                                          // style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={selected_ticket.sponsor_expire || false}
                                          onChange={e => this.onChangeTicketWillExpire(e)}
                                          name="ticket_expires"
                                          id="ticket_expires"
                                        />
                                      }
                                      label="Has Expiration Date"
                                    />
                                  </FormControl>
                                  {selected_ticket && selected_ticket.sponsor_expire ? (
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        size="small"
                                        id="ticket_expire_at"
                                        label="Expires At"
                                        type="datetime-local"
                                        defaultValue={this.state.selected_ticket.sponsor_expire_at.slice(0, -5)}
                                        onChange={(e) =>
                                          this.setState({ selected_ticket: { ...selected_ticket, sponsor_expire_at: new Date(e.target.value).toISOString() } })
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  ) : ""}
                                  <FormControl>
                                    <FormControlLabel
                                      style={{ marginTop: "5px" }}
                                      control={
                                        <Checkbox
                                          // style={{ marginBottom: "auto", paddingTop: 0 }}
                                          color="primary"
                                          checked={this.state.remove_ticket || false}
                                          onChange={this.onChangeRemoveTicket}
                                          name="remove_ticket"
                                          id="remove_ticket"
                                        />
                                      }
                                      label="Revoke Ticket"
                                    />
                                  </FormControl>
                                </ParticipantContent>
                                {/* <SectionHelpText>
                                      Add a description to provide more information
                                      about this element. The maximum number of
                                      characters is 140.
                                    </SectionHelpText> */}
                                <SectionFooter>
                                  <ButtonContainer>
                                    <Button
                                      disableElevation
                                      variant="contained"
                                      color="black"
                                      style={{ minWidth: "80px" }}
                                      onClick={() => {
                                        this.onPanel3Slideback(1);
                                      }}>
                                      Cancel
                                    </Button>
                                    {this.state.remove_ticket ? (<Button
                                      disableElevation
                                      variant="contained"
                                      color="secondary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.removeTicket(selected_ticket.id);
                                      }}>
                                      Revoke
                                    </Button>) : (<Button
                                      disableElevation
                                      variant="contained"
                                      color="primary"
                                      style={{
                                        minWidth: "80px",
                                        marginLeft: "auto",
                                      }}
                                      onClick={() => {
                                        this.applyTicket(selected_ticket);
                                      }}>
                                      Apply
                                    </Button>)}

                                  </ButtonContainer>
                                </SectionFooter>

                              </SectionContent>
                            ) : ""
                          }[this.state.panelthree]
                        }
                      </PanelThree>
                    </PanelContainer>
                  </CropPanel>
                  {this.state.paneltwo !== 0 ? (
                    ""
                  ) : (
                    <div>

                      {this.props.new_dialog ? (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>

                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.newPage();
                            }}>
                            Create
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>) : (<StyledButtonContainer w={this.state.panelW}>
                        <StyledLeftButtons
                          isMobile={dimensions.window_dimensions.isMobile}>
                          <StyledButtonDelete>
                            <Button
                              ref={this.moreOptionsButton}
                              disableElevation
                              id="basic-button"
                              variant="contained"
                              color="black"
                              aria-controls={this.state.showMoreOptions ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={this.state.showMoreOptions ? "true" : undefined}
                              onClick={e => this.handleShowOptionsMenu(true)}
                              endIcon={<KeyboardArrowDownIcon />}
                              style={{ minWidth: "128px" }}>
                              Options
                            </Button>
                            <Menu
                              id="basic-menu"
                              anchorEl={this.moreOptionsButton.current}
                              open={this.state.showMoreOptions}
                              onClose={e => this.handleShowOptionsMenu(false)}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}>
                              <MenuItem
                                onClick={e => this.handleShowArchiveConfirmation(e)}
                                disableRipple>
                                <ArchiveIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Archive
                              </MenuItem>
                              <MenuItem
                                onClick={e => this.handleShowDeleteConfirmation(e)}
                                disableRipple>
                                <DeleteForeverIcon
                                  style={{ marginRight: "10px" }}
                                />
                                Delete
                              </MenuItem>
                            </Menu>
                          </StyledButtonDelete>
                        </StyledLeftButtons>

                        <StyledButton>
                          <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            style={{
                              minWidth: "128px",
                              borderRadius: "16px",
                            }}
                            onClick={() => {
                              this.savePage();
                            }}>
                            Save
                          </Button>
                        </StyledButton>
                      </StyledButtonContainer>)}

                    </div>
                  )}
                </ColumnRight>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer >
      </div >
    );
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,

  participants: state.org.manage_participants,
  dialogType: state.ui.groupDialogType,
  pending_invites: state.groups.pending_invites,
  search_results: state.groups.search_results,
  organizations: state.auth.myprofile.organizations,
  org: state.org,
  myprofile: state.auth.myprofile
});
export default connect(mapStateToProps)(withRouter(OrgEdit));
