import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import projectReducer from "./projectReducer";
import groupsReducer from "./groupsReducer";
import orgsReducer from "./orgsReducer";
import homeReducer from "./homeReducer";
import errorReducer from "./errorReducer";
import publicReducer from "./publicReducer";
import uiReducer from "./uiReducer";
import fetchedReducer from "./fetchedReducer";

export default combineReducers({
  alerts: alertReducer,
  profile: profileReducer,
  project: projectReducer,
  groups: groupsReducer,
  org: orgsReducer,
  dimensions: homeReducer,
  auth: authReducer,
  errors: errorReducer,
  public: publicReducer,
  ui: uiReducer,
  fetched: fetchedReducer,
});
