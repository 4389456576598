const element_types = [
    { id: "UP", name: "Image", style: "2" },
    // { id: "VI", name: "Video", style: "2" },
    // { id: "AU", name: "Audio", style: "2" },
    { id: "ME", name: "Audio/Video", style: "2" },
    { id: "TE", name: "Text", style: "4" },
    { id: "DM", name: "Dimension", style: "3" },
    { id: "CO", name: "Image Comparison", style: "7" },
    // { id: "TD", name: "To Do", style: "9" },
    // { id: "TL", name: "Tool", style: "5" },
    // { id: "MT", name: "Material", style: "4" },
    // { id: "QN", name: "Question", style: "6" },
]

export default element_types;