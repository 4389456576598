export * from "./authActions";
export * from "./profileActions";
export * from "./homeActions";
export * from "./projectActions";
export * from "./fetchActions"


// TODO: Catagorize these per view.
export const CONSTANTS = {
  ON_ALERT: "ON_ALERT",
  CLEAR_ALERT: "CLEAR_ALERT",
  CLEAR_ALL_ALERTS: "CLEAR_ALL_ALERTS",
  SET_ALERT_VISIBILITY: "SET_ALERT_VISIBILITY",
  ON_ERROR: "ON_ERROR",
  GET_ERROR: "ON_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_ALL_ERRORS: "CLEAR_ALL_ERRORS",
  USER_LOADING: "USER_LOADING",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GET_CURRENT_PROFILE: "GET_CURRENT_PROFILE",
  SET_PROFILE_LOADING: "SET_PROFILE_LOADING",
  SET_PROFILE_PAGES_LOADING: "SET_PROFILE_PAGES_LOADING",
  GET_CURRENT_PROFILE_PAGES: "GET_CURRENT_PROFILE_PAGES",
  GET_CURRENT_PROFILE_GROUPS: "GET_CURRENT_PROFILE_GROUPS",
  SET_PROFILE_GROUPS_LOADING: "SET_PROFILE_GROUPS_LOADING",
  SET_DIMENSIONS: "SET_DIMENSIONS",
  SET_ZOOM: "SET_ZOOM",
  SELECT_ELEMENT: "SELECT_ELEMENT",
  SET_POSITION: "SET_POSITION",
  UPDATE_POSITION: "UPDATE_POSITION",
  SET_FRIENDLY_DATE: "SET_FRIENDLY_DATE",
  SAVE_ELEMENT: "SAVE_ELEMENT",
  NEW_ELEMENT: "NEW_ELEMENT",
  SET_PROJECT_PAGE_LOADING: "SET_PROJECT_PAGE_LOADING",
  SET_PROJECT_PAGE_DETAILS: "SET_PROJECT_PAGE_DETAILS",
  SET_PROJECT_PAGE_ELEMENTS: "SET_PROJECT_PAGE_ELEMENTS",
  SET_PROJECT_PAGE_MYGROUPS: "SET_PROJECT_PAGE_MYGROUPS",
  SET_PROJECT_PAGE_COMMENTS: "SET_PROJECT_PAGE_COMMENTS",
  SET_ELEMENT_TYPES: "SET_ELEMENT_TYPES",
  DELETE_ELEMENT: "DELETE_ELEMENT",
  ADD_COMMENT: "ADD_COMMENT",
  SAVE_COMMENT: "SAVE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  SET_PUBLIC_PROJECTS_LOADING: "SET_PUBLIC_PROJECTS_LOADING",
  GET_PUBLIC_PROJECTS: "GET_PUBLIC_PROJECTS",
  CREATE_NEW_PROJECT: "CREATE_NEW_PROJECT",
  GET_GROUPS: "GET_GROUPS",
  GET_GROUPS_ERROR: "GET_GROUPS_ERROR",
  SET_GROUPS_LOADING: "SET_GROUPS_LOADING",
  CREATE_NEW_GROUP: "CREATE_NEW_GROUP",
  SET_GROUP_LOADING: "SET_GROUP_LOADING",
  GET_GROUP_DATA: "GET_GROUP_DATA",
  SET_GROUPPROJECTS_LOADING: "SET_GROUPPROJECTS_LOADING",
  GET_GROUPPROJECTS_DATA: "GET_GROUPPROJECTS_DATA",
  GET_MY_CURRENT_PROFILE: "GET_MY_CURRENT_PROFILE",
  GET_RECENT_PAGES: "GET_RECENT_PAGES",
  SET_ELEMENT_TYPES_MENU: "SET_ELEMENT_TYPES_MENU",
  SET_MENU: "SET_MENU",
  SELECT_ELEMENT_TYPE: "SELECT_ELEMENT_TYPE",
  SET_GROUP_SETTINGS: "SET_GROUP_SETTINGS",
  NEW_MEMBER: "NEW_MEMBER",
  REMOVE_MEMBER: "REMOVE_MEMBER",
  CHANGE_ROLE: "CHANGE_ROLE",
  GET_VALIDATION: "GET_VALIDATION",
  SET_PROJECT_DIALOG_TYPE: "SET_PROJECT_DIALOG_TYPE",
  SET_ANNOTATE_MODE: "SET_ANNOTATE_MODE",
  SET_ANNOTATE_COORDINATES: "SET_ANNOTATE_COORDINATES",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  SET_CATEGORIES_LOADING: "SET_CATEGORIES_LOADING",
  GET_CATEGORIES: "GET_CATEGORIES",
  UPDATE_PROJECT_PAGE_DETAILS: "UPDATE_PROJECT_PAGE_DETAILS",
  GET_MY_PROJECTS_FOLLOWING: "GET_MY_PROJECTS_FOLLOWING",
  CHANGE_FOLLOW_STATUS: "CHANGE_FOLLOW_STATUS",
  SET_PENDING_INVITE: "SET_PENDING_INVITE",
  GET_INITIAL_PENDING: "GET_INITIAL_PENDING",
  REMOVE_PENDING_INVITE: "REMOVE_PENDING_INVITE",
  SET_QUERY: "SET_QUERY",
  GET_SEARCH_RESULTS: "GET_SEARCH_RESULTS",
  CLEAR_SEARCH_RESULTS: "CLEAR_SEARCH_RESULTS",
  LOAD_MORE_PROJECTS: "LOAD_MORE_PROJECTS",
  LOAD_MORE_ELEMENTS: "LOAD_MORE_ELEMENTS",
  GET_MORE_GROUPS: "GET_MORE_GROUPS",
  LOAD_MORE_GROUPPROJECTS: "LOAD_MORE_GROUPPROJECTS",
  SET_ELEMENT_COMMENTS: "SET_ELEMENT_COMMENTS",
  LOAD_MORE_COMMENTS: "LOAD_MORE_COMMENTS",
  SET_FEED_LOADING: "SET_FEED_LOADING",
  GET_FEED: "GET_FEED",
  GET_FEED_ERROR: "GET_FEED_ERROR",
  RESET_PROJECT_LINK: "RESET_PROJECT_LINK",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  LOAD_MORE_PROFILE_PAGES: "LOAD_MORE_PROFILE_PAGES",
  LOAD_MORE_FEED_ITEMS: "LOAD_MORE_FEED_ITEMS",
  LOAD_MORE_NOTIFICATIONS: "LOAD_MORE_NOTIFICATIONS",
  CHANGE_GROUP_FOLLOW_STATUS: "CHANGE_GROUP_FOLLOW_STATUS",
  SET_BANNER: "SET_BANNER",
  SET_PROJECTS_FOLLOWING_LOADING: "SET_PROJECTS_FOLLOWING_LOADING",
  SET_GROUPPARTICIPANTS: "SET_GROUPPARTICIPANTS",
  GET_GROUPPARTICIPANTS: "GET_GROUPPARTICIPANTS",
  LOAD_MORE_GROUPPARTICIPANTS: "LOAD_MORE_GROUPPARTICIPANTS",
  SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
  SET_TIMEOUT_ERROR: "SET_TIMEOUT_ERROR",
  SET_ELEMENT_ORDER: "SET_ELEMENT_ORDER",
  CHANGE_ROLE_INVITE: "CHANGE_ROLE_INVITE",
  RESET_STATE: "RESET_STATE",
  SET_ORG_LOADING: "SET_ORG_LOADING",
  GET_ORG_DATA: "GET_ORG_DATA",
  SET_ORGGROUPS_LOADING: "SET_ORGGROUPS_LOADING",
  GET_ORGGROUPS_DATA: "GET_ORGGROUPS_DATA",
  LOAD_MORE_ORGGROUPS: "LOAD_MORE_ORGGROUPS",
  SET_ORGPARTICIPANTS: "SET_ORGPARTICIPANTS",
  GET_ORGPARTICIPANTS: "GET_ORGPARTICIPANTS",
  LOAD_MORE_ORGPARTICIPANTS: "LOAD_MORE_ORGPARTICIPANTS",
  SET_PENDING_ORG_INVITE: "SET_PENDING_ORG_INVITE",
  REMOVE_ORG_MEMBER: "REMOVE_ORG_MEMBER",
  CHANGE_ORG_ROLE_INVITE: "CHANGE_ORG_ROLE_INVITE",
  SET_FOLLOWING_LOADING: "SET_FOLLOWING_LOADING",
  GET_FOLLOWING: "GET_FOLLOWING",
  SET_SHARED_LOADING: "SET_SHARED_LOADING",
  GET_SHARED: "GET_SHARED",
  SET_FOLLOWERS_LOADING: "SET_FOLLOWERS_LOADING",
  GET_FOLLOWERS: "GET_FOLLOWERS",
  GET_INITIAL_REQUESTED: "GET_INITIAL_REQUESTED",
  REMOVE_REQUEST: "REMOVE_REQUEST",
  ACCEPT_REQUEST: "ACCEPT_REQUEST",
  SET_FOLLOW: "SET_FOLLOW",
  SET_UNFOLLOW: "SET_UNFOLLOW",
  GET_PLAN: "GET_PLAN",
  GET_PLAN_ERROR: "GET_PLAN_ERROR",
  SET_PLAN_LOADING: "SET_PLAN_LOADING",
  REMOVE_PLAN_LOADING: "REMOVE_PLAN_LOADING",
  SET_GENERIC_ERROR: "SET_GENERIC_ERROR",
  SET_GALLERY_LOADING: "SET_GALLERY_LOADING",
  GET_GALLERY_PROJECTS: "GET_GALLERY_PROJECTS",
  SET_TAGS_LOADING: "SET_GALLERY_LOADING",
  GET_TAGS: "GET_TAGS",
  SET_PROJECT_DIALOG: "SET_PROJECT_DIALOG",
  UPLOAD_COMPLETE: "UPLOAD_COMPLETE",
  SET_SEARCH_LOADING: "SET_SEARCH_LOADING",
  GET_SEARCH: "GET_SEARCH",
  GET_SUBSCRIPTIONS: "GET_SUBSCRIPTIONS",
  SET_SUBSCRIPTIONS_LOADING: "SET_SUBSCRIPTIONS_LOADING",
  UPDATE_PROJECT_SETTINGS: "UPDATE_PROJECT_SETTINGS",
  UPDATE_SHARING_LINK: "UPDATE_SHARING_LINK",
  GET_ALL_PARTICIPANTS: "GET_ALL_PARTICIPANTS",
  GET_ERRORS: "GET_ERRORS",
  CONFIRM_WAITLIST: "CONFIRM_WAITLIST",
  GET_MY_SUBSCRIPTIONS: "GET_MY_SUBSCRIPTIONS",
  LOAD_MORE_SUBSCRIPTIONS: "LOAD_MORE_SUBSCRIPTIONS",
  SET_PROFILE_SUBSCRIBERS_LOADING: "SET_PROFILE_SUBSCRIBERS_LOADING",
  GET_PROFILE_SUBSCRIBERS: "GET_PROFILE_SUBSCRIBERS",
  TOGGLE_SUB_PROFILE: "TOGGLE_SUB_PROFILE",
  LOAD_MORE_PROFILE_SUBSCRIBERS: "LOAD_MORE_PROFILE_SUBSCRIBERS",
  LOAD_MORE_PROFILE_SUBSCRIPTIONS: "LOAD_MORE_PROFILE_SUBSCRIPTIONS",
  GET_PROFILE_SUBSCRIPTIONS: "GET_PROFILE_SUBSCRIPTIONS",
  SET_PROFILE_SUBSCRIPTIONS_LOADING: "SET_PROFILE_SUBSCRIPTIONS_LOADING",
  LOAD_MORE_PARTICIPANTS: "LOAD_MORE_PARTICIPANTS",
  TOGGLE_SUBSCRIPTION: "TOGGLE_SUBSCRIPTION",
  CHANGE_GROUP_REQUEST_STATUS: "CHANGE_GROUP_REQUEST_STATUS",
  SET_PROJECT_SUBSCRIBERS_LOADING: "SET_PROJECT_SUBSCRIBERS_LOADING",
  GET_PROJECT_SUBSCRIBERS: "GET_PROJECT_SUBSCRIBERS",
  LOAD_MORE_PROJECT_SUBSCRIBERS: "LOAD_MORE_PROJECT_SUBSCRIBERS",
  GET_ALL_ORG_PARTICIPANTS: "GET_ALL_ORG_PARTICIPANTS",
  LOAD_MORE_ORG_PARTICIPANTS: "LOAD_MORE_ORG_PARTICIPANTS",
  GET_GROUP_PREVIEW: "GET_GROUP_PREVIEW",
  UPDATE_JOIN_LINK: "UPDATE_JOIN_LINK",
  SET_PROJECTS_FOR_GROUPS_LOADING: "SET_PROJECTS_FOR_GROUPS_LOADING",
  GET_PROJECTS_FOR_GROUPS: "GET_PROJECTS_FOR_GROUPS",
  LOAD_MORE_PROJECTS_FOR_GROUPS: "LOAD_MORE_PROJECTS_FOR_GROUPS",
  SET_ORGPROJECTS_LOADING: "SET_ORGPROJECTS_LOADING",
  GET_ORGPROJECTS_DATA: "GET_ORGPROJECTS_DATA",
  LOAD_MORE_ORGPROJECTS: "LOAD_MORE_ORGPROJECTS",
  UPDATE_TICKET: "UPDATE_TICKET",
  SET_SELECTED_ELEMENT: "SET_SELECTED_ELEMENT"
};
