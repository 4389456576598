import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  saveGroup,
  uploadSaveGroup,
  deleteGroup,
} from "../actions/groupActions";
import {
  addMember,
  removeMember,
  changeRole,
  getConnections,
  removeInvite,
  getPendingInvites,
} from "../actions/groupActions";
import { sendInvite } from "../actions/groupActions";
import { sendOrgInvite } from "../actions/orgActions";
import CircularProgress from "@mui/material/CircularProgress";

import FormControl from "@mui/material/FormControl";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setGroupSettings } from "../actions/uiActions";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { setMenu } from "../actions/uiActions";
import CloseIcon from "../custom-icons/CloseIcon";
import {
  ElementContainer,
  TopContainer,
  ElementCard,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton
} from "./styles/MenuComponents.js";




// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;
// const StyledSelect = styled.select`
//   width: 150px;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   margin-right: 20px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;



const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const InviteInstruction = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  // letter-spacing: 0.00938em;
`;
const SearchResults = styled.div`
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: scroll;
`;
const MemberList = styled.div`
  // height: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  width: 100%;
  overflow-y: auto;
`;
const SearchLineItem = styled.div`
  width: 100%;
  height: 70px;
  // border-bottom: 1px solid #ccc;
  display: flex;
  margin-top: 0px;
`;
const PendingItem = styled.div`
  height: 50px;

  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
  border-bottom: 1px solid #ccc;
  display: flex;
`;
const PendingItemEmail = styled.div`
  margin-top: 15px;
`;
const UserNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  color: ##000000de;
`;
const OtherNameSmall = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: ##000000de;
`;
const NameContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const AuthorImageSmall = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 12px;
  margin-left: 10px;
  margin-right: 15px;
`;
const LeftColumn = styled.div`
  // width: 10%;
  diplay: flex;
  flex-direction: column;
`;
const NameColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumn = styled.div`
  width:100%
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnNarrow = styled.div`
  width: 100px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const MidColumnWide = styled.div`
  min-width: 150px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
`;
const RightColumn = styled.div`
  // width: 100%;
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  width: ${(props) => (props.isMobile ? "100%" : "350px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;
const ColumnSingle = styled.div`
  padding-left: ${(props) => (props.isMobile ? "32px" : "32px")};
  padding-right: ${(props) => (props.isMobile ? "32px" : "32px")};
  padding-bottom: ${(props) => (props.isMobile ? "32px" : "32px")};
  width: ${(props) => (props.isMobile ? "100%" : "450px")};
  padding-top: 24px;
  height: 100%;
  //   background-color: #ccc;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const EmailError = styled.div`
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #dd4d25;
  margin-top: 20px;
`;
const ControlLineBanner = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "32px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "auto")};
  display: flex;
`;
const ControlContentMembers = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
`;




const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;

// const StyledButtonNew = styled.button`
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 150px;
//   background-color: #2cc0f4;
//   color: white;
//   height: 32px;
//   border-radius: 2px;

//   padding-right: 30px;
//   padding-left: 30px;
//   margin-top: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
const AuthorImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 15px;
`;
const StyledElementPopup = styled.div`
  margin-left: -30px;
  margin-top: -30px;
  width: calc(100% - 10px);
  border-radius: 5px;
  height: calc(100% - 14px);
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledTable = styled.table`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
`;
const StyledTD = styled.td`
  text-align: center;
  min-width: 25px;
`;
class GlobalInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      description: "",
      group_type: "",
      image: "",
      form_new: true,
      imageRaw: "",
      imagePreview: false,
      showImageProgress: false,
      members: [],
      admins: [],
      invited: [],
      checked: [],
      manageusers: true,
      allmembers: [],
      inviteusername: "",
      email: "",
      search: "",
      showDeleteConfirmation: false,
      showInviteUI: true,
      emails: [],
      lastquery: "1",
      defaultrole: "CO",
      role_info: false,
    };
  }
  handleClosePopup() {
    document.body.style.overflow = "auto";
    this.props.dispatch(setMenu(false));
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  isEmailValid = (email) => {
    console.log(email);
    // return(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      email
    );
  };
  handleChangeToggle = (value, part_id) => {
    //console.log(value);
    //console.log(part_id);

    this.props.dispatch(changeRole(value, part_id));
  };
  handleChangeRole = (value) => {
    //console.log(value);
    //console.log(part_id);

    this.setState({ defaultrole: value });
  };
  handleChangeToggleGroupType = (event) => {
    //console.log(event.target.value);
    //console.log(event.target.id);
    this.setState({ group_type: event.target.value });
  };
  handleChangeDescription = (newValue) => {
    this.setState({ description: newValue });
  };
  handleChangeDate = (newValue) => {
    this.setState({ date: newValue });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  toggleRoleInfo = () => {
    if (this.state.role_info) {
      this.setState({ role_info: false });
    } else {
      this.setState({ role_info: true });
    }
  };
  onEmailChange = (event, values) => {
    console.log("onEmailChange");
    event.preventDefault();
    event.stopPropagation();
    console.log(values);
    console.log(this.state.emails);
    this.setState(
      {
        emails: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      }
    );
  };
  handleKeyDown = (event, values) => {
    // parse if brackets
    switch (event.keyCode) {
      case 9: //tab
      case 188: //comma 
      case 13: //enter
      case 32: {
        //space
       
        event.preventDefault();
        event.stopPropagation();
        if (event.target.value.length > 0) {
          console.log("keydown");
          event.target.blur();
          event.target.focus();
          const result_string = event.target.value + ",none,none";
          const new_email = { id: 2, data: result_string };
          this.setState({
            emails: [...this.state.emails, new_email],
          });
          console.log(this.state.emails);
        }

        break;
      }
      default:
    }
  };
  handlePaste = (event, values) => {
    event.preventDefault();
    event.stopPropagation();
    // look for emi colon
    // look for enters
    //look for tabs
    let email_data = event.clipboardData.getData("Text"); // remove spaces
    console.log(email_data);
    email_data = email_data.replace(/(?:\r\n|\r|\n)/g, ","); // replace carriage returns, line breaks etc
    email_data = email_data.replace(/;/g, ","); // replace semicolon ;
    email_data = email_data.replace(/\s/g, "");

    const emails = email_data.split(",");
    console.log(emails);
    let newemails = [...this.state.emails];
    emails.forEach((email, i) => {
      const result_string = email + ",none,none";
      const new_email = { id: 2, data: result_string };
      newemails.push(new_email);
    });
    this.setState({
      emails: newemails,
    });
  };
  handleBlur = (event, values) => {
    console.log("blur: " + event.target.value);
    if (event.target.value.length > 0) {
      console.log("blurring");

      const result_string = event.target.value + ",none,none";
      const new_email = { id: 2, data: result_string };
      this.setState({
        emails: [...this.state.emails, new_email],
      });
      console.log(this.state.emails);
    }
    //
  };
  sendInvites = () => {
    const { dialogType, follow_group } = this.props;
    let groupinvite = true
    this.state.emails.forEach((email, i) => {
      if (i < 20) {
        if (this.isEmailValid(email.data.split(",")[0])) {
          let data = {
            invite_email: email.data.split(",")[0],
            organization: this.props.orgid,
            group: null,
            status: "IN",
            role: this.state.defaultrole,
          };
          switch (dialogType) {
            case "group":
              data.organization = null
             data.group = this.props.groupid
             data.status = "IN"
             data.role = this.state.defaultrole
             this.props.dispatch(sendInvite(data, groupinvite));
              this.setState({ emails: [] });
              document.body.style.overflow = "auto";
              break;
            case "org":
              data.organization = this.props.orgid
              data.group = null
              data.status = "AC"
              data.role = this.state.defaultrole
              groupinvite = false
              this.props.dispatch(sendOrgInvite(data, groupinvite));
              this.setState({ emails: [] });
              document.body.style.overflow = "auto";
               break;
            default:
              break;
          }
      
          
        }
      }
    });
    this.handleClosePopup() 
  };
  removeInvite = (id) => {
    this.props.dispatch(removeInvite(id));
  };
  handleShowDeleteConfirmation() {
    document.body.style.overflow = "hidden";
    this.setState({ showDeleteConfirmation: true });
  }
  onChangeSearch = (value) => {
    // this.setState({ [e.target.id]: e.target.value });
    // const data = e.target.value;
    console.log(this.props.search_results);
    if (!this.props.search_results.length) {
      console.log("empty");
      this.setState({ lastquery: value });
    }
    console.log(value);
    console.log(this.state.lastquery);
    if (
      (value.slice(0, -1) === this.state.lastquery) &
      !this.props.search_results.length
    ) {
      this.setState({ lastquery: value });
    } else {
      this.props.dispatch(getConnections(value));
      console.log("search");
    }
  };
  deleteGroup = () => {
    this.props.dispatch(deleteGroup(this.state.id));
  };

  removeUser(id, part_id) {
    this.props.dispatch(removeMember(id, part_id));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  componentDidMount() {
    const { orgid } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";

    this.setState({ id: orgid });
  }

  handleToggle = (value) => () => {
    //console.log("toggle", value);
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };

  render() {
    const { dimensions, group, dialogType } = this.props;
    let email_error = "";
    let max_invites = "";
    this.state.emails.forEach((email, i) => {
      if (!this.isEmailValid(email.data.split(",")[0])) {
        email_error = "Please enter a valid email.";
      }
      if (i > 19) {
        max_invites = "The maximum number of invites in one batch is 20.";
      }
    });
    let Title = "Add Members";
    let Description = "Add Members";
    let showRoles = false;
    switch (dialogType) {
      case "followers":
        Title = "Add Followers";
        Description =
          "Enter email addresses for the people you want to invite to follow you.";
        showRoles = false;
        break;
      case "group":
        Title = "Add Group Participants";
        Description =
          "Enter email addresses for the people you want to invite to this group.";
        showRoles = true;
        break;
      case "org":
        Title = "Add Organization Members";
        Description =
          "Enter email addresses for the people you want to add to this organization.";
        showRoles = true;
        break;
      default:
        break;
    }

    return (
      <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
        <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
        <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={"auto"} dialog_width={500}>
          <SettingsHeader isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  {Title}
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleClosePopup();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
            <SettingsColumns>
              <ColumnSingle isMobile={dimensions.window_dimensions.isMobile}>
                <InviteInstruction>{Description}</InviteInstruction>

                <ControlLine isMobile={dimensions.window_dimensions.isMobile}>
                  <FormControl
                    style={{ marginTop: "-15px", marginBottom: "-12px" }}
                    fullWidth
                    variant="outlined">
                    <Autocomplete
                      id="invite-emails"
                      size="small"
                      options={this.props.search_results}
                      autoComplete
                      autoHighlight
                      freeSolo
                      multiple
                      filterSelectedOptions
                      clearOnBlur={true}
                      onBlur={this.handleBlur}
                      filterOptions={(x) => x}
                      includeInputInList
                      value={this.state.emails}
                      onChange={this.onEmailChange}
                      onInputChange={(event, newInputValue) => {
                        this.onChangeSearch(newInputValue);
                      }}
                      getOptionLabel={(option) => option.data}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          <SearchLineItem key={option.id}>
                            <LeftColumn>
                              <AuthorImageSmall
                                src={option.img}></AuthorImageSmall>
                            </LeftColumn>
                            <NameColumn>
                              <NameContainer>
                                <UserNameSmall>
                                  {option.data.split(",")[1]}
                                </UserNameSmall>
                                <OtherNameSmall>
                                  {" "}
                                  {option.data.split(",")[0]}
                                </OtherNameSmall>
                                <OtherNameSmall>
                                  {option.data.split(",")[2]}
                                </OtherNameSmall>
                              </NameContainer>
                            </NameColumn>
                          </SearchLineItem>
                        </Box>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            size="small"
                            variant="outlined"
                            color={
                              this.isEmailValid(option.data.split(",")[0])
                                ? "primary"
                                : "secondary"
                            }
                            label={option.data.split(",")[0]}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Emails"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "off", // disable autocomplete and autofill
                            onKeyDown: (e) => {
                              this.handleKeyDown(e);
                            },
                            onPaste: (e) => {
                              this.handlePaste(e);
                            },
                            // onBlur: (e) => {this.handleBlur(e)},
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <EmailError>
                    {email_error} {max_invites}
                  </EmailError>
                </ControlLine>
                {showRoles & dialogType === "org" ? (
                  <div>
                    <ControlLine
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <FormControl
                        id="hello"
                        size="small"
                        variant="outlined"
                        fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Default Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="defaultrole"
                          label="Default Role"
                          value={this.state.defaultrole}
                          onChange={(e) => {
                            this.handleChangeRole(e.target.value);
                          }}>
                          <MenuItem value="AD">Admin</MenuItem>
                          <MenuItem value="CO">Contributor</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        disableElevation
                        // variant="contained"
                        color="primary"
                        style={{ fontSize: "14px", textTransform: "none" }}
                        size="small"
                        onClick={() => {
                          this.toggleRoleInfo();
                        }}
                        startIcon={
                          <InfoOutlinedIcon style={{ color: "#383838" }} />
                        }>
                        Learn about the different roles
                      </Button>
                    </ControlLine>
                    {this.state.role_info ? (
                      <ControlLine>
                        <StyledTable>
                          <tr>
                            <td></td>
                            <StyledTD>Admin</StyledTD>
                            <StyledTD>Contributor</StyledTD>
                          </tr>
                          <tr>
                            <td>Change Organization Settings</td>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                            <StyledTD></StyledTD>
                          </tr>
                          <tr>
                            <td>Manage and Invite Members</td>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                            <StyledTD></StyledTD>
                          </tr>
                          <tr>
                            <td>Create Groups</td>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                          </tr>
                          <tr>
                            <td>Manage Groups</td>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                            <StyledTD>
                              <CheckIcon color="primary" />
                            </StyledTD>
                          </tr>
                        </StyledTable>
                      </ControlLine>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
 {showRoles & dialogType === "group" ? (
                  <div>
                    <ControlLine
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <FormControl
                        id="hello"
                        size="small"
                        variant="outlined"
                        fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">
                          Default Role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="defaultrole"
                          label="Default Role"
                          value={this.state.defaultrole}
                          onChange={(e) => {
                            this.handleChangeRole(e.target.value);
                          }}>
                            <MenuItem value="AD">Admin</MenuItem>
                              <MenuItem value="CO">Contributor</MenuItem>
                              <MenuItem value="CM">Commenter</MenuItem>
                              <MenuItem value="VI">Viewer</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        disableElevation
                        // variant="contained"
                        color="primary"
                        style={{ fontSize: "14px", textTransform: "none" }}
                        size="small"
                        onClick={() => {
                          this.toggleRoleInfo();
                        }}
                        startIcon={
                          <InfoOutlinedIcon style={{ color: "#383838" }} />
                        }>
                        Learn about the different roles
                      </Button>
                    </ControlLine>
                    {this.state.role_info ? (
                      <ControlLine>
                           <StyledTable>
                           <tbody>
                       <tr><td></td><StyledTD >Admin</StyledTD><StyledTD>Contributor</StyledTD><StyledTD>Commenter</StyledTD><StyledTD>Viewer</StyledTD>
                       </tr>
                       <tr>
                         <td>Change Group Settings</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD></StyledTD><StyledTD></StyledTD><StyledTD></StyledTD>
                       </tr>
                       <tr>
                         <td>Add/Remove Participants</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD></StyledTD><StyledTD></StyledTD><StyledTD></StyledTD>
                       </tr>
                       <tr>
                         <td>Share Projects to Group</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD></StyledTD><StyledTD></StyledTD>
                       </tr>
                       <tr>
                         <td>Comment on Projects</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD></StyledTD>
                       </tr>
                       <tr>
                         <td>See Other Participants</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD>
                       </tr>
                       <tr>
                         <td>View Comments</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD>
                       </tr>
                       <tr>
                         <td>View Projects</td><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD><StyledTD><CheckIcon color="primary" /></StyledTD>
                       </tr>
                      </tbody>
                       </StyledTable>
                      </ControlLine>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <StyledButtonContainer>
                  <StyledButton>
                    <Button
                      onClick={() => {
                        this.sendInvites();
                      }}
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px" }}>
                      Send Invites
                    </Button>
                  </StyledButton>
                </StyledButtonContainer>
              </ColumnSingle>
            </SettingsColumns>
          </Dialog>
        </TopContainer>
      </ElementContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  dimensions: state.dimensions,
  follow_group: state.auth.myprofile.followers_group,
  search_results: state.groups.search_results,
  groupid: state.groups.current_group.id,
  orgid: state.org.id
});
export default connect(mapStateToProps)(withRouter(GlobalInvite));
