import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  HeroImage,
  ThreeColumn,
  FeatureImage,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageRightSection,
  LeftRightImage,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
  FAQHeader,
  FAQParagraph,
  FAQColumn,
  FAQSubHeader,
} from "./MarketingComponents";
import marketing_urls from "../../config/marketing_urls";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";
class Support extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTitle(`${site_name} | Support`)
  }
  handleWaitMenu() {
    console.log("wait");
    this.props.dispatch(setMenu(true, 19));
  }
  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        {/* <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <ContentSectionTop>
          <CenterColumnSingle isMobile={isMobile}>
            <CustomH1 isMobile={isMobile}>
              <H1Text>Made Explained</H1Text>
            </CustomH1>
          </CenterColumnSingle>
        </ContentSectionTop>
        <ContentSection>
          <FAQColumn>
            <FAQHeader>Projects</FAQHeader>
            In Made, you have the ability to create projects and keep track of
            each step of your progress along a timeline. On the timeline, you
            can include various types of elements such as images, videos, text,
            dimensions, and image comparisons.
            <Link
              target="_blank"
              to={"/" + marketing_urls.sample_projects.allprojects + "/"}
              style={{ textDecoration: "none", color: "#008EFF" }}>
              <OpenInNewIcon
                style={{ fontSize: "15px", marginBottom: "-2px" }}
              />{" "}
              See a list of sample projects
            </Link>
            <FAQSubHeader>Element Order</FAQSubHeader>
            When working with your timeline in Made, it's important to know that
            the order of the elements is determined by their date and time. To
            switch up the order, simply adjust the date and time for each
            element. You can also make changes to multiple items at once using
            the re-order dialog.
            <FAQSubHeader>Project States</FAQSubHeader>In Made, your project can
            have various states such as "To Do," "In Progress," or "Complete."
            Once you finish a project, you can mark it as complete by adding a
            completion date, and even showcase a cover page above the timeline
            if you'd like! It's a great way to showcase your hard work and
            celebrate your progress.
            <FAQSubHeader>Categories and Tags</FAQSubHeader>When creating a
            project in Made, you have the option to choose one or more
            pre-defined categories to help organize and categorize your project.
            As for tags, you're free to select anything that fits your project
            and helps you stay organized.
            <FAQSubHeader>Sharing</FAQSubHeader>In Made, any project you create
            is automatically set to "private," meaning only you can see it.
            However, you can choose to share your project publicly or with
            specific groups to collaborate and showcase your progress. It's
            completely up to you to decide who gets to see your project and
            when.
            <FAQHeader>Elements</FAQHeader>
            In Made, you have the option to add different types of elements to
            your timeline. With each element, you can add a description, label,
            link, and date to provide more context and information.
            <Link
              target="_blank"
              to={"/project/" + marketing_urls.sample_projects.elements + "/"}
              style={{ textDecoration: "none", color: "#008EFF" }}>
              <OpenInNewIcon
                style={{ fontSize: "15px", marginBottom: "-2px" }}
              />{" "}
              See Example Elements
            </Link>
            <FAQSubHeader>Images</FAQSubHeader>
            <FAQParagraph>
              In Made, you have the option to upload up to 10 images to your
              timeline. The elements will automatically receive the date from
              the image, showing it on the correct position on the time line.
              Once uploaded, you can customize the images further by adding{" "}
              <Link
                target="_blank"
                to={"/project/" + marketing_urls.sample_projects.drawing + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                lines
              </Link>
              ,{" "}
              <Link
                target="_blank"
                to={"/project/" + marketing_urls.sample_projects.drawing + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                {" "}
                shapes
              </Link>
              , or
              <Link
                target="_blank"
                to={"/project/" + marketing_urls.sample_projects.grids + "/"}
                style={{ textDecoration: "none", color: "#008EFF" }}>
                {" "}
                grids
              </Link>
              , as well as adjusting settings like rotation, mirroring,
              cropping, and perspective cropping.
            </FAQParagraph>
            <FAQSubHeader>Video</FAQSubHeader>
            Upload video or audio to the timeline. The elements automatically
            get the date from the uploaded media. The limit for video is 60
            seconds.
            <FAQSubHeader>Text</FAQSubHeader>
            With the text element in Made, you can create a colorful and
            eye-catching element on your timeline, complete with a description.
            This feature can be incredibly helpful for creating chapter markers,
            jotting down notes, or adding to-do items to your project.
            <FAQSubHeader>Dimension</FAQSubHeader>Indicate the dimension of your
            project. For example a painting that is 20"x30".
            <FAQSubHeader>Image Comparison</FAQSubHeader>Select or upload 2
            images and compare them either side by side or as an overlay.
            <FAQHeader>Groups</FAQHeader>A group is a community of individuals
            who share a common interest, such as oil painting, woodworking, or
            even just friends and family. Within a group, participants can share
            projects privately that only other group members can view and
            comment on.
            <FAQSubHeader>Create Groups</FAQSubHeader>
            You have the option to create new groups and invite others to join.
            Keep in mind that only individuals with a Made subscription will be
            able to join a group. Once you're a member of a group, it will
            appear as a sharing option in your project settings, making it easy
            to collaborate with your group members on your projects.
            <FAQSubHeader>Roles</FAQSubHeader>
            As an Admin of a group, you can set different roles for
            participants. A Viewer can just view the projects and comments. A
            Commenter can also comment on elements, A Contributor can also share
            projects with the group. An Admin can invite and remove other
            members.
            <FAQSubHeader>Comments</FAQSubHeader>
            Comments on a project are specific to the group in which the project
            is shared. If a project is shared in multiple groups, comments made
            in one group will not be visible to members of other groups. As the
            owner of a project, you have the ability to switch between contexts
            (groups, public) to see and respond to comments as needed.
            Additionally, if you want to leave a comment that is visible to all
            members of every context, you can choose to "broadcast" that
            comment.
            <Link
              target="_blank"
              to={"/project/" + marketing_urls.sample_projects.comments + "/"}
              style={{ textDecoration: "none", color: "#008EFF" }}>
              <OpenInNewIcon
                style={{ fontSize: "15px", marginBottom: "-2px" }}
              />{" "}
              See Example Comments
            </Link>
            <FAQHeader>Feed</FAQHeader>
            On the homepage of Made, the feed displays a chronological list of
            projects that have been recently updated, and that you are
            subscribed to. This makes it easy to keep track of what's new and
            stay up-to-date with the progress of your subscribed projects.
            <br />
            You have the option to subscribe to people, groups, or projects. If
            you subscribe to a person or a group, you will automatically receive
            updates on all of their new and in-progress projects.
            <FAQHeader>Mobile</FAQHeader>
            Using Made on your mobile phone's browser is incredibly convenient,
            especially for quickly updating your projects on-the-go. Whether
            you've just completed a step in your project or you want to share a
            new image, Made's mobile interface makes it easy to post updates
            from anywhere. You can either upload an image or use your phone's
            camera directly within the browser for added convenience.
            <br />
            Apple iOS uses an image format HEIC by default. When uploading a heic image from the mobile phone,
            the phone will convert the image to jpeg and resets the date of the image to now. If you change the Camera Capture setting to "Most Compatible", then
            images will be stored a JPEG on the phone and when uploading the original date of the image will be retained.


          </FAQColumn>
        </ContentSection>
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(Support));
