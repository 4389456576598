 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const DrawRect = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M19,5v14H5V5H19 M21,3H3v18h18V3L21,3z"/>
</SvgIcon>
  );
};

export default DrawRect;

