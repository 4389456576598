import React, { Component } from "react";
import styled from "@emotion/styled";

class AngleGrid extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
 

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let x, y, w, h;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
     
      const expand = obj.options.expand;
      let gridwidth, gridheight;

      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }
      if (expand) {
        x = 0;
        y = 0;
        w = 100;
        h = 100 / used_ratio;
        gridwidth = 100;
        gridheight = 100 / used_ratio;
      } else {
        gridwidth = w;
        gridheight = h;
      }

     const x1 = x + 1*gridwidth/2.618
     const x2 = x + 1.618*gridwidth/2.618
     const y1 = y + 1*gridheight/2.618
     const y2 = y + 1.618*gridheight/2.618
     
    const g_ratio = 1.618;
    const angle = Math.atan2(gridheight,gridwidth)
    let l = Math.sqrt(gridwidth*gridwidth+gridheight*gridheight)
    if (l === 0){
      l = 0.01
    }
    const baroque = obj.options.baroque 
    const sinister = obj.options.sinister 
    const reciprocal = obj.options.reciprocal 
    const verts = obj.options.vertical 
    const horzs = obj.options.horizontal 
   
    let lines = []
    if (verts){
      
      const D = (gridwidth*(gridheight*gridheight/l))/l
      const linex1 = x +D
      const linex2 = x + gridwidth - D
      const line = (
       <g   key={obj.id + "g1" }> <line
          key={obj.id + "ll1" }
          x1={linex1 }
          y1={y}
          x2={linex1}
          y2={y + gridheight}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op*0.5+"%"}
          stroke={obj.bg}
        />
         <line
          key={obj.id + "ll2" }
          x1={linex2 }
          y1={y}
          x2={linex2}
          y2={y + gridheight}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op*0.5+"%"}
          stroke={obj.bg}
        />
        </g>
      )
    lines.push(line)
    }
    if (horzs){
      
      const D = (gridheight*(gridheight*gridheight/l))/l
      const liney1 = y + D
      const liney2 = y + gridheight - D
      const line = (
       <g  key={obj.id + "g2" }> <line
          key={obj.id + "ll3" }
          x1={x }
          y1={liney1}
          x2={x + gridwidth}
          y2={liney1}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op*0.5+"%"}
          stroke={obj.bg}
        />
         <line
          key={obj.id + "ll4" }
          x1={x }
          y1={liney2}
          x2={x + gridwidth}
          y2={liney2}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op*0.5+"%"}
          stroke={obj.bg}
        />
        </g>
      )
    lines.push(line)
    }
      if (baroque){
        const line = (
          <line
            key={obj.id + "ll5" }
            x1={x }
            y1={y+gridheight}
            x2={x + gridwidth}
            y2={y}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
        )
      lines.push(line)
      if (reciprocal){
        let linex = 1
        let linex2 = 1
        let liney = 1
        let liney2 = 1
        console.log(gridheight)
        if (gridwidth/gridheight > 1){
          linex = x + (gridwidth - ((gridheight*gridheight)/gridwidth))
          linex2 = x + ((gridheight*gridheight)/gridwidth)
          liney = y
          liney2 = y+gridheight
        }else{
          linex = x 
          linex2 = x + gridwidth
          liney = y + (gridheight - ((gridwidth*gridwidth)/gridheight)) || 1
          liney2 = y + ((gridwidth*gridwidth)/gridheight) || 1
        }
        const line = (
          <g  key={obj.id + "g3" }><line
            key={obj.id + "ll6" }
            x1={linex}
            y1={liney}
            x2={x + gridwidth}
            y2={y+gridheight}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
           <line
          key={obj.id + "ll7" }
          x1={x }
          y1={y}
          x2={linex2}
          y2={liney2}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op+"%"}
          stroke={obj.bg}
        />
        
          </g>
        )
      lines.push(line)
      }
      }
      if (sinister){
        const line = (
         <line
            key={obj.id + "ll8" }
            x1={x }
            y1={y}
            x2={x + gridwidth}
            y2={y +gridheight}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
         
        )
      lines.push(line)
      if (reciprocal){
        let linex = 1
        let linex2 = 1
        let liney = 1
        let liney2 = 1
        if (gridwidth/gridheight > 1){
          linex = x + ((gridheight*gridheight)/gridwidth) || 1
          linex2 = x + (gridwidth - ((gridheight*gridheight)/gridwidth)) || 1
          liney = y
          liney2 = y + gridheight
        }else{
          linex = x + gridwidth
          linex2 = x 
          liney = y + gridheight - ((gridwidth*gridwidth)/gridheight) || 1
          liney2 = y + ((gridwidth*gridwidth)/gridheight) || 1
        }
        const line = (
          <g  key={obj.id + "g4" }><line
            key={obj.id + "ll9" }
            x1={linex}
            y1={liney}
            x2={x}
            y2={y+gridheight}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
           <line
            key={obj.id + "ll10" }
            x1={x + gridwidth}
            y1={y}
            x2={linex2}
            y2={liney2}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
        </g>
        )
      lines.push(line)
      }
      }
      return (
        <g key={obj.id}>
          <g onMouseDown={this.handleMouseDownObj.bind(this, obj)}>
         
        
        {lines}
          </g>
          {obj.id === objSelect ? (
            <g>
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
               
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
              <rect
             
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
             >
              {" "}
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default AngleGrid;
