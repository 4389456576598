import { CONSTANTS } from "../actions";
import axiosInstance from "../utils/axiosApi";
import jwt_decode from "jwt-decode";
// import axios from 'axios'
import { api_endpoint } from "../config";
import { getMyProfileDataID, getNotifications } from "./profileActions";
import { setMenu } from "./uiActions";
import { showError } from './alertActions'
import analyticsInstance from "../utils/analytics";

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/users/register/", userData, { login: false })
    // .then((res) => history.push("/login")) // re-direct to login on successful register
    .then((res) => history.push("/verify")) // re-direct to login on successful register
    .catch((err) => {
      dispatch({
        type: CONSTANTS.GET_ERRORS,
        payload: err.response.data,
      })
    }
    );
};
// Register User
// wait list User
export const waitlistUser = (userData, history) => (dispatch) => {

  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/users/waitlist/", userData, { login: false })
    .then((res) => {
      dispatch({
        type: CONSTANTS.CONFIRM_WAITLIST,
      })
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.GET_ERRORS,
        payload: err.response.data,
      })
    }
    );
};
// Register User

export const initiateReset = (data, history) => (dispatch) => {
  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/users/passwordreset/", data, { login: false })
    .then((res) => dispatch({
      type: CONSTANTS.RESET_STATE,
      payload: 1,
    })
    )

    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
};

export const resetPassword = (data, history) => (dispatch) => {
  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/users/passwordreset/complete/", data, { login: false })
    .then((res) => history.push("/login")) // re-direct to login on successful reset
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
};
// Validate Email
export const validateEmail = (token, history) => (dispatch) => {
  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/email-verify/", { token: token }, { login: false })
    .then((res) =>
      dispatch({
        type: CONSTANTS.GET_VALIDATION,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
};
// Resend Validation Email
export const resendEmail = (userData, history) => (dispatch) => {
  axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance
    .post(api_endpoint + "/users/resend/", userData, { login: false })
    .then((res) =>
      dispatch({
        type: CONSTANTS.GET_VALIDATION,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(showError(err))
      // dispatch({
      //   type: CONSTANTS.ON_ERROR,
      //   payload: err.response.data,
      // })
    );
};
// Login - get user token
export const loginUser = (userData, history, isDialog) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_PROFILE_LOADING,
    payload: "loading",
  });
  axiosInstance
    .post("/token/obtain/", userData, { login: false })
    .then((result) => {
      axiosInstance.defaults.headers["Authorization"] =
        "Bearer " + result.data.access;
      localStorage.setItem("access_token", result.data.access);
      localStorage.setItem("refresh_token", result.data.refresh);
      // Decode token to get user data
      const decoded = jwt_decode(result.data.refresh);
      // Set current user

      decoded.username = userData.username;
      dispatch(setCurrentUser(decoded));
      //console.log("getdata", decoded);
      dispatch(getMyProfileDataID(decoded.user_id));
      dispatch(getNotifications());

      if (isDialog) {

        dispatch(setMenu(false));
        window.location.reload(true);

      } else {
        history.push("/home/");
        window.location.reload(true);
      }

    })
    .catch((err) => {
      // let msg
      // try {
      //   msg = err.response.data.detail
      // } catch (e) {
      //   msg = err.toString()
      // }
      // dispatch(showError({
      //   title: "Login Failed",
      //   message: msg
      // }

      // ));

      dispatch({
        type: CONSTANTS.GET_ERRORS,
        payload: err.response ? err.response.data : err,
      })

    });
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: CONSTANTS.SET_CURRENT_USER,
    payload: decoded,
  };
};
export const updateTicket = (data) => (dispatch) => {
  dispatch({
    type: CONSTANTS.UPDATE_TICKET,
    payload: data,
  })
};
// User loading
export const setUserLoading = () => {
  return {
    type: CONSTANTS.USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage

  axiosInstance
    .post("/blacklist/", {
      refresh_token: localStorage.getItem("refresh_token"),
    })
    .then((result) => {
      //console.log("logging out");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;
      dispatch(setCurrentUser({}));
      analyticsInstance.reset()
      window.location.href = "/";
    })
    .catch((err) => {
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("refresh_token");
      // axiosInstance.defaults.headers["Authorization"] = null;
      dispatch(setCurrentUser({}));
      analyticsInstance.reset()
      // window.location.href = "/";
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
  // localStorage.removeItem('access_token');
  // localStorage.removeItem('refresh_token');
  // Remove auth header for future requests

  // Set current user to empty object {} which will set isAuthenticated to false
};
// Get User Subscriptions
export const getSubscriptions = () => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_SUBSCRIPTIONS_LOADING,
  });
  axiosInstance
    .get(api_endpoint + "/me/subscriptions/")
    .then((res) =>
      dispatch({
        type: CONSTANTS.GET_SUBSCRIPTIONS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
}

export const getMySubscriptions = (type) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_SUBSCRIPTIONS_LOADING,
    payload: type
  });
  axiosInstance
    .get("me/subscriptions/" + type + "/")
    .then((result) => {
      result.data.sub_type = type
      dispatch({
        type: CONSTANTS.GET_MY_SUBSCRIPTIONS,
        payload: result.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const loadMoreSubscriptions = (link, type) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    res.data.sub_type = type
    dispatch({
      type: CONSTANTS.LOAD_MORE_SUBSCRIPTIONS,
      payload: res.data,
    });
  });
};
export const createSubscriptionGeneric = (type, id) => (dispatch) => {
  // dispatch({
  //   type: CONSTANTS.SET_SUBSCRIPTIONS_LOADING,
  // });
  const data = { id: id, type: type }
  analyticsInstance.track("create_subscription", data)
  axiosInstance
    .post(`${api_endpoint}/${type}/${id}/subscribe/`)
    .then((res) =>
      dispatch({
        type: CONSTANTS.TOGGLE_SUBSCRIPTION,
        payload: data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
}
export const removeSubscriptionGeneric = (type, id) => (dispatch) => {
  console.log("remove")
  // dispatch({
  //   type: CONSTANTS.SET_SUBSCRIPTIONS_LOADING,
  //   payload: type
  // });
  const data = { id: id, type: type }
  analyticsInstance.track("delete_subscription", data)
  axiosInstance
    .delete(`${api_endpoint}/${type}/${id}/subscribe/`)
    .then((res) =>
      dispatch({
        type: CONSTANTS.TOGGLE_SUBSCRIPTION,
        payload: data,
      })
    )
    .catch((err) =>
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err.response.data,
      })
    );
}