import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import { logoutUser } from "../actions/authActions";
import {
  getMyProfileDataID,
  getNotifications,
  setNotificationsRead,
} from "../actions/profileActions";
import { setMenu, resetProjectLink } from "../actions/uiActions";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CardTravelOutlinedIcon from "@mui/icons-material/CardTravelOutlined";
import UberMenu from "./UberMenu";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Badge from "@mui/material/Badge";
// import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BusinessIcon from '@mui/icons-material/Business';
import Button from "@mui/material/Button";
import theme from "../config/theme";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import Lifebuoy from "phosphor-react";

const WrapperDiv = styled.div`
  // height: 100vh;
  // height: -webkit-fill-available;
  // position: fixed;
  // z-index: 2000;
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  z-index: 998;
  width: 100%;
  // padding-left: 15px;
  padding-top: 3px;
  height: 47px;
 
  display: flex;
  background-color: ${(props) => (props.darkNav ? "transparent" : "#fff")};
  //   border-bottom: 1px solid #e3e3e3;
  box-shadow: ${(props) => (props.darkNav ? "nonet" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)")};
  
`;
const StyledName = styled.div`
  font-size: 18px;
  color: ##000000de;
  margin-top: 11px;
  margin-left: 6px;
  // text-transform: uppercase;
  font-family: Helvetica, sans-serif;
  font-weight: 600;
  font-style: normal;
`;
const StyledNavItem = styled.div`
  text-align: left;
  font-size: 24px;
  font-family: roboto, sans-serif;
  font-weight: 500;
  color: #FF00FF;
  // background-color: #ccc;
  text-decoration: none !important;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  // text-transform: uppercase;
  // width: 60px;
  white-space: nowrap;
`;
const StyledNavItemLogo = styled.div`
  text-align: left;
  font-size: 24px;
  font-family: roboto, sans-serif;
  font-weight: 500;
  color: #111;
  // background-color: #ccc;
  text-decoration: none !important;
  margin-top: 7px;

  margin-left: ${(props) => (props.isMobile ? "15px" : "32px")};
  margin-right: 5px;
  cursor: pointer;
  // text-transform: uppercase;
  // width: 60px;
  white-space: nowrap;
`;
const StyledNavItemNotify = styled.div`
  text-align: left;
  font-size: 24px;
  font-family: roboto, sans-serif;
  font-weight: 500;
  color: #111;
  // background-color: #ccc;
  text-decoration: none !important;
  margin-top: 6px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  // text-transform: uppercase;
  // width: 60px;
  white-space: nowrap;
`;
const TextNavItemLeft = styled.div`
  text-align: right;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #394b54;
  // background-color: #e6d0ff;
  text-decoration: none !important;
  margin-top: 14px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  // text-transform: uppercase;
  width: 100px;
  white-space: nowrap;
`;
const TextNavItemRight = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: ${(props) => (props.darkNav ? "#fff" : "#394b54")};
  // background-color: #e6d0ff;
  text-decoration: none !important;
  margin-top: 14px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  // text-transform: uppercase;

  white-space: nowrap;
`;
const NavItems = styled.div`
  text-align: left;
  font-size: 12px;
  font-family: roboto, sans-serif;
  font-weight: 500;
  color: #383838;

  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "15px" : "30px")};
  cursor: pointer;
  // text-transform: uppercase;
  display: flex;
`;
const NavGroup = styled.div`
  display: flex;
  // margin-right: 30px;
`;
const PTLeft = styled.div`
  margin-right: auto;
  // background-color: #e6d0ff;
  width: 50%;
  display: flex;
`;
const PTMid = styled.div`
  margin-left: auto;
  margin-right: auto;

  // min-width:300px;
  display: flex;
  justify-content: center;
`;
const PTRight = styled.div`
  margin-left: auto;
  display: flex;
  width: 50%;
`;
const StyledElementPopup = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1001;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  // vertical-align: middle;
`;
const StyledFooter = styled.div`
  position: fixed;
  bottom: 0;
  margin-bottom: -4px;
  z-index: 998;
  width: 100%;
  // padding-left: 15px;
  //   padding-top: 3px;
  height: 56px;
  background-color: #ddd;
  display: flex;
  background-color: #fff;
  //   border-bottom: 1px solid #e3e3e3;
  box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.18);
  //   -moz-box-shadow:  0 7px 9px -7px rgba(0,0,0,0.4);
  //   -webkit-box-shadow:  0 7px 9px -7px rgba(0,0,0,0.4);
  //   box-shadow:  0 7px 9px -7px rgba(0,0,0,0.4);
`;
const BottomStyledNavItem = styled.div`
  text-align: left;
  font-size: 24px;
  font-family: roboto, sans-serif;
  font-weight: 600;
  color: #383838;
  text-decoration: none !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  // text-transform: uppercase;
  width: 70px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
 
`;
const ListIcon = styled.div`
  
  font-size: 24px;
  font-family: roboto, sans-serif;
  font-weight: 600;
  color: #383838;
  text-decoration: none !important;
  
  margin-left: auto;
  margin-right: auto;
  
  
  white-space: nowrap;

 
`;
const ListLabel = styled.div`
  text-align: center;
  font-size: 12px;
  font-family: roboto, sans-serif;
  font-weight: 400;
  color: #383838;
  text-decoration: none !important;
  margin-top: -4px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  // text-transform: uppercase;
  width: 70px;
  white-space: nowrap;
 
 
`;
const StyledNumber = styled.div`
  text-align: left;
  font-size: 12px;
  font-family: roboto, sans-serif;
  font-weight: 600;
  color: #000000;

`;

const BottomNavItems = styled.div`
  text-align: left;
  font-size: 20px;
  font-family: roboto, sans-serif;
  font-weight: 600;
  color: ##000000de;

  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  // text-transform: uppercase;
  display: flex;
`;
class TopNav extends Component {
  constructor() {
    super();
    this.state = {
      showmenupopup: false,
    };
  }
  logOut = () => {
    this.props.dispatch(logoutUser());
  };
  handleCloseMenu() {
    if (this.props.menuType !== 23 && this.props.menuType !== 6 && this.props.menuType !== 4 && this.props.menuType !== 2 && this.props.menuType !== 15 && this.props.menuType !== 16 && this.props.menuType !== 17 && this.props.menuType !== 18 && this.props.menuType !== 21 && this.props.menuType !== 22) {
      document.body.style.overflow = "auto";
      this.props.dispatch(setMenu(false));
    }

  }
  handleReset() {
    this.props.dispatch(resetProjectLink(true));
  }
  handleSignUp() {
    window.location.href = "/register/";
    // this.props.dispatch(setMenu(true, 19));
  }
  handleLogIn() {
    // window.location.href = "/login/";
    this.props.dispatch(setMenu(true, 13));
  }
  componentDidMount() {
    if (this.props.myusername === undefined && this.props.isAuthenticated) {

      this.props.dispatch(getMyProfileDataID(this.props.user_id));
    }
    if (this.props.isAuthenticated) {
      this.props.dispatch(getNotifications());
    }

  }
  componentDidUpdate() {
    console.log("update")

  }
  handleShowMenu(state, type) {
    this.props.dispatch(setMenu(state, type));
    // if (this.state.showmenupopup === false ) {

    //   this.setState({ showmenupopup: true });
    // }else{
    //   this.setState({ showmenupopup: false });
    // }
  }
  handleShowNotifcations() {
    const { notifications } = this.props;
    this.props.dispatch(setMenu(true, 3));
    if (notifications.allIDS.length) {
      let NotList = { ids: [] };
      notifications.allIDS.forEach((Not) => {
        NotList["ids"].push(Not);
      });
      console.log(NotList);
      this.props.dispatch(setNotificationsRead(NotList));
    }
  }
  doNothing(e) {
    e.stopPropagation();
  }
  render() {
    const { isMobile, myusername, showMenu, hideBadge, organizations, isAuthenticated } = this.props;
    const loc = window.location.pathname.split("/")[1];
    console.log("render")
    let darkNav = false
    let isMarketing = false



    return (
      <WrapperDiv>
        <StyledHeader darkNav={darkNav}>
          <PTLeft>
            <StyledNavItemLogo
              isMobile={isMobile}
            >   <Link
              style={{ textDecoration: "none", color: "#111" }}
              to={isAuthenticated ? "/home/" : "/"}
            >
                <img width="80px" alt="logo" src="/made_black.png"></img> </Link>
              {/* <MenuOutlinedIcon fontSize="inherit" /> */}
            </StyledNavItemLogo>
            {/* <StyledName>
                <Link
                  style={{ textDecoration: "none", color: "#111" }}
                  to={"/"}
                >
                  Sequentially
                </Link>
              </StyledName> */}
          </PTLeft>
          {/* <PTMid>{isMobile ? (""):(<NavGroup> <Link  style={{textDecoration:"none"}} to={'/'+myusername}  ><TextNavItem>PROJECTS</TextNavItem></Link><StyledNavItem><AddCircleOutlineIcon  onClick={() => { this.handleShowMenu();}} fontSize='inherit'/></StyledNavItem><Link  style={{textDecoration:"none"}} to={'/groups/'}  ><TextNavItem>GROUPS</TextNavItem></Link></NavGroup>)}</PTMid> */}
          <PTMid>
            {isMobile || !isAuthenticated ? (
              ""
            ) : (

              <NavGroup>

                <Link
                  style={{ textDecoration: "none" }}
                  to={"/home/"}
                >
                  <TextNavItemRight
                    onClick={() => {
                      this.handleReset();
                    }}
                  >
                    Home
                  </TextNavItemRight>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/discover/"}
                >
                  <TextNavItemRight
                    onClick={() => {
                      this.handleReset();
                    }}
                  >
                    Discover
                  </TextNavItemRight>
                </Link>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"/" + myusername + "/"}
                >
                  <TextNavItemRight
                    onClick={() => {
                      this.handleReset();
                    }}
                  >
                    My Projects
                  </TextNavItemRight>
                </Link>

                <Link style={{ textDecoration: "none" }} to={"/groups/"}>
                  <TextNavItemRight>Groups</TextNavItemRight>
                </Link>
                {organizations && organizations.length ? (
                  <Link style={{ textDecoration: "none" }} to={"/organization/" + organizations[0].id + "/"}>
                    <TextNavItemRight>Organization</TextNavItemRight>
                  </Link>
                ) : ("")}
              </NavGroup>


            )}
          </PTMid>
          {isAuthenticated ? (<PTRight>
            <NavItems isMobile={isMobile}>
              <StyledNavItem title="add new" style={{ marginTop: "10px" }}>
                <AddCircleOutlineIcon
                  onClick={() => {
                    this.handleShowMenu(true, 1);
                  }}
                  fontSize="inherit"
                  sx={{ color: theme.palette.charcoal.main }}
                />
              </StyledNavItem>
              {/* <StyledNavItem title="send feedback" style={{ marginTop: "8px" }}>
                  <SupportAgentOutlinedIcon
                    title="send feedback"
                    onClick={() => {
                      this.handleShowMenu(true, 7);
                    }}
                    fontSize="inherit"
                    sx={{ color: theme.palette.charcoal.main }}
                  />
                </StyledNavItem> */}
              <StyledNavItemNotify title="notifications">
                <Badge
                  variant="dot"
                  invisible={hideBadge}
                  overlap="circular"
                  color="secondary"
                >
                  <NotificationsNoneIcon
                    onClick={() => {
                      this.handleShowNotifcations();
                    }}
                    fontSize="inherit"
                    sx={{ color: theme.palette.charcoal.main }}
                  />
                </Badge>
              </StyledNavItemNotify>
              <StyledNavItem title="profile settings" style={{ marginTop: "10px" }}>
                <AccountCircleOutlinedIcon
                  onClick={() => {
                    this.handleShowMenu(true, 8);
                  }}
                  fontSize="inherit"
                  sx={{ color: theme.palette.charcoal.main }}
                />
              </StyledNavItem>
            </NavItems>
          </PTRight>) : (<PTRight>
            <NavItems isMobile={isMobile}>
              <StyledNavItem title="sign up" style={{ marginTop: "6px" }}>
                <Button
                  style={{ width: "80px" }}
                  onClick={() => {
                    this.handleSignUp();
                  }}
                  disableElevation
                  variant="contained"
                  color={darkNav ? ("white") : ("primary")}
                >
                  Sign Up
                </Button>
              </StyledNavItem>
              <StyledNavItem title="log in" style={{ marginTop: "6px" }}>
                <Button
                  style={{ width: "80px", color: darkNav ? ("#fff") : ("#111") }}
                  onClick={() => {
                    this.handleLogIn();
                  }}
                  disableElevation
                  variant="toolbarbutton"
                  color="primary"

                >
                  Log In
                </Button>
              </StyledNavItem>

            </NavItems>
          </PTRight>)}

        </StyledHeader>
        {showMenu ? (
          <StyledElementPopup
            onClick={() => {
              this.handleCloseMenu();
            }}
          >

            <UberMenu />

            {/* {isMobile ? (  <MenuPanelNarrow 
                     onClick={(e) => {
                       this.doNothing(e);
                     }}>
                   <UberMenu />
                   </MenuPanelNarrow>):(
                       <MenuPanel 
                       onClick={(e) => {
                         this.doNothing(e);
                       }}>
                     <UberMenu/>
                     </MenuPanel>
                   )}               */}
          </StyledElementPopup>
        ) : (
          ""
        )}
        {isMobile && isAuthenticated ? (
          <StyledFooter>
            {isAuthenticated ? (<BottomNavItems>
              <Link style={{ textDecoration: "none" }} to={"/home/"}>
                <BottomStyledNavItem>
                  <ListIcon>
                    <ListAltIcon fontSize="inherit" />
                  </ListIcon>
                  <ListLabel>
                    Home
                  </ListLabel>
                </BottomStyledNavItem>
              </Link>

              <Link style={{ textDecoration: "none" }} to={"/discover/"}>
                <BottomStyledNavItem
                  onClick={() => {
                    this.handleReset();
                  }}
                >
                  <ListIcon>
                    <AutoAwesomeIcon fontSize="inherit" />
                  </ListIcon>
                  <ListLabel>
                    Discover
                  </ListLabel>


                </BottomStyledNavItem>
              </Link>
              <Link style={{ textDecoration: "none" }} to={"/" + myusername + "/"}>
                <BottomStyledNavItem
                  onClick={() => {
                    this.handleReset();
                  }}
                >
                  <ListIcon>
                    <CardTravelOutlinedIcon fontSize="inherit" />
                  </ListIcon>
                  <ListLabel>
                    My Projects
                  </ListLabel>


                </BottomStyledNavItem>
              </Link>
              {/* <BottomStyledNavItem>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      this.handleShowMenu(true, 1);
                    }}
                    fontSize="inherit"
                  />
                </BottomStyledNavItem> */}
              <Link style={{ textDecoration: "none" }} to={"/groups/"}>
                <BottomStyledNavItem>
                  <ListIcon>
                    <PeopleOutlineIcon fontSize="inherit" />
                  </ListIcon>
                  <ListLabel>
                    Groups
                  </ListLabel>

                </BottomStyledNavItem>
              </Link>
              {organizations && organizations.length ? (
                <Link style={{ textDecoration: "none" }} to={"/organization/" + organizations[0].id + "/"}>
                  <BottomStyledNavItem>
                    <ListIcon>
                      <BusinessIcon fontSize="inherit" />
                    </ListIcon>
                    <ListLabel>
                      ORG
                    </ListLabel>

                  </BottomStyledNavItem>
                </Link>
              ) : ("")}
            </BottomNavItems>) : (
              "")}

            {/* <BottomStyledNavItem>
                <StyledNumber>
                   {this.props.h}
                   </StyledNumber>
                </BottomStyledNavItem> */}
          </StyledFooter>
        ) : (
          ""
        )}
      </WrapperDiv>
    );

  }
}

const mapStateToProps = (state) => ({
  // loading: state.public.projects_loading,
  // projects: state.public.projects
  isAuthenticated: state.auth.isAuthenticated,
  myusername: state.auth.myprofile.username,
  organizations: state.auth.myprofile.organizations,
  hideBadge: state.auth.notifications.hideBadge,
  user_id: state.auth.user.user_id,
  isMobile: state.dimensions.window_dimensions.isMobile,
  showMenu: state.ui.showMenu,
  notifications: state.auth.notifications,
  menuType: state.ui.menuType,
  h: state.dimensions.window_dimensions.h
});
export default connect(mapStateToProps)(TopNav);
