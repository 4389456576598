import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { loadMoreFeedItems } from "../actions/homeActions";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import PersonThumb from "./thumbs/PersonThumb";
import FirstUseBanner from "./FirstUseBanner";
import { unfollowProject } from "../actions/projectActions";
import { api_endpoint } from "../config";
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: column;
  width 100%;
  flex-wrap: wrap;
  padding-bottom: 80px;
`;
const ProjectToolbar = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  height: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  text-align: center;
`;
const ProjectNavActive = styled.div`
  color: #111;
`;
const ProjectNavInActive = styled.div`
  color: #b6b6b6;
`;
const ProjectNavDash = styled.div`
  color: #111;
  width: 30px;
`;
// const ProjectSpacer = styled.div`
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 20px;
//   display: flex;
//   flex-direction: column;

//   padding-bottom: 50px;
//   cursor: pointer;

//   &:hover {
//     background-color: #e1e8f1;
//   }
// `;
// const CenterButton = styled.div`
//   margin-left: auto;
//   margin-right: auto;
//   margin-bottom: 20px;
//   display: flex;
//   flex-direction: column;

//   padding-bottom: 50px;
// `;
// const MoreButton = styled.div`
//   width: 100%;
//   display: flex;
//   margin-left: 10px;
//   margin-top: 20px;
// `;
// const ElementCardLarge = styled.div`
//   // width: calc(100%-40px);
//   width: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
//   height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
//   background-color: #fff;
//   border: ${(props) =>
//     props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
//   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
//   border-radius: 5px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 4px;
//   display: flex;
//   flex-direction: column;
//   position: relative;
// `;
// const ElementCard = styled.div`
//   // width: calc(100%-40px);
//   width: ${(props) => (props.isMobile ? "40" : "70")}px;
//   height: ${(props) => (props.isMobile ? "40" : "70")}px;
//   background-color: #fff;
//   border: ${(props) =>
//     props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
//   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
//   border-radius: 1.5%;
//   margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
//   margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
//   margin-top: 4px;
//   display: flex;
//   flex-direction: column;
//   position: relative;
// `;
// const ElementRow = styled.div`
//   display: flex;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 15px;
//   margin-bottom: 0px;
// `;
// const ProjectName = styled.div`
//   display: flex;
//   margin-left: auto;
//   margin-right: auto;
//   font-family: "Merriweather", serif;
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 121.5%;
//   margin-bottom: 8px;
//   text-align: center;
//   color: #111;
// `;
// const GroupName = styled.div`
//   display: flex;
//   margin-left: auto;
//   margin-right: auto;
//   font-family: "Roboto", sans-serif;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 121.5%;

//   color: #2196f3;
// `;
// const PersonName = styled.div`
//   // min-width: 300px;
//   display: flex;
//   margin-left: auto;
//   margin-right: auto;
//   font-family: "Merriweather", serif;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 121.5%;
//   margin-bottom: 8px;
//   color: #111;
//   margin-top: 10px;
// `;
const FollowItem = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  max-width: 700px;
  margin-bottom: 70px;
`;
const ItemLeft = styled.div``;

const ItemMid = styled.div`
  margin-left: 19px;
  margin-right: 19px;
`;

const ItemRight = styled.div`
  margin-left: auto;
`;

const ItemTitle = styled.div`
  font-family: Merriweather;
  font-size: ${(props) => (props.isMobile ? "16" : "24")}px;
  font-style: normal;
  font-weight: 700;
  line-height: ${(props) => (props.isMobile ? "20" : "32")}px;
  letter-spacing: 0em;
  text-align: left;
  color: #111;
  width: ${(props) => (props.isMobile ? "175" : "300")}px;
  text-overflow: ellipsis;
  // white-space: nowrap;
  overflow: hidden;
`;

const ItemGroups = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* or 200% */

  color: #2196f3;
`;
const ItemCreator = styled.div`
  display: flex;
`;

const ItemThumb = styled.div`
  //   margin: 10px;
  position: relative;
  display: block;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  flex: 1;
  width: ${(props) => (props.isMobile ? "50" : "95")}px;
  height: ${(props) => (props.isMobile ? "50" : "95")}px;
  background-color: #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: ${(props) =>
    props.noshadow ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};

  border-radius: 4px;

  display: flex;
  flex-direction: column;
`;
const StyledVisual = styled.div`
  margin: 5%;
  width: 90%;
  height: 90%;
  border-radius: 1px;

  background: url("${(props) => props.src}") #f1f1f1;

  background-position: center;
  background-size: cover;
`;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectname: "",
    };
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#f2f2f2";
  }
  handleChangename = (event, newValue) => {
    //console.log(newValue);
    this.setState({ projectname: newValue });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  // newProject() {
  //   //console.log(this.state.projectname);
  //   this.props.dispatch(createProject(this.state.projectname));
  // }
  unfollowProject = (id) => {
    this.props.dispatch(unfollowProject(id, this.props.userid));
  };
  loadMoreProjects = (link) => {
    this.props.dispatch(loadMoreFeedItems(link));
  };
  render() {
    const { following, loading, isMobile } = this.props;

    if (loading === false) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>

          <ProjectToolbar>
            <Link style={{ textDecoration: "none" }} to="/">
              <ProjectNavInActive>FEED</ProjectNavInActive>
            </Link>{" "}
            <ProjectNavDash>-</ProjectNavDash>{" "}
            <ProjectNavActive>FOLLOWING</ProjectNavActive>
          </ProjectToolbar>
          <Projectcontainer>
            {following.allIDS.map((projectid, index) => {
              const project = following.byID[projectid];
              let linkurl;
              if (project.groups.length) {
                linkurl =
                  "/project/" +
                  project.id +
                  "/" +
                  project.name.replace(/\s+/g, "-").toLowerCase() +
                  "/group/" +
                  project.groups[0].id + "/";
              } else {
                linkurl =
                  "/project/" +
                  project.id +
                  "/" +
                  project.name.replace(/\s+/g, "-").toLowerCase() + "/";
              }
              return (
                <FollowItem>
                  <Link
                    key={index}
                    style={{ textDecoration: "none" }}
                    to={linkurl}
                  >
                    <ItemLeft>
                      <ItemThumb isMobile={isMobile}>
                        <StyledVisual
                          src={project.image ? api_endpoint + "/media/" + project.image.id + "/file?size=256" : ""}
                        ></StyledVisual>
                      </ItemThumb>
                    </ItemLeft>
                  </Link>
                  <ItemMid>
                    <Link
                      key={index}
                      style={{ textDecoration: "none" }}
                      to={linkurl}
                    >
                      <ItemTitle isMobile={isMobile}>{project.name}</ItemTitle>
                    </Link>
                    <ItemGroups>
                      {project.groups.map((group, index) => {
                        if (index > 0) {
                          return ", " + group.name;
                        } else {
                          return group.name;
                        }
                      })}
                    </ItemGroups>
                    <ItemCreator>
                      <PersonThumb
                        username={project.creator.username}
                        src={
                          project.creator.avatar &&
                          api_endpoint + "/media/" + project.creator.avatar.id + "/file?size=256"

                        }
                        fullname={project.creator.fullname}
                        tag_line={project.creator.tag_line}
                        status={project.creator.status}
                        size="xs"
                      />
                    </ItemCreator>
                  </ItemMid>
                  <ItemRight>
                    <Button
                      disableElevation
                      variant="outlined"
                      color="primary"
                      style={{ minWidth: "100px" }}
                      onClick={() => {
                        this.unfollowProject(project.id);
                      }}
                    >
                      Unfollow
                    </Button>
                  </ItemRight>
                </FollowItem>
              );
            })}
          </Projectcontainer>
        </div>
      );
    } else {
      return (
        <div style={{ height: "100%", width: "100%", display: "flex" }}>
          <CircularProgress style={{ margin: "auto" }} />
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  userid: state.auth.user.user_id,
  loading: state.auth.following.loading,
  following: state.auth.following,
  isMobile: state.dimensions.window_dimensions.isMobile,
});
export default connect(mapStateToProps)(Home);
