 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'HideDrawing',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const HideDrawing = (props) => {
  return (
      <SvgIcon {...props}>
  <path d="M17.9,19.9l2.4,2.4l1.2-1.2L3.7,3.2L2.5,4.4l6.3,6.3L17.9,19.9z"/>
<path d="M8.1,12.9c2,0,3.7,1.7,3.7,3.7s-1.7,3.7-3.7,3.7s-3.7-1.7-3.7-3.7S6.1,12.9,8.1,12.9 M8.1,10.9c-3.1,0-5.7,2.6-5.7,5.7
	s2.6,5.7,5.7,5.7s5.7-2.6,5.7-5.7S11.3,10.9,8.1,10.9L8.1,10.9z"/>
<polygon points="12.4,9.5 12.4,4.1 19.2,4.1 19.2,10.9 13.8,10.9 15.7,12.9 21.2,12.9 21.2,2.1 10.4,2.1 10.4,7.5 "/>  </SvgIcon>
  );
};

export default HideDrawing;