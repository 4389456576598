import React, { Component, useRef } from "react";
import styled from "@emotion/styled";
import useAudioPlayer from "../hooks/useAudioPlayer";
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ReplayIcon from '@mui/icons-material/Replay';

const AudioContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
`
const ViewContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    max-height: calc(100% - 32px);
    background-image: url(${props => props.thumb});
    background-size: contain;
    background-repeat: no-repeat;
    flex-grow: 1;
`
const AudioOverlay = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${props => props.thumb});
    background-color: ${(props) => props.theme.palette.charcoal.main};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`
const BigButton = styled.div`
    width: 72px;
    height: 72px;
    max-width: 72px;
    max-height: 72px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.charcoal.main};
    color: #fff;
    box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 64px;
`
const AudioControls = styled.div`
    display: flex;
    flex-direction: row;
    background: ${props => props.theme.palette.charcoal.main};
    color: ${props => props.theme.palette.charcoal.contrastText};
    height: 32px;
    width: 100%;
    align-items: center;
    justify-content: center;
`
const TimeStamp = styled.div`
    display: flex;
    flex-grow: 1;
    font-size: 12px;
    font-family: ${props => props.theme.typography.fontFamily};
    white-space: nowrap;
    padding-right: 12px;
`
const SeekBar = styled(Slider)({
    color: "white",
    flexGrow: 1,
    '& .MuiSlider-thumb': {
        transition: 'none',
    },
    '& .MuiSlider-track': {
        transition: 'none',
    }
})


function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
}

const AudioPlayer = ({ src }) => {
    const audioElement = useRef(null);
    const {
        playerState,
        togglePlay,
        handleOnLoad,
        handleOnTimeUpdate,
        handleOnAudioEnded,
        handleAudioProgress,
        toggleMute,
    } = useAudioPlayer(audioElement);

    const [fresh, setFresh] = React.useState(true);

    if (playerState.isPlaying && fresh) {
        setFresh(false);
    }

    function handleKeyDown(e) {
        console.log(e)
        if (e.keyCode === 0x20) {
            togglePlay();
        }
    }


    return (
        <AudioContainer>
            <AudioOverlay thumb={src + "?size=1024"} onClick={e => { audioElement.current.focus(); togglePlay() }}>
                {fresh ?
                    <BigButton>
                        <PlayArrowIcon fontSize="inherit" />
                    </BigButton>
                    : null}
                {playerState.progress === 1 ?
                    <BigButton>
                        <ReplayIcon fontSize="inherit" />
                    </BigButton>
                    : null}
            </AudioOverlay>
            <audio
                src={src}
                ref={audioElement}
                onTimeUpdate={handleOnTimeUpdate}
                onLoadedMetadata={handleOnLoad}
                onEnded={handleOnAudioEnded}
                onKeyDown={handleKeyDown}
                preload="metadata"
                hidden
            />
            <AudioControls>
                <IconButton onClick={() => togglePlay()} sx={{ color: "white" }}>
                    {playerState.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                </IconButton>
                <TimeStamp>
                    {playerState.isLoaded ? formatTime(audioElement.current.currentTime) + " / " + formatTime(audioElement.current.duration) : "-:-- / -:--"}
                </TimeStamp>
                <SeekBar
                    value={playerState.progress}
                    min={0}
                    max={1}
                    step={0.0001}
                    size="small"
                    onChange={(e, val) => handleAudioProgress(val)}
                />
                <IconButton onClick={toggleMute} sx={{ color: "white" }}>
                    {playerState.isMuted ? (
                        <VolumeOffIcon />
                    ) : (
                        <VolumeUpIcon />
                    )}
                </IconButton>
            </AudioControls>
        </AudioContainer >
    );
}

export default AudioPlayer;