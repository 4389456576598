import React, { Component } from "react";
import styled from "styled-components";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ProjectThumb from "../thumbs/ProjectThumb";
import imgUrl from "../../utils/imgUrl";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const Container = styled(Box)(({ theme }) => ({
    width: "100%",
    maxWidth: "100vw",
    height: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    boxSizing: "border-box",
}));

const UserContainer = styled(Link)(({ theme, isMobile }) => ({
    width: "170px",
    height: "170px",
    maxWidth: "170px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    paddingRight: isMobile ? "8px" : "36px",
    boxSizing: "border-box",
}));

const UserIcon = styled(Box)(({ theme, src }) => ({
    display: "flex",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    backgroundColor: "#008EFF",
    backgroundImage: src ? `url(${imgUrl(src, 256)})` : 'url(/default_icon.png)',
    backgroundSize: "cover",
    backgroundPosition: "center",
    border: "5px solid #ffffff",
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.18)",
}));

const Username = styled.h3`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #383838;
    margin: 0;
    margin-top: 8px;
    text-align: center;
`

const ProjectList = styled(Stack)(({ theme }) => ({
    width: "100%",
    overflowX: "auto",
    overflowY: "visible",
    boxSizing: "border-box",
    padding: "16px 16px",
    scrollbarWidth: "none",  /* Firefox */
    "-ms-overflow-style": "none",  /* Internet Explorer 10+ */
    "&::-webkit-scrollbar": {
        display: "none"  /* Safari and Chrome */
    }
}))

const Project = styled(Link)(({ theme, src }) => ({
    minWidth: "170px",
    width: "170px",
    height: "170px",
    display: "flex",
    border: "1px solid #fff",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    backgroundColor: "#888",
    backgroundImage: `url(${src})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3);",
    textDecoration: "none",
}));

const EmptyNote = styled.div`
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: rgba(64,64,64,0.75);
    margin-left: 18px; 
`


export const UserPreview = (props) => {
    const isMobile = useSelector((state) => state.dimensions.window_dimensions.isMobile);

    return (
        <Container>
            <UserContainer to={"/" + props.username + "/"} isMobile={isMobile}>
                <UserIcon src={props.avatar} alt={"Avatar for " + props.username} />
                <Username>{props.fullname || props.username}</Username>
                {/* <p>{props.tagline}</p> */}
            </UserContainer>
            {props.recent_projects.length === 0 ? (
                <ProjectList direction="row" spacing={2}>
                    <EmptyNote>No public projects yet...</EmptyNote>
                </ProjectList>
            ) : (
                <ProjectList direction="row" spacing={2}>
                    {props.recent_projects.map((project) => {
                        let linkurl =
                            "/project/" +
                            project.id +
                            "/" +
                            project.name.replace(/\s+/g, "-").toLowerCase() + "/";
                        return (
                            <Project key={project.id} src={imgUrl(project.image, 256)} alt={project.name} to={linkurl} />
                        )
                    })}
                </ProjectList>
            )}
        </Container>
    )
}

