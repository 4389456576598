import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { api_endpoint } from "../../config";
import theme from "../../config/theme";
import { setMenu } from "../../actions/uiActions";
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import FaceIcon from '@mui/icons-material/Face';
import PersonThumb from "../thumbs/PersonThumb";
import GroupThumb from "../thumbs/GroupThumb";
import ProjectThumb from "../thumbs/ProjectThumb";
import { UserPreview } from "./UserPreview";

const Item = styled(Box)(({ theme }) => ({
    width: "auto",
    margin: "16px",
    bgcolor: 'background.paper',
    marginBottom: 32,
    cursor: "pointer",
    textDecoration: "none",
}))

class SearchResult extends Component {
    handleLoginMenu(e) {
        e.preventDefault()
        e.stopPropagation()
        this.props.dispatch(setMenu(true, 13))
    }

    render() {
        const data = this.props.data
        // console.log(data)
        let thumbnail, count;

        switch (this.props.type) {
            case "users":
                thumbnail = (
                    <UserPreview {...data} />
                )
                break;
            case "groups":
                thumbnail = (
                    <GroupThumb {...data} />
                )
                break;
            case "projects":
                count = this.props.data.total_elements
                thumbnail = (
                    <ProjectThumb {...data} count={count} size="large" />
                )
                break;
            default:
                return null
        }

        let misc = {};

        // if (this.props.auth.isAuthenticated) {
        //     misc.component = Link
        //     misc.to = to
        // } else {
        //     misc.onClick = (evt) => { this.handleLoginMenu() }
        // }

        return (
            <Item {...misc}>
                {thumbnail}
            </Item>
        )
    }

    // old_render() {
    //     const { isMobile, result, auth } = this.props;
    //     let hero, altImg, title, subtitle, color, bgcolor, icon, to
    //     let misc = {}
    //     try {
    //         if (result.type === "Project") {
    //             hero = `${api_endpoint}/media/${result.data.image}/file?size=512`;
    //             altImg = `${api_endpoint}/media/${result.data.creator.avatar}/file?size=512`;
    //             title = result.data.name;
    //             subtitle = result.data.creator.username;
    //             color = "#000"
    //             bgcolor = "#fff"
    //             to = `/project/${result.data.id}`
    //             icon = () => (
    //                 <Box sx={{ width: 42, height: 42, margin: "auto 8px", background: `url('${altImg}'), #ccc`, backgroundSize: "cover", backgroundPosition: "center", borderRadius: "50%" }} />
    //                 // <CardTravelIcon sx={{ width: 42, height: 42, margin: "auto 8px", color: color }} />
    //             )
    //         } else if (result.type === "User") {
    //             hero = `${api_endpoint}/media/${result.data.avatar}/file?size=512`;
    //             title = result.data.fullname || result.data.username;
    //             subtitle = result.data.fullname ? result.data.username : "";
    //             color = "#fff"
    //             bgcolor = "#000"
    //             to = `/${result.data.username}`
    //             icon = () => (
    //                 <FaceIcon sx={{ width: 42, height: 42, margin: "auto 8px", color: color }} />
    //             )
    //         } else if (result.type === "Group") {
    //             hero = `${api_endpoint}/media/${result.data.image}/file?size=512`;
    //             title = result.data.name;
    //             subtitle = result.data.description;
    //             color = theme.palette.primary.contrastText
    //             bgcolor = theme.palette.primary.main
    //             to = `/group/${result.data.id}`
    //             icon = () => (
    //                 <PeopleIcon sx={{ width: 42, height: 42, margin: "auto 8px", color: color }} />
    //             )
    //         } else {
    //             return null
    //         }

    //         // AUTHDIALOG: if not authenticated, show login dialog
    //         if (auth.isAuthenticated) {
    //             misc.component = Link
    //             misc.to = to
    //         } else {
    //             misc.onClick = (evt) => { this.handleLoginMenu() }
    //         }
    //     } catch (e) {
    //         console.warn(e)
    //         return null
    //     }

    //     return (
    //         <Box
    //             sx={{
    //                 width: 319,
    //                 bgcolor: 'background.paper',
    //                 display: 'flex',
    //                 flexDirection: 'column',
    //                 backgroundColor: bgcolor,
    //                 color: color,
    //                 margin: 2,
    //                 border: "1px solid #FFFFFF",
    //                 boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.3)",
    //                 cursor: "pointer",
    //                 textDecoration: "none",
    //             }}
    //             {...misc}
    //         >
    //             <Box sx={{ width: 319, height: 319, background: `url('${hero}'), #ccc`, backgroundSize: "cover", backgroundPosition: "center" }} />
    //             <Box sx={{ display: "flex", flexDirection: "row", height: 70, alignItems: "center" }}>
    //                 {result.type === "Group" ? icon() : null}
    //                 {result.type === "User" ? icon() : null}
    //                 {result.type === "Project" ? icon() : null}
    //                 <Box sx={{ p: 0.5 }}>
    //                     <Typography variant="h6" sx={{ lineHeight: "1.25" }}>
    //                         {title}
    //                     </Typography>
    //                     {subtitle ? (<Typography variant="body1" sx={{ lineHeight: "1.25", color: "rgba(16, 16, 16, 0.66)" }}>
    //                         {subtitle}
    //                     </Typography>) : null}
    //                 </Box>
    //             </Box>
    //         </Box>
    //     )
    // }
}

const mapStateToProps = (state) => ({
    isMobile: state.dimensions.window_dimensions.isMobile,
    auth: state.auth,
});
export default connect(mapStateToProps)(SearchResult);