import { CONSTANTS } from "../actions";

const initialState = {
  window_dimensions: {},
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_DIMENSIONS: {
      const dimensions = {
        w: action.payload.width,
        h: action.payload.height,
        isMobile: action.payload.isMobile,
      };
      // console.log(dimensions)
      return { ...state, window_dimensions: dimensions };
    }

    default:
      return state;
  }
}
