import React, { Component } from "react";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import { connect } from "react-redux";
import Slider from "@mui/material/Slider";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FilterBAndWIcon from "@mui/icons-material/FilterBAndW";
import Select from "@mui/material/Select";
import {
  saveImagesaveElement,
  saveElement,
  newElement,
  newImage,
  uploadFiles,
  deleteElement,
  newImageSave,
} from "../actions/projectActions";
import CircularProgress from "@mui/material/CircularProgress";
import Dimension from "./elements/Dimension";
import Next from "./elements/Next";
import Question from "./elements/Question";
import Text from "./elements/Text";
import ElementThumb from "./thumbs/ElementThumb";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloseIcon from "../custom-icons/CloseIcon";
import FlipHor from "../custom-icons/FlipHor";
import Resize from "../custom-icons/Resize";
import loadImage from "blueimp-load-image";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import Rotate90DegreesCwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCwOutlined";
// import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import mimetype from "../config/mimetype.js";

import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { api_endpoint } from "../config";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import SubjectIcon from "@mui/icons-material/Subject";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import HeightOutlinedIcon from "@mui/icons-material/HeightOutlined";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import Rotate90DegreesCcwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCcwOutlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import FlipOutlinedIcon from "@mui/icons-material/FlipOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EditIcon from "@mui/icons-material/Edit";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import GridOnIcon from "@mui/icons-material/GridOn";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Draggable from "react-draggable";
import injectScript from "../utils/injectScript";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Divider } from "@mui/material";

import DrawCursor from "../custom-icons/DrawCursor";
import DrawLine from "../custom-icons/DrawLine";
import DrawRect from "../custom-icons/DrawRect";
import DrawCirc from "../custom-icons/DrawCirc";
import Grid from "../custom-icons/Grid";
import Spiral from "../custom-icons/Spiral";
import Phi from "../custom-icons/Phi";
import Diagonal from "../custom-icons/Diagonal";
import CompareIcon from "@mui/icons-material/Compare";
import ImageIcon from "@mui/icons-material/Image";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import Line from "./drawing/Line";
import Rect from "./drawing/Rect";
import Circ from "./drawing/Circ";
import SimpleGrid from "./drawing/Grid";
import GoldenGrid from "./drawing/GoldenGrid";
import PhiGrid from "./drawing/PhiGrid";
import AngleGrid from "./drawing/AngleGrid";
import BackspaceIcon from "@mui/icons-material/Backspace";
import VideoPlayer from "./VideoPlayer";
import AudioPlayer from "./AudioPlayer";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LineWeightIcon from "@mui/icons-material/LineWeight";
import OpacityIcon from "@mui/icons-material/Opacity";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu, { MenuProps } from "@mui/material/Menu";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import BlurOnIcon from '@mui/icons-material/BlurOn';
import ContrastIcon from '@mui/icons-material/Contrast';
import LightModeIcon from '@mui/icons-material/LightMode';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import AllOutIcon from '@mui/icons-material/AllOut';
import ColorLensIcon from '@mui/icons-material/ColorLens';
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "32px")};
  margin-top: ${(props) => (props.isMobile ? "0px" : "32px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "32px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "32px")};
  min-height: ${(props) =>
    props.isMobile ? "calc(100% + 50px)" : "calc(100% - 32px)"};
  background-color: ${(props) => (props.isMobile ? "#fff" : "auto")};
`;

const TopContainer = styled.div`
  // // max-width: 900px;
  // width: ${(props) => (props.isMobile ? "100%" : "100%")};
  // height: 100%;
  // margin-left: auto;
  // margin-right: auto;
  // // margin-left: 0;
  // // margin-right: 0;
  // display: flex;
  // flex-direction: row;
  width: 100%;
  height: calc(100% - 32px);
`;

const ElementCard = styled.div`
  // width: ${(props) => (props.isMobile ? "100%" : "auto")};

  // height: calc(100% - 30px);
  // background-color: #fff;
  // padding: 30px;

  // border-radius: ${(props) => (props.isMobile ? "0px" : "5px")};
  // margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  // margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  // margin-top: ${(props) => (props.isMobile ? "0px" : "0px")};
  // margin-bottom: ${(props) => (props.isMobile ? "0px" : "auto")};
  // display: flex;
  // flex-direction: column;

  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  padding: ${(props) => (props.isMobile ? "0px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const ElementCardConfirmation = styled.div`
  margin: 32px;
  min-height: ${(props) => (props.isMobile ? "100%" : "calc(100% - 64px)")};
  // min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  padding: ${(props) => (props.isMobile ? "32px" : "32px")};
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

// const FormLabelCustom = styled.div`

//    font-size: 14px;
//    color: rgba(0, 0, 0, 0.54);
//    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

// }
// `;
// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

const SubControl = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`;
const SubControlSpacer = styled.div`
  width: 10px;
`;
const SubControlSpacer2 = styled.div`
  width: 20px;
`;
// const SubControlLabel = styled.div`
//   margin-top: ${(props) => (props.isMobile ? "0px" : "12px")};
//   margin-bottom: 4px;
//   color: #787878;
//   font-size: 12px;
// `;
// const StyledSelect = styled.select`
//   width: 100%;
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

const StyledButtonDelete = styled.div`
   
    margin-top: 32px;
  margin-right: 20px;
    
}
`;
const StyledLeftButtons = styled.div`
   
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    margin-bottom: ${(props) => (props.isMobile ? "32px" : "0px")};
}
`;
const StyledButton = styled.div`
    
  
    
    margin-top: 32px;
    margin-left: auto;
    margin-bottom: ${(props) => (props.isMobile ? "32px" : "0px")};
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width:  ${(props) => props.w}px;
   margin-top: auto;
}
`;
const LinkError = styled.div`
font-size: 12px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 400;
text-decoration: none;
color: #0073ec;
margin-top: 5px;
}
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "32px")};
  width: ${(props) => (props.isMobile ? "100%" : "288px")};
  padding-top: 24px;
  // height: 100%;
  background-color: #ccc;
  display: none;
`;
const ColumnMiddle = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "100px")};
  height: 100%;
  flex: ${(props) => (props.isMobile ? "none" : "1")};
  // padding-left: ${(props) => (props.isMobile ? "0px" : "15px")};
  // margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  background-color: #dd4d25;
`;

const PreviewContent = styled.div`
  flex-direction: column;
  position: relative;

  background-color: #383838;
  width: 100%;
  height: ${(props) => (props.isMobile ? props.h : "calc(100vh - 160px)")};
  background-color: #000;
  display: flex;
`;
const PreviewContentCompare = styled.div`
  flex-direction: row;
  position: relative;

  background-color: #383838;
  width: 100%;
  height: ${(props) =>
    props.isMobile
      ? props.isOverlay === "OV"
        ? props.h
        : "auto"
      : "calc(100vh - 160px)"};
  background-color: #000;
  display: flex;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const CompareWrapper = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  // clip-path: ${(props) =>
    "polygon(0 0 , " + props.clip + "% 0, " + props.clip + "% 100%, 0 100%)"};
  // clip-path: ${(props) =>
    props.type === "SS"
      ? "none"
      : "polygon(0 0 , " +
        props.clip +
        "% 0, " +
        props.clip +
        "% 100%, 0 100%)"};;
`;
const CompareWrapperRight = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  clip-path: ${(props) =>
    "polygon(" +
    props.clip +
    "% 0 , 100% 0, 100% 100%, " +
    props.clip +
    "% 100%)"};
`;
const FirstImage = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.w};
  height: ${(props) => props.h};

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #ccc;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SVGWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SVGContent = styled.svg`
  z-index: 805;
  // mix-blend-mode: overlay;
  position: absolute;
  // background-color:green;
  width: 100%;
  height: 100%;
  //  touch-action: none;
  touch-action: ${(props) => (props.touchaction ? "none" : "auto")};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SecondImage = styled.div`
  position: relative;
  width: ${(props) => props.w};
  display: flex;
  height: ${(props) => props.h};
  transform: ${(props) =>
    "translateX(" +
    props.translate_x / 10 +
    "%) scale(" +
    (1 + props.scale / 100) +
    ") rotate(" +
    props.rotate +
    "deg) translateY(" +
    props.translate_y / 10 +
    "%)"};
  opacity: ${(props) => props.opacity / 100};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #f4f2f2;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const OverlayCorrection = styled.div`
  position: relative;
  width: ${(props) => props.w}px;
  display: flex;
  height: ${(props) => props.h}px;

  margin: auto;
  overflow: hidden;
`;
const NoImageSelected = styled.div`
  margin: auto;
`;
const ImageLabel = styled.div`
  font-family: Merriweather;
  font-size: 128px;
  font-weight: 400;
  line-height: 161px;
  letter-spacing: 0em;
  text-align: center;
  color: #dadada;
`;
const ImageButton = styled.div``;
const DividerLine = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  margin-top: auto;
  background-color: #d9d9d9;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const VSBadge = styled.div`
  font-family: Roboto;
  z-index: 1;
  line-height: 40px;

  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #383838;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  left: -20px;
  top: calc(50% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  background-color: #d9d9d9;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const ColumnRight = styled.div`
  min-width: ${(props) => (props.isMobile ? "100%" : "288px")};

  padding-top: ${(props) => (props.isMobile ? "32px" : "0px")};
  margin-left: ${(props) => (props.isMobile ? "32px" : "32px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};

  display: flex;
  flex-direction: column;
`;
const CropPanel = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-width: ${(props) => props.w}px;
  max-height: ${(props) => (props.isMobile ? "auto" : "calc(100vh - 225px)")};
  display: flex;
`;
const slidein = (x1, x2) => keyframes`
     
      0% {
        transform: translateX(${x1}px);
      }
      100% {
        transform:  translateX(${x2}px);
      }
   
`;

const PanelContainer = styled.div`
  animation: ${(props) => slidein(props.x1, props.x2)} 0.2s both;

  // margin-left: -288px;
  display: flex;
  width: 864px;
`;
const PanelOne = styled.div`
  min-width: ${(props) => props.w}px;
  max-width: ${(props) => props.w}px;
  height: 100%;
  // background-color: #ccc;
`;
const PanelTwo = styled.div`
  height: calc(100vh - 225px);
  min-width: 100%;
  height: 100%;
  // background-color: #ddd;
  overflow-y: auto;
`;
const PanelThree = styled.div`
  min-width: 100%;
  height: 100%;
  // background-color: #eee;
`;
const EditItem = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 22px;
`;

const SectionHeader = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 13px;
`;
const SectionGroupHeader = styled.div`
  display: flex;
  color: #757575;
  font-size: 14px;
  margin-bottom: 13px;
`;
const GroupedButtons = styled.div`
  display: flex;
  flex
  margin-bottom: 27px;
`;
const GroupedDrawButtons = styled.div`
  display: flex;
  margin-bottom: 27px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.expanded ? "0px" : "4px")};

  border-bottom-right-radius: ${(props) => (props.expanded ? "0px" : "4px")};
  border-bottom: ${(props) => (props.expanded ? "none" : "auto")};
  width: calc(100% - 3px);
`;
const GroupedDrawButtonsBottom = styled.div`
  display: flex;
  margin-bottom: 27px;
  border: 1px solid #e0e0e0;
  background-color: #ebebeb;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: none;
  width: calc(100% - 3px);
  margin-top: -28px;
`;
const ButtonSpacer = styled.div`
  width: 16px;
`;
const SectionFooter = styled.div`
  margin-top: 4px;
`;
const SectionHelpText = styled.div`
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
`;
const SectionHelpTextTools = styled.div`
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: -8px;
  margin-bottom: 7px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
`;
const SectionIcon = styled.div`
  margin-left: 0px;
  font-size: 24px;
`;
const BackIcon = styled.div`
  margin-left: -9px;
  font-size: 32px;
`;
const SectionLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-top: 5px;
  margin-left: 10px;
  color: #111;
`;
const SectionLabel2 = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;

  color: #111;
`;
const SectionContent = styled.div`
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  max-width: ${(props) => props.w}px;
`;
const IconLabelGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const IconLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
`;
const SectionButtonContainer = styled.div`
  display: flex;
`;
const Alignlineitem = styled.div`
  height: 42px;
  display: flex;
`;
const AlignIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 3px;
`;
const AlignIconSingle = styled.div`
  height: 20px;
  width: 20px;
  margin-top: 3px;
  margin-right: 10px;
`;
const AlignLabel = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 4px;
  padding-left: 10px;
  width: 100px;
`;
const AlignSlider = styled.div`
  width: 200px;
`;
const AlignInput = styled.input`
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #757575;
  width: 40px;
  height: 20px;
  margin-top: 2px;
  margin-left: auto;
  text-align: right;
`;
const AlignInputSmall = styled.input`
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #757575;
  width: 40px;
  height: 20px;
  margin-top: 2px;
  margin-left: auto;
  text-align: right;
`;

const ThumbPreview = styled.div`
  width: 170px;
  // max-width: ${(props) => props.w}px;
  margin-bottom: 20px;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? "15px" : "15px")};
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;
const ImageDateInstruction = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
`;
const ImageDateAction = styled.span`
  color: #2196f3;
  cursor: pointer;
`;
const ControlLineDouble = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? "15px" : "15px")};
  margin-bottom: ${(props) => (props.isMobile ? "15px" : "15px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const TimeSpacer = styled.div`
  width: 20px;
  height: 20px;
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "100%")};
  display: flex;
`;
const ControlContentGrid = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "100%")};
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
`;
const FixedOptions = styled.div`
  padding-left: 32px;
`;
const FixedShortCuts = styled.div`
  margin-bottom: 10px;
`;
const CoverImageLarge = styled.div`
  // width: ${(props) => props.w}px;
  // height: ${(props) => props.h}px;
  width: 100%;
  height: 100%;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  // margin-top: 10px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: -3px;
`;
const SettingsHeader = styled.div`
  display: flex;
  min-height: 32px;
  padding-top: ${(props) => (props.isMobile ? "32px" : "0px")};
  padding-left: ${(props) => (props.isMobile ? "32px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "32px" : "0px")};
  padding-bottom: ${(props) => (props.isMobile ? "6px" : "0px")};
`;
const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  height: 100%;
`;
const SmallElement = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "100")}px;
  height: ${(props) => (props.isMobile ? "40" : "100")}px;
  background-color: #111;
  border: 1px solid #fff;

  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  //   display: flex;
  //   flex-direction: column;
  position: static;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${(props) => "url(" + props.src + ")"};
`;
const SettingsColumns = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const Colordot = styled.div`
  width: 15px;
  height: 20px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
`;
const WhiteColordot = styled.div`
  width: 14px;
  height: 19px;
  border-radius: 10px;
  background-color: ${(props) => props.color};
  border: 1px solid #e0e0e0;
`;
const SettingsTitle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  color: #000000;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
`;
const CompareToggle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-11px")};

  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: 20px;
`;
const ConverImageContainter = styled.div`
  width: 100%;
`;

const StyledG = styled.g`
  transform-box: fill-box;
  transform-origin: center center;
  transform: ${(props) => props.transformset};
`;
const CoverImage = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #fff;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const DimensionContainer = styled.div`
  // box-sizing: border-box;

  width: ${(props) => props.w}px;
  height: ${(props) => props.w}px;
  margin: auto;
`;
const OtherContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.isMobile ? "auto" : "calc(100vh - 160px)")};

  background-color: ${(props) => (props.isMobile ? "#000" : "#f2f2f2")};
  display: flex;
`;
const DimensionCenter = styled.div`
  margin: auto;
  background-color: #d8d8d8;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;

const CloseButtonNarrow = styled.div`
  font-size: 20px;
  color: #757575;
  margin-top: ${(props) => (props.isMobile ? "-15px" : "-13px")};
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "-10px" : "-13px")};
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const RotateProgress = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
`;
const CoverVideoSmall = styled.video`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  object-fit: cover;
  // margin-top: 10px;
`;
const CoverImageSmall = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 8px;
`;

const UploadButtonsCondensed = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 10px;
  height: 30px;
  flex-direction: column;
}
`;
const TopButtons = styled.div`
  display: flex;
 
  flex-direction: row;
}
`;
// const Ortext = styled.div`
//   text-align: center;
//   margin-top: 20px;
//   width: 100%;
// `;
// const OrtextC = styled.div`
//   text-align: center;
//   margin-top: 5px;
//   margin-left: 5px;
//   margin-right: 5px;
//   width: 100%;
// `;
const LinkBadgeContainer = styled.div`
  display: flex;
`;
const LinkBadge = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  max-width: 50%;
  margin-left: auto;
  // position: absolute;
  margin-right: 4px;
  margin-top: 10px;
  right: 0;
  bottom: 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  // background-color: #ccc;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;

const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const NestedElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3011;

  position: relative;
  top: ${(props) => (props.isMobile ? "50%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  // width: 100%;
`;
const ConfirmationDescription = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  display: flex;
  margin: 32px;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;
const StyledButtonLeft2 = styled.div`
    margin-left: ${(props) => (props.isMobile ? "auto" : "20px")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "auto")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight2 = styled.div`

    margin-top: 20px;

    margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
    margin-right: ${(props) => (props.isMobile ? "auto" : "0px")};
    padding-right:  ${(props) => (props.isMobile ? "0px" : "30px")};
    
}
`;
const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-right: 20px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const CropDotOriginal = styled.div`
  width: 30px;
  height: 30px;
  //   border-top: 3px solid #111;
  //   border-left: 3px solid #111;
  border-radius: 50%;

  //   background-color: #aa4124;
  z-index: 2000;
  position: absolute;
  margin-top: -15px;
  margin-left: -15px;
  display: flex;
`;
const CropDot = styled.div`
  width: 30px;
  height: 30px;
  //   border-top: 3px solid #111;
  //   border-left: 3px solid #111;
  border-radius: 50%;
  border: 2px solid #fff;

  //   background-color: #aa4124;
  z-index: 2000;
  position: absolute;
  margin-top: -17px;
  margin-left: -17px;
  display: flex;
`;
const CropDotInside = styled.div`
  width: 28px;
  height: 28px;
  //   border-top: 3px solid #111;
  //   border-left: 3px solid #111;
  border-radius: 50%;
  border: 1px solid #383838;

  //   background-color: #aa4124;
  // z-index: 2000;

  margin: auto;
  display: flex;
`;
const CropMove = styled.div`
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  //   border-top: 3px solid #111;
  //   border-left: 3px solid #111;
  border-radius: 50%;
  border: 1px solid #111;
  //   background-color: #aa4124;
  z-index: 2000;
  position: absolute;
  margin-top: -15px;
  margin-left: -15px;
`;
const CropSquare = styled.div`
  //   border-top: 3px solid #111;
  //   border-left: 3px solid #111;
  // border-radius: 50%;
  // border: 3px solid #111;
  //   background-color: #aa4124;
  z-index: 2000;
  position: absolute;
  // width: 100%;
  // height: 100%;
  // margin-top: -50%;
  // margin-left: -50%
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  margin-top: ${(props) => -props.h / 2}px;
  margin-left: ${(props) => -props.w / 2}px;
`;
const SliderContainer = styled.div`
  min-height: ${(props) => props.maxH}px;
  padding: 10px;
  position: relative;
  width: 100%;
  background-color: #383838;
  display: flex;
  // align-items: normal;
`;
const SliderHandleContainer = styled.div`
  height: 100%;
  width: 30px;
  z-index: 806;
  position: absolute;
  left: 50%;
  // background-color: #ffffff;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
`;

const SliderCircle = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  place-content: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  pointer-events: auto;
  backdrop-filter: blur(7px);
  //     position: absolute;
  // top: 50%;
  margin-left: -14px;
  box-shadow: 0 0 7px rgba(0;0;0;.35);
  // transform: portrait ? rotate(90deg) : undefined;
`;
const SliderArrow2 = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;
  transform: rotate(180deg);
  color: #ffffff;
`;
const SliderArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;

  color: #ffffff;
`;
const SliderHandle = styled.div`
  // flexGrow: 1;
  height: 50%;
  width: 2px;
  background-color: #ffffff;
  pointer-events: auto;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

  margin-left: 14px;

  // position: absolute;
  // left: 50%;
`;
const OPENCV_URL = "/opencv/opencv.js";
const max_duration = 15;
let video = false;
class ElementPopupEdit extends Component {
  constructor(props) {
    super(props);
    this.svgRef = React.createRef();
    this.svgRefB = React.createRef();
    this.canvasCropLines = React.createRef();
    this.bkgImage = React.createRef();
    this.nodeRefA = React.createRef(null);
    this.nodeRefB = React.createRef(null);
    this.nodeRefC = React.createRef(null);
    this.nodeRefD = React.createRef(null);
    this.drawRefA = React.createRef(null);
    this.drawRefB = React.createRef(null);
    this.drawRef1 = React.createRef(null);
    this.drawRef2 = React.createRef(null);
    this.drawRef3 = React.createRef(null);
    this.drawRef4 = React.createRef(null);
    this.drawRef5 = React.createRef(null);
    this.savePage = this.savePage.bind(this);

    this.state = {
      id: "",
      description: "",
      date: "",
      time: "",
      image_date: "",
      image_time: "",
      image_show_full: false,
      seconds: "",
      milliseconds: "",
      image: "",
      grayBaseImage: "",
      image_for_thumb: "",
      imageA: false,
      imageB: false,
      imageAid: false,
      imageBid: false,
      compareSxS: true,
      comparison_type: "SS",
      default_comparison_type: "SS",
      imageNumberSelect: 0,
      image_largest: "",
      link_url: "",
      link_name: "",
      element_type: false,
      link: "",
      form_new: true,
      imageRaw: "",
      imagePreview: false,
      imageRawB: "",
      imagePreviewB: false,
      showImageProgress: false,
      x: null,
      y: null,
      z: null,
      unit: null,
      rotateProgress: false,
      compareOverlay: false,
      showDeleteConfirmation: false,
      showLinkError: false,
      video_duration: 0,
      showVideoError: false,
      isVideo: false,
      imageAloaded: false,
      imageBloaded: false,
      showImageEdit: false,
      showimageeditpopup: false,
      showimageselectpopup: false,
      image_ratio: 1,
      image_ratioA: 1,
      image_ratioB: 1,
      imageAHeight: 1,
      imageAWidth: 1,
      imageBHeight: 1,
      imageBWidth: 1,
      maxW: 0,
      showDescription: false,
      showLink: false,
      showDate: false,
      showLabel: false,
      showThumb: false,
      colorstate: "color",
      showEditUI: false,
      showDimensions: false,
      showSource: false,
      element_label: "NO",
      text_color: "",
      text_alignment: "C",
      slide: false,
      x1: 0,
      x2: 0,
      panelone: 0,
      paneltwo: 0,
      clip: 0,
      panelthree: 0,
      uniqueImageIDS: [],
      image_order: [],
      panelW: 288,
      hasChanges: false,
      edgevalues: [50, 125],
      edge_ratio: 2.5,
      edge_pos: 50,
      blurvalue: 7,
      contrast: 1,
      brightness: 0,
      gray_levels: 10,
      blurprogress: false,
      showSaveConfirmation: false,
      showcrop: false,
      croptype: "fixed",
      boundingX: 500,
      boundingY: 500,
      clearlink: false,
      drawboundingX: 500,
      drawboundingY: 500,
      points: [
        [0, 0], // Top-left [x, y]
        [300, 200], // Top-right [x, y]
        [300, 400 - 200], // bottom-right [x, y]
        [0, 200], // bottom-left [x, y]
      ],
      croppoints: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
      ],
      cropstep: 0,
      controlledPosition1: {
        x: 10,
        y: 10,
      },
      controlledPosition2: {
        x: 490,
        y: 10,
      },
      controlledPosition3: {
        x: 490,
        y: 490,
      },
      controlledPosition4: {
        x: 10,
        y: 490,
      },
      drawPositionA: {
        x: -200,
        y: -200,
      },
      drawPosition1: {
        x: -200,
        y: -200,
      },
      drawPosition2: {
        x: -200,
        y: -200,
      },
      drawPosition3: {
        x: -200,
        y: -200,
      },
      drawPosition4: {
        x: -200,
        y: -200,
      },
      drawPosition5: {
        x: -200,
        y: -200,
      },
      pastState: "",
      scale_b: 0,
      translate_x_b: 0,
      translate_y_b: 0,
      rotate_b: 0,
      opacity_b: 100,
      tool: "drag",
      maintool: "drag",
      allowDraw: false,
      objDragSelect: null,
      objResizeSelect: null,
      objResizeCorner: null,
      objSelect: null,
      canvasSelect: "A",
      draw_color: "#008EFF",
      line_thickness: 2,
      line_opacity: 100,
      currentObject: null,
      dragStart: null,
      objects: [],
      overlayH: 0,
      overlayW: 0,
      fixedCropx: null,
      fixedCropy: null,
      hasLink: null,
      cropType: "FR",
      cropPerpsType: "AU",
      updateCrop: false,
      fixedPerspWidth: null,
      fixedPerspHeight: null,
      grid_expand: false,
      grid_square: true,
      grid_columns: 15,
      grid_rows: 10,
      grid_show_options: false,
      golden_show_options: false,
      line_show_options: true,
      phi_show_options: false,
      angle_show_options: false,
      golden_options: {
        expand: false,
        golden_lock: true,
        segments: 10,
        orientation: "landscape",
        rotate: 0,
        flip: 0,
      },
      angle_options: {
        expand: false,
        golden_lock: true,
        baroque: true,
        orientation: "landscape",
        rotate: 0,
        flip: 0,
        sinister: false,
        reciprocal: true,
        horizontal: false,
        vertical: false,
      },
      line_options: {
        show_dash: false,
        segments: 1,
      },
      tempratio: 0,
      tempw: 0,
      temph: 0,
      anchorEl: null,
    };

    this.handleCloseRootPopup = this.handleCloseRootPopup.bind(this);
    this.handleCloseSavePopup = this.handleCloseSavePopup.bind(this);
    this.handleCloseSaveNewPopup = this.handleCloseSaveNewPopup.bind(this);
  }
  handleCloseRootPopup() {
    this.setState({ showimageeditpopup: false });
    this.setState({ showimageselectpopup: false });
  }
  handleShowOptionsMenu = (e) => {
    console.log(e.target.id);
    this.setState({ anchorEl: e.target });
  };
  handleCloseOptionsMenu = (e) => {
    this.setState({ anchorEl: null });
  };
  handleCloseSavePopup(newimage2) {
    //console.log(newimage2);
    const file = new File([newimage2], "fileName.png", {
      type: "image/png",
      lastModified: new Date(),
    });
    this.setState({ imagePreview: URL.createObjectURL(newimage2) });
    this.setState({ imageRaw: file });
    // this.setState({ showimageeditpopup: false });
    // this.savePage();
  }
  handleCloseSaveNewPopup(newimage2) {
    //console.log(newimage2);
    const file = new File([newimage2], "fileName.png", {
      type: "image/png",
      lastModified: new Date(),
    });
    this.setState({ form_new: true });
    this.setState({ imagePreview: URL.createObjectURL(newimage2) });
    this.setState({ imageRaw: file });
    this.setState({ showimageeditpopup: false });
    this.savePage();
  }
  onChangeRadio = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ element_label: e.target.value });
  };
  onChangeRadioComparison = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ comparison_type: e.target.value });
    this.setState({ default_comparison_type: e.target.value });
    if (e.target.value === "SS") {
      this.setState({ compareSxS: true });
      this.setState({ clip: 0 });
    } else {
      this.setState({ compareSxS: false });
      this.setState({ clip: 50 });
    }
  };
  onChangeToggleComparison = () => {
    if (this.state.comparison_type === "OV") {
      this.setState({ compareSxS: true });
      this.setState({ clip: 0 });
      this.setState({ comparison_type: "SS" });
    } else {
      this.setState({ compareSxS: false });
      this.setState({ clip: 50 });
      this.setState({ comparison_type: "OV" });
    }
  };
  onChangeRadioThumb = (e) => {
    console.log(this.state.image_show_full, e.target.value);
    this.setState({ hasChanges: true });
    if (e.target.value === "true") {
      this.setState({ image_show_full: true });
    } else {
      this.setState({ image_show_full: false });
    }
  };
  onChangeRadioCrop = (e) => {
    //console.log(this.state.image_show_full);
    this.setState({ hasChanges: true });
    this.setState({ cropType: e.target.value });
    if (e.target.value === "FR") {
      this.setState({ fixedCropx: null });
      this.setState({ fixedCropy: null });
      this.setState({ updateCrop: true });
    }
  };
  onChangeRadioPerspCrop = (e) => {
    //console.log(this.state.image_show_full);
    this.setState({ hasChanges: true });
    this.setState({ cropPerpsType: e.target.value });
  };
  onChangeCropPerspRatio = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: e.target.value });
  };
  handleImageEdit = (e) => {
    this.setState({ showimageeditpopup: true });
  };
  SelectImage = (number) => {
    this.setState({ showimageselectpopup: true });
    this.setState({ imageNumberSelect: number });
  };
  HandleImageLoaded = (number) => {
    //console.log("loaded")
    if (number === 1) {
      this.setState({ imageAloaded: true });
    } else {
      this.setState({ imageBloaded: true });
    }
  };
  onChange = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    console.log([e.target.id]);
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeGrid = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    console.log("num", Number(e.target.value));
    // if (  Number(e.target.value) >=1){
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];

      if (obj.type === "grid") {
        let newrows, newcolumns;
        if (e.target.id === "grid_columns") {
          newcolumns = Number(e.target.value);

          newrows = this.state.grid_rows;
        } else {
          newcolumns = this.state.grid_columns;

          newrows = Number(e.target.value);
        }
        const options = { ...obj.options, columns: newcolumns, rows: newrows };
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }

    this.setState({ [e.target.id]: e.target.value });
    // }
  };
  onChangeGridCheck = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      if (obj.type === "grid") {
        let newexpand, newsquare;
        if (e.target.id === "grid_expand") {
          newexpand = !this.state.grid_expand;
          newsquare = this.state.grid_square;
        } else {
          newexpand = this.state.grid_expand;
          newsquare = !this.state.grid_square;
        }
        if (newexpand) {
          this.setState({ drawPosition1: { x: -10000, y: -10000 } });
          this.setState({ drawPosition2: { x: -10000, y: -10000 } });
          this.setState({ drawPosition3: { x: -10000, y: -10000 } });
          this.setState({ drawPosition4: { x: -10000, y: -10000 } });
          this.setState({ drawPosition5: { x: -10000, y: -10000 } });
        }
        const options = {
          ...obj.options,
          expand: newexpand,
          square: newsquare,
        };
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }

    if (this.state[e.target.id] === true) {
      this.setState({ [e.target.id]: false });
    } else {
      this.setState({ [e.target.id]: true });
    }
  };
  onChangeLineMarks = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      if (obj.type === "line") {
        let newdash;
        if (e.target.id === "show_dash") {
          newdash = !this.state.line_options.show_dash;
        }
        const options = {
          ...obj.options,
          show_dash: newdash,
        };
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }

    const new_line_options = { ...this.state.line_options };

    if (new_line_options[e.target.id] === true) {
      new_line_options[e.target.id] = false;
    } else {
      new_line_options[e.target.id] = true;
    }
    this.setState({ line_options: new_line_options });
  };
  onChangeLineSegs = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      if (obj.type === "line") {
        let newsegs;
        if (e.target.id === "segments") {
          newsegs = Number(e.target.value);
        }
        const options = {
          ...obj.options,
          segments: newsegs,
        };
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }

    const new_line_options = { ...this.state.line_options };
    new_line_options[e.target.id] = Number(e.target.value);

    this.setState({ line_options: new_line_options });
  };
  onChangeGoldenCheck = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      if (obj.type === "golden" || obj.type === "phi") {
        let newexpand, newlock;
        if (e.target.id === "expand") {
          newexpand = !this.state.golden_options.expand;
          newlock = false;
        } else {
          newexpand = this.state.golden_options.expand;
          newlock = !this.state.golden_options.golden_lock;
        }
        if (newexpand) {
          this.setState({ drawPosition1: { x: -10000, y: -10000 } });
          this.setState({ drawPosition2: { x: -10000, y: -10000 } });
          this.setState({ drawPosition3: { x: -10000, y: -10000 } });
          this.setState({ drawPosition4: { x: -10000, y: -10000 } });
          this.setState({ drawPosition5: { x: -10000, y: -10000 } });
        }
        const options = {
          ...obj.options,
          expand: newexpand,
          golden_lock: newlock,
        };
        let yEnd;
        if (newlock) {
          // set the height to the correct width
          yEnd = obj.yStart + (obj.xEnd - obj.xStart) * 0.618;
        } else {
          yEnd = obj.yEnd;
        }
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
              yEnd: yEnd,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }
    const new_golden_options = { ...this.state.golden_options };

    if (new_golden_options[e.target.id] === true) {
      new_golden_options[e.target.id] = false;
    } else {
      new_golden_options[e.target.id] = true;
    }
    if (e.target.id === "expand") {
      new_golden_options["golden_lock"] = false;
    }
    this.setState({ golden_options: new_golden_options });
  };
  onChangeAngleCheck = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      if (obj.type === "angle") {
        let newexpand = this.state.angle_options.expand;
        let newlock = this.state.angle_options.golden_lock;
        let newbar = this.state.angle_options.baroque;
        let newsin = this.state.angle_options.sinister;
        let newrec = this.state.angle_options.reciprocal;
        let newver = this.state.angle_options.vertical;
        let newhor = this.state.angle_options.horizontal;

        if (e.target.id === "expand") {
          newexpand = !this.state.angle_options.expand;
          newlock = false;
        }
        if (newexpand) {
          this.setState({ drawPosition1: { x: -10000, y: -10000 } });
          this.setState({ drawPosition2: { x: -10000, y: -10000 } });
          this.setState({ drawPosition3: { x: -10000, y: -10000 } });
          this.setState({ drawPosition4: { x: -10000, y: -10000 } });
          this.setState({ drawPosition5: { x: -10000, y: -10000 } });
        }
        if (e.target.id === "golden_lock") {
          newlock = !newlock;
        }
        if (e.target.id === "baroque") {
          newbar = !newbar;
        }
        if (e.target.id === "sinister") {
          newsin = !newsin;
        }
        if (e.target.id === "reciprocal") {
          newrec = !newrec;
        }
        if (e.target.id === "vertical") {
          newver = !newver;
        }
        if (e.target.id === "horizontal") {
          newhor = !newhor;
        }
        const options = {
          ...obj.options,
          expand: newexpand,
          golden_lock: newlock,
          baroque: newbar,
          sinister: newsin,
          reciprocal: newrec,
          vertical: newver,
          horizontal: newhor,
        };
        let yEnd;
        if (newlock) {
          // set the height to the correct width
          yEnd = obj.yStart + (obj.xEnd - obj.xStart) * 0.618;
        } else {
          yEnd = obj.yEnd;
        }
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              options: options,
              yEnd: yEnd,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
    }
    const new_angle_options = { ...this.state.angle_options };

    if (new_angle_options[e.target.id] === true) {
      new_angle_options[e.target.id] = false;
    } else {
      new_angle_options[e.target.id] = true;
    }
    if (e.target.id === "expand") {
      new_angle_options["golden_lock"] = false;
    }
    this.setState({ angle_options: new_angle_options });
  };
  rotateGolden = (type) => {
    if (type === "rotate") {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      const new_golden_options = { ...obj.options };
      let new_locked;
      if (new_golden_options["rotate"] !== 270) {
        new_golden_options["rotate"] = new_golden_options["rotate"] + 90;
      } else {
        new_golden_options["rotate"] = 0;
      }
      const w = obj.xEnd - obj.xStart;
      const h = obj.yEnd - obj.yStart;
      const newxStart = obj.xStart + (w - h) / 2;
      const newyStart = obj.yStart - (w - h) / 2;
      const newxEnd = newxStart + h;
      const newyEnd = newyStart + w;
      if (obj.locked === 0.618) {
        new_locked = 1.618;
      } else {
        new_locked = 0.618;
      }
      console.log(new_golden_options);
      let width, height;
      if (obj.canvas === "A") {
        width = this.svgRef.current.getBoundingClientRect().width;
        height = this.svgRef.current.getBoundingClientRect().height;
      } else {
        width = this.svgRefB.current.getBoundingClientRect().width;
        height = this.svgRefB.current.getBoundingClientRect().height;
      }
      const ratio = height / width;
      if (obj.options.expand) {
        this.setState({ drawPosition1: { x: -10000, y: -10000 } });
        this.setState({ drawPosition2: { x: -10000, y: -10000 } });
        this.setState({ drawPosition3: { x: -10000, y: -10000 } });
        this.setState({ drawPosition4: { x: -10000, y: -10000 } });
        this.setState({ drawPosition5: { x: -10000, y: -10000 } });
      } else {
        this.setState({
          drawPosition1: {
            x: (newxStart * width) / 100,
            y: (newyStart * height) / 100 / ratio,
          },
        });
        this.setState({
          drawPosition2: {
            x: (newxEnd * width) / 100,
            y: (newyStart * height) / 100 / ratio,
          },
        });
        this.setState({
          drawPosition3: {
            x: (newxEnd * width) / 100,
            y: (newyEnd * height) / 100 / ratio,
          },
        });
        this.setState({
          drawPosition4: {
            x: (newxStart * width) / 100,
            y: (newyEnd * height) / 100 / ratio,
          },
        });
        this.setState({
          drawPosition5: {
            x: ((newxStart + (newxEnd - newxStart) / 2) * width) / 100,
            y: ((newyStart + (newyEnd - newyStart) / 2) * height) / 100 / ratio,
          },
        });
      }
      this.setState({
        objects: [
          ...this.state.objects.slice(0, index),
          {
            ...obj,
            options: new_golden_options,
            locked: new_locked,
            xEnd: newxEnd,
            yEnd: newyEnd,
            xStart: newxStart,
            yStart: newyStart,
          },
          ...this.state.objects.slice(index + 1),
        ],
      });
    }
    if (type === "flip") {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      const new_golden_options = { ...obj.options };

      if (new_golden_options["flip"] === 0) {
        new_golden_options["flip"] = 1;
      } else {
        new_golden_options["flip"] = 0;
      }

      this.setState({
        objects: [
          ...this.state.objects.slice(0, index),
          {
            ...obj,
            options: new_golden_options,
          },
          ...this.state.objects.slice(index + 1),
        ],
      });
    }
  };
  onChangeCropRatio = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ updateCrop: true });
  };
  onFlipCropRatio = () => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    this.setState({ fixedCropx: this.state.fixedCropy });
    this.setState({ fixedCropy: this.state.fixedCropx });
    this.setState({ updateCrop: true });
  };
  setCropRatio = (w, h) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    this.setState({ fixedCropx: w });
    this.setState({ fixedCropy: h });
    this.setState({ updateCrop: true });
  };
  onChangeSlider = (e) => {
    this.setState({ hasChanges: true });
    this.setState({ [e.target.name]: e.target.value });
    // let transform
    // transform = "scale(" + (1 + e.target.value/100) +")"
    // this.setState({ transform_b: transform });
  };

  onChangeSection = (state, bool) => {
    this.setState({ [state]: bool });
    this.setState({ x1: 0 });
    this.setState({ x2: -this.state.panelW });
  };
  turnOnDraw = () => {
    this.setState({ allowDraw: true });
    this.HandleSVGLoad();
  };
  onChangeSectionNumber = (num) => {
    this.setState({ paneltwo: num });
    this.setState({ x1: 0 });
    this.setState({ x2: -this.state.panelW });
    switch (num) {
      case 5:
        this.setState({ pastState: this.state.description });
        break;
      case 1:
        this.setState({
          pastState: [this.state.link_url, this.state.link_name],
        });
        break;
      case 3:
        this.setState({ pastState: [this.state.date, this.state.time] });
        break;
      case 4:
        this.setState({
          pastState: [
            this.state.x,
            this.state.y,
            this.state.z,
            this.state.unit,
          ],
        });
        break;
      case 2:
        this.setState({ pastState: this.state.element_label });
        break;
      case 7:
        this.setState({ pastState: this.state.image_show_full });
        break;
      case 10:
        this.setState({
          pastState: [
            this.state.image,
            this.state.imageRaw,
            this.state.imagePreview,
          ],
        });
        break;
      case 11:
        this.setState({
          pastState: [
            this.state.image,
            this.state.imageRaw,
            this.state.imagePreview,
          ],
        });
        break;
      case 13:
        this.setState({
          pastState: [
            this.state.image,
            this.state.imageRaw,
            this.state.imagePreview,
            this.state.imageB,
            this.state.imageRawB,
            this.state.imagePreviewB,
          ],
        });
        break;
      case 14:
        this.setState({
          pastState: [this.state.default_comparison_type, this.state.clip],
        });
        break;
      case 15:
        this.setState({
          pastState: [
            this.state.scale_b,
            this.state.translate_x_b,
            this.state.translate_y_b,
            this.state.rotate_b,
            this.state.opacity_b,
          ],
        });
        break;
      case 16:
        this.setState({
          pastState: this.state.objects,
        });
        break;
      default:
      // code block
    }
  };
  ToggleText = (e, value) => {
    this.setState({ text_alignment: value });
  };
  ToggleColor = (e, value) => {
    this.setState({ text_color: value });
  };
  ToggleColorLine = (e, value) => {
    console.log(value);
    if (value) {
      if (this.state.objSelect) {
        const index = this.state.objects.findIndex(
          (o) => o.id === this.state.objSelect
        );
        const obj = this.state.objects[index];
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              bg: value,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
        this.setState({ draw_color: value });
      } else {
        this.setState({ draw_color: value });
      }
    }
  };
  changeSliderLine = (e, value) => {
    console.log(value);
    if (value) {
      if (this.state.objSelect) {
        const index = this.state.objects.findIndex(
          (o) => o.id === this.state.objSelect
        );
        const obj = this.state.objects[index];
        let new_sz = obj.sz;
        let new_op = obj.op;
        if (e.target.name === "line_thickness") {
          new_sz = e.target.value;
        }
        if (e.target.name === "line_opacity") {
          new_op = e.target.value;
        }
        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            {
              ...obj,
              sz: new_sz,
              op: new_op,
            },
            ...this.state.objects.slice(index + 1),
          ],
        });
        this.setState({ [e.target.name]: e.target.value });
      } else {
        this.setState({ [e.target.name]: e.target.value });
      }
    }
  };
  onChangeLine = (e) => {
    //console.log(e);
    //console.log([e.target.id], e.target.value);
    this.setState({ hasChanges: true });
    if (this.state.objSelect) {
      const index = this.state.objects.findIndex(
        (o) => o.id === this.state.objSelect
      );
      const obj = this.state.objects[index];
      let new_sz = obj.sz;
      let new_op = obj.op;
      if (e.target.id === "line_thickness") {
        new_sz = e.target.value;
      }
      if (e.target.id === "line_opacity") {
        new_op = e.target.value;
      }
      this.setState({
        objects: [
          ...this.state.objects.slice(0, index),
          {
            ...obj,
            sz: new_sz,
            op: new_op,
          },
          ...this.state.objects.slice(index + 1),
        ],
      });
      this.setState({ [e.target.id]: e.target.value });
    } else {
      this.setState({ [e.target.id]: e.target.value });
    }
  };
  onSlide = (state, bool) => {
    this.setState({ slide: true });
  };
  clearLink = () => {
    this.setState({ clearlink: true });
    this.setState({ link_url: "" });
    this.setState({ link_name: "" });
  };
  onSlideback = (state, bool) => {
    // validate
    if (
      this.isValidHttpUrl(this.state.link_url) ||
      this.state.link_url === ""
    ) {
      this.setState({ paneltwo: 0 });
      this.setState({ x1: -this.state.panelW });
      this.setState({ x2: 0 });
    } else {
      this.setState({ showLinkError: true });
    }
    this.setState({ allowDraw: false });
    this.setState({ objSelect: null });
  };
  onSlidebackCancel = (num) => {
    // validate
    //console.log(num);
    this.setState({ paneltwo: 0 });
    this.setState({ x1: -this.state.panelW });
    this.setState({ x2: 0 });
    switch (num) {
      case 5:
        this.setState({ description: this.state.pastState });
        break;
      case 1:
        this.setState({ link_url: this.state.pastState[0] });
        this.setState({ link_name: this.state.pastState[1] });
        break;
      case 3:
        this.setState({ date: this.state.pastState[0] });
        this.setState({ time: this.state.pastState[1] });
        break;
      case 4:
        this.setState({ x: this.state.pastState[0] });
        this.setState({ y: this.state.pastState[1] });
        this.setState({ z: this.state.pastState[2] });
        this.setState({ unit: this.state.pastState[3] });
        break;
      case 2:
        this.setState({ element_label: this.state.pastState });

        break;
      case 7:
        this.setState({ image_show_full: this.state.pastState });
        break;
      case 10:
        this.setState({ image: this.state.pastState[0] });
        this.setState({ imagePreview: this.state.pastState[2] });
        this.setState({ imageRaw: this.state.pastState[1] });
        break;
      case 11:
        this.setState({ image: this.state.pastState[0] });
        this.setState({ imagePreview: this.state.pastState[2] });
        this.setState({ imageRaw: this.state.pastState[1] });
        break;
      case 16:
        this.setState({ allowDraw: false });
        this.setState({ objSelect: null });
        this.setState({ objects: this.state.pastState });
        break;
      case 13:
        this.setState({ image: this.state.pastState[0] });
        this.setState({ imageRaw: this.state.pastState[1] });
        this.setState({ imagePreview: this.state.pastState[2] });
        this.setState({ imageB: this.state.pastState[3] });
        this.setState({ imageRawB: this.state.pastState[4] });
        this.setState({ imagePreviewB: this.state.pastState[5] });
        break;
      case 14:
        this.setState({ default_comparison_type: this.state.pastState[0] });
        this.setState({ comparison_type: this.state.pastState[0] });
        this.setState({ clip: this.state.pastState[1] });
        break;
      case 15:
        this.setState({ scale_b: this.state.pastState[0] });
        this.setState({ translate_x_b: this.state.pastState[1] });
        this.setState({ translate_y_b: this.state.pastState[2] });
        this.setState({ rotate_b: this.state.pastState[3] });
        this.setState({ opacity_b: this.state.pastState[4] });

        break;

      default:
      // code block
    }
  };
  passRotate = () => {
    // //console.log(this.cvCanvas.current)
    this.cvCanvas.current.handleRotate();
  };
  onChangeImageSize = (e) => {
    if (this.state.image_show_full) {
      this.setState({ image_show_full: false });
    } else {
      this.setState({ image_show_full: true });
    }
  };
  handleClosePopup(target) {
    if (this.state.hasChanges) {
      this.setState({ showSaveConfirmation: true });
    } else {
      this.props.history.push(target);
    }
  }
  handleCloseSaveConfirmation(target) {
    this.props.history.push(target);
  }
  handleCloseConfirmation() {
    this.setState({ showDeleteConfirmation: false });
  }
  handleCloseImageEdit() {
    this.setState({ showImageEdit: false });
  }
  handleCloseSelect() {
    this.setState({ showimageselectpopup: false });
  }
  handleChangeToggle = (event, newValue) => {
    ////console.log(event.target.value);
    this.setState({ element_type: event.target.value });
  };
  handleChangeDescription = (newValue) => {
    this.setState({ description: newValue });
  };
  handleChangeDate = (newValue) => {
    ////console.log(this.state.date);
    ////console.log(newValue);
    this.setState({ hasChanges: true });
    this.setState({ date: newValue });
  };
  handleChangeTime = (newValue) => {
    ////console.log(newValue);
    this.setState({ hasChanges: true });
    this.setState({ time: newValue });
  };
  handleShowConfirmation = () => {
    this.handleCloseOptionsMenu();
    this.setState({ showDeleteConfirmation: true });
  };
  handleDuplicateElement = () => {
    this.handleCloseOptionsMenu();

    this.savePage("new");
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  deleteElement = () => {
    const { projectid, projectname } = this.props;
    this.props.dispatch(deleteElement(this.state.id));
    const target = "/project/" + projectid + "/" + projectname + "/";
    ////console.log(target);
    this.props.history.push(target);
  };
  handleReorder = () => {
    const { projectid } = this.props;

    const target = "/order/" + projectid + "/";

    this.props.history.push(target);
  };
  isValidHttpUrl = (string) => {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };
  savePage = (type) => {
    //console.log("saving2");

    const { projectid, projectname, element_types } = this.props;
    let date;
    let dimension;
    let metadata = null;
    let project_id = projectid;
    if (this.state.form_new) {
      project_id = this.props.id;
    }
    ////console.log(element_types.byID[this.state.element_type].name);
    if (this.state.element_type === "DM") {
      ////console.log("dimension");
      metadata = {
        dimension_x: this.state.x,
        dimension_y: this.state.y,
        dimension_z: this.state.z,
        dimension_unit: this.state.unit,
      };
      dimension = {
        // id: "none",
        x: this.state.x,
        y: this.state.y,
        z: this.state.z,
        unit: this.state.unit,
      };
    }

    if (this.state.link_url) {
      if (!metadata) {
        metadata = {};
      }
      metadata["link_url"] = this.state.link_url;
    }

    if (this.state.link_name) {
      if (!metadata) {
        metadata = {};
      }

      metadata["link_name"] = this.state.link_name;
    }
    if (this.state.clearlink) {
      if (!metadata) {
        metadata = {};
      }
      metadata["link_url"] = "";
      metadata["link_name"] = "";
    }
    if (this.state.element_type === "CO") {
      //console.log("compare");
      if (this.state.image_order) {
        if (!metadata) {
          metadata = {};
        }

        metadata["image_order"] = this.state.image_order;
      }
      if (this.state.default_comparison_type) {
        if (!metadata) {
          metadata = {};
        }

        metadata["compare_type"] = this.state.default_comparison_type;
      }
      if (this.state.scale_b) {
        if (!metadata) {
          metadata = {};
        }

        metadata["resize"] = this.state.scale_b;
      } else {
        metadata["resize"] = null;
      }
      if (this.state.translate_x_b) {
        if (!metadata) {
          metadata = {};
        }

        metadata["horizontal"] = this.state.translate_x_b;
      }
      if (this.state.translate_y_b) {
        if (!metadata) {
          metadata = {};
        }

        metadata["vertical"] = this.state.translate_y_b;
      }
      if (this.state.rotate_b) {
        if (!metadata) {
          metadata = {};
        }

        metadata["rotate"] = this.state.rotate_b;
      }
      if (this.state.opacity_b !== 100) {
        if (!metadata) {
          metadata = {};
        }

        metadata["opacity"] = this.state.opacity_b;
      }
      if (this.state.objects) {
        if (!metadata) {
          metadata = {};
        }

        metadata["drawing"] = this.state.objects;
      }
    }
    if (this.state.objects) {
      if (!metadata) {
        metadata = {};
      }

      metadata["drawing"] = this.state.objects;
    }
    if (this.state.element_label !== "NO") {
      if (!metadata) {
        metadata = {};
      }

      metadata["label"] = this.state.element_label;
    } else {
      if (!metadata) {
        metadata = {};
      }

      metadata["label"] = null;
    }
    if (this.state.text_color !== "") {
      if (!metadata) {
        metadata = {};
      }

      metadata["text_color"] = this.state.text_color;
    } else {
      if (!metadata) {
        metadata = {};
      }

      metadata["text_color"] = null;
    }
    if (this.state.date === "") {
      date = new Date();
    } else {
      date = new Date(
        this.state.date +
          "T" +
          this.state.time +
          ":" +
          this.state.seconds +
          "." +
          this.state.milliseconds
      ).toISOString();
    }

    const data = {
      description: this.state.description,
      element_date: date,
      element_type: this.state.element_type,
      id: this.state.id,
      project: project_id,

      show_full_image: this.state.image_show_full,
    };
    if (metadata) {
      data.metadata = metadata;
    }
    //console.log(data);
    if (this.state.video_duration <= max_duration) {
      if (
        this.isValidHttpUrl(this.state.link_url) ||
        this.state.link_url === ""
      ) {
        //console.log("yes");
        if (this.state.imageBid || this.state.imageAid) {
          // two selected images

          data.images = [this.state.imageAid, this.state.imageBid];
          //console.log("images",data.images)
        }
        if (this.state.form_new || type === "new") {
          ////console.log(this.state.imageRaw);
          //console.log("new");

          if (this.state.imageRaw || this.state.imageRawB) {
            // image A is uploaded
            //console.log(this.state.image_order)
            let files = [];

            if (this.state.imageRaw) {
              files.push(this.state.imageRaw);
            }
            if (this.state.imageRawB) {
              files.push(this.state.imageRawB);
            }
            this.props.dispatch(
              uploadFiles(data, files, "new", this.state.image_order)
            );
          } else {
            // description, element_date, element_type, projectid, imageid, imageurl, alt_text
            if (type === "new") {
              data.images = this.state.images;
            }
            this.props.dispatch(newElement(data));
          }
        } else {
          //console.log("saving")
          if (this.state.imageRaw || this.state.imageRawB) {
            // image A is uploaded
            //console.log(this.state.image_order)
            let files = [];

            if (this.state.imageRaw) {
              files.push(this.state.imageRaw);
            }
            if (this.state.imageRawB) {
              files.push(this.state.imageRawB);
            }
            this.props.dispatch(
              uploadFiles(data, files, "save", this.state.image_order)
            );
          } else {
            this.props.dispatch(saveElement(data));
          }
        }

        const target = "/project/" + project_id + "/" + projectname + "/";
        ////console.log(target);
        this.props.history.push(target);
      } else {
        this.setState({ showLinkError: true });
      }
    } else {
      this.setState({ showVideoError: true });
    }
  };

  setImageDimensions() {
    const { dimensions } = this.props;
    let maxW, maxH;

    if (dimensions.window_dimensions.isMobile) {
      maxW = dimensions.window_dimensions.w - 0;

      maxH = dimensions.window_dimensions.h * 100;
    } else {
      if (this.state.expandImage) {
        maxW = dimensions.window_dimensions.w - 150;
        maxH = dimensions.window_dimensions.h * 150;
      } else {
        maxW = dimensions.window_dimensions.w - 448;
        maxH = dimensions.window_dimensions.h - 150;
      }
    }
    if (this.state.compareSxS) {
      maxW = maxW / 2;
    }
    let image_w_a;
    let image_h_a;
    const image_r_a = this.state.image_ratio;
    let image_w_b;
    let image_h_b;
    const image_r_b = this.state.image_ratioB;

    // //console.log("image_r_a",image_r_a)
    // //console.log("image_r_b",image_r_b)
    if (image_r_a > 1) {
      // landscape
      if (maxW / image_r_a > maxH) {
        image_w_a = "auto";
        image_h_a = "100%";
      } else {
        image_w_a = "100%";
        image_h_a = "auto";
      }
    } else {
      // portrait

      if (maxH * image_r_a > maxW) {
        image_w_a = "100%";
        image_h_a = "auto";
      } else {
        image_w_a = "auto";
        image_h_a = "100%";
      }
    }
    if (image_r_b < image_r_a) {
      image_w_b = "auto";
      image_h_b = "100%";
    } else {
      image_w_b = "100%";
      image_h_b = "auto";
    }
    // if (image_r_b > 1) {
    //   // landscape
    //   if (maxW / image_r_b > this.bkgImage.current.getBoundingClientRect().height) {
    //     image_w_b = "auto";
    //     image_h_b = "100%";
    //   } else {
    //     image_w_b = "100%";
    //     image_h_b = "auto";
    //   }
    // } else {
    //   // portrait
    //   if (maxH * image_r_b > this.bkgImage.current.getBoundingClientRect().width) {
    //     image_w_b = "100%";
    //     image_h_b = "auto";
    //   } else {
    //     image_w_b = "auto";
    //     image_h_b = "100%";
    //   }
    // }
    if (this.state.imageAHeight !== image_h_a) {
      //console.log("set");
      this.setState({ imageAHeight: image_h_a });
    }
    if (this.state.imageAWidth !== image_w_a) {
      this.setState({ imageAWidth: image_w_a });
    }
    if (this.state.imageBHeight !== image_h_b) {
      this.setState({ imageBHeight: image_h_b });
    }
    if (this.state.imageBWidth !== image_w_b) {
      this.setState({ imageBWidth: image_w_b });
    }
    if (this.state.maxW !== maxW) {
      this.setState({ maxW: maxW });
    }
    if (this.bkgImage.current) {
      if (
        this.state.overlayW !==
        this.bkgImage.current.getBoundingClientRect().width
      ) {
        this.setState({
          overlayW: this.bkgImage.current.getBoundingClientRect().width,
        });
      }
      if (
        this.state.overlayH !==
        this.bkgImage.current.getBoundingClientRect().height
      ) {
        this.setState({
          overlayH: this.bkgImage.current.getBoundingClientRect().height,
        });
      }
    }
  }
  clearLines() {
    const ctx = this.canvasCropLines.current.getContext("2d");
    const canvas = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  }
  hideCrop = (type) => {
    console.log("no cropping");
    this.setState({ showcrop: false });
    const ctx = this.canvasCropLines.current.getContext("2d");
    const canvas = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };
  showCrop = (type) => {
    console.log("cropping");
    //console.log(this.bkgImage.current.width);
    const canvasW = this.bkgImage.current.width;
    const canvasH = this.bkgImage.current.height;
    //  this.canvasCropLines.current.width = canvasW
    //  this.canvasCropLines.current.height = canvasH
    this.setState({ boundingX: this.bkgImage.current.width });
    this.setState({ boundingY: this.bkgImage.current.height });

    this.setState({ showcrop: true });
    this.setState({ croptype: type });

    if (this.state.fixedCropx && this.state.fixedCropy) {
      const fixedRatio = this.state.fixedCropx / this.state.fixedCropy;
      console.log(this.state.image_ratio, fixedRatio);
      if (this.state.image_ratio > fixedRatio) {
        const cropHeight = canvasH - 60;
        const cropWidth = cropHeight * fixedRatio;

        this.setState({
          controlledPosition1: { x: (canvasW - cropWidth) / 2, y: 30 },
          controlledPosition2: {
            x: canvasW - (canvasW - cropWidth) / 2,
            y: 30,
          },
          controlledPosition3: {
            x: canvasW - (canvasW - cropWidth) / 2,
            y: canvasH - 30,
          },
          controlledPosition4: {
            x: (canvasW - cropWidth) / 2,
            y: canvasH - 30,
          },
        });
      } else {
        console.log("y");
        const cropWidth = canvasW - 60;
        const cropHeight = cropWidth / fixedRatio;

        this.setState({
          controlledPosition1: { x: 30, y: (canvasH - cropHeight) / 2 },
          controlledPosition2: {
            x: canvasW - 30,
            y: (canvasH - cropHeight) / 2,
          },
          controlledPosition3: {
            x: canvasW - 30,
            y: canvasH - (canvasH - cropHeight) / 2,
          },
          controlledPosition4: {
            x: 30,
            y: canvasH - (canvasH - cropHeight) / 2,
          },
        });
      }
    } else {
      this.setState({
        controlledPosition1: { x: 30, y: 30 },
        controlledPosition2: { x: canvasW - 30, y: 30 },
        controlledPosition3: { x: canvasW - 30, y: canvasH - 30 },
        controlledPosition4: { x: 30, y: canvasH - 30 },
      });
    }

    this.drawLines();
    if (type === "perspcrop") {
      this.loadOpenCv();
    }
  };
  // doOilPaintEffect = () => {
  //   this.oilPaintEffect(
  //     imgCanvas,
  //     settings.radius,
  //     settings.intensity

  //     radius: 2,
  //     intensity: 15,
  //   );
  // }

  // oilPaintEffect = (canvas, radius, intensity) => {
  //   // https://stackoverflow.com/questions/24222556/apply-a-oil-paint-sketch-effect-to-a-photo-using-javascript
  //   var width = canvas.width,
  //     height = canvas.height,
  //     imgData = ctx.getImageData(0, 0, width, height),
  //     pixData = imgData.data,
  //     // change to createElement getting added element just for the demo
  //     destCanvas = document.createElement("canvas"),
  //     dCtx = destCanvas.getContext("2d"),
  //     pixelIntensityCount = [];

  //   destCanvas.width = width;
  //   destCanvas.height = height;

  //   var destImageData = dCtx.createImageData(width, height),
  //     destPixData = destImageData.data,
  //     intensityLUT = [],
  //     rgbLUT = [];

  //   for (var y = 0; y < height; y++) {
  //     intensityLUT[y] = [];
  //     rgbLUT[y] = [];
  //     for (var x = 0; x < width; x++) {
  //       var idx = (y * width + x) * 4,
  //         r = pixData[idx],
  //         g = pixData[idx + 1],
  //         b = pixData[idx + 2],
  //         avg = (r + g + b) / 3;

  //       intensityLUT[y][x] = Math.round(
  //         (avg * intensity) / 255
  //       );
  //       rgbLUT[y][x] = {
  //         r: r,
  //         g: g,
  //         b: b,
  //       };
  //     }
  //   }

  //   for (y = 0; y < height; y++) {
  //     for (x = 0; x < width; x++) {
  //       pixelIntensityCount = [];

  //       // Find intensities of nearest pixels within radius.
  //       for (var yy = -radius; yy <= radius; yy++) {
  //         for (var xx = -radius; xx <= radius; xx++) {
  //           if (
  //             y + yy > 0 &&
  //             y + yy < height &&
  //             x + xx > 0 &&
  //             x + xx < width
  //           ) {
  //             var intensityVal = intensityLUT[y + yy][x + xx];

  //             if (!pixelIntensityCount[intensityVal]) {
  //               pixelIntensityCount[intensityVal] = {
  //                 val: 1,
  //                 r: rgbLUT[y + yy][x + xx].r,
  //                 g: rgbLUT[y + yy][x + xx].g,
  //                 b: rgbLUT[y + yy][x + xx].b,
  //               };
  //             } else {
  //               pixelIntensityCount[intensityVal].val++;
  //               pixelIntensityCount[intensityVal].r +=
  //                 rgbLUT[y + yy][x + xx].r;
  //               pixelIntensityCount[intensityVal].g +=
  //                 rgbLUT[y + yy][x + xx].g;
  //               pixelIntensityCount[intensityVal].b +=
  //                 rgbLUT[y + yy][x + xx].b;
  //             }
  //           }
  //         }
  //       }

  //       pixelIntensityCount.sort(function (a, b) {
  //         return b.val - a.val;
  //       });

  //       var curMax = pixelIntensityCount[0].val,
  //         dIdx = (y * width + x) * 4;

  //       destPixData[dIdx] = ~~(
  //         pixelIntensityCount[0].r / curMax
  //       );
  //       destPixData[dIdx + 1] = ~~(
  //         pixelIntensityCount[0].g / curMax
  //       );
  //       destPixData[dIdx + 2] = ~~(
  //         pixelIntensityCount[0].b / curMax
  //       );
  //       destPixData[dIdx + 3] = pixData[dIdx + 3];
  //     }
  //   }

  //   // change this to ctx to instead put the data on the original canvas
  //   ctx.putImageData(destImageData, 0, 0);
  //   img.src = imgCanvas.toDataURL();
  // }
  // grayscale = () => {
  //   fitOn();

  //   var ctx = imgCanvas.getContext("2d");
  //   var width = imgCanvas.width;
  //   var height = imgCanvas.height;
  //   var data = ctx.getImageData(0, 0, width, height),
  //     px = data.data;
  //   for (y = 0; y < height; y++) {
  //     for (x = 0; x < width; x++) {
  //       var dIdx = (y * width + x) * 4;
  //       var value =
  //         0.2126 * px[dIdx] +
  //         0.7152 * px[dIdx + 1] +
  //         0.0722 * px[dIdx + 2];
  //       px[dIdx] = value;
  //       px[dIdx + 1] = value;
  //       px[dIdx + 2] = value;
  //       //px[dIdx + 3] = 255;
  //     }
  //   }

  //   ctx.putImageData(data, 0, 0);
  //   img.src = imgCanvas.toDataURL();
  // }
  loadOpenCv = () => {
    const promise = injectScript("opencv-injected-js", OPENCV_URL);
    promise
      .then(() => {
        //console.log(`success to load ${OPENCV_URL}`);
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        //console.log(`Failed to load ${OPENCV_URL}`);
      });
  };
  resetCropLines = () => {
    const newrect = this.canvasA.current.getBoundingClientRect();
    this.setState({ controlledPosition1: { x: 30, y: 30 } });
    this.setState({ controlledPosition2: { x: newrect.width - 30, y: 30 } });
    this.setState({
      controlledPosition3: { x: newrect.width - 30, y: newrect.height - 30 },
    });
    this.setState({ controlledPosition4: { x: 30, y: newrect.height - 30 } });
    this.clearLines();
    this.setState({ showcrop: false });
    this.setState({ boundingX: newrect.width });
    this.setState({ boundingY: newrect.height });
  };
  handleDragSlider = (e, ui) => {
    let clip = (ui.x + this.state.maxW / 2) / (this.state.maxW / 100);
    //console.log(this.state.maxW, this.state.clip)
    this.setState({ clip: clip });
  };
  handleDrag = (event, dragElement) => {
    const ratiox = 1;
    const ratioy = 1;

    if (event.target.id === "1") {
      const ox = this.state.controlledPosition1.x;
      const oy = this.state.controlledPosition1.y;
      const dEx = dragElement.x;
      let dEy;
      let dy;

      if (this.state.fixedCropx && this.state.fixedCropy) {
        const fixedRatio = this.state.fixedCropx / this.state.fixedCropy;

        dy = (dEx - ox) / fixedRatio;
      } else {
        dEy = dragElement.y;
        dy = dEy - oy;
      }

      const dx = dEx - ox;

      this.setState({
        controlledPosition1: { x: dEx, y: oy + dy },
      });
      if (this.state.croptype === "fixed") {
        const o2x = this.state.controlledPosition2.x;
        const o2y = this.state.controlledPosition2.y + dy;
        const o4x = this.state.controlledPosition4.x + dx;
        const o4y = this.state.controlledPosition4.y;

        this.setState({ controlledPosition2: { x: o2x, y: o2y } });
        this.setState({ controlledPosition4: { x: o4x, y: o4y } });
      }
    }
    if (event.target.id === "2") {
      const ox = this.state.controlledPosition2.x;
      const oy = this.state.controlledPosition2.y;
      const dEx = dragElement.x;
      let dEy;
      let dy;

      if (this.state.fixedCropx && this.state.fixedCropy) {
        const fixedRatio = this.state.fixedCropx / this.state.fixedCropy;

        dy = -(dEx - ox) / fixedRatio;
      } else {
        dEy = dragElement.y;
        dy = dEy - oy;
      }

      const dx = dEx - ox;

      this.setState({
        controlledPosition2: { x: dEx, y: oy + dy },
      });
      if (this.state.croptype === "fixed") {
        const o1x = this.state.controlledPosition1.x;
        const o1y = this.state.controlledPosition1.y + dy;
        const o3x = this.state.controlledPosition3.x + dx;
        const o3y = this.state.controlledPosition3.y;

        this.setState({ controlledPosition1: { x: o1x, y: o1y } });
        this.setState({ controlledPosition3: { x: o3x, y: o3y } });
      }
    }
    if (event.target.id === "3") {
      const ox = this.state.controlledPosition3.x;
      const oy = this.state.controlledPosition3.y;
      const dEx = dragElement.x;
      let dEy;
      let dy;

      if (this.state.fixedCropx && this.state.fixedCropy) {
        const fixedRatio = this.state.fixedCropx / this.state.fixedCropy;

        dy = (dEx - ox) / fixedRatio;
      } else {
        dEy = dragElement.y;
        dy = dEy - oy;
      }

      const dx = dEx - ox;

      this.setState({
        controlledPosition3: { x: dEx, y: oy + dy },
      });
      if (this.state.croptype === "fixed") {
        console.log("fixed");
        const o4x = this.state.controlledPosition4.x;
        const o4y = this.state.controlledPosition4.y + dy;
        const o2x = this.state.controlledPosition2.x + dx;
        const o2y = this.state.controlledPosition2.y;

        this.setState({ controlledPosition2: { x: o2x, y: o2y } });
        this.setState({ controlledPosition4: { x: o4x, y: o4y } });
      }
    }
    if (event.target.id === "4") {
      const ox = this.state.controlledPosition4.x;
      const oy = this.state.controlledPosition4.y;
      const dEx = dragElement.x;
      let dEy;
      let dy;

      if (this.state.fixedCropx && this.state.fixedCropy) {
        const fixedRatio = this.state.fixedCropx / this.state.fixedCropy;

        dy = -(dEx - ox) / fixedRatio;
      } else {
        dEy = dragElement.y;
        dy = dEy - oy;
      }

      const dx = dEx - ox;

      this.setState({
        controlledPosition4: { x: dEx, y: oy + dy },
      });
      if (this.state.croptype === "fixed") {
        const o3x = this.state.controlledPosition3.x;
        const o3y = this.state.controlledPosition3.y + dy;
        const o1x = this.state.controlledPosition1.x + dx;
        const o1y = this.state.controlledPosition1.y;

        this.setState({ controlledPosition1: { x: o1x, y: o1y } });
        this.setState({ controlledPosition3: { x: o3x, y: o3y } });
      }
    }
    this.drawLines();
  };
  drawLines() {
    //console.log("this.drawLines");
    const ctx = this.canvasCropLines.current.getContext("2d");
    const canvas = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    //console.log("h:", canvas.height);
    const pointSize = 20;
    const point1 = this.state.controlledPosition1;
    const point2 = this.state.controlledPosition2;
    const point3 = this.state.controlledPosition3;
    const point4 = this.state.controlledPosition4;
    //console.log("point4:", point4);
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#111111";
    ctx.beginPath();
    ctx.moveTo(point1.x + 0.5, point1.y + 0.5);
    ctx.lineTo(point2.x - 0.5, point2.y + 0.5);
    ctx.lineTo(point3.x - 0.5, point3.y - 0.5);
    ctx.lineTo(point4.x + 0.5, point4.y - 0.5);
    ctx.lineTo(point1.x + 0.5, point1.y + 0.5);
    ctx.closePath();
    ctx.stroke();
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#ffffff";
    ctx.beginPath();
    ctx.moveTo(point1.x - 0.5, point1.y - 0.5);
    ctx.lineTo(point2.x + 0.5, point2.y - 0.5);
    ctx.lineTo(point3.x + 0.5, point3.y + 0.5);
    ctx.lineTo(point4.x - 0.5, point4.y + 0.5);
    ctx.lineTo(point1.x - 0.5, point1.y - 0.5);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.fillStyle = "rgba(1, 1, 1, 0.25)";
    // First we draw a path counter-clockwise
    ctx.moveTo(0, 0);
    ctx.lineTo(0, this.state.boundingY);
    ctx.lineTo(this.state.boundingX, this.state.boundingY);
    ctx.lineTo(this.state.boundingX, 0);
    // the shape to subtract clockwise
    ctx.moveTo(point1.x - 1.5, point1.y - 1.5);
    ctx.lineTo(point2.x + 1.5, point2.y - 1.5);
    ctx.lineTo(point3.x + 1.5, point3.y + 1.5);
    ctx.lineTo(point4.x - 1.5, point4.y + 1.5);
    ctx.lineTo(point1.x - 1.5, point1.y - 1.5);

    ctx.fill();
    var handlelength = 30;
    var offset = 2.5;
    this.drawHandle(
      ctx,
      { x: point4.x - offset, y: point4.y },
      { x: point1.x - offset, y: point1.y - offset },
      { x: point2.x, y: point2.y - offset },
      handlelength
    );
    this.drawHandle(
      ctx,
      { x: point1.x, y: point1.y - offset },
      { x: point2.x + offset, y: point2.y - offset },
      { x: point3.x + offset, y: point3.y },
      handlelength
    );
    this.drawHandle(
      ctx,
      { x: point2.x + offset, y: point2.y },
      { x: point3.x + offset, y: point3.y + offset },
      { x: point4.x, y: point4.y + offset },
      handlelength
    );
    this.drawHandle(
      ctx,
      { x: point3.x, y: point3.y + offset },
      { x: point4.x - offset, y: point4.y + offset },
      { x: point1.x - offset, y: point1.y },
      handlelength
    );
  }
  buildUnitVector(p1, p2, uVect) {
    uVect.x = p2.x - p1.x;
    uVect.y = p2.y - p1.y;
    var vectorNorm = Math.sqrt(uVect.x * uVect.x + uVect.y * uVect.y);
    uVect.x /= vectorNorm;
    uVect.y /= vectorNorm;
  }

  drawHandle(ctx, p1, p2, p3, a) {
    var unitVector1 = { x: 0, y: 0 },
      unitVector2 = { x: 0, y: 0 },
      s = { x: 0, y: 0 },
      m = { x: 0, y: 0 },
      e = { x: 0, y: 0 };
    this.buildUnitVector(p1, p2, unitVector1);
    this.buildUnitVector(p2, p3, unitVector2);
    s.x = p2.x - unitVector1.x * a;
    s.y = p2.y - unitVector1.y * a;
    e.x = p2.x + unitVector2.x * a;
    e.y = p2.y + unitVector2.y * a;
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.moveTo(s.x, s.y);
    ctx.lineTo(p2.x, p2.y);
    ctx.lineTo(e.x, e.y);
    ctx.stroke();
  }
  handleStop = (event, dragElement) => {
    //logic here to look if bR is to the left/top of tL and then flip it all
    // if (event.target.id === "1") {
    //   const ox = this.state.controlledPosition1.x;
    //   const oy = this.state.controlledPosition1.y;
    //   const dx = dragElement.x - ox;
    //   const dy = dragElement.y - oy;
    //   this.setState({
    //     controlledPosition1: { x: dragElement.x, y: dragElement.y },
    //   });
    //   if (this.state.croptype === "fixed") {
    //     const o2x = this.state.controlledPosition2.x;
    //     const o2y = this.state.controlledPosition2.y + dy;
    //     const o4x = this.state.controlledPosition4.x + dx;
    //     const o4y = this.state.controlledPosition4.y;
    //     this.setState({ controlledPosition2: { x: o2x, y: o2y } });
    //     this.setState({ controlledPosition4: { x: o4x, y: o4y } });
    //   }
    // }
    // if (event.target.id === "2") {
    //   const ox = this.state.controlledPosition2.x;
    //   const oy = this.state.controlledPosition2.y;
    //   const dx = dragElement.x - ox;
    //   const dy = dragElement.y - oy;
    //   this.setState({
    //     controlledPosition2: { x: dragElement.x, y: dragElement.y },
    //   });
    //   if (this.state.croptype === "fixed") {
    //     const o1x = this.state.controlledPosition1.x;
    //     const o1y = this.state.controlledPosition1.y + dy;
    //     const o3x = this.state.controlledPosition3.x + dx;
    //     const o3y = this.state.controlledPosition3.y;
    //     this.setState({ controlledPosition1: { x: o1x, y: o1y } });
    //     this.setState({ controlledPosition3: { x: o3x, y: o3y } });
    //   }
    // }
    // if (event.target.id === "3") {
    //   const ox = this.state.controlledPosition3.x;
    //   const oy = this.state.controlledPosition3.y;
    //   const dx = dragElement.x - ox;
    //   const dy = dragElement.y - oy;
    //   this.setState({
    //     controlledPosition3: { x: dragElement.x, y: dragElement.y },
    //   });
    //   if (this.state.croptype === "fixed") {
    //     const o4x = this.state.controlledPosition4.x;
    //     const o4y = this.state.controlledPosition4.y + dy;
    //     const o2x = this.state.controlledPosition2.x + dx;
    //     const o2y = this.state.controlledPosition2.y;
    //     this.setState({ controlledPosition2: { x: o2x, y: o2y } });
    //     this.setState({ controlledPosition4: { x: o4x, y: o4y } });
    //   }
    // }
    // if (event.target.id === "4") {
    //   const ox = this.state.controlledPosition4.x;
    //   const oy = this.state.controlledPosition4.y;
    //   const dx = dragElement.x - ox;
    //   const dy = dragElement.y - oy;
    //   this.setState({
    //     controlledPosition4: { x: dragElement.x, y: dragElement.y },
    //   });
    //   if (this.state.croptype === "fixed") {
    //     const o3x = this.state.controlledPosition3.x;
    //     const o3y = this.state.controlledPosition3.y + dy;
    //     const o1x = this.state.controlledPosition1.x + dx;
    //     const o1y = this.state.controlledPosition1.y;
    //     this.setState({ controlledPosition1: { x: o1x, y: o1y } });
    //     this.setState({ controlledPosition3: { x: o3x, y: o3y } });
    //   }
    // }
  };

  handlePerspectiveCrop = (e) => {
    const rect = this.canvasA.current.getBoundingClientRect();

    const cv = window.cv;
    let src = cv.imread(this.state.imageA);
    let dst = new cv.Mat();

    // let dsize = new cv.Size(src.rows, src.cols);
    const bR = this.state.controlledPosition3;
    const bL = this.state.controlledPosition4;
    const tR = this.state.controlledPosition2;
    const tL = this.state.controlledPosition1;
    const imageResizeRatio = 1;
    const multiply = this.state.imageA.width / rect.width;
    // create source coordinates matrix
    const sourceCoordinates = [tL, tR, bR, bL].map((point) => [
      (multiply * point.x) / imageResizeRatio,
      (multiply * point.y) / imageResizeRatio,
    ]);
    // get max width
    const maxWidth =
      (multiply * Math.max(bR.x - bL.x, tR.x - tL.x)) / imageResizeRatio;
    // get max height
    const maxHeight =
      (multiply * Math.max(bL.y - tL.y, bR.y - tR.y)) / imageResizeRatio;
    //   const maxHeight = maxWidth * 0.77272727

    const perspRatio = this.getRealAspectRatio(rect.width, rect.height);

    let destCoordinates = [];

    let dsize;
    if (perspRatio) {
      dsize = new cv.Size(maxWidth, maxWidth / perspRatio);
      destCoordinates = [
        [0, 0],
        [maxWidth, 0],
        [maxWidth, maxWidth / perspRatio],
        [0, maxWidth / perspRatio],
      ];
    } else {
      dsize = new cv.Size(maxWidth, maxHeight);
      destCoordinates = [
        [0, 0],
        [maxWidth, 0],
        [maxWidth, maxHeight],
        [0, maxHeight],
      ];
    }

    let srcTri = cv.matFromArray(
      4,
      1,
      cv.CV_32FC2,
      [].concat(...sourceCoordinates)
    );
    let dstTri = cv.matFromArray(
      4,
      1,
      cv.CV_32FC2,
      [].concat(...destCoordinates)
    );
    let M = cv.getPerspectiveTransform(srcTri, dstTri);
    // You can try more different parameters
    cv.warpPerspective(
      src,
      dst,
      M,
      dsize,
      cv.INTER_LINEAR,
      cv.BORDER_CONSTANT,
      new cv.Scalar()
    );
    cv.imshow(this.canvasA.current, dst);
    src.delete();
    dst.delete();
    M.delete();
    srcTri.delete();
    dstTri.delete();
    this.setState({ imageA: this.canvasA.current });
    this.resetCropLines();
    this.setState({ showRevert: true });
    //   var canvas = this.canvasA.current
    //   canvas.toBlob(function(blob) {
    //     //console.log("blob")

    //     this.setState({ canvasBlob: blob });
    // });
  };
  componentDidUpdate() {
    const { dimensions, element } = this.props;

    if (this.state.showcrop) {
      const canvasW = this.bkgImage.current.width;
      const canvasH = this.bkgImage.current.height;
      if (this.state.boundingX !== canvasW) {
        //console.log("new lines");
        this.setState({ controlledPosition1: { x: 30, y: 30 } });
        this.setState({
          controlledPosition2: { x: canvasW - 30, y: 30 },
        });
        this.setState({
          controlledPosition3: {
            x: canvasW - 30,
            y: canvasH - 30,
          },
        });
        this.setState({
          controlledPosition4: { x: 30, y: canvasH - 30 },
        });
        this.setState({ boundingX: canvasW });
      }

      if (this.state.boundingY !== canvasH) {
        this.setState({ controlledPosition1: { x: 30, y: 30 } });
        this.setState({
          controlledPosition2: { x: canvasW - 30, y: 30 },
        });
        this.setState({
          controlledPosition3: {
            x: canvasW - 30,
            y: canvasH - 30,
          },
        });
        this.setState({
          controlledPosition4: { x: 30, y: canvasH - 30 },
        });
        this.setState({ boundingY: canvasH });
      }
      if (this.state.updateCrop) {
        this.showCrop("fixed");
        this.setState({ updateCrop: false });
      }
      this.drawLines();
    }
    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
    if (
      this.state.image ||
      this.state.imagePreview ||
      this.state.imageB ||
      this.state.imagePreviewB
    ) {
      this.setImageDimensions();
    }
    if (element && element.images[0]) {
      // if (this.state.currentElementID !== element.id) {
      //   this.setState({ currentElementID: element.id });

      // }
      this.setImageDimensions();
    } else {
      // determine size for non image containers
      let sq;
      if (dimensions.window_dimensions.isMobile) {
        sq = dimensions.window_dimensions.w - 0;
      } else {
        sq = dimensions.window_dimensions.w - 448;
        if (sq > dimensions.window_dimensions.h - 160) {
          sq = dimensions.window_dimensions.h - 160;
        }
      }
      if (this.state.nonImageSquare !== sq) {
        //console.log("sq", sq);
        this.setState({ nonImageSquare: sq });
      }
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyPress);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.keyPress);
    const { element, dialog_type, dimensions, elements } = this.props;
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
    // const loc = window.location.pathname.split("/")[1];
    // const el = window.location.pathname.split("/")[2];
    //
    let uniqueImageIDS = [];
    elements.allIDS.forEach((elementid) => {
      const element = elements.byID[elementid];
      if (element.images) {
        element.images.forEach((img) => {
          if (img.mime_type.split("/")[0] !== "video") {
            if (img.mime_type.split("/")[0] !== "audio") {
              if (uniqueImageIDS.indexOf(img.id) === -1) {
                uniqueImageIDS.push(img.id);
              }
            }
          }
        });
      }
    });
    this.setState({ uniqueImageIDS: uniqueImageIDS });

    if (dimensions.window_dimensions.isMobile) {
      if (this.state.panelW !== dimensions.window_dimensions.w - 64) {
        this.setState({ panelW: dimensions.window_dimensions.w - 64 });
      }
    } else {
      if (this.state.panelW !== 288) {
        this.setState({ panelW: 288 });
      }
    }
    if (dialog_type === "edit") {
      if (element.element_type === "CO") {
        this.setState({ compareSxS: true });
      } else {
        this.setState({ compareSxS: false });
      }
      const local_date = new Date(element.element_date);
      const day = ("0" + local_date.getDate()).slice(-2);
      const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
      let hour = local_date.getHours();
      let min = local_date.getMinutes();
      const local_milli_state = local_date.getMilliseconds();
      let local_seconds_state = local_date.getSeconds();
      local_seconds_state =
        (local_seconds_state < 10 ? "0" : "") + local_seconds_state;
      hour = (hour < 10 ? "0" : "") + hour;
      min = (min < 10 ? "0" : "") + min;
      const local_date_state =
        local_date.getFullYear() + "-" + month + "-" + day;
      const local_time_state = hour + ":" + min;
      ////console.log(local_seconds_state);
      ////console.log(local_milli_state);
      ////console.log("local_date_state" + local_date_state);
      this.setState({ id: element.id });
      this.setState({ form_new: false });
      this.setState({ description: element.description });
      this.setState({ date: local_date_state });
      this.setState({ time: local_time_state });
      this.setState({ milliseconds: local_milli_state });
      this.setState({ seconds: local_seconds_state });
      this.setState({ element_type: element.element_type });
      let tempimages = [];
      if (element.images) {
        element.images.forEach((img) => {
          tempimages.push(img.id);
        });
      }
      this.setState({ images: tempimages });
      if (element.metadata) {
        if (element.metadata.compare_type) {
          if (element.metadata.compare_type === "SS") {
            this.setState({ clip: 0 });
            this.setState({ compareSxS: true });
          } else {
            this.setState({ clip: 50 });
            this.setState({ compareSxS: false });
          }
        }
      }

      this.setState({
        image_order: element.metadata
          ? element.metadata.image_order
            ? element.metadata.image_order
            : [0, 0]
          : [0, 0],
      });
      this.setState({
        comparison_type: element.metadata
          ? element.metadata.compare_type
            ? element.metadata.compare_type
            : "SS"
          : "SS",
      });
      this.setState({
        default_comparison_type: element.metadata
          ? element.metadata.compare_type
            ? element.metadata.compare_type
            : "SS"
          : "SS",
      });
      this.setState({
        scale_b: element.metadata
          ? element.metadata.resize
            ? element.metadata.resize
            : 0
          : 0,
      });
      this.setState({
        translate_x_b: element.metadata
          ? element.metadata.horizontal
            ? element.metadata.horizontal
            : 0
          : 0,
      });
      this.setState({
        translate_y_b: element.metadata
          ? element.metadata.vertical
            ? element.metadata.vertical
            : 0
          : 0,
      });
      this.setState({
        rotate_b: element.metadata
          ? element.metadata.rotate
            ? element.metadata.rotate
            : 0
          : 0,
      });
      this.setState({
        opacity_b: element.metadata
          ? element.metadata.opacity
            ? element.metadata.opacity
            : 100
          : 100,
      });
      this.setState({
        objects: element.metadata
          ? element.metadata.drawing
            ? element.metadata.drawing
            : []
          : [],
      });

      this.setState({
        element_label: element.metadata
          ? element.metadata.label
            ? element.metadata.label
            : "NO"
          : "NO",
      });
      this.setState({
        text_color: element.metadata
          ? element.metadata.text_color
            ? element.metadata.text_color
            : ""
          : "",
      });
      this.setState({
        link_url: element.metadata
          ? element.metadata.link_url
            ? element.metadata.link_url
            : ""
          : "",
      });
      this.setState({
        hasLink: element.metadata
          ? element.metadata.link_url
            ? true
            : ""
          : "",
      });
      this.setState({
        link_name: element.metadata
          ? element.metadata.link_name
            ? element.metadata.link_name
            : ""
          : "",
      });
      this.setState({
        x: element.metadata
          ? element.metadata.dimension_x
            ? element.metadata.dimension_x
            : 1
          : 1,
      });
      this.setState({
        y: element.metadata
          ? element.metadata.dimension_y
            ? element.metadata.dimension_y
            : 1
          : 1,
      });
      this.setState({
        z: element.metadata
          ? element.metadata.dimension_z
            ? element.metadata.dimension_z
            : 0
          : 0,
      });
      this.setState({
        image_show_full: element.show_full_image
          ? element.show_full_image
          : false,
      });
      this.setState({
        unit: element.metadata
          ? element.metadata.dimension_unit
            ? element.metadata.dimension_unit
            : ""
          : "",
      });
      if (element.images.length > 0) {
        if (element.element_type === "CO" && element.metadata.image_order) {
          // use image order for src
          if (element.metadata.image_order[0] === element.images[0].id) {
            this.setState({
              imageAid: element.images[0].id,
            });
            this.setState({
              imageBid: element.images[1].id,
            });
          } else {
            this.setState({
              imageAid: element.images[1].id,
            });
            this.setState({
              imageBid: element.images[0].id,
            });
          }
          this.setState({
            image:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[0] +
              "/file",
          });

          this.setState({
            image_largest:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[0] +
              "/file",
          });

          const image = new Image();

          image.crossOrigin = "anonymous";
          image.setAttribute("crossOrigin", "");

          image.src =
            api_endpoint +
            "/media/" +
            element.metadata.image_order[0] +
            "/file?size=256";
          // image.src = '/testimages/1x/610.jpg'

          image.onload = () => {
            //console.log("width:" + image.width);
            //console.log("height:" + image.height);
            this.setState({ image_ratio: image.width / image.height });
            this.setImageDimensions();
          };

          this.setState({
            imageB:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[1] +
              "/file",
          });

          this.setState({
            image_largestB:
              api_endpoint +
              "/media/" +
              element.metadata.image_order[1] +
              "/file",
          });

          const imageB = new Image();

          imageB.crossOrigin = "anonymous";
          imageB.setAttribute("crossOrigin", "");

          imageB.src =
            api_endpoint +
            "/media/" +
            element.metadata.image_order[1] +
            "/file?size=256";
          // image.src = '/testimages/1x/610.jpg'

          imageB.onload = () => {
            //console.log("width:" + imageB.width);
            //console.log("height:" + imageB.height);
            this.setState({ image_ratioB: imageB.width / imageB.height });
            this.setImageDimensions();
          };
        } else {
          // use first image only
          if (element.images[0].mime_type.split("/")[0] === "video") {
            this.setState({ isVideo: true });
            this.setState({
              image: api_endpoint + "/media/" + element.images[0].id + "/file",
            });
          } else {
            this.setState({
              image: api_endpoint + "/media/" + element.images[0].id + "/file",
            });
          }
          this.setState({
            image_largest:
              api_endpoint + "/media/" + element.images[0].id + "/file",
          });
          this.setState({
            image_for_thumb:
              api_endpoint +
              "/media/" +
              element.images[0].id +
              "/file?size=512",
          });
          const image = new Image();

          image.crossOrigin = "anonymous";
          image.setAttribute("crossOrigin", "");

          image.src =
            api_endpoint + "/media/" + element.images[0].id + "/file?size=256";
          // api_endpoint + "/media/" + element.images[0].id + "/file";
          // image.src = '/testimages/1x/610.jpg'

          image.onload = () => {
            //console.log("width:" + image.width);
            //console.log("height:" + image.height);
            this.setState({ image_ratio: image.width / image.height });
            this.setImageDimensions();
          };
        }
      } else {
        // determine size for non image containers
        let sq;
        if (dimensions.window_dimensions.isMobile) {
          sq = dimensions.window_dimensions.w - 0;
        } else {
          sq = dimensions.window_dimensions.w - 448;
          if (sq > dimensions.window_dimensions.h - 160) {
            sq = dimensions.window_dimensions.h - 160;
          }
        }
        if (this.state.nonImageSquare !== sq) {
          //console.log("sq", sq);
          this.setState({ nonImageSquare: sq });
        }
      }
    } else {
      // new dialog
      //console.log("eT",this.props.selectedElementType)
      if (this.props.selectedElementType === "CO") {
        //console.log("sxs")
        this.setState({ compareSxS: true });
      } else {
        this.setState({ compareSxS: false });
      }
      const local_date = new Date();
      const day = ("0" + local_date.getDate()).slice(-2);
      const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
      let hour = local_date.getHours();
      let min = local_date.getMinutes();
      const local_milli_state = local_date.getMilliseconds();

      hour = (hour < 10 ? "0" : "") + hour;
      min = (min < 10 ? "0" : "") + min;
      let local_seconds_state = local_date.getSeconds();
      local_seconds_state =
        (local_seconds_state < 10 ? "0" : "") + local_seconds_state;
      const local_date_state =
        local_date.getFullYear() + "-" + month + "-" + day;
      const local_time_state = hour + ":" + min;
      ////console.log(local_seconds_state);
      ////console.log(local_milli_state);
      this.setState({ id: "" });
      this.setState({ form_new: true });
      this.setState({ description: "" });
      this.setState({ date: local_date_state });
      this.setState({ time: local_time_state });
      this.setState({ seconds: local_seconds_state });
      this.setState({ milliseconds: local_milli_state });

      this.setState({
        element_type:
          this.props.selectedElementType || this.props.element_types.allIDS[0],
      });
      if (this.props.selectedElementType === "CO") {
        this.setState({ compareSxS: true });
      } else {
        this.setState({ compareSxS: false });
      }
      this.setState({ x: 1 });
      this.setState({ y: 1 });
      this.setState({ z: 0 });
      this.setState({ unit: "" });
      let sq;
      if (dimensions.window_dimensions.isMobile) {
        sq = dimensions.window_dimensions.w - 0;
      } else {
        sq = dimensions.window_dimensions.w - 448;
        if (sq > dimensions.window_dimensions.h - 160) {
          sq = dimensions.window_dimensions.h - 160;
        }
      }
      if (this.state.nonImageSquare !== sq) {
        //console.log("sq", sq);
        this.setState({ nonImageSquare: sq });
      }
    }
    ////console.log(this.state.x);
  }

  dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mimetype_local = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mimetype_local });
  }

  validateFile = (file) => {
    var video = document.createElement("video");
    video.preload = "metadata";

    // video.onloadedmetadata = function() {

    // }
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      // //console.log(Number(video.duration))
      this.setState({ video_duration: video.duration });
      if (Number(video.duration) > max_duration) {
        //console.log(video.duration);
        this.setState({ showVideoError: true });
      }
    };

    video.src = URL.createObjectURL(file);
  };
  handleImageChange = (e) => {
    if (e.target.files.length) {
      this.setState({ hasChanges: true });
      //console.log(e.target.files[0].name);
      // ////console.log(e.target.files[0].size);

      // ////console.log(e.target.files[0].lastModifiedDate);
      // this.setState({ showVideoError: false });

      // this.setState({ video_duration: String(this.validateFile(e.target.files[0]))});
      this.validateFile(e.target.files[0]);

      if (e.target.files[0].type.split("/")[0] === "video") {
        this.setState({ isVideo: true });
      }
      const local_date = new Date(e.target.files[0].lastModified);
      const day = ("0" + local_date.getDate()).slice(-2);
      const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
      let hour = local_date.getHours();
      let min = local_date.getMinutes();
      hour = (hour < 10 ? "0" : "") + hour;
      min = (min < 10 ? "0" : "") + min;
      const local_date_state =
        local_date.getFullYear() + "-" + month + "-" + day;
      const local_time_state = hour + ":" + min;
      let img = new Image();
      var objectUrl = URL.createObjectURL(e.target.files[0]);
      img.src = objectUrl;
      img.onload = () => {
        const new_image_ratio = img.width / img.height;
        //console.log(new_image_ratio);
        this.setState({ image_ratio: new_image_ratio });
        this.setState({ date: local_date_state });
        this.setState({ time: local_time_state });
        this.setState({ image_date: local_date_state });
        this.setState({ image_time: local_time_state });
        this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });

        this.setState({ imageRaw: e.target.files[0] });
        this.setImageDimensions();
        this.onSlideback();
        // URL.revokeObjectURL(objectUrl);
      };
    }
  };
  handleSetImageDate() {
    this.setState({ date: this.state.image_date });
    this.setState({ time: this.state.image_time });
  }
  handleSetTodayDate() {
    const local_date = new Date();
    const day = ("0" + local_date.getDate()).slice(-2);
    const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
    let hour = local_date.getHours();
    let min = local_date.getMinutes();
    const local_milli_state = local_date.getMilliseconds();

    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;
    let local_seconds_state = local_date.getSeconds();
    local_seconds_state =
      (local_seconds_state < 10 ? "0" : "") + local_seconds_state;
    const local_date_state = local_date.getFullYear() + "-" + month + "-" + day;
    const local_time_state = hour + ":" + min;
    this.setState({ date: local_date_state });
    this.setState({ time: local_time_state });
    this.setState({ seconds: local_seconds_state });
    this.setState({ milliseconds: local_milli_state });
  }

  rotateImage = (type, direction) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.setAttribute("crossOrigin", "");

    let imagesrc = this.state.image;

    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    }
    if (
     
      type === "edge" ||
      type === "blur" ||
      type === "values" ||
      type === "contrast"
    ) {
      if (this.state.colorstate === "color") {
        imagesrc = this.state.pastState[0];
      } else {
        imagesrc = this.state.grayBaseImage;
      }
    }
    if (type === "color") {
      imagesrc = this.state.pastState[0];
    }
    if (type === "grayscale") {
      imagesrc = this.state.pastState[0];
    }
    if (type === "blur") {
      this.setState({ blurprogress: true });
    }
    image.src = imagesrc;
    image.onload = () => {
      // Create a canvas object.
      let canvas = document.createElement("canvas");
      // Create canvas context.
      let ctx = canvas.getContext("2d");

      // Assign width and height.
      if (type === "crop") {
        //console.log("crop");
        const bR = this.state.controlledPosition3;
        const bL = this.state.controlledPosition4;
        const tR = this.state.controlledPosition2;
        const tL = this.state.controlledPosition1;
        const cropRatio = image.width / this.bkgImage.current.width;
        const cropW = (bR.x - tL.x) * cropRatio;
        const cropH = (bR.y - tL.y) * cropRatio;
        canvas.width = cropW;
        canvas.height = cropH;
        ctx.drawImage(
          image,
          tL.x * cropRatio,
          tL.y * cropRatio,
          cropW,
          cropH,
          0,
          0,
          cropW,
          cropH
        );

        this.setState({ image_ratio: cropW / cropH });
        this.onSlideback();
        this.hideCrop();
      }
      if (type === "perspcrop") {
        //console.log("perspcrop");
        const rect = this.bkgImage.current.getBoundingClientRect();
        //console.log(rect);
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();
        const bR = this.state.controlledPosition3;
        const bL = this.state.controlledPosition4;
        const tR = this.state.controlledPosition2;
        const tL = this.state.controlledPosition1;
        const imageResizeRatio = 1;
        const multiply = image.width / rect.width;
        // const multiply = image.width / rect.width;
        // create source coordinates matrix
        const sourceCoordinates = [tL, tR, bR, bL].map((point) => [
          (multiply * point.x) / imageResizeRatio,
          (multiply * point.y) / imageResizeRatio,
        ]);
        // get max width
        const maxWidth =
          (multiply * Math.max(bR.x - bL.x, tR.x - tL.x)) / imageResizeRatio;
        // get max height
        const maxHeight =
          (multiply * Math.max(bL.y - tL.y, bR.y - tR.y)) / imageResizeRatio;
        //   const maxHeight = maxWidth * 0.77272727
        let perspRatio;
        if (this.state.cropPerpsType === "MA") {
          console.log("MA");
          perspRatio = this.state.fixedPerspWidth / this.state.fixedPerspHeight;
          console.log(perspRatio);
        } else {
          perspRatio = this.getRealAspectRatio(rect.width, rect.height);
        }

        //console.log("perspRatio,", perspRatio, multiply, imageResizeRatio);
        let destCoordinates = [];

        let dsize;
        if (perspRatio) {
          dsize = new cv.Size(maxWidth, maxWidth / perspRatio);
          destCoordinates = [
            [0, 0],
            [maxWidth, 0],
            [maxWidth, maxWidth / perspRatio],
            [0, maxWidth / perspRatio],
          ];
        } else {
          dsize = new cv.Size(maxWidth, maxHeight);
          destCoordinates = [
            [0, 0],
            [maxWidth, 0],
            [maxWidth, maxHeight],
            [0, maxHeight],
          ];
        }

        let srcTri = cv.matFromArray(
          4,
          1,
          cv.CV_32FC2,
          [].concat(...sourceCoordinates)
        );
        let dstTri = cv.matFromArray(
          4,
          1,
          cv.CV_32FC2,
          [].concat(...destCoordinates)
        );
        let M = cv.getPerspectiveTransform(srcTri, dstTri);
        // You can try more different parameters
        //console.log(dsize);
        cv.warpPerspective(
          src,
          dst,
          M,
          dsize,
          cv.INTER_LINEAR,
          cv.BORDER_CONSTANT,
          new cv.Scalar()
        );
        cv.imshow(canvas, dst);
        src.delete();
        dst.delete();
        M.delete();
        srcTri.delete();
        dstTri.delete();

        this.setState({ image_ratio: perspRatio });
        this.onSlideback();
        this.hideCrop();
      }
      if (type === "rotate") {
        canvas.width = image.height;
        canvas.height = image.width;
        //console.log("rotate");
        ctx.translate(canvas.width / 2, canvas.height / 2);
        if (direction === "right") {
          ctx.rotate(Math.PI / 2);
        } else {
          ctx.rotate(-Math.PI / 2);
        }

        ctx.drawImage(image, -image.width / 2, -image.height / 2);

        this.setState({ image_ratio: 1 / this.state.image_ratio });
      }
      if (type === "flip") {
        //console.log("flip");
        canvas.width = image.width;
        canvas.height = image.height;
        if (direction === "ver") {
          ctx.translate(image.width, 0);
          ctx.scale(-1, 1);
          ctx.drawImage(image, 0, 0);
        } else {
          ctx.translate(0, image.height);
          ctx.scale(1, -1);
          ctx.drawImage(image, 0, 0);
        }
      }
      if (type === "grayscale") {
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();

        cv.cvtColor(src, dst, cv.COLOR_RGBA2GRAY, 0);

        cv.imshow(canvas, dst);
        this.setState({ grayBaseImage: canvas.toDataURL("image/png") });
        src.delete();
        dst.delete();
      }
      if (type === "color") {
        const cv = window.cv;
        let src = cv.imread(image);

        cv.imshow(canvas, src);

        src.delete();
      }
      if (type === "blur") {
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();
        let blur = 2 * Math.round(this.state.blurvalue / 2) - 1;
        let ksize = new cv.Size(blur, blur);
        // cv.medianBlur(src, dst, this.state.blurvalue);
        cv.GaussianBlur(src, dst, ksize, 0, 0, cv.BORDER_DEFAULT);
        // cv.cvtColor(dst, dst, cv.COLOR_RGBA2GRAY, 0);

        cv.imshow(canvas, dst);
        src.delete();
        dst.delete();
        this.setState({ blurprogress: false });
      }
      if (type === "contrast") {
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();
        // cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);
        // You can try more different parameters
        // cv.threshold(src, dst, this.state.edgevalues[0], this.state.edgevalues[1], cv.THRESH_BINARY);
        let alpha = this.state.contrast;
        let beta = this.state.brightness;

        cv.convertScaleAbs(src, dst, alpha, beta);

        cv.imshow(canvas, dst);
        src.delete();
        dst.delete();
      }
      if (type === "edge") {
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();
        cv.cvtColor(src, src, cv.COLOR_RGB2GRAY, 0);

        let alpha = this.state.contrast;
        let beta = this.state.brightness;

        cv.convertScaleAbs(src, dst, alpha, beta);
        cv.Canny(
          dst,
          dst,
          this.state.edgevalues[0],
          this.state.edgevalues[1],
          3,
          true
        );
        let inv_dst = new cv.Mat();
        cv.bitwise_not(dst, inv_dst);
        cv.imshow(canvas, inv_dst);
        src.delete();
        dst.delete();
      }
      if (type === "values") {
        const cv = window.cv;
        let src = cv.imread(image);
        let dst = new cv.Mat();
        let blur = 2 * Math.round(this.state.blurvalue / 2) - 1;
        let ksize = new cv.Size(blur, blur);

        cv.GaussianBlur(src, dst, ksize, 0, 0, cv.BORDER_DEFAULT);
        // cv.cvtColor(dst, dst, cv.COLOR_RGB2GRAY, 0);

        cv.imshow(canvas, dst);
        src.delete();
        dst.delete();
        // ctx = canvas.getContext("2d");

        var imgData = ctx.getImageData(
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.height
        );

        ctx.putImageData(this.posterize(imgData, this.state.gray_levels), 0, 0);
      }
      canvas.toBlob((blob) => {
        const file = new File([blob], "fileName.png", {
          type: "image/png",
          lastModified: new Date(),
        });
        this.setState({ imagePreview: URL.createObjectURL(blob) });
        this.setState({ imageRaw: file });
        this.setState({ boundingX: 100 });
        this.setState({ boundingY: 100 });
        this.setState({ hasChanges: true });
      });
    };
  };
  posterize = (imgData, levels) => {
    var numLevels = parseInt(levels, 10) || 1;
    var data = imgData.data;
    var uniquevalues = {};
    numLevels = Math.max(2, Math.min(256, numLevels));

    var numAreas = 256 / numLevels;
    var numValues = 255 / (numLevels - 1);

    var rect = imgData;
    var w = rect.width;
    var h = rect.height;
    var w4 = w * 4;
    var y = h;
    do {
      var offsetY = (y - 1) * w4;
      var x = w;
      do {
        var offset = offsetY + (x - 1) * 4;

        var r = numValues * ((data[offset] / numAreas) >> 0);
        var g = numValues * ((data[offset + 1] / numAreas) >> 0);
        var b = numValues * ((data[offset + 2] / numAreas) >> 0);

        if (r > 255) r = 255;
        if (g > 255) g = 255;
        if (b > 255) b = 255;

        data[offset] = r;
        data[offset + 1] = g;
        data[offset + 2] = b;
        const concat = String(r) + String(g) + String(b);
        if (!uniquevalues[concat]) {
          uniquevalues[concat] = { r: r, b: b, g: g };
        }
      } while (--x);
    } while (--y);

    return imgData;
  };
  handleChangeEdgeDouble = (e) => {
    this.setState({ edgevalues: e.target.value });
    // this.rotateImage("edge")
  };
  handleChangeEdge = (e) => {
    let low = 100;
    let high = low * e.target.value;
    this.setState({ edge_ratio: e.target.value });
    this.setState({ edgevalues: [low, high] });
    // this.rotateImage("edge")
  };
  handleChangeEdge2 = (e) => {
    let low = e.target.value;
    let high = low * this.state.edge_ratio;
    this.setState({ edge_pos: e.target.value });
    this.setState({ edgevalues: [low, high] });
    // this.rotateImage("edge")
  };
  handleChangeBlur = (e) => {
    this.setState({ blurvalue: e.target.value });
  };
  handleContrast = (e) => {
    this.setState({ contrast: e.target.value });
  };
  handleBright = (e) => {
    this.setState({ brightness: e.target.value });
  };
  handleColorStateChange = (e) => {
    this.setState({ colorstate: e.target.value });
    if (e.target.value === "color") {
      this.rotateImage("color");
    } else {
      this.rotateImage("grayscale");
    }
    // this.setState({ horstylevalue: e.target.value + "px" });
  };
  handleChangeValues = (e) => {
    this.setState({ gray_levels: e.target.value });

    // this.setState({ horstylevalue: e.target.value + "px" });
  };
  handleSelect(imgid, column) {
    //console.log(column);
    this.setState({ hasChanges: true });
    if (column === 1) {
      const image = new Image();

      image.crossOrigin = "anonymous";
      image.setAttribute("crossOrigin", "");

      image.src = api_endpoint + "/media/" + imgid + "/file?size=256";

      image.onload = () => {
        const imgurl = api_endpoint + "/media/" + imgid + "/file?size=2048";
        this.setState({ image_ratio: image.width / image.height });
        this.setState({ image: imgurl });
        this.setState({ imageAid: imgid });
        this.setState({ image_order: [imgid, this.state.image_order[1]] });
        this.setImageDimensions();
      };
    }
    if (column === 2) {
      const image2 = new Image();

      image2.crossOrigin = "anonymous";
      image2.setAttribute("crossOrigin", "");

      image2.src = api_endpoint + "/media/" + imgid + "/file?size=256";

      image2.onload = () => {
        // calc aspect ratio & sizes sizes here.
        const imgurl = api_endpoint + "/media/" + imgid + "/file?size=2048";
        this.setState({ image_ratioB: image2.width / image2.height });
        this.setState({ imageB: imgurl });
        this.setState({ imageBid: imgid });
        this.setState({ image_order: [this.state.image_order[0], imgid] });
        this.setImageDimensions();
      };
    }

    this.setState({ showimageselectpopup: false });
  }
  handleImageChangeCompare = (e) => {
    if (e.target.files.length) {
      this.setState({ hasChanges: true });
      // ////console.log(e.target.files[0].name);
      // ////console.log(e.target.files[0].size);

      // ////console.log(e.target.files[0].lastModifiedDate);
      // this.setState({ showVideoError: false });

      // this.setState({ video_duration: String(this.validateFile(e.target.files[0]))});
      this.validateFile(e.target.files[0]);

      if (e.target.files[0].type.split("/")[0] === "video") {
        this.setState({ isVideo: true });
      }
      const local_date = new Date(e.target.files[0].lastModified);
      const day = ("0" + local_date.getDate()).slice(-2);
      const month = ("0" + (local_date.getMonth() + 1)).slice(-2);
      let hour = local_date.getHours();
      let min = local_date.getMinutes();
      hour = (hour < 10 ? "0" : "") + hour;
      min = (min < 10 ? "0" : "") + min;
      const local_date_state =
        local_date.getFullYear() + "-" + month + "-" + day;
      const local_time_state = hour + ":" + min;
      let img = new Image();
      var objectUrl = URL.createObjectURL(e.target.files[0]);
      img.src = objectUrl;
      img.onload = () => {
        this.setState({ date: local_date_state });
        this.setState({ time: local_time_state });
        this.setState({ image_date: local_date_state });
        this.setState({ image_time: local_time_state });
        if (this.state.imageNumberSelect === 1) {
          const new_image_ratio = img.width / img.height;
          //console.log(new_image_ratio);
          this.setState({ image_ratio: new_image_ratio });
          this.setState({
            imagePreview: URL.createObjectURL(e.target.files[0]),
          });
          this.setState({ image_order: [0, this.state.image_order[1]] });
          this.setState({ imageRaw: e.target.files[0] });
        } else {
          const new_image_ratio = img.width / img.height;
          //console.log(new_image_ratio);
          this.setState({ image_ratioB: new_image_ratio });
          this.setState({
            imagePreviewB: URL.createObjectURL(e.target.files[0]),
          });
          this.setState({ image_order: [this.state.image_order[0], 0] });
          this.setState({ imageRawB: e.target.files[0] });
        }

        this.setImageDimensions();
        //console.log("hello");
        // URL.revokeObjectURL(objectUrl);
      };
    }
    // this.setImageDimensions();
    this.setState({ showimageselectpopup: false });
  };
  getRealAspectRatio(imageWidth, imageHeight) {
    // //console.log(imageWidth, imageHeight)
    // //console.log(this.state.controlledPosition1, this.state.controlledPosition2,this.state.controlledPosition3,this.state.controlledPosition4)
    const u0 = imageWidth / 2;
    const v0 = imageHeight / 2;
    const m1x = this.state.controlledPosition1.x - u0;
    const m1y = this.state.controlledPosition1.y - v0;
    const m2x = this.state.controlledPosition2.x - u0;
    const m2y = this.state.controlledPosition2.y - v0;
    const m3x = this.state.controlledPosition4.x - u0;
    const m3y = this.state.controlledPosition4.y - v0;
    const m4x = this.state.controlledPosition3.x - u0;
    const m4y = this.state.controlledPosition3.y - v0;

    const k2 =
      ((m1y - m4y) * m3x - (m1x - m4x) * m3y + m1x * m4y - m1y * m4x) /
      ((m2y - m4y) * m3x - (m2x - m4x) * m3y + m2x * m4y - m2y * m4x);

    const k3 =
      ((m1y - m4y) * m2x - (m1x - m4x) * m2y + m1x * m4y - m1y * m4x) /
      ((m3y - m4y) * m2x - (m3x - m4x) * m2y + m3x * m4y - m3y * m4x);

    const f_squared =
      -(
        (k3 * m3y - m1y) * (k2 * m2y - m1y) +
        (k3 * m3x - m1x) * (k2 * m2x - m1x)
      ) /
      ((k3 - 1) * (k2 - 1));

    let whRatio = Math.sqrt(
      (Math.pow(k2 - 1, 2) +
        Math.pow(k2 * m2y - m1y, 2) / f_squared +
        Math.pow(k2 * m2x - m1x, 2) / f_squared) /
        (Math.pow(k3 - 1, 2) +
          Math.pow(k3 * m3y - m1y, 2) / f_squared +
          Math.pow(k3 * m3x - m1x, 2) / f_squared)
    );
    // //console.log("whRatio", whRatio)
    if (k2 === 1 && k3 === 1) {
      whRatio = Math.sqrt(
        (Math.pow(m2y - m1y, 2) + Math.pow(m2x - m1x, 2)) /
          (Math.pow(m3y - m1y, 2) + Math.pow(m3x - m1x, 2))
      );
    }

    return whRatio;
  }

  handleSelectTool = (e, value) => {
    this.setState({ maintool: value });
    if (value === "drag" || value === "line") {
      this.setState({ tool: value });
    }
    if (value === "shapes") {
      this.setState({ tool: "rect" });
    }
    this.setState({ golden_show_options: false });
    this.setState({ grid_show_options: false });
    this.setState({ line_show_options: false });
    this.setState({ phi_show_options: false });
    this.setState({ angle_show_options: false });
    if (value === "line") {
      this.setState({ line_show_options: true });
      this.setState({ tool: "line" });
    }
    if (value === "grids") {
      this.setState({ grid_show_options: true });
      this.setState({ tool: "grid" });
    }
  };
  handleSubSelectTool = (e, value) => {
    if (value !== null) {
      this.setState({ tool: value });
      this.setState({ golden_show_options: false });
      this.setState({ grid_show_options: false });
      this.setState({ line_show_options: false });
      this.setState({ phi_show_options: false });
      this.setState({ angle_show_options: false });
      if (value === "grid") {
        this.setState({ grid_show_options: true });
      }
      if (value === "golden") {
        this.setState({ golden_show_options: true });
      }
      if (value === "phi") {
        this.setState({ phi_show_options: true });
      }
      if (value === "angle") {
        this.setState({ angle_show_options: true });
      }
    }
  };

  handleMouseDownObj = (obj, e) => {
    const { tool } = this.state;
    if (this.state.allowDraw) {
      let width, height;

      if (obj.canvas === "A") {
        this.setState({ editA: true });
        width = this.svgRef.current.getBoundingClientRect().width;
        height = this.svgRef.current.getBoundingClientRect().height;
      } else {
        this.setState({ editA: false });
        width = this.svgRefB.current.getBoundingClientRect().width;
        height = this.svgRefB.current.getBoundingClientRect().height;
      }
      const ratio = height / width;
      this.setState({ drawboundingX: width });
      this.setState({ drawboundingY: height });

      // if (tool === "drag") {
      this.setState({ golden_show_options: false });
      this.setState({ grid_show_options: false });
      this.setState({ line_show_options: false });
      this.setState({ phi_show_options: false });
      this.setState({ angle_show_options: false });
      if (obj.type === "line") {
        console.log(obj);
        this.setState({
          drawPosition1: {
            x: (obj.xStart * width) / 100,
            y: (obj.yStart * height) / 100 / ratio,
          },
        });
        this.setState({ drawPosition2: { x: -10000, y: -10000 } });
        this.setState({
          drawPosition3: {
            x: (obj.xEnd * width) / 100,
            y: (obj.yEnd * height) / 100 / ratio,
          },
        });
        this.setState({ drawPosition4: { x: -10000, y: -10000 } });
        this.setState({
          drawPosition5: {
            x: ((obj.xStart + (obj.xEnd - obj.xStart) / 2) * width) / 100,
            y:
              ((obj.yStart + (obj.yEnd - obj.yStart) / 2) * height) /
              100 /
              ratio,
          },
        });
        this.setState({
          line_options: obj.options,
          line_show_options: true,
          golden_show_options: false,
        });
      } else {
        console.log(obj);
        if (obj.options.expand) {
          this.setState({ drawPosition1: { x: -10000, y: -10000 } });
          this.setState({ drawPosition2: { x: -10000, y: -10000 } });
          this.setState({ drawPosition3: { x: -10000, y: -10000 } });
          this.setState({ drawPosition4: { x: -10000, y: -10000 } });
          this.setState({ drawPosition5: { x: -10000, y: -10000 } });
        } else {
          this.setState({
            drawPosition1: {
              x: (obj.xStart * width) / 100,
              y: (obj.yStart * height) / 100 / ratio,
            },
          });
          this.setState({
            drawPosition2: {
              x: (obj.xEnd * width) / 100,
              y: (obj.yStart * height) / 100 / ratio,
            },
          });
          this.setState({
            drawPosition3: {
              x: (obj.xEnd * width) / 100,
              y: (obj.yEnd * height) / 100 / ratio,
            },
          });
          this.setState({
            drawPosition4: {
              x: (obj.xStart * width) / 100,
              y: (obj.yEnd * height) / 100 / ratio,
            },
          });
          this.setState({
            drawPosition5: {
              x: ((obj.xStart + (obj.xEnd - obj.xStart) / 2) * width) / 100,
              y:
                ((obj.yStart + (obj.yEnd - obj.yStart) / 2) * height) /
                100 /
                ratio,
            },
          });
        }
      }
      if (obj.type === "grid") {
        this.setState({
          grid_square: obj.options.square,
          grid_expand: obj.options.expand,
          grid_columns: obj.options.columns,
          grid_rows: obj.options.rows,
          grid_show_options: true,
          golden_show_options: false,
        });
      }
      if (obj.type === "golden") {
        this.setState({
          golden_options: obj.options,
          golden_show_options: true,
          grid_show_options: false,
        });
      }
      if (obj.type === "phi") {
        this.setState({
          golden_options: obj.options,
          phi_show_options: true,
        });
      }
      if (obj.type === "angle") {
        this.setState({
          angle_options: obj.options,
          angle_show_options: true,
        });
      }
      this.setState({ draw_color: obj.bg });
      this.setState({ line_thickness: obj.sz });
      this.setState({ line_opacity: obj.op });

      this.setState({
        objDragSelect: obj.id,
        objSelect: obj.id,
      });
    }
    // }
  };
  // handleTouchDownObj = (obj, e) => {
  //   const { tool } = this.state;
  //   if (this.state.allowDraw) {

  //     if (tool === "drag") {
  //       if (obj.type === "grid") {
  //         this.setState({
  //           grid_square: obj.options.square,
  //           grid_expand: obj.options.expand,
  //           grid_columns: obj.options.columns,
  //           grid_rows: obj.options.rows,
  //           grid_show_options: true,
  //           golden_show_options: false,
  //         });
  //       }
  //       if (obj.type === "golden") {
  //         this.setState({
  //           golden_options: obj.options,
  //           golden_show_options: true,
  //           grid_show_options: false,
  //         });
  //       }
  //       this.setState({ draw_color: obj.bg });
  //       this.setState({ line_thickness: obj.sz });
  //       this.setState({ line_opacity: obj.op });
  //       this.setState({
  //         objDragSelect: obj.id,
  //         objSelect: obj.id,
  //         dragStart: {
  //           x: e.touches[0].clientX,
  //           y: e.touches[0].clientY,
  //         },
  //       });
  //     }
  //   }
  // };
  // handleMouseUpObj = (obj, e) => {

  //   this.setState({ objDragSelect: null });
  // };
  // handleMouseDownResize = (obj, corner, e) => {
  //   const { tool } = this.state;
  //   e.stopPropagation();
  //   if (this.state.allowDraw) {
  //     // if (tool === "drag") {

  //     this.setState({
  //       objResizeSelect: obj.id,
  //       objResizeCorner: corner,
  //       dragStart: {
  //         x: e.clientX,
  //         y: e.clientY,
  //       },
  //     });
  //     // }
  //   }
  // };
  // handleTouchDownResize = (obj, corner, e) => {
  //   const { tool } = this.state;
  //   e.stopPropagation();
  //   if (this.state.allowDraw) {
  //     // if (tool === "drag") {

  //     this.setState({
  //       objResizeSelect: obj.id,
  //       objResizeCorner: corner,
  //       dragStart: {
  //         x: e.touches[0].clientX,
  //         y: e.touches[0].clientY,
  //       },
  //     });
  //     // }
  //   }
  // };
  // handleMouseUpResize = (obj, e) => {
  //   e.stopPropagation();
  //   if (this.state.objSelect) {
  //     // check if rect is inverted
  //     console.log("cehck invert");
  //     const index = this.state.objects.findIndex(
  //       (o) => o.id === this.state.objSelect
  //     );
  //     const obj = this.state.objects[index];
  //     console.log(obj);
  //     if (obj.type !== "line") {
  //       if (obj.xEnd < obj.xStart || obj.yEnd < obj.yStart) {
  //         console.log("invert");
  //         let newxStart = obj.xStart;
  //         let newxEnd = obj.xEnd;
  //         let newyStart = obj.yStart;
  //         let newyEnd = obj.yEnd;
  //         if (obj.xEnd < obj.xStart) {
  //           newxStart = obj.xEnd;
  //           newxEnd = obj.xStart;
  //         }
  //         if (obj.yEnd < obj.yStart) {
  //           newyStart = obj.yEnd;
  //           newyEnd = obj.yStart;
  //         }
  //         this.setState({
  //           objects: [
  //             ...this.state.objects.slice(0, index),
  //             {
  //               ...obj,
  //               xStart: newxStart,
  //               xEnd: newxEnd,
  //               yStart: newyStart,
  //               yEnd: newyEnd,
  //             },
  //             ...this.state.objects.slice(index + 1),
  //           ],
  //         });
  //       }
  //     }
  //   }
  //   if (this.state.allowDraw) {
  //     this.setState({ objResizeSelect: null });
  //     this.setState({ objResizeCorner: null });
  //     this.setState({ dragStart: null });
  //   }
  // };
  handleCancelSelect(e) {
    if (this.state.allowDraw) {
      this.setState({ objSelect: null });
      this.setState({ objResizeSelect: null });
      this.setState({ objResizeCorner: null });
      this.setState({ dragStart: null });
    }
  }

  getCoordsGlobal(e, canvas) {
    if (e.clientX) {
      if (canvas === "B") {
        const { top, left, width, height } =
          this.svgRefB.current.getBoundingClientRect();
        const ratio = width / height;
        return {
          x: ((e.clientX - left) / width) * 100,
          y: (((e.clientY - top) / height) * 100) / ratio,
        };
      } else {
        const { top, left, width, height } =
          this.svgRef.current.getBoundingClientRect();
        const ratio = width / height;
        return {
          x: ((e.clientX - left) / width) * 100,
          y: (((e.clientY - top) / height) * 100) / ratio,
        };
      }
    } else {
      if (e.type !== "touchend") {
        if (canvas === "B") {
          const { top, left, width, height } =
            this.svgRefB.current.getBoundingClientRect();
          const ratio = width / height;
          return {
            x: ((e.touches[0].clientX - left) / width) * 100,
            y: (((e.touches[0].clientY - top) / height) * 100) / ratio,
          };
        } else {
          const { top, left, width, height } =
            this.svgRef.current.getBoundingClientRect();
          const ratio = width / height;
          return {
            x: ((e.touches[0].clientX - left) / width) * 100,
            y: (((e.touches[0].clientY - top) / height) * 100) / ratio,
          };
        }
      } else {
        if (canvas === "B") {
          const { top, left, width, height } =
            this.svgRefB.current.getBoundingClientRect();
          const ratio = width / height;
          return {
            x: ((e.changedTouches[0].clientX - left) / width) * 100,
            y: (((e.changedTouches[0].clientY - top) / height) * 100) / ratio,
          };
        } else {
          const { top, left, width, height } =
            this.svgRef.current.getBoundingClientRect();
          const ratio = width / height;
          return {
            x: ((e.changedTouches[0].clientX - left) / width) * 100,
            y: (((e.changedTouches[0].clientY - top) / height) * 100) / ratio,
          };
        }
      }
    }
  }
  handleDragObjectStop = (e, dragElement) => {
    const index = this.state.objects.findIndex(
      (o) => o.id === this.state.objSelect
    );
    const obj = this.state.objects[index];
    let width, height;

    if (obj.canvas === "A") {
      width = this.svgRef.current.getBoundingClientRect().width;
      height = this.svgRef.current.getBoundingClientRect().height;
      this.setState({ tempw: width });
      this.setState({ temph: height });
    } else {
      width = this.svgRefB.current.getBoundingClientRect().width;
      height = this.svgRefB.current.getBoundingClientRect().height;
    }
    const ratio = height / width;
    this.setState({ tempratio: ratio });
    if (obj.type === "line") {
      this.setState({
        drawPosition1: {
          x: (obj.xStart * width) / 100,
          y: (obj.yStart * height) / 100 / ratio,
        },
      });
      this.setState({ drawPosition2: { x: -10000, y: -10000 } });
      this.setState({
        drawPosition3: {
          x: (obj.xEnd * width) / 100,
          y: (obj.yEnd * height) / 100 / ratio,
        },
      });
      this.setState({ drawPosition4: { x: -10000, y: -10000 } });
      this.setState({
        drawPosition5: {
          x: ((obj.xStart + (obj.xEnd - obj.xStart) / 2) * width) / 100,
          y:
            ((obj.yStart + (obj.yEnd - obj.yStart) / 2) * height) / 100 / ratio,
        },
      });
    } else {
      this.setState({
        drawPosition1: {
          x: (obj.xStart * width) / 100,
          y: (obj.yStart * height) / 100 / ratio,
        },
      });
      this.setState({
        drawPosition2: {
          x: (obj.xEnd * width) / 100,
          y: (obj.yStart * height) / 100 / ratio,
        },
      });
      this.setState({
        drawPosition3: {
          x: (obj.xEnd * width) / 100,
          y: (obj.yEnd * height) / 100 / ratio,
        },
      });
      this.setState({
        drawPosition4: {
          x: (obj.xStart * width) / 100,
          y: (obj.yEnd * height) / 100 / ratio,
        },
      });
      this.setState({
        drawPosition5: {
          x: ((obj.xStart + (obj.xEnd - obj.xStart) / 2) * width) / 100,
          y:
            ((obj.yStart + (obj.yEnd - obj.yStart) / 2) * height) / 100 / ratio,
        },
      });
    }
  };
  handleDragObjectStart = (e, dragElement) => {
    console.log(dragElement);
    this.setState({
      objResizeSelect: this.state.objSelect,
      objResizeCorner: e.target.id,
    });
  };
  handleDragObject = (event, dragElement) => {
    console.log("dragging object", dragElement);
    const dEx = dragElement.x;
    const dEy = dragElement.y;
    console.log(dragElement);
    const index = this.state.objects.findIndex(
      (o) => o.id === this.state.objSelect
    );
    const obj = this.state.objects[index];
    const canvas = obj.canvas;
    const { objResizeCorner, objResizeSelect, dragStart } = this.state;
    if (objResizeSelect && objResizeCorner) {
      console.log("resize", dragStart, objResizeCorner);
      let width, height, used_ratio;
      if (canvas === "A") {
        width = this.svgRef.current.getBoundingClientRect().width;
        height = this.svgRef.current.getBoundingClientRect().height;
        //console.log(width,height)
        // used_ratio = this.state.image_ratio;
      } else {
        width = this.svgRefB.current.getBoundingClientRect().width;
        height = this.svgRefB.current.getBoundingClientRect().height;
        // used_ratio = this.state.image_ratioB;
      }
      const ratio = height / width;
      const index = this.state.objects.findIndex(
        (o) => o.id === objResizeSelect
      );
      // const { x: xDragStart, y: yDragStart } = dragStart;
      // let xDragEnd, yDragEnd;
      // if (e.clientX) {
      //   xDragEnd = e.clientX;
      //   yDragEnd = e.clientY;

      // } else {
      //   xDragEnd = e.touches[0].clientX;
      //   yDragEnd = e.touches[0].clientY;
      // }
      // const { clientX: xDragEnd, clientY: yDragEnd } = e;

      const xDelta = (dragElement.deltaX / width) * 100;
      const yDelta = ((dragElement.deltaY / height) * 100) / used_ratio;

      console.log(width, height, used_ratio);
      const obj = this.state.objects[index];
      console.log(obj);
      let lock = false;
      if (event.shiftKey) {
        console.log("lock");
        if (obj.type === "rect" || obj.type === "circ") {
          lock = true;
        } else {
          lock = false;
        }
      }
      if (obj.type === "golden" && obj.options.golden_lock) {
        lock = true;
      }
      if (obj.type === "phi" && obj.options.golden_lock) {
        lock = true;
      }
      if (obj.type === "angle" && obj.options.golden_lock) {
        lock = true;
      }
      switch (event.target.id) {
        case "1":
          if (obj.type !== "line") {
            this.setState({
              drawPosition2: { x: this.state.drawPosition2.x, y: dEy },
              drawPosition4: { x: dEx, y: this.state.drawPosition4.y },
            });
          }
          this.setState({
            drawPosition5: {
              x:
                this.state.drawPosition3.x +
                (dEx - this.state.drawPosition3.x) / 2,
              y:
                this.state.drawPosition3.y +
                (dEy - this.state.drawPosition3.y) / 2,
            },
          });

          this.setState({
            drawPosition1: { x: dEx, y: dEy },

            objects: [
              ...this.state.objects.slice(0, index),
              {
                ...obj,
                xStart: (dEx / width) * 100,
                xEnd: obj.xEnd,
                yStart: lock
                  ? obj.yEnd + (obj.xStart + xDelta - obj.xEnd) * obj.locked
                  : (dEy / height) * 100 * ratio,
                yEnd: obj.yEnd,
              },
              ...this.state.objects.slice(index + 1),
            ],
          });
          break;
        case "2":
          this.setState({
            drawPosition5: {
              x:
                this.state.drawPosition1.x +
                (dEx - this.state.drawPosition1.x) / 2,
              y:
                this.state.drawPosition3.y +
                (dEy - this.state.drawPosition3.y) / 2,
            },
          });

          this.setState({
            drawPosition2: { x: dEx, y: dEy },
            drawPosition1: { x: this.state.drawPosition1.x, y: dEy },
            drawPosition3: { x: dEx, y: this.state.drawPosition3.y },
            objects: [
              ...this.state.objects.slice(0, index),
              {
                ...obj,
                xStart: obj.xStart,
                xEnd: (dEx / width) * 100,
                yStart: lock
                  ? obj.yEnd + (obj.xStart + xDelta - obj.xEnd) * obj.locked
                  : (dEy / height) * 100 * ratio,
                yEnd: obj.yEnd,
              },
              ...this.state.objects.slice(index + 1),
            ],
          });
          break;
        case "3":
          console.log("3");
          if (obj.type !== "line") {
            this.setState({
              drawPosition4: { x: this.state.drawPosition4.x, y: dEy },
              drawPosition2: { x: dEx, y: this.state.drawPosition2.y },
            });
          }
          this.setState({
            drawPosition5: {
              x:
                this.state.drawPosition1.x +
                (dEx - this.state.drawPosition1.x) / 2,
              y:
                this.state.drawPosition1.y +
                (dEy - this.state.drawPosition1.y) / 2,
            },
          });

          this.setState({
            drawPosition3: { x: dEx, y: dEy },

            objects: [
              ...this.state.objects.slice(0, index),
              {
                ...obj,
                xStart: obj.xStart,
                xEnd: (dEx / width) * 100,
                yStart: obj.yStart,
                yEnd: lock
                  ? obj.yStart + (obj.xEnd + xDelta - obj.xStart) * obj.locked
                  : (dEy / height) * 100 * ratio,
              },
              ...this.state.objects.slice(index + 1),
            ],
          });
          break;
        case "4":
          this.setState({
            drawPosition5: {
              x:
                this.state.drawPosition3.x +
                (dEx - this.state.drawPosition3.x) / 2,
              y:
                this.state.drawPosition1.y +
                (dEy - this.state.drawPosition1.y) / 2,
            },
          });

          this.setState({
            drawPosition4: { x: dEx, y: dEy },
            drawPosition3: { x: this.state.drawPosition3.x, y: dEy },
            drawPosition1: { x: dEx, y: this.state.drawPosition1.y },
            objects: [
              ...this.state.objects.slice(0, index),
              {
                ...obj,
                xStart: (dEx / width) * 100,
                xEnd: obj.xEnd,
                yStart: obj.yStart,
                yEnd: lock
                  ? obj.yStart + (obj.xEnd + xDelta - obj.xStart) * obj.locked
                  : (dEy / height) * 100 * ratio,
              },
              ...this.state.objects.slice(index + 1),
            ],
          });
          break;
        default:
        // code block
      }
    }
  };
  handleDragDraw = (event, dragElement) => {
    // drawing an new object
    console.log("dragging");
    const dEx = dragElement.x;
    const dEy = dragElement.y;
    if (event.target.id === "A") {
      this.setState({
        drawPositionA: { x: dEx, y: dEy },
      });
    } else {
      this.setState({
        drawPositionB: { x: dEx, y: dEy },
      });
    }
    this.handleMouseMove(event.target.id, event);
  };
  handleStopDraw = (e, dragElement) => {
    console.log("stop drag");

    let xEnd, yEnd;
    let canvas = e.target.id;
    xEnd = this.getCoordsGlobal(e, canvas).x;
    yEnd = this.getCoordsGlobal(e, canvas).y;

    const { width, height } = this.svgRef.current.getBoundingClientRect();
    this.setState({ drawboundingX: width });
    this.setState({ drawboundingY: height });
    this.setState({ drawPositionA: { x: width / 2, y: height / 2 } });
    const index = this.state.objects.findIndex(
      (o) => o.id === this.state.currentObject
    );
    const obj = this.state.objects[index];
    console.log(Math.abs(xEnd - this.state.dragStart.x));
    if (
      Math.abs(xEnd - this.state.dragStart.x) < 2 &&
      Math.abs(yEnd - this.state.dragStart.y) < 2
    ) {
      // delete object  and go back  to  select
      this.setState({
        objSelect: null,
        objects: [
          ...this.state.objects.slice(0, index),
          ...this.state.objects.slice(index + 1),
        ],
      });
      this.setState({ tool: "drag" });
      this.setState({ maintool: "drag" });
    } else {
      this.handleMouseDownObj(obj, e);
    }
  };
  handleDragStart = (e, dragElement) => {
    // start drawing a new object

    let xStart, yStart;
    let canvas = e.target.id;
    xStart = this.getCoordsGlobal(e, canvas).x;
    yStart = this.getCoordsGlobal(e, canvas).y;

    if (canvas === "A") {
      const { width, height } = this.svgRef.current.getBoundingClientRect();
      this.setState({ drawboundingX: width });
      this.setState({ drawboundingY: height });
      this.setState({
        drawPositionA: {
          x: (xStart * width) / 100,
          y: (yStart * height) / 100,
        },
      });
    } else {
      const { width, height } = this.svgRefB.current.getBoundingClientRect();
      // this.setState({ drawboundingX: width });
      // this.setState({ drawboundingY: height });
      this.setState({
        drawPositionB: {
          x: (xStart * width) / 100,
          y: (yStart * height) / 100,
        },
      });
    }

    this.handleMouseDown(canvas, e);
    this.setState({
      dragStart: {
        x: xStart,
        y: yStart,
      },
    });
  };
  HandleSVGLoad = () => {
    const { width, height } = this.svgRef.current.getBoundingClientRect();
    this.setState({ drawboundingX: width });
    this.setState({ drawboundingY: height });
    this.setState({ drawPositionA: { x: width / 2, y: height / 2 } });
  };
  handleMouseMoveDrag = (canvas, mobile, e) => {
    // let xStart, yStart;
    //   if (mobile) {
    //     xStart = this.getCoordsMobile(e, canvas).x;
    //     yStart = this.getCoordsMobile(e, canvas).y;
    //   } else {
    //     xStart = this.getCoords(e, canvas).x;
    //     yStart = this.getCoords(e, canvas).y;
    //   }
    //   //martijn
    //   const { width, height } = this.svgRef.current.getBoundingClientRect();
    //   this.setState({ drawboundingX: width });
    //   this.setState({ drawboundingY: height });
    //   this.setState({ drawPositionA: { x: xStart*width/100, y: yStart*height/100 } });
  };
  handleMouseDown(canvas, e) {
    if (this.state.allowDraw) {
      const { shiftKey } = e;
      let lock = null;

      lock = 1;

      let xStart, yStart;
      xStart = this.getCoordsGlobal(e, canvas).x;
      yStart = this.getCoordsGlobal(e, canvas).y;
      // martijn
      const { width, height } = this.svgRef.current.getBoundingClientRect();
      // this.setState({ drawboundingX: width });
      // this.setState({ drawboundingY: height });
      // this.setState({ drawPositionA: { x: xStart*width/100, y: yStart*height/100 } });
      // console.log(xStart, yStart);
      // console.log("mousedown", this.state.objResizeSelect);

      let options = {};
      if (this.state.tool === "grid") {
        options = {
          square: this.state.grid_square,
          expand: this.state.grid_expand,
          rows: this.state.grid_rows,
          columns: this.state.grid_columns,
        };
        console.log(options);
      }
      if (this.state.tool === "golden" || this.state.tool === "phi") {
        options = this.state.golden_options;
        lock = 0.618;
      }
      if (this.state.tool === "angle") {
        options = this.state.angle_options;
        lock = 0.618;
      }
      if (this.state.tool === "line") {
        options = this.state.line_options;
        lock = 1;
      }
      this.setState((s) => {
        const obj = {
          id: Date.now(),
          type: this.state.tool,
          bg: this.state.draw_color,
          sz: this.state.line_thickness,
          op: this.state.line_opacity,
          xStart,
          yStart,
          xEnd: xStart,
          yEnd: yStart,
          locked: lock,
          canvas: canvas,
          options: options,
        };
        return {
          objects: [...s.objects, obj],
          currentObject: obj.id,
        };
      });
    } else {
      //
    }
  }

  handleMouseMove(canvas, e) {
    // this.preventDefaultMove(e)
    // e.stopPropagation();
    if (this.state.allowDraw) {
      const { currentObject, objDragSelect, dragStart } = this.state;

      if (currentObject) {
        const index = this.state.objects.findIndex(
          (o) => o.id === currentObject
        );
        let xEnd, yEnd;
        xEnd = this.getCoordsGlobal(e, canvas).x;
        yEnd = this.getCoordsGlobal(e, canvas).y;
        let lock = null;
        if (e.shiftKey) {
          if (this.state.tool === "rect" || this.state.tool === "circ") {
            lock = 1;
          }
        } else {
          lock = null;
        }
        if (
          this.state.objects[index].type === "golden" &&
          this.state.objects[index].options.golden_lock
        ) {
          lock = true;
        }
        if (
          this.state.objects[index].type === "phi" &&
          this.state.objects[index].options.golden_lock
        ) {
          lock = true;
        }
        if (
          this.state.objects[index].type === "angle" &&
          this.state.objects[index].options.golden_lock
        ) {
          lock = true;
        }
        if (lock) {
          const w = xEnd - this.state.objects[index].xStart;
          const h = yEnd - this.state.objects[index].yStart;
          const size = Math.min(w, h);
          // console.log(size);
          xEnd = this.state.objects[index].xStart + size;
          yEnd =
            this.state.objects[index].yStart +
            size * this.state.objects[index].locked;
        }

        const obj = {
          ...this.state.objects[index],
          xEnd,
          yEnd,
        };

        this.setState({
          objects: [
            ...this.state.objects.slice(0, index),
            obj,
            ...this.state.objects.slice(index + 1),
          ],
        });
      }
      // if (objResizeSelect && dragStart && objResizeCorner) {
      //   console.log("resize", dragStart, objResizeCorner);
      //   let width, height, used_ratio;
      //   if (canvas === "A") {
      //     width = this.svgRef.current.getBoundingClientRect().width;
      //     height = this.svgRef.current.getBoundingClientRect().height;
      //     //console.log(width,height)
      //     used_ratio = this.state.image_ratio;
      //   } else {
      //     width = this.svgRefB.current.getBoundingClientRect().width;
      //     height = this.svgRefB.current.getBoundingClientRect().height;
      //     used_ratio = this.state.image_ratioB;
      //   }

      //   const index = this.state.objects.findIndex(
      //     (o) => o.id === objResizeSelect
      //   );
      //   const { x: xDragStart, y: yDragStart } = dragStart;
      //   let xDragEnd, yDragEnd;
      //   if (e.clientX) {
      //     xDragEnd = e.clientX;
      //     yDragEnd = e.clientY;

      //   } else {
      //     xDragEnd = e.touches[0].clientX;
      //     yDragEnd = e.touches[0].clientY;
      //   }
      //   // const { clientX: xDragEnd, clientY: yDragEnd } = e;

      //   const xDelta = ((xDragEnd - xDragStart) / width) * 100;
      //   const yDelta = (((yDragEnd - yDragStart) / height) * 100) / used_ratio;
      //   console.log(xDragEnd, xDragStart, xDelta);
      //   const obj = this.state.objects[index];
      //   console.log(obj)
      //   let lock = false;
      //   if (e.shiftKey) {
      //     console.log("lock");
      //     if (obj.type === "rect" || obj.type === "circ") {
      //       lock = true;
      //     } else {
      //       lock = false;
      //     }
      //   }
      //   if (obj.type === "golden" && obj.options.golden_lock) {
      //     lock = true;
      //   }
      //   if (obj.type === "phi" && obj.options.golden_lock) {
      //     lock = true;
      //   }
      //   if (obj.type === "angle" && obj.options.golden_lock) {
      //     lock = true;
      //   }
      //   switch (objResizeCorner) {
      //     case "1":
      //       this.setState({
      //         dragStart: {
      //           x: xDragEnd,
      //           y: yDragEnd,
      //         },
      //         objects: [
      //           ...this.state.objects.slice(0, index),
      //           {
      //             ...obj,
      //             xStart: obj.xStart + xDelta,
      //             xEnd: obj.xEnd,
      //             yStart: lock
      //               ? obj.yEnd + (obj.xStart + xDelta - obj.xEnd) * obj.locked
      //               : obj.yStart + yDelta,
      //             yEnd: obj.yEnd,
      //           },
      //           ...this.state.objects.slice(index + 1),
      //         ],
      //       });
      //       break;
      //     case "2":
      //       this.setState({
      //         dragStart: {
      //           x: xDragEnd,
      //           y: yDragEnd,
      //         },
      //         objects: [
      //           ...this.state.objects.slice(0, index),
      //           {
      //             ...obj,
      //             xStart: obj.xStart,
      //             xEnd: obj.xEnd + xDelta,
      //             yStart: lock
      //               ? obj.yEnd + (obj.xStart + xDelta - obj.xEnd) * obj.locked
      //               : obj.yStart + yDelta,
      //             yEnd: obj.yEnd,
      //           },
      //           ...this.state.objects.slice(index + 1),
      //         ],
      //       });
      //       break;
      //     case "3":
      //       this.setState({
      //         dragStart: {
      //           x: xDragEnd,
      //           y: yDragEnd,
      //         },
      //         objects: [
      //           ...this.state.objects.slice(0, index),
      //           {
      //             ...obj,
      //             xStart: obj.xStart,
      //             xEnd: obj.xEnd + xDelta,
      //             yStart: obj.yStart,
      //             yEnd: lock
      //               ? obj.yStart + (obj.xEnd + xDelta - obj.xStart) * obj.locked
      //               : obj.yEnd + yDelta,
      //           },
      //           ...this.state.objects.slice(index + 1),
      //         ],
      //       });
      //       break;
      //     case "4":
      //       this.setState({
      //         dragStart: {
      //           x: xDragEnd,
      //           y: yDragEnd,
      //         },
      //         objects: [
      //           ...this.state.objects.slice(0, index),
      //           {
      //             ...obj,
      //             xStart: obj.xStart + xDelta,
      //             xEnd: obj.xEnd,
      //             yStart: obj.yStart,
      //             yEnd: lock
      //               ? obj.yStart + (obj.xEnd + xDelta - obj.xStart) * obj.locked
      //               : obj.yEnd + yDelta,
      //           },
      //           ...this.state.objects.slice(index + 1),
      //         ],
      //       });
      //       break;
      //     default:
      //     // code block
      //   }
      // }
      // if (objDragSelect && dragStart) {
      //   console.log("drag");
      //   let width, height, used_ratio;
      //   if (canvas === "A") {
      //     width = this.svgRef.current.getBoundingClientRect().width;
      //     height = this.svgRef.current.getBoundingClientRect().height;
      //     used_ratio = this.state.image_ratio;
      //   } else {
      //     width = this.svgRefB.current.getBoundingClientRect().width;
      //     height = this.svgRefB.current.getBoundingClientRect().height;
      //     used_ratio = this.state.image_ratioB;
      //   }
      //   const index = this.state.objects.findIndex(
      //     (o) => o.id === objDragSelect
      //   );
      //   const { x: xDragStart, y: yDragStart } = dragStart;
      //   let xDragEnd, yDragEnd;
      //   if (e.clientX) {
      //     xDragEnd = e.clientX;
      //     yDragEnd = e.clientY;

      //   } else {
      //     xDragEnd = e.touches[0].clientX;
      //     yDragEnd = e.touches[0].clientY;
      //   }

      //   const xDelta = ((xDragEnd - xDragStart) / width) * 100;
      //   const yDelta = (((yDragEnd - yDragStart) / height) * 100) / used_ratio;
      //   const obj = this.state.objects[index];
      //   this.setState({
      //     dragStart: {
      //       x: xDragEnd,
      //       y: yDragEnd,
      //     },
      //     objects: [
      //       ...this.state.objects.slice(0, index),
      //       {
      //         ...obj,
      //         xStart: obj.xStart + xDelta,
      //         xEnd: obj.xEnd + xDelta,
      //         yStart: obj.yStart + yDelta,
      //         yEnd: obj.yEnd + yDelta,
      //       },
      //       ...this.state.objects.slice(index + 1),
      //     ],
      //   });
      // }
    }
  }
  handleMoveObject = (e, dragElement) => {
    console.log("move");
    const dEx = dragElement.x;
    const dEy = dragElement.y;

    this.setState({
      drawPosition5: { x: dEx, y: dEy },
    });
    let width, height, used_ratio;
    const index = this.state.objects.findIndex(
      (o) => o.id === this.state.objSelect
    );
    const obj = this.state.objects[index];
    const canvas = obj.canvas;
    if (canvas === "A") {
      width = this.svgRef.current.getBoundingClientRect().width;
      height = this.svgRef.current.getBoundingClientRect().height;
    } else {
      width = this.svgRefB.current.getBoundingClientRect().width;
      height = this.svgRefB.current.getBoundingClientRect().height;
    }
    const ratio = height / width;
    // const { x: xDragStart, y: yDragStart } = dragStart;
    // let xDragEnd, yDragEnd;
    // if (e.clientX) {
    //   xDragEnd = e.clientX;
    //   yDragEnd = e.clientY;

    // } else {
    //   xDragEnd = e.touches[0].clientX;
    //   yDragEnd = e.touches[0].clientY;
    // }

    const xDelta = (dragElement.deltaX / width) * 100;
    const yDelta = (dragElement.deltaY / height) * 100 * ratio;
    if (obj.type === "line") {
      this.setState({
        drawPosition1: {
          x: ((obj.xStart + xDelta) * width) / 100,
          y: ((obj.yStart + yDelta) * height) / 100 / ratio,
        },
        drawPosition2: { x: -10000, y: -10000 },
        drawPosition3: {
          x: ((obj.xEnd + xDelta) * width) / 100,
          y: ((obj.yEnd + yDelta) * height) / 100 / ratio,
        },
        drawPosition4: { x: -10000, y: -10000 },
      });
    } else {
      this.setState({
        drawPosition1: {
          x: ((obj.xStart + xDelta) * width) / 100,
          y: ((obj.yStart + yDelta) * height) / 100 / ratio,
        },
        drawPosition2: {
          x: ((obj.xEnd + xDelta) * width) / 100,
          y: ((obj.yStart + yDelta) * height) / 100 / ratio,
        },
        drawPosition3: {
          x: ((obj.xEnd + xDelta) * width) / 100,
          y: ((obj.yEnd + yDelta) * height) / 100 / ratio,
        },
        drawPosition4: {
          x: ((obj.xStart + xDelta) * width) / 100,
          y: ((obj.yEnd + yDelta) * height) / 100 / ratio,
        },
      });
    }

    this.setState({
      objects: [
        ...this.state.objects.slice(0, index),
        {
          ...obj,
          xStart: obj.xStart + xDelta,
          xEnd: obj.xEnd + xDelta,
          yStart: obj.yStart + yDelta,
          yEnd: obj.yEnd + yDelta,
        },
        ...this.state.objects.slice(index + 1),
      ],
    });
  };

  deleteLine() {
    const index = this.state.objects.findIndex(
      (o) => o.id === this.state.objSelect
    );
    if (index === -1) {
      return;
    }
    this.setState({
      objSelect: null,
      objects: [
        ...this.state.objects.slice(0, index),
        ...this.state.objects.slice(index + 1),
      ],
    });
  }
  deleteAllLines() {
    this.setState({
      objSelect: null,
      objects: [],
    });
  }
  keyPress = (e) => {
    console.log(e.keyCode);
    if (this.state.allowDraw) {
      if (e.keyCode === 46) {
        if (this.state.objSelect) {
          this.deleteLine();
        }
      }
      if (e.keyCode === 27) {
        this.setState({
          objSelect: null,
          objDragSelect: null,
          currentObject: null,
        });
      }
    }
  };
  handleMouseUp(canvas, e) {
    if (this.state.allowDraw) {
      if (this.state.currentObject) {
        // check if rect is inverted
        console.log("cehck invert");
        const index = this.state.objects.findIndex(
          (o) => o.id === this.state.currentObject
        );
        const obj = this.state.objects[index];
        console.log(obj);
        if (obj.type !== "line") {
          if (obj.xEnd < obj.xStart || obj.yEnd < obj.yStart || obj.locked) {
            console.log("invert");
            let newxStart = obj.xStart;
            let newxEnd = obj.xEnd;
            let newyStart = obj.yStart;
            let newyEnd = obj.yEnd;
            if (obj.xEnd < obj.xStart) {
              newxStart = obj.xEnd;
              newxEnd = obj.xStart;
            }
            if (obj.yEnd < obj.yStart) {
              newyStart = obj.yEnd;
              newyEnd = obj.yStart;
            }
            // if (obj.locked){
            //   const w = newxEnd - newxStart
            //   const h = newyEnd - newyStart
            //   const size = Math.min(w, h);
            //   newxEnd = newxStart + size
            //   newyEnd = newyStart + size*obj.locked
            // }
            this.setState({
              objects: [
                ...this.state.objects.slice(0, index),
                {
                  ...obj,
                  xStart: newxStart,
                  xEnd: newxEnd,
                  yStart: newyStart,
                  yEnd: newyEnd,
                },
                ...this.state.objects.slice(index + 1),
              ],
            });
          }
        }
      }
      if (this.state.currentObject && !this.state.objResizeCorner) {
        const index = this.state.objects.findIndex(
          (o) => o.id === this.state.currentObject
        );
        const obj = this.state.objects[index];
        const deltaX = obj.xEnd - obj.xStart;
        const deltaY = obj.yEnd - obj.yStart;
        console.log(deltaX, deltaY);
        if (Math.abs(deltaX) < 0.5 && Math.abs(deltaY) < 0.5) {
          this.setState({
            tool: "drag",
            maintool: "drag",
            objSelect: null,
            objects: [
              ...this.state.objects.slice(0, index),
              ...this.state.objects.slice(index + 1),
            ],
          });
        } else {
          this.setState({ hasChanges: true });
          if (!this.state.objSelect) {
            this.setState({
              objSelect: this.state.currentObject,
            });
          }
        }
        //console.log(this.state.objects);
      }

      this.setState({
        objDragSelect: null,
        currentObject: null,
        objResizeCorner: null,
      });
      console.log(this.state.objects);
    }
  }

  render() {
    const { element_types, element, dimensions, project_url, elements } =
      this.props;

    let imagesrc = this.state.image;

    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    }
    let imagesrcB = this.state.imageB;

    if (this.state.imagePreviewB) {
      imagesrcB = this.state.imagePreviewB;
    }
    const loc = window.location.pathname.split("/")[1];

    const viewboxA = "0 0 100 " + 100 / this.state.image_ratio;

    const viewboxB = "0 0 100 " + 100 / this.state.image_ratioB;

    let mime = null;
    try {
      mime = element.images[0].mime_type.split("/")[0];
    } catch (e) {
      // ignore
    }

    const open = Boolean(this.state.anchorEl);
    // console.log("anchor:", this.state.anchorEl, open);
    // if (dimensions.window_dimensions.isMobile) {
    // } else {
    // }
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
          }}>
          <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
            {this.state.showSaveConfirmation ? (
              <StyledElementPopup>
                <ElementPanel>
                  <ElementCardConfirmation
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <ConfirmationDescription>
                      This element has changes that have not been saved.
                    </ConfirmationDescription>
                    <ButtonGroup
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <StyledButtonLeft2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.handleCloseSaveConfirmation(project_url);
                          }}
                          disableElevation
                          variant="outlined"
                          color="primary"
                          style={{ minWidth: "150px" }}>
                          Don't Save
                        </Button>
                      </StyledButtonLeft2>
                      <StyledButtonRight2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.savePage();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{ minWidth: "180px" }}>
                          Save Changes
                        </Button>
                      </StyledButtonRight2>
                    </ButtonGroup>
                  </ElementCardConfirmation>
                </ElementPanel>
              </StyledElementPopup>
            ) : (
              ""
            )}
            {this.state.showDeleteConfirmation ? (
              <StyledElementPopup>
                <ElementPanel>
                  <ElementCardConfirmation
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <ConfirmationDescription>
                      Do you really want to delete this element?
                      <br />
                      Deleting this element will also delete all comments and
                      cannot be undone.
                    </ConfirmationDescription>
                    <ButtonGroup
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <StyledButtonLeft2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.handleCloseConfirmation();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{ minWidth: "150px" }}>
                          No
                        </Button>
                      </StyledButtonLeft2>
                      <StyledButtonRight2
                        isMobile={dimensions.window_dimensions.isMobile}>
                        <Button
                          onClick={() => {
                            this.deleteElement();
                          }}
                          disableElevation
                          variant="contained"
                          color="secondary"
                          style={{ minWidth: "180px" }}>
                          Yes, Delete Element
                        </Button>
                      </StyledButtonRight2>
                    </ButtonGroup>
                  </ElementCardConfirmation>
                </ElementPanel>
              </StyledElementPopup>
            ) : (
              ""
            )}
            {this.state.showImageEdit ? (
              <StyledElementPopup>
                <ElementPanel>
                  <ElementCardConfirmation
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <SettingsHeader>
                      <SettingsTitle
                        isMobile={dimensions.window_dimensions.isMobile}>
                        Edit Image
                      </SettingsTitle>
                      <CloseButtonNarrow
                        isMobile={dimensions.window_dimensions.isMobile}
                        onClick={() => {
                          this.handleCloseImageEdit();
                        }}>
                        {" "}
                        <CloseIcon sx={{ fontSize: "22px" }} />
                      </CloseButtonNarrow>
                    </SettingsHeader>
                    <ConfirmationDescription>Hello</ConfirmationDescription>
                    <ButtonGroup>
                      <StyledButtonLeft>
                        <Button
                          onClick={() => {
                            this.handleCloseImageEdit();
                          }}
                          disableElevation
                          variant="contained"
                          color="primary"
                          style={{ minWidth: "150px" }}>
                          No
                        </Button>
                      </StyledButtonLeft>
                      <StyledButtonRight>
                        <Button
                          onClick={() => {
                            this.saveImage();
                          }}
                          disableElevation
                          variant="contained"
                          color="secondary"
                          style={{ minWidth: "180px" }}>
                          Save Image
                        </Button>
                      </StyledButtonRight>
                    </ButtonGroup>
                  </ElementCardConfirmation>
                </ElementPanel>
              </StyledElementPopup>
            ) : (
              ""
            )}
            {this.state.showimageselectpopup ? (
              <StyledElementPopup>
                <ElementPanel>
                  <ElementCardConfirmation
                    isMobile={dimensions.window_dimensions.isMobile}>
                    <SettingsHeader>
                      <SettingsTitle
                        isMobile={dimensions.window_dimensions.isMobile}>
                        Select Image
                      </SettingsTitle>
                      <CloseButtonNarrow
                        isMobile={dimensions.window_dimensions.isMobile}
                        onClick={() => {
                          this.handleCloseSelect();
                        }}>
                        {" "}
                        <CloseIcon sx={{ fontSize: "22px" }} />
                      </CloseButtonNarrow>
                    </SettingsHeader>
                    <ImagesContainer>
                      {this.state.uniqueImageIDS.map((imgid, index2) => {
                        const image_url =
                          api_endpoint + "/media/" + imgid + "/file?size=256";
                        //console.log(image_url);

                        return (
                          <SmallElement
                            src={image_url}
                            key={"el" + index2}
                            cardwidth={100}
                            onClick={() => {
                              this.handleSelect(
                                imgid,
                                this.state.imageNumberSelect
                              );
                            }}></SmallElement>
                        );
                      })}
                    </ImagesContainer>
                    <ButtonGroup>
                      <StyledButtonLeft></StyledButtonLeft>
                      <StyledButtonRight>
                        <Button
                          variant="contained"
                          component="label"
                          disableElevation
                          color="primary"
                          style={{
                            minWidth: "80px",
                          }}>
                          Browse...
                          <input
                            type="file"
                            id="upload-button"
                            accept={mimetype.image}
                            multiple={false}
                            style={{ display: "none" }}
                            onChange={this.handleImageChangeCompare}
                          />
                        </Button>
                      </StyledButtonRight>
                    </ButtonGroup>
                  </ElementCardConfirmation>
                </ElementPanel>
              </StyledElementPopup>
            ) : (
              ""
            )}
            <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
              <ElementCard isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsHeader
                  isMobile={dimensions.window_dimensions.isMobile}>
                  <SettingsTitle
                    isMobile={dimensions.window_dimensions.isMobile}>
                    Element Settings
                  </SettingsTitle>
                  {this.state.element_type === "CO" ? (
                    <CompareToggle>
                      {" "}
                      <Button
                        onClick={() => {
                          this.onChangeToggleComparison();
                        }}
                        size="small"
                        variant="toolbarbutton"
                        color="toolbarbutton"
                        startIcon={
                          this.state.comparison_type === "SS" ? (
                            <CompareIcon fontSize="inherit" />
                          ) : (
                            <ImageIcon fontSize="inherit" />
                          )
                        }>
                        {this.state.comparison_type === "SS"
                          ? "Show As Overlay"
                          : "Show Side By Side"}
                      </Button>
                    </CompareToggle>
                  ) : (
                    ""
                  )}

                  <CloseButtonNarrow
                    isMobile={dimensions.window_dimensions.isMobile}
                    onClick={() => {
                      this.handleClosePopup(project_url);
                    }}>
                    {" "}
                    <IconButton>
                      <CloseIcon sx={{ fontSize: "22px" }} />
                    </IconButton>
                  </CloseButtonNarrow>
                </SettingsHeader>

                <SettingsColumns
                  isMobile={dimensions.window_dimensions.isMobile}>
                  <ColumnMiddle
                    isMobile={dimensions.window_dimensions.isMobile}>
                    {
                      {
                        TD: (
                          <DimensionCenter>
                            <DimensionContainer w={this.state.nonImageSquare}>
                              <Next
                                selected={this.props.selected}
                                description={this.state.description}
                              />
                            </DimensionContainer>
                          </DimensionCenter>
                        ),
                        TE: (
                          <DimensionCenter>
                            <DimensionContainer
                              w={this.state.nonImageSquare}
                              onClick={() => {
                                this.onChangeSectionNumber(5);
                              }}>
                              <Text
                                selected={this.props.selected}
                                description={this.state.description}
                                tag={
                                  this.state.element_label !== "NO"
                                    ? this.state.element_label
                                    : null
                                }
                                textcolor={this.state.text_color}
                                textalignment={this.state.text_alignment}
                              />
                            </DimensionContainer>
                          </DimensionCenter>
                        ),
                        QN: (
                          <DimensionCenter>
                            <DimensionContainer w={this.state.nonImageSquare}>
                              <Question
                                selected={this.props.selected}
                                description={this.state.description}
                              />
                            </DimensionContainer>
                          </DimensionCenter>
                        ),
                        DM: (
                          <OtherContainer
                            isMobile={dimensions.window_dimensions.isMobile}>
                            <DimensionCenter>
                              <DimensionContainer w={this.state.nonImageSquare}>
                                <Dimension
                                  selected={this.props.selected}
                                  description={this.state.description}
                                  unit={this.state.unit}
                                  x={this.state.x}
                                  y={this.state.y}
                                  z={this.state.z}
                                />
                              </DimensionContainer>
                            </DimensionCenter>

                            {/* {element.description ? (
                                   <ElementDescription>{element.description}</ElementDescription>
                                 ) : (
                                   ""
                                 )} */}
                          </OtherContainer>
                        ),
                        UP: (
                          <PreviewContent
                            isMobile={dimensions.window_dimensions.isMobile}
                            h={this.state.imageAHeight}>
                            {
                              {
                                video: <VideoPlayer src={this.state.image} />,
                                audio: <AudioPlayer src={this.state.image} />,
                                image: (
                                  <ImageWrapper
                                    isMobile={
                                      dimensions.window_dimensions.isMobile
                                    }
                                    w={this.state.imageAWidth}
                                    h={this.state.imageAHeight}>
                                    {this.state.showcrop ? (
                                      <div>
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.boundingX,
                                            bottom: this.state.boundingY,
                                          }}
                                          position={
                                            this.state.controlledPosition1
                                          }
                                          onDrag={this.handleDrag}
                                          onStop={this.handleStop}
                                          nodeRef={this.nodeRefA}>
                                          <CropDotOriginal
                                            ref={this.nodeRefA}
                                            id={"1"}
                                          />
                                        </Draggable>
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.boundingX,
                                            bottom: this.state.boundingY,
                                          }}
                                          position={
                                            this.state.controlledPosition2
                                          }
                                          onDrag={this.handleDrag}
                                          onStop={this.handleStop}
                                          nodeRef={this.nodeRefB}>
                                          <CropDotOriginal
                                            ref={this.nodeRefB}
                                            id={"2"}
                                          />
                                        </Draggable>
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.boundingX,
                                            bottom: this.state.boundingY,
                                          }}
                                          position={
                                            this.state.controlledPosition3
                                          }
                                          onDrag={this.handleDrag}
                                          onStop={this.handleStop}
                                          nodeRef={this.nodeRefC}>
                                          <CropDotOriginal
                                            ref={this.nodeRefC}
                                            id={"3"}
                                          />
                                        </Draggable>
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.boundingX,
                                            bottom: this.state.boundingY,
                                          }}
                                          position={
                                            this.state.controlledPosition4
                                          }
                                          onDrag={this.handleDrag}
                                          onStop={this.handleStop}
                                          nodeRef={this.nodeRefD}>
                                          <CropDotOriginal
                                            ref={this.nodeRefD}
                                            id={"4"}
                                          />
                                        </Draggable>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <canvas
                                      ref={this.canvasCropLines}
                                      style={{
                                        position: "absolute",
                                        zIndex: 500,
                                        pointerEvents: "none",
                                      }}
                                      width={this.state.boundingX}
                                      height={this.state.boundingY}></canvas>
                                    <div>
                                      {this.state.tool !== "drag" &&
                                      this.state.allowDraw ? (
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.drawboundingX,
                                            bottom: this.state.drawboundingY,
                                          }}
                                          position={this.state.drawPositionA}
                                          onStart={this.handleDragStart}
                                          onDrag={this.handleDragDraw}
                                          onStop={this.handleStopDraw}
                                          nodeRef={this.drawRefA}>
                                          <CropSquare
                                            w={this.state.drawboundingX}
                                            h={this.state.drawboundingY}
                                            ref={this.drawRefA}
                                            id={"A"}
                                          />
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.objSelect &&
                                      this.state.allowDraw ? (
                                        <div
                                          style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                          }}>
                                          {" "}
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition1}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef1}>
                                            <CropDot ref={this.drawRef1}>
                                              <CropDotInside id={"1"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition2}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef2}>
                                            <CropDot ref={this.drawRef2}>
                                              <CropDotInside id={"2"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition3}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef3}>
                                            <CropDot ref={this.drawRef3}>
                                              <CropDotInside id={"3"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition4}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef4}>
                                            <CropDot ref={this.drawRef4}>
                                              <CropDotInside id={"4"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition5}
                                            // onStart={this.handleMoveObjectStart}

                                            onDrag={this.handleMoveObject}
                                            // onStop={this.handleMoveObjectStop}
                                            nodeRef={this.drawRef5}>
                                            <CropDot
                                              ref={this.drawRef5}
                                              id={"5"}>
                                              {" "}
                                              <CropDotInside>
                                                {" "}
                                                <OpenWithIcon
                                                  style={{
                                                    margin: "auto",
                                                    fill: "#FFF",
                                                    fontSize: "20px",
                                                  }}
                                                />
                                              </CropDotInside>
                                            </CropDot>
                                          </Draggable>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <SVGContent
                                      viewBox={viewboxA}
                                      height="100%"
                                      width="100%"
                                      ref={this.svgRef}>
                                      <rect
                                        onMouseDown={this.handleCancelSelect.bind(
                                          this
                                        )}
                                        onTouchStart={this.handleCancelSelect.bind(
                                          this
                                        )}
                                        width="100%"
                                        height="100%"
                                        fillOpacity="0%"
                                        fill="white"></rect>
                                      {this.state.objects.map((o) => {
                                        if (o.canvas === "A") {
                                          if (o.type === "rect") {
                                            return (
                                              <Rect
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }

                                          if (o.type === "line") {
                                            return (
                                              <Line
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "circ") {
                                            return (
                                              <Circ
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "grid") {
                                            return (
                                              <SimpleGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "golden") {
                                            return (
                                              <GoldenGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "phi") {
                                            return (
                                              <PhiGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "angle") {
                                            return (
                                              <AngleGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                        }

                                        return null;
                                      })}
                                    </SVGContent>
                                    <img
                                      alt=""
                                      ref={this.bkgImage}
                                      onLoad={() => {
                                        this.HandleImageLoaded(1);
                                      }}
                                      style={{
                                        width: this.state.imageAWidth,
                                        height: this.state.imageAHeight,
                                        marginBottom: "-4px",
                                        userDrag: "none",
                                        userSelect: "none",
                                      }}
                                      src={imagesrc}
                                      draggable="false"
                                    />
                                  </ImageWrapper>
                                ),
                              }[mime]
                            }
                          </PreviewContent>
                        ),
                        CO: (
                          <PreviewContentCompare
                            isMobile={dimensions.window_dimensions.isMobile}
                            isOverlay={this.state.comparison_type}
                            h={
                              dimensions.window_dimensions.w /
                                this.state.image_ratio +
                              "px"
                            }>
                            <CompareWrapper
                              clip={this.state.clip}
                              type={this.state.comparison_type}>
                              <FirstImage
                                w={this.state.imageAWidth}
                                h={this.state.imageAHeight}>
                                {imagesrc ? (
                                  <div
                                    style={{
                                      width: this.state.imageAWidth,
                                      height: this.state.imageAHeight,
                                      position: "relative",
                                    }}>
                                    <div>
                                      {this.state.tool !== "drag" &&
                                      this.state.allowDraw ? (
                                        <Draggable
                                          bounds={{
                                            left: 0,
                                            top: 0,
                                            right: this.state.drawboundingX,
                                            bottom: this.state.drawboundingY,
                                          }}
                                          position={this.state.drawPositionA}
                                          onStart={this.handleDragStart}
                                          onDrag={this.handleDragDraw}
                                          onStop={this.handleStopDraw}
                                          nodeRef={this.drawRefA}>
                                          <CropSquare
                                            w={this.state.drawboundingX}
                                            h={this.state.drawboundingY}
                                            ref={this.drawRefA}
                                            id={"A"}
                                          />
                                        </Draggable>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.objSelect &&
                                      this.state.allowDraw &&
                                      this.state.editA ? (
                                        <div
                                          style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                          }}>
                                          {" "}
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition1}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef1}>
                                            <CropDot ref={this.drawRef1}>
                                              <CropDotInside id={"1"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition2}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef2}>
                                            <CropDot ref={this.drawRef2}>
                                              <CropDotInside id={"2"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition3}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef3}>
                                            <CropDot ref={this.drawRef3}>
                                              <CropDotInside id={"3"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition4}
                                            onStart={this.handleDragObjectStart}
                                            onDrag={this.handleDragObject}
                                            onStop={this.handleDragObjectStop}
                                            nodeRef={this.drawRef4}>
                                            <CropDot ref={this.drawRef4}>
                                              <CropDotInside id={"4"} />
                                            </CropDot>
                                          </Draggable>
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPosition5}
                                            // onStart={this.handleMoveObjectStart}

                                            onDrag={this.handleMoveObject}
                                            // onStop={this.handleMoveObjectStop}
                                            nodeRef={this.drawRef5}>
                                            <CropDot
                                              ref={this.drawRef5}
                                              id={"5"}>
                                              {" "}
                                              <CropDotInside>
                                                {" "}
                                                <OpenWithIcon
                                                  style={{
                                                    margin: "auto",
                                                    fill: "#FFF",
                                                    fontSize: "20px",
                                                  }}
                                                />
                                              </CropDotInside>
                                            </CropDot>
                                          </Draggable>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <SVGContent
                                      touchaction={this.state.touchaction}
                                      viewBox={viewboxA}
                                      //  height="100%" width="100%"

                                      ref={this.svgRef}>
                                      <rect
                                        onMouseDown={this.handleCancelSelect.bind(
                                          this
                                        )}
                                        onTouchStart={this.handleCancelSelect.bind(
                                          this
                                        )}
                                        width="100%"
                                        height="100%"
                                        fillOpacity="0%"
                                        fill="white"></rect>
                                      {this.state.objects.map((o) => {
                                        if (o.canvas === "A") {
                                          if (o.type === "rect") {
                                            return (
                                              <Rect
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }

                                          if (o.type === "line") {
                                            return (
                                              <Line
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "circ") {
                                            return (
                                              <Circ
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "grid") {
                                            return (
                                              <SimpleGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "golden") {
                                            return (
                                              <GoldenGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "phi") {
                                            return (
                                              <PhiGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                          if (o.type === "angle") {
                                            return (
                                              <AngleGrid
                                                editable={true}
                                                drawnow={
                                                  this.state.imageAloaded
                                                }
                                                key={o.id + "ee"}
                                                objSelect={this.state.objSelect}
                                                obj={o}
                                                svg={this.svgRef.current}
                                                handleMouseDownObj={
                                                  this.handleMouseDownObj
                                                }
                                              />
                                            );
                                          }
                                        }

                                        return null;
                                      })}
                                    </SVGContent>

                                    <img
                                      alt=""
                                      ref={this.bkgImage}
                                      style={{
                                        width: this.state.imageAWidth,
                                        height: this.state.imageAHeight,
                                        marginBottom: "-4px",
                                      }}
                                      onLoad={() => {
                                        this.HandleImageLoaded(1);
                                      }}
                                      src={imagesrc}
                                      draggable="false"
                                    />
                                  </div>
                                ) : (
                                  <NoImageSelected>
                                    <ImageLabel>A</ImageLabel>
                                    <ImageButton>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.SelectImage(1);
                                        }}>
                                        Select Image
                                      </Button>
                                    </ImageButton>
                                  </NoImageSelected>
                                )}
                              </FirstImage>
                            </CompareWrapper>
                            {this.state.comparison_type === "SS" ? (
                              <DividerLine>
                                <VSBadge>vs</VSBadge>
                              </DividerLine>
                            ) : (
                              <Draggable
                                axis="x"
                                bounds={{
                                  left: -this.state.maxW / 2,
                                  top: 0,
                                  right: this.state.maxW / 2,
                                  bottom: 800,
                                }}
                                //  position={this.state.controlledPosition1}
                                onDrag={this.handleDragSlider}
                                //  onStop={this.handleStop}
                                nodeRef={this.nodeRefA}>
                                <SliderHandleContainer ref={this.nodeRefA}>
                                  <SliderHandle id={"1"} />
                                  <SliderCircle>
                                    <SliderArrow />
                                    <SliderArrow2 />
                                  </SliderCircle>
                                  <SliderHandle id={"1"} />
                                </SliderHandleContainer>
                              </Draggable>
                            )}

                            <CompareWrapperRight
                              clip={this.state.clip}
                              type={this.state.comparison_type}>
                              <OverlayCorrection
                                w={this.state.overlayW}
                                h={this.state.overlayH}>
                                <SecondImage
                                  scale={this.state.scale_b}
                                  rotate={this.state.rotate_b}
                                  opacity={this.state.opacity_b}
                                  translate_x={this.state.translate_x_b}
                                  translate_y={this.state.translate_y_b}
                                  w={this.state.imageBWidth}
                                  h={this.state.imageBHeight}>
                                  {imagesrcB ? (
                                    <div
                                      style={{
                                        width: this.state.imageBWidth,
                                        height: this.state.imageBHeight,
                                        position: "relative",
                                      }}>
                                      <div>
                                        {this.state.tool !== "drag" &&
                                        this.state.allowDraw ? (
                                          <Draggable
                                            bounds={{
                                              left: 0,
                                              top: 0,
                                              right: this.state.drawboundingX,
                                              bottom: this.state.drawboundingY,
                                            }}
                                            position={this.state.drawPositionA}
                                            onStart={this.handleDragStart}
                                            onDrag={this.handleDragDraw}
                                            onStop={this.handleStopDraw}
                                            nodeRef={this.drawRefB}>
                                            <CropSquare
                                              w={this.state.drawboundingX}
                                              h={this.state.drawboundingY}
                                              ref={this.drawRefB}
                                              id={"B"}
                                            />
                                          </Draggable>
                                        ) : (
                                          ""
                                        )}
                                        {this.state.objSelect &&
                                        this.state.allowDraw &&
                                        !this.state.editA ? (
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "100%",
                                              height: "100%",
                                            }}>
                                            {" "}
                                            <Draggable
                                              bounds={{
                                                left: 0,
                                                top: 0,
                                                right: this.state.drawboundingX,
                                                bottom:
                                                  this.state.drawboundingY,
                                              }}
                                              position={
                                                this.state.drawPosition1
                                              }
                                              onStart={
                                                this.handleDragObjectStart
                                              }
                                              onDrag={this.handleDragObject}
                                              onStop={this.handleDragObjectStop}
                                              nodeRef={this.drawRef1}>
                                              <CropDot ref={this.drawRef1}>
                                                <CropDotInside id={"1"} />
                                              </CropDot>
                                            </Draggable>
                                            <Draggable
                                              bounds={{
                                                left: 0,
                                                top: 0,
                                                right: this.state.drawboundingX,
                                                bottom:
                                                  this.state.drawboundingY,
                                              }}
                                              position={
                                                this.state.drawPosition2
                                              }
                                              onStart={
                                                this.handleDragObjectStart
                                              }
                                              onDrag={this.handleDragObject}
                                              onStop={this.handleDragObjectStop}
                                              nodeRef={this.drawRef2}>
                                              <CropDot ref={this.drawRef2}>
                                                <CropDotInside id={"2"} />
                                              </CropDot>
                                            </Draggable>
                                            <Draggable
                                              bounds={{
                                                left: 0,
                                                top: 0,
                                                right: this.state.drawboundingX,
                                                bottom:
                                                  this.state.drawboundingY,
                                              }}
                                              position={
                                                this.state.drawPosition3
                                              }
                                              onStart={
                                                this.handleDragObjectStart
                                              }
                                              onDrag={this.handleDragObject}
                                              onStop={this.handleDragObjectStop}
                                              nodeRef={this.drawRef3}>
                                              <CropDot ref={this.drawRef3}>
                                                <CropDotInside id={"3"} />
                                              </CropDot>
                                            </Draggable>
                                            <Draggable
                                              bounds={{
                                                left: 0,
                                                top: 0,
                                                right: this.state.drawboundingX,
                                                bottom:
                                                  this.state.drawboundingY,
                                              }}
                                              position={
                                                this.state.drawPosition4
                                              }
                                              onStart={
                                                this.handleDragObjectStart
                                              }
                                              onDrag={this.handleDragObject}
                                              onStop={this.handleDragObjectStop}
                                              nodeRef={this.drawRef4}>
                                              <CropDot ref={this.drawRef4}>
                                                <CropDotInside id={"4"} />
                                              </CropDot>
                                            </Draggable>
                                            <Draggable
                                              bounds={{
                                                left: 0,
                                                top: 0,
                                                right: this.state.drawboundingX,
                                                bottom:
                                                  this.state.drawboundingY,
                                              }}
                                              position={
                                                this.state.drawPosition5
                                              }
                                              // onStart={this.handleMoveObjectStart}

                                              onDrag={this.handleMoveObject}
                                              // onStop={this.handleMoveObjectStop}
                                              nodeRef={this.drawRef5}>
                                              <CropDot
                                                ref={this.drawRef5}
                                                id={"5"}>
                                                {" "}
                                                <CropDotInside>
                                                  {" "}
                                                  <OpenWithIcon
                                                    style={{
                                                      margin: "auto",
                                                      fill: "#FFF",
                                                      fontSize: "20px",
                                                    }}
                                                  />
                                                </CropDotInside>
                                              </CropDot>
                                            </Draggable>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <SVGContent
                                        touchaction={this.state.touchaction}
                                        viewBox={viewboxA}
                                        //  height="100%" width="100%"

                                        ref={this.svgRefB}>
                                        <rect
                                          onMouseDown={this.handleCancelSelect.bind(
                                            this
                                          )}
                                          width="100%"
                                          height="100%"
                                          fillOpacity="0%"
                                          fill="white"></rect>
                                        {this.state.objects.map((o) => {
                                          if (o.canvas === "B") {
                                            if (o.type === "rect") {
                                              return (
                                                <Rect
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }

                                            if (o.type === "line") {
                                              return (
                                                <Line
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                            if (o.type === "circ") {
                                              return (
                                                <Circ
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                            if (o.type === "grid") {
                                              return (
                                                <SimpleGrid
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                            if (o.type === "golden") {
                                              return (
                                                <GoldenGrid
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                            if (o.type === "phi") {
                                              return (
                                                <PhiGrid
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                            if (o.type === "angle") {
                                              return (
                                                <AngleGrid
                                                  editable={true}
                                                  drawnow={
                                                    this.state.imageAloaded
                                                  }
                                                  key={o.id + "ee"}
                                                  objSelect={
                                                    this.state.objSelect
                                                  }
                                                  obj={o}
                                                  svg={this.svgRef.current}
                                                  handleMouseDownObj={
                                                    this.handleMouseDownObj
                                                  }
                                                />
                                              );
                                            }
                                          }

                                          return null;
                                        })}
                                      </SVGContent>
                                      <img
                                        alt=""
                                        ref={this.bkgImageB}
                                        style={{
                                          width: this.state.imageBWidth,
                                          height: this.state.imageBHeight,
                                          marginBottom: "-4px",
                                        }}
                                        onLoad={() => {
                                          this.HandleImageLoaded(2);
                                        }}
                                        src={imagesrcB}
                                        draggable="false"
                                      />
                                    </div>
                                  ) : (
                                    <NoImageSelected>
                                      <ImageLabel>B</ImageLabel>
                                      <ImageButton>
                                        <Button
                                          disableElevation
                                          variant="contained"
                                          color="primary"
                                          style={{
                                            minWidth: "80px",
                                            marginLeft: "auto",
                                          }}
                                          onClick={() => {
                                            this.SelectImage(2);
                                          }}>
                                          Select Image
                                        </Button>
                                      </ImageButton>
                                    </NoImageSelected>
                                  )}
                                </SecondImage>
                              </OverlayCorrection>
                            </CompareWrapperRight>
                          </PreviewContentCompare>
                        ),
                      }[this.state.element_type]
                    }
                  </ColumnMiddle>
                  <ColumnRight isMobile={dimensions.window_dimensions.isMobile}>
                    <CropPanel
                      w={this.state.panelW}
                      isMobile={dimensions.window_dimensions.isMobile}>
                      <PanelContainer x1={this.state.x1} x2={this.state.x2}>
                        <PanelOne w={this.state.panelW}>
                          {this.state.paneltwo === 0 ? (
                            <div>
                              {this.state.element_type === "UP" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(6);
                                  }}>
                                  <SectionIcon>
                                    <FileUploadIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Select Media</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "CO" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(13);
                                  }}>
                                  <SectionIcon>
                                    <FileUploadIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Select Images</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "CO" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(14);
                                  }}>
                                  <SectionIcon>
                                    <CompareIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>
                                    Default Comparison Type
                                  </SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "CO" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(15);
                                  }}>
                                  <SectionIcon>
                                    <OpenWithIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Image B Alignment</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {(this.state.element_type === "UP" &&
                                mime === "image") ||
                              this.state.element_type === "CO" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(16);
                                    this.turnOnDraw();
                                  }}>
                                  <SectionIcon>
                                    <EditIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Draw</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" ||
                              this.state.element_type === "TE" ||
                              this.state.element_type === "CO" ||
                              this.state.element_type === "DM" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(5);
                                  }}>
                                  <SectionIcon>
                                    <SubjectIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Description</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}

                              {this.state.element_type === "TE" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(12);
                                  }}>
                                  <SectionIcon>
                                    <FormatColorFillIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Background Color</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "TE" ||
                              this.state.element_type === "UP" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(1);
                                  }}>
                                  <SectionIcon>
                                    <InsertLinkIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Link</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "TE" ||
                              this.state.element_type === "UP" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(2);
                                  }}>
                                  <SectionIcon>
                                    <LabelOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Label</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              <EditItem
                                onClick={() => {
                                  this.onChangeSectionNumber(3);
                                }}>
                                <SectionIcon>
                                  <InsertInvitationOutlinedIcon fontSize="inherit" />
                                </SectionIcon>
                                <SectionLabel>Date/Time</SectionLabel>
                              </EditItem>
                              {this.state.element_type === "DM" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(4);
                                  }}>
                                  <SectionIcon>
                                    <HeightOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Dimensions</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" &&
                              mime !== "audio" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(7);
                                  }}>
                                  <SectionIcon>
                                    <InsertPhotoOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Thumbnail</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" &&
                              mime === "image" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(10);
                                  }}>
                                  <SectionIcon>
                                    <Rotate90DegreesCcwOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Rotate</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" &&
                              mime === "image" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(8);
                                    this.showCrop("fixed");
                                  }}>
                                  <SectionIcon>
                                    <CropOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Crop</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" &&
                              mime === "image" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(9);
                                    this.showCrop("perspcrop");
                                  }}>
                                  <SectionIcon>
                                    <CropFreeOutlinedIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Perspective Crop</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                              {this.state.element_type === "UP" &&
                              mime === "image" ? (
                                <EditItem
                                  onClick={() => {
                                    this.onChangeSectionNumber(11);
                                    this.loadOpenCv();
                                  }}>
                                  <SectionIcon>
                                    <ColorLensIcon fontSize="inherit" />
                                  </SectionIcon>
                                  <SectionLabel>Color Tools</SectionLabel>
                                </EditItem>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </PanelOne>
                        <PanelTwo>
                          {
                            {
                              0: (
                                <SectionContent
                                  w={this.state.panelW}></SectionContent>
                              ),
                              5: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Description</SectionLabel2>
                                  </SectionHeader>

                                  <FormControl fullWidth variant="outlined">
                                    <TextField
                                      size="small"
                                      id="description"
                                      label={null}
                                      // label="Description"
                                      variant="outlined"
                                      multiline
                                      rows={6}
                                      inputProps={{ maxLength: 140 }}
                                      value={this.state.description}
                                      onChange={this.onChange}
                                    />
                                  </FormControl>
                                  <SectionHelpText>
                                    Add a description to provide more
                                    information about this element. The maximum
                                    number of characters is 140.
                                  </SectionHelpText>
                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(5);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              1: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Link</SectionLabel2>
                                  </SectionHeader>
                                  <ControlLine>
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        size="small"
                                        id="link_url"
                                        label="Link (url)"
                                        variant="outlined"
                                        value={this.state.link_url}
                                        onChange={this.onChange}
                                      />
                                    </FormControl>
                                    {this.state.showLinkError ? (
                                      <LinkError>
                                        Please enter a valid link, e.g. starting
                                        with http:// or https://
                                      </LinkError>
                                    ) : (
                                      ""
                                    )}
                                  </ControlLine>

                                  <ControlLine>
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        size="small"
                                        id="link_name"
                                        label="Link Name (optional)"
                                        variant="outlined"
                                        value={this.state.link_name}
                                        onChange={this.onChange}
                                      />
                                    </FormControl>
                                  </ControlLine>

                                  {this.state.hasLink ? (
                                    <Button
                                      onClick={() => {
                                        this.clearLink();
                                      }}
                                      // size="small"
                                      disableElevation
                                      variant="contained"
                                      color="error"
                                      startIcon={<DeleteForeverIcon />}>
                                      Clear Link
                                    </Button>
                                  ) : (
                                    ""
                                  )}

                                  <SectionHelpText>
                                    Link to a website outside of Sequentially.
                                    The link shows up on the timeline.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(1);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              2: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Label</SectionLabel2>
                                  </SectionHeader>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      column="true"
                                      value={this.state.element_label}
                                      onChange={this.onChangeRadio}>
                                      <FormControlLabel
                                        value="NO"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="None"
                                      />
                                      <FormControlLabel
                                        value="MT"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="Material"
                                      />
                                      <FormControlLabel
                                        value="TL"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="Tool"
                                      />
                                      <FormControlLabel
                                        value="TD"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="To Do"
                                      />
                                      <FormControlLabel
                                        value="QN"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="Question"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                  <SectionHelpText>
                                    Provide a label to this element to help the
                                    viewer understand what the intend of the
                                    image is.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(2);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              3: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Date / Time</SectionLabel2>
                                  </SectionHeader>

                                  {this.state.image_date ? (
                                    <ControlLine>
                                      <ImageDateInstruction>
                                        The element date has been automatically
                                        set to the{" "}
                                        <ImageDateAction
                                          onClick={() => {
                                            this.handleSetImageDate();
                                          }}>
                                          date of the uploaded
                                        </ImageDateAction>{" "}
                                        image. Set to{" "}
                                        <ImageDateAction
                                          onClick={() => {
                                            this.handleSetTodayDate();
                                          }}>
                                          Today
                                        </ImageDateAction>{" "}
                                        instead.
                                      </ImageDateInstruction>
                                    </ControlLine>
                                  ) : (
                                    ""
                                  )}

                                  <ControlLine>
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        size="small"
                                        id="element_date"
                                        label="Date"
                                        type="date"
                                        value={this.state.date || ""}
                                        onChange={(event) =>
                                          this.handleChangeDate(
                                            event.target.value
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </ControlLine>
                                  <ControlLine>
                                    <FormControl fullWidth variant="outlined">
                                      <TextField
                                        size="small"
                                        id="element_time"
                                        label="Time"
                                        type="time"
                                        value={this.state.time || ""}
                                        onChange={(event) =>
                                          this.handleChangeTime(
                                            event.target.value
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </FormControl>
                                  </ControlLine>
                                  <SectionHelpText>
                                    The date and time determine when this
                                    element shows up in the timeline.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(3);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              4: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Dimension</SectionLabel2>
                                  </SectionHeader>
                                  <ControlContent
                                    isMobile={
                                      dimensions.window_dimensions.isMobile
                                    }>
                                    <SubControl>
                                      <FormControl variant="outlined">
                                        <TextField
                                          size="small"
                                          id="x"
                                          type="number"
                                          label="Width"
                                          variant="outlined"
                                          value={this.state.x}
                                          onChange={this.onChange}
                                        />
                                      </FormControl>
                                    </SubControl>
                                    <SubControlSpacer></SubControlSpacer>
                                    <SubControl>
                                      <FormControl variant="outlined">
                                        <TextField
                                          size="small"
                                          id="y"
                                          type="number"
                                          label="Height"
                                          variant="outlined"
                                          value={this.state.y}
                                          onChange={this.onChange}
                                        />
                                      </FormControl>
                                    </SubControl>
                                    <SubControlSpacer></SubControlSpacer>
                                    <SubControl>
                                      <FormControl variant="outlined">
                                        <TextField
                                          size="small"
                                          id="z"
                                          type="number"
                                          label="Depth"
                                          variant="outlined"
                                          value={this.state.z}
                                          onChange={this.onChange}
                                        />
                                      </FormControl>
                                    </SubControl>
                                    <SubControlSpacer></SubControlSpacer>
                                    <SubControl>
                                      <FormControl variant="outlined">
                                        <TextField
                                          size="small"
                                          id="unit"
                                          placeholder="e.g. cm"
                                          label="Unit"
                                          variant="outlined"
                                          value={this.state.unit}
                                          onChange={this.onChange}
                                        />
                                      </FormControl>
                                    </SubControl>
                                  </ControlContent>
                                  <SectionHelpText>
                                    Provide the dimensions for this project.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(4);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              6: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Select Media</SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent></SectionContent>
                                  <SectionHelpText>
                                    Select an image file to replace the current
                                    image with. Maximum filesize is 20mb.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(6);
                                        }}>
                                        Cancel
                                      </Button>
                                      {/* <Button
                                        disableElevation
                                        variant="outlined"
                                        color="primary"
                                        style={{ minWidth: "80px", marginLeft: "auto" }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                       Apply
                                      </Button> */}
                                      <Button
                                        variant="contained"
                                        component="label"
                                        disableElevation
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                        }}>
                                        Browse...
                                        <input
                                          type="file"
                                          id="upload-button"
                                          accept={mimetype.image}
                                          multiple={false}
                                          style={{ display: "none" }}
                                          onChange={this.handleImageChange}
                                        />
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              13: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Select Images</SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <SectionButtonContainer>
                                      <Button
                                        onClick={() => {
                                          this.SelectImage(1);
                                        }}
                                        variant="contained"
                                        component="label"
                                        disableElevation
                                        color="primary"
                                        style={{
                                          minWidth: "100px",
                                        }}>
                                        Select A
                                      </Button>

                                      <Button
                                        onClick={() => {
                                          this.SelectImage(2);
                                        }}
                                        variant="contained"
                                        component="label"
                                        disableElevation
                                        color="primary"
                                        style={{
                                          minWidth: "100px",
                                          marginLeft: "auto",
                                        }}>
                                        Select B
                                      </Button>
                                    </SectionButtonContainer>
                                  </SectionContent>
                                  <SectionHelpText>
                                    Select an image file to replace the current
                                    image with. Maximum filesize is 20mb.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(13);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              7: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Thumbnail</SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent w={this.state.panelW}>
                                    <ThumbPreview>
                                      <ElementThumb
                                        cardwidth={264}
                                        image_url={this.state.image_for_thumb}
                                        isVideo={this.state.isVideo}
                                        show_full={this.state.image_show_full}
                                        element={element}
                                        element_types={element_types}
                                        description={this.state.description}
                                        unit={this.state.unit}
                                        link_url={this.state.link_url}
                                        link_name={this.state.link_name}
                                        x={this.state.x}
                                        y={this.state.y}
                                        z={this.state.z}
                                        drawing={
                                          this.state.objects
                                        }></ElementThumb>
                                    </ThumbPreview>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        column="true"
                                        value={this.state.image_show_full}
                                        onChange={this.onChangeRadioThumb}>
                                        <FormControlLabel
                                          value={false}
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Fill Frame (no black bars)"
                                        />
                                        <FormControlLabel
                                          value={true}
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Fit to Frame (show black bars)"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </SectionContent>
                                  <SectionHelpText>
                                    Set how the image is displayed on the
                                    thumbnail in the timeline.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(7);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              8: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                      this.hideCrop();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Crop Image</SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        column="true"
                                        value={this.state.cropType}
                                        onChange={this.onChangeRadioCrop}>
                                        <FormControlLabel
                                          value="FR"
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Freeform"
                                        />
                                        <FormControlLabel
                                          value="FI"
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Fixed Ratio"
                                        />
                                        {this.state.cropType === "FI" ? (
                                          <FixedOptions>
                                            <FixedShortCuts>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(
                                                    1,
                                                    (
                                                      1 / this.state.image_ratio
                                                    ).toFixed(3)
                                                  );
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                Original
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(1, 1.618);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                Golden
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(4, 5);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                4:5
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(9, 16);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                9:16
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(5, 7);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                5:7
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(3, 4);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                3:4
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(3, 5);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                3:5
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(2, 3);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                2:3
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(11, 14);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                11:14
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  this.setCropRatio(1, 1);
                                                }}
                                                size="small"
                                                variant="condensedbutton"
                                                color="toolbarbutton">
                                                1:1
                                              </Button>
                                            </FixedShortCuts>
                                            <ControlContent>
                                              <SubControl>
                                                <FormControl variant="outlined">
                                                  <TextField
                                                    size="small"
                                                    id="fixedCropx"
                                                    type="number"
                                                    label="Width"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    style={{ width: "90px" }}
                                                    variant="outlined"
                                                    value={
                                                      this.state.fixedCropx
                                                    }
                                                    onChange={
                                                      this.onChangeCropRatio
                                                    }
                                                  />
                                                </FormControl>
                                              </SubControl>
                                              <SubControlSpacer2></SubControlSpacer2>
                                              <IconButton
                                                onClick={() => {
                                                  this.onFlipCropRatio();
                                                }}>
                                                <SwapHorizIcon fontSize="inherit" />
                                              </IconButton>
                                              <SubControlSpacer></SubControlSpacer>
                                              <SubControl>
                                                <FormControl variant="outlined">
                                                  <TextField
                                                    size="small"
                                                    id="fixedCropy"
                                                    type="number"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    label="Height"
                                                    style={{ width: "90px" }}
                                                    variant="outlined"
                                                    value={
                                                      this.state.fixedCropy
                                                    }
                                                    onChange={
                                                      this.onChangeCropRatio
                                                    }
                                                  />
                                                </FormControl>
                                              </SubControl>
                                            </ControlContent>
                                          </FixedOptions>
                                        ) : (
                                          ""
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </SectionContent>
                                  <SectionHelpText>
                                    Crop an image into a smaller one and/or with
                                    a different aspect ratio. Drag the crop
                                    corners to change the crop area.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(8);
                                          this.hideCrop();
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("crop");
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              9: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                      this.hideCrop();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>
                                      Perspective Crop
                                    </SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <svg
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 213.2 80.41">
                                      <g>
                                        <rect
                                          x="128.65"
                                          y="8.69"
                                          width="84.04"
                                          height="63.03"
                                          style={{
                                            fill: "none",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <polyline
                                          points="128.65 56.71 147.68 38.37 165.23 54.54 190.08 22.59 212.7 54.15"
                                          style={{
                                            fill: "#fff",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <circle
                                          cx="161.64"
                                          cy="30.05"
                                          r="9.03"
                                          style={{
                                            fill: "#fff",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                      </g>
                                      <polygon
                                        points="117.33 40.21 106.38 29.26 106.38 35.33 94.38 35.33 94.38 45.09 106.38 45.09 106.38 51.16 117.33 40.21"
                                        style={{
                                          fill: "#fff",
                                          stroke: "#000",
                                          strokeMiterlimit: "10",
                                        }}
                                      />
                                      <g>
                                        <polyline
                                          points="64.41 63.37 .5 79.91 79.91 79.91 79.91 68.8"
                                          style={{ fill: "#fff" }}
                                        />
                                        <polygon
                                          points=".5 .5 64.41 .5 64.41 63.37 .5 79.91 .5 .5"
                                          style={{ fill: "#f2f2f3" }}
                                        />
                                        <polyline
                                          points="64.41 63.37 64.41 .5 79.91 .5 79.91 68.8 64.41 63.37"
                                          style={{ fill: "#cfcfce" }}
                                        />
                                        <rect
                                          x=".5"
                                          y=".5"
                                          width="79.41"
                                          height="79.41"
                                          style={{
                                            fill: "none",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <line
                                          x1="64.41"
                                          y1=".5"
                                          x2="64.41"
                                          y2="63.37"
                                          style={{
                                            fill: "none",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <line
                                          x1="79.91"
                                          y1="68.8"
                                          x2="64.41"
                                          y2="63.37"
                                          style={{
                                            fill: "none",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <line
                                          x1=".5"
                                          y1="79.91"
                                          x2="64.41"
                                          y2="63.37"
                                          style={{
                                            fill: "none",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                      </g>
                                      <g>
                                        <polygon
                                          points="54.97 43.85 10.83 51.63 10.83 12.71 54.97 12.71 54.97 43.85"
                                          style={{
                                            fill: "#fff",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <polyline
                                          points="10.83 42.35 20.95 30.5 29.82 38.44 42.92 20.56 54.97 35.11"
                                          style={{
                                            fill: "#fff",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                        <ellipse
                                          cx="28.08"
                                          cy="23.67"
                                          rx="5.21"
                                          ry="4.68"
                                          transform="translate(-.29 46.99) rotate(-79.54)"
                                          style={{
                                            fill: "#fff",
                                            stroke: "#000",
                                            strokeMiterlimit: "10",
                                          }}
                                        />
                                      </g>
                                    </svg>{" "}
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        column="true"
                                        value={this.state.cropPerpsType}
                                        onChange={this.onChangeRadioPerspCrop}>
                                        <FormControlLabel
                                          value="AU"
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Automatic"
                                        />
                                        <FormControlLabel
                                          value="MA"
                                          control={
                                            <Radio
                                              disableRipple
                                              color="primary"
                                            />
                                          }
                                          label="Manually Set Output Dimensions"
                                        />
                                        {this.state.cropPerpsType === "MA" ? (
                                          <FixedOptions>
                                            <ControlContent>
                                              <SubControl>
                                                <FormControl variant="outlined">
                                                  <TextField
                                                    size="small"
                                                    id="fixedPerspWidth"
                                                    type="number"
                                                    label="Width"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    style={{ width: "90px" }}
                                                    variant="outlined"
                                                    value={
                                                      this.state.fixedPerspWidth
                                                    }
                                                    onChange={
                                                      this
                                                        .onChangeCropPerspRatio
                                                    }
                                                  />
                                                </FormControl>
                                              </SubControl>
                                              <SubControlSpacer2></SubControlSpacer2>

                                              <SubControlSpacer></SubControlSpacer>
                                              <SubControl>
                                                <FormControl variant="outlined">
                                                  <TextField
                                                    size="small"
                                                    id="fixedPerspHeight"
                                                    type="number"
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                    label="Height"
                                                    style={{ width: "90px" }}
                                                    variant="outlined"
                                                    value={
                                                      this.state
                                                        .fixedPerspHeight
                                                    }
                                                    onChange={
                                                      this
                                                        .onChangeCropPerspRatio
                                                    }
                                                  />
                                                </FormControl>
                                              </SubControl>
                                            </ControlContent>
                                          </FixedOptions>
                                        ) : (
                                          ""
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </SectionContent>
                                  <SectionHelpText>
                                    Distortion occurs when an object (e.g. a
                                    painting) is photographed from an angle
                                    rather than from a straight-on view. The
                                    Perspective Crop tool lets you correct this
                                    distortion. Drag the crop corners so that
                                    edges line up with the rectangular sides in
                                    the photo. The resulting dimensions can be
                                    calculated automatically, or set manually
                                    when the output dimensions seem incorrect.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlideback();
                                          this.hideCrop();
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("perspcrop");
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              10: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>
                                      Rotate and Flip
                                    </SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <SectionGroupHeader>
                                      Rotate Image
                                    </SectionGroupHeader>
                                    <GroupedButtons>
                                      {" "}
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          this.rotateImage("rotate", "left");
                                        }}>
                                        <Rotate90DegreesCcwIcon
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                      <ButtonSpacer />
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          this.rotateImage("rotate", "right");
                                        }}>
                                        <Rotate90DegreesCwOutlinedIcon
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                    </GroupedButtons>
                                    <SectionGroupHeader>
                                      Flip Image
                                    </SectionGroupHeader>
                                    <GroupedButtons>
                                      {" "}
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          this.rotateImage("flip", "ver");
                                        }}>
                                        <FlipOutlinedIcon
                                          style={{ fontSize: "20px" }}
                                        />
                                      </Button>
                                      <ButtonSpacer />
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          this.rotateImage("flip", "hor");
                                        }}>
                                        <FlipHor style={{ fontSize: "20px" }} />
                                      </Button>
                                    </GroupedButtons>
                                  </SectionContent>
                                  {/* <SectionHelpText>
                                    
                                  </SectionHelpText> */}

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(10);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              11: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Color Tools</SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <ToggleButtonGroup
                                      fullWidth={true}
                                      color="primary"
                                      value={this.state.colorstate}
                                      exclusive
                                      onChange={this.handleColorStateChange}
                                      aria-label="Platform">
                                      <ToggleButton value="color">
                                        Color
                                      </ToggleButton>
                                      <ToggleButton value="gray">
                                        Gray Scale
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                    <SectionGroupHeader
                                      style={{ marginTop: "20px" }}>
                                      Blur
                                    </SectionGroupHeader>

                                    <Alignlineitem>
                                      {/* <AlignLabel>Blur</AlignLabel> */}
                                      <AlignIconSingle>
                                        {" "}
                                        <BlurOnIcon style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.blurvalue}
                                          onChange={this.handleChangeBlur}
                                          onChangeCommitted={
                                            this.handleCommitGray
                                          }
                                          valueLabelDisplay="auto"
                                          min={1}
                                          max={101}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.blurvalue}
                                        onChange={this.handleChangeBlur}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("blur");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem>
                                    <SectionGroupHeader>
                                      Contrast and Brightness
                                    </SectionGroupHeader>
                                    <Alignlineitem>
                                      {/* <AlignLabel>Blur</AlignLabel> */}
                                      <AlignIconSingle>
                                        {" "}
                                        <ContrastIcon style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.contrast}
                                          onChange={this.handleContrast}
                                          valueLabelDisplay="auto"
                                          min={1}
                                          step={0.1}
                                          max={3}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.contrast}
                                        onChange={this.handleContrast}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("contrast");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      {/* <AlignLabel>Blur</AlignLabel> */}
                                      <AlignIconSingle>
                                        {" "}
                                        <LightModeIcon style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.brightness}
                                          onChange={this.handleBright}
                                          valueLabelDisplay="auto"
                                          min={0}
                                          max={100}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.brightness}
                                        onChange={this.handleBright}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("contrast");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem>
                                    <SectionGroupHeader>
                                      Edge detection
                                    </SectionGroupHeader>
                                    <SectionHelpTextTools>
                                      Keeping the slider to the left includes more edge detail. The contrast sliders have an effect on the edge detection.
                                    </SectionHelpTextTools>
                                    <Alignlineitem>
                                      {/* <AlignLabel>Blur</AlignLabel> */}
                                      <AlignIconSingle>
                                        {" "}
                                        <AllOutIcon style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.edgevalues}
                                          onChange={this.handleChangeEdgeDouble}
                                          valueLabelDisplay="auto"
                                          min={1}
                                          max={255}
                                        />
                                      </AlignSlider>
                                      {/* <AlignInputSmall
                                        id="line_opacity"
                                        value={this.state.edgevalues}
                                        onChange={this.handleChangeEdgeDouble}
                                      /> */}
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("edge");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem>
                                    {/* <Alignlineitem>
                                     
                                      <AlignIconSingle>
                                      
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.edge_pos}
                                          onChange={this.handleChangeEdge2}
                                          valueLabelDisplay="auto"
                                          min={1}
                                          max={255}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.edge_pos}
                                        onChange={this.handleChangeEdge2}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("edge");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem> */}
                                    {/* <Alignlineitem>
                                     
                                      <AlignIconSingle>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.edge_ratio}
                                          onChange={this.handleChangeEdge}
                                          valueLabelDisplay="auto"
                                          step={0.1}
                                          min={1}
                                          max={3}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.edge_ratio}
                                        onChange={this.handleChangeEdge}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("edge");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem> */}
                                    <SectionGroupHeader>
                                      Number of Values/Colors
                                    </SectionGroupHeader>
                                    <SectionHelpTextTools>
                                      The blur slider has an effect on how well
                                      value areas blend together
                                    </SectionHelpTextTools>
                                    <Alignlineitem>
                                      {/* <AlignLabel>Blur</AlignLabel> */}
                                      <AlignIconSingle>
                                        {" "}
                                        <InvertColorsIcon style={{ fontSize: "20px" }} />
                                      </AlignIconSingle>
                                      <AlignSlider>
                                        <Slider
                                          value={this.state.gray_levels}
                                          onChange={this.handleChangeValues}
                                          onChangeCommitted={
                                            this.handleCommitGray
                                          }
                                          valueLabelDisplay="auto"
                                          min={2}
                                          max={50}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.gray_levels}
                                        onChange={this.handleChangeValues}
                                      />
                                      <IconButton
                                        style={{
                                          marginTop: "-8px",
                                          height: "40px",
                                        }}
                                        onClick={() => {
                                          this.rotateImage("values");
                                        }}>
                                        {this.state.blurprogress ? (
                                          <CircularProgress size={20} />
                                        ) : (
                                          <CheckIcon />
                                        )}
                                      </IconButton>
                                    </Alignlineitem>
                                  </SectionContent>
                                  {/* <SectionHelpText>
                                    
                                  </SectionHelpText> */}

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(10);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              12: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>
                                      Background Color
                                    </SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <ToggleButtonGroup
                                      value={this.state.text_color}
                                      exclusive
                                      onChange={this.ToggleColor}
                                      aria-label="Text Alignment"
                                      style={{
                                        height: "32px",
                                        marginLeft: "10px",
                                      }}>
                                      <ToggleButton
                                        value="#2196F3"
                                        aria-label="Left">
                                        <Colordot color="#2196F3"></Colordot>
                                      </ToggleButton>
                                      <ToggleButton
                                        value="#111"
                                        aria-label="Center">
                                        <Colordot color="#111"></Colordot>
                                      </ToggleButton>
                                      <ToggleButton
                                        value="#F00073"
                                        aria-label="Right">
                                        <Colordot color="#F00073"></Colordot>
                                      </ToggleButton>
                                      <ToggleButton
                                        value="#f7cd00"
                                        aria-label="Right">
                                        <Colordot color="#f7cd00"></Colordot>
                                      </ToggleButton>
                                      <ToggleButton
                                        value="#C4C4C4"
                                        aria-label="Right">
                                        <Colordot color="#C4C4C4"></Colordot>
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </SectionContent>
                                  <SectionHelpText></SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(12);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              14: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>
                                      Default Comparison Type
                                    </SectionLabel2>
                                  </SectionHeader>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      column="true"
                                      value={this.state.default_comparison_type}
                                      onChange={this.onChangeRadioComparison}>
                                      <FormControlLabel
                                        value="SS"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="Side by Side"
                                      />
                                      <FormControlLabel
                                        value="OV"
                                        control={
                                          <Radio
                                            disableRipple
                                            color="primary"
                                          />
                                        }
                                        label="Overlay"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                  <SectionHelpText>
                                    Side by Side shows both images next to each
                                    other, while Overlay puts them on top of
                                    each other with a comparison slider
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(14);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              15: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>
                                      Image B Alignment
                                    </SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <Alignlineitem>
                                      <AlignIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIcon>
                                      <AlignLabel>Resize</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={0}
                                          value={this.state.scale_b}
                                          min={-100}
                                          max={100}
                                          name="scale_b"
                                          onChange={this.onChangeSlider}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="scale_b"
                                        value={this.state.scale_b}
                                        onChange={this.onChange}
                                      />
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <AlignIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIcon>
                                      <AlignLabel>Horizontal</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={0}
                                          value={this.state.translate_x_b}
                                          min={-100}
                                          max={100}
                                          name="translate_x_b"
                                          onChange={this.onChangeSlider}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="translate_x_b"
                                        value={this.state.translate_x_b}
                                        onChange={this.onChange}
                                      />
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <AlignIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIcon>
                                      <AlignLabel>Vertical</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={0}
                                          value={this.state.translate_y_b}
                                          min={-100}
                                          max={100}
                                          name="translate_y_b"
                                          onChange={this.onChangeSlider}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="translate_y_b"
                                        value={this.state.translate_y_b}
                                        onChange={this.onChange}
                                      />
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <AlignIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIcon>
                                      <AlignLabel>Rotate</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={0}
                                          value={this.state.rotate_b}
                                          min={-45}
                                          max={45}
                                          name="rotate_b"
                                          onChange={this.onChangeSlider}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="rotate_b"
                                        value={this.state.rotate_b}
                                        onChange={this.onChange}
                                      />
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <AlignIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </AlignIcon>
                                      <AlignLabel>Opacity</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={100}
                                          value={this.state.opacity_b}
                                          min={0}
                                          max={100}
                                          name="opacity_b"
                                          onChange={this.onChangeSlider}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="opacity_b"
                                        value={this.state.opacity_b}
                                        onChange={this.onChange}
                                      />
                                    </Alignlineitem>
                                  </SectionContent>
                                  <SectionHelpText>
                                    Use the sliders to adjust image B, so it
                                    lines up better with image A. It is also
                                    possible to type directly into the number
                                    fields.
                                  </SectionHelpText>

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(15);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                              16: (
                                <SectionContent w={this.state.panelW}>
                                  <SectionHeader
                                    onClick={() => {
                                      this.onSlideback();
                                    }}>
                                    {" "}
                                    <BackIcon>
                                      <ChevronLeftIcon fontSize="inherit" />
                                    </BackIcon>{" "}
                                    <SectionLabel2>Draw </SectionLabel2>
                                  </SectionHeader>
                                  <SectionContent>
                                    <GroupedDrawButtons
                                      expanded={
                                        this.state.maintool === "grids" ||
                                        this.state.maintool === "shapes"
                                          ? true
                                          : false
                                      }>
                                      <ToggleButtonGroup
                                        value={this.state.maintool}
                                        exclusive
                                        onChange={this.handleSelectTool}
                                        aria-label="Drawing Tools">
                                        <ToggleButton
                                          style={{
                                            border: "0",
                                            borderRight: "1px solid #e0e0e0",
                                            minWidth: "72px",
                                          }}
                                          value="drag"
                                          aria-label="Left">
                                          <IconLabelGroup>
                                            {" "}
                                            <DrawCursor />
                                            <IconLabel>Select</IconLabel>
                                          </IconLabelGroup>
                                        </ToggleButton>
                                        <ToggleButton
                                          style={{
                                            border: "0",
                                            borderRight: "1px solid #e0e0e0",
                                            borderRadius: "0",
                                            minWidth: "72px",
                                          }}
                                          value="line"
                                          aria-label="Center">
                                          <IconLabelGroup>
                                            {" "}
                                            <DrawLine />
                                            <IconLabel>Line</IconLabel>
                                          </IconLabelGroup>
                                        </ToggleButton>
                                        <ToggleButton
                                          style={{
                                            border: "0",
                                            borderRight: "1px solid #e0e0e0",
                                            minWidth: "72px",
                                          }}
                                          value="shapes"
                                          aria-label="Right">
                                          <IconLabelGroup>
                                            {" "}
                                            <DrawRect />
                                            <IconLabel>Shapes</IconLabel>
                                          </IconLabelGroup>
                                        </ToggleButton>
                                        <ToggleButton
                                          style={{
                                            border: "0",
                                            borderRadius: "0",
                                            minWidth: "72px",
                                          }}
                                          value="grids"
                                          aria-label="Right">
                                          <IconLabelGroup>
                                            {" "}
                                            <Grid />
                                            <IconLabel>Grids</IconLabel>
                                          </IconLabelGroup>
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </GroupedDrawButtons>
                                    {this.state.maintool === "grids" ? (
                                      <GroupedDrawButtonsBottom>
                                        <ToggleButtonGroup
                                          value={this.state.tool}
                                          exclusive
                                          onChange={this.handleSubSelectTool}
                                          aria-label="Text Alignment"
                                          style={{ flexWrap: "wrap" }}>
                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRight: "1px solid #e0e0e0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="grid"
                                            aria-label="Left">
                                            <IconLabelGroup>
                                              {" "}
                                              <Grid />
                                              <IconLabel>Simple</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>
                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRight: "1px solid #e0e0e0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="golden"
                                            aria-label="Center">
                                            <IconLabelGroup>
                                              {" "}
                                              <Spiral />
                                              <IconLabel>Spiral</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>

                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRight: "1px solid #e0e0e0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="phi"
                                            aria-label="Right">
                                            <IconLabelGroup>
                                              <Phi />
                                              <IconLabel>phi</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>
                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="angle"
                                            aria-label="Right">
                                            <IconLabelGroup>
                                              <Diagonal />
                                              <IconLabel>Angle</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </GroupedDrawButtonsBottom>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.maintool === "shapes" ? (
                                      <GroupedDrawButtonsBottom>
                                        <ToggleButtonGroup
                                          style={{ flexWrap: "wrap" }}
                                          value={this.state.tool}
                                          exclusive
                                          onChange={this.handleSubSelectTool}
                                          aria-label="Text Alignment">
                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRight: "1px solid #e0e0e0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="rect"
                                            aria-label="Left">
                                            <IconLabelGroup>
                                              {" "}
                                              <DrawRect />
                                              <IconLabel>Rect</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>
                                          <ToggleButton
                                            style={{
                                              border: "0",
                                              borderRight: "1px solid #e0e0e0",
                                              borderRadius: "0",
                                              minWidth: "72px",
                                            }}
                                            value="circ"
                                            aria-label="Center">
                                            <IconLabelGroup>
                                              {" "}
                                              <DrawCirc />
                                              <IconLabel>Circle</IconLabel>
                                            </IconLabelGroup>
                                          </ToggleButton>
                                        </ToggleButtonGroup>
                                      </GroupedDrawButtonsBottom>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.line_show_options ? (
                                      <SectionContent>
                                        {" "}
                                        <SectionGroupHeader>
                                          Line Options
                                        </SectionGroupHeader>
                                        <FormControl component="fieldset">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.line_options[
                                                      "show_dash"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeLineMarks
                                                  }
                                                  name="show_dash"
                                                  id="show_dash"
                                                />
                                              }
                                              label="Show Perpendicular Line Marks"
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <ControlContentGrid>
                                          <SubControl>
                                            <FormControl variant="outlined">
                                              <TextField
                                                size="small"
                                                id="segments"
                                                type="number"
                                                InputProps={{
                                                  inputProps: {
                                                    min: 1,
                                                    max: 50,
                                                  },
                                                }}
                                                label="Segments"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                style={{ width: "90px" }}
                                                variant="outlined"
                                                value={
                                                  this.state.line_options[
                                                    "segments"
                                                  ]
                                                }
                                                onChange={this.onChangeLineSegs}
                                              />
                                            </FormControl>
                                          </SubControl>
                                        </ControlContentGrid>
                                      </SectionContent>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.grid_show_options ? (
                                      <SectionContent>
                                        {" "}
                                        <SectionGroupHeader>
                                          Grid Options
                                        </SectionGroupHeader>
                                        <FormControl component="fieldset">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.grid_expand
                                                  }
                                                  onChange={
                                                    this.onChangeGridCheck
                                                  }
                                                  name="expand"
                                                  id="grid_expand"
                                                />
                                              }
                                              label="Expand Grid to Image Edges"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.grid_square
                                                  }
                                                  onChange={
                                                    this.onChangeGridCheck
                                                  }
                                                  name="square"
                                                  id="grid_square"
                                                />
                                              }
                                              label="Square Grid Cells"
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        <ControlContentGrid>
                                          <SubControl>
                                            <FormControl variant="outlined">
                                              <TextField
                                                size="small"
                                                id="grid_columns"
                                                type="number"
                                                InputProps={{
                                                  inputProps: {
                                                    min: 1,
                                                    max: 50,
                                                  },
                                                }}
                                                label="Columns"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                style={{ width: "90px" }}
                                                variant="outlined"
                                                value={this.state.grid_columns}
                                                onChange={this.onChangeGrid}
                                              />
                                            </FormControl>
                                          </SubControl>
                                          <SubControlSpacer2></SubControlSpacer2>

                                          <SubControlSpacer></SubControlSpacer>
                                          <SubControl>
                                            <FormControl variant="outlined">
                                              <TextField
                                                size="small"
                                                id="grid_rows"
                                                type="number"
                                                InputProps={{
                                                  inputProps: {
                                                    min: 1,
                                                    max: 50,
                                                  },
                                                }}
                                                disabled={
                                                  this.state.grid_square
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                label="Rows"
                                                style={{ width: "90px" }}
                                                variant="outlined"
                                                value={this.state.grid_rows}
                                                onChange={this.onChangeGrid}
                                              />
                                            </FormControl>
                                          </SubControl>
                                        </ControlContentGrid>
                                      </SectionContent>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.golden_show_options ? (
                                      <SectionContent>
                                        {" "}
                                        <SectionGroupHeader>
                                          Golden Spiral Options
                                        </SectionGroupHeader>
                                        <FormControl component="fieldset">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.golden_options[
                                                      "expand"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeGoldenCheck
                                                  }
                                                  name="expand"
                                                  id="expand"
                                                />
                                              }
                                              label="Expand Grid to Image Edges"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.golden_options[
                                                      "golden_lock"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeGoldenCheck
                                                  }
                                                  name="square"
                                                  id="golden_lock"
                                                />
                                              }
                                              label="Lock Spiral to Golden Ratio"
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        {/* mmm */}
                                        <SectionGroupHeader>
                                          Rotate and Flip
                                        </SectionGroupHeader>
                                        <GroupedButtons>
                                          {" "}
                                          <Button
                                            disableElevation
                                            variant="contained"
                                            disabled={
                                              this.state.objSelect
                                                ? false
                                                : true
                                            }
                                            color="primary"
                                            onClick={() => {
                                              this.rotateGolden("rotate");
                                            }}>
                                            <Rotate90DegreesCcwIcon
                                              style={{ fontSize: "20px" }}
                                            />
                                          </Button>
                                          <ButtonSpacer />
                                          <Button
                                            disableElevation
                                            variant="contained"
                                            disabled={
                                              this.state.objSelect
                                                ? false
                                                : true
                                            }
                                            color="primary"
                                            onClick={() => {
                                              this.rotateGolden("flip");
                                            }}>
                                            <FlipOutlinedIcon
                                              style={{ fontSize: "20px" }}
                                            />
                                          </Button>
                                        </GroupedButtons>
                                      </SectionContent>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.angle_show_options ? (
                                      <SectionContent>
                                        {" "}
                                        <SectionGroupHeader>
                                          Angle Grid Options
                                        </SectionGroupHeader>
                                        <FormControl component="fieldset">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "expand"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="expand"
                                                  id="expand"
                                                />
                                              }
                                              label="Expand Grid to Image Edges"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "golden_lock"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="square"
                                                  id="golden_lock"
                                                />
                                              }
                                              label="Lock Grid to Golden Ratio"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "baroque"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="baroque"
                                                  id="baroque"
                                                />
                                              }
                                              label="Show baroque diagonal"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "sinister"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="sinister"
                                                  id="sinister"
                                                />
                                              }
                                              label="Show sinister diagonal"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "reciprocal"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="reciprocal"
                                                  id="reciprocal"
                                                />
                                              }
                                              label="Show reciprocal lines"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "horizontal"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="horizontal"
                                                  id="horizontal"
                                                />
                                              }
                                              label="Show horizontal lines"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.angle_options[
                                                      "vertical"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeAngleCheck
                                                  }
                                                  name="vertical"
                                                  id="vertical"
                                                />
                                              }
                                              label="Show vertical lines"
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        {/* aaa */}
                                      </SectionContent>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.phi_show_options ? (
                                      <SectionContent>
                                        {" "}
                                        <SectionGroupHeader>
                                          Golden Ratio Grid (Phi) Options
                                        </SectionGroupHeader>
                                        <FormControl component="fieldset">
                                          <FormGroup>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.golden_options[
                                                      "expand"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeGoldenCheck
                                                  }
                                                  name="expand"
                                                  id="expand"
                                                />
                                              }
                                              label="Expand Grid to Image Edges"
                                            />
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  disableRipple
                                                  color="primary"
                                                  checked={
                                                    this.state.golden_options[
                                                      "golden_lock"
                                                    ]
                                                  }
                                                  onChange={
                                                    this.onChangeGoldenCheck
                                                  }
                                                  name="square"
                                                  id="golden_lock"
                                                />
                                              }
                                              label="Lock Spiral to Golden Ratio"
                                            />
                                          </FormGroup>
                                        </FormControl>
                                        {/* mmm */}
                                        <SectionGroupHeader>
                                          Rotate and Flip
                                        </SectionGroupHeader>
                                        <GroupedButtons>
                                          {" "}
                                          <Button
                                            disableElevation
                                            variant="contained"
                                            disabled={
                                              this.state.objSelect
                                                ? false
                                                : true
                                            }
                                            color="primary"
                                            onClick={() => {
                                              this.rotateGolden("rotate");
                                            }}>
                                            <Rotate90DegreesCcwIcon
                                              style={{ fontSize: "20px" }}
                                            />
                                          </Button>
                                          <ButtonSpacer />
                                          <Button
                                            disableElevation
                                            variant="contained"
                                            disabled={
                                              this.state.objSelect
                                                ? false
                                                : true
                                            }
                                            color="primary"
                                            onClick={() => {
                                              this.rotateGolden("flip");
                                            }}>
                                            <FlipOutlinedIcon
                                              style={{ fontSize: "20px" }}
                                            />
                                          </Button>
                                        </GroupedButtons>
                                      </SectionContent>
                                    ) : (
                                      ""
                                    )}
                                    <Alignlineitem>
                                      <BorderColorIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </BorderColorIcon>
                                      <AlignLabel>Color</AlignLabel>
                                      <ToggleButtonGroup
                                        value={this.state.draw_color}
                                        exclusive
                                        onChange={this.ToggleColorLine}
                                        aria-label="Text Alignment"
                                        style={{
                                          height: "32px",
                                        }}>
                                        <ToggleButton
                                          value="#008EFF"
                                          aria-label="Left">
                                          <Colordot color="#008EFF"></Colordot>
                                        </ToggleButton>

                                        <ToggleButton
                                          value="#EA1B65"
                                          aria-label="Right">
                                          <Colordot color="#EA1B65"></Colordot>
                                        </ToggleButton>
                                        <ToggleButton
                                          value="#f7cd00"
                                          aria-label="Right">
                                          <Colordot color="#f7cd00"></Colordot>
                                        </ToggleButton>
                                        <ToggleButton
                                          value="#111"
                                          aria-label="Center">
                                          <Colordot color="#111"></Colordot>
                                        </ToggleButton>
                                        <ToggleButton
                                          value="#fff"
                                          aria-label="Right">
                                          <WhiteColordot color="#fff"></WhiteColordot>
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <LineWeightIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </LineWeightIcon>
                                      <AlignLabel>Stroke</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={1}
                                          value={this.state.line_thickness}
                                          min={1}
                                          max={10}
                                          name="line_thickness"
                                          onChange={this.changeSliderLine}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_thickness"
                                        value={this.state.line_thickness}
                                        onChange={this.onChangeLine}
                                      />
                                    </Alignlineitem>
                                    <Alignlineitem>
                                      <OpacityIcon>
                                        {" "}
                                        <Resize style={{ fontSize: "20px" }} />
                                      </OpacityIcon>
                                      <AlignLabel>Opacity</AlignLabel>
                                      <AlignSlider>
                                        <Slider
                                          size="small"
                                          defaultValue={100}
                                          value={this.state.line_opacity}
                                          min={0}
                                          max={100}
                                          name="line_opacity"
                                          onChange={this.changeSliderLine}
                                        />
                                      </AlignSlider>
                                      <AlignInput
                                        id="line_opacity"
                                        value={this.state.line_opacity}
                                        onChange={this.onChangeLine}
                                      />
                                    </Alignlineitem>
                                    <Stack
                                      spacing={1}
                                      sx={{ marginTop: "4px" }}>
                                      <Button
                                        onClick={() => {
                                          this.deleteLine();
                                        }}
                                        disableElevation
                                        disabled={!this.state.objSelect}
                                        // size="small"
                                        variant="contained"
                                        // color="toolbarbutton"
                                        startIcon={<BackspaceIcon />}>
                                        Erase Selected
                                      </Button>
                                      <Button
                                        onClick={() => {
                                          this.deleteAllLines();
                                        }}
                                        // size="small"
                                        disableElevation
                                        variant="contained"
                                        color="error"
                                        startIcon={<DeleteForeverIcon />}>
                                        Clear All
                                      </Button>
                                    </Stack>
                                  </SectionContent>
                                  {this.state.element_type === "CO" ? (
                                    <SectionHelpText>
                                      You can draw on top of either of the
                                      images in side by side mode. In Overlay
                                      mode you can only draw on image A (left).
                                    </SectionHelpText>
                                  ) : (
                                    <SectionHelpText>
                                      You can draw on top of the image.
                                    </SectionHelpText>
                                  )}

                                  <SectionFooter>
                                    <ButtonContainer>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="black"
                                        style={{ minWidth: "80px" }}
                                        onClick={() => {
                                          this.onSlidebackCancel(16);
                                        }}>
                                        Cancel
                                      </Button>
                                      <Button
                                        disableElevation
                                        variant="contained"
                                        color="primary"
                                        style={{
                                          minWidth: "80px",
                                          marginLeft: "auto",
                                        }}
                                        onClick={() => {
                                          this.onSlideback();
                                        }}>
                                        Apply
                                      </Button>
                                    </ButtonContainer>
                                  </SectionFooter>
                                </SectionContent>
                              ),
                            }[this.state.paneltwo]
                          }
                        </PanelTwo>
                        {/* <PanelThree></PanelThree> */}
                      </PanelContainer>
                    </CropPanel>
                    {this.state.paneltwo !== 0 ? (
                      ""
                    ) : (
                      <StyledButtonContainer w={this.state.panelW}>
                        {this.state.form_new ? (
                          ""
                        ) : (
                          <StyledLeftButtons
                            isMobile={dimensions.window_dimensions.isMobile}>
                            <StyledButtonDelete>
                              <Button
                                disableElevation
                                id="basic-button"
                                variant="contained"
                                color="black"
                                aria-controls={open ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                onClick={this.handleShowOptionsMenu}
                                endIcon={<KeyboardArrowDownIcon />}
                                style={{ minWidth: "128px" }}>
                                Options
                              </Button>
                              <Menu
                                id="basic-menu"
                                anchorEl={this.state.anchorEl}
                                open={open}
                                onClose={this.handleCloseOptionsMenu}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}>
                                <MenuItem
                                  onClick={this.handleShowConfirmation}
                                  disableRipple>
                                  <DeleteForeverIcon
                                    style={{ marginRight: "10px" }}
                                  />
                                  Delete
                                </MenuItem>
                                <MenuItem
                                  onClick={this.handleDuplicateElement}
                                  disableRipple>
                                  <FileCopyIcon
                                    style={{ marginRight: "10px" }}
                                  />
                                  Duplicate
                                </MenuItem>
                              </Menu>
                            </StyledButtonDelete>
                            {/* <StyledButtonDelete>
                  <Button
                    disableElevation
                    variant="outlined"
                    color="primary"
                    style={{ minWidth: "128px" }}
                    onClick={() => {
                      this.handleReorder();
                    }}
                  >
                    Re-order Elements
                  </Button>
                
                </StyledButtonDelete> */}
                          </StyledLeftButtons>
                        )}
                        {this.state.form_new ? (
                          <StyledButton>
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              style={{ minWidth: "128px" }}
                              onClick={() => {
                                this.savePage();
                              }}>
                              SAVE
                            </Button>
                          </StyledButton>
                        ) : (
                          <StyledButton>
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              style={{
                                minWidth: "128px",
                                borderRadius: "16px",
                              }}
                              onClick={() => {
                                this.savePage();
                              }}>
                              Save
                            </Button>
                          </StyledButton>
                        )}
                      </StyledButtonContainer>
                    )}
                  </ColumnRight>
                </SettingsColumns>
              </ElementCard>
            </TopContainer>
          </ElementContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  element: state.project.current_project.elements.byID[ownProps.id],
  element_types: state.project.current_project.element_types,
  dimensions: state.dimensions,
  selectedElementType: state.ui.selectedElementType,
  dialog_type: state.project.dialog_type,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(withRouter(ElementPopupEdit));
