import React, { Component } from "react";
import styled from "@emotion/styled";

class Line extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
  

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
      const w = obj.xEnd - obj.xStart;
      const h = obj.yEnd - obj.yStart;

      const angle = Math.atan2(h, w);
     
      // console.log(angle);
      const dash_length = 1 + 0.2 * (obj.sz - 1);
      let dashlines = [
        <line
          key={obj.id + "dash_start"}
          x1={obj.xEnd + Math.sin(angle) * dash_length}
          y1={obj.yEnd - Math.cos(angle) * dash_length}
          x2={obj.xEnd - Math.sin(angle) * dash_length}
          y2={obj.yEnd + Math.cos(angle) * dash_length}
          stroke={obj.bg}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op + "%"}
        />,
        <line
          key={obj.id + "dash_end"}
          x1={obj.xStart + Math.sin(angle) * dash_length}
          y1={obj.yStart - Math.cos(angle) * dash_length}
          x2={obj.xStart - Math.sin(angle) * dash_length}
          y2={obj.yStart + Math.cos(angle) * dash_length}
          stroke={obj.bg}
          strokeWidth={linethickness2}
          strokeOpacity={obj.op + "%"}
        />,
      ];
      for (let i = 1; i < obj.options.segments; i++) {
    
        const x = obj.xStart + (w / obj.options.segments) * i;
        const y = obj.yStart + (h / obj.options.segments) * i;
        const line = (
          <line
            key={obj.id + "ll" + i}
            x1={x + Math.sin(angle) * dash_length}
            y1={y - Math.cos(angle) * dash_length}
            x2={x - Math.sin(angle) * dash_length}
            y2={y + Math.cos(angle) * dash_length}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op + "%"}
            stroke={obj.bg}
          />
        );
        dashlines.push(line);
      }
      return (
        <g key={obj.id + "dd"}>
          {obj.id === objSelect ? (
            <g key={obj.id + "dd1"}>
              <line
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              
                x1={obj.xStart}
                y1={obj.yStart}
                x2={obj.xEnd}
                y2={obj.yEnd}
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
                strokeOpacity="0%"
              />
              <line
                x1={obj.xStart}
                y1={obj.yStart}
                x2={obj.xEnd}
                y2={obj.yEnd}
                strokeWidth={linethickness2}
                stroke={obj.bg}
                strokeOpacity={obj.op + "%"}
              />
              {obj.options.show_dash && <g key={obj.id + "dsl"}>{dashlines}</g>}

              <rect
             
                x={obj.xStart - dotx}
                y={obj.yStart - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={obj.xEnd - dotx}
                y={obj.yEnd - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
           
              >
              <line
                x1={obj.xStart}
                y1={obj.yStart}
                x2={obj.xEnd}
                y2={obj.yEnd}
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
                strokeOpacity="0%"
              />
              <line
                x1={obj.xStart}
                y1={obj.yStart}
                x2={obj.xEnd}
                y2={obj.yEnd}
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op + "%"}
              />
              {obj.options.show_dash && <g key={obj.id + "dl"}>{dashlines}</g>}
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default Line;
