import { CONSTANTS } from "../actions";
const initialState = {};
export default function errors(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.GET_MY_CURRENT_PROFILE:
    case CONSTANTS.CLEAR_ALL_ERRORS:
      return {}; // clearing errors
    case CONSTANTS.GET_ERRORS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
