import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { ColumnMiddle } from "../styles/MenuComponents";
import { connect } from "react-redux";
import NavBar2 from "./navbar.js"
import { Ribbons } from "../marketing_pages/Ribbon.js";

import Footer from "../marketing_pages/Footer.js";
import { StyledBackGround, CenterColumn, Columns, LeftColumn } from "./styles"
import {
  ContentWrapper,
  HeaderSection,

} from "../marketing_pages/MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";

class Cookies extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    window.scrollTo(0, 0);
    setTitle(`${site_name} | About Cookies`)
  }

  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        {/* <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <StyledBackGround>

          <Columns isMobile={this.props.isMobile}>
            <LeftColumn isMobile={this.props.isMobile}>
              <NavBar2 />
            </LeftColumn>
            <CenterColumn>
              <h1>Cookie Notice</h1>



              <p>
                Made uses cookies and other tracking technologies to recognize
                you when you visit our services,{" "}
                <a href="https://www.asitsmade.com">www.asitsmade.com</a>
                , or any website or mobile application owned, operated or controlled
                by us (collectively, the “Services”). This cookie notice explains
                what these technologies are and why we use them, as well as your
                rights to control our use of them.
              </p>
              <p>
                We may use cookies and other tracking technologies described in this
                Cookie Statement to collect Personal Information, or to collect
                information that becomes Personal Information if combined with other
                information. For more information about how we process your Personal
                Information on our Services, please read our Privacy Policy.
              </p>
              <h3>What Are Cookies?</h3>
              <p>
                Cookies are small data files that are placed on your computer or
                mobile device when you visit a website. Cookies are sent back to our
                website to allow us to recognize your device. Many major web
                services use cookies to provide useful features for their users.
              </p>
              <p>
                Cookies set by the website owner (in this case, Made) are called
                “first party cookies”. Cookies set by parties other than the website
                owner are called “third party cookies”. Third party cookies enable
                third party features or functionality on or through the website,
                such as advertising, interactive content, and analytics. The parties
                that set these third party cookies can recognize your computer both
                when it visits the website in question and also when it visits
                certain other websites.
              </p>
              <h3>Why Do We Use Them?</h3>
              <p>
                We use first party and third party cookies for several reasons. Some
                cookies are required for our Services to operate, and we refer to
                these as “essential” cookies. We also use cookies to tailor the
                content and information we may send or display to you and
                personalize your experience while interacting with our Services.
                Third parties serve cookies through our Services for advertising,
                analytics, and other purposes.
              </p>
              <p>
                Our Services use the following types of cookies for the purposes set
                out below:
                <br />
              </p>
              <p>
                <strong>Essential Website Cookies</strong>
              </p>
              <p>
                These cookies are strictly necessary to provide you with services
                available through our Services and to use certain features and
                functionality. You can block or delete these cookies by changing
                your browser settings, as described under the heading "How can I
                control cookies?" in the Cookie Statement. But, these cookies are
                strictly necessary to deliver our Services to you and you cannot
                refuse them without impacting how our Services function.
              </p>
              <p>
                <strong>
                  On Our Website{" "}
                  <a href="https://www.asitsmade.com">www.asitsmade.com</a>
                </strong>
              </p>
              <p>Vendor - Made (Cookies and Local Storage)</p>
              <ul>
                <ul>
                  <ul>
                    <li>refresh_token</li>
                    <li>__anon_id</li>
                    <li>access_token</li>
                    <li>csrftoken</li>
                    <li>_grecaptcha</li>
                  </ul>
                </ul>
              </ul>


              <p>
                <strong>Analytics and Customization Cookies</strong>
              </p>
              <p>
                These cookies collect information that is used to help us understand
                how our Services are being used, or to help us customize our
                Services to enhance your experience. The information gathered does
                not identify any individual visitor. The information is aggregated
                and therefore anonymous, and we use this it to help operate our
                Services more efficiently, to gather broad demographic information,
                and to monitor the level of activity on our Services.
              </p>
              <p>
                <strong>
                  On Our Website{" "}
                  <a href="https://www.asitsmade.com/">www.asitsmade.com</a>
                </strong>
              </p>
              <p>Vendor - Google (Cookies)</p>
              <ul>
                <ul>
                  <ul>
                    <li>1P_JAR</li>
                    <li>_ga</li>
                    <li>_ga_id</li>

                  </ul>
                </ul>
              </ul>

              <h3>How Can I Control Cookies?</h3>
              <p>
                You have the right to decide whether to accept or reject cookies.
                Here are a few ways you can manage cookies.
                <br />
              </p>
              <p>
                TrustArc Preference Manager: Manage your advertising cookie
                preferences for certain companies with{" "}
                <a href="https://preferences-mgr.truste.com/" target="_blank">
                  TrustArc's preference center
                </a>
                .
              </p>
              <p>
                Browser Controls: You can set your web browser controls to accept or
                refuse cookies. If you choose to refuse cookies, you may have
                limited or impaired functionality of our applications. These
                controls vary from browser-to-browser, so visit your browser's help
                topics for more information.
              </p>
              <p>
                Disabling Interest Based Advertising: Most advertising networks
                allow you to opt out of Interest Based Advertising. Visit{" "}
                <a href="https://www.aboutads.info/choices/">
                  https://www.aboutads.info/choices/
                </a>{" "}
                or{" "}
                <a href="https://www.youronlinechoices.com/">
                  https://www.youronlinechoices.com
                </a>{" "}
                for more information.
              </p>
              <p>
                Mobile Advertising: If you no longer wish to receive interest-based
                advertising on your mobile applications or mobile browser, please
                refer to your device’s operating system settings. iOS users can
                follow the instructions provided by Apple here:{" "}
                <a href="https://support.apple.com/en-us/HT202074" target="_blank">
                  Apple Support Center
                </a>
                . Android Users, follow the instructions provided by Google here:{" "}
                <a
                  href="https://support.google.com/googleplay/answer/3405269"
                  target="_blank">
                  Google Play Help
                </a>
                .
              </p>
              <p>
                Do Not Track: Our Services do not currently process or respond to Do
                Not Track (DNT) signals. We will continue to monitor developments
                around DNT browser technology and the implementation of a standard.
                To learn more about “DNT”, visit{" "}
                <a href="https://allaboutdnt.com/">All About Do Not Track</a>.
              </p>
              <h3>How Often Will You Update This Cookie Statement?</h3>
              <p>
                We will keep this Cookie Statement updated to reflect changes to the
                cookies we use or for other operational, legal, or regulatory
                reasons. Please continue to reference this page for up-to-date
                information about how we use cookies and related technologies.
              </p>
              <p>
                The date at the top of this Cookie Statement indicates when it was
                last updated.
              </p>
              <p>
                If you have any additional questions about our use of cookies or
                other technologies, please email us at{" "}
                <a href="mailto:support@asitsmade.com">support@asitsmade.com</a>
              </p>
              <p>Last Updated: January 25, 2023</p>
            </CenterColumn>
          </Columns>
        </StyledBackGround>
        <Footer />
      </ContentWrapper>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  isMobile: state.dimensions.window_dimensions.isMobile,

  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Cookies);
// export default withRouter(Privacy);
