import React, { Component } from "react";
import styled from "@emotion/styled";
import calcSVG from "../../utils/svgText";

import Line from "../drawing/Line";
import Rect from "../drawing/Rect";
import Circ from "../drawing/Circ";
import SimpleGrid from "../drawing/Grid";
import GoldenGrid from "../drawing/GoldenGrid";
import PhiGrid from "../drawing/PhiGrid";
import AngleGrid from "../drawing/AngleGrid";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const ElementImage = styled.div`
  // border-radius: 1.5%;

  // margin: 2.5%;
  width: 100%;
  height: 80%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "contain"
      : "cover"};
  // background-size: cover;
  // background-size: contain;
`;
const ElementImageOnly = styled.div`
  // border-radius: 1.5%;

  width: 100%;
  height: 100%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "contain"
      : "cover"};
`;
const CropContainer = styled.div`
  // border-radius: 1.5%;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #383838;
  overflow: hidden;
  align-items: center;
  flex: 1 1 0;
`;
const CropContainerDescr = styled.div`
  // border-radius: 1.5%;
  display: flex;
  width: 100%;
  height: 80%;
  background-color: #383838;
  overflow: hidden;
  align-items: center;
  flex: 1 1 0;
`;

const PositionContainer = styled.div`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  position: relative;
  opacity: ${(props) => props.o};
  margin: auto;
  margin-top: ${(props) =>
    props.show_full === "true" || props.show_full === true ? "auto" : props.description ? "40%" : "50%"};
  margin-left: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "auto"
      : props.w === "100%"
        ? "auto"
        : "50%"};

  transform: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "none"
      : props.w === "100%"
        ? "translate(0%, -50%)"
        : "translate(-50%, -50%)"};
`;
const ElementVideoOnly = styled.video`
  width: 100%;
  height: 100%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  object-fit: ${(props) =>
    props.show_full === "true" || props.show_full === true ? "auto" : "cover"};
  z-index: 900;
`;
const ElementVideo = styled.video`
  border-radius: 1.5%;
  margin: 2.5%;
  width: 95%;
  height: 77%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  object-fit: ${(props) => (props.show_full === "true" ? "auto" : "cover")};
`;
const ElementDescription = styled.div`
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14")}px;
  width: 95%;
  height: 15%;
  margin: 2.5%;
  text-decoration: none !important;
  color: #111;
  position: relative;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
`;
const ElementDescriptionTextOnlySVG = styled.div`
  width: 86%;
  height: 91%;
  margin: 7%;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
`;

const ElementContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Tag = styled.div`
  width: 100%;
  // height: 100%;turning this off makes it work on iOS, but resizes different on ios
  position: absolute;
  margin-right: ${(props) => (props.feedplacement ? "0" : "0")}px;
  margin-top: ${(props) => (props.feedplacement ? "0" : "0")}px;
  right: 0;
  top: 0;
  z-index: 901;
  // background-color: #ccc;
`;

const SVGContent = styled.svg`
  z-index: 805;
  // mix-blend-mode: overlay;
  position: absolute;

  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const DataOverlay = styled.div`
  z-index: 810;
  // mix-blend-mode: overlay;
  position: absolute;

  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PlayButton = styled.div`
  width: calc(${(props) => props.size}px / 4);
  height: calc(${(props) => props.size}px / 4);
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.charcoal.main};
  color: #fff;
  box-shadow: 1px 2px 7px 2px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: min(calc(${(props) => props.size}px / 5), 64px);
`

class Update extends Component {
  constructor(props) {
    super(props);
    this.bkgImage = React.createRef();
    this.svgRef = React.createRef();
    this.state = {
      ratioA: 1,
      imageWidth: "auto",
      imageWidthWrapper: "auto",
      imageHeight: "auto",
      imageHeightpx: "auto",
      visibility: "hidden",
      opacity: "0",
      viewboxA: "0 0 100 100",
      image_loaded: false,
    };
  }
  HandleImageLoaded = (number) => {
    const { show_full } = this.props;
    //console.log("loaded")
    if (number === 1) {
      const rect = this.bkgImage.current.getBoundingClientRect();

      const ratio = rect.width / rect.height;
      const viewboxA = "0 0 100 " + 100 / ratio;
      // console.log(ratio);
      this.setState({ viewboxA: viewboxA });
      this.setState({ ratioA: ratio });

      this.setImageDimensions();
      //  this.setImageDimensions()
      this.setState({ image_loaded: true });
    }
  };
  setImageDimensions() {
    const { show_full } = this.props;
    let image_w_a;
    let image_w_a_wrapper;
    let image_h_a;

    let image_w_b;
    let image_h_b;

    // console.log("id");
    // const rect = this.bkgImage.current.getBoundingClientRect();
    if (this.state.ratioA > 1) {
      if (show_full) {
        image_w_a = "100%";

        image_h_a = "auto";
      } else {
        image_w_a = "auto";

        image_h_a = "100%";
      }
    } else {
      if (show_full) {
        image_w_a = "auto";
        image_h_a = "100%";
      } else {
        image_w_a = "100%";

        image_h_a = "auto";
      }
    }

    if (this.state.imageHeight !== image_h_a) {
      //console.log("set");
      this.setState({ imageHeight: image_h_a });
    }

    if (this.state.imageWidth !== image_w_a) {
      this.setState({ imageWidth: image_w_a });
    }

    if (this.state.image_loaded) {
      if (this.state.visibility !== "visible") {
        this.setState({ visibility: "visible" });
        this.setState({ opacity: "1" });
      }
    }
  }

  componentDidUpdate() {
    const { card_height, show_full } = this.props;
    this.setImageDimensions();
  }
  render() {
    const {
      image_url,
      description,
      tag,
      color,
      feedplacement,
      show_full,
      isPlayback,
      VideoControls,
      drawing,
      size,
      card_height,
      lazyload
    } = this.props;
    let labelname = "";
    let labelcolor = "";
    switch (tag) {
      case "TL":
        labelname = "TOOL";
        labelcolor = "#EA1B65";
        break;
      case "MT":
        labelname = "MATERIAL";
        labelcolor = "#2196F3";
        break;
      case "TD":
        labelname = "TO DO";
        labelcolor = "#383838";
        break;
      case "QN":
        labelname = "QUESTION";
        labelcolor = "#f7cd00";
        break;
      default:
      // code block
    }
    // console.log(image_url)
    let loadingsetting = "eager"
    if (lazyload){
      loadingsetting = "lazy"
    }
    if (image_url) {
      if (description) {
        return (
          <ElementContainer>
            {tag ? (
              <Tag feedplacement={feedplacement}>
                <svg
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 800 800"
                  // width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    style={{
                      fill: labelcolor,
                    }}
                    d="M 600 0 L 680 0 L 840 160 L 840 240"
                  />
                  <text
                    letterSpacing="1.5"
                    fontSize="28"
                    fontFamily="Arial"
                    fill="#fff"
                    textAnchor="middle"
                    transform="translate(726,100) rotate(45)">
                    {labelname}
                  </text>
                </svg>
              </Tag>
            ) : (
              ""
            )}
            <CropContainerDescr>
              <PositionContainer
                description={description}
                w={this.state.imageWidth}
                h={this.state.imageHeight}
                o={this.state.opacity}
                show_full={show_full}>
                <SVGContent
                  viewBox={this.state.viewboxA}
                  ref={this.svgRef}>
                  <rect
                    width="100%"
                    height="100%"
                    fillOpacity="0%"
                    fill="white"></rect>

                  {drawing.map((o) => {
                    if (o.canvas === "A") {
                      if (o.type === "rect") {
                        return (
                          <Rect
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "line") {
                        return (
                          <Line
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "circ") {
                        return (
                          <Circ
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "golden") {
                        return (
                          <GoldenGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "phi") {
                        return (
                          <PhiGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "angle") {
                        return (
                          <AngleGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "grid") {
                        return (
                          <SimpleGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                    }

                    return null;
                  })}
                </SVGContent>
                <img
                  
                  alt=""
                  ref={this.bkgImage}
                  onLoad={() => {
                    this.HandleImageLoaded(1);
                  }}
                  style={{
                    width: this.state.imageWidth,
                    height: this.state.imageHeight,
                    margin: "auto",
                    marginBottom: "-4px",
                    visibility: this.state.visibility,
                  }}
                  src={image_url}
                  draggable="false"
                  loading={loadingsetting}
                />
                <DataOverlay>
                  {isPlayback ? (
                    <PlayButton size={size}>
                      <PlayArrowIcon fontSize="inherit" />
                    </PlayButton>
                  ) : null}
                </DataOverlay>
              </PositionContainer>
              {/* <ElementImageOnly
              show_full={show_full}
              style={{
                backgroundImage: "url(" + image_url + ")",
              }}></ElementImageOnly> */}
            </CropContainerDescr>

            <ElementDescription>
              <svg
                viewBox="0 0 358 50"
                width="100%"
                height="100%"
                xmlns="http://www.w3.org/2000/svg">
                <g>{calcSVG(description, 358, 17, 26, 2, 14)}</g>
              </svg>
            </ElementDescription>
          </ElementContainer>
        );
      } else {
        return (
          <ElementContainer>
            {tag ? (
              <Tag feedplacement={feedplacement}>
                <svg
                  style={{ width: "100%", height: "100%" }}
                  viewBox="0 0 800 800"
                  // width="100%"
                  // height="100%"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    style={{
                      fill: labelcolor,
                    }}
                    d="M 600 0 L 680 0 L 840 160 L 840 240"
                  />
                  <text
                    letterSpacing="1.5"
                    fontSize="28"
                    fontFamily="Arial"
                    fill="#fff"
                    textAnchor="middle"
                    transform="translate(726,100) rotate(45)">
                    {labelname}
                  </text>
                </svg>
              </Tag>
            ) : (
              ""
            )}
            <CropContainer>
              <PositionContainer
                w={this.state.imageWidth}
                h={this.state.imageHeight}
                o={this.state.opacity}
                show_full={show_full}>
                <SVGContent
                  viewBox={this.state.viewboxA}
                  ref={this.svgRef}>
                  <rect
                    width="100%"
                    height="100%"
                    fillOpacity="0%"
                    fill="white"></rect>

                  {drawing.map((o) => {
                    if (o.canvas === "A") {
                      if (o.type === "rect") {
                        return (
                          <Rect
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "line") {
                        return (
                          <Line
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "circ") {
                        return (
                          <Circ
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "golden") {
                        return (
                          <GoldenGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "phi") {
                        return (
                          <PhiGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "angle") {
                        return (
                          <AngleGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                      if (o.type === "grid") {
                        return (
                          <SimpleGrid
                            editable={false}
                            svg={this.svgRef.current}
                            drawnow={this.state.image_loaded}
                            key={o.id + "tl"}
                            obj={o}
                          />
                        );
                      }
                    }

                    return null;
                  })}
                </SVGContent>
                <img
                  alt=""
                  ref={this.bkgImage}
                  onLoad={() => {
                    this.HandleImageLoaded(1);
                  }}
                  style={{
                    width: this.state.imageWidth,
                    height: this.state.imageHeight,
                    margin: "auto",
                    marginBottom: "-4px",
                    visibility: this.state.visibility,
                  }}
                  src={image_url}
                  draggable="false"
                  loading={loadingsetting}
                />
                <DataOverlay>
                  {isPlayback ? (
                    <PlayButton size={size}>
                      <PlayArrowIcon fontSize="inherit" />
                    </PlayButton>
                  ) : null}
                </DataOverlay>
              </PositionContainer>
              {/* <ElementImageOnly
                show_full={show_full}
                style={{
                  backgroundImage: "url(" + image_url + ")",
                }}></ElementImageOnly> */}
            </CropContainer>
          </ElementContainer>
        );
      }
    } else {
      return ("")
    }
  }
}

export default Update;
