 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const Resize = (props) => {
  return (
      <SvgIcon {...props}>
<rect x="19" y="7" width="2" height="2"/>
<rect x="7" y="3" width="2" height="2"/>
<path d="M3,5h2V3C3.9,3,3,3.9,3,5z"/>
<rect x="15" y="3" width="2" height="2"/>
<rect x="3" y="7" width="2" height="2"/>
<rect x="19" y="11" width="2" height="2"/>
<rect x="19" y="15" width="2" height="2"/>
<rect x="11" y="3" width="2" height="2"/>
<path d="M21,5c0-1.1-0.9-2-2-2v2H21z"/>
<rect x="15" y="19" width="2" height="2"/>
<path d="M21,19h-2v2C20.1,21,21,20.1,21,19z"/>
<polygon points="11,7 13.3,9.3 10.3,12.3 3,12.3 3,21 11.7,21 11.7,13.7 14.7,10.7 17,13 17,7 "/></SvgIcon>
  );
};

export default Resize;

