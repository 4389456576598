import { useState, useEffect } from "react";

const useAudioPlayer = (audioElement) => {
    const [playerState, setPlayerState] = useState({
        isLoaded: false,
        isPlaying: false,
        progress: 0,
        speed: 1,
        isMuted: false,
    });

    const handleOnLoad = () => {
        setPlayerState({
            ...playerState,
            isLoaded: true
        });
    };

    const handleOnTimeUpdate = () => {
        const progress = audioElement.current.currentTime / audioElement.current.duration;
        setPlayerState({
            ...playerState,
            progress,
        });
    };

    const handleOnAudioEnded = () => {
        setPlayerState({
            ...playerState,
            isPlaying: false
        });
    };

    const handleAudioProgress = (seek) => {
        const manualChange = Number(seek);
        audioElement.current.currentTime = audioElement.current.duration * manualChange;
        setPlayerState({
            ...playerState,
            progress: manualChange,
        });
    };

    const handleAudioSpeed = (event) => {
        const speed = Number(event.target.value);
        audioElement.current.playbackRate = speed;
        setPlayerState({
            ...playerState,
            speed,
        });
    };

    const togglePlay = () => {
        setPlayerState({
            ...playerState,
            isPlaying: !playerState.isPlaying,
        });
    };

    useEffect(() => {
        playerState.isPlaying
            ? audioElement.current.play()
            : audioElement.current.pause();
    }, [playerState.isPlaying, audioElement]);

    const toggleMute = () => {
        setPlayerState({
            ...playerState,
            isMuted: !playerState.isMuted,
        });
    };

    useEffect(() => {
        playerState.isMuted
            ? (audioElement.current.muted = true)
            : (audioElement.current.muted = false);
    }, [playerState.isMuted, audioElement]);

    return {
        playerState,
        togglePlay,
        handleOnLoad,
        handleOnTimeUpdate,
        handleOnAudioEnded,
        handleAudioProgress,
        handleAudioSpeed,
        toggleMute,
    };
};

export default useAudioPlayer;