const monthYear = (date) => {
  const el_date = new Date(date);
  if (el_date !== "Invalid Date") {
    var options = { month: "long" };
    const month = new Intl.DateTimeFormat("en-US", options).format(el_date);
    const year = el_date.getFullYear();
    return month + " " + year;
  } else {
    return "";
  }

  //
};
export default monthYear;
