import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";

import { connect } from "react-redux";
import { validateEmail, resendEmail } from "../../actions/authActions";
import styled from '@emotion/styled'
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
const StyledloginContainer = styled.div`
  display: block;
  margin: 16.66667vh auto 16.66667vh auto;
  text-align: center;
`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  width: 252px;
  margin: 24px 24px 10px 24px;
  margin-left: auto;
  margin-right: auto;
`;
const StyledHeaderText = styled.div`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #111;
`;

const StyledInput = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
    font-size: 16px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;    
    width: 252px;
    background-color: #0073ec;
    color: white;
    height: 32px;
    border-radius: 2px;
  
    padding-right: 30px;
    padding-left: 30px;
    margin: 10px 24px 10px 24px;
  
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: none;
    
}
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;
class Verify extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      username: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
    };
  }
  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      email: this.state.email,
    };

    this.props.resendEmail(newUser, this.props.history);
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  componentDidMount() {
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    const token = window.location.search.substr(1);
    if (token) {
      this.props.validateEmail(token, this.props.history);
    }
    if (this.props.auth.isAuthenticated) {
      // this.props.history.push("/");
      window.location.href = "/"
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }

  render() {
    const { errors } = this.state;
    const { validation_message, validation_state } = this.props;
    switch (validation_state) {
      case 1:
        return (
          <StyledloginContainer>
            <StyledHeaderText>
              Your Email has been succesfully validated
              <br />
              <br /> Click the button below to log in.
              <br />
              <br />
            </StyledHeaderText>
            <Link to="../login/" style={{ textDecoration: "none" }}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                style={{ minWidth: "150px" }}>
                Login
              </Button>
            </Link>
          </StyledloginContainer>
        );

      case 2:
        return (
          <StyledloginContainer>
            <StyledHeaderText>
              If you already have verified your email,<br/><br/>
              click the button below to log in.
              <br />
              <br /> 
            </StyledHeaderText>
            <Link to="../login/" style={{ textDecoration: "none" }}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                style={{ minWidth: "150px" }}>
                Login
              </Button>
            </Link>
            <StyledHeaderText>
            <br />
              <br /> 
              or resend the verification email.
            </StyledHeaderText>
            <form noValidate onSubmit={this.onSubmit}>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: "3px" }}
                  size="small"
                  id="email"
                  label="Email"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </FormControl>

              <StyledError>{errors.email}</StyledError>
            </FormField>
              <StyledError>{errors.email}</StyledError>
              <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ minWidth: "252px", marginTop: '10px' }}
                      
                    >
                      Send New Email
                    </Button>
            </form>
          </StyledloginContainer>
        );

      case 4:
        return (
          <StyledloginContainer>
            <StyledHeaderText>
              Please check your inbox to verify your email
            </StyledHeaderText>
          </StyledloginContainer>
        );

      case 5:
        return (
          <StyledloginContainer>
            <StyledHeaderText>This email is already verified.<br/><br/></StyledHeaderText>
            <Link to="../login/" style={{ textDecoration: "none" }}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                style={{ minWidth: "150px" }}>
                Login
              </Button>
            </Link>
          </StyledloginContainer>
        );

      case 6:
        return (
          <StyledloginContainer>
            <StyledHeaderText>This email is not registered.</StyledHeaderText>
            <Link to="../register" style={{ textDecoration: "none" }}>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                style={{ minWidth: "150px" }}>
                Sign Up
              </Button>
            </Link>
          </StyledloginContainer>
        );

      default:
        return (
          <StyledloginContainer>
            <StyledHeaderText>
              Please check your inbox to verify your email
            </StyledHeaderText>

            <StyledError>{validation_message}</StyledError>
          </StyledloginContainer>
        );
    }
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  validation_message: state.auth.validation_message,
  validation_state: state.auth.validation_state,
  errors: state.errors,
  auth: state.auth,
});

export default connect(mapStateToProps, { validateEmail, resendEmail })(
  withRouter(Verify)
);
