import React, { Component } from "react";
import styled from '@emotion/styled'

import Update from "../elements/Update";

import Next from "../elements/Next";
import Question from "../elements/Question";
import Dimension from "../elements/Dimension";
const ElementCard = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  background-color: #fff;
  border: ${(props) =>
    props.selected ? "1px solid #87c1f0" : "1px solid #fff"};
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

class ElementThumb extends Component {
  render() {
    const {
      cardwidth,
      image_url,
      isVideo,
      show_full,
      description,
      element_type_style,
      unit,
      x,
      y,
      z,
      link_url,
      link_name,
      drawing,
      element
    } = this.props;
    console.log(show_full)
    return (
      <ElementCard cardwidth={cardwidth} selected={this.props.selected}>
        {
          {
            "UP": (
              <Update
                selected={this.props.selected}
                image_url={image_url}
                isVideo={isVideo}
                VideoControls={true}
                show_full={show_full}
                isPlayback={isVideo}
                description={description}
                link_url={link_url}
                link_name={link_name}
                preview={true}
                size={150}
                drawing={drawing}
                element={element}
              />
            ),
            
            
          }[element.element_type]
        }
      </ElementCard>
    );
  }
}

export default ElementThumb;
