import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import marketing_urls from "../../config/marketing_urls";
import { Ribbons } from "./Ribbon";
import { setMenu } from "../../actions/uiActions";
import Footer from "./Footer";
import {
  ContentWrapper,
  ContentSectionTop,
  CenterColumnSingle,
  HeaderSection,
  CustomH1,
  ScrollBox,
  ScrollList,
  ScrollItem,
  H1Text,
  CalltoAction,
  CustomP,
  ContentSection,
  CenterColumn,
  HeroImage,
  ThreeColumn,
  FeatureImage,
  FeatureImageLarge,
  FeatureTitle,
  FeatureSubTitle,
  FeatureText,
  ContentSectionGradient,
  CenterHeader,
  CenterList,
  CategoryItem,
  ContentSectionSingle,
  CenterImage,
  CenterText,
  ImageLeftSection,
  ImageLeftSectionLarge,
  ImageRightSection,
  ImageRightSectionLarge,
  LeftRightImage,
  LeftRightImageLarge,
  LeftRightText,
  ContentSectionImage,
  ImageOverlay,
  ContentSectionSample,
  SampleImageSection,
  SampleText,
  SampleImage,
  SampleSubTitle,
  SampleTextLeft
} from "./MarketingComponents";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";

class HomeMarketing extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    setTitle(`${site_name} | About Groups`)
  }
  handleWaitMenu() {
    window.location.href = "/register/";
    // console.log("wait");
    // this.props.dispatch(setMenu(true, 19));
  }
  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection>
        <ContentSectionTop>
          <CenterColumnSingle isMobile={isMobile}>
            <CustomH1 style={{ color: "#E8AD1F" }} isMobile={isMobile}>
              <ScrollBox isMobile={isMobile}>
                <ScrollList isMobile={isMobile}>
                  <ScrollItem>learn</ScrollItem>
                  <ScrollItem>follow</ScrollItem>
                  <ScrollItem>teach</ScrollItem>
                  <ScrollItem>learn</ScrollItem>
                </ScrollList>{" "}
              </ScrollBox>
              <H1Text style={{ paddingLeft: "0px", paddingRight: "0px" }}>&nbsp;as it's made</H1Text>
            </CustomH1>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
            <CustomP isMobile={isMobile}>
              {" "}
              Connect & create with people <br /> you know and trust
            </CustomP>
          </CenterColumnSingle>
        </ContentSectionTop>
        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <HeroImage>
              <img
                style={{ width: "100%" }}
                src="/marketing_images/a_groups_5.jpg"
              />
            </HeroImage>
          </CenterColumn>
        </ContentSection>

        <ContentSection color="transparent">
          <CenterColumn isMobile={isMobile}>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/groups_9.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#E8AD1F" }}>
                Share with
                <br />
                confidence
              </FeatureTitle>
              <FeatureText>
                It can be intimidating to share your work with strangers. Made
                allows you to share your work with groups of people you know.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/groups_8.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#E8AD1F" }}>
                Timely
                <br />
                feedback
              </FeatureTitle>
              <FeatureText>
                Get feedback when it is most helpful - while the project is
                still in progress. Take the anxiety out of requesting feedback
                via private groups.
              </FeatureText>
            </ThreeColumn>
            <ThreeColumn isMobile={isMobile}>
              <FeatureImage>
                <img
                  style={{ width: "100%" }}
                  src="/marketing_images/project_feature_2.png"
                />
              </FeatureImage>
              <FeatureTitle style={{ color: "#E8AD1F" }}>
                Stay in
                <br />
                control
              </FeatureTitle>
              <FeatureText>
                Groups allow you to manage who sees what. Your comments stay in
                the group so you don’t have to worry about who sees what you
                say.
              </FeatureText>
            </ThreeColumn>
          </CenterColumn>
        </ContentSection>
        {/* <ContentSectionGradient>
          <CenterColumnSingle>
            <CenterHeader color="#fff">follow your passion</CenterHeader>
            <CenterList>
              <CategoryItem>Drawing</CategoryItem>
              <CategoryItem>Painting</CategoryItem>
              <CategoryItem>Woodworking</CategoryItem>
              <CategoryItem>Digital Art</CategoryItem>
              <CategoryItem>Ceramics</CategoryItem>
              <CategoryItem>Textiles</CategoryItem>
              <CategoryItem>Crafts</CategoryItem>
              <CategoryItem>Electronics</CategoryItem>
              <CategoryItem>DIY</CategoryItem>
            </CenterList>
          </CenterColumnSingle>
        </ContentSectionGradient> */}
        {/* <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#38DF88">Curious?</CenterHeader>
            <CenterText maxW="425">
               If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection> */}
        <ContentSectionImage src={"marketing_images/Rectangle_217.jpg"}>
          <ImageOverlay isMobile={isMobile}>
            Share the creative journey with people you know
          </ImageOverlay>
        </ContentSectionImage>
        <ContentSection>
          <CenterColumnSingle>
            <CenterHeader color="#E8AD1F">Curious?</CenterHeader>
            <CenterText maxW="425">
              If you are interested in trying out Made, then sign up for an account and
              start your creative journey.
            </CenterText>
            <CalltoAction>
              {" "}
              <Button
                onClick={() => {
                  this.handleWaitMenu();
                }}
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  maxWidth: "200px",
                  minWidth: "200px",
                  maxHeight: "32px",
                  marginTop: "30px",
                }}>
                Sign Up
              </Button>
            </CalltoAction>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSectionSingle color="#f5f5f5">
          <CenterColumnSingle>
            <CenterImage color="#008eff">
              <img
                style={{ width: "100%", mixBlendMode: "multiply", marginBottom: "60px" }}
                src="/marketing_images/illustration_large_brush.png"
              />
            </CenterImage>
          </CenterColumnSingle>
        </ContentSectionSingle>

        <ContentSectionImage src={"marketing_images/Rectangle_219.jpg"}>
          <ImageOverlay isMobile={isMobile}>
            Gain creative insights by tracking the evolution of a project - from
            idea to completion
          </ImageOverlay>
        </ContentSectionImage>
        <ContentSection>
          <CenterColumnSingle>
            <ImageLeftSection isMobile={isMobile}>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "20px" }}
                  src="/marketing_images/illustration_pencils.png"
                />
              </LeftRightImage>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#E8AD1F" }}>
                  Be a mensch
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Are you an expert? Do you have skills you’d like to pass onto
                  others? Create a private group and invite members to conduct
                  lessons in a private space.
                </CenterText>
              </LeftRightText>
            </ImageLeftSection>
            <ImageRightSection isMobile={isMobile}>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#E8AD1F" }}>
                  Join a community
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Browse groups to find a topic you may be interested in and
                  request to join. Share your work with other members of the
                  group.
                </CenterText>
              </LeftRightText>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "100%", marginBottom: "20px" }}
                  src="/marketing_images/illustration_bottle.png"
                />
              </LeftRightImage>
            </ImageRightSection>
            <ImageLeftSection isMobile={isMobile}>
              <LeftRightImage>
                {" "}
                <img
                  style={{ width: "70%", marginBottom: "20px" }}
                  src="/marketing_images/illustration_tubes.png"
                />
              </LeftRightImage>
              <LeftRightText isMobile={isMobile}>
                <FeatureSubTitle style={{ color: "#E8AD1F" }}>
                  Share across multiple groups
                </FeatureSubTitle>{" "}
                <CenterText maxW={250}>
                  Create a single project and share it across multiple groups.
                </CenterText>
              </LeftRightText>
            </ImageLeftSection>
          </CenterColumnSingle>
        </ContentSection>
        <ContentSectionSample>
          <CenterColumnSingle>
            <Link
              target="_blank"
              to={"/project/" + marketing_urls.sample_projects.sunflowers + "/"}
              style={{ textDecoration: "none" }}>
              <SampleImageSection isMobile={isMobile}>

                <SampleText isMobile={isMobile}>
                  <SampleSubTitle color="#383838" isMobile={isMobile}>
                    Sample Project
                  </SampleSubTitle>{" "}
                  <SampleTextLeft isMobile={isMobile}>
                    Check out this sample oil painting project in Made.
                  </SampleTextLeft>
                </SampleText>
                <SampleImage isMobile={isMobile}>
                  <img
                    style={{ width: "100%", marginBottom: "20px" }}
                    src="/marketing_images/sample3.png"
                  />
                </SampleImage>

              </SampleImageSection>
            </Link>
          </CenterColumnSingle>
        </ContentSectionSample>
        <Footer />
        {/* {isMobile ? <BottomSpacer /> : ""} */}
      </ContentWrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(HomeMarketing));
