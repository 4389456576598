 import * as React from 'react';
 import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';

 const SvgIcon = styled(MuiSvgIcon, {
   name: 'FlipHor',
  shouldForwardProp: (prop) => prop !== 'fill',
 })(() => ({
  //  fill: 'none',
  //  stroke: 'currentColor',
//   strokeLinecap: 'round',
//   strokeLinejoin:  'round',
//  strokeWidth:  '2px',
 }));

 SvgIcon.defaultProps = {
  viewBox: '0 0 24 24',
  focusable: 'false',
  'aria-hidden': 'true',
  };

 const Spiral = (props) => {
  return (
      <SvgIcon {...props}>
<path d="M16.7,18.5C16.7,18.5,16.7,18.5,16.7,18.5c-2,0-3.6-1.6-3.6-3.6c0-1.4,1.2-2.6,2.6-2.6v2c-0.3,0-0.6,0.3-0.6,0.6
	c0,0.9,0.7,1.6,1.6,1.6c1.8,0,3.2-1.5,3.2-3.2c0-3.2-2.6-5.8-5.8-5.8c-5.5,0-10.1,4.5-10.1,10h-2c0-6.6,5.4-12,12.1-12
	c4.3,0,7.8,3.5,7.8,7.8C21.9,16.1,19.6,18.5,16.7,18.5L16.7,18.5z M16.7,18.5L16.7,18.5L16.7,18.5z"/>
</SvgIcon>
  );
};

export default Spiral;

