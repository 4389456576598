import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { api_endpoint } from "../config";
import { withRouter } from "react-router-dom";

import {
  newProject,
  uploadNewProject,
  saveProject,
  uploadSaveProject,
  deleteProject,
} from "../actions/projectActions";
import { getProjectCategories } from "../actions/profileActions";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { getProjectContent } from "../actions/projectActions";
import { getMemberships } from "../actions/profileActions";
import ProjectThumb from "./thumbs/ProjectThumb";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";
import Update from "./elements/Update";
import Next from "./elements/Next";
import ProjectChangeDateElement from "./ProjectChangeDateElement";
import Question from "./elements/Question";
import Dimension from "./elements/Dimension";
import { DragDropContext } from "react-beautiful-dnd";
import Draggable from "react-draggable";
import { saveOrder } from "../actions/projectActions";
import { element } from "prop-types";
import ElementCompare from "./ElementCompare";
import CompareIcon from "@mui/icons-material/Compare";
import ImageIcon from "@mui/icons-material/Image";
// import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "../utils/react-compare-slider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import HeightIcon from "@mui/icons-material/Height";
import ExpandIcon from "@mui/icons-material/Expand";
// import cv from "@techstark/opencv-js";
import injectScript from "../utils/injectScript";
// window.cv = cv;
const ImageSet = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;
const SingleImage = styled.div`
  padding: 3px;
  border: 1px solid #ccc;
  margin: 2px;
  height: 75px;
  cursor: pointer;
`;

const UploadButtonsSingle = styled.div`
  margin-left: 100px;

  margin-top: 125px;
  width: 140px;
`;

const ElementPanel = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 3000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // max-height: 100%;
  // width: 100%;
`;
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "50px")};
  margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "100%")};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
`;

const ElementCard = styled.div`
  // width: ${(props) => (props.isMobile ? "100%" : "calc(100% - 160px)")};
  width: ${(props) => (props.isMobile ? "100%" : "calc(100% - 160px)")};
  // max-width: calc(100% - 30px);
  height: ${(props) => (props.isMobile ? "100%" : "auto")};
  min-height: 800px;
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: ${(props) => (props.isMobile ? "0px" : "5px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-top: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "40px")};
  display: flex;
  flex-direction: column;
`;
const ElementCardWide = styled.div`
  width: calc(100% - 40px);
  min-height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;
const StyledButtonDelete = styled.div`
   
    margin-top: 20px;
  
    
}
`;
const FormLabelCustom = styled.div`
   
   font-size: 14px;
   color: rgba(0, 0, 0, 0.54);
   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  
    
}
`;
const StyledButton = styled.div`
    
  
    
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
}
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ColumnLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "15px")};
  width: ${(props) => (props.isMobile ? "100%" : "360px")};
  padding-top: 24px;
  height: 100%;
`;
const ColumnRight = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "270px")};
  //   height: 100%;

  padding-left: ${(props) => (props.isMobile ? "0px" : "15px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  //   background-color: #dadada;
`;

const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "30px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% )")};
  display: flex;
`;
const PreviewContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100%)")};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 7px;
  margin-left: 20px;
  margin-bottom: 57px;
`;
const ControlLabelPreview = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  margin-bottom: 12px;
  margin-left: -17px;
`;
const ThumbContent = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -15px;
  margin-left: -17px;
`;
const SettingsHeader = styled.div`
  display: flex;
`;
const SettingsColumns = styled.div`
  display: flex;
  flex-direction: row;

  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: ${(props) => (props.isMobile ? "none" : "4px")};
  width: ${(props) => (props.isMobile ? "calc(100% + 60px)" : "100%")};
  margin-left: ${(props) => (props.isMobile ? "-31px" : "none")};
  height: 100%;
`;

const SliderWrapper = styled.div`
  // min-height: 800px;
  display: flex;
`;
const SliderContainer = styled.div`
  min-height: ${(props) => props.maxH}px;
  padding: 10px;
  position: relative;
  width: 100%;
  background-color: #383838;
  display: flex;
  // align-items: normal;
`;
const SliderHandleContainer = styled.div`
  height: 100%;
  width: 30px;
  z-index: 2000;
  position: absolute;
  left: 50%;
  // background-color: #ffffff;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
`;

const SliderCircle = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  place-content: center;
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  border-color: #ffffff;
  pointer-events: auto;
  backdrop-filter: blur(7px);
  //     position: absolute;
  // top: 50%;
  margin-left: -14px;
  box-shadow: 0 0 7px rgba(0;0;0;.35);
  // transform: portrait ? rotate(90deg) : undefined;
`;
const SliderArrow2 = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;
  transform: rotate(180deg);
  color: #ffffff;
`;
const SliderArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 8px solid transparent;
  border-right: 10px solid;
  border-bottom: 8px solid transparent;

  color: #ffffff;
`;
const SliderHandle = styled.div`
  // flexGrow: 1;
  height: 50%;
  width: 2px;
  background-color: #ffffff;
  pointer-events: auto;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

  margin-left: 14px;

  // position: absolute;
  // left: 50%;
`;
const CanvasContainer = styled.div`
  position: ${(props) => (props.overlay ? "absolute" : "relative")};
  left: ${(props) => (props.overlay ? "50%" : "auto")};
  transform: ${(props) =>
    props.overlay ? " translateX(-50%) translateY(-50%)" : "auto"};
  top: ${(props) => (props.overlay ? "50%" : "auto")};

  // max-width: 750px;
  // max-height: 800px;
  margin-bottom: auto;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  
`;
const StyledCanvasA = styled.canvas`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
 
  // // max-width: 750px;
  // // max-height: 800px;
  // margin-bottom: auto;
  // margin-top: auto;
  // margin-left:auto;
  // margin-right: auto;
`;

const StyledCanvasSide = styled.canvas`
  opacity: 1;

  max-height: 800px;
`;

const StyledSliders = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const CompareLeft = styled.div`
  padding-left: ${(props) => (props.isMobile ? "2px" : "15px")};
  padding-right: ${(props) => (props.isMobile ? "0px" : "15px")};
  width: ${(props) =>
    props.isMobile ? "100%" : props.optimizewidth ? "50%" : "auto"};
  padding-top: ${(props) => (props.isMobile ? "1px" : "0px")};

  min-height: 100%;
  border-right: 1px solid #ccc;

  display: flex;
  flex-direction: column;
`;
const CompareRight = styled.div`
  width: ${(props) =>
    props.isMobile ? "100%" : props.optimizewidth ? "50%" : "auto"};
  height: 100%;
  padding-top: ${(props) => (props.isMobile ? "1px" : "0px")};
  padding-left: ${(props) => (props.isMobile ? "2px" : "15px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "15px")};

  display: flex;
  flex-direction: column;
`;
const BackButton = styled.div`
  height: 30px;
  margin-top: 5px;
`;
const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  margin-left: auto;
  margin-right: auto;
  width: ${(props) => (props.isMobile ? "112px" : "auto")};
  height: fit-content;
`;

const SettingsTitle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
`;
const CoverImage = styled.div`
  width: 100%;
  height: 290px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  margin-top: 10px;
`;
const CoverImageSmall = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  margin-top: 8px;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButtonsCondensed = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-top: 3px;
  height: 90px;
}
`;
const Ortext = styled.div`
  text-align: center;
  margin-top: 20px;
  width: 100%;
`;
const OrtextC = styled.div`
  text-align: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 0px;
  width: 100%;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-top: 20px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
`;
const CloseButtonNarrow = styled.div`
  font-size: 20px;
  color: #757575;
  margin-top: ${(props) => (props.isMobile ? "-15px" : "-20px")};
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "-10px" : "-15px")};

  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const PopupDialog = styled.div`
  max-width: 300px;
  height: calc(100% - 30px);
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const DialogInstruction = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  line-height: 20px;
  margin-bottom: 20px;
`;
const Toolbar = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
  display: flex;
  line-height: 20px;
  margin-bottom: 20px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const StyledButtonLeft = styled.div`
margin-left: 20px;
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
  padding-right: 20px;
  margin-top: 20px;
  margin-left: auto;
`;
const StyledDate = styled.div`
  width: calc(100% - 40px);

  margin-left: 10px;
`;
const SmallElement = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.isMobile ? "40" : "100")}px;
  height: ${(props) => (props.isMobile ? "40" : "100")}px;
  background-color: #fff;
  border: 1px solid #ccc;

  border-radius: 1.5%;
  margin-left: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-right: ${(props) => (props.isMobile ? "2" : "5")}px;
  margin-top: 5px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  margin-bottom: 5px;
  //   display: flex;
  //   flex-direction: column;
  position: static;
  clip-path: polygon(0 0 , 50% 0, 50% 100%, 0 100%);
`;
const ElementLine = styled.div`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  //   border-top: 1px solid #ccc;
  //   border-bottom: 1px solid #ccc;
  padding-top: 1px;
  padding-bottom: 2px;
  margin-top: 5px;
  margin-bottom: 5px;

  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0);
  display: flex;
  &:hover {
    background-color: #fff;
    border: 1px solid #2196f3;
  }
`;
const ElementNumber = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 130%;

  // width: 80px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 30px;
  color: #c4c4c4;
`;
const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 20px;
`;
const GroupLine = styled.div`
  border-bottom: 1px solid #2196f3;
  width: 100%;
`;
const GroupText = styled.div`
  background-color: #2196f3;
  margin-left: auto;
  margin-right: auto;
  margin-top: -16px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 32px;
  border-radius: 7px;
`;
const StyledDraghandle = styled.div`
  width: 95px;
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  margin-left: 0px;
  padding-top: 30px;
`;
const DragDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 1px;
  margin-left: auto;
  margin-right: 30px;
`;
const MyCustomHandle = () => (
  <div style={{ width: "5px", height: "100%", backgroundColor: "red" }}></div>
);

const OPENCV_URL = "/opencv/opencv.js";

class ProjectCompare extends Component {
  constructor(props) {
    super(props);
    this.canvasB = React.createRef();
    this.canvasA = React.createRef();
    this.drawcanvasB = React.createRef();
    this.drawcanvasA = React.createRef();
    this.nodeRefA = React.createRef(null);
    this.loadOpenCv();
    this.state = {
      resetEdit: true,
      firstSelected: "",
      secondSelected: "",
      imageA: new Image(),
      imageB: new Image(),
      cA: null,
      cB: null,
      selectFirst: true,
      selectSecond: true,
      showOverlay: false,
      opacityvalue: 100,
      opacitystylevalue: 1,
      maxW: 0,
      maxH: 0,
      imageBWidth: 1,
      imageBHeight: 1,
      imageAWidth: 1,
      imageAHeight: 1,
      imageARatio: 1,
      imageBRatio: 1,
      scalevalue: 100,
      clip: 50,
      scalestylevalue: "scale(1)",
      horvalue: 0,
      horstylevalue: 0,
      vervalue: 0,
      verstylevalue: "0px",
      transformvalue:
        "perspective(none) rotateY(0deg) scale(1) rotate(0deg) skewX(0deg) skewY(0deg)",
      rotatevalue: 0,
      rotatestylevalue: "rotate(0deg)",
      skewx: 0,
      skewxstyle: "skewX(0deg)",
      skewy: 0,
      skewystyle: "skewY(0deg)",
      persp: 200,
      perspstyle: "perspective(200px)",
      rotx: 0,
      rotxtyle: "rotateX(0deg)",
      roty: 0,
      rotystyle: "rotateY(0deg)",
      scalex: 100,
      scalexstyle: "scale(1)",
      scaley: 100,
      scaleystyle: "scale(1)",
      startxy: {},
      drawings_a: [],
      drawingA: false,
      colorA: "#ff0000",
      points: [
        [0, 0], // Top-left [x, y]
        [300, 200], // Top-right [x, y]
        [300, 400 - 200], // bottom-right [x, y]
        [0, 200], // bottom-left [x, y]
      ],
      croppoints: [
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
        { x: 0, y: 0 },
      ],
      cropstep: 0,
    };
    this.handleCloseFirst = this.handleCloseFirst.bind(this);
    this.handleCloseSecond = this.handleCloseSecond.bind(this);
    // this.findxy = this.findxy.bind(this);
  }

  loadOpenCv = () => {
    const promise = injectScript("opencv-injected-js", OPENCV_URL);
    promise
      .then(() => {
        console.log(`success to load ${OPENCV_URL}`);

        // eslint-disable-next-line no-undef
        // console.log(cv.getBuildInformation());
      })
      .catch(() => {
        // eslint-disable-next-line no-console
        console.log(`Failed to load ${OPENCV_URL}`);
      });
  };
  handleDrag = (e, ui) => {
    let clip =
      (ui.x + this.state.imageBWidth / 2) / (this.state.imageBWidth / 100);
    console.log(ui.x, clip);
    this.setState({ clip: clip });
  };
  onChange = (e) => {
    this.setState({ opacityvalue: e.target.value });
    this.setState({ opacitystylevalue: e.target.value / 100 });
  };
  onChangeScale = (e) => {
    this.setState({ scalevalue: e.target.value });

    this.setState({ scalestylevalue: "scale(" + e.target.value / 100 + ")" });
    this.consolidateStyles();
  };
  onChangeScaleX = (e) => {
    this.setState({ scalex: e.target.value });

    this.setState({ scalexstyle: "scaleX(" + e.target.value / 100 + ")" });
    this.consolidateStyles();
  };
  onChangeScaleY = (e) => {
    this.setState({ scaley: e.target.value });

    this.setState({ scaleystyle: "scaleY(" + e.target.value / 100 + ")" });
    this.consolidateStyles();
  };
  onChangeVer = (e) => {
    this.setState({ vervalue: e.target.value });

    this.setState({ verstylevalue: e.target.value + "px" });
  };
  onChangeHor = (e) => {
    this.setState({ horvalue: e.target.value });

    this.setState({ horstylevalue: e.target.value + "px" });
  };
  onChangeRotate = (e) => {
    this.setState({ rotatevalue: e.target.value });
    this.setState({ rotatestylevalue: "rotate(" + e.target.value + "deg)" });
    this.consolidateStyles();
  };
  onChangeClip = (e) => {
    this.setState({ clip: e.target.value });
    // this.setState({ skewxstyle: "skewX(" + e.target.value + "deg)" });
    // this.consolidateStyles();
  };
  onChangeSkewY = (e) => {
    this.setState({ skewy: e.target.value });
    this.setState({ skewystyle: "skewY(" + e.target.value + "deg)" });
    this.consolidateStyles();
  };
  onChangePersp = (e) => {
    this.setState({ persp: e.target.value });
    this.setState({ perspstyle: "perspective(" + e.target.value + "px)" });
    this.consolidateStyles();
  };
  onChangeRotX = (e) => {
    this.setState({ rotx: e.target.value });
    // this.setState({rotxtyle: "rotateX("+e.target.value+"deg)" });
    // this.consolidateStyles();
    let points = [];
    var d0x = this.state.points[0][0];
    var d0y = this.state.points[0][1];
    var d1x = this.state.points[1][0];
    var d1y = this.state.points[1][1];
    var d2x = this.state.points[2][0];
    var d2y = this.state.points[2][1];
    var d3x = this.state.points[3][0];
    var d3y = this.state.points[3][1];
    // [0, 0],                               // Top-left [x, y]
    // [image.width, 0],                 // Top-right [x, y]
    // [image.width , image.height],  // bottom-right [x, y]
    // [0, image.height]                      // bottom-left [x, y]
    if (e.target.value >= 0) {
      points = [
        [d0x, 0], // Top-left [x, y]
        [d1x, 0 + e.target.value], // Top-right [x, y]
        [d2x, this.state.imageA.height - e.target.value], // bottom-right [x, y]
        [d3x, this.state.imageA.height], // bottom-left [x, y]
      ];
    } else {
      points = [
        [d0x, 0 - e.target.value], // Top-left [x, y]
        [d1x, 0], // Top-right [x, y]
        [d2x, this.state.imageA.height], // bottom-right [x, y]
        [d3x, this.state.imageA.height + e.target.value], // bottom-left [x, y]
      ];
    }

    this.setState({ points: points });
    this.refreshContext();
  };
  onChangeRotY = (e) => {
    this.setState({ roty: e.target.value });
    let points = [];
    var d0x = this.state.points[0][0];
    var d0y = this.state.points[0][1];
    var d1x = this.state.points[1][0];
    var d1y = this.state.points[1][1];
    var d2x = this.state.points[2][0];
    var d2y = this.state.points[2][1];
    var d3x = this.state.points[3][0];
    var d3y = this.state.points[3][1];
    // [0, 0],                               // Top-left [x, y]
    // [image.width, 0],                 // Top-right [x, y]
    // [image.width , image.height],  // bottom-right [x, y]
    // [0, image.height]                      // bottom-left [x, y]
    if (e.target.value >= 0) {
      points = [
        [0 + e.target.value, d0y], // Top-left [x, y]
        [this.state.imageA.width - e.target.value, d1y], // Top-right [x, y]
        [this.state.imageA.width, d2y], // bottom-right [x, y]
        [0, d3y], // bottom-left [x, y]
      ];
    } else {
      points = [
        [0, d0y], // Top-left [x, y]
        [this.state.imageA.width, d1y], // Top-right [x, y]
        [this.state.imageA.width + e.target.value, d2y], // bottom-right [x, y]
        [-e.target.value, d3y], // bottom-left [x, y]ttom-left [x, y]
      ];
    }

    this.setState({ points: points });
    this.refreshContext();
  };
  consolidateStyles = () => {
    this.setState({
      transformvalue:
        this.state.perspstyle +
        " " +
        this.state.rotxtyle +
        " " +
        this.state.rotystyle +
        " " +
        this.state.scalestylevalue +
        " " +
        this.state.rotatestylevalue +
        " " +
        this.state.skewxstyle +
        " " +
        this.state.skewystyle +
        " " +
        this.state.scalexstyle +
        " " +
        this.state.scaleystyle,
    });
  };
  onChangeRadio = (e) => {
    this.setState({ project_state: e.target.value });
  };
  onChangeGroups = (e) => {
    //console.log(e.target.id);
    let tempmemberships = this.state.memberships;
    //console.log(tempmemberships);
    tempmemberships.forEach((membership) => {
      if (membership.id === e.target.id) {
        membership.checked = e.target.checked;
      }
    });
    this.setState({ memberships: tempmemberships });
  };
  onChangeRadioPublic = (e) => {
    this.setState({ public: e.target.value });
  };
  onChangeCover = (e) => {
    this.setState({ cover: e.target.checked });
  };
  calcImageSizes = () => {
    let maxW;
    let maxH;

    if (this.props.dimensions.window_dimensions.isMobile) {
      if (this.state.showOverlay) {
        maxW = this.props.dimensions.window_dimensions.w - 70;
      } else {
        maxW = this.props.dimensions.window_dimensions.w / 2 - 30;
      }
    } else {
      if (this.state.showOverlay) {
        maxW = this.props.dimensions.window_dimensions.w - 180;
      } else {
        maxW = this.props.dimensions.window_dimensions.w / 2 - 90;
      }
    }
    maxH = this.props.dimensions.window_dimensions.h - 100;
    if (this.state.maxW !== maxW) {
      this.setState({ maxW: maxW });
    }
    if (this.state.maxH !== maxH) {
      this.setState({ maxH: maxH });
    }

    const imageA = this.state.imageA;
    const imageB = this.state.imageB;
    let image_w_a;
    let image_h_a;
    const image_r_a = imageA.width / imageA.height;

    if (image_r_a > 1) {
      // landscape
      if (maxW / image_r_a > maxH) {
        image_w_a = maxH * image_r_a;
        image_h_a = maxH;
      } else {
        image_w_a = maxW;
        image_h_a = maxW / image_r_a;
      }
    } else {
      // portrait
      if (maxH * image_r_a > maxW) {
        image_w_a = maxW;
        image_h_a = maxW / image_r_a;
      } else {
        image_w_a = maxH * image_r_a;
        image_h_a = maxH;
      }
    }
    let image_w_b;
    let image_h_b;
    const image_r_b = imageB.width / imageB.height;

    if (image_r_b > 1) {
      // landscape
      if (maxW / image_r_b > maxH) {
        image_w_b = maxH * image_r_b;
        image_h_b = maxH;
      } else {
        image_w_b = maxW;
        image_h_b = maxW / image_r_b;
      }
    } else {
      // portrait
      if (maxH * image_r_b > maxW) {
        image_w_b = maxW;
        image_h_b = maxW / image_r_b;
      } else {
        image_w_b = maxH * image_r_b;
        image_h_b = maxH;
      }
    }

    if (this.state.showOverlay) {
      // find highest ratio, set height of
      if (image_r_a > image_r_b) {
        // height of image_h_b should be set to image_h_a
        // first calc image_h_a
        // use  this height to set for b
        image_h_b = image_h_a;
        image_w_b = image_h_b * image_r_b;
      } else {
        // height of image_H_b should be set to image_h_a
        image_h_a = image_h_b;
        image_w_a = image_h_a * image_r_a;
      }
    }

    if (this.state.imageAHeight !== image_h_a) {
      this.setState({ imageAHeight: image_h_a });
    }
    if (this.state.imageAWidth !== image_w_a) {
      this.setState({ imageAWidth: image_w_a });
    }
    if (this.state.imageARatio !== image_r_a) {
      this.setState({ imageARatio: image_r_a });
    }
    if (this.state.imageBHeight !== image_h_b) {
      this.setState({ imageBHeight: image_h_b });
    }
    if (this.state.imageBWidth !== image_w_b) {
      this.setState({ imageBWidth: image_w_b });
    }
    if (this.state.imageBRatio !== image_r_b) {
      this.setState({ imageBRatio: image_r_b });
    }
  };
  refreshContextA = (e) => {
    const image = this.state.imageA;

    const ctx = this.canvasA.current.getContext("2d");
    var canvas = ctx.canvas;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = this.state.imageAWidth;
    canvas.height = this.state.imageAHeight;
    const ctx_draw = this.drawcanvasA.current.getContext("2d");
    var drawcanvas = ctx_draw.canvas;
    drawcanvas.width = this.state.imageAWidth;
    drawcanvas.height = this.state.imageAHeight;
    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    const drawings = this.state.drawings_a;

    ctx_draw.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    drawings.forEach((drawing) => {
      if (drawing.type === "line") {
        // ctx_draw.beginPath();
        // ctx_draw.moveTo(drawing.data.x_s*ctx.canvas.width, drawing.data.y_s*ctx.canvas.height);
        // ctx_draw.lineTo(drawing.data.x_e*ctx.canvas.width, drawing.data.y_e*ctx.canvas.height);
        // ctx_draw.strokeStyle='white';
        // ctx_draw.lineWidth = 3;
        // ctx_draw.stroke();

        ctx_draw.beginPath();
        ctx_draw.moveTo(
          drawing.data.x_s * ctx.canvas.width,
          drawing.data.y_s * ctx.canvas.height
        );
        ctx_draw.lineTo(
          drawing.data.x_e * ctx.canvas.width,
          drawing.data.y_e * ctx.canvas.height
        );
        ctx_draw.strokeStyle = this.state.colorA;
        ctx_draw.lineWidth = 1;
        ctx_draw.stroke();
      }
    });
    // if (this.state.imageAHeight !== canvas.height){
    //   this.setState({ imageAHeight: canvas.height });
    // }
    // if (this.state.imageAWidth !== canvas.width){
    //   this.setState({ imageAWidth: canvas.width });
    // }
    // if (this.state.imageARatio !== imageratio){
    //   this.setState({ imageARatio: imageratio });
    // }
    // var hRatio = canvas.width / image.width;
    // var vRatio = canvas.height / image.height;
    // var ratio = Math.min(hRatio, vRatio);
    // var centerShift_x = (canvas.width - image.width * ratio) / 2;
    // var centerShift_y = (canvas.height - image.height * ratio) / 2;

    // ctx.drawImage(
    //   image,
    //   0,
    //   0,
    //   image.width,
    //   image.height,
    //   centerShift_x,
    //   centerShift_y,
    //   image.width * ratio,
    //   image.height * ratio
    // );

    //   var c=document.createElement('canvas');
    // var cctx=c.getContext('2d');
    // c.width=canvas.width;
    // c.height=canvas.height;
    // cctx.drawImage(ctx.canvas,0,0);
    // cctx.globalCompositeOperation='source-atop';
    // cctx.fillStyle='red';
    // cctx.fillRect(0,0,ctx.canvas.width,ctx.canvas.height);
    // cctx.globalCompositeOperation='source-over';

    // // // draw the grayscale image onto the canvas
    // // // ctx2.drawImage(ctx2.canvas,0,0);
    // // ctx2.filter = 'grayscale(100%)';
    // // // set compositing to color (changes hue with new overwriting colors)
    // ctx.globalCompositeOperation='color';

    // // // draw the fully green img on top of the grayscale image
    // // // ---- the img is now greenscale ----
    // ctx.drawImage(c,0,0);

    // // // Always clean up -- change compositing back to default
    // ctx.globalCompositeOperation='source-over';
  };
  refreshContextB = (e) => {
    const ctx2 = this.canvasB.current.getContext("2d");
    var image = this.state.imageB;

    var canvas = ctx2.canvas;
    var temp_canvas = document.createElement("canvas");
    const temp_ctx = temp_canvas.getContext("2d");
    ctx2.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = this.state.imageBWidth;
    canvas.height = this.state.imageBHeight;

    temp_ctx.canvas.width = canvas.width;
    temp_ctx.canvas.height = canvas.height;
    temp_ctx.clearRect(0, 0, temp_ctx.canvas.width, temp_ctx.canvas.height);

    const scale_ratio = this.state.scalevalue / 100;
    var temp_hRatio = (canvas.width / image.width) * scale_ratio;
    var temp_vRatio = (canvas.height / image.height) * scale_ratio;
    var temp_ratio = Math.min(temp_hRatio, temp_vRatio);
    var temp_centerShift_x =
      (canvas.width - image.width * temp_ratio) / 2 + this.state.horvalue;
    var temp_centerShift_y =
      (canvas.height - image.height * temp_ratio) / 2 + this.state.vervalue;

    // temp_ctx.clearRect(0, 0, canvas.width, canvas.height);

    temp_ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height,
      temp_centerShift_x,
      temp_centerShift_y,
      image.width * temp_ratio,
      image.height * temp_ratio
    );

    // ctx2.clearRect(0, 0, canvas.width, canvas.height);
    ctx2.globalAlpha = this.state.opacitystylevalue;
    let clip;
    if (this.state.showOverlay) {
      clip = this.state.clip;
    } else {
      clip = 0;
    }
    const zero_src = 0 + (temp_ctx.canvas.width * clip) / 100;
    const zero_dst = 0 + (temp_ctx.canvas.width * clip) / 100;
    const clipped_width_src =
      temp_ctx.canvas.width - (temp_ctx.canvas.width * clip) / 100;
    const clipped_width_dest =
      temp_ctx.canvas.width - (temp_ctx.canvas.width * clip) / 100;
    // console.log(image2.width,centerShift_x, zero_src,zero_dst,clipped_width_src,clipped_width_dest)

    ctx2.drawImage(
      temp_ctx.canvas,
      zero_src,
      0,
      clipped_width_src,
      temp_ctx.canvas.height,
      zero_dst,
      0,
      clipped_width_dest,
      temp_ctx.canvas.height
    );
    // ctx2.filter = 'grayscale(100%)';
    // ctx2.filter = 'hue-rotate(120deg)';
    // ctx2.drawImage(temp_ctx.canvas, 0,0);
    // create a fully green version of img
    // var c=document.createElement('canvas');
    // var cctx=c.getContext('2d');
    // c.width=canvas.width;
    // c.height=canvas.height;
    // cctx.drawImage(ctx2.canvas,0,0);
    // cctx.globalCompositeOperation='source-atop';
    // cctx.fillStyle='green';
    // cctx.fillRect(0,0,ctx2.canvas.width,ctx2.canvas.height);
    // cctx.globalCompositeOperation='source-over';

    // // // draw the grayscale image onto the canvas
    // // // ctx2.drawImage(ctx2.canvas,0,0);
    // // ctx2.filter = 'grayscale(100%)';
    // // // set compositing to color (changes hue with new overwriting colors)
    // ctx2.globalCompositeOperation='color';

    // // // draw the fully green img on top of the grayscale image
    // // // ---- the img is now greenscale ----
    // ctx2.drawImage(c,0,0);

    // // // Always clean up -- change compositing back to default
    // ctx2.globalCompositeOperation='source-over';
  };
  ToggleSlider = (e) => {
    if (this.state.showOverlay) {
      this.setState({ clip: 10 });
      this.setState({ showOverlay: false });
    } else {
      this.setState({ clip: 50 });
      this.setState({ showOverlay: true });
    }
    this.refreshContextA();
    this.refreshContextB();
  };
  enableDraw = (e) => {};

  handleSelect(elementid, column) {
    if (column === 1) {
      this.setState({ selectFirst: false });
      const image = new Image();

      image.crossOrigin = "anonymous";
      image.setAttribute("crossOrigin", "");

      image.src =
        api_endpoint +
        "/media/" +
        this.props.elements.byID[elementid].images[0].id +
        "/file?size=2048";
      // image.src = '/testimages/1x/610.jpg'

      image.onload = () => {
        this.setState({ firstSelected: elementid });
        this.setState({ imageA: image });
        if (this.state.imageB.src) {
          this.calcImageSizes();
        }
        this.refreshContextA();
      };
    }
    if (column === 2) {
      const image2 = new Image();

      console.log(this.state.imageB.src);
      image2.crossOrigin = "anonymous";
      image2.setAttribute("crossOrigin", "");

      image2.src =
        api_endpoint +
        "/media/" +
        this.props.elements.byID[elementid].images[0].id +
        "/file?size=2048";
      //  image2.src = '/testimages/1x/34.jpg'
      //   console.log("secondimage")
      //   console.log(image2)
      image2.onload = () => {
        // this.setState({ secondSelected: elementid });
        this.setState({ selectSecond: false });
        console.log("BB");
        this.setState({ imageB: image2 });
        console.log("image2.width", image2);

        if (this.state.imageA.src) {
          this.calcImageSizes();
        }
        this.refreshContextB();
        // }
      };
    }
  }
  handleCloseFirst(column) {
    this.setState({ selectFirst: true });
  }
  handleCloseSecond(column) {
    this.setState({ selectSecond: true });
  }
  handleSetSelect(column) {
    if (column === 1) {
      this.setState({ selectFirst: true });
    }
    if (column === 2) {
      this.setState({ selectSecond: true });
    }
  }
  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
    if (this.state.showDeleteConfirmation === true) {
      this.setState({ showDeleteConfirmation: false });
    }
  }
  handleCloseDialog(target) {
    console.log("clese");
    this.props.dispatch(setMenu(false));
    this.props.handleCloseRootPopup();
    // this.props.history.replace(target);
  }

  doNothing(e) {
    e.stopPropagation();
  }

  draw(mouse, e, canvas) {
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (mouse === "down") {
      if (this.state.drawingA) {
        this.setState({ drawingA: false });
        const x_s = this.state.startxy.x / rect.width;
        const y_s = this.state.startxy.y / rect.height;
        const x_e = x / rect.width;
        const y_e = y / rect.height;
        const new_drawing = this.state.drawings_a;
        new_drawing.push({
          type: "line",
          data: { x_s: x_s, y_s: y_s, x_e: x_e, y_e: y_e },
        });
        this.setState({ drawings_a: new_drawing });
      } else {
        this.setState({ startxy: { x: x, y: y } });
        this.setState({ drawingA: true });
      }
    }

    const ctx = this.drawcanvasA.current.getContext("2d");

    if (mouse === "move") {
      console.log(this.state.drawingA);
      if (this.state.drawingA) {
        const drawings = this.state.drawings_a;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawings.forEach((drawing) => {
          if (drawing.type === "line") {
            console.log(drawing.data);

            ctx.beginPath();
            ctx.moveTo(
              drawing.data.x_s * ctx.canvas.width,
              drawing.data.y_s * ctx.canvas.height
            );
            ctx.lineTo(
              drawing.data.x_e * ctx.canvas.width,
              drawing.data.y_e * ctx.canvas.height
            );
            ctx.strokeStyle = this.state.colorA;
            ctx.stroke();
          }
        });

        ctx.beginPath();
        ctx.moveTo(this.state.startxy.x, this.state.startxy.y);
        ctx.lineTo(x, y);
        ctx.strokeStyle = this.state.colorA;
        ctx.stroke();
      }
    }
    // if (mouse === "up"){
    //   this.setState({ drawingA: false });
    //   this.setState({ drawings_a: {type: "line", data: {x_s:this.state.startxy.x, y_s:this.state.startxy.y, x_e:x,y_e:y} }});
    // }
  }

  componentDidMount() {
    if (this.props.dimensions.window_dimensions.isMobile) {
      this.setState({
        maxW: this.props.dimensions.window_dimensions.w / 2 - 30,
      });
      this.setState({ maxH: this.props.dimensions.window_dimensions.h - 100 });
    } else {
      this.setState({
        maxW: this.props.dimensions.window_dimensions.w / 2 - 90,
      });
      this.setState({ maxH: this.props.dimensions.window_dimensions.h - 100 });
    }
    const ctx = this.drawcanvasA.current.getContext("2d");
    const canvas = ctx.canvas;

    // canvas.addEventListener("mousemove", function (e) {
    //     this.findxy('move', e)
    // }, false);
    // canvas.addEventListener("click", this.findxy('down'), false);
    canvas.addEventListener(
      "mousedown",
      (e) => this.draw("down", e, canvas),
      false
    );
    canvas.addEventListener(
      "mousemove",
      (e) => this.draw("move", e, canvas),
      false
    );
    canvas.addEventListener(
      "mouseup",
      (e) => this.draw("up", e, canvas),
      false
    );
    // canvas.addEventListener("mouseup", function (e) {
    //     this.findxy('up', e)
    // }, false);
    // canvas.addEventListener("mouseout", function (e) {
    //     this.findxy('out', e)
    // }, false);
  }

  componentDidUpdate() {
    if (this.state.imageA.src) {
      this.refreshContextA();
    }
    if (this.state.imageB.src) {
      this.refreshContextB();
    }
    if (this.state.imageA.src && this.state.imageB.src) {
      this.calcImageSizes();
    }
  }

  render() {
    const {
      project,
      elements,
      dimensions,
      project_url,
      new_dialog,
      categories,
      element_types,
    } = this.props;
    let groupedelements = {};
    let groups = [];
    let prevfriendly = "";
    elements.allIDS.map((elementid, index) => {
      const element = elements.byID[elementid];
      if (element.friendly_date !== prevfriendly) {
        prevfriendly = element.friendly_date;
        groupedelements[element.friendly_date] = [elementid];
        groups.push(element.friendly_date);
      } else {
        groupedelements[element.friendly_date].push(elementid);
      }
    });
    let image_url2;
    let image_url1;
    if (
      this.state.showOverlay &&
      this.state.firstSelected &&
      this.state.secondSelected
    ) {
      if (elements.byID[this.state.firstSelected].images.length === 0) {
        image_url1 = false;
      } else {
        image_url1 =
          api_endpoint +
          "/media/" +
          this.props.elements.byID[this.state.firstSelected].images[0].id +
          "/file?size=1024";
      }

      if (elements.byID[this.state.secondSelected].images.length === 0) {
        image_url2 = false;
      } else {
        image_url2 =
          api_endpoint +
          "/media/" +
          this.props.elements.byID[this.state.firstSelected].images[0].id +
          "/file?size=1024";
      }
    }

    return (
      <div style={{ height: "100%" }}>
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <ElementCard isMobile={dimensions.window_dimensions.isMobile}>
              <SettingsHeader>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Compare Images
                </SettingsTitle>
                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialog(project_url);
                  }}>
                  {" "}
                  <IconButton size="large">
                    <CloseRoundedIcon fontSize="inherit" />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <DialogInstruction>
                Select the images to compare
              </DialogInstruction>
              <Toolbar>
                <ToggleButtonGroup
                  value={this.state.showOverlay}
                  exclusive
                  onChange={this.ToggleSlider}
                  aria-label="Image Optimization"
                  style={{ height: "32px", marginLeft: "10px" }}>
                  <ToggleButton value={false} aria-label="Side by Side">
                    <ImageIcon />
                    Side by Side
                  </ToggleButton>
                  <ToggleButton value={true} aria-label="Overlay">
                    <CompareIcon />
                    Overlay
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  disableElevation
                  // variant="contained"
                  color="primary"
                  style={{ fontSize: "14px", textTransform: "none" }}
                  size="small"
                  onClick={() => {
                    this.enableDraw();
                  }}>
                  Draw
                </Button>
              </Toolbar>
              {this.state.showOverlay ? (
                <StyledSliders>
                  <Box width={250} sx={{ m: 3 }}>
                    Opacity
                    <Slider
                      valueLabelDisplay="auto"
                      id="opacityvalue"
                      size="small"
                      color="primary"
                      onChange={this.onChange}
                      value={this.state.opacityvalue}
                      aria-labelledby="continuous-slider"
                      min={0}
                      max={100}
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                  </Box>
                  {/* <Box width={300}>
                        Clip
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeClip}
                        value={this.state.clip}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={100}
                      />
                      </Box> */}
                  <Box width={250} sx={{ m: 3 }}>
                    Vertical Movemement
                    <Slider
                      valueLabelDisplay="auto"
                      id="opacityvalue"
                      size="small"
                      color="primary"
                      onChange={this.onChangeVer}
                      value={this.state.vervalue}
                      aria-labelledby="continuous-slider"
                      min={-200}
                      max={200}
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                  </Box>
                  <Box width={250} sx={{ m: 3 }}>
                    Horizontal Movemement
                    <Slider
                      valueLabelDisplay="auto"
                      id="opacityvalue"
                      size="small"
                      color="primary"
                      onChange={this.onChangeHor}
                      value={this.state.horvalue}
                      aria-labelledby="continuous-slider"
                      min={-200}
                      max={200}
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                  </Box>
                  <Box width={250} sx={{ m: 3 }}>
                    Scale
                    <Slider
                      valueLabelDisplay="auto"
                      id="opacityvalue"
                      size="small"
                      color="primary"
                      onChange={this.onChangeScale}
                      value={this.state.scalevalue}
                      aria-labelledby="continuous-slider"
                      min={0}
                      max={200}
                      style={{ marginLeft: "5px", marginRight: "5px" }}
                    />
                  </Box>
                  {/* <Box width={300}>
                          Opacity
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChange}
                        value={this.state.opacityvalue}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={100}
                      />
                      </Box>
                      <Box width={300}>
                          Scale
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeScale}
                        value={this.state.scalevalue}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                          Scale X
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeScaleX}
                        value={this.state.scalex}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                          Scale Y
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeScaleY}
                        value={this.state.scaley}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                         Vertical Movemement
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeVer}
                        value={this.state.vervalue}
                        aria-labelledby="continuous-slider"
                        min={-200}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                         Horizontal Movemement
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeHor}
                        value={this.state.horvalue}
                        aria-labelledby="continuous-slider"
                        min={-200}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                        Rotate
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeRotate}
                        value={this.state.rotatevalue}
                        aria-labelledby="continuous-slider"
                        min={-92}
                        max={90}
                      />
                      </Box>
                      <Box width={300}>
                        Skew X
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeSkewX}
                        value={this.state.skewx}
                        aria-labelledby="continuous-slider"
                        min={-45}
                        max={45}
                      />
                      </Box>
                      <Box width={300}>
                        Skew Y
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeSkewY}
                        value={this.state.skewy}
                        aria-labelledby="continuous-slider"
                        min={-45}
                        max={45}
                      />
                      </Box>
                      <Box width={300}>
                        Perspective Amount (lens?)
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangePersp}
                        value={this.state.persp}
                        aria-labelledby="continuous-slider"
                        min={0}
                        max={1000}
                      />
                        </Box> */}
                  {/* <Box width={300}>
                        Perspective Vertical
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeRotY}
                        value={this.state.roty}
                        aria-labelledby="continuous-slider"
                        min={-200}
                        max={200}
                      />
                      </Box>
                      <Box width={300}>
                        Perspective Horizontal
                        <Slider
                        valueLabelDisplay="auto"
                        id ="opacityvalue"
                        size="small"
                        color="primary"
                        onChange={this.onChangeRotX}
                        value={this.state.rotx}
                        aria-labelledby="continuous-slider"
                        min={-200}
                        max={200}
                      />
                      </Box> */}
                </StyledSliders>
              ) : (
                ""
              )}

              <SliderWrapper>
                <SliderContainer maxH={this.state.maxH}>
                  {this.state.showOverlay ? (
                    <Draggable
                      axis="x"
                      bounds={{
                        left: -this.state.imageBWidth / 2,
                        top: 0,
                        right: this.state.imageBWidth / 2,
                        bottom: 800,
                      }}
                      //  position={this.state.controlledPosition1}
                      onDrag={this.handleDrag}
                      //  onStop={this.handleStop}
                      nodeRef={this.nodeRefA}>
                      <SliderHandleContainer ref={this.nodeRefA}>
                        <SliderHandle id={"1"} />
                        <SliderCircle>
                          <SliderArrow />
                          <SliderArrow2 />
                        </SliderCircle>
                        <SliderHandle id={"1"} />
                      </SliderHandleContainer>
                    </Draggable>
                  ) : (
                    ""
                  )}
                  <CanvasContainer overlay={this.state.showOverlay}>
                    <StyledCanvasA
                      overlay={this.state.showOverlay}
                      ref={this.canvasA}
                    />
                    <StyledCanvasA ref={this.drawcanvasA}></StyledCanvasA>
                  </CanvasContainer>

                  <CanvasContainer overlay={this.state.showOverlay}>
                    <StyledCanvasA ref={this.drawcanvasB}></StyledCanvasA>
                    <StyledCanvasA
                      overlay={this.state.showOverlay}
                      ref={this.canvasB}
                    />
                  </CanvasContainer>
                </SliderContainer>
              </SliderWrapper>

              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
                <CompareLeft
                  isMobile={dimensions.window_dimensions.isMobile}
                  optimizewidth={this.state.optimizewidth}>
                  {!this.state.selectFirst ? (
                    <BackButton>
                      {" "}
                      <Button
                        disableElevation
                        // variant="contained"
                        color="primary"
                        style={{ fontSize: "14px", textTransform: "none" }}
                        size="small"
                        onClick={() => {
                          this.handleCloseFirst();
                        }}
                        startIcon={
                          <ArrowBackIosIcon style={{ color: "#383838" }} />
                        }>
                        Select A
                      </Button>
                    </BackButton>
                  ) : (
                    ""
                  )}

                  {this.state.selectFirst ? (
                    <SelectContainer
                      isMobile={dimensions.window_dimensions.isMobile}>
                      {elements.allIDS
                        .filter((elementid) => {
                          return elements.byID[elementid].images.length > 0;
                        })
                        .map((elementid, index) => {
                          const element = elements.byID[elementid];
                          let image_url;
                          if (element.images.length === 0) {
                            image_url = false;
                          } else {
                            console.log("ff");
                            image_url =
                              api_endpoint +
                              "/media/" +
                              element.images[0].id +
                              "/file?size=256";
                            console.log(image_url);
                          }
                          return (
                            <SmallElement
                              key={"el" + index}
                              cardwidth={100}
                              onClick={() => {
                                this.handleSelect(elementid, 1);
                              }}>
                              {
                                {
                                  2: (
                                    <Update
                                      image_url={image_url}
                                      description={element.description}
                                    />
                                  ),
                                  9: <Next description={element.description} />,
                                  6: (
                                    <Question
                                      description={element.description}
                                    />
                                  ),
                                  3: (
                                    <Dimension
                                      description={element.description}
                                      unit={
                                        element.metadata
                                          ? element.metadata.dimension_unit
                                            ? element.metadata.dimension_unit
                                            : ""
                                          : ""
                                      }
                                      x={
                                        element.metadata
                                          ? element.metadata.dimension_x
                                            ? element.metadata.dimension_x
                                            : 60
                                          : 60
                                      }
                                      y={
                                        element.metadata
                                          ? element.metadata.dimension_y
                                            ? element.metadata.dimension_y
                                            : 60
                                          : 60
                                      }
                                      z={
                                        element.metadata
                                          ? element.metadata.dimension_z
                                            ? element.metadata.dimension_z
                                            : 60
                                          : 60
                                      }
                                    />
                                  ),
                                  4: (
                                    <Update
                                      image_url={image_url}
                                      description={element.description}
                                      tag="MATERIAL"
                                      color="#2196F3"
                                      feedplacement={true}
                                    />
                                  ),
                                  5: (
                                    <Update
                                      selected={this.props.selected}
                                      image_url={image_url}
                                      description={element.description}
                                      tag="TOOL"
                                      color="#EA1B65"
                                      feedplacement={true}
                                    />
                                  ),
                                }[
                                  element_types.byID[element.element_type].style
                                ]
                              }
                            </SmallElement>
                          );
                        })}
                    </SelectContainer>
                  ) : (
                    ""
                    // <ElementCompare
                    //   group_id={this.props.group_id}
                    //   isOwner={this.props.isOwner}
                    //   project_url={this.props.project_url}
                    //   history={this.props.history}
                    //   id={this.state.firstSelected}
                    //   handleClose={this.handleCloseFirst}
                    //   optimizewidth={this.state.optimizewidth}></ElementCompare>
                  )}
                </CompareLeft>
                <CompareRight
                  isMobile={dimensions.window_dimensions.isMobile}
                  optimizewidth={this.state.optimizewidth}>
                  {!this.state.selectSecond ? (
                    <BackButton>
                      {" "}
                      <Button
                        disableElevation
                        // variant="contained"
                        color="primary"
                        style={{ fontSize: "14px", textTransform: "none" }}
                        size="small"
                        onClick={() => {
                          this.handleCloseSecond();
                        }}
                        startIcon={
                          <ArrowBackIosIcon style={{ color: "#383838" }} />
                        }>
                        Select B
                      </Button>
                    </BackButton>
                  ) : (
                    ""
                  )}
                  {this.state.selectSecond ? (
                    <SelectContainer
                      isMobile={dimensions.window_dimensions.isMobile}>
                      {elements.allIDS
                        .filter((elementid) => {
                          return elements.byID[elementid].images.length > 0;
                        })
                        .map((elementid, index) => {
                          const element = elements.byID[elementid];
                          let image_url;
                          if (element.images.length === 0) {
                            image_url = false;
                          } else {
                            image_url = image_url =
                              api_endpoint +
                              "/media/" +
                              element.images[0].id +
                              "/file?size=256";
                          }
                          return (
                            <SmallElement
                              key={"el" + index}
                              cardwidth={100}
                              onClick={() => {
                                this.handleSelect(elementid, 2);
                              }}>
                              {
                                {
                                  2: (
                                    <Update
                                      image_url={image_url}
                                      description={element.description}
                                    />
                                  ),
                                  9: <Next description={element.description} />,
                                  6: (
                                    <Question
                                      description={element.description}
                                    />
                                  ),
                                  3: (
                                    <Dimension
                                      description={element.description}
                                      unit={
                                        element.metadata
                                          ? element.metadata.dimension_unit
                                            ? element.metadata.dimension_unit
                                            : ""
                                          : ""
                                      }
                                      x={
                                        element.metadata
                                          ? element.metadata.dimension_x
                                            ? element.metadata.dimension_x
                                            : 60
                                          : 60
                                      }
                                      y={
                                        element.metadata
                                          ? element.metadata.dimension_y
                                            ? element.metadata.dimension_y
                                            : 60
                                          : 60
                                      }
                                      z={
                                        element.metadata
                                          ? element.metadata.dimension_z
                                            ? element.metadata.dimension_z
                                            : 60
                                          : 60
                                      }
                                    />
                                  ),
                                  4: (
                                    <Update
                                      image_url={image_url}
                                      description={element.description}
                                      tag="MATERIAL"
                                      color="#2196F3"
                                      feedplacement={true}
                                    />
                                  ),
                                  5: (
                                    <Update
                                      selected={this.props.selected}
                                      image_url={image_url}
                                      description={element.description}
                                      tag="TOOL"
                                      color="#EA1B65"
                                      feedplacement={true}
                                    />
                                  ),
                                }[
                                  element_types.byID[element.element_type].style
                                ]
                              }
                            </SmallElement>
                          );
                        })}
                    </SelectContainer>
                  ) : (
                    ""
                    // <ElementCompare
                    //   group_id={this.props.group_id}
                    //   isOwner={this.props.isOwner}
                    //   project_url={this.props.project_url}
                    //   history={this.props.history}
                    //   id={this.state.secondSelected}
                    //   handleClose={this.handleCloseSecond}
                    //   optimizewidth={this.state.optimizewidth}></ElementCompare>
                  )}
                </CompareRight>
              </SettingsColumns>

              <StyledButtonContainer>
                <StyledButton>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    style={{ minWidth: "150px" }}
                    onClick={() => {
                      this.handleCloseDialog(project_url);
                    }}>
                    Done
                  </Button>
                </StyledButton>
              </StyledButtonContainer>
            </ElementCard>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  project_loading: state.project.loading,
  elements: state.project.current_project.elements,
  element_types: state.project.current_project.element_types,
  userid: state.auth.user.user_id,
  categories: state.auth.project_categories,
  groups: state.profile.groups,
  groups_loading: state.profile.groups_loading,
  dimensions: state.dimensions,
});
export default connect(mapStateToProps)(withRouter(ProjectCompare));
