import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import theme from "../../config/theme";
import { showError } from "../../actions/alertActions";
import { withRouter } from "react-router-dom";

import { setMenu } from "../../actions/uiActions";
import setTitle from "../../utils/setTitle";
import { site_name } from "../../config";

const StyledloginContainer = styled.div`
  display: block;
  // margin: 16.66667vh auto 16.66667vh auto;
  margin-top: ${(props) => (props.isDialog ? "40px" : "16.66667vh")};
  margin-bottom: ${(props) => (props.isDialog ? "40px" : "16.66667vh")};
  margin-left: ${(props) => (props.isDialog ? "auto" : "auto")};
  margin-right: ${(props) => (props.isDialog ? "auto" : "auto")};

  text-align: center;
  display: flex;
  flex-direction: column;
`;

const StyledloginDialog = styled.div`
  display: inline-block;
  margin: 0 auto 8.33333vh auto;
  margin-top: ${(props) => (props.isDialog ? "0" : "0")};
  margin-bottom: ${(props) => (props.isDialog ? "0" : "8.33333vh")};
  margin-left: ${(props) => (props.isDialog ? "auto" : "auto")};
  margin-right: ${(props) => (props.isDialog ? "auto" : "auto")};
  // background-color: #F3F3F3;
  width: 300px;
  min-height: 20px;
  border-radius: 2px;
  // box-shadow: 0 5px 30px 0 rgb(0 0 0 / 20%), 0 0 2px 0 rgb(0 0 0 / 20%);
`;
const LoginImage = styled.div`
  display: inline-block;
  margin: 0 auto 0px auto;

  width: 300px;
  min-height: 40px;

`;
const FormField = styled.div`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
   width: 252px;
  margin: 24px 24px 10px 24px;
  
`;
const StyledInputPwd = styled.input`
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  color: #262626;
  background-color: #ffffff;
  top: 262px;
  left: 595px;
  height: 32px;
  padding: 0 7px 0 7px;
  width: 236px;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid #cccccc;
  border-radius: 2px;
  &:focus {
    outline-color: #e5e5e5;
  }
`;

const StyledButton = styled.button`
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 252px;
  background-color: #0073ec;
  color: white;
  height: 32px;
  border-radius: 2px;

  padding-right: 30px;
  padding-left: 30px;
  
  margin: 10px 24px 10px 24px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const Styledtext = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
  margin-bottom: 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
`;
const Loginline = styled.div`
  padding-top: 5px;
  padding-bottom: 0px;
`;
const StyledtextGray = styled(Styledtext)`
  color: #37474f;
  display: inline-block;
  margin-right: 4px;
`;
const StyledError = styled.div`
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #0073ec;
`;

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      emailerror: "",
      errors: {},
    };
  }
  handleWaitMenu() {
    console.log("wait");
    this.props.dispatch(setMenu(true, 19));
  }
  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    setTitle(`Login | ${site_name}`)
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#FFFFFF";
    if (this.props.auth.user.username) {
      this.props.history.push("/" + this.props.auth.user.username); // push user to profile page when they login
    }
  }
  componentDidUpdate(nextProps) {
    if (this.props.auth.user.username) {
      this.props.history.push("/" + this.props.auth.user.username); // push user to profile page when they login
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.errors !== state.errors) {
      return {
        errors: props.errors,
      };
    }
    return null;
  }
  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return true
    return re.test(String(email).toLowerCase());
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === "password" && e.keycode === 13) {
      this.onSubmit(e);
    }
  };
  // onSubmit = (e) => {
  //  if (this.validateEmail(this.state.username)){
  //   this.setState({ emailerror:""});
  //   e.preventDefault();
  //   const userData = {
  //     email: this.state.username,
  //     password: this.state.password,
  //   };
  //   console.log("email")
  //   this.props.loginUser(userData, this.props.history);
  //  }else{
  //   console.log("no_email")
  //   e.preventDefault();
  //   this.setState({ emailerror:"Please use email to login."});
  //   return false;
  //  }


  // };
  onSubmit = (e) => {

    e.preventDefault();
    const userData = {
      email: this.state.username,
      password: this.state.password,
    };
    try {
      this.props.dispatch(loginUser(userData, this.props.history, this.props.isDialog));
    } catch (error) {
      console.log(error);
      showError(error);
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <StyledloginContainer isDialog={this.props.isDialog}>
        <LoginImage> <img width="180px" alt="logo" src="/Logo Centered LARGE.png"></img></LoginImage>
        <StyledloginDialog isDialog={this.props.isDialog}>
          <form noValidate onSubmit={e => { this.onSubmit(e) }}>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: '3px' }}
                  size="small"
                  id="username"
                  label="Username or Email"
                  variant="outlined"
                  value={this.state.username}
                  onChange={this.onChange}
                />

              </FormControl>

            </FormField>
            <StyledError>{errors.email}</StyledError>
            <FormField>
              <FormControl fullWidth variant="outlined">
                <TextField
                  style={{ background: "#fff", borderRadius: '3px' }}
                  size="small"
                  id="password"
                  variant="outlined"
                  label="Password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </FormControl>
            </FormField>


            <StyledError>{errors.password}</StyledError>
            <StyledError>{errors.detail}</StyledError>
            <StyledError>{this.state.emailerror}</StyledError>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              type="submit"
              style={{ minWidth: "252px", marginTop: '16px', marginBottom: '16px' }}
              onClick={e => { this.onSubmit(e) }}
            >
              Login
            </Button>

            <Styledtext>
              <Loginline>

                <StyledtextGray> Don't have a login? </StyledtextGray>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/register"
                >
                  Sign up
                </Link>
                {/* <span  onClick={e => { this.handleWaitMenu() }}>Join</span> */}
              </Loginline>
              <Loginline>
                <StyledtextGray> Forgot your </StyledtextGray>
                <Link
                  style={{ color: "inherit", textDecoration: "none" }}
                  to="/resetpassword"
                >
                  password?
                </Link>
                {/* <Typography 
                  variant="h1"
                  color="secondary"
                >
                  Secondary
                </Typography>
                <Typography 
                  variant="h1"
                  color="warning"
                >
                  Black
                </Typography>
                <Typography 
                  variant="h2"
                  sx={{color: theme.palette.charcoal.main}}
                >
                  Charcoal
                </Typography> */}
              </Loginline>
            </Styledtext>
          </form>

        </StyledloginDialog>
      </StyledloginContainer>
    );
  }
}
// Login.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps)(withRouter(Login));
