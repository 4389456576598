import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getGroupData,
  addMember,
  removeMember,
  loadMoreGroupProjects,
  followGroup,
  unfollowGroup,
  requestAccessGroup,
  joinGroupAuto,
  leaveGroup,
  getGroupProjects,
  saveGroup
} from "../actions/groupActions";
import GroupEdit from "./GroupEdit";
import GroupUserTable from "./GroupUserTable";
import ProjectThumb from "./thumbs/ProjectThumb";
import PersonThumb from "./thumbs/PersonThumb";
import SettingsIcon from "@mui/icons-material/Settings";
import InventoryIcon from '@mui/icons-material/Inventory';
import IconButton from "@mui/material/IconButton";
import { setGroupSettings } from "../actions/uiActions";
import Button from "@mui/material/Button";
import PeopleIcon from "@mui/icons-material/People";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import monthYear from "../utils/monthYear";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import FirstUseBanner from "./FirstUseBanner";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import { api_endpoint, site_name } from "../config";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import CloseIcon from "../custom-icons/CloseIcon";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import theme from "../config/theme";
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnRight,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog,
  StyledButtonContainer,
  StyledLeftButtons,
  StyledButtonDelete,
  StyledButton,
  PaddingContainer
} from "./styles/MenuComponents.js";
import setTitle from "../utils/setTitle";
const BottomPanel = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  width: 100%;
`;
const ShowHide = styled.div`
 
  display: ${(props) => (props.hide ? "none" : "block")};

 
`;
const LeftInfoBar = styled.div`
  min-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  max-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  height: 25%;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  // background-color: #fff;
  margin-left: ${(props) => (props.isMobile ? "25px" : "12px")};
  // border-radius: 62px;
  margin-top:13px;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
  z-index: 100;
  padding-bottom: 20px;
`;
const Tablecontainer = styled.div`
  width: ${(props) => (props.isMobile ? "calc(100% - 80px)" : "calc(100% - 80px)")};
  height: 25%;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  background-color: #fff;
 
  border-radius: 4px;
  margin-left: 40px;
  margin-right: 30px;
  margin-top: 50px;
 
  margin-bottom: ${(props) => (props.isMobile ? "50px" : "15px")};
  z-index: 100;
  padding-bottom: 20px;
`;
const InfoTitleContainer = styled.div`
  display: flex;

  margin-left: 10px;
  margin-top: 10px;
`;
const InfoTitleAvatar = styled.div`
  min-width: 104px;
  height: 104px;
  border-radius: 52px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;

  // background-size: cover;
  background-color: #ccc;
`;
const InfoTitleRight = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  margin-left: ${(props) => (props.isMobile ? "0px" : "14px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "30px")};
`;
const InfoTitleName = styled.div`
  display: flex;
  color: #fff;
  width: 100%;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 121.5%;
  margin-bottom: 20px;
  margin-left: 32px;
  margin-right: 32px;
  z-index: 10;
  margin-top: auto;
  overflow-wrap: anywhere;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 28px;
`;
const InfoHeader = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 700;
  margin-left: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: #3d3d3d;
  }
`;
const InfoCommand = styled.div`
  display: flex;
  width: 100%;
  font-size: 25px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandProject = styled.div`
  display: flex;
  width: 100%;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandTextProject = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #394b54;
  margin-top: 0px;
  margin-left: 18px;
`;
const InfoCommandNumber = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 700;
  color: #111;
  margin-top: 0px;
  margin-left: 6px;
`;
const InfoCommandText = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #2196f3;
  margin-top: 4px;
  margin-left: 16px;
`;
const InfoMember = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-bottom: 15px;
`;
const InfoAboutText = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 161.5%;
  color: #394b54;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
const InfoEst = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  margin-left: 5px;
  color: #757575;
`;
const InfoExpandContainer = styled.div`
  display: flex;
`;
const InfoCollapseContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
const InfoExpand = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const StyledHeader = styled.div`
  height: 240px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  display: flex;
  position: relative;
`;
const RightButtons = styled.div`
  margin-left: auto;
  margin-top: -10px;
  margin-right: 10px;
`;
const RightButtonsMobile = styled.div`
  margin-left: auto;

`;
const Requested = styled.div`
  color: #383838;
  margin-top: 10px;
  font-family: "Roboto", serif;
  font-size: 14px;
`;
const Gradient = styled.div`
  text-align: bottom;
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;

  // border-radius: 4px;
  background-image: linear-gradient(180deg, rgba(0,0,0,0) 61%, rgba(0,0,0,.4) 100%);
  transition: opacity 300ms ease-in-out 0s;
  z-index: 9;
`;
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-top: ${(props) => (props.isMobile ? "-25px" : "9px")};
  flex-wrap: wrap;
  width: ${(props) => (props.isMobile ? "275px" : "auto")};
  padding-bottom: 50px;
`;
// const EmptyInstruction2 = styled.div`
//   font-family: Roboto, sans-serif;
//   font-weight: 700;
//   text-align: center;
//   color: #ccc;
//   font-size: 20px;
//   line-height: 200%;
//   margin-left: auto;
//   margin-right: auto;
// `;
const Participantcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-top: ${(props) => (props.isMobile ? "-25px" : "9px")};
  flex-wrap: wrap;
  width: ${(props) => (props.isMobile ? "304px" : "auto")};
  padding-bottom: 50px;
`;
// const EmptyInstruction2 = styled.div`
//   font-family: Roboto, sans-serif;
//   font-weight: 700;
//   text-align: center;
//   color: #ccc;
//   font-size: 20px;
//   line-height: 200%;
//   margin-left: auto;
//   margin-right: auto;
// `;
const RightSection = styled.div`
  margin-top: ${(props) => (props.isMobile ? "0px" : "38px")};
  width: 100%;
  z-index: 10;
`;
const SectionContainer = styled.div`
  width: 100%;
  display: ${(props) => (props.show ? "flex" : "none")};
  flex-direction: column;
`;
const SectionContainerAbout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SectionContainerProjects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SectionContainerParticipants = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const PageTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#111" : "#fff")};
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 40px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "40px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "30px")};
  display: ${(props) => (props.isMobile ? "none" : "flex")};
`;
const BaseTitle = styled.div`
  color: ${(props) =>
    props.isMobile ? "#394b54" : "rgba(57, 75, 84, 1)"};
  font-weight: 700;
  // margin-left: 6px;
`;
const SubTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#757575" : "#394b54")};
  cursor: pointer;
`;
const Aboutcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 40px;
  margin-right: 30px;
  margin-top: ${(props) => (props.isMobile ? "-25px" : "50px")};
  margin-bottom: 90px;
  flex-wrap: wrap;
  line-height: 30px;
`;
const ProjectSpacer = styled.div`
  margin: 10px;
`;

const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.87);
  position: fixed;

  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const GroupNavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupNavBar = styled.div`
  height: 60px;
  // background-color: #ccc;
  display: flex;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #b6b6b6;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;
const GroupNavItem = styled.div`
  margin-top: auto;
  text-transform: uppercase;
  // margin-right: 20px;
  // color: ${(props) => (props.sel ? "#000" : "#000")};
  color: #394b54;
  font-weight: ${(props) => (props.sel ? "600" : "400")};
  cursor: pointer;
`;
const Dash = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #b6b6b6;
  margin-top: auto;
  margin-left: 14px;
  margin-right: 14px;
`;
const TitleDash = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: ${(props) => (props.isMobile ? "#757575" : "#fff")};

  margin-left: 8px;
  margin-right: 8px;
`;
const ElementPanel = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
const MoreButton = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
`;
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   //   width: 700px;
//   margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const TopContainer = styled.div`
//   // max-width: 900px;
//   width: ${(props) => (props.isMobile ? "100%" : "auto")};
//   height: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   // margin-left: 0;
//   // margin-right: 0;
//   display: flex;
//   flex-direction: row;
// `;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "400px")};
  // max-width: 300px;
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 20px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`;
// const SettingsHeader = styled.div`
//   display: flex;
// `;
const ConfirmationDescription = styled.div`
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
   font-family: Roboto, sans-serif;
   font-size: 14px;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
// const SettingsColumns = styled.div`
//   display: flex;
// `;


const StyledButtonLeft = styled.div`
  
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-left: 30px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const MenuPanelNarrow = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
`;
// const SectionLabel = styled.div`
//   font-family: Roboto, sans-serif;  
//   font-size: 16px;
//   font-weight 400;
//   margin-left: 30px;
//   margin-top: 25px;
//   margin-bottom: 10px;
//   color: #757575;
//   text-align: left;
// `;
const MenuLine = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuIcon = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 13px;
  margin-left: 30px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 700;
`;
const MenuLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
// const MenuLabelNoIcon = styled.div`
//   font-family: Roboto, sans-serif;
//   font-size: 16px;
//   font-weight 400;
//   margin-top: 13px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   color: #212121;
//   margin-left: 30px;
// `;
const ElementCardMenu = styled.div`
  width: 300px;
  min-height: 200px;
  //   min-height: ${(props) =>
    props.cardheight ? props.cardheight : "300"}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  padding: 20px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: ${(props) =>
    props.isMobile ? "none" : "url('/lamp.png')"};
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  z-index: 1;
`;

const initialState = {
  showinvite: false,
  showusercard: false,
  inviteusername: "",
  isOwner: true,
  selectedProject: true,
  project_state: "ALL",
  selectedMember: false,
  selectedDiscussion: false,
  selectedAbout: false,
  selected: 1,
  showpopupedit: false,
  initial: true,
  collapsed: false,
  showLeaveConfirmation: false,
  showDeleteConfirmation: true,
  showstatepopup: false,
}

class GroupPage extends Component {
  memberListCutoff = 3;
  constructor() {
    super();
    this.state = initialState;
  }
  loadMoreProjects = (link) => {
    let state = false;
    if (this.state.project_state !== "ALL") {
      state = this.state.project_state;
    }

    this.props.dispatch(loadMoreGroupProjects(link, state));
  };
  handleGetProjects(project_state) {
    let state = false;
    if (project_state !== "ALL") {
      state = project_state;
    }

    this.props.dispatch(getGroupProjects(this.props.group.id, state));
  }
  handleShowMenu(type) {
    document.body.style.overflow = "hidden";
    this.props.dispatch(setGroupSettings(true, type));
  }
  handleLeaveGroup(type) {
    // document.body.style.overflow = "hidden";
    this.props.dispatch(leaveGroup(this.props.group.id));
  }
  handleShowConfirmation() {
    document.body.style.overflow = "hidden";
    this.setState({ showLeaveConfirmation: true });
  }

  handleshowinvite() {
    // if (this.state.showinvite === false) {
    //   this.setState({ showinvite: true });
    // } else {
    //   this.setState({ showinvite: false });
    // }
    this.props.dispatch(setMenu(true, 11));
  }
  handleshowNewProject() {
    // if (this.state.showinvite === false) {
    //   this.setState({ showinvite: true });
    // } else {
    //   this.setState({ showinvite: false });
    // }
    this.props.dispatch(setMenu(true, 20));
  }
  handleshowuser() {
    if (this.state.showusercard === false) {
      this.setState({ showusercard: true });
    } else {
      this.setState({ showusercard: false });
    }
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#f2f2f2";
    const groupid = window.location.pathname.split("/")[2];

    this.props.dispatch(getGroupData(groupid));
  }
  componentDidUpdate() {
    if (
      this.props.isMobile &&
      this.state.collapsed === false &&
      this.state.initial === true
    ) {
      this.setState({ collapsed: true });
      this.setState({ initial: false });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  doNothing(e) {
    e.stopPropagation();
  }
  followGroup() {
    const { group } = this.props;
    this.props.dispatch(followGroup(group.id));
  }
  requestAccess() {
    const { group, auth } = this.props;
    if (auth.isAuthenticated) {
      this.props.dispatch(requestAccessGroup(group.id));
    } else {
      this.props.dispatch(setMenu(true, 13));
    }

  }
  joinGroupAuto() {
    const { group, auth } = this.props;
    if (auth.isAuthenticated) {
      this.props.dispatch(joinGroupAuto(group.id));
    } else {
      this.props.dispatch(setMenu(true, 13));
    }

  }
  unfollowGroup() {
    const { group } = this.props;
    this.props.dispatch(unfollowGroup(group.id));
  }
  handleClosePopup() {
    //console.log("close");
    document.body.style.overflow = "auto";
    this.props.dispatch(setGroupSettings(false));
    this.setState({ showLeaveConfirmation: false });
    this.setState({ showDeleteConfirmation: false });
  }
  handleprojectState(state) {
    this.handleGetProjects(state);
    this.setState({ project_state: state });
    this.setState({ selected: 1 });
  }
  handleExpandInfo() {
    this.setState({ collapsed: false });
  }
  handleCollapseInfo() {
    this.setState({ collapsed: true });
  }
  changeNav = (tab) => {
    this.setState({ selected: tab });

  };
  handleshowStatePopup() {
    document.body.style.overflow = "hidden";
    this.setState({ showstatepopup: true });
  }
  removeUser(id) {
    const { group } = this.props;
    let updatemembers = [];
    group.members.forEach((member) => {
      if (member.id !== id) {
        updatemembers.push(member.id);
      }
    });
    // updatemembers.push("h1yQiy1Z_5FF7Tg0lHQpjfPyDyjOxDvtwT")

    //console.log("add");
    this.props.dispatch(removeMember(group.id, updatemembers, group.name));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  handleCloseMenu() {
    document.body.style.overflow = "auto";
    this.setState({ showstatepopup: false });
  }
  keyPress(e) {
    const { group } = this.props;
    let updatemembers = [];
    group.members.forEach((member) => {
      updatemembers.push(member.id);
    });
    // updatemembers.push("h1yQiy1Z_5FF7Tg0lHQpjfPyDyjOxDvtwT")
    if (e.keyCode === 13) {
      //console.log("add");
      this.props.dispatch(
        addMember(
          group.id,
          this.state.inviteusername,
          updatemembers,
          group.name
        )
      );
      this.setState({ showinvite: false });
      this.setState({ inviteusername: "" });
    }
  }
  unarchiveGroup() {
    this.props.dispatch(saveGroup(this.props.group.id, { name: this.props.group.name, archived: false }));
    this.setState({ archived: false })
  }
  render() {
    const { group, group_loading, projects, showGroupSettings, isMobile } =
      this.props;

    if (!group_loading && group && !this.state.titleSet) {
      this.setState({ titleSet: true })
      setTitle(`${group.name} | ${site_name}`)
    }

    let NoContent = false;
    // let NoFilterContent = false;
    //console.log(projects.no_results);

    let emptyText = "";
    let emptyText2 = "";
    let emptyText3 = "";
    if (projects.no_results !== 0) {
      NoContent = true;
      if (projects.no_results === 1) {
        if (!group.viewer_status || group.viewer_status === "RE") {
          emptyText = "Oh, that empty feeling!";
          emptyText2 =
            "You need to be a member of this group to see the projects.";
          if (group.allow_join_requests && !group.viewer_status) {
            emptyText3 =
              "Use the button on the left to request access.";
          } else {
            emptyText3 =
              "";
          }
          if (group.allow_join_auto && !group.viewer_status) {
            emptyText3 =
              "Use the button on the left to join this group.";
          } else {
            emptyText3 =
              "";
          }
        } else {
          emptyText = "Oh, that empty feeling!";
          emptyText2 =
            "It looks like no projects are being shared to this group.";
          emptyText3 =
            "Group members can add projects by selecting this group in the sharing settings of the project.";
        }

      }
      if (projects.no_results === 2) {
        emptyText = "There are no projects in this filter set";
        emptyText2 = "";
        emptyText3 = "";
      }
    }
    let imagesource = "../../default_group_header.jpg";
    if (group.image) {

      imagesource = api_endpoint + "/media/" + group.image.id + "/file?size=2048"


    }
    if (group_loading === false) {
      const established = monthYear(group.created_at);
      let subtitle = "In Progress";

      switch (this.state.project_state) {
        case "ALL":
          subtitle = "All states";
          break;
        case "IN":
          subtitle = "In Progress";
          break;
        case "TO":
          subtitle = "Planned";
          break;
        case "CO":
          subtitle = "Completed";
          break;
        default:
        // code block
      }
      // let grouptype = "";
      // switch (group.group_type) {
      //   case "CO":
      //     grouptype = "Collaboration Group";
      //     break;
      //   case "PU":
      //     grouptype = "Publication Group";
      //     break;
      //   case "FE":
      //     grouptype = "Feedback Group";
      //     break;
      //   default:
      //     break;
      // }
      return (
        <div style={{ height: "100%" }}>

          {this.state.showstatepopup ? (
            <div>
              <StyledElementPopup
                onClick={() => {
                  this.handleCloseMenu();
                }}
              >
                <MenuPanelNarrow
                  onClick={() => {
                    this.handleCloseMenu();
                  }}
                >
                  <ElementCardMenu>
                    <SectionLabel>Select a Project State</SectionLabel>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      <MenuIcon></MenuIcon>
                      <MenuLabel>Show All</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("IN");
                      }}
                    >
                      <MenuIcon>{group.total_inprogress}</MenuIcon>
                      <MenuLabel>In Progress</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("TO");
                      }}
                    >
                      <MenuIcon>{group.total_planned}</MenuIcon>
                      <MenuLabel>Planned</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("CO");
                      }}
                    >
                      <MenuIcon>{group.total_completed}</MenuIcon>
                      <MenuLabel>Completed</MenuLabel>
                    </MenuLine>
                  </ElementCardMenu>
                </MenuPanelNarrow>
              </StyledElementPopup>
            </div>
          ) : (
            ""
          )}
          {this.state.showLeaveConfirmation ? (
            <StyledElementPopup

            // onMouseDown={() => {
            //   this.handleClosePopup();
            // }}
            >
              <ElementPanel
                isMobile={isMobile}
                onMouseDown={(e) => {
                  this.doNothing(e);
                }}>
                <ElementContainer isMobile={isMobile}>
                  <TopContainer isMobile={isMobile}>
                    <Dialog isMobile={isMobile} dialog_height={"auto"} dialog_width={"auto"}>
                      <SettingsHeader isMobile={isMobile}>
                        <SettingsTitle isMobile={isMobile}>
                          Do you really want to leave this group?
                        </SettingsTitle>

                        <CloseButtonNarrow
                          isMobile={isMobile}
                          onClick={() => {
                            this.handleClosePopup();
                          }}>
                          {" "}
                          <IconButton>
                            <CloseIcon sx={{ fontSize: "22px" }} />
                          </IconButton>
                        </CloseButtonNarrow>
                      </SettingsHeader>
                      <ConfirmationDescription>
                        You will only be able to join this group again if an admin
                        reinvites you.
                      </ConfirmationDescription>
                      <PaddingContainer>
                        <StyledButtonContainer w={this.state.panelW}>
                          <StyledLeftButtons
                            isMobile={isMobile}>
                            <StyledButtonDelete>
                              <Button
                                disableElevation
                                variant="contained"
                                color="secondary"
                                style={{ minWidth: "128px" }}
                                onClick={() => {
                                  this.handleClosePopup();
                                }}>
                                No
                              </Button>
                            </StyledButtonDelete>
                          </StyledLeftButtons>

                          <StyledButton>
                            <Button
                              disableElevation
                              variant="contained"
                              color="primary"
                              style={{
                                minWidth: "128px",
                                borderRadius: "16px",
                              }}
                              onClick={() => {
                                this.handleLeaveGroup();
                              }}>
                              Yes, Leave Group
                            </Button>
                          </StyledButton>
                        </StyledButtonContainer>
                      </PaddingContainer>
                    </Dialog>
                  </TopContainer>
                </ElementContainer>
              </ElementPanel>
            </StyledElementPopup>
          ) : (
            ""
          )}
          {showGroupSettings ? (
            <StyledElementPopup
              onMouseDown={() => {
                this.handleClosePopup();
              }}
            >
              <ElementPanel
                isMobile={isMobile}
                onMouseDown={(e) => {
                  this.doNothing(e);
                }}
              >
                <GroupEdit dialogType={"edit"} />
              </ElementPanel>
            </StyledElementPopup>
          ) : (
            ""
          )}
          <StyledHeader src={imagesource}>
            <InfoTitleName>
              <span>{group.name}</span>
            </InfoTitleName>
            <Gradient>


            </Gradient>

          </StyledHeader>
          <BottomPanel isMobile={isMobile}>
            <LeftInfoBar isMobile={isMobile}>
              <InfoTitleContainer>
                {/* <InfoTitleAvatar src={imagesource}>
                  {" "}
                  <PeopleIcon
                    style={{
                      marginLeft: "17px",
                      marginTop: "17px",
                      color: "#fff",
                      fontSize: "70px",
                    }}
                  />
                </InfoTitleAvatar> */}
                <InfoTitleRight isMobile={isMobile}>
                  {group.allow_join_auto && !group.viewer_status ? (<Button
                    style={{}}
                    onClick={() => {
                      this.joinGroupAuto();
                    }}
                    disableElevation

                    color="primary"
                    variant="contained"
                    startIcon={<GroupAddIcon />}
                  >
                    Join
                  </Button>) : ("")}
                  {group.allow_join_requests && !group.viewer_status && !group.allow_join_auto ? (<Button
                    style={{}}
                    onClick={() => {
                      this.requestAccess();
                    }}
                    disableElevation

                    color="primary"
                    variant="contained"
                    startIcon={<GroupAddIcon />}
                  >
                    Request Access
                  </Button>) : ("")}
                  {group.allow_join_requests && group.viewer_status === "RE" && !group.allow_join_auto ? (<Requested>Your Access Request is Pending</Requested>) : ("")}
                  {group.viewer_status === "AC" ? (<Button
                    onClick={() => {
                      this.handleshowNewProject()
                    }}
                    style={{ width: "136px" }}
                    disableElevation
                    variant="contained"
                    color="primary"
                  >
                    Add Project
                  </Button>) : ("")}
                  {isMobile ? (<RightButtonsMobile >
                    {group.viewer_status && group.viewer_status !== "RE" ? (<div>{group.viewer_is_subscribed ? (
                      <IconButton
                        title="Unsubscribe"
                        size="small"
                        onClick={() => {
                          this.unfollowGroup();
                        }}
                      >
                        <StarIcon style={{ color: "#008EFF" }} />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          this.followGroup();
                        }}
                        disableElevation
                        style={{ color: "#008EFF" }}
                        color="primary"
                        startIcon={<StarOutlineIcon />}
                      >
                        Subscribe
                      </Button>
                    )}</div>) : ("")}

                  </RightButtonsMobile>) : ("")}

                </InfoTitleRight>
              </InfoTitleContainer>
              {this.state.collapsed ? (
                <GroupNavBarContainer>
                  <GroupNavBar>
                    <GroupNavItem
                      onClick={() => {
                        this.changeNav(3);
                      }}
                      sel={this.state.selected === 3 ? (true) : (false)}
                    >
                      About
                    </GroupNavItem>
                    <Dash>-</Dash>
                    <GroupNavItem
                      onClick={() => {
                        this.changeNav(1);
                      }}
                      sel={this.state.selected === 1 ? (true) : (false)}
                    >
                      Projects
                    </GroupNavItem>
                    <Dash>-</Dash>
                    <GroupNavItem
                      onClick={() => {
                        this.changeNav(2);
                      }}
                      sel={this.state.selected === 2 ? (true) : (false)}
                    >
                      Participants
                    </GroupNavItem>

                    {/* <GroupNavItem onClick={() => {this.changeNav("discussion");}} sel = {this.state.selectedDiscussion}>Discusssion</GroupNavItem> */}
                  </GroupNavBar>
                  <InfoExpandContainer>
                    <InfoExpand>
                      <IconButton
                        title="Settings"
                        size="small"
                        onClick={() => {
                          this.handleExpandInfo();
                        }}
                      >
                        <ExpandMoreOutlinedIcon fontSize="20px" />{" "}
                      </IconButton>
                    </InfoExpand>
                  </InfoExpandContainer>
                </GroupNavBarContainer>
              ) : (
                <div>
                  <InfoContainer>
                    <InfoHeader
                      onClick={() => {
                        this.changeNav(3);
                      }}
                    >
                      About
                    </InfoHeader>
                    <InfoAboutText>{group.description}</InfoAboutText>
                    {group.archived ? (
                      <InfoAboutText>
                        <InventoryIcon sx={{ fontSize: "14px" }} /> <b>This group is archived.</b>
                      </InfoAboutText>) : null}
                  </InfoContainer>

                  <InfoContainer>
                    <InfoHeader
                      onClick={() => {
                        this.changeNav(1);
                      }}
                    >
                      Projects
                    </InfoHeader>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("IN");
                      }}
                    >
                      <InfoCommandNumber>
                        {" "}
                        {group.total_inprogress}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>
                        In Progress
                      </InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("TO");
                      }}
                    >
                      <InfoCommandNumber>
                        {" "}
                        {group.total_planned}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>Planned</InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("CO");
                      }}
                    >
                      <InfoCommandNumber>
                        {" "}
                        {group.total_completed}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>Completed</InfoCommandTextProject>
                    </InfoCommandProject>
                    <InfoCommandProject
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      <InfoCommandTextProject style={{ marginLeft: "0px" }}>
                        Show All
                      </InfoCommandTextProject>
                    </InfoCommandProject>
                  </InfoContainer>
                  <InfoContainer>
                    <InfoHeader
                      onClick={() => {
                        this.changeNav(2);
                      }}
                    >
                      Participants
                    </InfoHeader>

                    {group.allmembers.allIDS
                      .filter((id, index) => index <= this.memberListCutoff)
                      .map((id, index) => {
                        const member = group.allmembers.byID[id];
                        if (index < this.memberListCutoff) {
                          return (
                            <InfoMember key={"m" + index} index={"m" + index}>
                              <PersonThumb {...member} role={member.role} groupid={group.id} size="s" />
                            </InfoMember>
                          );
                        }
                        return (
                          <InfoCommand
                            key={"m" + index}
                            index={"m" + index}
                            onClick={() => {
                              this.changeNav(2);
                            }}
                          >
                            <InfoCommandText>View All Participants</InfoCommandText>
                          </InfoCommand>
                        );
                      })}
                    {group.viewer_status === false ? (<InfoCommandProject

                    >
                      <InfoCommandNumber>
                        {" "}
                        {group.total_members}
                      </InfoCommandNumber>
                      <InfoCommandTextProject>Participants</InfoCommandTextProject>
                    </InfoCommandProject>) : ("")}
                  </InfoContainer>
                  {group.viewer_is_admin ? (
                    <InfoContainer>

                      <InfoCommand
                        onClick={() => {
                          this.handleshowinvite();
                        }}
                      >
                        <PersonAddIcon fontSize="inherit" />{" "}
                        <InfoCommandText>Invite</InfoCommandText>
                      </InfoCommand>
                      {group.total_requests !== 0 ? (
                        <InfoCommand
                          onClick={() => {
                            this.handleShowMenu("people_request");
                          }}
                        >
                          <GroupAddIcon fontSize="inherit" />{" "}
                          <InfoCommandText>New Join Requests ({group.total_requests})</InfoCommandText>
                        </InfoCommand>) : ("")}

                      <InfoCommand
                        onClick={() => {
                          this.handleShowMenu("people");
                        }}
                      >
                        <PeopleIcon fontSize="inherit" />{" "}
                        <InfoCommandText>Manage Participants</InfoCommandText>
                      </InfoCommand>
                      {group.archived ? (
                        <InfoCommand
                          onClick={() => {
                            this.unarchiveGroup();
                          }}
                        >
                          <UnarchiveIcon fontSize="inherit" />{" "}
                          <InfoCommandText>Unarchive Group</InfoCommandText>
                        </InfoCommand>
                      ) : (
                        <InfoCommand
                          onClick={() => {
                            this.handleShowMenu("settings");
                          }}
                        >
                          <SettingsIcon fontSize="inherit" />{" "}
                          <InfoCommandText>Group Settings</InfoCommandText>
                        </InfoCommand>
                      )}
                    </InfoContainer>
                  ) : (
                    ""
                  )}

                  {group.viewer_status && !group.viewer_is_admin ? (<InfoContainer>
                    <InfoCommand
                      onClick={() => {
                        this.handleShowConfirmation();
                      }}
                    >
                      <PeopleIcon fontSize="inherit" />{" "}
                      <InfoCommandText>Leave Group</InfoCommandText>
                    </InfoCommand>
                  </InfoContainer>) : ("")}
                  <InfoContainer>
                    <InfoEst>Formed {established}</InfoEst>
                  </InfoContainer>
                  {isMobile ? (
                    <InfoCollapseContainer>
                      <InfoExpand>
                        <IconButton
                          title="Settings"
                          size="small"
                          onClick={() => {
                            this.handleCollapseInfo();
                          }}
                        >
                          <ExpandLessOutlinedIcon fontSize="20px" />{" "}
                        </IconButton>
                      </InfoExpand>
                    </InfoCollapseContainer>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </LeftInfoBar>

            <RightSection isMobile={isMobile}>

              <SectionContainer show={this.state.selected === 1 ? true : false}>
                <PageTitle isMobile={isMobile}>
                  <BaseTitle isMobile={isMobile}>Projects </BaseTitle>{" "}
                  <TitleDash>-</TitleDash>{" "}
                  <SubTitle
                    isMobile={isMobile}
                    onClick={(e) => {
                      this.handleshowStatePopup();
                    }}
                  >
                    {subtitle}
                  </SubTitle>
                  <RightButtons>
                    {group.viewer_status && group.viewer_status !== "RE" ? (<div>{group.viewer_is_subscribed ? (
                      <IconButton
                        title="Unsubscribe"
                        size="small"
                        onClick={() => {
                          this.unfollowGroup();
                        }}
                      >
                        <StarIcon style={{ color: "#008EFF" }} />
                      </IconButton>
                    ) : (
                      <Button
                        onClick={() => {
                          this.followGroup();
                        }}
                        disableElevation
                        style={{ color: "#008EFF" }}
                        color="primary"
                        startIcon={<StarOutlineIcon />}
                      >
                        Subscribe
                      </Button>
                    )}</div>) : ("")}

                  </RightButtons>
                </PageTitle>
                <Projectcontainer isMobile={isMobile}>
                  {NoContent ? (
                    <EmptyProject>
                      <EmptyContainer>
                        <EmptyTitle>{emptyText}</EmptyTitle>
                        <EmptyInstruction>
                          {emptyText2}
                          <br />

                          {emptyText3}
                        </EmptyInstruction>{" "}
                      </EmptyContainer>

                    </EmptyProject>
                  ) : (
                    ""
                  )}

                  {projects.allIDS.map((pid, index) => {
                    const project = projects.byID[pid];
                    return (
                      <ProjectSpacer key={index}>
                        <ProjectThumb {...project} group={!group.viewer_status ? null : group.id} count={project.total_elements} link={true} />
                      </ProjectSpacer>
                    );
                  })}
                  {projects.next ? (
                    <MoreButton>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.loadMoreProjects(projects.next);
                        }}
                      >
                        Load More Projects...
                      </Button>
                    </MoreButton>
                  ) : (
                    ""
                  )}
                </Projectcontainer>
              </SectionContainer>


              <SectionContainer show={this.state.selected === 2 ? true : false}>
                <PageTitle isMobile={isMobile}>
                  <BaseTitle isMobile={isMobile}>Participants</BaseTitle>
                </PageTitle>
                <Participantcontainer isMobile={isMobile}>
                  {group.allmembers.allIDS.map((id, index) => (
                    <ProjectSpacer key={"mem" + index}>
                      <PersonThumb
                        username={group.allmembers.byID[id].username}
                        src={
                          group.allmembers.byID[id].avatar &&
                          api_endpoint + "/media/" + group.allmembers.byID[id].avatar.id + "/file?size=256"
                        }
                        fullname={group.allmembers.byID[id].fullname}
                        tag_line={group.allmembers.byID[id].tag_line}
                        role={group.allmembers.byID[id].role}
                        groupid={group.id}
                        size="l"
                      />
                    </ProjectSpacer>
                  ))}
                </Participantcontainer>
              </SectionContainer>


              <SectionContainer show={this.state.selected === 3 ? true : false}>
                <PageTitle isMobile={isMobile}>
                  <BaseTitle isMobile={isMobile}>About</BaseTitle>
                </PageTitle>
                <Aboutcontainer isMobile={isMobile}>{group.description}</Aboutcontainer>
              </SectionContainer>


            </RightSection>
          </BottomPanel>
        </div>
      );
    } else {
      return <p>loading...</p>;
    }
  }
}

const mapStateToProps = (state) => ({
  group: state.groups.current_group,
  group_loading: state.groups.group_loading,
  projects: state.groups.current_group.projects,
  showGroupSettings: state.ui.showGroupSettings,
  isMobile: state.dimensions.window_dimensions.isMobile,
  auth: state.auth
});
export default connect(mapStateToProps)(GroupPage);
