import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import doNotTrack from 'analytics-plugin-do-not-track'
import { ga_measurement_id, debug, site_name } from "../config";


const debugPlugin = {
    name: 'debug-plugin',
    page: ({ payload }) => {
        console.log('page view fired', payload)
    },
    track: ({ payload }) => {
        console.log('track event payload', payload)
    },
    identify: ({ payload }) => {
        console.log('identify event triggered', payload)
    }
}

const analyticsPlugins = [
    doNotTrack(),
    googleAnalytics({
        measurementIds: [ga_measurement_id],
    }),
    googleTagManager({
        containerId: ga_measurement_id
    })
]

if (debug) {
    analyticsPlugins.push(debugPlugin)
}

const analyticsInstance = Analytics({
    app: site_name,
    plugins: analyticsPlugins,
    debug: debug
})

export default analyticsInstance