import { useState, useEffect } from "react";

const useVideoPlayer = (videoElement) => {
    const [playerState, setPlayerState] = useState({
        isLoaded: false,
        isPlaying: false,
        progress: 0,
        speed: 1,
        isMuted: false,
    });

    const handleOnLoad = () => {
        setPlayerState({
            ...playerState,
            isLoaded: true
        });
    };

    const handleOnTimeUpdate = () => {
        const progress = videoElement.current.currentTime / videoElement.current.duration;
        setPlayerState({
            ...playerState,
            progress,
        });
    };

    const handleOnVideoEnded = () => {
        setPlayerState({
            ...playerState,
            isPlaying: false
        });
    };

    const handleVideoProgress = (seek) => {
        const manualChange = Number(seek);
        videoElement.current.currentTime = videoElement.current.duration * manualChange;
        setPlayerState({
            ...playerState,
            progress: manualChange,
        });
    };

    const handleVideoSpeed = (event) => {
        const speed = Number(event.target.value);
        videoElement.current.playbackRate = speed;
        setPlayerState({
            ...playerState,
            speed,
        });
    };

    const togglePlay = () => {
        setPlayerState({
            ...playerState,
            isPlaying: !playerState.isPlaying,
        });
    };

    useEffect(() => {
        playerState.isPlaying
            ? videoElement.current.play()
            : videoElement.current.pause();
    }, [playerState.isPlaying, videoElement]);

    const toggleMute = () => {
        setPlayerState({
            ...playerState,
            isMuted: !playerState.isMuted,
        });
    };

    useEffect(() => {
        playerState.isMuted
            ? (videoElement.current.muted = true)
            : (videoElement.current.muted = false);
    }, [playerState.isMuted, videoElement]);

    return {
        playerState,
        togglePlay,
        handleOnLoad,
        handleOnTimeUpdate,
        handleOnVideoEnded,
        handleVideoProgress,
        handleVideoSpeed,
        toggleMute,
    };
};

export default useVideoPlayer;