import React, { Component } from "react";
import styled from "@emotion/styled";
import calcSVG from "../../utils/svgText";
import Line from "../drawing/Line";
import Rect from "../drawing/Rect";
import Circ from "../drawing/Circ";
import SimpleGrid from "../drawing/Grid";
import GoldenGrid from "../drawing/GoldenGrid";
import PhiGrid from "../drawing/PhiGrid";
import AngleGrid from "../drawing/AngleGrid";
import { api_endpoint } from "../../config";
const ElementImage = styled.div`
  // border-radius: 1.5%;

  // margin: 2.5%;
  width: 100%;
  height: 80%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "contain"
      : "cover"};
  // background-size: cover;
  // background-size: contain;
`;
const SVGContent = styled.svg`
  z-index: 805;
  // mix-blend-mode: overlay;
  position: absolute;

  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const CropContainer = styled.div`
position: absolute;
  // border-radius: 1.5%;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #383838;
  overflow: hidden;
  align-items: center;
  flex: 1 1 0;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
`;
const CropContainerRight = styled.div`
  position: absolute;
  // border-radius: 1.5%;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #383838;
  overflow: hidden;
  align-items: center;
  flex: 1 1 0;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
`;
const CropContainerDescr = styled.div`
  // border-radius: 1.5%;
  display: flex;
  width: 100%;
  height: 80%;
  background-color: #383838;
  overflow: hidden;
  align-items: center;
  flex: 1 1 0;
`;

const PositionContainer = styled.div`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  position: relative;
  opacity: ${(props) => props.o};
  margin: auto;
  margin-top: ${(props) =>
    props.show_full === "true" || props.show_full === true ? "auto" : props.description ? "40%" : "50%"};
  margin-left: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "auto"
      : props.w === "100%"
      ? "auto"
      : "50%"};

  transform: ${(props) =>
    props.show_full === "true" || props.show_full === true
      ? "none"
      : props.w === "100%"
      ? "translate(0%, -50%)"
      : "translate(-50%, -50%)"};
`;
const ElementImageOnly = styled.div`
  // border-radius: 1.5%;

  width: 50%;
  height: 100%;
  background-color: #383838;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
`;
const ElementImageOnlyB = styled.div`
  // border-radius: 1.5%;

  width: 50%;
  height: 100%;
  background-color: #383838;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
`;
const ImageWrapper = styled.div`
  // border-radius: 1.5%;
position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #383838;
`;
const ImageWrapperDescr = styled.div`
  // border-radius: 1.5%;
    position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  background-color: #383838;
`;
const ElementVideoOnly = styled.video`
  width: 100%;
  height: 100%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  object-fit: ${(props) =>
    props.show_full === "true" || props.show_full === true ? "auto" : "cover"};
  z-index: 900;
`;
const ElementVideo = styled.video`
  border-radius: 1.5%;
  margin: 2.5%;
  width: 95%;
  height: 77%;
  background-color: #383838;
  background-position: 50%;
  background-repeat: no-repeat;
  object-fit: ${(props) => (props.show_full === "true" ? "auto" : "cover")};
`;
const ElementDescription = styled.div`
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14")}px;
  width: 95%;
  height: 15%;
  margin: 2.5%;
  text-decoration: none !important;
  color: #111;
  position: relative;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
`;
const ElementDescriptionTextOnlySVG = styled.div`
  width: 86%;
  height: 91%;
  margin: 7%;
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;
`;
const DividerLine = styled.div`
  position: relative;
  width: 1px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  margin-top: auto;
  background-color: #fff;
  margin-bottom: auto;
`;
const VSBadge = styled.div`
  font-family: Roboto;
  z-index: 1;
  line-height: 40px;

  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #383838;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  left: -20px;
  top: calc(50% - 20px);
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  background-color: #fff;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const ElementContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Tag = styled.div`
  width: 100%;
  // height: 100%;turning this off makes it work on iOS, but resizes different on ios
  position: absolute;
  margin-right: ${(props) => (props.feedplacement ? "0" : "0")}px;
  margin-top: ${(props) => (props.feedplacement ? "0" : "0")}px;
  right: 0;
  top: 0;
  z-index: 901;
  // background-color: #ccc;
`;
const CompareBadge = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  margin-right: ${(props) => (props.feedplacement ? "0" : "0")}px;
  margin-top: ${(props) => (props.feedplacement ? "0" : "0")}px;
 
  z-index: 901;
  // background-color: #ccc;
`;
const SliderHandleContainer = styled.div`
  height: 100%;
  width: 30px;
  z-index: 806;
  position: absolute;
  left: 50%;
  // background-color: #ffffff;
  margin-left: -15px;
  display: flex;
  flex-direction: column;
`;

const SliderCircle = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  place-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  pointer-events: auto;
  backdrop-filter: blur(7px);
  //     position: absolute;
  // top: 50%;
  margin-left: -7px;
  // box-shadow: 0 0 7px rgba(0;0;0;.35);
  // transform: portrait ? rotate(90deg) : undefined;
`;
const SliderArrow2 = styled.div`
  width: 0px;
  height: 0px;
  border-top: 6px solid transparent;
  border-right: 8px solid;
  border-bottom: 6px solid transparent;
  transform: rotate(180deg);
  color: #ffffff;
`;
const SliderArrow = styled.div`
  width: 0px;
  height: 0px;
  border-top: 6px solid transparent;
  border-right: 8px solid;
  border-bottom: 6px solid transparent;

  color: #ffffff;
`;
const SliderHandle = styled.div`
  // flexGrow: 1;
  height: 50%;
  width: 1px;
  background-color: #ffffff;
  pointer-events: auto;
  // box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);

  margin-left: 14px;

  // position: absolute;
  // left: 50%;
`;
const PreviewContentCompare = styled.div`
  flex-direction: row;
  position: relative;

  background-color: #383838;
  width: 100%;
  height: ${(props) => (props.isMobile ? (props.isOverlay === "OV" ? props.h : "auto") : props.expandImage ? (props.isOverlay === "OV" ? props.h2 : "auto"):  "100%")};
  background-color: #000;
  display: flex;
`;
// const ImageWrapper = styled.div`
//   position: relative;
//   width: ${(props) => props.w};
//   height: ${(props) => props.h};
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: auto;
//   margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
// `;
const CompareWrapper = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  // clip-path: ${(props) =>
    "polygon(0 0 , " + props.clip + "% 0, " + props.clip + "% 100%, 0 100%)"};
  // clip-path: ${(props) =>
    props.type === "SS"
      ? "none"
      : "polygon(0 0 , " +
        props.clip +
        "% 0, " +
        props.clip +
        "% 100%, 0 100%)"};;
`;
const CompareWrapperRight = styled.div`
  display: flex;
  position: ${(props) => (props.type === "SS" ? "relative" : "absolute")};
  width: ${(props) => (props.type === "SS" ? "50%" : "100%")};
  height: 100%;
  overflow: hidden;
  clip-path: ${(props) =>
    "polygon(" +
    props.clip +
    "% 0 , 100% 0, 100% 100%, " +
    props.clip +
    "% 100%)"};
`;
const OverlayCorrection = styled.div`
  position: relative;
  width: ${(props) => props.w}px;
  display: flex;
  height: ${(props) => props.h}px;

  margin: auto;
  overflow: hidden;
`;
const FirstImage = styled.div`
  position: relative;
  display: flex;
  width: ${(props) => props.w};
  height: ${(props) => props.h};

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #ccc;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
const SVGWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-top: auto;

  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;

const SecondImage = styled.div`
  position: relative;
  width: ${(props) => props.w};
  display: flex;
  height: ${(props) => props.h};
  transform: ${(props) =>
    "translateX(" +
    props.translate_x / 10 +
    "%) scale(" +
    (1 + props.scale / 100) +
    ") rotate(" +
    props.rotate +
    "deg) translateY(" +
    props.translate_y / 10 +
    "%)"};
  opacity: ${(props) => props.opacity / 100};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  // background-color: #f4f2f2;
  margin-bottom: ${(props) => (props.isMobile ? "-4px" : "auto")};
`;
class Compare extends Component {
  constructor(props) {
    super(props);
    this.bkgImage = React.createRef();
    this.bkgImageB = React.createRef();
    this.svgRef = React.createRef();
    this.svgRefB = React.createRef();
    this.state = {
      ratioA: 1,
      imageAWidth: "auto",
      imageWidthWrapper: "auto",
      imageAHeight: "auto",
   
      imageBHeight: 1,
      imageBWidth: 1,
      ratioB: 1,
      visibility: "hidden",
      opacity: "0",
      viewboxA: "0 0 100 100",
      viewboxB: "0 0 100 100",
      imageAloaded: false,
      imageBloaded: false,
      scale_b: 0,
      translate_x_b: 0,
      translate_y_b: 0,
      rotate_b: 0,
      opacity_b: 100,
      comparison_type: "SS",
    };
  }
  HandleImageLoaded = (number) => {
    const { show_full } = this.props;
    //console.log("loaded")
    if (number === 1) {
      const rect = this.bkgImage.current.getBoundingClientRect();

      const ratio = rect.width / rect.height;
      const viewboxA = "0 0 100 " + 100 / ratio;
      // console.log(ratio);
      this.setState({ viewboxA: viewboxA });
      this.setState({ ratioA: ratio });

      this.setImageDimensions();
      //  this.setImageDimensions()
      this.setState({ imageAloaded: true });
    }
    if (number === 2) {
      const rect = this.bkgImageB.current.getBoundingClientRect();

      const ratio = rect.width / rect.height;
      const viewboxB = "0 0 100 " + 100 / ratio;
      // console.log(ratio);
      this.setState({ viewboxB: viewboxB });
      this.setState({ ratioB: ratio });

      this.setImageDimensions();
      //  this.setImageDimensions()
      this.setState({ imageBloaded: true });
    }
  };
  setImageDimensions() {
   
    let image_w_a;
    let image_w_a_wrapper;
    let image_h_a;

    let image_w_b;
    let image_h_b;
    const show_full = true;
    // console.log("id");
    // const rect = this.bkgImage.current.getBoundingClientRect();
    if (this.state.ratioA >= 1) {
      if (show_full) {
        image_w_a = "100%";

        image_h_a = "auto";
      } else {
        image_w_a = "auto";

        image_h_a = "100%";
      }
    } else {
      if (show_full) {
        image_w_a = "auto";
        image_h_a = "100%";
      } else {
        image_w_a = "100%";

        image_h_a = "auto";
      }
    }
    if (this.state.ratioB >= 1) {
      if (show_full) {
        image_w_b = "100%";

        image_h_b = "auto";
      } else {
        image_w_b = "auto";

        image_h_b = "100%";
      }
    } else {
      if (show_full) {
        image_w_b = "auto";
        image_h_b = "100%";
      } else {
        image_w_b = "100%";

        image_h_b = "auto";
      }
    }

    if (this.state.imageAHeight !== image_h_a) {
      //console.log("set");
      this.setState({ imageAHeight: image_h_a });
    }

    if (this.state.imageAWidth !== image_w_a) {
      this.setState({ imageAWidth: image_w_a });
    }
    if (this.state.imageBHeight !== image_h_b) {
      //console.log("set");
      this.setState({ imageBHeight: image_h_b });
    }

    if (this.state.imageBWidth !== image_w_b) {
      this.setState({ imageBWidth: image_w_b });
    }
    if (this.bkgImage) {
      if (this.bkgImage.current) {
        if (
          this.state.overlayW !==
          this.bkgImage.current.getBoundingClientRect().width
        ) {
          this.setState({
            overlayW: this.bkgImage.current.getBoundingClientRect().width,
          });
        }
        if (
          this.state.overlayH !==
          this.bkgImage.current.getBoundingClientRect().height
        ) {
          this.setState({
            overlayH: this.bkgImage.current.getBoundingClientRect().height,
          });
        }
      }
    }
    if (this.state.imageAloaded && this.state.imageBloaded) {
      if (this.state.visibility !== "visible") {
        this.setState({ visibility: "visible" });
        this.setState({ opacity: "1" });
      }
    }
  }
  componentDidMount(){
    const { element } = this.props;
    if (element.metadata) {
      if (element.metadata.compare_type) {
        if (element.metadata.compare_type === "SS") {
          this.setState({ clip: 0 });
          this.setState({ compareSxS: true });
        } else {
          this.setState({ clip: 50 });
          this.setState({ compareSxS: false });
        }
      }
    }

    this.setState({
      comparison_type: element.metadata
        ? element.metadata.compare_type
          ? element.metadata.compare_type
          : "SS"
        : "SS",
    });
    this.setState({
      scale_b: element.metadata
        ? element.metadata.resize
          ? element.metadata.resize
          : 0
        : 0,
    });
    this.setState({
      translate_x_b: element.metadata
        ? element.metadata.horizontal
          ? element.metadata.horizontal
          : 0
        : 0,
    });
    this.setState({
      translate_y_b: element.metadata
        ? element.metadata.vertical
          ? element.metadata.vertical
          : 0
        : 0,
    });
    this.setState({
      rotate_b: element.metadata
        ? element.metadata.rotate
          ? element.metadata.rotate
          : 0
        : 0,
    });
    this.setState({
      opacity_b: element.metadata
        ? element.metadata.opacity
          ? element.metadata.opacity
          : 100
        : 100,
    });
    this.setState({
      objects: element.metadata
        ? element.metadata.drawing
          ? element.metadata.drawing
          : []
        : [],
    });
  }
  componentDidUpdate() {
    const {  element } = this.props;
    if (this.state.comparison_type !== element.metadata.compare_type ){
      this.setState({
        comparison_type: element.metadata
          ? element.metadata.compare_type
            ? element.metadata.compare_type
            : "SS"
          : "SS",
      });
      if (element.metadata.compare_type === "SS") {
        this.setState({ clip: 0 });
       
      } else {
        this.setState({ clip: 50 });
      
      }
    }
   

   
    this.setImageDimensions();
  }
  render() {
    const {
      image_url,
      image_urlB,
      description,
      tag,
      color,
      feedplacement,
      show_full,
      isVideo,
      VideoControls,
      compare_type,
      drawing, 
      image_order,
      lazyload
    } = this.props;
    let labelname = "";
    let labelcolor = "";
    switch (tag) {
      case "TL":
        labelname = "TOOL";
        labelcolor = "#EA1B65";
        break;
      case "MT":
        labelname = "MATERIAL";
        labelcolor = "#2196F3";
        break;
      case "TD":
        labelname = "TO DO";
        labelcolor = "#383838";
        break;
      case "QN":
        labelname = "QUESTION";
        labelcolor = "#f7cd00";
        break;
      default:
      // code block
    }
    let imageA, imageB
    if (image_order.length === 2){
      imageA = api_endpoint+"/media/"+image_order[0]+"/file?size=1024";
      imageB = api_endpoint+"/media/"+image_order[1]+"/file?size=1024";
    }else{
      imageA = image_url
      imageB = image_urlB
    }
    let loadingsetting = "eager"
    if (lazyload){
      loadingsetting = "lazy"
    }
    if (description) {
      return (
        <ElementContainer>
          {tag ? (
            <Tag feedplacement={feedplacement}>
              <svg
                style={{ width: "100%", height: "100%" }}
                viewBox="0 0 800 800"
                // width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  style={{
                    fill: labelcolor,
                  }}
                  d="M 600 0 L 680 0 L 840 160 L 840 240"
                />
                <text
                  letterSpacing="1.5"
                  fontSize="28"
                  fontFamily="Arial"
                  fill="#fff"
                  textAnchor="middle"
                  transform="translate(726,100) rotate(45)">
                  {labelname}
                </text>
              </svg>
            </Tag>
          ) : (
            ""
          )}
          <ImageWrapperDescr>
          <CompareBadge feedplacement={feedplacement}>
          {compare_type === "SS" ? (
             <svg
             style={{ width: "100%", height: "100%" }}
             viewBox="0 0 800 800"
             // width="100%"
             // height="100%"
             xmlns="http://www.w3.org/2000/svg">
             <line x1="400" y1="0" x2="400" y2="800" stroke="#fff" strokeWidth="3"></line>
             <ellipse cx="400" cy="400" rx="40" ry="40" fill="#fff" ></ellipse>
             <text
                     letterSpacing="1.5"
                     fontSize="20"
                     fontWeight="bold"
                     fontFamily="Arial"
                     fill="#383838"
                     textAnchor="middle"
                     transform="translate(400,410) ">
                     VS
                   </text>
           </svg>
        ):( <svg
          style={{ width: "100%", height: "100%" }}
          viewBox="0 0 800 800"
          // width="100%"
          // height="100%"
          xmlns="http://www.w3.org/2000/svg">
          <line x1="400" y1="0" x2="400" y2="800" stroke="#fff" strokeWidth="3"></line>
          <ellipse cx="400" cy="400" rx="40" ry="40" fill="#fff" ></ellipse>
          <polygon points="405 390,405 410,420 400" fill="#383838"></polygon>
          <polygon points="395 390,395 410,380 400" fill="#383838"></polygon>
        </svg>)}
            </CompareBadge>
     
          <PreviewContentCompare
              isMobile={false}
              expandImage={this.state.expandImage}
              isOverlay={this.state.comparison_type}
              h={"100%"}
              h2={"100%"}>
              <CompareWrapper
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <FirstImage
                  w={this.state.imageAWidth}
                  h={this.state.imageAHeight}>
                  <div
                    style={{
                      width: this.state.imageAWidth,
                      height: this.state.imageAHeight,
                      position: "relative",
                    }}>
                    <SVGContent
                      viewBox={this.state.viewboxA}
                      //  height="100%" width="100%"
                     
                      ref={this.svgRef}>
                      <rect
                   
                        width="100%"
                        height="100%"
                        fillOpacity="0%"
                        fill="white"></rect>
                      {drawing.map((o) => {
                        if (o.canvas === "A") {
                          if (o.type === "rect") {
                            return (<Rect editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }

                          if (o.type === "line") {
                            return (<Line editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "circ") {
                            return (<Circ editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "golden") {
                            return (<GoldenGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "phi") {
                            return (<PhiGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "angle") {
                            return (<AngleGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "grid") {
                            return (<SimpleGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                        }

                        return null;
                      })}
                    </SVGContent>

                    <img
                      alt=""
                      ref={this.bkgImage}
                      style={{
                        width: this.state.imageAWidth,
                        height: this.state.imageAHeight,
                        marginBottom: "-4px"
                      }}
                      onLoad={() => {
                        this.HandleImageLoaded(1);
                      }}
                      src={imageA}
                      draggable="false"
                      loading={loadingsetting}
                    />
                  </div>
                </FirstImage>
              </CompareWrapper>
           

              <CompareWrapperRight
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <OverlayCorrection
                  w={this.state.overlayW}
                  h={this.state.overlayH}>
                  <SecondImage
                    scale={this.state.scale_b}
                    rotate={this.state.rotate_b}
                    opacity={this.state.opacity_b}
                    translate_x={this.state.translate_x_b}
                    translate_y={this.state.translate_y_b}
                    w={this.state.imageBWidth}
                    h={this.state.imageBHeight}>
                    <div
                      style={{
                        width: this.state.imageBWidth,
                        height: this.state.imageBHeight,
                        position: "relative",
                      }}>
                      <SVGContent
                        viewBox={this.state.viewboxB}
                        //  height="100%" width="100%"
                       
                        ref={this.svgRefB}>
                        <rect
                         
                          width="100%"
                          height="100%"
                          fillOpacity="0%"
                          fill="white"></rect>
                        {drawing.map((o) => {
                          if (o.canvas === "B") {
                            if (o.type === "rect") {
                              return (<Rect editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
  
                            if (o.type === "line") {
                              return (<Line editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "circ") {
                              return (<Circ editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "golden") {
                              return (<GoldenGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "phi") {
                              return (<PhiGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "angle") {
                              return (<AngleGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "grid") {
                              return (<SimpleGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                          }
                          return null;
                        })}
                      </SVGContent>

                      <img
                        alt=""
                        ref={this.bkgImageB}
                        style={{
                          width: this.state.imageBWidth,
                          height: this.state.imageBHeight,
                          marginBottom: "-4px"
                        }}
                        onLoad={() => {
                          this.HandleImageLoaded(2);
                        }}
                        src={imageB}
                        draggable="false"
                        loading={loadingsetting}
                      />
                    </div>
                  </SecondImage>
                </OverlayCorrection>
              </CompareWrapperRight>
            </PreviewContentCompare>
            </ImageWrapperDescr>

          <ElementDescription>
            <svg
              viewBox="0 0 358 50"
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg">
              <g>{calcSVG(description, 358, 17, 26, 2, 14)}</g>
            </svg>
          </ElementDescription>
        </ElementContainer>
      );
    } else {
      return (
        <ElementContainer>
          {tag ? (
            <Tag feedplacement={feedplacement}>
              <svg
                style={{ width: "100%", height: "100%" }}
                viewBox="0 0 800 800"
                // width="100%"
                // height="100%"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  style={{
                    fill: labelcolor,
                  }}
                  d="M 600 0 L 680 0 L 840 160 L 840 240"
                />
                <text
                  letterSpacing="1.5"
                  fontSize="28"
                  fontFamily="Arial"
                  fill="#fff"
                  textAnchor="middle"
                  transform="translate(726,100) rotate(45)">
                  {labelname}
                </text>
              </svg>
            </Tag>
          ) : (
            ""
          )}
          <CompareBadge feedplacement={feedplacement}>
          {compare_type === "SS" ? (
             <svg
             style={{ width: "100%", height: "100%" }}
             viewBox="0 0 800 800"
             // width="100%"
             // height="100%"
             xmlns="http://www.w3.org/2000/svg">
             <line x1="400" y1="0" x2="400" y2="800" stroke="#fff" strokeWidth="3"></line>
             <ellipse cx="400" cy="400" rx="40" ry="40" fill="#fff" ></ellipse>
             <text
                     letterSpacing="1.5"
                     fontSize="20"
                     fontWeight="bold"
                     fontFamily="Arial"
                     fill="#383838"
                     textAnchor="middle"
                     transform="translate(400,410) ">
                     VS
                   </text>
           </svg>
        ):( <svg
          style={{ width: "100%", height: "100%" }}
          viewBox="0 0 800 800"
          // width="100%"
          // height="100%"
          xmlns="http://www.w3.org/2000/svg">
          <line x1="400" y1="0" x2="400" y2="800" stroke="#fff" strokeWidth="3"></line>
          <ellipse cx="400" cy="400" rx="40" ry="40" fill="#fff" ></ellipse>
          <polygon points="405 390,405 410,420 400" fill="#383838"></polygon>
          <polygon points="395 390,395 410,380 400" fill="#383838"></polygon>
        </svg>)}
            </CompareBadge>
        <PreviewContentCompare
              isMobile={false}
              expandImage={this.state.expandImage}
              isOverlay={this.state.comparison_type}
              h={"100%"}
              h2={"100%"}>
              <CompareWrapper
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <FirstImage
                  w={this.state.imageAWidth}
                  h={this.state.imageAHeight}>
                  <div
                    style={{
                      width: this.state.imageAWidth,
                      height: this.state.imageAHeight,
                      position: "relative",
                    }}>
                    <SVGContent
                      viewBox={this.state.viewboxA}
                      //  height="100%" width="100%"
                     
                      ref={this.svgRef}>
                      <rect
                   
                        width="100%"
                        height="100%"
                        fillOpacity="0%"
                        fill="white"></rect>
                      {drawing.map((o) => {
                        if (o.canvas === "A") {
                          if (o.type === "rect") {
                            return (<Rect editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }

                          if (o.type === "line") {
                            return (<Line editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "circ") {
                            return (<Circ editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "golden") {
                            return (<GoldenGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "phi") {
                            return (<PhiGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "angle") {
                            return (<AngleGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                          if (o.type === "grid") {
                            return (<SimpleGrid editable={false} svg={this.svgRef.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                          }
                        }

                        return null;
                      })}
                    </SVGContent>

                    <img
                      alt=""
                      ref={this.bkgImage}
                      style={{
                        width: this.state.imageAWidth,
                        height: this.state.imageAHeight,
                        marginBottom: "-4px"
                      }}
                      onLoad={() => {
                        this.HandleImageLoaded(1);
                      }}
                      src={imageA}
                      draggable="false"
                      loading={loadingsetting}
                    />
                  </div>
                </FirstImage>
              </CompareWrapper>
            
              <CompareWrapperRight
                clip={this.state.clip}
                type={this.state.comparison_type}>
                <OverlayCorrection
                  w={this.state.overlayW}
                  h={this.state.overlayH}>
                  <SecondImage
                    scale={this.state.scale_b}
                    rotate={this.state.rotate_b}
                    opacity={this.state.opacity_b}
                    translate_x={this.state.translate_x_b}
                    translate_y={this.state.translate_y_b}
                    w={this.state.imageBWidth}
                    h={this.state.imageBHeight}>
                    <div
                      style={{
                        width: this.state.imageBWidth,
                        height: this.state.imageBHeight,
                        position: "relative",
                      }}>
                      <SVGContent
                        viewBox={this.state.viewboxB}
                        //  height="100%" width="100%"
                       
                        ref={this.svgRefB}>
                        <rect
                         
                          width="100%"
                          height="100%"
                          fillOpacity="0%"
                          fill="white"></rect>
                        {drawing.map((o) => {
                          if (o.canvas === "B") {
                            if (o.type === "rect") {
                              return (<Rect editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
  
                            if (o.type === "line") {
                              return (<Line editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "circ") {
                              return (<Circ editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "golden") {
                              return (<GoldenGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "phi") {
                              return (<PhiGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "angle") {
                              return (<AngleGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                            if (o.type === "grid") {
                              return (<SimpleGrid editable={false} svg={this.svgRefB.current} drawnow={this.state.imageAloaded} key={o.id + "pu"} obj={o} />)
                            }
                          }
                          return null;
                        })}
                      </SVGContent>

                      <img
                        alt=""
                        ref={this.bkgImageB}
                        style={{
                          width: this.state.imageBWidth,
                          height: this.state.imageBHeight,
                          marginBottom: "-4px"
                        }}
                        onLoad={() => {
                          this.HandleImageLoaded(2);
                        }}
                        src={imageB}
                        draggable="false"
                        loading={loadingsetting}
                      />
                    </div>
                  </SecondImage>
                </OverlayCorrection>
              </CompareWrapperRight>
            </PreviewContentCompare>
        </ElementContainer>
      );
    }
  }
}

export default Compare;
