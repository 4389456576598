import React, { Component } from "react";
import styled from "styled-components";
import calcSVG from "../../utils/svgText";

const ElementDescription = styled.div`
  width: 86%;
  margin: 7%;
  text-decoration: none !important;
  position: relative;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
`;

const ElementContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f7cd00;

`;

// const QuestionIcon = styled.div`
//   sss
//   color: #111;
//   margin-left: auto;
//   margin-right: auto;

// `;
class Question extends Component {
  render() {
    const { description } = this.props;

    return (
      <ElementContainer>
        <ElementDescription>
          <svg
            viewBox="0 0 344 364"
            width="100%"
            height="100%"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              transform="scale(4),translate(30, 10)"
              d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
            ></path>
            <g>
            <g>{calcSVG(description, 344, 26, 39, 7, 24, "#111", "center")}</g>
            </g>
          </svg>
        </ElementDescription>
      </ElementContainer>
    );
  }
}

export default Question;
