import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { useDispatch } from 'react-redux';
import * as alertActions from "../actions/alertActions";
import { Typography } from '@mui/material';
import ProfileSetup from './ProfileSetup/ProfileSetup';
import React, { useState, useEffect } from 'react';
import { useAnalytics } from "use-analytics";
import styled from "@emotion/styled";
import {
    Example,
    Example2,
    ExampleWithProps
} from "./styles/MenuComponents.js";

import Video from "./VideoPlayer.js";
import { Ribbons } from './marketing_pages/Ribbon';

const VideoContainer = styled.div`
    display: flex;
    width: 720px;
    height: 480px;
`


const Debug = (props) => {
    const dispatch = useDispatch();
    const analytics = useAnalytics();

    const [lerp, setLerp] = useState(1);

    const handleClick = (s) => {
        dispatch(alertActions.showAlert("This is a test alert", s));
    };

    const handleErrorClick = () => {
        try {
            throw new Error("This is a test error");
        } catch (error) {
            dispatch(alertActions.showError(error, "error"));
        }
    };

    const handleAnalyticsClick = (e) => {
        analytics.track('test', {
            text: e.target.innerText,
            debug_mode: true
        })
    };

    return (
        <React.Fragment>
            <Ribbons val={lerp} />
            {/* <ProfileSetup /> */}
            <div style={{ padding: "56px 36px", paddingTop: "128px" }}>
                <Typography variant='h1' gutterBottom>Debug</Typography>
                {/* <pre><code>{JSON.stringify(props, null, 2)}</code></pre> */}
                <Stack spacing={1} direction="column">
                    <Slider min={0} max={1} step={0.001} value={lerp} onChange={e => setLerp(e.target.value)} sx={{ width: 300 }} />
                    <Stack spacing={1} direction="row">
                        <Button variant="contained" color="info" onClick={e => handleClick("info")}>Info</Button>
                        <Button variant="contained" color="success" onClick={e => handleClick("success")}>Success</Button>
                        <Button variant="contained" color="warning" onClick={e => handleClick("warning")}>Warning</Button>
                        <Button variant="contained" color="error" onClick={e => handleErrorClick(e)}>Error</Button>
                    </Stack>
                    <Stack spacing={1} direction="row">
                        <Button variant="contained" onClick={e => handleAnalyticsClick(e)}>Debug Analytics</Button>
                    </Stack>
                </Stack>
            </div>
            {/* Example for references to components in one file. */}
            <Example />
            <Example2 />
            <ExampleWithProps color="#ccc">
                <div>Example with props</div>
            </ExampleWithProps>
            <Example />
            <Example2 />
            <Example />
            <Example2 />
            <br />
            {/* 
            <VideoContainer>
                <Video src="http://localhost:8000/media/i8CQDmPsqHUteUp2SMvocA/file" />
            </VideoContainer> */}
        </React.Fragment>

    );
}

export default Debug;