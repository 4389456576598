import deburr from "lodash.deburr";
import widthsMap from "./widthsMap";

const settingsDefaults = { font: "Arial", size: 100 };

const getWidth = (string, settings) => {
  const sett = { ...settingsDefaults, ...settings };
  const font = sett.font.toLowerCase();
  const size = sett.size;
  const variant = 0 + (sett.bold ? 1 : 0) + (sett.italic ? 2 : 0);
  const map = sett.map || widthsMap;
  const available = Object.keys(map);
  if (available.indexOf(font) === -1) {
    throw new Error(
      `This font is not supported. Supported fonts are: ${available.join(", ")}`
    );
  }
  let totalWidth = 0;
  deburr(string)
    .split("")
    .forEach((char) => {
      // eslint-disable-next-line no-control-regex
      if (/[\x00-\x1F]/.test(char)) {
        // non-printable character
        return true;
      }
      // use width of 'x' as fallback for unregistered char
      const widths = map[font][char] || map[font].x;
      const width = widths[variant];
      totalWidth += width;
      return true;
    });
  return totalWidth * (size / 100);
};

const calcSVG = (
  text,
  viewport_w,
  fontSize,
  lineSpacing,
  maxLines,
  startPos,
  color,
  align
) => {
  // const viewport_w = 340;
  // const fontSize = 30;
  const ratio = 1.29;
  if (!color) {
    color = "#111";
  }

  // const lineSpacing = 39;
  // const maxLines = 9;
  let lines = [];
  const words = text.split("\n").join(" \n ").split(" ");
  let linewidth = 0;
  let linenumber = 0;

  for (var i = 0; i < words.length; i++) {
    if (words[i] === "\n") {
      linenumber = linenumber + 1
      linewidth = 0
      continue
    }
    const width =
      ratio * getWidth(words[i], { font: "Merriweather", size: fontSize });
    if (linewidth + width < viewport_w) {
      // still fits this line
      linewidth =
        linewidth +
        width +
        ratio * getWidth(" ", { font: "Merriweather", size: fontSize });
      if (lines[linenumber]) {
        lines[linenumber] = lines[linenumber] + words[i] + " ";
      } else {
        lines[linenumber] = words[i] + " ";
      }
    } else {
      if (width > viewport_w) {
        // this single word does not fit
        if (i !== 0) {
          // if it is first word, then don't incremement line number
          linenumber = linenumber + 1;
        }
        const word = words[i];

        linewidth = 0;
        for (var c = 0; c < word.length; c++) {
          // console.log(word.charAt(i));
          if (
            linewidth +
            ratio *
            getWidth(word.charAt(c), {
              font: "Merriweather",
              size: fontSize,
            }) <
            viewport_w
          ) {
            linewidth =
              linewidth +
              ratio *
              getWidth(word.charAt(c), {
                font: "Merriweather",
                size: fontSize,
              });
            if (lines[linenumber]) {
              lines[linenumber] = lines[linenumber] + word.charAt(c);
            } else {
              lines[linenumber] = word.charAt(c);
            }
          } else {
            // character doesn't fit, start a new line
            linenumber = linenumber + 1;

            linewidth =
              ratio *
              getWidth(word.charAt(c), {
                font: "Merriweather",
                size: fontSize,
              });
            if (lines[linenumber]) {
              lines[linenumber] = lines[linenumber] + word.charAt(c);
            } else {
              lines[linenumber] = word.charAt(c);
            }
          }
        }
        // add a space at the end
        linewidth =
          linewidth +
          ratio * getWidth(" ", { font: "Merriweather", size: fontSize });
        if (lines[linenumber]) {
          lines[linenumber] = lines[linenumber] + " ";
        } else {
          lines[linenumber] = " ";
        }
      } else {
        // word fits on a new line
        linenumber = linenumber + 1;
        linewidth =
          width +
          ratio * getWidth(" ", { font: "Merriweather", size: fontSize });
        if (lines[linenumber]) {
          lines[linenumber] = lines[linenumber] + words[i] + " ";
        } else {
          lines[linenumber] = words[i] + " ";
        }
      }
    }

    if (linenumber >= maxLines) {
      // there are more words, but no more space.
      if (
        ratio *
        getWidth(lines[maxLines - 1], {
          font: "Merriweather",
          size: fontSize,
        }) +
        ratio * getWidth("...", { font: "Merriweather", size: fontSize }) <
        viewport_w
      ) {
        lines[maxLines - 1] = lines[maxLines - 1] + "...";
      } else {
        let tempstring = lines[maxLines - 1];
        for (var ch = 0; ch < tempstring.length; ch++) {
          tempstring = tempstring.slice(0, -1);
          if (
            ratio *
            getWidth(tempstring, { font: "Merriweather", size: fontSize }) +
            ratio *
            getWidth("...", { font: "Merriweather", size: fontSize }) <
            viewport_w
          ) {
            lines[maxLines - 1] = tempstring + "...";
            break;
          }
        }
      }
      lines.length = maxLines;
      break;
    }
  }

  let resultArray = [];
  for (i = 0; i < lines.length; i++) {
    let ypos, xpos;
    if (align === "center") {
      xpos =
        (viewport_w -
          (ratio *
            getWidth(lines[i], { font: "Merriweather", size: fontSize }) -
            ratio * getWidth(" ", { font: "Merriweather", size: fontSize }))) /
        2;

      ypos =
        (373 - lineSpacing * lines.length) / 2 + startPos + lineSpacing * i;
      // need to replace this hardcoded value at some point
    } else {
      if (align === "center-question") {
        xpos =
          (viewport_w -
            (ratio *
              getWidth(lines[i], { font: "Merriweather", size: fontSize }) -
              ratio *
              getWidth(" ", { font: "Merriweather", size: fontSize }))) /
          2;
        ypos = startPos + lineSpacing * i;
      } else {
        xpos = 0;
        ypos = startPos + lineSpacing * i;
      }
    }
    resultArray.push(
      <text
        key={"l" + i}
        x={xpos}
        y={ypos}
        fontFamily="Merriweather"
        fontSize={fontSize}
        fill={color}
      >
        {lines[i]}
      </text>
    );
  }

  return resultArray;
};

export default calcSVG;
