Object.defineProperty(exports, "__esModule", {
  value: true,
});
/* eslint quotes: off, comma-dangle: off, quote-props: off */
var widthsMap = {
  merriweather: {
    0: [50, 50, 50, 50],
    1: [50, 50, 50, 50],
    2: [50, 50, 50, 50],
    3: [50, 50, 50, 50],
    4: [50, 50, 50, 50],
    5: [50, 50, 50, 50],
    6: [50, 50, 50, 50],
    7: [50, 50, 50, 50],
    8: [50, 50, 50, 50],
    9: [50, 50, 50, 50],
    " ": [25, 25, 25, 25],
    "!": [33, 33, 33, 39],
    '"': [41, 56, 42, 56],
    "#": [50, 50, 50, 50],
    $: [50, 50, 50, 50],
    "%": [83, 100, 83, 83],
    "&": [78, 83, 78, 78],
    "'": [18, 28, 21, 28],
    "(": [33, 33, 33, 33],
    ")": [33, 33, 33, 33],
    "*": [50, 50, 50, 50],
    "+": [56, 57, 67, 57],
    ",": [25, 25, 25, 25],
    "-": [33, 33, 33, 33],
    ".": [25, 25, 25, 25],
    "/": [28, 28, 28, 28],
    ":": [28, 33, 33, 33],
    ";": [28, 33, 33, 33],
    "<": [56, 57, 67, 57],
    "=": [56, 57, 67, 57],
    ">": [56, 57, 67, 57],
    "?": [44, 50, 50, 50],
    "@": [92, 93, 92, 83],
    A: [72, 72, 61, 67],
    B: [67, 67, 61, 67],
    C: [67, 72, 67, 67],
    D: [72, 72, 72, 72],
    E: [61, 67, 61, 67],
    F: [56, 61, 61, 67],
    G: [72, 78, 72, 72],
    H: [72, 78, 72, 78],
    I: [33, 39, 33, 39],
    J: [39, 50, 44, 50],
    K: [72, 78, 67, 67],
    L: [61, 67, 56, 61],
    M: [89, 94, 83, 89],
    N: [72, 72, 67, 72],
    O: [72, 78, 72, 72],
    P: [56, 61, 61, 61],
    Q: [72, 78, 72, 72],
    R: [67, 72, 61, 67],
    S: [56, 56, 50, 56],
    T: [61, 67, 56, 61],
    U: [72, 72, 72, 72],
    V: [72, 72, 61, 67],
    W: [94, 100, 83, 89],
    X: [72, 72, 61, 67],
    Y: [72, 72, 56, 61],
    Z: [61, 67, 56, 61],
    "[": [33, 33, 39, 33],
    "\\": [28, 28, 28, 28],
    "]": [33, 33, 39, 33],
    "^": [47, 58, 42, 57],
    _: [50, 50, 50, 50],
    "`": [33, 33, 33, 33],
    a: [44, 50, 50, 50],
    b: [50, 56, 50, 50],
    c: [44, 44, 44, 44],
    d: [50, 56, 50, 50],
    e: [44, 44, 44, 44],
    f: [33, 33, 28, 33],
    g: [50, 50, 50, 50],
    h: [50, 56, 50, 56],
    i: [28, 28, 28, 28],
    j: [28, 33, 28, 28],
    k: [50, 56, 44, 50],
    l: [28, 28, 28, 28],
    m: [78, 83, 72, 78],
    n: [50, 56, 50, 56],
    o: [50, 50, 50, 50],
    p: [50, 56, 50, 50],
    q: [50, 56, 50, 50],
    r: [33, 44, 39, 39],
    s: [39, 39, 39, 39],
    t: [28, 33, 28, 28],
    u: [50, 56, 50, 56],
    v: [50, 50, 44, 44],
    w: [72, 72, 67, 67],
    x: [50, 50, 44, 50],
    y: [50, 50, 44, 44],
    z: [44, 44, 39, 39],
    "{": [48, 39, 40, 35],
    "|": [20, 22, 28, 22],
    "}": [48, 39, 40, 35],
    "~": [54, 52, 54, 57],
  },
};

exports.default = widthsMap;
module.exports = exports["default"];
