import { CONSTANTS } from ".";
import axiosInstance from "../utils/axiosApi";
import { setMenu, updateProgress, timeoutError } from "../actions/uiActions";
import analyticsInstance from "../utils/analytics";



export const getFollowing = (userid) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FOLLOWING_LOADING,
    payload: "loading",
  });
  let url = "users/" + userid + "/following_people/";

  axiosInstance.get(url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_FOLLOWING,
      payload: res.data,
    });
  });
};
export const getFollowers = (userid) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_FOLLOWERS_LOADING,
    payload: "loading",
  });
  let url = "users/" + userid + "/followers/";

  axiosInstance.get(url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_FOLLOWERS,
      payload: res.data,
    });
  });
};
export const getSharedProjects = (userid) => (dispatch) => {

  dispatch({
    type: CONSTANTS.SET_SHARED_LOADING,
    payload: "loading",
  });
  let url = "users/" + userid + "/shared_projects/";

  axiosInstance.get(url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_SHARED,
      payload: res.data,
    });
  });
};
export const getGroups = (status, group_type, archived) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_GROUPS_LOADING,
    payload: "loading",
  });
  let url = "groups/";
  if (status !== "ALL") {
    url = url + "?status=" + status;
    if (group_type) {
      url = url + "&group_type=" + group_type;
    }
  } else {
    if (group_type) {
      url = url + "?group_type=" + group_type;
    }
  }
  if (archived === true) {
    url = url + (url.includes("?") ? "&" : "?") + "archived=True"
  }

  axiosInstance.get(url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_GROUPS,
      payload: res.data,
    });
  }).catch((err) => {
    dispatch({
      type: CONSTANTS.GET_GROUPS_ERROR,
      payload: err,
    });
  });
};
export const leaveGroup = (groupid) => (dispatch) => {
  axiosInstance.post("groups/" + groupid + "/leave/").then((res) => {
    analyticsInstance.track("leave_group", { id: groupid })
    // navigate to groups.
    window.location = "/groups/";
  });
};
export const deleteGroup = (groupid) => (dispatch) => {
  axiosInstance.delete("groups/" + groupid + "/").then((res) => {
    // navigate to groups.
    window.location = "/groups/";
  });
};
export const followGroup = (groupid) => (dispatch) => {
  axiosInstance.post("groups/" + groupid + "/subscribe/").then((res) => {
    dispatch({
      type: CONSTANTS.CHANGE_GROUP_FOLLOW_STATUS,
      payload: true,
    });
  });
};

export const requestAccessGroup = (groupid) => (dispatch) => {
  axiosInstance.post("groups/" + groupid + "/join/").then((res) => {
    dispatch({
      type: CONSTANTS.CHANGE_GROUP_REQUEST_STATUS,
      payload: true,
    });
  });
};
export const joinGroupAuto = (groupid) => (dispatch) => {
  axiosInstance.post("groups/" + groupid + "/join/").then((res) => {
    // navigate to groups.
    window.location = "/group/" + groupid;
  });
};
export const unfollowGroup = (groupid) => (dispatch) => {
  axiosInstance.delete("groups/" + groupid + "/subscribe/").then((res) => {
    dispatch({
      type: CONSTANTS.CHANGE_GROUP_FOLLOW_STATUS,
      payload: false,
    });
  });
};
export const loadMoreGroups = (link) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_GROUPS_LOADING,
    payload: "loading",
  });
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.GET_MORE_GROUPS,
      payload: res.data,
    });
  }).catch((err) => {
    dispatch({
      type: CONSTANTS.GET_GROUPS_ERROR,
      payload: err,
    });
  });
};
export const sendInvite = (data, groupinvite) => (dispatch) => {
  axiosInstance.post("participants/", data).then((res) => {
    if (groupinvite) {
      dispatch({
        type: CONSTANTS.SET_PENDING_INVITE,
        payload: { email: res.data.invite_email, id: res.data.id, role: res.data.role, status: res.data.status },
      });
    }

  });
};
export const removeInvite = (id, part_id) => (dispatch) => {
  axiosInstance.delete("participants/" + part_id + "/").then((res) => {
    console.log(res);
    dispatch({
      type: CONSTANTS.REMOVE_PENDING_INVITE,
      payload: id,
    });
  });
};

export const getConnections = (data) => (dispatch) => {
  axiosInstance.get("connections/?search=" + data).then((res) => {
    dispatch({
      type: CONSTANTS.GET_SEARCH_RESULTS,
      payload: res.data.results,
    });
  });
};

export const getGroupData = (id) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_GROUP_LOADING,
    payload: "loading",
  });
  axiosInstance.get("groups/" + id + "/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_GROUP_DATA,
      payload: res.data,
    });
    dispatch(getGroupParticipants(id, "AC"));

  });
};
export const getGroupProjects = (id, state) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_GROUPPROJECTS_LOADING,
    payload: "loading",
  });
  let get_url = "groups/" + id + "/projects/";
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_GROUPPROJECTS_DATA,
      payload: { data: res.data, state: state },
    });
    // dispatch(getGroupParticipants(id, "AC"));
  });
};
export const loadMoreGroupProjects = (link, state) => (dispatch) => {
  let get_url = link;
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_GROUPPROJECTS,
      payload: res.data,
    });
  });
};
export const getGroupParticipants = (id, status) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_GROUPPARTICIPANTS,
    payload: "loading",
  });
  let get_url = "groups/" + id + "/participants/";
  if (status) {
    get_url = get_url + "?status=" + status;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_GROUPPARTICIPANTS,
      payload: res.data,
    });
    dispatch(getGroupProjects(id));
  });
};
export const loadMoreGroupParticipants = (link, status) => (dispatch) => {
  let get_url = link;
  if (status) {
    get_url = get_url + "?status=" + status;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_GROUPPARTICIPANTS,
      payload: res.data,
    });
  });
};
export const getPendingInvites = (id) => (dispatch) => {
  axiosInstance.get("groups/" + id + "/participants/?status=IN").then((res) => {
    dispatch({
      type: CONSTANTS.GET_INITIAL_PENDING,
      payload: res.data,
    });
  });
};
export const getAllParticipants = (id, type) => (dispatch) => {
  axiosInstance.get("groups/" + id + "/participants/?status=" + type).then((res) => {
    res.data.sub_type = type;
    dispatch({
      type: CONSTANTS.GET_ALL_PARTICIPANTS,
      payload: res.data,
    });
  });
};
export const loadMoreParticipants = (link, type) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    res.data.sub_type = type
    dispatch({
      type: CONSTANTS.LOAD_MORE_PARTICIPANTS,
      payload: res.data,
    });
  });
};
export const getRequestedInvites = (id) => (dispatch) => {
  axiosInstance.get("groups/" + id + "/participants/?status=RE").then((res) => {
    dispatch({
      type: CONSTANTS.GET_INITIAL_REQUESTED,
      payload: res.data,
    });
  });
};
export const upLoadcreateGroup = (imageRaw, data) => (dispatch) => {
  axiosInstance
    .get("notifications/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_NOTIFICATIONS,
        payload: result.data,
      });

      dispatch(timeoutError(false));
      dispatch(setMenu(true, 5));
      dispatch(updateProgress(0, 1));
      if (imageRaw) {
        var file = imageRaw;
        var blob = file.slice(0, file.size, file.type);
        var split = file['name'].split('.');
        var filename = split[0];
        var extension = split.pop()
        console.log(split)

        if (filename.length > 90) {
          filename = filename.substring(0, 90);
        }


        var tempname = filename + '.' + extension;
        var newFile = new File([blob], tempname, { type: file.type })
        const formData = new FormData();
        formData.append("upload", newFile);
        formData.append("alt_text", "temp");
        axiosInstance({
          method: "post",
          url: "media/",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: progressEvent => {
            dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
          }
        })
          .then((response) => {
            //console.log("got it");
            //console.log(response.data);
            const imageid = response.data.id;
            data.image = imageid;
            dispatch(createGroup(data));
          })
          .catch(function (response) {
            //handle error
            if (response === "timeout") {
              dispatch(timeoutError(true));
            }
          });
      }



    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });



};
export const createGroup = (data) => (dispatch) => {
  //   const data = {
  //     "name": name

  //  }
  console.log(data)
  axiosInstance.post("groups/", data).then((res) => {
    dispatch(setMenu(false, 5));
    window.location = "/group/" + res.data.id + "/" + res.data.name;
    analyticsInstance.track("create_group", { id: res.data.id, title: res.data.name })
    dispatch({
      type: CONSTANTS.CREATE_NEW_GROUP,
      payload: res.data,
    });
  });
};
export const addMember = (id, username, currentmembers, name) => (dispatch) => {
  axiosInstance
    .get("users/by-username/" + username + "/")
    .then((result) => {
      //console.log(result);
      dispatch({
        type: CONSTANTS.NEW_MEMBER,
        payload: result.data[0],
      });
      // add person here to data
      // const data ={'members': currentmembers, 'name': name}
      // data.members.push(result.data[0].id)
      // //console.log(data)
      // dispatch(saveGroup(id, data))
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const uploadSaveGroup = (id, data, imageRaw) => (dispatch) => {

  axiosInstance
    .get("notifications/")
    .then((result) => {
      dispatch({
        type: CONSTANTS.GET_NOTIFICATIONS,
        payload: result.data,
      });
      dispatch(timeoutError(false));
      dispatch(setMenu(true, 5));
      dispatch(updateProgress(0, 1));
      if (imageRaw) {
        var file = imageRaw;
        var blob = file.slice(0, file.size, file.type);
        var split = file['name'].split('.');
        var filename = split[0];
        var extension = split.pop()
        console.log(split)

        if (filename.length > 90) {
          filename = filename.substring(0, 90);
        }

        var tempname = filename + '.' + extension;
        var newFile = new File([blob], tempname, { type: file.type })
        const formData = new FormData();
        formData.append("upload", newFile);
        formData.append("alt_text", "temp");
        axiosInstance({
          method: "post",
          url: "media/",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: progressEvent => {
            dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
          }
        })
          .then((response) => {
            //console.log("got it");
            //console.log(response.data);
            const imageid = response.data.id;
            data.image = imageid;
            dispatch(saveGroup(id, data));
          })
          .catch(function (response) {
            //handle error
            if (response === "timeout") {
              dispatch(timeoutError(true));
            }
          });
      }



    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });


};
export const saveGroup = (id, data) => (dispatch) => {
  //console.log(data);

  axiosInstance.put("groups/" + id + "/", data).then((res) => {
    dispatch(setMenu(false, 5));
    dispatch(getGroupData(id));
  });
};

export const removeMember = (id, part_id, type) => (dispatch) => {
  axiosInstance
    .delete("/participants/" + part_id + "/")
    .then((result) => {
      //console.log(result);
      dispatch({
        type: CONSTANTS.REMOVE_MEMBER,
        payload: { part_id: part_id, type: type },
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const rejectRequest = (part_id) => (dispatch) => {
  axiosInstance
    .delete("/participants/" + part_id + "/")
    .then((result) => {
      //console.log(result);
      dispatch({
        type: CONSTANTS.REMOVE_REQUEST,
        payload: part_id,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const acceptRequest = (part_id) => (dispatch) => {
  axiosInstance
    .patch("/participants/" + part_id + "/", { status: "AC" })
    .then((result) => {
      //console.log(result);
      dispatch({
        type: CONSTANTS.ACCEPT_REQUEST,
        payload: part_id,
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};

export const changeRole = (value, part_id, type) => (dispatch) => {
  axiosInstance
    .put("/participants/" + part_id + "/", { role: value })
    .then((result) => {
      //console.log(result);
      if (type === "AC") {
        dispatch({
          type: CONSTANTS.CHANGE_ROLE,
          payload: { role: value, part_id: part_id, type: type },
        });
      }


    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const getGroupPreview = (id) => (dispatch) => {
  console.log("getgrouppreview", id)
  // axiosInstance.defaults.headers["Authorization"] = undefined; // no auth
  axiosInstance.get("groups/preview/" + id + "/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_GROUP_PREVIEW,
      payload: res.data,
    });
  }).catch(err => {
    dispatch({
      type: CONSTANTS.GET_ERRORS,
      payload: err,
    });
  });
};


export const joinGroup = (joinid, groupid) => (dispatch) => {

  axiosInstance.post("groups/" + joinid + "/join/").then((res) => {
    analyticsInstance.track("join_group", { id: res.data.id, title: res.data.name })
    // navigate to groups.
    window.location = "/group/" + groupid;
  });
};
export const toggleJoinLink = (id) => (dispatch) => {
  axiosInstance.post("groups/" + id + "/joinlink/").then((res) => {
    // dispatch(setMenu(false));
    console.log("joinlink", res.data);
    dispatch({
      type: CONSTANTS.UPDATE_JOIN_LINK,
      payload: res.data,
    });
    // window.history.replaceState({}, "Sequentially", project_url);
  });
};
export const getProjectsforGroup =
  (id, groupid) => (dispatch) => {
    let get_url;

    get_url = "users/" + id + "/projects/?exclude_group=" + groupid;
    dispatch({
      type: CONSTANTS.SET_PROJECTS_FOR_GROUPS_LOADING,
      payload: "loading",
    });
    axiosInstance.get(get_url).then((res) => {
      dispatch({
        type: CONSTANTS.GET_PROJECTS_FOR_GROUPS,
        payload: { data: res.data },
      });
    });
  };

export const loadmoreProjectsforGroup = (link) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    dispatch({
      type: CONSTANTS.SET_PROJECTS_FOR_GROUPS_LOADING,
      payload: "loading",
    });
    dispatch({
      type: CONSTANTS.LOAD_MORE_PROJECTS_FOR_GROUPS,
      payload: { data: res.data },
    });
  });
};
export const saveProjectsToGroup =
  (groupid, data) => (dispatch) => {
    let post_url;

    post_url = "groups/" + groupid + "/projects/";


    axiosInstance
      .put(post_url, data)
      .then((res) => {
        console.log("added")
        dispatch(getGroupProjects(groupid));
      })
      .catch(function (response) {
        //handle error
        //console.log(response);
      });
  };
export const removeProjectsFromGroup =
  (groupid, data) => (dispatch) => {
    let post_url;

    post_url = "groups/" + groupid + "/projects/remove/";


    axiosInstance
      .post(post_url, data)
      .then((res) => {
        console.log("removed")
        dispatch(getGroupProjects(groupid));
      })
      .catch(function (response) {
        //handle error
        //console.log(response);
      });
  };