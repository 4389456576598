import React, { Component } from "react";
import styled from '@emotion/styled'
import { connect } from "react-redux";
import { api_endpoint } from "../config";
import { Link } from "react-router-dom";
import CommentContainer from "./CommentContainer";
import CommentNew from "./CommentNew";
import Update from "./elements/Update";
import Compare from "./elements/Compare";
import Text from "./elements/Text";
import Next from "./elements/Next";
import Question from "./elements/Question";
import Dimension from "./elements/Dimension";
import { updatePosition } from "../actions/projectActions";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 10px;
`;
const ElementContainerVertical = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  position: relative;
 
`;
const TopContainer = styled.div`
  width: ${(props) => (props.containerwidth ? props.containerwidth : "300")}px;
  height: ${(props) => (props.cardheight ? props.cardheight : "300")}px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;
const TopContainerVertical = styled.div`
  width: 100%;
 
  position: relative;
  margin-left: auto;
  margin-right: auto;
`;
const LineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  // background-color: #f2f2f2;
`;
const LineContainerLeft = styled.div`
  width: calc(
    50% + 1px
  ); // these added pixels are to try to align the dotted lines, but it is not working well when then line is more than 1px thick
  height: 100%;
  margin-left: -9px;
  // border-right: 1px solid #e3e3e3;
`;
const LineContainerRight = styled.div`
  width: 50%;
  height: 100%;
`;
const LineContainerTop = styled.div`
  width: 100%;
  height: 10px;

  border-bottom: 1px solid #008EFF;
`;
const BottomContainer = styled.div`
  width: calc(100% - 16px);
  // height: 300px;
`;
const BottomContainerVertical = styled.div`
  width:100%;
  max-width: 468px;

  margin-left: auto;
  margin-right: auto;
  // height: 300px;
`;
const ElementCard = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  background-color: #fff;
  border: ${(props) =>
    props.selected ? "1px solid #fff" : "1px solid #fff"};
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
  // border-radius: 1.5%;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;
const ElementCardVertical = styled.div`
  // width: calc(100%-40px);
  width: ${(props) => props.isMobile ? "100%" : (props.cardwidth ? props.cardwidth + "px" : "300px")};
  height: ${(props) => (props.cardwidth ? props.cardwidth : "300")}px;
  background-color: #fff;
 margin-left: auto;
 margin-right: auto;
  display: flex;
  flex-direction: column;
`;
const InnerContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
`;
const InnerContainerVertical = styled.div`
 
  margin-left: auto;
  margin-right: auto;
`;
const ElementCardLine = styled.div`
  width: calc(50% + 2px);
  height: 64px;
  border-right: 1px solid #dadada;
  margin-bottom: -10px;
`;
const ElementCardLineVertical = styled.div`
  width: calc(50% + 2px);
  height: 32px;
  border-right: 1px solid #008EFF;
 
`;
const ElementCardLineBottom = styled.div`
  margin-top: -45px;
  width: 50%;
  height: 60px;
  border-right: 1px solid #dadada;
`;
const DateBadge = styled.div`
  position: absolute;
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
// line-height: 24px;
  padding: 9px;
  // left: calc(50%);
  // right: calc(50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -6px;
  min-width: 60px;
  max-width: 100px;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #dadada;
  background-color: #fff;
  color: #000;
`;
const DateBadgeVertical = styled.div`

  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
// line-height: 24px;
  padding: 9px;
  // left: calc(50%);
  // right: calc(50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
 
  min-width: 60px;
  max-width: 100px;
  text-align: center;
  border-radius: 16px;
  border: 1px solid #dadada;
  background-color: #fff;
  color: #000;
`;
const DateBadgeFirst = styled.div`
  position: absolute;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  padding: 8px;
  right: 25px;
  top: -5px;
  min-width: 60px;
  text-align: center;
  border-radius: 5px;

  background-color: #2196f3;
`;
const LinkBadgeVertical = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 35%;
  
  margin-left: auto;
  margin-right: auto;


  // box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  background: #008EFF;
  border-radius: 16px;
  color: #fff;
`;
const LinkBadgeLeft = styled.div`
  background-color: #fff;
  // height: 10px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 35%;
  position: absolute;
  margin-left: 5px;
  margin-bottom: 37px;
  left: 8px;
  top: -45px;
  // box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  background: #008EFF;
  border-radius: 16px;
  color: #fff;
`;
const LinkBadge = styled.div`
 
  // height: 10px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 35%;
  position: absolute;
  margin-right: 8px;
  margin-bottom: 37px;
  right: 8px;
  top: -45px;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 18%);
  border-radius: 3px;
  display: flex;
  z-index: 2;
  background: #008EFF;
  border-radius: 16px;
  color: #fff;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
class Element extends Component {
  constructor() {
    super();
    this.state = {
      showEditIcon: false,
    };
  }
  updateCardPos = (Xpos, elementid, pos) => {
    const { dispatch } = this.props;

    dispatch(updatePosition(Xpos, elementid, pos));
  };

  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  setEditIcon = (newValue) => {
    this.setState({ showEditIcon: newValue });
  };
  handleEditElement = () => { };
  render() {
    const {
      zoomlevel,
      element_types,
      isOwner,
      pos,
      length,
      id,
      element,
      card_height,
      dimensions,
      project_url,
      allow_public_comments,
      group_id,
      project_id,
      project_name,
      lazyload,
      vertical
    } = this.props;
    // //console.log("isOwner" + isOwner)
    let isFirst = false;
    let isLast = false;
    let hasImage = false;
    if (element.images[0]) {
      const type = element.images[0].mime_type.split("/")
      if (type[0] === "image"){
        hasImage = true;
      }
      
    }
    if (pos === "first" || length === 1) {

      isFirst = true;
    }
    if (pos === "last" || length === 1) {
      isLast = true;
    }

    let customname = "Link";
    if (element.metadata) {
      if (element.metadata.link_name) {
        customname = element.metadata.link_name;
      }
    }
    let minsize;
    if (dimensions.window_dimensions.isMobile) {
      minsize = 70;
    } else {
      minsize = 120;
    }

    const cardmargin = 32;
    let containerwidth;
    // let containerwidth = Math.round(
    //   minsize + ((card_height - minsize - 50) * zoomlevel) / 100
    // );
    if (zoomlevel > 50) {
      containerwidth = Math.round(card_height - 25);
    } else {
      containerwidth = Math.round(
        minsize + ((card_height - minsize - 25) * zoomlevel) / 50
      );
    }

    if (containerwidth < minsize) {
      // containerwidth = minsize/2
    }
   let cardwidth = containerwidth - cardmargin;
    // const ImageWidth = Math.round(ImageHeight * (AppliedRatio / 100));
    // const scale = (containerwidth)/(minsize + ((card_height - minsize- 50) ))
    // const maxcard =
    //   Math.round(minsize + (card_height - minsize - 50)) - cardmargin;
    // const mincard = minsize - cardmargin;
    // const scale = (mincard + ((maxcard-mincard)*zoomlevel/100))/maxcard
    let commentcount = 0;
    let zerocomments = false;
    // console.log(group_id)
    // console.log(element.comments)
    if (vertical ){
      if( dimensions.window_dimensions.isMobile){
        cardwidth = dimensions.window_dimensions.w
      }else{
        cardwidth = 450
      }
      
    }
    if (element.comments) {

      switch (group_id) {
        case "1":
          element.comments.allIDS.map((comment, index) => {
            if (
              element.comments.byID[comment].group === null
            ) {
              commentcount = commentcount + 1;
            }

            return "";
          });
          break;
        case "2":
          element.comments.allIDS.map((comment, index) => {
            if (
              element.comments.byID[comment].group !== null || element.comments.byID[comment].public === true
            ) {
              commentcount = commentcount + 1;
            }

            return "";
          });
          break;
        case "unshared":
          commentcount = element.comments.allIDS.length
          break;
        default:
          element.comments.allIDS.map((comment, index) => {
            if (
              element.comments.byID[comment].group === group_id ||
              element.comments.byID[comment].public === true
            ) {
              commentcount = commentcount + 1;
            }

            return "";
          });
      }
    }
    // if (group_id !== "1") {
    //   element.comments.allIDS.map((comment, index) => {
    //     if (
    //       element.comments.byID[comment].group === group_id ||
    //       element.comments.byID[comment].public === true 
    //     ) {
    //       commentcount = commentcount + 1;
    //     }

    //     return "";
    //   });
    // } else {
    //   element.comments.allIDS.map((comment, index) => {
    //     if (
    //       element.comments.byID[comment].group === null
    //     ) {
    //       commentcount = commentcount + 1;
    //     }

    //     return "";
    //   });
    // }

    if (commentcount === 0) {
      zerocomments = true;
    }
   
   
    let image_url;
    let image_urlB;
    let show_full = element.show_full_image
    let isPlayback = false;
    if (element.images.length === 0) {
      image_url = false;
      image_urlB = false;
    } else {
      if (element.images[0]) {
        if (element.images[0].mime_type) {
          if (["video", "audio"].includes(element.images[0].mime_type.split("/")[0])) {
            isPlayback = true;
            image_url = api_endpoint + "/media/" + element.images[0].id + "/file?size=1024";
          }
          else {
            if (element.element_type === "CO") {
              if (element.metadata.image_order[0] === element.images[0].id) {
                image_url = api_endpoint + "/media/" + element.images[0].id + "/file?size=1024";
              } else {
                image_url = api_endpoint + "/media/" + element.images[1].id + "/file?size=1024";
              }

            } else {
              image_url = api_endpoint + "/media/" + element.images[0].id + "/file?size=1024";
            }

            // image_url = element.images[0].upload
          }
        }
      }
      if (element.images[1]) {
        if (element.images[1].mime_type) {
          if (element.images[1].mime_type.split("/")[0] === "video") {
            isPlayback = true;
            image_urlB = api_endpoint + "/media/" + element.images[1].id + "/file?size=1024";
          }
          else {
            if (element.element_type === "CO") {
              if (element.metadata.image_order[1] === element.images[1].id) {
                image_urlB = api_endpoint + "/media/" + element.images[1].id + "/file?size=1024";
              } else {
                image_urlB = api_endpoint + "/media/" + element.images[0].id + "/file?size=1024";
              }

            } else {
              image_urlB = api_endpoint + "/media/" + element.images[1].id + "/file?size=1024";
            }





          }
        }
      }
    }
    let link_url
    let link_name
    if (element.metadata) {
      if (element.metadata.link_url) {
        link_url = element.metadata.link_url
      }
      if (element.metadata.link_name) {
        link_name = element.metadata.link_name
      }
    }
    if (vertical){
      return (
        <ElementContainerVertical
  
          id={"bm-" + id}
          ref={(el) => {
            if (!el) return;
  
            // const Xpos = el.getBoundingClientRect().left;
            // let width = el.getBoundingClientRect().width;
  
            // this.updateCardPos(width, id);
          }}
        >
  
          <TopContainerVertical
          
          >
             {!isFirst ? (  <ElementCardLineVertical></ElementCardLineVertical>):("")}
              {element.new_date_v ? (
              <DateBadgeVertical>{element.friendly_date}</DateBadgeVertical>
            ) : (
              <div></div>
            )}
             <ElementCardLineVertical></ElementCardLineVertical>
            <InnerContainerVertical>
  
              <Link
                to={project_url + "element/" + id + "/"}
                style={{ textDecoration: "none" }}
              >
                <ElementCardVertical
                isMobile={dimensions.window_dimensions.isMobile}
                  cardwidth={cardwidth}
                  selected={this.props.selected}
                >
                  {
                    {
                      "UP": (
                        <Update
                          selected={this.props.selected}
                          image_url={image_url}
                          isPlayback={isPlayback}
                          VideoControls={true}
                          show_full={show_full}
                          lazyload={lazyload}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                          element={element}
                          size={cardwidth}
                        />
                      ),
                      "CO": (
                        <Compare
                          selected={this.props.selected}
                          image_url={image_url}
                          image_urlB={image_urlB}
                          show_full={show_full}
                          lazyload={lazyload}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          compare_type={element.metadata ? (element.metadata.compare_type ? element.metadata.compare_type : "SS") : "SS"}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                          image_order={element.metadata ? (element.metadata.image_order ? element.metadata.image_order : []) : []}
                          element={element}
                        />
                      ),
                      "TE": (
                        <Text
                          selected={this.props.selected}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          textcolor={element.metadata ? (element.metadata.text_color ? element.metadata.text_color : "") : ""}
                        />
                      ),
                      "DM": (
                        <Dimension
                          selected={this.props.selected}
                          description={element.description}
                          unit={
                            element.metadata ? (element.metadata.dimension_unit ? element.metadata.dimension_unit : "") : ""
                          }
                          x={element.metadata ? (element.metadata.dimension_x ? element.metadata.dimension_x : 60) : 60}
                          y={element.metadata ? (element.metadata.dimension_y ? element.metadata.dimension_y : 60) : 60}
                          z={element.metadata ? (element.metadata.dimension_z ? element.metadata.dimension_z : 60) : 60}
                        />
                      ),
                     
                    }[element.element_type]
                  }
                </ElementCardVertical>
              </Link>
              <ElementCardLineVertical></ElementCardLineVertical>
            </InnerContainerVertical>
          </TopContainerVertical>
  
         
           
  
           
          
              {link_url ? (
                <a
                  style={{ color: "#111", textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                  href={link_url}
                >
                  <LinkBadgeVertical title={customname}>
                    <InsertLinkIcon />
                    <LinkLabel>{customname}</LinkLabel>
                  </LinkBadgeVertical>
                  <ElementCardLineVertical></ElementCardLineVertical>
                </a>
              ) : (
                ""
              )}
             
           
        
          <BottomContainerVertical>
        
            {commentcount === 0 ? (
              <CommentNew
                element_url={project_url + "element/" + id + "/"}
                group_id={group_id}
                isOwner={isOwner}
                elementid={this.props.id}
                hasImage={hasImage}
                element_type={element.element_type}
                allow_public_comments={allow_public_comments}
              ></CommentNew>
            ) : (
              ""
            )}
  
            {/* {element.comments[0] || this.state.showEditIcon  ?  <ElementCardLineBottom></ElementCardLineBottom> :"" }
          {!element.comments[0] && this.state.showEditIcon  ?  <CommentNew elementid={this.props.id}></CommentNew> :"" } */}
            <CommentContainer
              has_comments={element.has_comments}
              comments_per_group={element.comments_per_group}
              element_url={project_url + "element/" + id + "/"}
              commentcount={commentcount}
              zerocomments={zerocomments}
              group_id={group_id}
              isOwner={isOwner}
              project_id={project_id}
              project_name={project_name}
              allow_public_comments={allow_public_comments}
              id={id}
               element_type={element.element_type}
              hasImage={hasImage}
              placement="timeline"
              vertical={vertical}
            ></CommentContainer>
          </BottomContainerVertical>
        </ElementContainerVertical>
      );
    }else{
      return (
        <ElementContainer
  
          id={"bm-" + id}
          ref={(el) => {
            if (!el) return;
  
            // const Xpos = el.getBoundingClientRect().left;
            let width = el.getBoundingClientRect().width;
  
            this.updateCardPos(width, id);
          }}
        >
  
          <TopContainer
            cardheight={card_height}
            containerwidth={containerwidth}
          >
            <InnerContainer>
  
              <Link
                to={project_url + "element/" + id + "/"}
                style={{ textDecoration: "none" }}
              >
                <ElementCard
                  cardwidth={cardwidth}
                  selected={this.props.selected}
                >
                  {
                    {
                      "UP": (
                        <Update
                          selected={this.props.selected}
                          image_url={image_url}
                          isPlayback={isPlayback}
                          VideoControls={true}
                          show_full={show_full}
                          lazyload={lazyload}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                          element={element}
                          size={cardwidth}
                        />
                      ),
                      "CO": (
                        <Compare
                          selected={this.props.selected}
                          image_url={image_url}
                          image_urlB={image_urlB}
                          show_full={show_full}
                          lazyload={lazyload}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          compare_type={element.metadata ? (element.metadata.compare_type ? element.metadata.compare_type : "SS") : "SS"}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          drawing={element.metadata ? (element.metadata.drawing ? element.metadata.drawing : []) : []}
                          image_order={element.metadata ? (element.metadata.image_order ? element.metadata.image_order : []) : []}
                          element={element}
                        />
                      ),
                      "TE": (
                        <Text
                          selected={this.props.selected}
                          tag={element.metadata ? (element.metadata.label ? element.metadata.label : null) : null}
                          description={element.description}
                          link_url={element.metadata ? (element.metadata.link_url ? element.metadata.link_url : "") : ""}
                          link_name={element.metadata ? (element.metadata.link_name ? element.metadata.link_name : "") : ""}
                          textcolor={element.metadata ? (element.metadata.text_color ? element.metadata.text_color : "") : ""}
                        />
                      ),
                      "DM": (
                        <Dimension
                          selected={this.props.selected}
                          description={element.description}
                          unit={
                            element.metadata ? (element.metadata.dimension_unit ? element.metadata.dimension_unit : "") : ""
                          }
                          x={element.metadata ? (element.metadata.dimension_x ? element.metadata.dimension_x : 60) : 60}
                          y={element.metadata ? (element.metadata.dimension_y ? element.metadata.dimension_y : 60) : 60}
                          z={element.metadata ? (element.metadata.dimension_z ? element.metadata.dimension_z : 60) : 60}
                        />
                      ),
                     
                    }[element.element_type]
                  }
                </ElementCard>
              </Link>
              <ElementCardLine></ElementCardLine>
            </InnerContainer>
          </TopContainer>
  
          <LineContainer>
            {link_url && isFirst ? (
              <a
                style={{ color: "#111" }}
                target="_blank"
                rel="noreferrer"
                href={link_url}
              >
                <LinkBadgeLeft title={customname}>
                  <InsertLinkIcon />
                  <LinkLabel>{customname}</LinkLabel>
                </LinkBadgeLeft>
              </a>
            ) : (
              ""
            )}
            {element.new_date ? (
              <DateBadge>{element.friendly_date}</DateBadge>
            ) : (
              <div></div>
            )}
  
            <LineContainerLeft>
              {isFirst ? <div></div> : <LineContainerTop></LineContainerTop>}
            </LineContainerLeft>
            <LineContainerRight>
              {link_url && !isFirst ? (
                <a
                  style={{ color: "#111" }}
                  target="_blank"
                  rel="noreferrer"
                  href={link_url}
                >
                  <LinkBadge title={customname}>
                    <InsertLinkIcon />
                    <LinkLabel>{customname}</LinkLabel>
                  </LinkBadge>
                </a>
              ) : (
                ""
              )}
              {isLast ? <div></div> : <LineContainerTop></LineContainerTop>}
            </LineContainerRight>
          </LineContainer>
          <BottomContainer>
            <ElementCardLineBottom></ElementCardLineBottom>
            { }
            {commentcount === 0 ? (
              <CommentNew
                element_url={project_url + "element/" + id + "/"}
                group_id={group_id}
                isOwner={isOwner}
                elementid={this.props.id}
              hasImage={hasImage}
              element_type={element.element_type}
                allow_public_comments={allow_public_comments}
              ></CommentNew>
            ) : (
              ""
            )}
  
            {/* {element.comments[0] || this.state.showEditIcon  ?  <ElementCardLineBottom></ElementCardLineBottom> :"" }
          {!element.comments[0] && this.state.showEditIcon  ?  <CommentNew elementid={this.props.id}></CommentNew> :"" } */}
            <CommentContainer
              has_comments={element.has_comments}
              comments_per_group={element.comments_per_group}
              element_url={project_url + "element/" + id + "/"}
              commentcount={commentcount}
              zerocomments={zerocomments}
              group_id={group_id}
              isOwner={isOwner}
              project_id={project_id}
              project_name={project_name}
              allow_public_comments={allow_public_comments}
              id={id}
              element_type={element.element_type}
              hasImage={hasImage}
              placement="timeline"
            ></CommentContainer>
          </BottomContainer>
        </ElementContainer>
      );
    }
   
  }
}

const mapStateToProps = (state, ownProps) => ({
  // project: state.project,
  dimensions: state.dimensions,
  zoomlevel: state.project.zoom.level,
  card_height: state.project.zoom.card_height,
  element: state.project.current_project.elements.byID[ownProps.id],
  comments: state.project.current_project.elements.byID[ownProps.id].comments,
  elementdate:
    state.project.current_project.elements.byID[ownProps.id].friendly_date,
  element_types: state.project.current_project.element_types,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Element);
