const friendlyTimeCalc = (date, type, project_state, completion_date) => {
  let today;

  if (project_state === "CO" && completion_date) {
    // 2021-09-09T00:00:00Z

    today = new Date(completion_date);
  } else {
    today = new Date();
  }


  const el_date = new Date(date);

  // console.log(project_state)
  // console.log(new Date("2021-04-26T20:17:53.462599Z"))
  if (type === "To Do" && el_date > today) {
    return "To Do";
  } else {
    const Edate = new Date(date);
    let Today;
    if (project_state === "CO" && completion_date) {
      Today = new Date(completion_date);
    } else {
      Today = new Date();
    }
    const time = Math.abs(Today - Edate);
    // If there are years
    let humanTime;
    let units;
    if (time > 1000 * 60 * 60 * 24 * 365) {
      humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 365), 10);
      units = "year";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // If there are months
    else if (time > 1000 * 60 * 60 * 24 * 30) {
      humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 30), 10);
      units = "month";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // If there are weeks
    else if (time > 1000 * 60 * 60 * 24 * 7) {
      humanTime = parseInt(time / (1000 * 60 * 60 * 24 * 7), 10);
      units = "week";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // If there are days
    else if (time > 1000 * 60 * 60 * 24) {
      humanTime = parseInt(time / (1000 * 60 * 60 * 24), 10);
      units = "day";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // If there are hours
    else if (time > 1000 * 60 * 60) {
      humanTime = parseInt(time / (1000 * 60 * 60), 10);
      units = "hour";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // If there are minutes
    else if (time > 1000 * 60) {
      humanTime = parseInt(time / (1000 * 60), 10);
      units = "minute";
      if (humanTime !== 1) {
        units = units + "s";
      }
    }

    // Otherwise, use seconds
    else {
      humanTime = parseInt(time / 1000, 10);
      units = "seconds";
    }
    var timeUnits;
    if (project_state === "CO" && completion_date) {
      if (el_date > today) {
        timeUnits = humanTime + " " + units + " after completion";
      } else {
        if (
          units === "hours" ||
          units === "hour" ||
          units === "minutes" ||
          units === "minute" ||
          units === "seconds" ||
          units === "second"
        ) {
          timeUnits = "On day of completion";
        } else {
          timeUnits = humanTime + " " + units + " before completion";
        }
      }
    } else {
      if (el_date > today) {
        timeUnits = humanTime + " " + units + " ahead";
      } else {
        if (
          units === "seconds" ||
          units === "second"
        ) {
          timeUnits = "< 1 minute ago";
        } else {
          timeUnits = humanTime + " " + units + " ago";
        }

      }
    }

    return timeUnits;
  }
};

export default friendlyTimeCalc;