import { CONSTANTS } from "../actions";
import { element_types_local } from "../config";
const initialState = {
  projects_loading: true,
  projects: {
    byID: {},
    allIDS: [],
    list: [],
    count: 0,
    previous: "",
    next: "",
    no_results: 0,
  },
  feed_loading: true,
  feed: { byID: {}, allIDS: [] },
  feed_error: null,
  tags_loading: true,
  group_preview: {},
  tags: {
    loading: true,
    data: []
  },
  categories: {
    loading: true,
    data: [],
    selected: null
  },
};

export default function publicReducer(state = initialState, action) {
  let data = {}
  switch (action.type) {
    case CONSTANTS.SET_PUBLIC_PROJECTS_LOADING: {
      //console.log(action.payload);
      return { ...state, projects_loading: true };
    }
    case CONSTANTS.GET_GROUP_PREVIEW: {
   
      return { ...state, group_preview: action.payload };
    }
    case CONSTANTS.GET_PUBLIC_PROJECTS: {
      let new_projects = {
        byID: {},
        allIDS: [],
        count: action.payload.data.count,
        previous: action.payload.data.previous,
        next: action.payload.data.next,
        no_results: 0,
      };
      const Projects = action.payload.data.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      //console.log(action.payload.state);
      if (action.payload.data.results.length === 0) {
        new_projects.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_projects.no_results = 1;
      }
      return {
        ...state,
        projects: new_projects,
        projects_loading: false,
      };
    }

    case CONSTANTS.SET_FEED_LOADING: {
      return { ...state, feed_loading: true };
    }
    case CONSTANTS.GET_FEED: {
      let feed = {
        byID: {},
        allIDS: [],
        count: action.payload.count,
        next: action.payload.next,
        previous: action.payload.previous,
      };
      let element_types = { byID: {}, allIDS: [] };
      const Types = element_types_local;
      Types.forEach((type) => {
        element_types.allIDS.push(type.id);
        element_types.byID[type.id] = {
          id: type.id,
          name: type.name,
          style: type.style,
        };
      });
      action.payload.results.forEach((feeditem) => {
        if (feeditem.elements.length) {
          feeditem["element_type_number"] = 2
          feeditem["hero"] = feeditem.elements[0];
          feeditem.elements.shift();
          feeditem.elements.reverse();

          feed.allIDS.push(feeditem.id);
          feed.byID[feeditem.id] = feeditem;
        }
        feed.element_types = element_types
      });

      //console.log(action.payload);
      return { ...state, feed: feed, feed_loading: false };
    }
    case CONSTANTS.LOAD_MORE_FEED_ITEMS: {
      let feed = { ...state.feed };
      action.payload.results.forEach((feeditem) => {
        if (feeditem.elements.length) {
          feeditem["element_type_number"] = 2
          feeditem["hero"] = feeditem.elements[0];
          feeditem.elements.shift();
          feeditem.elements.reverse();

          feed.allIDS.push(feeditem.id);
          feed.byID[feeditem.id] = feeditem;
        }
      });
      feed.count = action.payload.count;
      feed.next = action.payload.next;
      feed.previous = action.payload.previous;

      //console.log(action.payload);
      return { ...state, feed: feed, feed_loading: false };
    }
    case CONSTANTS.GET_FEED_ERROR: {
      return { ...state, feed_loading: false, feed_error: action.payload };
    }
    // case CONSTANTS.CREATE_NEW_PROJECT: {
    //   let newprojects = {...state.projects};
    //   newprojects.push(action.payload);
    //   //console.log(action.payload);
    //   return { ...state, projects: newprojects };
    // }
    case CONSTANTS.SET_GALLERY_LOADING: {
      return { ...state, projects_loading: true };
    }
    case CONSTANTS.GET_GALLERY_PROJECTS: {
      let new_projects = {
        byID: {},
        allIDS: [],
        count: action.payload.data.count,
        previous: action.payload.data.previous,
        next: action.payload.data.next,
        no_results: 0,
      };
      const Projects = action.payload.data.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      //console.log(action.payload.state);
      if (action.payload.data.results.length === 0) {
        new_projects.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_projects.no_results = 1;
      }
      return {
        ...state,
        projects: new_projects,
        projects_loading: false,
      };
    }
    case CONSTANTS.SET_TAGS_LOADING: {
      return { ...state, tags_loading: true };
    }
    case CONSTANTS.GET_TAGS: {
      data = {}
      action.payload.results.forEach(t => data[t.name] = false)
      return {
        ...state,
        tags: {
          loading: false,
          data: action.payload.results,
        }
      };
    }
    case CONSTANTS.GET_CATEGORIES:
      return {
        ...state,
        categories: {
          loading: false,
          data: action.payload.results.map(c => {
            c.selected = false
            return c
          }),
          selected: null
        }
      };
    default:
      return state;
  }
}
