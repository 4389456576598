import React, { Component } from "react";
import styled from "@emotion/styled";
import friendlyTimeCalc from "../utils/timeAgo";
import { connect } from "react-redux";
import CommentInput from "./CommentInput";
import { deleteComment } from "../actions/projectActions";
import { api_endpoint } from "../config";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import DrawOutlinedIcon from "@mui/icons-material/DrawOutlined";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import Brightness6Icon from '@mui/icons-material/Brightness6';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import DeblurOutlinedIcon from '@mui/icons-material/DeblurOutlined';
import VrpanoOutlinedIcon from '@mui/icons-material/VrpanoOutlined';
import NaturePeopleOutlinedIcon from '@mui/icons-material/NaturePeopleOutlined';
import TextureOutlinedIcon from '@mui/icons-material/TextureOutlined';
import FilterDramaOutlinedIcon from '@mui/icons-material/FilterDramaOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import BrushOutlinedIcon from '@mui/icons-material/BrushOutlined';
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import FaceIcon from '@mui/icons-material/Face';
const SingleCommentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const AuthorImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  margin-top: -2px;
`;
const AnnotationNumber = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 16px;
  color: #fff;
  background-color: #2794f3;
  text-align: center;
  line-height: 32px;
  margin-top: -2px;
`;
const CommentTextContainer = styled.div`
  width: 100%;
  min-width: 100px;
  margin-top: 16px;
  margin-right: 16px;
  margin-bottom: 0px;
`;
const Indent = styled.div`
  margin-left: ${(props) => (props.indent ? props.indent * 30 : "0")}px;
`;
const AtParent = styled.span`
  color: #8e8e8e;
`;
const AuthorImageContainer = styled.div`
  height: 100%;
  margin-left: 12px;
  margin-top: 20px;
  margin-right: 10px;
`;
const CommentEditMode = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-right: 16px;
`;
const AuthorStyle = styled.div`
  font-weight: 700;
  font-color: #383838
  font-size: 12px;
`;
const DateStyle = styled.div`
  color: #b6b6b6;
  margin-top: 3px;
  display: flex;
  line-height: 16px;
`;
const ConfirmStyle = styled.div`
  color: #383838;
  margin-top: 3px;
  display: flex;
  line-height: 16px;
`;
const ConfirmYes = styled.span`
  color: rgb(240, 0, 115);
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      
    }
`;
const ConfirmNo = styled.span`
  color: #383838;
  padding-left: 3px;
  padding-right: 3px;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
      
    }
`;
const EditButton = styled.div`
  color: #8e8e8e;
  font-weight: 600;
  margin-left: 5px;
  cursor: pointer;
`;
const ContentStyle = styled.div`
  margin-top: 3px;
  display: flex;
  font-size: 12px;
  line-height: 16px;
`;
const ContentStyleFeedback = styled.div`
  margin-top: 3px;
 
  font-size: 12px;
  line-height: 16px;
`;
const InlineBold = styled.span`
font-weight: 600;
`;
const ContentStylePopup = styled.div`
  margin-top: 3px;
  display: flex;
  font-size: 12px;
  line-height: 16px;
`;

class Comment extends Component {
  constructor() {
    super();
    this.state = {
      editMode: false,
      replyMode: false,
      deleteMode: false,
      focusareas: {
        0: {
          ui_name: "everything",
          menu_name: "Everything",
          icon: <AllInclusiveOutlinedIcon />,
          checked: true,
        },
        1: {
          ui_name: "technique",
          menu_name: "Technique",
          icon: <BrushOutlinedIcon />,
          checked: false,
        },
        2: {
          ui_name: "composition",
          menu_name: "Composition",
          icon: <WallpaperOutlinedIcon />,
          checked: false,
        },
        3: {
          ui_name: "color/value",
          menu_name: "Color and Value",
          icon: <ColorLensOutlinedIcon />,
          checked: false,
        },
        4: {
          ui_name: "edges",
          menu_name: "Edges",
          icon: <DeblurOutlinedIcon />,
          checked: false,
        },
        5: {
          ui_name: "light/shadow",
          menu_name: "Light and Shadow",
          icon: <Brightness6Icon />,
          checked: false,
        },
        6: {
          ui_name: "idea",
          menu_name: "Idea",
          icon: <PsychologyOutlinedIcon />,
          checked: false,
        },
        7: {
          ui_name: "texture",
          menu_name: "Texture",
          icon: <TextureOutlinedIcon />,
          checked: false,
        },
        
        8: {
          ui_name: "accuracy",
          menu_name: "Accuracy",
          icon: <ManageSearchOutlinedIcon />,
          checked: false,
        },
        9: {
          ui_name: "perspective",
          menu_name: "Perspective",
          icon: <VrpanoOutlinedIcon />,
          checked: false,
        },
        10: {
            ui_name: "mood/atmosphere",
            menu_name: "Mood and Atmosphere",
            icon: <FilterDramaOutlinedIcon />,
            checked: false,
          },
          11: {
            ui_name: "facial likeness",
            menu_name: "Facial Likeness",
            icon: <FaceIcon />,
            show: ["compare"]
          },
      },
    };
    this.handleEditMode = this.handleEditMode.bind(this);
    this.handleReplyMode = this.handleReplyMode.bind(this);
  }

  componentDidMount() {
    // document.body.style.margin = "0";
    // document.body.style.backgroundColor = "#fff";
  }
  handleEditMode(state) {
    this.setState({ editMode: state });
    // if (this.state.editMode){
    //   this.setState({ editMode: false });
    // }else{

    // }
  }
  handleReplyMode(state) {
    if (this.state.replyMode === false) {
      this.setState({ replyMode: true });
    } else {
      this.setState({ replyMode: false });
    }

    // if (this.state.editMode){
    //   this.setState({ editMode: false });
    // }else{

    // }
  }
  handleDelete = () => {
    const { id, element_id } = this.props;
    this.setState({ deleteMode: false });
    this.props.dispatch(deleteComment(id, element_id));
  };
  handleDeleteState = () => {
    if (this.state.deleteMode === false) {
      this.setState({ deleteMode: true });
    } else {
      this.setState({ deleteMode: false });
    }
  };
  render() {
    const {
      id,
      author,
      element_id,
      avatar,
      content,
      date,
      date_created,
      group,
      from_group,
      groupdata,
      groupdata2,
      authorid,
      authid,
      isOwner,
      placement,
      annotation,
      public_comment,
      allow_public_comments,
      broadcast,
      comment_type,
      ai,
      answered,
      focus,
      sentiment
    } = this.props;
    let isCommentOwner = false;
    if (authorid === authid) {
      isCommentOwner = true;
    }

    let customlabel = "";
    if (group) {
      if (groupdata) {
        customlabel = " > " + groupdata.name;
      } else {
        // this is a situation where a group+project has comments, but the project is not shared in that group anymore.
        customlabel = "  >  unshared group";
      }
    } else {
      // customlabel = "All Groups";
      if (from_group) {
        if (groupdata2) {
          customlabel = " > " + groupdata2.name;
        }

        // customlabel = "temp";
        // console.log("temp")
      } else {
        customlabel = "";
      }
    }
    if (comment_type === "FR" || comment_type === "FA") {
      if (this.props.indent || comment_type === "FA"){
        return (
          <SingleCommentContainer>
            <Indent indent={this.props.indent}></Indent>
            <AuthorImageContainer style={{fontSize: "30ps"}}>
            {this.state.focusareas[(focus)].icon}
            
            </AuthorImageContainer>
  
            <CommentTextContainer>
              {/* <AuthorStyle>
                Composition
               
              </AuthorStyle> */}
              {placement === "timeline" ? (
                <ContentStyleFeedback>
                  <InlineBold>{this.state.focusareas[(focus)].menu_name + ": "}</InlineBold>
                  <span>
                   
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStyleFeedback>
              ) : (
                <ContentStyleFeedback>
                    <InlineBold>{this.state.focusareas[(focus)].menu_name + ": "}</InlineBold>
                  <span>
                   
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStyleFeedback>
              )}
              {this.state.deleteMode ? (
                <ConfirmStyle>
                  Are you sure you want to delete this response?{" "}
                  <ConfirmYes
                    onClick={() => {
                      this.handleDelete();
                    }}>
                    {" "}
                    Yes{" "}
                  </ConfirmYes>{" "}
                  |{" "}
                  <ConfirmNo
                    onClick={() => {
                      this.handleDeleteState();
                    }}>
                    {" "}
                    Cancel{" "}
                  </ConfirmNo>
                </ConfirmStyle>
              ) : (
                <DateStyle>
                  {friendlyTimeCalc(date, "comment")}
                 
                  {isCommentOwner || isOwner ? (
                   <EditButton
                   onClick={() => {
                     this.handleDeleteState();
                   }}>
                   <DeleteForeverIcon sx={{ fontSize: "14px" }} />
                 </EditButton>
                  )  : (
                    ""
                  )}
                 
                </DateStyle>
              )}
  
              
            </CommentTextContainer>
          </SingleCommentContainer>
        );
      }else{
        return (
          <SingleCommentContainer>
            <Indent indent={this.props.indent}></Indent>
            <AuthorImageContainer>
              <AccessTimeIcon sx={{ fontSize: "30px" }}/>
            </AuthorImageContainer>
  
            <CommentTextContainer>
              <AuthorStyle>
                AI Instructor feedback requested
                {customlabel}
              </AuthorStyle>
              {placement === "timeline" ? (
                <ContentStyle>
                  <span>
                    {this.props.at_parent ? (
                      <AtParent>@{this.props.at_parent} &nbsp;</AtParent>
                    ) : (
                      ""
                    )}
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStyle>
              ) : (
                <ContentStylePopup>
                  <span>
                    {this.props.at_parent ? (
                      <AtParent>@{this.props.at_parent} &nbsp;</AtParent>
                    ) : (
                      ""
                    )}
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStylePopup>
              )}
              {this.state.deleteMode ? (
                <ConfirmStyle>
                  Are you sure you want to delete this request?{" "}
                  <ConfirmYes
                    onClick={() => {
                      this.handleDelete();
                    }}>
                    {" "}
                    Yes{" "}
                  </ConfirmYes>{" "}
                  |{" "}
                  <ConfirmNo
                    onClick={() => {
                      this.handleDeleteState();
                    }}>
                    {" "}
                    Cancel{" "}
                  </ConfirmNo>
                </ConfirmStyle>
              ) : (
                <DateStyle>
                  {friendlyTimeCalc(date_created, "comment")}
                  {answered ? (""):(" | It typically takes a couple of minutes for a response | ")}
                  {isCommentOwner ? (
                   <EditButton
                   onClick={() => {
                     this.handleDeleteState();
                   }}>
                   <DeleteForeverIcon sx={{ fontSize: "14px" }} />
                 </EditButton>
                  )  : (
                    ""
                  )}
                 
                </DateStyle>
              )}
  
              
            </CommentTextContainer>
          </SingleCommentContainer>
        );
      }
     
    } else {
      if (this.state.editMode) {
        return (
          <SingleCommentContainer>
            <Indent indent={this.props.indent}></Indent>
            <AuthorImageContainer>
              {annotation ? (
                <AnnotationNumber>{annotation}</AnnotationNumber>
              ) : (
                <AuthorImage
                  src={
                    avatar &&
                    api_endpoint + "/media/" + avatar.id + "/file?size=256"
                  }></AuthorImage>
              )}
            </AuthorImageContainer>
            <CommentEditMode>
              <CommentInput
                placement={placement}
                element_id={element_id}
                isOwner={isOwner}
                editMode={true}
                comment_id={id}
                comment_content={content}
                current_group={group}
                from_group={from_group}
                handleEditMode={this.handleEditMode}
                allow_public_comments={allow_public_comments}
                broadcast={broadcast}
              />
            </CommentEditMode>
          </SingleCommentContainer>
        );
      } else {
        return (
          <SingleCommentContainer>
            <Indent indent={this.props.indent}></Indent>
            <AuthorImageContainer>
              {annotation ? (
                <AnnotationNumber>{annotation}</AnnotationNumber>
              ) : (
                <AuthorImage
                  src={
                    avatar &&
                    api_endpoint + "/media/" + avatar.id + "/file?size=256"
                  }></AuthorImage>
              )}
            </AuthorImageContainer>

            <CommentTextContainer>
              <AuthorStyle>
                {author}
                {customlabel}
              </AuthorStyle>
              {placement === "timeline" ? (
                <ContentStyle>
                  <span>
                    {this.props.at_parent ? (
                      <AtParent>@{this.props.at_parent} &nbsp;</AtParent>
                    ) : (
                      ""
                    )}
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStyle>
              ) : (
                <ContentStylePopup>
                  <span>
                    {this.props.at_parent ? (
                      <AtParent>@{this.props.at_parent} &nbsp;</AtParent>
                    ) : (
                      ""
                    )}
                    {content}
                  </span>
                  {/* <AnnotatedContentStyle></AnnotatedContentStyle> */}
                </ContentStylePopup>
              )}
              {this.state.deleteMode ? (
                <ConfirmStyle>
                  Are you sure you want to delete this comment?{" "}
                  <ConfirmYes
                    onClick={() => {
                      this.handleDelete();
                    }}>
                    {" "}
                    Yes{" "}
                  </ConfirmYes>{" "}
                  |{" "}
                  <ConfirmNo
                    onClick={() => {
                      this.handleDeleteState();
                    }}>
                    {" "}
                    Cancel{" "}
                  </ConfirmNo>
                </ConfirmStyle>
              ) : (
                <DateStyle>
                  {friendlyTimeCalc(date, "comment")}
                  {isCommentOwner ? (
                    <EditButton
                      onClick={() => {
                        this.handleEditMode(true);
                      }}>
                      {" "}
                      Edit
                    </EditButton>
                  ) : isOwner ? (
                    <EditButton
                      onClick={() => {
                        this.handleDeleteState();
                      }}>
                      <DeleteForeverIcon sx={{ fontSize: "14px" }} />
                    </EditButton>
                  ) : (
                    ""
                  )}
                  <EditButton
                    onClick={() => {
                      this.handleReplyMode();
                    }}>
                    {" "}
                    Reply
                  </EditButton>
                </DateStyle>
              )}

              {this.state.replyMode ? (
                <CommentEditMode>
                  <CommentInput
                    placement={placement}
                    element_id={element_id}
                    isOwner={isOwner}
                    editMode={false}
                    replyMode={true}
                    parent_id={id}
                    comment_content={content}
                    current_group={group}
                    from_group={from_group}
                    handleReplyMode={this.handleReplyMode}
                    allow_public_comments={allow_public_comments}
                  />
                </CommentEditMode>
              ) : (
                ""
              )}
            </CommentTextContainer>
          </SingleCommentContainer>
        );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  groupdata: state.project.current_project.groups.byID[ownProps.group],
  groupdata2: state.project.current_project.groups.byID[ownProps.from_group],
  authid: state.auth.user.user_id,
  // project_loading: state.project.loading,
});
export default connect(mapStateToProps)(Comment);
