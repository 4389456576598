import axios from "axios";
import jwt_decode from "jwt-decode";
import { api_endpoint } from "../config";
import { connect } from "react-redux";
import { updateProgress } from "../actions/uiActions";
const axiosInstance = axios.create({
  baseURL: api_endpoint,
  timeout: 60000,

  login: true,

  headers: {
    // "Content-Type": "application/json",
    accept: "application/json",
  },

});
let running = false;

let subscribers = [];

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

function onTokenRefreshed(token) {
  subscribers.map((cb) => cb(token));
}

async function addAuth(config) {

  if (config.login) {
    let access = localStorage.getItem("access_token")
    let refresh = localStorage.getItem("refresh_token");
    if (access && refresh) {
      const accessParts = jwt_decode(access);
      const refreshParts = jwt_decode(refresh);
      const now = Math.ceil(Date.now() / 1000);
      if (refreshParts.exp <= now) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login"
        throw new Error("Auth expired!")
      }
      if (accessParts.exp <= now) {
        let cfg = {
          login: false,
          headers: {
            "Authorization": "Bearer " + access
          }
        }
        let response = await axiosInstance.post("/token/refresh/", { refresh: refresh }, cfg)
        access = response.data.access
        refresh = response.data.refresh
        localStorage.setItem("access_token", access);
        localStorage.setItem("refresh_token", refresh);
      }
      config.headers["Authorization"] = "Bearer " + access
    } else {
      config.headers["Authorization"] = undefined
    }
  } else {
    config.headers["Authorization"] = undefined
  }
  return config
}

async function reauthenticate(error) {
  const originalRequest = error.config
  const refreshToken = localStorage.getItem("refresh_token");
  // console.log(originalRequest);
  if (refreshToken) {
    const tokenParts = jwt_decode(refreshToken);

    // exp date in token is expressed in seconds, while now() returns milliseconds:
    const now = Math.ceil(Date.now() / 1000);

    if (tokenParts.exp > now) {
      if (running === false) {
        running = true;
        let response = await axiosInstance.post("/token/refresh/", { refresh: refreshToken }, { login: false })
        localStorage.setItem("access_token", response.data.access);
        localStorage.setItem("refresh_token", response.data.refresh);
        onTokenRefreshed(response.data.access)
        originalRequest.headers["Authorization"] = "Bearer " + response.data.access;
        running = false;
        let token = response.data.access;
        originalRequest.headers.Authorization = `Bearer ${token}`;
        return axios(originalRequest)
      } else {
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      }
    } else {
      console.log("Refresh token is expired ", tokenParts.exp, now);
      throw error
      // window.location.href = "/login";
    }
  } else {
    console.log("Refresh token not available.");
    // window.location.href = "/login";
    throw error
    // check for allowed unauthenticated pages here
  }
  throw error
}

async function handleCommonErrors(error) {
  const response = error.response
  const originalRequest = error.config
  console.error(error)
  // console.error(`${response.status} | ${originalRequest.method} ${originalRequest.url}`)
  // Prevent infinite loops early
  if (
    error.code === 'ECONNABORTED' ||
    error.code === 'ECONNRESET'
  ) {
    // window.location.href = "/maitenence";
  }
  if (window.location.pathname === "/login") {
    throw error
  }
  if (
    error.response.status === 401 &&
    (
      originalRequest.url === "token/refresh/" ||
      originalRequest.url === "token/obtain/"
    )
  ) {
    throw error
  }
  if (
    error.response.status === 401 ||
    error.response.data.code === "token_not_valid"
  ) {
    try {
      return await reauthenticate(error)
    } catch (err2) {
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("refresh_token");
      console.error(err2)
      throw err2
    }
  }
  throw error
}

axiosInstance.interceptors.request.use(addAuth, async e => { throw e })
// axiosInstance.interceptors.response.use(r => r, handleCommonErrors)


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    console.log(error);
    console.log(error.code);
    // Prevent infinite loops early
    if (error.code === 'ECONNABORTED' || !error.response) {
      return Promise.reject('timeout');
    }

    if (
      error.response &&
      error.response.data.code === "token_not_valid" &&
      error.response.status === 401 &&
      error.response.statusText === "Unauthorized"
    ) {
      return reauthenticate(error)
    }


    if (
      error.response.status === 401 &&
      originalRequest.url === api_endpoint + "token/refresh/"
    ) {
      window.location.href = "/login";
      return Promise.reject(error);
    }
    if (error.response.data.detail === "Token is blacklisted") {
      console.log("blacklist");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      axiosInstance.defaults.headers["Authorization"] = null;

      window.location.href = "/login";
      return Promise.reject(error);
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default axiosInstance;
