import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import mimetype from "../config/mimetype";
import { saveProject, uploadSaveProject } from "../actions/projectActions";
import { upLoadcreateGroup, createGroup } from "../actions/groupActions";
import { getProjectCategories } from "../actions/profileActions";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import { getProjectContent } from "../actions/projectActions";
import { getMemberships } from "../actions/profileActions";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { setMenu } from "../actions/uiActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import GroupWorkOutlinedIcon from "@mui/icons-material/GroupWorkOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
// //   width: 700px;
//   margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const ElementContainerNarrow = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
//   // margin-bottom: 10px;
//   background-color: #fff;
//   margin-left: -1px;
//   // min-width: 100%;
// `;
// const TopContainer = styled.div`
//     max-width: 900px;
//     width: ${(props) => (props.isMobile ? "100%" : "auto")};
//     height: 100%;
//     margin-left: auto;
//     margin-right: auto;
//     // margin-left: 0;
//     // margin-right: 0;
//     display: flex;
//     flex-direction: row;
// `;
// const ElementCard = styled.div`
//   width: ${(props) => (props.isMobile ? "100%" : "calc(100%-40px)")};
//   height: calc(100% - 30px);
//   background-color: #f2f2f2;
//   padding: 20px;
//   box-shadow: ${(props) => (props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)")};
//   border-radius: 5px;
//   margin-left: 5px;
//   margin-right: 5px;
//   margin-top: 4px;
//   display: flex;
//   flex-direction: column;
// `;
// const ColumnLeft = styled.div`
//   padding-left: ${(props) => (props.isMobile ? "0px" : "20px")};
//   padding-right: ${(props) => (props.isMobile ? "0px" : "20px")};
//   width: ${(props) => (props.isMobile ? "100%" : "450px")};

//   height: 100%;
//   //   background-color: #ccc;
// `;
// const ColumnRight = styled.div`
//   width: 350px;
//   //   height: 100%;

//   margin-left: 50px;
//   //   background-color: #dadada;
// `;
// const ControlLine = styled.div`
//   width: 100%;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
// `;
// const ElementCard = styled.div`
//   width: calc(100%-40px);
//   min-height: 100%;
//   background-color: #fff;
//   padding: 20px;

//   margin-left: 5px;
//   margin-right: 5px;
//   margin-top: 0px;
//   display: flex;
//   flex-direction: column;
// `;

// const ElementDescription = styled.div`
//   font-family: "Alegreya", serif;
//   // font-family: adobe-garamond-pro, serif;
//   font-size: 14px;
//   width: ${(props) => (props.textAreaWidth ? props.textAreaWidth : "200")}px;
//   // height: 100%;
//   padding: 20px;
//   // white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   text-decoration: none !important;
//   color: #111;
// `;

// const ElementCardLine = styled.div`
//   width: 50%;
//   height: 10px;

//   border-right: 2px dashed #e3e3e3;
// `;
// const StyledInput = styled.input`
//   width: calc(100% - 12px);
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   padding: 6px;
//   border: none;
//   border-radius: 4px;
//   // &:focus {
//   //     outline-color: #e5e5e5;
//   //   }
// `;

// const StyledInputTextArea = styled.input`
//   text-align: left;
//   font-size: 16px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   margin-top: 10px;
//   margin-bottom: 20px;
//   padding: 5px;
//   border: 1px solid #cccccc;
//   border-radius: 2px;
//   &:focus {
//     outline-color: #e5e5e5;
//   }
// `;

// const StyledButtonDelete = styled.button`
//     font-size: 16px;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     width: 150px;
//     background-color: #cd3131;
//     color: white;
//     height: 32px;
//     border-radius: 2px;

//     padding-right: 30px;
//     padding-left: 30px;
//     margin-top: 20px;

//     font-size: 14px;
//     text-align: center;
//     cursor: pointer;
//     border: none;

// }
// `;
// const StyledButton = styled.button`
//     font-size: 16px;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//     font-weight: 400;
//     width: 150px;
//     background-color: #2cc0f4;
//     color: white;
//     height: 32px;
//     border-radius: 2px;

//     padding-right: 30px;
//     padding-left: 30px;
//     margin-top: 20px;
//     margin-left: auto;
//     font-size: 14px;
//     text-align: center;
//     cursor: pointer;
//     border: none;

// }
// `;

// const StyledButtonContainer = styled.div`
//    display: flex;
//    flex-direction: row;
//    width: 100%;
//    padding-bottom: 60px;

// }
// `;
// const ImagePreviewContainer = styled.div`
//   height: 118px;
//   background-size: cover;
//   width: 206px;
//   background-position: 50%;
//   background-repeat: no-repeat;
//   background-size: cover;
// `;
// const ImageProgress = styled.div`
//   height: 118px;
//   background-color: rgba(255, 255, 255, 0.5);
//   width: 206px;
//   display: flex;
// `;
// const StyledDropTarget = styled.div`
//   border: 1px dashed #dddddd;
//   width: 100%;
//   margin-bottom: 10px;
//   text-align: left;

//   background-color: white;
//   vertical-align: middle;
//   display: flex;
//   justify-content: left;
//   align-items: center;
// `;
// const StyledDropTargetText = styled.div`
//   margin: 5px;
//   font-size: 12px;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.5;
//   color: ##000000de;
//   letter-spacing: 0.00938em;
// `;
// const SettingsTitle = styled.div`
//   margin-top: ${(props) => (props.isMobile ? "10px" : "40px")};
//   font-size: 18px;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "40px")};
// `;
// const ControlLine = styled.div`
//   width: 100%;
//   margin-bottom: ${(props) => (props.isMobile ? "10px" : "30px")};
//   display: flex;
//   flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
// `;
// const ControlLabel = styled.div`
//   width: 90px;
//   color: #787878;
//   font-size: 14px;
//   padding-top: 10px;
//   padding-bottom: 10px;
// `;
// const ControlContent = styled.div`
//   width: ${(props) => (props.isMobile ? "auto" : "calc(100% - 90px)")};
// `;
// const CoverImage = styled.div`
//   width: 100%;
//   height: 290px;
//   border: 1px solid #fff;
//   border-radius: 4px;
//   background: url("${(props) => props.src}");

//   background-position: center;
//   background-size: cover;
// `;
// const UploadButtons = styled.div`
//   margin-left: 100px;

//   margin-top: 78px;
//   width: 140px;
// `;

// const Ortext = styled.div`
//   text-align: center;
//   margin-top: 20px;
//   width: 100%;
// `;
// const UploadButton = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   width: 140px;
//   background-color: rgba(255, 255, 255, 0.8);
//   color: #2196f3;
//   height: 28px;
//   border-radius: 2px;

//   padding-right: 10px;
//   padding-left: 10px;
//   padding-top: 10px;
//   margin-top: 20px;

//   font-size: 14px;
//   text-align: center;
//   cursor: pointer;
//   border: none;
// `;
// const StyledElementPopup = styled.div`
//   width: 100%;
//   height: 100%;
//   background-color: rgba(1, 1, 1, 0.5);
//   position: absolute;
//   // pointer-events: none;
//   z-index: 2000;
//   overflow-y: auto;
//   overflow-x: hidden;
//   vertical-align: middle;

// `;
const ElementContainer = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  //   width: 700px;
  margin-bottom: 0px;
  margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
  min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
  background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
`;
const TopContainer = styled.div`
  // max-width: 900px;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-left: 0;
  // margin-right: 0;
  display: flex;
  flex-direction: row;
`;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "460px")};
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 30px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: ${(props) => (props.isMobile ? "0px" : "5px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-right: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-top: ${(props) => (props.isMobile ? "0px" : "0px")};
  margin-bottom: ${(props) => (props.isMobile ? "0px" : "0px")};
  display: flex;
  flex-direction: column;
`;

const StyledButtonDelete = styled.div`
   
    margin-top: 20px;
  
    
}
`;
const StyledButton = styled.div`
    
  
    
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const StyledButtonContainer = styled.div`
   display: flex;
   flex-direction: row;
   width: 100%;
}
`;
const ImageProgress = styled.div`
  height: 118px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 206px;
  display: flex;
`;
const ControlLine = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.isMobile ? "20px" : "30px")};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "column")};
`;

const ControlLabel = styled.div`
  width: 90px;
  color: #787878;
  font-size: 14px;
  // padding-top: 10px;
  padding-bottom: 10px;
`;
const ControlContent = styled.div`
  width: ${(props) => (props.isMobile ? "auto" : "calc(100% )")};
  display: flex;
`;
const SettingsHeader = styled.div`
  display: flex;
`;
const SettingsTitle = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: ${(props) => (props.isMobile ? "-3px" : "-6px")};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: ${(props) => (props.isMobile ? "10px" : "10px")};
  margin-left: ${(props) => (props.isMobile ? "0px" : "0px")};
`;
const CoverImage = styled.div`
  width: 100%;
  height: 90px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
`;
const CoverImageSmall = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: url("${(props) => props.src}");
  position: relative;
  background-position: center;
  background-size: cover;
  // margin-top: 10px;
`;
const ImageLine = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  margin-top: 8px;
`;
const UploadButtons = styled.div`
  margin: auto;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const UploadButtonsCondensed = styled.div`
  display: flex;
  margin-left: 10px;
  padding-top: 10px;
  height: 30px;
}
`;
const OrtextC = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
`;
const UploadButton = styled.div`
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  width: 140px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #2196f3;
  height: 28px;
  border-radius: 2px;

  padding-right: 10px;
  padding-left: 0px;
  padding-top: 10px;
  margin-top: 0px;

  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
`;
const CloseButtonNarrow = styled.div`
  font-size: 30px;
  margin-top: -20px;
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "0px" : "-15px")};
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const GroupTypeContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;
const GroupTypeInstruction = styled.div`
 width: 100%
//  text-align: center;
 font-family: "Roboto", serif;
 font-size: 18px;
 font-weight: 700;

 margin-top: 15px;
`;
const SecondInstruction = styled.div`
 width: 100%
//  text-align: center;
 font-family: "Roboto", serif;
 color: #8a8a8a;
 font-size: 14px;
 font-weight: 400;
line-height: 160%;
 margin-bottom: 20px;
 margin-top: 5px;
`;
const GroupTypeStep = styled.div`
  width: calc(100% - 30px);
  background-color: ${(props) => (props.active ? "#2196f3" : "#fff")};
  display: flex;
  color: ${(props) => (props.active ? "#fff" : "#111")};
  flex-direction: row;

  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 15px;
  cursor: pointer;
  &:hover {
    border: 1px solid #2196f3;
  }
`;
const GroupTypeRight = styled.div`
  display flex;
  flex-direction: column;
  margin-left: 20px;
`;
const GroupTypeIcon = styled.div`
  font-size: 50px;
  margin-top: 3px;
`;
const GroupTypeLabel = styled.div`
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 700;
`;
const GroupTypeDescr = styled.div`
  margin-top: 5px;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
`;
const SettingsContainer = styled.div``;
class GroupPageEdit extends Component {
  constructor(props) {
    super(props);

    this.newGroup = this.newGroup.bind(this);
    this.state = {
      activeStep: 1,
      form_new: true,
      group_type: "CO",
      id: "",
      name: "",
      description: "",
      image: "",
      imageRaw: "",
      imagePreview: false,
      showImageProgress: false,
      members: [],
      admins: [],
      invited: [],
      checked: [],
      manageusers: true,
      allmembers: [],
      inviteusername: "",
      org: "private"
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeRadio = (e) => {
    this.setState({ project_state: e.target.value });
  };
  handleChangeToggle = (e) => {
    this.setState({ org: e.target.value });
  };
  deletePage = () => {
    // this.props.dispatch(deleteProject(this.state.id));
  };
  savePage = () => {
    //console.log("save project");
    const { project, project_url } = this.props;
    let selectedgroups = [];
    this.state.memberships.forEach((membership) => {
      if (membership.checked) {
        selectedgroups.push(membership.id);
      }
    });

    let sharedprop = false;
    if (this.state.public === "true") {
      // the radio buttons don't accept true/false, just strings
      sharedprop = true;
    }
    let selected_categories = [];
    this.state.category.forEach((cat) => {
      selected_categories.push(cat.id);
    });
    let data;
    if (this.state.selectedImageID) {
      data = {
        description: this.state.description,
        image: this.state.selectedImageID,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: this.state.completed_at,
        project_state: this.state.project_state,
        public: sharedprop,
        groups: selectedgroups,
        category: selected_categories,
      };
    } else {
      data = {
        description: this.state.description,
        name: this.state.name,
        cover: this.state.cover,
        completed_at: this.state.completed_at,
        project_state: this.state.project_state,
        public: sharedprop,
        groups: selectedgroups,
        category: selected_categories,
      };
    }
    if (this.state.completed_at === null || this.state.completed_at === "") {
      delete data.completed_at;
    }
    if (this.state.imagePreview) {
      // first upload and then save
      this.props.dispatch(
        uploadSaveProject(this.state.id, data, this.state.imageRaw, project_url)
      );
    } else {
      this.props.dispatch(saveProject(this.state.id, data, project_url));
    }

    const target =
      "/project/" +
      project.current_project.id +
      "/" +
      project.current_project.name + "/";

    this.props.history.push(target);
  };
  newGroup = () => {
    //console.log("new group");

    let data = {
      description: this.state.description,
      name: this.state.name,
      group_type: this.state.group_type,
    };
    if (this.state.org !== "private") {
      data.organization = this.state.org
    }
    if (this.state.imagePreview) {
      // first upload and then save
      this.props.dispatch(upLoadcreateGroup(this.state.imageRaw, data));
    } else {
      //console.log(data);
      this.props.dispatch(createGroup(data));
    }

    // const target =
    //   "/project/" +
    //   project.current_project.id +
    //   "/" +
    //   project.current_project.name;

    // this.props.history.goBack() // should find a way to go to the project after creation
  };
  handleClosePopup() {
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    this.props.history.push(target);
  }
  handleSelectImage(url, id) {
    // there is a bug here when you upload image, then select from element and the upload the same image again.
    this.setState({ selectedImage: url });
    this.setState({ selectedImageID: id });
    this.setState({ imagePreview: false });
    this.setState({ imageRaw: "" });
    if (this.state.showpopup === true) {
      this.setState({ showpopup: false });
    }
  }
  setInitialGroupSelection(memberships, groups) {
    //console.log("memberships");
    //console.log(memberships, groups);
    memberships.forEach((membership) => {
      //console.log(membership);
      membership.checked = false;
      if (groups) {
        groups.allIDS.forEach((group) => {
          if (membership.id === groups.byID[group].id) {
            membership.checked = true;
          }
        });
      }
    });
    //console.log(memberships);
    this.setState({ memberships: memberships });
  }

  doNothing(e) {
    e.stopPropagation();
  }
  componentDidMount() {
    const { project, userid, new_dialog, categories, defaultgroup } = this.props;
    // if (!categories.length) {
    //   this.props.dispatch(getProjectCategories());
    // }
    if (defaultgroup) {
      this.setState({ org: defaultgroup });
    }
    // const el = window.location.pathname.split("/")[2];
    // this.props.dispatch(getMemberships(userid));
    // if (!project.current_project.id) {
    //   this.props.dispatch(getProjectContent(el));
    // }
    //console.log("new_dialog", new_dialog);
  }
  componentDidUpdate() {
    const { project, project_loading, groups, groups_loading } = this.props;
    //console.log("update");
    //console.log(groups);
    if (this.state.initial && !project_loading) {
      //console.log("now");
      this.setState({ initial: false });
    }
    if (this.state.initialgroups && !groups_loading) {
      //console.log("nowwwww");
      this.setState({ initialgroups: false });
      this.setInitialGroupSelection(groups, project.current_project.groups);
    }
    //console.log(this.state.category);
  }
  handleImageChange = (e) => {
    this.setState({ selectedImage: "" });
    if (e.target.files.length) {
      this.setState({ imagePreview: URL.createObjectURL(e.target.files[0]) });
      this.setState({ imageRaw: e.target.files[0] });
    }
  };

  imageContainer() {
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <CoverImage src={imagesrc}>
          <UploadButtons>
            <label htmlFor="upload-button">
              {" "}
              <UploadButton>Upload New</UploadButton>{" "}
            </label>
            <input
              type="file"
              id="upload-button"
              accept={mimetype.image}
              multiple={false}
              style={{ display: "none" }}
              onChange={this.handleImageChange}
            />
          </UploadButtons>
        </CoverImage>
      );
    }
  }
  imageContainerCondensed() {
    let imagesrc = this.state.image;
    if (this.state.imagePreview) {
      imagesrc = this.state.imagePreview;
    } else {
      if (this.state.selectedImage) {
        imagesrc = this.state.selectedImage;
      }
    }
    if (this.state.showImageProgress) {
      return (
        <CoverImage>
          <ImageProgress>
            <CircularProgress color="#dddddd" style={{ margin: "auto" }} />
          </ImageProgress>
        </CoverImage>
      );
    } else {
      return (
        <ImageLine>
          <CoverImageSmall src={imagesrc}> </CoverImageSmall>
          {this.state.form_new ? (
            <UploadButtonsCondensed>
              <Button
                variant="contained"
                component="label"
                disableElevation
                color="primary"
                style={{ maxWidth: "100px", minWidth: "100px" }}
              >
                Upload
                <input
                  type="file"
                  id="upload-button"
                  accept={mimetype.image}
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={this.handleImageChange}
                />
              </Button>
            </UploadButtonsCondensed>
          ) : (
            <UploadButtonsCondensed>
              <Button
                variant="contained"
                component="label"
                disableElevation
                color="primary"
                style={{ maxWidth: "100px", minWidth: "100px" }}
              >
                Upload
                <input
                  type="file"
                  id="upload-button"
                  accept={mimetype.image}
                  multiple={false}
                  style={{ display: "none" }}
                  onChange={this.handleImageChange}
                />
              </Button>

              <OrtextC>or</OrtextC>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                style={{ maxWidth: "155px", minWidth: "155px" }}
                onClick={() => {
                  this.handleOpenPopup();
                }}
              >
                Pick from Element
              </Button>
            </UploadButtonsCondensed>
          )}
        </ImageLine>
      );
    }
  }
  handleGroupType = (type) => {
    this.setState({ group_type: type });
    this.setState({ activeStep: this.state.activeStep + 1 });
  };
  getSteps() {
    return ["Select Group Type", "Create Group Settings"];
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return "Step 1: Select group type...";
      case 1:
        return "Step 2: Create Group Settings";
      default:
        return "Unknown step";
    }
  }

  render() {
    const { dimensions, project_url, organizations } = this.props;

    // const handleBack = () => {
    //   this.setState({ activeStep: this.state.activeStep - 1 });
    // };

    // let imagesrc = this.state.image;
    // if (this.state.imagePreview) {
    //   imagesrc = this.state.imagePreview;
    // } else {
    //   if (this.state.selectedImage) {
    //     imagesrc = this.state.selectedImage;
    //   }
    // }
    // let pageTitle = "Group Settings";
    // let default_cat = project.current_project.category;
    if (this.state.form_new) {
      // pageTitle = "";
      //   default_cat = [];
    }
    return (
      <div style={{ height: "100%" }}>
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <ElementCard isMobile={dimensions.window_dimensions.isMobile}>
              {/* <Stepper activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStep(index)}>{label}</StepButton>
                  </Step>
                ))}
              </Stepper> */}
              {this.state.activeStep === 0 ? (
                <GroupTypeContainer>
                  <SettingsHeader>
                    <SettingsTitle
                      isMobile={dimensions.window_dimensions.isMobile}
                    >
                      Select what type of group your want to create
                    </SettingsTitle>
                    <CloseButtonNarrow
                      isMobile={dimensions.window_dimensions.isMobile}
                      onClick={() => {
                        this.handleCloseDialog(project_url);
                      }}
                    >
                      {" "}
                      <IconButton size="large">
                        <CloseRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </CloseButtonNarrow>
                  </SettingsHeader>
                  <GroupTypeInstruction> </GroupTypeInstruction>
                  <SecondInstruction>
                    {" "}
                    Different types of groups allow members to contribute in the
                    form of comments or projects{" "}
                  </SecondInstruction>
                  <GroupTypeStep
                    active={this.state.group_type === "PU"}
                    onClick={() => {
                      this.handleGroupType("PU");
                    }}
                  >
                    <GroupTypeIcon>
                      {" "}
                      <AddPhotoAlternateOutlinedIcon fontSize="inherit" />
                    </GroupTypeIcon>
                    <GroupTypeRight>
                      <GroupTypeLabel>Publication</GroupTypeLabel>
                      <GroupTypeDescr>
                        Only admins can share projects and add comments. Members
                        can view projects.
                      </GroupTypeDescr>
                    </GroupTypeRight>
                  </GroupTypeStep>
                  <GroupTypeStep
                    active={this.state.group_type === "FE"}
                    onClick={() => {
                      this.handleGroupType("FE");
                    }}
                  >
                    {" "}
                    <GroupTypeIcon>
                      {" "}
                      <FeedbackOutlinedIcon fontSize="inherit" />
                    </GroupTypeIcon>
                    <GroupTypeRight>
                      <GroupTypeLabel>Feedback</GroupTypeLabel>
                      <GroupTypeDescr>
                        Only admins can share projects. Admins and members can
                        add comments.
                      </GroupTypeDescr>
                    </GroupTypeRight>
                  </GroupTypeStep>
                  <GroupTypeStep
                    active={this.state.group_type === "CO"}
                    onClick={() => {
                      this.handleGroupType("CO");
                    }}
                  >
                    {" "}
                    <GroupTypeIcon>
                      {" "}
                      <GroupWorkOutlinedIcon fontSize="inherit" />
                    </GroupTypeIcon>
                    <GroupTypeRight>
                      <GroupTypeLabel>Collaboration</GroupTypeLabel>
                      <GroupTypeDescr>
                        Both admins and members can share projects and add
                        comments
                      </GroupTypeDescr>
                    </GroupTypeRight>
                  </GroupTypeStep>
                </GroupTypeContainer>
              ) : (
                ""
              )}
              {this.state.activeStep === 1 ? (
                <SettingsContainer>
                  <SettingsHeader>
                    <SettingsTitle
                      isMobile={dimensions.window_dimensions.isMobile}
                    >
                      Enter {this.state.group_type === "FE" ? "Feedback " : ""}
                      {this.state.group_type === "CO" ? "Collaboration " : ""}
                      {this.state.group_type === "PU" ? "Publication " : ""}
                      Group Details
                    </SettingsTitle>
                    <CloseButtonNarrow
                      isMobile={dimensions.window_dimensions.isMobile}
                      onClick={() => {
                        this.handleCloseDialog(project_url);
                      }}
                    >
                      {" "}
                      <IconButton size="large">
                        <CloseRoundedIcon fontSize="inherit" />
                      </IconButton>
                    </CloseButtonNarrow>
                  </SettingsHeader>
                  <GroupTypeInstruction> </GroupTypeInstruction>
                  <SecondInstruction>
                    Give your group a name a describe what the group is about.
                    You can always change it later.
                  </SecondInstruction>
                  {organizations && organizations.length ? (
                    <ControlLine isMobile={dimensions.window_dimensions.isMobile}>
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          Organization
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.org}
                          onChange={this.handleChangeToggle}
                          label="Organization"
                        >
                          <MenuItem key="element00" value="private">
                            Private (Not associated with an organization)
                          </MenuItem>
                          {organizations.map((org, index) => {
                            return (
                              <MenuItem key={"org" + index} value={org.id}>
                                {org.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </ControlLine>

                  ) : ("")}

                  <ControlLine isMobile={dimensions.window_dimensions.isMobile}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        size="small"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        id="name"
                        label="Name"
                        variant="outlined"
                        value={this.state.name}
                        onChange={this.onChange}
                      />
                    </FormControl>
                    {/* <StyledInput
                   value={this.state.name}
                   id="name"
                   onChange={this.onChange}
                   type="text"></StyledInput> */}
                  </ControlLine>
                  <ControlLine isMobile={dimensions.window_dimensions.isMobile}>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        size="small"
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        id="description"
                        label="Description"
                        variant="outlined"
                        multiline
                        value={this.state.description}
                        onChange={this.onChange}
                      />
                    </FormControl>
                  </ControlLine>
                  <ControlLine
                    style={{ marginBottom: "10px" }}
                    isMobile={dimensions.window_dimensions.isMobile}
                  >
                    <ControlLabel>Banner</ControlLabel>
                    <ControlContent
                      isMobile={dimensions.window_dimensions.isMobile}
                    >
                      {this.imageContainer()}
                    </ControlContent>
                  </ControlLine>

                </SettingsContainer>
              ) : (
                ""
              )}

              <StyledButtonContainer>
                {this.state.form_new ? (
                  ""
                ) : (
                  <StyledButtonDelete>
                    <Button
                      disableElevation
                      variant="contained"
                      color="secondary"
                      style={{ minWidth: "150px" }}
                      onClick={() => {
                        this.deletePage();
                      }}
                    >
                      Delete
                    </Button>
                  </StyledButtonDelete>
                )}
                {this.state.activeStep === 1 ? (
                  <StyledButtonDelete>
                    {/* <Button
                    disableElevation
                  
                    
                    style={{ minWidth: "150px" }}
                    onClick={() => {
                        handleBack();
                      }}>
                    Back
                  </Button> */}
                  </StyledButtonDelete>
                ) : (
                  ""
                )}
                {this.state.activeStep === 1 ? (
                  <StyledButton>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      style={{ minWidth: "150px" }}
                      onClick={() => {
                        this.newGroup();
                      }}
                    >
                      Create
                    </Button>
                  </StyledButton>
                ) : (
                  ""
                )}
              </StyledButtonContainer>
            </ElementCard>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  project_loading: state.project.loading,
  elements: state.project.current_project.elements,
  userid: state.auth.user.user_id,
  defaultgroup: state.ui.default,
  categories: state.auth.project_categories,
  organizations: state.auth.myprofile.organizations,
  groups: state.profile.groups,
  groups_loading: state.profile.groups_loading,
  dimensions: state.dimensions,
});
export default connect(mapStateToProps)(withRouter(GroupPageEdit));
