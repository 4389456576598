import React, { Component } from "react";
import styled from "@emotion/styled";
// import TextareaAutosize from "react-textarea-autosize";

import { connect } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { addComment } from "../actions/projectActions";
import { saveComment } from "../actions/projectActions";
import { deleteComment } from "../actions/projectActions";
import { AnnotateMode } from "../actions/uiActions";
import { AnnotateCoordinates } from "../actions/uiActions";
import { setMenu, setSelectedElement } from "../actions/uiActions";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import IconButton from "@mui/material/IconButton";
const NewCommentHeader = styled.div`
  background-color: #fff;
  min-height: 35px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 10px;
  // margin-top: 10px;
  // border: 1px solid #C4C4C4;;
  // border-left:  ${(props) => (props.showAnnotation ? "none" : "1px solid #C4C4C4")};
  // border-bottom:  ${(props) => (props.showAnnotation ? "none" : "1px solid #C4C4C4")};
  // border-radius: 3px;
  display: flex;

  flex-direction: column;
`;

const CommentBox = styled.div`
  
  display: flex;
  fontsize: "12px";
  fontfamily: "Roboto, Helvetica, Arial, sans-serif";
  fontweight: "400";
  lineheight: "1.8";
  color: " #000000de";
`;
const OptionBox = styled.div`
  
padding-left: 10px;
`;
const NewCommentContainer = styled.div`
  background-color: #fff;
  // min-height: 65px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  // margin-bottom: 10px;
  margin-top:  ${(props) => (props.placement === "timeline" ? "0" : "10")}px;
  display: flex;
  flex-direction: column;
`;
const CommentSecondLine = styled.div`
  display: flex;
  // margin-top: 10px;
  // margin-left: 1px;
`;
const CommentFirstLine = styled.div`
  display: flex;
  border: 1px solid #C4C4C4;;
`;
const NoCommentInstruction = styled.div`
  fontsize: "12px";
  fontfamily: "Roboto, Helvetica, Arial, sans-serif";
  padding: 12px;
  color: #ccc;
  border: 1px solid #C4C4C4;
`;
const DropdownContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;
const StackedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const SecondLineLabel = styled.div`
  white-space: nowrap;
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-right: 4px;
`;
const RightButtons = styled.div`
  margin-left: auto;
  display: flex;
  min-width: 80px;
`;
const EditCommentButtons = styled.div`
  width: 100%;
  display: flex;
`;
const DeleteButton = styled.div`
  color: #8e8e8e;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
`;
const AnnotationButton = styled.div`
  margin-top: 5px;
  padding: 5px;
  color: #111;
  display: flex;
  // height: 25px;
  border-radius: 5px;
  // background-color: #efefef;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
     
    }
  }
`;
const FeedbackButton = styled.div`
  margin-bottom: 5px;
  padding: 5px;
  color: #111;
  display: flex;
  // height: 25px;
  border-radius: 5px;
  // background-color: #efefef;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
     
    }
  }
`;
const AnnotationIcon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 6px;
  margin-right: 6px;
  margin-top: 3px;
  background-color: #2794f3;
`;
const AnnotationInstruction = styled.div`
  margin-top: 5px;
  color: #8e8e8e;
  display: flex;
  font-size: 14px;
`;
const AnnotationWrapper = styled.div`
  // margin-top: 5px;
  margin-bottom: 16px;
`;
const CancelAnnotateButton = styled.div`
  margin-left: 5px;
  color: #8e8e8e;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
`;
const CancelButton = styled.div`
  margin-left: 5px;
  color: #8e8e8e;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
`;
const AnnotationNumber = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  color: #fff;
  background-color: #2794f3;
  text-align: center;
  line-height: 24px;
  margin-top: 6px;
  margin-right: 0px;
  margin-left: 5px;
`;
class CommentInput extends Component {
 
  constructor() {
    super();
    
    this.state = {
      comment: "",
      selectedGroup: "1",
      selectedGroupName: "Private",
      showDropDown: false,
      availableGroups: [],
      initial: true,
      showOption: false,
      broadcast: false,
     
    };
  }
  componentDidMount() {
    const { comment_content, current_group, editMode, isOwner, groups, project, broadcast } =
      this.props;
    if (current_group) {
      this.setState({ selectedGroup: current_group });
      this.setState({ showDropDown: false });
      // if (!isOwner) {
      //   this.setState({ showDropDown: false });
      // }
    } else {
      if (!isOwner) {
        this.setState({ selectedGroup: groups.allIDS[0] });
      }
    }

    if (!project.public && !project.share_link_enabled && groups.allIDS.length === 0) {
      this.setState({ broadcast: true });
    }
    console.log(isOwner)
    if (isOwner) {
      this.setState({ broadcast: true });
    }
    if (editMode) {
      this.setState({ comment: comment_content });
      this.setState({ broadcast: broadcast });
      console.log(broadcast)
    }
    this.handleClearAnnotation();
  }
  componentDidUpdate() {
    const { groups, project, isOwner, broadcast, editMode } =
      this.props;
      console.log(editMode)
      if (isOwner && editMode === false && this.state.initial) {
      if (this.state.broadcast === false ) {
        this.setState({ broadcast: true });
        this.setState({ initial: false });
      }

    } else {
      //
    }

  }
  handlePostButton = () => {
    if (this.props.auth.isAuthenticated){
      let allow_comment = false
      if (this.props.from_group){
        allow_comment = true
      }else{
        if (this.props.auth.ticket){
          allow_comment = true
        }else{
          allow_comment = false
        }
      }
      if (allow_comment){
        // this is a group project
     
      this.myInp.blur();
      if (this.state.comment !== "") {
        if (this.props.editMode) {
          this.props.handleEditMode(false);
          this.handleSaveComment();
        } else {
          if (this.props.replyMode) {
            this.props.handleReplyMode();
            this.handleAddComment();
          } else {
            this.handleAddComment();
          }
        }
  
        this.setState({ comment: "" });
      }
    }else{
      this.props.dispatch(setMenu(true, 22))
      
    }
    }else{
      this.props.dispatch(setMenu(true, 13))
    }
   
  };
  handleFeedbackButton = ( ) =>{
    const {
      element_id,
      from_group,
      element_type
    } = this.props;
    let groupid = false;
    if (from_group !== "1" && from_group !== "2") {
      groupid = from_group;
    }
    this.props.dispatch(setSelectedElement(element_id, groupid, element_type, this.props.groups.allIDS.length));
    this.props.dispatch(setMenu(true, 23));
  }
  handleAddComment = () => {
    const {
      element_id,
      annotationCoordinates,
      from_group,
      replyMode,
      parent_id,
      project,
      isOwner
    } = this.props;
    let groupid = false;
    if (from_group !== "1" && from_group !== "2") {
      groupid = from_group;
    }
    console.log("rom", from_group)
    let data = {
      content: this.state.comment,
      element: element_id,
      public: true,
    };
    if (from_group) {
      data.public = false;
    }
    if (groupid) {
      data.group = groupid;
    }
    if (this.state.broadcast) {
      data.public = true;
      data.broadcast = true;
      data.group = null;
    }
    if (replyMode) {
      if (isOwner){
        const parentcomment = project.elements.byID[element_id].comments.byID[parent_id]
        data.public = parentcomment.public;
        data.broadcast = parentcomment.broadcast;
        data.group = parentcomment.group;
      }
     
   
      data.parent = parent_id;
    }
   
    if (annotationCoordinates.x) {
      data.x = annotationCoordinates.x;
      data.y = annotationCoordinates.y;
    }
    console.log(data)
    this.handleClearAnnotation();
    this.props.dispatch(addComment(data));
  };
  handleSaveComment = () => {
    const { element_id, comment_id, broadcast, from_group } = this.props;
    // let groupid = false;
    // if (this.state.selectedGroup !== "1" && this.state.selectedGroup !== "2") {
    //   groupid = this.state.selectedGroup;
    // }
   
    let data = {
      content: this.state.comment,
      element: element_id,
      broadcast: this.state.broadcast
      // public: false,
    };
    // if (this.state.broadcast === false){
    //   let groupid = false;
    //   if (from_group !== "1" && from_group !== "2") {
    //     groupid = from_group;
    //   }
    //   if (groupid) {
    //     data.group = groupid;
    //   }
    // }else{
    //   data.public = true;
    //   data.broadcast = true;
    //   data.group = null;
    // }
    // console.log(this.state.selectedGroup)
    // console.log(this.props.from_group)

    // if (groupid) {
    //   data.group = groupid;
    // } else {
    //   data.group = null;
    // }
    // if (this.props.public_comment) {
    //   data.public = true;
    //   data.group = null
    // }
    this.props.dispatch(saveComment(comment_id, data));
  };
  handleChangeComment = (newValue) => {
    this.setState({ comment: newValue });
  };
  handleCancel = () => {
    this.props.handleEditMode(false);
  };
  handleAnnotate = () => {
    if (this.props.auth.isAuthenticated){
      this.props.dispatch(AnnotateMode(true));
    }else{
      this.props.dispatch(setMenu(true, 13))
    }
    
  };
  handleCancelAnnotation = () => {
    this.props.dispatch(AnnotateMode(false));
  };
  handleClearAnnotation = () => {
    this.props.dispatch(AnnotateCoordinates(false, false));
    this.props.dispatch(AnnotateMode(false));
  };
  handleDelete = () => {
    const { comment_id, element_id } = this.props;
    this.props.dispatch(deleteComment(comment_id, element_id));
    this.props.handleEditMode(false);
  };
  handleChangeGroup = (event) => {
    this.setState({ selectedGroup: event.target.value });
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  onChangeBroadcast = (e) => {
    this.setState({ broadcast: e.target.checked });
  };
  onFocus = (e) => {
    if (this.props.auth.isAuthenticated){
      if (this.props.groups.allIDS.length > 0) {
        this.setState({ showOption: true });
      }
    }else{
    
      this.props.dispatch(setMenu(true, 13))
      
    }
   

  };
  onBlur = (e) => {
    const {
      placement,
    } = this.props;
    if (placement === "timeline") {
      this.setState({ showOption: false });
    }

  };
  render() {
    const {
      from_group,
      editMode,
      groups,
      isOwner,
      placement,
      annotationMode,
      annotationCoordinates,
      newCommentAnnotationNumber,
      element_type,
      hasImage,
      allow_public_comments,
      replyMode
    } = this.props;
    let templabel;
    //console.log(groups.byID[from_group]);

    if (!from_group) {
      templabel = "New comment...";
    } else {

      templabel = "New comment...";
      // "Write new comment in this group'" +
      // groups.byID[this.state.selectedGroup].name +
      // "'...";

    }
 
    let allowComment = false;
    let commentInstruction =
      "---";
    // console.log(from_group)
    //console.log(isOwner);
    if (from_group === "2") {
      commentInstruction = "Select a specific group to enter a new comment in.";
    }
    else if (from_group === "unshared") {
      allowComment = false;
      commentInstruction = "Viewing comments from unshared and deleted groups."
    }
    else if (from_group) {
      // check group exists
      if (groups.byID[from_group]) {
        if (groups.byID[from_group].archived) {
          allowComment = false;
          commentInstruction = "This group is archived, so you can not enter comments.";
        } else if (
          groups.byID[from_group].viewer_role !== "VI" ||
          groups.byID[from_group].viewer_is_admin ||
          isOwner
        ) {
          allowComment = true;
        } else {
          commentInstruction = "Your role in this group is Viewer, so you can not enter comments.";
        }
      } else {
        allowComment = false;
        commentInstruction = "The group you selected could not be found."
      }
    } else {
      // public
      if (allow_public_comments || isOwner) {
        allowComment = true;
      } else {
        commentInstruction = "Public commenting has been turned off for this project";
      }


    }

    let showAnnotation = false;

    if (placement === "popup" && element_type === "UP" && hasImage) {
      showAnnotation = true;
    }
    // if (element_style === "3") {
    //   showAnnotation = true;
    // }
    let setAnnotation = false;
    if (annotationCoordinates.x !== false) {
      setAnnotation = true;
    }

    if (allowComment) {
      return (
        <NewCommentContainer placement={placement}>
          {showAnnotation && !editMode ? (
            <AnnotationWrapper>
              {setAnnotation ? (
                <AnnotationInstruction>
                  Annotation Set
                  <CancelAnnotateButton
                    onClick={() => {
                      this.handleClearAnnotation();
                    }}>
                    | clear
                  </CancelAnnotateButton>
                </AnnotationInstruction>
              ) : (
                <div>
                  {annotationMode ? (
                    <AnnotationInstruction>
                      Click in image to set annotation
                      <CancelAnnotateButton
                        onClick={() => {
                          this.handleCancelAnnotation();
                        }}>
                        | cancel
                      </CancelAnnotateButton>
                    </AnnotationInstruction>
                  ) : (
                    <AnnotationButton
                      onClick={() => {
                        this.handleAnnotate();
                      }}>
                      <AnnotationIcon></AnnotationIcon>
                      <span style={{marginLeft: "10px"}}>Add Annotation</span>
                    </AnnotationButton>
                  )}
                </div>
              )}
                         </AnnotationWrapper>
          ) : (
            ""
          )}
            {hasImage && isOwner && !replyMode && !editMode && placement !== "timeline"  ?   <FeedbackButton style={{marginTop: "-10px"}} onClick={() => this.handleFeedbackButton()}><AutoAwesomeIcon sx={{ fontSize: "18px", color: "#111111" }} />  <span style={{marginTop: "2px", marginLeft: "8px"}}>Request AI Feedback</span> </FeedbackButton> : ""}

          <CommentFirstLine >
            {annotationCoordinates.x && showAnnotation ? (
              <AnnotationNumber>{newCommentAnnotationNumber}</AnnotationNumber>
            ) : (
              ""
            )}
            <NewCommentHeader >
              <CommentBox>
                <TextField
                  variant="standard"
                  id="comment"
                  ref={(ip) => (this.myInp = ip)}
                  inputProps={{
                    style: { lineHeight: " 16px", fontSize: "12px" },
                  }}
                  InputProps={{ disableUnderline: true }}
                  placeholder={templabel}
                  multiline
                  value={this.state.comment}
                  onChange={this.onChange}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  fontSize="inherit"
                  style={{
                    padding: " 5px",
                    paddingLeft: " 10px",
                    border: "none",
                    borderRadius: "3px",
                    width: "calc(100% - 15px)",
                  }}
                />

                <Button
                  onClick={() => this.handlePostButton()}
                  color="primary"
                  disableElevation
                  size="small">
                  POST
                </Button>
              
                {hasImage && isOwner && !replyMode && !editMode && !showAnnotation && placement === "timeline" ?   <IconButton  onClick={() => this.handleFeedbackButton()}><AutoAwesomeIcon sx={{ fontSize: "18px", color: "#111111" }} /> </IconButton> : ""}
               
              </CommentBox>
              {this.state.showOption && isOwner && !replyMode && !editMode ? (<OptionBox>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel

                      control={
                        <Checkbox

                          color="primary"
                          size="small"
                          checked={this.state.broadcast}
                          onChange={this.onChangeBroadcast}
                          name="broadcast"
                          id="broadcast"
                        />
                      }
                      label={<div style={{ fontSize: "12px" }}>Display in all groups and public</div>}
                    />
                  </FormGroup>
                </FormControl>
              </OptionBox>) : ("")}

            </NewCommentHeader>

          </CommentFirstLine>
          <CommentSecondLine>
            <StackedContainer>

              {this.state.showDropDown && !from_group ? (
                <DropdownContainer>
                  <SecondLineLabel>Enter in: </SecondLineLabel>
                  <FormControl>
                    <Select
                      MenuProps={{
                        style: { zIndex: 35001 },
                      }}
                      style={{
                        fontSize: "12px",
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontWeight: "400",
                        lineHeight: "1.8",
                        color: " #000000de",
                        height: "15px",
                      }}
                      value={this.state.selectedGroup}
                      onChange={this.handleChangeGroup}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}>
                      {isOwner &&
                        (isOwner ? (
                          <MenuItem value={"1"}>Private</MenuItem>
                        ) : (
                          ""
                        ))}
                      {isOwner &&
                        (isOwner ? (
                          <MenuItem value={"2"}>
                            Public (Show in All Groups)
                          </MenuItem>
                        ) : (
                          ""
                        ))}
                      {groups.allIDS.map((groupid, index) => {
                        return (
                          <MenuItem key={"group" + index} value={groupid}>
                            {groups.byID[groupid].name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </DropdownContainer>
              ) : (
                ""
              )}
            </StackedContainer>
            {editMode ? (
              <EditCommentButtons>
               {/* {isOwner && !replyMode ? (<OptionBox>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel

                      control={
                        <Checkbox

                          color="primary"
                          size="small"
                          checked={this.state.broadcast}
                          onChange={this.onChangeBroadcast}
                          name="broadcast"
                          id="broadcast"
                        />
                      }
                      label={<div style={{ fontSize: "12px" }}>Display in all groups and public</div>}
                    />
                  </FormGroup>
                </FormControl>
              </OptionBox>) : ("")} */}
                <RightButtons>
                  <DeleteButton
                    onClick={() => {
                      this.handleDelete();
                    }}>
                    delete
                  </DeleteButton>
                  <CancelButton
                    onClick={() => {
                      this.handleCancel();
                    }}>
                    | cancel
                  </CancelButton>
                </RightButtons>
              </EditCommentButtons>
            ) : (
              ""
            )}
          </CommentSecondLine>
        </NewCommentContainer>
      );
    } else {
      return <NewCommentContainer placement={placement}><NoCommentInstruction>{commentInstruction}</NoCommentInstruction></NewCommentContainer>;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  groups: state.project.current_project.groups,
  annotationMode: state.ui.annotationMode,
  annotationCoordinates: state.ui.annotationCoordinates,
  project: state.project.current_project,
  auth: state.auth
});
export default connect(mapStateToProps)(CommentInput);
