import React, { Component } from "react";
import styled from "@emotion/styled";

class SimpleGrid extends Component {
  handleMouseDownObj(obj, e) {
    const { editable } = this.props;
    if (editable) {
      this.props.handleMouseDownObj(obj, e);
    }
  }
  

  render() {
    const { obj, svg, objSelect, drawnow } = this.props;
    let x, y, w, h;
    let width = 1;
    let height = 1;
    let used_ratio = 1;
    if (drawnow) {
      if (svg) {
        width = svg.getBoundingClientRect().width;
        height = svg.getBoundingClientRect().height;

        used_ratio = width / height;
      }

      const linethickness = (1.5 / width) * 100;
      const linethickness2 = 0.2 * obj.sz / used_ratio;
      const dot = 3;
      const dotx = (dot / width) * 100;
      const doty = ((dot / height) * 100) / used_ratio;
      const square = obj.options.square;

      const columns = obj.options.columns;
      const rows = obj.options.rows;
      const expand = obj.options.expand;
      let gridwidth, gridheight;

      if (obj.xStart < obj.xEnd) {
        x = obj.xStart;
        w = obj.xEnd - obj.xStart;
      } else {
        x = obj.xEnd;
        w = obj.xStart - obj.xEnd;
      }

      if (obj.yStart < obj.yEnd) {
        y = obj.yStart;
        h = obj.yEnd - obj.yStart;
      } else {
        y = obj.yEnd;
        h = obj.yStart - obj.yEnd;
      }
      if (expand) {
        x = 0;
        y = 0;
        w = 100;
        h = 100 / used_ratio;
        gridwidth = 100;
        gridheight = 100 / used_ratio;
      } else {
        gridwidth = w;
        gridheight = h;
      }

      const column_spacing = gridwidth / columns;
      const row_spacing = gridheight / rows;
      let square_rows = 0;
      if (column_spacing !== 0) {
        square_rows = Math.ceil(gridheight / column_spacing);
      }

     
      // console.log(columns, rows, expand,square_rows,column_spacing)
      let lines = [];
      for (let i = 1; i < columns; i++) {
        const line = (
          <line
            onMouseDown={this.handleMouseDownObj.bind(this, obj)}
            key={obj.id + "col" + i}
            x1={x + i * column_spacing}
            y1={y}
            x2={x + i * column_spacing}
            y2={y + h}
            strokeWidth={linethickness2}
            strokeOpacity={obj.op+"%"}
            stroke={obj.bg}
          />
        );

        lines.push(line);
      }

      if (square) {
        for (let i = 1; i < square_rows; i++) {
          const line = (
            <line
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              key={obj.id + "row" + i}
              x1={x}
              y1={y + i * column_spacing}
              x2={x + w}
              y2={y + i * column_spacing}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
          );

          lines.push(line);
        }
      } else {
        for (let i = 1; i < rows; i++) {
          const line = (
            <line
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
              key={obj.id + "row" + i}
              x1={x}
              y1={y + i * row_spacing}
              x2={x + w}
              y2={y + i * row_spacing}
              strokeWidth={linethickness2}
              strokeOpacity={obj.op+"%"}
              stroke={obj.bg}
            />
          );

          lines.push(line);
        }
      }
      return (
        <g key={obj.id}>
          {lines}
          {obj.id === objSelect ? (
            <g>
              {" "}
              <rect
                x={x}
                y={y}
                width={ w}
                height={ h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                onMouseDown={this.handleMouseDownObj.bind(this, obj)}
                
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
              <rect
              
                x={x + w - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
               
                x={x + w - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                name="3"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x - dotx}
                y={y + h - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
              <rect
              
                x={x - dotx}
                y={y - doty}
                width={dotx * 2}
                height={doty * 2}
                fill="white"
                stroke="#383838"
                strokeWidth={linethickness}
              />
            </g>
          ) : (
            <g
              onMouseDown={this.handleMouseDownObj.bind(this, obj)}
             >
              {" "}
              <rect
                x={x}
                y={y}
                width={ w}
                height={h}
                fill="none"
                stroke={obj.bg}
                strokeWidth={linethickness2}
                strokeOpacity={obj.op+"%"}
              />
              <rect
                x={x}
                y={y}
                width={w}
                height={h}
                fill="none"
                strokeOpacity="0%"
                stroke={obj.bg}
                strokeWidth={linethickness * 15}
              />
            </g>
          )}
        </g>
      );
    } else {
      return "";
    }
  }
}

export default SimpleGrid;
