import React, { Component } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setMenu } from "../actions/uiActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "../custom-icons/CloseIcon";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import imgUrl from "../utils/imgUrl";
import { resetProjectLink } from "../actions/uiActions";
import {
    createSubscriptionGenericProfile,
    removeSubscriptionGenericProfile,
    loadMoreProfileSubscribers
  } from "../actions/profileActions";
import {
  ElementContainer,
  TopContainer,
  SettingsHeader,
  SettingsColumns,
  ColumnMiddle,
  ColumnCenter,
  CropPanel,
  PanelContainer,
  PanelOne,
  PanelTwo,
  SettingsTitle,
  CloseButtonNarrow,
  EditItem,
  SectionIcon,
  SectionLabel,
  SectionNumber,
  SectionContent,
  SectionHeader,
  BackIcon,
  SectionLabel2,
  SectionFooter,
  SectionHelpText,
  ButtonContainer,
  Dialog
} from "./styles/MenuComponents.js";

const SubscriptionCard = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
 
`;
const ImageContainer = styled.div`
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const CircularImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
`;
const SubscriptionsDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  width: 100%;
`;
const SubName = styled.div`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
  margin-top: auto;
  margin-bottom: auto;
    text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 228px);
  display: block;
 

`;
const TestStyle = styled.div`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 500;
  // margin-top: auto;
  // margin-bottom: auto;
    text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  background-color: #f2f2f2;
  height: 50px;

`;
const TestStyle2 = styled.div`

  width: 100%;
  display: flex;

`;
const TestStyle3 = styled.div`

  width: 100px;
  background-color: #ccc;
  margin-right: 32px;

`;
const Description = styled.div`
  display: flex;
`;
const UnsubscribeButton = styled.div`
  display: flex;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
 padding-top: 32px;
  padding-left: 32px;
  overflow-y: auto;
    max-height:  ${(props) => (props.isMobile ? "auto" : "351px")};
  
    border-top: 1px solid #e0e0e0
`;
const GroupLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
class ProfilePageSubscribers extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
     
    };
  }
  
  resetLink(){
    this.props.dispatch(resetProjectLink(true));
    this.handleCloseDialog()
  }
  handleCloseDialog(target) {
    this.props.dispatch(setMenu(false));
    
   
    // this.props.history.replace(target);
  }
  
  
  loadMore = (link, type) => {
    this.props.dispatch(loadMoreProfileSubscribers(link, type));
  };
 
  
  componentDidMount() {
    const { dimensions } = this.props;
   
   
  }
  componentDidUpdate() {
    const {
   
      dimensions,
    } = this.props;
   
  }
  unSubscribe(type, id) {
    console.log("unsubscribe");
    this.props.dispatch(removeSubscriptionGenericProfile("users", id));
  }
  Subscribe(type, id) {
    console.log("unsubscribe");
    this.props.dispatch(createSubscriptionGenericProfile("users", id));
  }
  render() {
    const {
     dimensions,
     subscribers,
     authid
    } = this.props;
    
    console.log(this.props.subscribers)
    return (
      <div style={{ height: "100%", position: "relative" }}>
        
        <ElementContainer isMobile={dimensions.window_dimensions.isMobile}>
          <TopContainer isMobile={dimensions.window_dimensions.isMobile}>
            <Dialog isMobile={dimensions.window_dimensions.isMobile} dialog_height={425} dialog_width={500}>
              <SettingsHeader   isMobile={dimensions.window_dimensions.isMobile}>
                <SettingsTitle isMobile={dimensions.window_dimensions.isMobile}>
                  Subscribers
                </SettingsTitle>

                <CloseButtonNarrow
                  isMobile={dimensions.window_dimensions.isMobile}
                  onClick={() => {
                    this.handleCloseDialog();
                  }}>
                  {" "}
                  <IconButton>
                    <CloseIcon sx={{ fontSize: "22px" }} />
                  </IconButton>
                </CloseButtonNarrow>
              </SettingsHeader>
              <SettingsColumns isMobile={dimensions.window_dimensions.isMobile}>
               

                <ColumnCenter directUI={this.state.directUI} isMobile={dimensions.window_dimensions.isMobile}>
                <SubContainer isMobile={
                                    dimensions.window_dimensions.isMobile
                                  }>
             
             {
                                      subscribers.loading ? (
                                        <div
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}>
                                          <CircularProgress />
                                        </div>
                                      ) : (
                                        subscribers.results.map(
                                          (data, index) => {
                                            return ( <div key={index}><SubscriptionCard key={index}>
                                              <StyledLink
                                              onClick={() => {
                                                this.resetLink(
                                                
                                                );
                                              }}
                                                style={{
                                                  textDecoration: "none",
                                                }}
                                                to={"/" + data.username + "/"}>
                                              
                                                    <CircularImage
                                                        style={{
                                                          backgroundImage:
                                                            "url(" +
                                                            imgUrl(
                                                              data.avatar
                                                            ) +
                                                            ")",
                                                        }}
                                                        alt={`avatar for ${data.username}`}
                                                    />
               
                                              </StyledLink>
                                             
                                             <SubName title={data.fullname || data.username}>
                                                <StyledLink
                                                onClick={() => {
                                                  this.resetLink(
                                                  
                                                  );
                                                }}
                                                  style={{
                                                    textDecoration: "none",
                                                  
                                                  }}
                                                  to={"/" + data.username + "/"}>
                                                
                                                  {data.fullname || data.username}
                                                 
                                                </StyledLink>
                                                </SubName>
                                                {authid !== data.id ? data.subscribed ? (<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px",
                                                  
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.unSubscribe(
                                                      "users",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Unsubscribe
                                                </Button>):(<Button
                                                  disableElevation
                                                  // variant="contained"
                                                  color="primary"
                                                  style={{
                                                    fontSize: "14px",
                                                    textTransform: "none",
                                                    marginLeft: "auto",
                                                    marginTop: "auto",
                                                    marginBottom: "auto",
                                                    marginRight: "32px"
                                                  }}
                                                  size="small"
                                                  onClick={() => {
                                                    this.Subscribe(
                                                      "users",
                                                      data.id
                                                    );
                                                  }}
                                                  startIcon={
                                                    <StarOutlineIcon
                                                      style={{
                                                        color: "#383838",
                                                      }}
                                                    />
                                                  }>
                                                  Subscribe
                                                </Button>):("")}
                                               
                                                
                                            
                                            </SubscriptionCard>
                                            {index === subscribers.results.length - 1 && subscribers.next ? ( 
                                              <Button
                                                disableElevation
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                  this.loadMore(subscribers.next, "users");
                                                }}
                                              >
                                                Load More Subscriptions...
                                              </Button>
                                            ):("") }
                                            </div>
                                            )
                                            
                                          }
                                        )
                                      )
                                  }
                                  </SubContainer>
                </ColumnCenter>
              </SettingsColumns>
            </Dialog>
          </TopContainer>
        </ElementContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
 
  dimensions: state.dimensions,
  subscribers: state.profile.subscribers,
  authid: state.auth.user.user_id
  
});
export default connect(mapStateToProps)(withRouter(ProfilePageSubscribers));
