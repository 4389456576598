import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  getOrgData,
  //   addMember,
  //   removeMember,
  //   loadMoreOrgGroups,
  //   followGroup,
  //   unfollowGroup,
  //   leaveGroup,
  getOrgGroups,
  getOrgProjects
} from "../actions/orgActions";
import OrgEdit from "./OrgEdit";
// import OrgInvite from "./OrgInvite";
import OrgUserTable from "./OrgUserTable";
import theme from "../config/theme";
import ProjectThumb from "./thumbs/ProjectThumb";
import PersonThumb from "./thumbs/PersonThumb";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { setGroupSettings } from "../actions/uiActions";
import Button from "@mui/material/Button";
import Stack from "@mui/system/Stack";
import Divider from '@mui/material/Divider';
import PeopleIcon from "@mui/icons-material/People";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import monthYear from "../utils/monthYear";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import FirstUseBanner from "./FirstUseBanner";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import GroupThumb from "./thumbs/GroupThumb";
import { setMenu } from "../actions/uiActions";
import { api_endpoint } from "../config";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
const BottomPanel = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};

  width: 100%;
`;
const LeftInfoBar = styled.div`
  min-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  max-width: ${(props) => (props.isMobile ? "calc(100% - 50px)" : "260px")};
  height: 25%;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  background-color: sx={{color: theme.palette.black.dark}};
  margin-left: ${(props) => (props.isMobile ? "25px" : "16px")};
  border-radius: 0px;
  margin-top:22px;
  margin-bottom: ${(props) => (props.isMobile ? "15px" : "15px")};
  z-index: 100;
  padding-bottom: 20px;
`;
const Tablecontainer = styled.div`
  width: ${(props) => (props.isMobile ? "calc(100% - 80px)" : "calc(100% - 80px)")};
  height: 25%;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  background-color: #fff;
 
  border-radius: 4px;
  margin-left: 40px;
  margin-right: 30px;
  margin-top: 25px;
 
  margin-bottom: ${(props) => (props.isMobile ? "50px" : "15px")};
  z-index: 100;
  padding-bottom: 20px;
`;
const InfoTitleContainer = styled.div`
  display: flex;

  margin-left: 10px;
  margin-top: 10px;
`;
const InfoTitleLogo = styled.div`
  display: flex;
  width: 50%;
  margin-left: 0px;
  margin-top: 10px;
`;
const InfoTitleAvatar = styled.div`
  min-width: 104px;
  height: 104px;
  border-radius: 52px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;

  // background-size: cover;
  background-color: #ccc;
`;
const InfoTitleRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-left: 14px;
  margin-right: 30px;
`;
const OrgName = styled.div`
  display: flex;
  width: 100%;
 font-family: 'Merriweather';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 120%;
margin-top: 20px;
 
  color: #111;
  overflow-wrap: anywhere;
`;
const InfoTitleName = styled.div`
  display: flex;
  width: 100%;
  font-family: "Merriweather", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 121.5%;
  margin-bottom: auto;
  margin-top: auto;
  overflow-wrap: anywhere;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  margin-left: 18px;
  margin-right: 18px;
  margin-top: 28px;
`;
const InfoHeader = styled.div`
  display: flex;
  // width: 100%;
  font-family: "Roboto", serif;
  font-size: 18px;
  font-weight: 700;
  // margin-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? "#111" : "#c3c3c3")};
  &:hover {
    color: #3d3d3d;
  }
`;
const InfoCommand = styled.div`
  display: flex;
  width: 100%;
  font-size: 25px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandProject = styled.div`
  display: flex;
  width: 100%;
  font-size: 18px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;
const InfoCommandTextProject = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #111;
  margin-top: 0px;
  margin-left: 10px;
`;
const InfoCommandNumber = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 700;
  color: #111;
  margin-top: 0px;
  // margin-left: 10px;
`;
const InfoCommandText = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  color: #2196f3;
  margin-top: 4px;
  margin-left: 10px;
`;
const InfoMember = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
const InfoAboutText = styled.div`
  display: flex;
  width: 100%;
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 161.5%;
  color: #6d6d6d;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
const InfoEst = styled.div`
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100%;
  color: #757575;
`;
const InfoExpandContainer = styled.div`
  display: flex;
`;
const InfoCollapseContainer = styled.div`
  display: flex;
  margin-top: 20px;
`;
const InfoExpand = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

const StyledHeader = styled.div`
  height: 288px;
  background: url("${(props) => props.src}") #ccc;
  background-position: center;
  background-size: cover;
  display: flex;
  position: relative;
  padding-top: 50px;
`;

const TagLine = styled.div`
 margin: auto;
 min- width: 60%;
 font-family: 'Merriweather';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 120%;
/* or 58px */
padding: 32px;
display: flex;
align-items: center;
text-align: center;

/* White */
background: rgba(1, 1, 1, .7);
color: #FFFFFF;

`;
const RightButtons = styled.div`
  margin-left: auto;
  margin-top: -10px;
  margin-right: 10px;
`;
const Gradient = styled.div`
  text-align: bottom;
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;

  // border-radius: 4px;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.5));
  transition: opacity 300ms ease-in-out 0s;
  z-index: 9;
`;
const Projectcontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "26px")};
  margin-top: ${(props) => (props.isMobile ? "10px" : "10px")};
  flex-wrap: wrap;
  width: ${(props) => (props.isMobile ? "302px" : "auto")};
  padding-bottom: 50px;
`;
// const EmptyInstruction2 = styled.div`
//   font-family: Roboto, sans-serif;
//   font-weight: 700;
//   text-align: center;
//   color: #ccc;
//   font-size: 20px;
//   line-height: 200%;
//   margin-left: auto;
//   margin-right: auto;
// `;
const RightSection = styled.div`
  margin-top: ${(props) => (props.isMobile ? "0px" : "24px")};
  width: 100%;
  z-index: 10;
`;
const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const PageTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#757575" : "#757575")};
  font-family: "Roboto", serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 40px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "40px")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "30px")};
  display: flex;
`;
const BaseTitle = styled.div`
  color: ${(props) =>
    props.isMobile ? "#757575" : "#757575"};
  font-weight: 400;
`;
const SubTitle = styled.div`
  color: ${(props) => (props.isMobile ? "#111" : "#111")};
  cursor: pointer;
`;
const InviteButton = styled.div`
 
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-left: 40px;
  margin-left: ${(props) => (props.isMobile ? "auto" : "auto")};
  margin-right: ${(props) => (props.isMobile ? "auto" : "40px")};
  display: flex;
`;

const Aboutcontainer = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
  line-height: 27px;
  width: 100%;
`;
const AboutImage = styled.div`
 margin-top: 20px;
 
filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.3));
 width: 100%;
 border: 5px solid #fff;
  
`;
const AboutColumns = styled.div`
  
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  width: 100%;
  
`;
const AboutLine = styled.div`
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 27px;
/* or 193% */


/* Dark-Gray */

color: #383838;
  margin-bottom: 20px;
  
`;
const AboutHeader = styled.div`
font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
  margin-bottom: 0px;
  
`;
const ProjectSpacer = styled.div`
  margin: 10px;
`;

const StyledElementPopup = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.87);
  position: fixed;

  // pointer-events: none;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  vertical-align: middle;
  display: flex;
`;
const GroupNavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const GroupNavBar = styled.div`
  height: 60px;
  // background-color: #ccc;
  display: flex;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #b6b6b6;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;
const GroupNavItem = styled.div`
  margin-top: auto;
  text-transform: uppercase;
  // margin-right: 20px;
  color: ${(props) => (props.sel ? "#111" : "#b6b6b6")};
  font-weight: ${(props) => (props.sel ? "600" : "400")};
  cursor: pointer;
`;
const Dash = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #b6b6b6;
  margin-top: auto;
  margin-left: 14px;
  margin-right: 14px;
`;
const TitleDash = styled.div`
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: ${(props) => (props.isMobile ? "#757575" : "#fff")};

  margin-left: 8px;
  margin-right: 8px;
`;
const ElementPanel = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
const MoreButton = styled.div`
  width: 100%;
  display: flex;
  margin-left: 10px;
  margin-top: 20px;
`;
// const ElementContainer = styled.div`
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   display: flex;
//   flex-direction: column;
//   //   width: 700px;
//   margin-bottom: 10px;
//   margin-top: ${(props) => (props.isMobile ? "-50px" : "auto")};
//   margin-left: ${(props) => (props.isMobile ? "0px" : "auto")};
//   margin-right: ${(props) => (props.isMobile ? "0px" : "auto")};
//   min-height: ${(props) => (props.isMobile ? "calc(100% + 50px)" : "auto")};
//   background-color: ${(props) => (props.isMobile ? "#f2f2f2" : "auto")};
// `;
// const TopContainer = styled.div`
//   // max-width: 900px;
//   width: ${(props) => (props.isMobile ? "100%" : "auto")};
//   height: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   // margin-left: 0;
//   // margin-right: 0;
//   display: flex;
//   flex-direction: row;
// `;

const ElementCard = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "400px")};
  // max-width: 300px;
  height: calc(100% - 30px);
  background-color: #fff;
  padding: 20px;
  box-shadow: ${(props) =>
    props.isMobile ? "none" : "1px 1px 5px 0px rgba(0, 0, 0, 0.18)"};
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
`;
const SettingsHeader = styled.div`
  display: flex;
`;
const ConfirmationDescription = styled.div`
  display: flex;
`;
const ButtonGroup = styled.div`
  display: flex;
`;
// const SettingsColumns = styled.div`
//   display: flex;
// `;
const CloseButtonNarrow = styled.div`
  font-size: 30px;
  margin-top: 5px;
  margin-left: auto;
  margin-right: ${(props) => (props.isMobile ? "0px" : "10px")};
  color: #111;
  min-width: 20px;
  max-height: 20px;
  cursor: pointer;
`;
const SettingsTitle = styled.div`
  margin-top: ${(props) => (props.isMobile ? "10px" : "10px")};
  font-size: 18px;
  margin-right: 20px;

  margin-bottom: ${(props) => (props.isMobile ? "10px" : "20px")};
  // margin-left: ${(props) => (props.isMobile ? "0px" : "20px")};
`;
const StyledButtonLeft = styled.div`
  
  
    padding-right: 30px;
   
    margin-top: 20px;
  
   
    
}
`;
const StyledButtonRight = styled.div`
    
  
    
    padding-left: 30px;
    margin-top: 20px;
    margin-left: auto;
   
    
}
`;
const MenuPanelNarrow = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  z-index: 100000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  width: 100%;
`;
const SectionLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 10px;
  color: #757575;
  text-align: left;
`;
const MenuLine = styled.div`
  height: 45px;
  // border-bottom: 1px solid #f2f2f2;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:hover {
    @media (pointer: fine) {
      background-color: #efefef;
    }
  }
`;
const MenuIcon = styled.div`
  width: 35px;
  font-size: 20px;
  color: #111;
  margin-top: 13px;
  margin-left: 30px;
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 700;
`;
const MenuLabel = styled.div`
  font-family: Roboto, sans-serif;  
  font-size: 16px;
  font-weight 400;
  margin-top: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #212121;
`;
// const MenuLabelNoIcon = styled.div`
//   font-family: Roboto, sans-serif;
//   font-size: 16px;
//   font-weight 400;
//   margin-top: 13px;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
//   color: #212121;
//   margin-left: 30px;
// `;
const ElementCardMenu = styled.div`
  width: 300px;
  min-height: 200px;
  //   min-height: ${(props) =>
    props.cardheight ? props.cardheight : "300"}px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;
const EmptyProject = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const LinkBadge = styled.div`
 
  // height: 10px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
 
  
 
  display: flex;
  z-index: 2;
  background: #008EFF;
  border-radius: 16px;
  color: #fff;
`;

const LinkLabel = styled.div`
  margin-left: 5px;
  margin-top: 5px;
  margin-right: 5px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const EmptyContainer = styled.div`
  width: 100%;
  margin-top: auto;
`;
const EmptyTitle = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  text-align: center;
  padding: 20px;
`;
const EmptyInstruction = styled.div`
  font-family: Merriweather;
  font-style: italic;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  color: #000000;
`;
const EmptyIllustration = styled.div`
  position: absolute;
  left: 0;
  bottom: 100px;
  width: 100%;
  margin-top: auto;
  margin-bottom: ${(props) => (props.isMobile ? "auto" : "100px")};
  height: ${(props) => (props.isMobile ? "150px" : "300px")};
  background-image: ${(props) =>
    props.isMobile ? "none" : "url('/lamp.png')"};
  background-position: right bottom; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  z-index: 1;
`;
const OrgLogo = styled.img`
  width: 100%;
  height: auto;
  // background: url(${(props) => props.src});
  // background-size: cover;
  // background-position: center;
  // border: 2px solid #fff;
  // box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.18);
`
const MiddleNav = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-left: 40px;
  padding-right: 20px;
  box-sizing: border-box;
`

class OrganizationPage extends Component {
  memberListCutoff = 3;
  constructor() {
    super();
    this.state = {
      showinvite: false,
      showEdit: false,
      showusercard: false,
      inviteusername: "",
      isOwner: true,
      selectedProject: true,
      project_state: "ALL",
      selectedMember: false,
      selectedDiscussion: false,
      selectedAbout: false,
      selected: 1,
      showpopupedit: false,
      initial: true,
      collapsed: false,
      showLeaveConfirmation: false,
      showDeleteConfirmation: true,
      showstatepopup: false,

    };
    this.handleCloseInvite = this.handleCloseInvite.bind(this);
  }
  loadMoreProjects = (link) => {
    let state = false;
    if (this.state.project_state !== "ALL") {
      state = this.state.project_state;
    }

    // this.props.dispatch(loadMoreGroupProjects(link, state));
  };
  handleGetProjects(project_state) {
    let state = false;
    if (project_state !== "ALL") {
      state = project_state;
    }

    // this.props.dispatch(getGroupProjects(this.props.group.id, state));
  }
  handleShowMenu(type) {
    document.body.style.overflow = "hidden";
    // this.props.dispatch(setGroupSettings(true, type));
  }
  handleLeaveGroup(type) {
    // document.body.style.overflow = "hidden";
    // this.props.dispatch(leaveGroup(this.props.group.id));
  }
  handleShowInvite() {
    // this.setState({ showInvite: true });
    this.props.dispatch(setMenu(true, 12));
  }
  handleShowSettings() {
    this.setState({ showEdit: true });
  }
  handleCloseInvite() {
    this.setState({ showInvite: false });
    this.setState({ showEdit: false });
  }
  handleShowConfirmation() {
    document.body.style.overflow = "hidden";
    this.setState({ showLeaveConfirmation: true });
  }


  handleshowuser() {
    if (this.state.showusercard === false) {
      this.setState({ showusercard: true });
    } else {
      this.setState({ showusercard: false });
    }
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#f2f2f2";
    const orgid = window.location.pathname.split("/")[2];

    this.props.dispatch(getOrgData(orgid));
    this.props.dispatch(getOrgGroups(orgid));
    this.props.dispatch(getOrgProjects(orgid));
  }
  componentDidUpdate() {
    if (
      this.props.isMobile &&
      this.state.collapsed === false &&
      this.state.initial === true
    ) {
      this.setState({ collapsed: true });
      this.setState({ initial: false });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  doNothing(e) {
    e.stopPropagation();
  }
  followGroup() {
    const { group } = this.props;
    // this.props.dispatch(followGroup(group.id));
  }
  unfollowGroup() {
    const { group } = this.props;
    // this.props.dispatch(unfollowGroup(group.id));
  }
  handleClosePopup() {
    //console.log("close");
    document.body.style.overflow = "auto";
    // this.props.dispatch(setGroupSettings(false));
    this.setState({ showLeaveConfirmation: false });
    this.setState({ showDeleteConfirmation: false });
  }
  handleprojectState(state) {
    this.handleGetProjects(state);
    this.setState({ project_state: state });
    this.setState({ selected: 1 });
  }
  handleExpandInfo() {
    this.setState({ collapsed: false });
  }
  handleCollapseInfo() {
    this.setState({ collapsed: true });
  }
  changeNav = (tab) => {
    this.setState({ selected: tab });

  };
  handleshowStatePopup() {
    document.body.style.overflow = "hidden";
    this.setState({ showstatepopup: true });
  }
  removeUser(id) {
    const { group } = this.props;
    let updatemembers = [];
    group.members.forEach((member) => {
      if (member.id !== id) {
        updatemembers.push(member.id);
      }
    });
    // updatemembers.push("h1yQiy1Z_5FF7Tg0lHQpjfPyDyjOxDvtwT")

    //console.log("add");
    // this.props.dispatch(removeMember(group.id, updatemembers, group.name));
    this.setState({ showinvite: false });
    this.setState({ inviteusername: "" });
  }
  handleCloseMenu() {
    document.body.style.overflow = "auto";
    this.setState({ showstatepopup: false });
  }
  handleShowNewGroup() {
    this.props.dispatch(setMenu(true, 4, this.props.org.id));
  }
  keyPress(e) {
    const { group } = this.props;
    let updatemembers = [];
    group.members.forEach((member) => {
      updatemembers.push(member.id);
    });
    // updatemembers.push("h1yQiy1Z_5FF7Tg0lHQpjfPyDyjOxDvtwT")
    if (e.keyCode === 13) {
      //console.log("add");
      //   this.props.dispatch(
      //     addMember(
      //       group.id,
      //       this.state.inviteusername,
      //       updatemembers,
      //       group.name
      //     )
      //   );
      this.setState({ showinvite: false });
      this.setState({ inviteusername: "" });
    }
  }
  render() {
    const { org, org_loading, groups, showGroupSettings, isMobile } =
      this.props;

    let NoContent = false;
    // let NoFilterContent = false;
    //console.log(projects.no_results);

    let emptyText = "";
    let emptyText2 = "";
    let emptyText3 = "";
    if (groups.no_results !== 0) {
      NoContent = true;
      if (groups.no_results === 1) {
        emptyText = "Oh, that empty feeling!";
        emptyText2 =
          "It looks like no projects are being shared to this group.";
        emptyText3 =
          "Group members can add projects by selecting this group in the settings of each project.";
      }
      if (groups.no_results === 2) {
        emptyText = "There are no projects in this filter set";
        emptyText2 = "";
        emptyText3 = "";
      }
    }
    let imagesource = "../../default_group_header.jpg";

    if (org.image) {
      imagesource = api_endpoint + "/media/" + org.image.id + "/file?size=2048"

    }
    let logosource = "";

    if (org.logo) {
      logosource = api_endpoint + "/media/" + org.logo.id + "/file?size=original"

    }
    let coversource = "";

    if (org.cover) {
      coversource = api_endpoint + "/media/" + org.cover.id + "/file?size=1024"

    }
    if (org_loading === false) {
      const established = monthYear(org.created_at);
      let subtitle = "In Progress";

      switch (this.state.project_state) {
        case "ALL":
          subtitle = "All states";
          break;
        case "IN":
          subtitle = "In Progress";
          break;
        case "TO":
          subtitle = "Planned";
          break;
        case "CO":
          subtitle = "Completed";
          break;
        default:
        // code block
      }
      // let grouptype = "";
      // switch (group.group_type) {
      //   case "CO":
      //     grouptype = "Collaboration Group";
      //     break;
      //   case "PU":
      //     grouptype = "Publication Group";
      //     break;
      //   case "FE":
      //     grouptype = "Feedback Group";
      //     break;
      //   default:
      //     break;
      // }
      return (
        <div style={{ height: "100%" }}>

          {this.state.showstatepopup ? (
            <div>
              <StyledElementPopup
                onClick={() => {
                  this.handleCloseMenu();
                }}
              >
                <MenuPanelNarrow
                  onClick={() => {
                    this.handleCloseMenu();
                  }}
                >
                  {/* <ElementCardMenu>
                    <SectionLabel>Select a Project State</SectionLabel>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("ALL");
                      }}
                    >
                      <MenuIcon></MenuIcon>
                      <MenuLabel>Show All</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("IN");
                      }}
                    >
                      <MenuIcon>{group.total_inprogress}</MenuIcon>
                      <MenuLabel>In Progress</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("TO");
                      }}
                    >
                      <MenuIcon>{group.total_planned}</MenuIcon>
                      <MenuLabel>Planned</MenuLabel>
                    </MenuLine>
                    <MenuLine
                      onClick={() => {
                        this.handleprojectState("CO");
                      }}
                    >
                      <MenuIcon>{group.total_completed}</MenuIcon>
                      <MenuLabel>Completed</MenuLabel>
                    </MenuLine>
                  </ElementCardMenu> */}
                </MenuPanelNarrow>
              </StyledElementPopup>
            </div>
          ) : (
            ""
          )}
          {this.state.showLeaveConfirmation ? (
            <StyledElementPopup
              onMouseDown={() => {
                this.handleClosePopup();
              }}
            >
              <ElementPanel
                onMouseDown={(e) => {
                  this.doNothing(e);
                }}
              >
                <ElementCard isMobile={isMobile}>
                  <SettingsHeader>
                    <SettingsTitle isMobile={isMobile}>
                      Do you really want to leave this group?
                    </SettingsTitle>
                    <CloseButtonNarrow
                      isMobile={isMobile}
                      onClick={() => {
                        this.handleClosePopup();
                      }}
                    >
                      {" "}
                      <CloseRoundedIcon fontSize="inherit" />
                    </CloseButtonNarrow>
                  </SettingsHeader>
                  <ConfirmationDescription>
                    You will only be able to join this group again if an admin
                    reinvites you.
                  </ConfirmationDescription>
                  <ButtonGroup>
                    <StyledButtonLeft>
                      <Button
                        onClick={() => {
                          this.handleClosePopup();
                        }}
                        disableElevation
                        variant="contained"
                        color="primary"
                        style={{ minWidth: "150px" }}
                      >
                        No
                      </Button>
                    </StyledButtonLeft>
                    <StyledButtonRight>
                      <Button
                        onClick={() => {
                          this.handleLeaveGroup();
                        }}
                        disableElevation
                        variant="contained"
                        color="secondary"
                        style={{ minWidth: "150px" }}
                      >
                        Yes, Leave Group
                      </Button>
                    </StyledButtonRight>
                  </ButtonGroup>
                </ElementCard>
              </ElementPanel>
            </StyledElementPopup>
          ) : (
            ""
          )}
          {/* {this.state.showInvite ? (
            <StyledElementPopup
              onMouseDown={() => {
                this.handleClosePopup();
              }}
            >
              <ElementPanel
                onMouseDown={(e) => {
                  this.doNothing(e);
                }}
              >
                <OrgInvite 
                orgid={org.id}
                handleCloseInvite={this.handleCloseInvite} />
              </ElementPanel>
            </StyledElementPopup>
          ) : (
            ""
          )} */}
          {this.state.showEdit ? (
            <StyledElementPopup
              onMouseDown={() => {
                this.handleClosePopup();
              }}
            >
              <ElementPanel
                onMouseDown={(e) => {
                  this.doNothing(e);
                }}
              >
                <OrgEdit
                  orgid={org.id}
                  handleCloseInvite={this.handleCloseInvite} />
              </ElementPanel>
            </StyledElementPopup>
          ) : (
            ""
          )}
          <StyledHeader src={imagesource}>
            {org.tag_line ? (
              <TagLine>{org.tag_line}</TagLine>
            ) : ""}
          </StyledHeader>
          <BottomPanel isMobile={isMobile}>
            <LeftInfoBar isMobile={isMobile}>
              <InfoTitleContainer>
                <img style={{ width: "100%" }} src={logosource} />
              </InfoTitleContainer>
              <InfoTitleContainer>
                <OrgName>{org.name}</OrgName>
              </InfoTitleContainer>
              {coversource ? (<InfoTitleContainer><AboutImage isMobile={isMobile}><img style={{ width: "100%" }} src={coversource} /></AboutImage>  </InfoTitleContainer>) : ("")}
              {org.link_url ? (<InfoTitleContainer>

                <a
                  style={{ color: "#111", textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                  href={org.link_url}
                >
                  <InfoCommand

                  >
                    <InsertLinkIcon fontSize="inherit" />{" "}
                    <InfoCommandText>{org.link_name ? (org.link_name) : (org.link_url)}</InfoCommandText>
                  </InfoCommand>
                </a>

              </InfoTitleContainer>) : ("")}
              <InfoTitleContainer>

                <Aboutcontainer isMobile={isMobile}>  {org.description.split("\n").map((line, index) => (<div key={"line" + index}>
                  {line.substring(0, 3) === "## " ? (<AboutHeader>{line.substring(3, line.length)}</AboutHeader>) : (<AboutLine>{line}</AboutLine>)}


                </div>))}</Aboutcontainer>
              </InfoTitleContainer>

              {org.viewer_is_admin ? (
                <InfoContainer>
                  {/* <InfoCommand
                        onClick={() => {
                          this.changeNav(4);
                        }}
                      >
                        <PeopleIcon fontSize="inherit" />{" "}
                        <InfoCommandText>Manage Members</InfoCommandText>
                      </InfoCommand> */}
                  <InfoCommand
                    onClick={() => {
                      this.handleShowSettings();
                    }}
                  >
                    <SettingsIcon fontSize="inherit" />{" "}
                    <InfoCommandText>Organization Settings</InfoCommandText>
                  </InfoCommand>
                  <Button
                    onClick={() => {
                      this.handleShowInvite()
                    }}
                    style={{ width: "136px", marginTop: "44px" }}
                    disableElevation
                    variant="contained"
                    color="primary"
                  >
                    INVITE
                  </Button>
                </InfoContainer>

              ) : (
                ""
              )}

              {/* <InfoContainer>
                    <InfoEst>Formed {established}</InfoEst>
                  </InfoContainer> */}
              {isMobile ? (
                <InfoCollapseContainer>
                  <InfoExpand>
                    <IconButton
                      title="Settings"
                      size="small"
                      onClick={() => {
                        this.handleCollapseInfo();
                      }}
                    >
                      <ExpandLessOutlinedIcon fontSize="20px" />{" "}
                    </IconButton>
                  </InfoExpand>
                </InfoCollapseContainer>
              ) : (
                ""
              )}
            </LeftInfoBar>
            <RightSection isMobile={isMobile}>
              <MiddleNav>
                <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />} sx={{ width: "100%", flexShrink: 1 }}>
                  <InfoHeader selected={this.state.selected === 1} onClick={() => { this.changeNav(1) }}>Groups</InfoHeader>
                  <InfoHeader selected={this.state.selected === 2} onClick={() => { this.changeNav(2) }}>Projects</InfoHeader>
                </Stack>
                {org.viewer_is_admin && this.state.selected === 1 ? (
                  <Button
                    style={{ width: "180px", flexGrow: 1 }}
                    onClick={() => {
                      this.handleShowNewGroup();
                    }}
                    disableElevation
                    variant="contained"
                    // color="primary"
                    sx={{ bgcolor: theme.palette.primary.main }}
                  // sx={{fontSize: theme.components.MuiButton.variants.style.bottombutton}}
                  >
                    NEW GROUP
                  </Button>
                ) : ""}
                {org.viewer_status === "AC" && this.state.selected === 2 ? (
                  <Button
                    style={{ width: "180px", flexGrow: 1 }}
                    onClick={() => {
                      this.handleShowNewProject();
                    }}
                    disableElevation
                    variant="contained"
                    // color="primary"
                    sx={{ bgcolor: theme.palette.primary.main }}
                  // sx={{fontSize: theme.components.MuiButton.variants.style.bottombutton}}
                  >
                    NEW PROJECT
                  </Button>
                ) : ""}
              </MiddleNav>

              {this.state.selected === 1 ? (
                <SectionContainer>
                  <PageTitle isMobile={isMobile}>
                    {/* <BaseTitle isMobile={isMobile}>View:</BaseTitle>
                    <Dash isMobile={isMobile}></Dash>
                    <SubTitle
                      isMobile={isMobile}
                      onClick={(e) => {
                        this.handleshowStatePopup();
                      }}
                    >
                      {subtitle}
                    </SubTitle>
                    <Dash isMobile={isMobile}>|</Dash> */}

                    {!isMobile ? (<RightButtons>
                      {/* <Button
                    style={{ width: "136px" }}
                    onClick={() => {this.handleNewProject()}}
                    disableElevation
                    variant="contained"
                    color="primary">
                    NEW PROJECT2
                  </Button> */}

                      {/* {this.state.isOwner ? (
                        <RightButtons>
                          {}
                        </RightButtons>
                      ) : (
                        ""
                      )} */}


                    </RightButtons>) : ("")}
                  </PageTitle>

                  <Projectcontainer isMobile={isMobile}>
                    {NoContent ? (
                      <EmptyProject>
                        <EmptyContainer>
                          <EmptyTitle>{emptyText}</EmptyTitle>
                          <EmptyInstruction>
                            {emptyText2}
                            <br />

                            {emptyText3}
                          </EmptyInstruction>{" "}
                        </EmptyContainer>

                      </EmptyProject>
                    ) : (
                      ""
                    )}

                    {groups.allIDS.map((group, index) => (
                      <ProjectSpacer key={"grpi" + index}>
                        <GroupThumb
                          created_at={groups.byID[group].created_at}
                          total_members={groups.byID[group].total_members}
                          total_projects={
                            groups.byID[group].total_inprogress +
                            groups.byID[group].total_planned +
                            groups.byID[group].total_completed
                          }
                          creator={groups.byID[group].creator}
                          src={
                            groups.byID[group].image
                              ? groups.byID[group].image
                              : ""
                          }
                          description={groups.byID[group].description}
                          name={groups.byID[group].name}
                          id={group}
                          link={true}
                          viewer_status={groups.byID[group].viewer_status}
                        />
                      </ProjectSpacer>
                    ))}
                    {groups.next ? (
                      <MoreButton>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.loadMoreGroups(groups.next);
                          }}
                        >
                          Load More Groups...
                        </Button>
                      </MoreButton>
                    ) : (
                      ""
                    )}
                  </Projectcontainer>
                </SectionContainer>
              ) : (
                ""
              )}
              {this.state.selected === 2 ? (
                <SectionContainer>
                  {/* <PageTitle isMobile={isMobile}>
                    <BaseTitle isMobile={isMobile}>Projects</BaseTitle>
                  </PageTitle> */}
                  <Projectcontainer isMobile={isMobile}>
                    {NoContent ? (
                      <EmptyProject>
                        <EmptyContainer>
                          <EmptyTitle>{emptyText}</EmptyTitle>
                          <EmptyInstruction>
                            {emptyText2}
                            <br />

                            {emptyText3}
                          </EmptyInstruction>{" "}
                        </EmptyContainer>

                      </EmptyProject>
                    ) : (
                      ""
                    )}

                    {org.projects.allIDS.map((pid, index) => {
                      const project = org.projects.byID[pid];
                      console.log(project)
                      return (
                        <ProjectSpacer key={index}>
                          <ProjectThumb {...project} count={project.total_elements} link={true} />
                        </ProjectSpacer>
                      );
                    })}
                    {org.projects.next ? (
                      <MoreButton>
                        <Button
                          disableElevation
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.loadMoreProjects(org.projects.next);
                          }}
                        >
                          Load More Projects...
                        </Button>
                      </MoreButton>
                    ) : (
                      ""
                    )}
                  </Projectcontainer>
                </SectionContainer>
              ) : (
                ""
              )}
              {this.state.selected === 3 ? (
                <SectionContainer>
                  <PageTitle isMobile={isMobile}>
                    <BaseTitle isMobile={isMobile}>About</BaseTitle>
                  </PageTitle>
                  <AboutColumns isMobile={isMobile}>




                  </AboutColumns>
                </SectionContainer>
              ) : (
                ""
              )}
              {this.state.selected === 4 ? (
                <SectionContainer>
                  <PageTitle isMobile={isMobile}>
                    <BaseTitle isMobile={isMobile}>Manage Group Members</BaseTitle>
                  </PageTitle>
                  <InviteButton>
                    <Button
                      onClick={() => {
                        this.handleShowInvite();
                      }}
                      style={{ width: "236px", marginTop: "30px" }}
                      disableElevation
                      variant="contained"
                      color="primary"
                    >
                      Add Organization member
                    </Button>
                  </InviteButton>
                  <Tablecontainer isMobile={isMobile}><OrgUserTable org={org} /></Tablecontainer>
                </SectionContainer>
              ) : (
                ""
              )}
            </RightSection>
          </BottomPanel>
        </div >
      );
    } else {
      return <p>loading...</p>;
    }
  }
}

const mapStateToProps = (state) => ({
  org: state.org,
  org_loading: state.org.org_loading,
  groups: state.org.groups,
  showGroupSettings: state.ui.showGroupSettings,
  isMobile: state.dimensions.window_dimensions.isMobile,
});
export default connect(mapStateToProps)(OrganizationPage);
