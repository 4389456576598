import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import NavBar2 from "./navbar.js"
import { Ribbons } from "../marketing_pages/Ribbon.js";

import Footer from "../marketing_pages/Footer.js";
import { StyledBackGround, CenterColumn, Columns, LeftColumn } from "./styles"
import {
  ContentWrapper,
  HeaderSection,

} from "../marketing_pages/MarketingComponents";
import { site_name } from "../../config/index.js";
import setTitle from "../../utils/setTitle.js";

class Terms extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    document.body.style.margin = "0";
    document.body.style.backgroundColor = "#f2f2f2";
    window.scrollTo(0, 0);
    setTitle(`Made Terms | ${site_name}`)
  }

  render() {
    const { isMobile } = this.props;
    return (
      <ContentWrapper>
        {/* <Ribbons />
        <HeaderSection isMobile={isMobile}></HeaderSection> */}
        <StyledBackGround>

          <Columns isMobile={isMobile}>
            <LeftColumn isMobile={isMobile}>
              <NavBar2 />
            </LeftColumn>
            <CenterColumn>
              <h1>Terms of Service</h1>

              <p>
                Welcome, and thank you for your interest in Sequentially LLC,
                operating as Made (“<strong>Made</strong>,” “<strong>we</strong>,”
                or “<strong>us</strong>”) and our website at{" "}
                <a href="https://www.asitsmade.com/">www.asitsmade.com</a>,
                services at www.asitsmade.com along with our related websites,
                networks, applications, APIs, mobile applications, and other
                services provided by us (collectively, the “
                <strong>Service</strong>”). These Terms of Service are a legally
                binding contract between you and Made regarding your use of the
                Service.
              </p>
              <p>
                <strong>PLEASE READ THE FOLLOWING TERMS CAREFULLY. </strong>
              </p>
              <p>
                <strong>BY CLICKING</strong> “<strong>I ACCEPT</strong>,”{" "}
                <strong>
                  OR BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING
                  THE SERVICE
                </strong>
                , YOU AGREE THAT YOU HAVE READ AND UNDERSTOOD, AND, AS A CONDITION
                TO YOUR USE OF THE SERVICE, YOU AGREE TO BE BOUND BY, THE
                FOLLOWING TERMS AND CONDITIONS, INCLUDING MADE’S PRIVACY POLICY
                (TOGETHER, THESE “<strong>TERMS</strong>”). If you are not
                eligible, or do not agree to the Terms, then you do not have our
                permission to use the Service. YOUR USE OF THE SERVICE, AND MADE’S
                PROVISION OF THE SERVICE TO YOU, CONSTITUTES AN AGREEMENT BY MADE
                AND BY YOU TO BE BOUND BY THESE TERMS.
              </p>
              <p>
                <strong>ARBITRATION </strong>
                <strong>NOTICE. </strong>Except for certain kinds of disputes
                described in Section 16, you agree that disputes arising under
                these TERMS will be resolved by binding, individual arbitration,
                and BY ACCEPTING THESE TERMS, YOU AND MADE ARE EACH WAIVING THE
                RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
                REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO
                TO COURT to assert or defend your rights under this contract
                (except for matters that may be taken to small claims court). Your
                rights will be determined by a NEUTRAL ARBITRATOR and NOT a judge
                or jury. (See Section 16).
              </p>
              <h3>1. Service Overview</h3>

              <p>
                <strong></strong>Made provides a platform via its website to a community of
                registered users (“users” or “you”) to engage in a variety of activities,
                including to upload and display photographs (User Content”), share comments,
                opinions, and ideas, register for premium membership accounts (“Premium Accounts”).
              </p>
              <h3>2. Eligibility</h3>
              <p>
                You must be at least 13 years old to use the Service. By agreeing
                to these Terms, you represent and warrant to us that: (a) you are
                at least 13 years old; (b) you have not previously been suspended
                or removed from the Service; and (c) your registration and your
                use of the Service is in compliance with any and all applicable
                laws and regulations. If you are an entity, organization, or
                company, the individual accepting these Terms on your behalf
                represents and warrants that they have authority to bind you to
                these Terms and you agree to be bound by these Terms, and you will
                be responsible for all charges incurred on your account.
              </p>
              <h3>3. Accounts and Registration</h3>

              <p>
                You may not use as a username the name of another person or entity
                or that is not lawfully available for use, a name or trademark
                that is subject to any rights of another person or entity other
                than you without appropriate authorization, or a name that is
                otherwise offensive, vulgar, or obscene.
              </p>
              <p>
                To access the web application functionality of the Service, you
                must register for an account. When you register for an account,
                you may be required to provide us with some information about
                yourself, such as your name, email address, or other contact
                information. You agree that the information you provide to us is
                accurate and that you will keep it accurate and up-to-date at all
                times. When you register, you will be asked to provide a password.
                You are solely responsible for maintaining the confidentiality of
                your account and password, and you accept responsibility for all
                activities that occur under your account. If you believe that your
                account is no longer secure, then you must immediately notify us
                at support@asitsmade.com.
              </p>
              <h3>4. Premium Accounts</h3>

              <p>
                Made provides premium services to holders of its paid Premium
                Accounts. To register for a Premium Account, you must be at least
                18 years of age and have a valid Made account. In addition to the
                general terms and conditions provided for by these Terms, the
                following terms and conditions apply specifically to Premium
                Account holders:
              </p>
              <p>
                Made may modify, suspend, or discontinue the Services provided to
                Premium Account holders at any time at its sole discretion and
                without prior notice or providing any refund for any fees paid for
                Premium Accounts.
              </p>
              <p>
                Made reserves the right to determine
                pricing for the Service. Made will make reasonable efforts to keep
                pricing information published on the website. We
                encourage you to check our website periodically for current
                pricing information. Made may change the fees for any feature of
                the Service, including additional fees or charges, if Made gives
                you advance notice of changes before they apply. Pricing changes
                will apply on an on-going basis, but will not change the portion
                of the Service for which you have already pre-paid for the
                remainder of the term for which you have already paid. Made, at
                its sole discretion, may make promotional offers with different
                features and different pricing to any of Made’s customers. These
                promotional offers, unless made to you, will not apply to your
                offer or these Terms.
              </p>

              <p>
                You authorize Made to charge
                all sums for the orders that you make and any level of Service you
                select as described in these Terms or published by Made, to the
                payment method specified in your account. You are responsible for
                all taxes, processing fees, and foreign transaction fees
                associated with your payment for the Services. If you pay any fees
                with a credit card, Made may seek pre-authorization of your credit
                card account prior to your purchase to verify that the credit card
                is valid and has the necessary funds or credit available to cover
                your purchase. You may update your credit card information by
                entering updated credit card information in the “Plan” page in
                your Profile Settings page. Any update to your credit card may
                take up to 48 hours to take effect.
              </p>
              <p>
                The Service may include
                automatically recurring payments for periodic charges (“
                <strong>Subscription Service</strong>”).<strong> </strong>If you
                activate a Subscription Service, you authorize Made to
                periodically charge, on a going-forward basis and until
                cancellation of either the recurring payments or your account, all
                accrued sums on or before the payment due date for the accrued
                sums, or to invoice you for a set Subscription Fee (as defined
                below) in advance of your chosen subscription period. If you
                choose to be invoiced, payment is due within 30 days of your
                receipt of invoice. The “
                <strong>Subscription Billing Date</strong>” is the date when you
                purchase your first subscription to the Service.
                The subscription will continue unless and until you
                cancel your subscription or we terminate it. You must cancel your
                subscription before it renews in order to avoid billing of the
                next periodic Subscription Fee to your account.  You may
                cancel the Subscription Service by clicking on the link to
                “Change Subscription” from the Profile Settings and following the
                instructions.
              </p>



              <h3>5. Licenses</h3>
              <p>
                <strong></strong>
                <strong>5.1 Limited License.</strong> Subject to your complete and
                ongoing compliance with these Terms, Made grants you a limited,
                non-exclusive, non-transferable, non-sublicensable, revocable
                license to access and use the Service.
              </p>
              <p>
                <strong>5.2 License Restrictions.</strong> Except and solely to
                the extent such a restriction is impermissible under applicable
                law, you may not: (a) reproduce, distribute, publicly display, or
                publicly perform the Service; (b) make modifications to the
                Service; (c) reverse-engineer or otherwise attempt to use the API
                other than as intended by these Terms; or (d) interfere with or
                circumvent any feature of the Service, including any security or
                access control mechanism. If you are prohibited under applicable
                law from using the Service, you may not use it.
              </p>

              <p>
                <strong></strong>
                <strong>5.4 Feedback</strong> If you choose to provide input and
                suggestions regarding problems with or proposed modifications or
                improvements to the Service (“<strong>Feedback</strong>”), then
                you hereby grant Made an unrestricted, perpetual, irrevocable,
                non-exclusive, fully-paid, royalty-free right to exploit the
                Feedback in any manner and for any purpose, including to improve
                the Service and create other products and services.
              </p>
              <h3>6. Ownership; Proprietary&nbsp;Rights</h3>
              <p>
                <strong></strong>The Service is owned and operated by Made. The
                visual interfaces, graphics, design, compilation, information,
                data, computer code (including source code or object code),
                products, software, services, and all other elements of the
                Service (“<strong>Materials</strong>”) provided by Made are
                protected by intellectual property and other laws. All Materials
                included in the Service are the property of Made or its third
                party licensors. Except as expressly authorized by Made, you may
                not make use of the Materials. Made reserves all rights to the
                Materials not granted expressly in these Terms.
                <strong></strong>
              </p>
              <h3>7. Third Party Services and Linked&nbsp;Websites</h3>
              <p>
                <strong>7.2 Third Party Service and Linked Websites.</strong>
                Made may provide tools through the Service that enable you to
                transfer information, including User Content, to third party
                services. By using one of these tools, you agree that Made may
                transfer that information to the applicable third party service.
                Third party services are not under Made’s control, and, to the
                fullest extent permitted by law, Made is not responsible for any
                third party service’s use of your exported information. Made does
                not guarantee that the Services will maintain integrations with
                any Third-Party Platform and Made may disable provided
                integrations of the Services with any Third-Party Platform at any
                time.
              </p>
              <p>
                <strong>7.2 Third Party Software.</strong> The Service may include
                or incorporate third party software components that are generally
                available free of charge under licenses granting recipients broad
                rights to copy, modify, and distribute those components (“
                <strong>Third Party Components</strong>”). Although the Service is
                provided to you subject to these Terms, nothing in these Terms
                prevents, restricts, or is intended to prevent or restrict you
                from obtaining Third Party Components under the applicable third
                party licenses or to limit your use of Third Party Components
                under those third party licenses.
              </p>
              <h3>8. User Content</h3>
              <p>
                <strong>8.1 User Content Generally</strong>. Certain features of
                the Service may permit users to upload content to the Service,
                including images, data, text, and other types of information (“
                <strong>User Content</strong>”) and to publish User Content on the
                Service. You retain any copyright and other proprietary rights
                that you may hold in the User Content that you post to the
                Service.
              </p>
              <p>
                <strong>8.2 Limited License Grant to Made</strong>. By providing
                User Content to or via the Service, you grant Made, only for use
                in connection with providing and improving the Service, a
                worldwide, non-exclusive, royalty-free, perpetual, fully paid
                right and license (with the right to sublicense) to host, store,
                transfer, display, perform, reproduce, modify for the purpose of
                formatting for display, and distribute your User Content, in whole
                or in part, in any media formats and through any media channels
                now known or hereafter developed. Please see the Made Privacy
                Policy, as described in Section 17.3 for more information on how
                this information can be used and is protected.
              </p>
              <p>
                <strong>8.3 Limited License Grant to Other Users</strong>. By
                providing User Content to or via the Service to other users of the
                Service, including those in your organization’s account, you grant
                those users a non-exclusive license to access and use that User
                Content as permitted by these Terms and the functionality of the
                Service.
              </p>
              <p>
                <strong>8.4 User Content Representations and Warranties</strong>.
                Made disclaims any and all liability in connection with User
                Content. You are solely responsible for your User Content and the
                consequences of providing User Content via the Service. By
                providing User Content via the Service, you affirm, represent, and
                warrant that:
              </p>
              <p>
                a. you are the creator and owner of the User Content, or have the
                necessary licenses, rights, consents, and permissions to authorize
                Made and users of the Service to use and distribute your User
                Content as necessary to exercise the licenses granted by you in
                this Section, in the manner contemplated by Made, the Service, and
                these Terms;
              </p>
              <p>
                b. your User Content, and the use of your User Content as
                contemplated by these Terms, does not and will not: (i) infringe,
                violate, or misappropriate any third party right, including any
                copyright, trademark, patent, trade secret, moral right, privacy
                right, right of publicity, or any other intellectual property or
                proprietary right; (ii) slander, defame, libel, or invade the
                right of privacy, publicity or other property rights of any other
                person; or (iii) cause Made to violate any law or regulation; and
              </p>
              <p>
                c. your User Content could not be deemed by a reasonable person to
                be objectionable, profane, indecent, pornographic, harassing,
                threatening, embarrassing, hateful, or otherwise inappropriate.
              </p>
              <p>
                <strong>8.5 User Content Disclaimer</strong>. We are under no
                obligation to edit or control User Content that you or other users
                post or publish, and will not be in any way responsible or liable
                for User Content. Made may, however, at any time and without prior
                notice, screen, remove, edit, or block any User Content that in
                our sole judgment violates these Terms or is otherwise
                objectionable. You understand that when using the Service you will
                be exposed to User Content from a variety of sources and
                acknowledge that User Content may be inaccurate, offensive,
                indecent, or objectionable. You agree to waive, and do waive, any
                legal or equitable right or remedy you have or may have against
                Made with respect to User Content. If notified by a user or
                content owner that User Content allegedly does not conform to
                these Terms, we may investigate the allegation and determine in
                our sole discretion whether to remove the User Content, which we
                reserve the right to do at any time and without notice. For
                clarity, Made does not permit copyright-infringing activities on
                the Service.
              </p>
              <p>
                <strong>8.6 Monitoring Content</strong>. Made does not control and
                does not have any obligation to monitor: (a) User Content; (b) any
                content made available by third parties; or (c) the use of the
                Service by its users. You acknowledge and agree that Made reserves
                the right to, and may from time to time, monitor any and all
                information transmitted or received through the Service for
                operational and other purposes. If at any time Made chooses to
                monitor the content, Made still assumes no responsibility or
                liability for content. During monitoring, information may be
                examined, recorded, copied, and used in accordance with our
                Privacy Policy.
              </p>
              <h3>9. Copyright&nbsp;Infringement</h3>
              <p>If you believe any materials accessible on or from our Services
                infringe your copyright, you may request removal of those materials
                (or access to them) from the Services by submitting a
                written notification. Notices to Made regarding any alleged copyright
                infringement should be directed to Made via email at:
                support@asitsmade.com. In accordance with the Online Copyright
                Infringement Liability Limitation Act of the Digital Millennium
                Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice
                (the "DMCA Notice") must include the following:</p>
              <p>a. Your name, address, telephone number, and email address (if any);</p>
              <p>b. A description of the copyrighted work that you claim has been infringed;</p>
              <p>c. A description of where on the Site the material that you claim is infringing
                may be found, sufficient for Made to locate the material (e.g., the URL);</p>
              <p>d. A statement that you have a good faith belief that the use of the copyrighted work is not authorized by you, your agents, or the law and is not a fair use;</p>
              <p>e. A statement by you UNDER PENALTY OF PERJURY that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf; and</p>
              <p>f. Your electronic or physical signature.</p>
              <p>If we remove or disable access to Content in response to a notice of Infringement,
                we will make reasonable attempts to contact the user who posted the affected Content.
                If you feel that your Content is not infringing, you may provide Made with a
                counter notice in writing to the attention of “Copyright Infringement Counter
                Notification” at support@asitsmade.com. You must include in your counter notice
                sufficient information to enable Made to make a reasonable determination.
                Please note that you may be held accountable for damages (including costs and attorneys' fees)
                if you materially misrepresent that your Content is not infringing the copyrights of others.</p>
              <h3>10. Prohibited&nbsp;Conduct</h3>
              <p>BY USING THE SERVICE YOU AGREE NOT TO:</p>
              <p>
                a. use the Service for any illegal purpose or in violation of any
                local, state, national, or international law;
              </p>
              <p>
                b. violate, or encourage others to violate, any right of a third
                party, including by infringing or misappropriating any third party
                intellectual property right;
              </p>
              <p>
                c. interfere with security-related features of the Service,
                including by: (i) disabling or circumventing features that prevent
                or limit use or copying of any content; or (ii) reverse
                engineering or otherwise attempting to discover the source code of
                any portion of the Service except to the extent that the activity
                is expressly permitted by applicable law;
              </p>
              <p>
                d. interfere with the operation of the Service or any user’s
                enjoyment of the Service, including by: (i) uploading or otherwise
                disseminating any virus, adware, spyware, worm, or other malicious
                code; (ii) making any unsolicited offer or advertisement to
                another user of the Service; (iii) collecting personal information
                about another user or third party without consent; or (iv)
                interfering with or disrupting any network, equipment, or server
                connected to or used to provide the Service;
              </p>
              <p>
                e. access the Service in order to
                create products or services that compete with Made;
              </p>
              <p>
                f. perform any fraudulent activity including impersonating any
                person or entity, claiming a false affiliation, accessing any
                other Service account without permission, or falsifying your age
                or date of birth;
              </p>
              <p>
                g. sell or otherwise transfer the access granted under these Terms
                or any Materials (as defined in Section 6) or any right or ability
                to view, access, or use any Materials;
              </p>
              <p>
                h. attempt to do any of the acts described in this Section 9 or
                assist or permit any person in engaging in any of the acts
                described in this Section 9;
              </p>
              <p>
                i. Providing your password to any other person or using any other person’s username and password; or
              </p>
              <p>
                j. Harass, threaten, embarrass or cause distress or discomfort to another App participant, user, or other individual or entity.
              </p>
              <h3>11. Modification of these&nbsp;Terms</h3>
              <p>
                <strong></strong>We reserve the right to change these Terms on a
                going-forward basis at any time. If we make a material change to
                these terms, we will give you reasonable notice prior to the
                change taking effect, either by emailing the email address
                associated with your account or by messaging you through the
                Service.
              </p>

              <h3>12. Term, Termination and Modification of the&nbsp;Service</h3>
              <p>
                <strong>12.1 Term</strong>
                <strong>.</strong> These Terms are effective beginning when you
                accept the Terms or first download, install, access, or use the
                Service, and ending when terminated as described in Section 12.2.
              </p>
              <p>
                <strong>12.2 Termination.</strong> If you violate any provision of
                these Terms, your authorization to access the Service and these
                Terms automatically terminate.
              </p>
              <p>
                In addition, Made may, at its sole discretion terminate or suspend
                these Terms or your account, at any time for any or no reason with or without
                notice.
              </p>
              <p>
                You may terminate your account and these Terms at any time by
                clicking on the “Delete” button in the user profile section of the
                application and following the instructions.
              </p>

              <p>
                <strong>12.4 Modification of the Service.</strong> Made reserves
                the right to modify the Service at any time (including by limiting
                or discontinuing minor features or functionality of the Service),
                temporarily or permanently, without notice to you. Made will have
                no liability for any change to the Service. <strong></strong>
              </p>
              <p>
                <strong>12.5 Discontinuation of the Service. </strong>If you
                prepaid for the Service, and Made terminates all of the Service or
                discontinues to operate as a business, other than due to your
                violation of these Terms, Made will provide a pro-rata refund for
                the remaining portion of the pre-paid subscription and give you
                access to your data for 90 days.
              </p>
              <h3> 13.&nbsp;Indemnity</h3>
              <p>
                <strong></strong>To the fullest extent permitted by law, you are
                responsible for your use of the Service, and you will defend and
                indemnify Sequentially, operating as Made, and its officers,
                directors, employees, consultants, affiliates, subsidiaries and
                agents (together, the “<strong>Made Entities</strong>”) from and
                against every claim brought by a third party, and any related
                liability, damage, loss, and expense, including reasonable
                attorneys’ fees and costs, arising out of or connected with: (a)
                your unauthorized use of, or misuse of, the Service; (b) your
                violation of any portion of these Terms, any representation,
                warranty, or agreement referenced in these Terms, or any
                applicable law or regulation; (c) your violation of any third
                party right, including any intellectual property right or
                publicity, confidentiality, other property, or privacy right; or
                (d) any dispute or issue between you and any third party. We
                reserve the right, at our own expense, to assume the exclusive
                defense and control of any matter otherwise subject to
                indemnification by you (without limiting your indemnification
                obligations with respect to that matter), and in that case, you
                agree to cooperate with our defense of those claims. <br />
              </p>
              <h3>14. Disclaimers; Limited&nbsp;Warranty</h3>

              <p>
                <strong>14.1 Limited Warranty </strong>For the benefit of our
                paying customers, Made warrants, that our Service will
                substantially conform with the products advertised on our website{" "}
                <a href="https://www.asitsmade.com/">www.asitsmade.com</a>.
                Our sole liability (and Customer's sole and exclusive remedy) for
                any breach of this warranty will be, at no charge to Customer, for
                Made to use commercially reasonable efforts to correct the
                reported non-conformity or provide a reasonable workaround. If
                Made determines such remedy to be impracticable, either party may
                terminate the applicable Subscription Term and Customer will
                receive as its sole remedy a refund of any fees pre-paid for use
                of such Service for the terminated portion of the applicable
                Subscription Term. The limited warranty set forth in this Section
                14.1 will not apply: (i) unless Customer makes a claim within
                thirty (30) days of the date on which Customer first noticed the
                non-conformity, (ii) if the error was caused by misuse,
                unauthorized modifications or third-party hardware, software or
                services, or (iii) to use provided on a no-charge, trial or
                evaluation basis.
              </p>
              <p>
                <strong>14.2 Warranty Disclaimer </strong>EXCEPT FOR THE LIMITED
                WARRANTY PROVIDED IN SECTION 14.1, THE SERVICE AND ALL MATERIALS
                AND CONTENT AVAILABLE THROUGH THE SERVICE ARE PROVIDED “AS IS” AND
                ON AN “AS AVAILABLE” BASIS. Made DISCLAIMS ALL WARRANTIES OF ANY
                KIND, WHETHER EXPRESS OR IMPLIED, RELATING TO THE SERVICE AND ALL
                MATERIALS AND CONTENT AVAILABLE THROUGH THE SERVICE, INCLUDING:
                (A) ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT;
                AND (B) ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE, OR
                TRADE. Made DOES NOT WARRANT THAT THE SERVICE OR ANY PORTION OF
                THE SERVICE, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE
                SERVICE, WILL BE PERFORMANT, UNINTERRUPTED, SECURE, OR FREE OF
                ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND Made DOES NOT
                WARRANT THAT ANY OF THOSE ISSUES WILL BE CORRECTED.
              </p>
              <p>
                NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
                FROM THE SERVICE OR Made ENTITIES OR ANY MATERIALS OR CONTENT
                AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING
                ANY OF THE Made ENTITIES OR THE SERVICE THAT IS NOT EXPRESSLY
                STATED IN THESE TERMS. WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT
                MAY RESULT FROM THE SERVICE AND YOUR DEALING WITH ANY OTHER
                SERVICE USER. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF
                THE SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
                RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR
                COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION WITH THE
                SERVICE). <strong></strong>
              </p>
              <p>
                THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION APPLY
                TO THE FULLEST EXTENT PERMITTED BY LAW. Made does not disclaim any
                warranty or other right that Made is prohibited from disclaiming
                under applicable law.
              </p>
              <h3>15. Limitation of&nbsp;Liability</h3>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE Made
                ENTITIES BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING DAMAGES FOR LOSS OF
                PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR
                RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY TO ACCESS
                OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT ON THE SERVICE,
                WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                STATUTE, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY Made
                ENTITY HAS BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.
              </p>
              <p>
                EXCEPT AS PROVIDED IN SECTION 16.4(iii) AND TO THE FULLEST EXTENT
                PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE Made ENTITIES TO
                YOU FOR ALL CLAIMS ARISING OUT OF OR RELATING TO THE USE OF OR ANY
                INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER
                THESE TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED
                TO THE GREATER OF: (A) THE AMOUNT YOU HAVE PAID TO Made FOR ACCESS
                TO AND USE OF THE SERVICE IN THE 12 MONTHS PRIOR TO THE EVENT OR
                CIRCUMSTANCE GIVING RISE TO CLAIM; OR (B) $100.
              </p>
              <p>
                EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF
                LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS
                INTENDED TO AND DOES ALLOCATE THE RISKS BETWEEN THE PARTIES UNDER
                THESE TERMS. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS
                OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS
                SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE TERMS.
                THE LIMITATIONS IN THIS SECTION 15 WILL APPLY EVEN IF ANY LIMITED
                REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
              </p>
              <h3>16. Dispute Resolution and Arbitration&nbsp;</h3>
              <p>
                <strong>16.1 Generally</strong>. In the interest of resolving
                disputes between you and Made in the most expedient and cost
                effective manner, and except as described in Section 16.2, you and
                Made agree that every dispute arising in connection with these
                Terms will be resolved by binding arbitration. Arbitration is less
                formal than a lawsuit in court. Arbitration uses a neutral
                arbitrator instead of a judge or jury, may allow for more limited
                discovery than in court, and can be subject to very limited review
                by courts. Arbitrators can award the same damages and relief that
                a court can award. This agreement to arbitrate disputes includes
                all claims arising out of or relating to any aspect of these
                Terms, whether based in contract, tort, statute, fraud,
                misrepresentation, or any other legal theory, and regardless of
                whether a claim arises during or after the termination of these
                Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE
                TERMS, YOU AND Made ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY
                OR TO PARTICIPATE IN A CLASS ACTION.
              </p>
              <p>
                <strong>16.2 Exceptions</strong>. Despite the provisions of
                Section 16.1, nothing in these Terms will be deemed to waive,
                preclude, or otherwise limit the right of either party to: (a)
                bring an individual action in small claims court; (b) pursue an
                enforcement action through the applicable federal, state, or local
                agency if that action is available; (c) seek injunctive relief in
                a court of law in aid of arbitration; or (d) to file suit in a
                court of law to address an intellectual property infringement
                claim.
              </p>
              <p>
                <strong>16.3 Arbitrator</strong>. Any arbitration between you and
                Made will be settled under the Federal Arbitration Act and
                administered by the American Arbitration Association (“
                <strong>AAA</strong>”) under its Consumer Arbitration Rules
                (collectively, “<strong>AAA Rules</strong>”) as modified by these
                Terms. The AAA Rules and filing forms are available online at{" "}
                <a href="http://www.adr.org/">www.adr.org</a>, by calling
                the AAA at 1-800-778-7879, or by contacting Made. The arbitrator
                has exclusive authority to resolve any dispute relating to the
                interpretation, applicability, or enforceability of this binding
                arbitration agreement.
              </p>
              <p>
                <strong>16.4 Notice of Arbitration; Process</strong>. A party who
                intends to seek arbitration must first send a written notice of
                the dispute to the other party by certified U.S. Mail or by
                Federal Express (signature required) or, only if that other party
                has not provided a current physical address, then by electronic
                mail (“<strong>Notice of Arbitration</strong>”). Made’s address
                for Notice is: Sequentially, dba Made, 619 Western Ave., Suite
                600, Seattle, WA 98105. The Notice of Arbitration must: (a)
                describe the nature and basis of the claim or dispute; and (b) set
                forth the specific relief sought (“
                <strong>Demand</strong>”). The parties will make good faith
                efforts to resolve the claim directly, but if the parties do not
                reach an agreement to do so within 30 days after the Notice of
                Arbitration is received, you or Made may commence an arbitration
                proceeding. During the arbitration, the amount of any settlement
                offer made by you or Made must not be disclosed to the arbitrator
                until after the arbitrator makes a final decision and award, if
                any. If the arbitrator awards you an amount higher than the last
                written settlement amount offered by Made in settlement of the
                dispute prior to the award, Made will pay to you the higher of:
                (i) the amount awarded by the arbitrator; or (ii) $10,000.
              </p>
              <p>
                <strong>16.5 Fees</strong>. If you commence arbitration in
                accordance with these Terms, Made will reimburse you for your
                payment of the filing fee, unless your claim is for more than
                $10,000, in which case the payment of any fees will be decided by
                the AAA Rules. Any arbitration hearing will take place at a
                location to be agreed upon in King County, Washington, but if the
                claim is for $10,000 or less, you may choose whether the
                arbitration will be conducted: (a) solely on the basis of
                documents submitted to the arbitrator; (b) through a
                non-appearance based telephone hearing; or (c) by an in-person
                hearing as established by the AAA Rules in the county (or parish)
                of your billing address. If the arbitrator finds that either the
                substance of your claim or the relief sought in the Demand is
                frivolous or brought for an improper purpose (as measured by the
                standards set forth in Federal Rule of Civil Procedure 11(b)),
                then the payment of all fees will be governed by the AAA Rules. In
                that case, you agree to reimburse Made for all monies previously
                disbursed by it that are otherwise your obligation to pay under
                the AAA Rules. Regardless of the manner in which the arbitration
                is conducted, the arbitrator must issue a reasoned written
                decision sufficient to explain the essential findings and
                conclusions on which the decision and award, if any, are based.
                The arbitrator may make rulings and resolve disputes as to the
                payment and reimbursement of fees or expenses at any time during
                the proceeding and upon request from either party made within 14
                days of the arbitrator’s ruling on the merits.
              </p>
              <p>
                <strong>16.6 No Class Actions</strong>. YOU AND Made AGREE THAT
                EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
                INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both
                you and Made agree otherwise, the arbitrator may not consolidate
                more than one person’s claims, and may not otherwise preside over
                any form of a representative or class proceeding.
              </p>
              <p>
                <strong>16.7 Modifications to this Arbitration Provision</strong>.
                If Made makes any future change to this arbitration provision,
                other than a change to Made’s address for Notice of Arbitration,
                you may reject the change by sending us written notice within 30
                days of the change to Made’s address for Notice of Arbitration, in
                which case your account will be closed for future renewal and the
                original terms shall apply for the remainder of your pre-paid
                term. If you choose to renew the account, the new terms will go
                into effect.
              </p>
              <p>
                <strong>16.8 Enforceability</strong>. If Section 16.6 is found to
                be unenforceable or if the entirety of this Section 16 is found to
                be unenforceable, then the entirety of this Section 16 will be
                null and void and, in that case, the parties agree that the
                exclusive jurisdiction and venue described in Section 17.1 will
                govern any action arising out of or related to these Terms.
              </p>
              <h3>17. General Terms</h3>
              <p>
                These Terms, together with the Privacy Policy and any other
                agreements expressly incorporated by reference into these Terms,
                are the entire and exclusive understanding and agreement between
                you and Made regarding your use of the Service. You may not assign
                or transfer these Terms or your rights under these Terms, in whole
                or in part, by operation of law or otherwise, without our prior
                written consent. We may assign these Terms at any time without
                notice or consent. The failure to require performance of any
                provision will not affect our right to require performance at any
                other time after that, nor will a waiver by us of any breach or
                default of these Terms, or any provision of these Terms, be a
                waiver of any subsequent breach or default or a waiver of the
                provision itself. Use of section headers in these Terms is for
                convenience only and will not have any impact on the
                interpretation of any provision. Throughout these Terms the use of
                the word “including” means “including but not limited to”. If any
                part of these Terms is held to be invalid or unenforceable, the
                unenforceable part will be given effect to the greatest extent
                possible, and the remaining parts will remain in full force and
                effect.
              </p>
              <p>
                <strong>17.1 Governing Law</strong>. These Terms are governed by
                the laws of the State of Washington without regard to conflict of
                law principles. You and Made submit to the personal and exclusive
                jurisdiction of the state courts and federal courts located within
                King County, Washington for resolution of any lawsuit or court
                proceeding permitted under these Terms. We operate the Service
                from our offices in Washington, and we make no representation that
                Materials included in the Service are appropriate or available for
                use in other locations.
              </p>
              <p>
                <strong>17.2 International Use</strong>. [Made handles data in
                compliance with our Privacy
                Policy as described in Section 17.3 below.] We make no other
                representation that the Service is appropriate or available for
                use outside of the United States. Access to the Service from
                countries or territories or by individuals where such access is
                illegal is prohibited.
              </p>
              <p>
                <strong>17.3 Privacy Policy</strong>. Please read the Made{" "}
                <a href="https://www.asitsmade.com/privacy">
                  Privacy Policy
                </a>{" "}
                carefully for information relating to our collection, use,
                storage, disclosure of your personal information. The Made Privacy
                Policy is incorporated by this reference into, and made a part of,
                these Terms.
              </p>
              <p>
                <strong>17.4 Additional Terms</strong>. Your use of the Service is
                subject to all additional Terms, policies, rules, or guidelines
                applicable to the Service or certain features of the Service that
                we may post on or link to from the Service (the “
                <strong>Additional Terms</strong>”). All Additional Terms are
                incorporated by this reference into, and made a part of, these
                Terms.
              </p>
              <p>
                <strong>17.5 Consent to Electronic Communications</strong>. By
                using the Service, you consent to receiving certain electronic
                communications from us as further described in our Privacy Policy.
                Please read our Privacy Policy to learn more about our electronic
                communications practices. You agree that any notices, agreements,
                disclosures, or other communications that we send to you
                electronically will satisfy any legal communication requirements,
                including that those communications be in writing.{" "}
                <strong></strong>
              </p>
              <p>
                <strong>17.6 Contact Information. </strong>The Service is offered
                by Sequentially, dba Made, located at 619 Western Ave., Suite 600,
                Seattle, WA 98104. You may contact us by sending correspondence to
                that address or by emailing us at support@asitsmade.com. You can
                access a copy of these Terms anytime on this website.{" "}
                <a href="https://www.asitsmade.com/terms">
                  www.asitsmade.com/terms
                </a>
                .
              </p>
              <p>
                <strong>17.7 Notice to California Residents.</strong> If you are a
                California resident, under California Civil Code Section 1789.3,
                you may contact the Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer Affairs
                in writing at 1625 N. Market Blvd., Suite S-202, Sacramento,
                California 95834, or by telephone at (800) 952-5210 in order to
                resolve a complaint regarding the Service or to receive further
                information regarding use of the Service.
              </p>
              <p>Last Updated: January 25, 2023</p>

            </CenterColumn>
          </Columns>
        </StyledBackGround>
        <Footer />
      </ContentWrapper>
    );
  }
}
// Register.propTypes = {
//   registerUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired,
// };
const mapStateToProps = (state) => ({
  errors: state.errors,
  isMobile: state.dimensions.window_dimensions.isMobile,
});

export default connect(mapStateToProps)(withRouter(Terms));

