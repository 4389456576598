import Alert from "../types/Alert";
import { CONSTANTS } from "./index.js";


export const showAlert = (data, severity = "info", duration = 5000) => (dispatch) => {
    let payload;
    if (data instanceof Alert) {
        payload = data;
    } else {
        payload = new Alert(data, severity, duration);
    }
    dispatch({
        type: CONSTANTS.ON_ALERT,
        payload: payload
    });
    setTimeout(() => {
        dispatch(setAlertVisibility(payload.id, false));
        setTimeout(() => {
            dispatch(clearAlert(payload.id));
        }, 500);
    }, payload.duration);
}

export const setAlertVisibility = (id, show) => (dispatch) => {
    dispatch({
        type: CONSTANTS.SET_ALERT_VISIBILITY,
        payload: { id, show }
    });
}

export const clearAlert = (id) => (dispatch) => {
    dispatch({
        type: CONSTANTS.CLEAR_ALERT,
        payload: id
    });
}

export const clearAllAlerts = () => (dispatch) => {
    dispatch({
        type: CONSTANTS.CLEAR_ALL_ALERTS
    });
}

export const showError = (data) => (dispatch) => {
    dispatch(showAlert(data, "error"));
}
