import { CONSTANTS } from ".";
import axiosInstance from "../utils/axiosApi";
import { setMenu, updateProgress, timeoutError } from "../actions/uiActions";

// export const getGroups = (status) => (dispatch) => {
//   dispatch({
//     type: CONSTANTS.SET_GROUPS_LOADING,
//     payload: "loading",
//   });
//   let url = "groups/";
//   if (status !== "ALL") {
//     url = url + "?status=" + status;
//   }
//   axiosInstance.get(url).then((res) => {
//     dispatch({
//       type: CONSTANTS.GET_GROUPS,
//       payload: res.data,
//     });
//   });
// };
// export const leaveGroup = (groupid) => (dispatch) => {
//   axiosInstance.post("groups/" + groupid + "/leave/").then((res) => {
//     // navigate to groups.
//     window.location = "/groups/";
//   });
// };
// export const deleteGroup = (groupid) => (dispatch) => {
//   axiosInstance.delete("groups/" + groupid + "/").then((res) => {
//     // navigate to groups.
//     window.location = "/groups/";
//   });
// };
// export const followGroup = (groupid) => (dispatch) => {
//   axiosInstance.post("groups/" + groupid + "/follow/").then((res) => {
//     dispatch({
//       type: CONSTANTS.CHANGE_GROUP_FOLLOW_STATUS,
//       payload: true,
//     });
//   });
// };
// export const unfollowGroup = (groupid) => (dispatch) => {
//   axiosInstance.post("groups/" + groupid + "/unfollow/").then((res) => {
//     dispatch({
//       type: CONSTANTS.CHANGE_GROUP_FOLLOW_STATUS,
//       payload: false,
//     });
//   });
// };
// export const loadMoreGroups = (link) => (dispatch) => {
//   dispatch({
//     type: CONSTANTS.SET_GROUPS_LOADING,
//     payload: "loading",
//   });
//   axiosInstance.get(link).then((res) => {
//     dispatch({
//       type: CONSTANTS.GET_MORE_GROUPS,
//       payload: res.data,
//     });
//   });
// };
export const sendInvite = (data) => (dispatch) => {
  axiosInstance.post("participants/", data).then((res) => {
    dispatch({
      type: CONSTANTS.SET_PENDING_ORG_INVITE,
      payload: { email: res.data.invite_email, id: res.data.id, role: res.data.role, status: res.data.status },
    });
  });
};
// export const removeInvite = (id) => (dispatch) => {
//   axiosInstance.delete("participants/" + id + "/").then((res) => {
//     //console.log(res);
//     dispatch({
//       type: CONSTANTS.REMOVE_PENDING_INVITE,
//       payload: id,
//     });
//   });
// };

// export const getConnections = (data) => (dispatch) => {
//   axiosInstance.get("connections/?search=" + data).then((res) => {
//     dispatch({
//       type: CONSTANTS.GET_SEARCH_RESULTS,
//       payload: res.data.results,
//     });
//   });
// };

export const getOrgData = (id) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_ORG_LOADING,
    payload: "loading",
  });
  axiosInstance.get("organizations/" + id + "/").then((res) => {
    dispatch({
      type: CONSTANTS.GET_ORG_DATA,
      payload: res.data,
    });
    dispatch(getOrgGroups(id));
  });
};
export const getOrgGroups = (id, state) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_ORGGROUPS_LOADING,
    payload: "loading",
  });
  let get_url = "organizations/" + id + "/groups/";
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {

    dispatch({
      type: CONSTANTS.GET_ORGGROUPS_DATA,
      payload: { data: res.data, state: state },
    });
    // dispatch(getOrgParticipants(id, "AC"));
  });
};
export const loadMoreOrgGroups = (link, state) => (dispatch) => {
  let get_url = link;
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_ORGGROUPS,
      payload: res.data,
    });
  });
};
export const getOrgProjects = (id, state) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_ORGPROJECTS_LOADING,
    payload: "loading",
  });
  let get_url = "organizations/" + id + "/projects/";
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {

    dispatch({
      type: CONSTANTS.GET_ORGPROJECTS_DATA,
      payload: { data: res.data, state: state },
    });
    // dispatch(getOrgParticipants(id, "AC"));
  });
};
export const loadMoreOrgProjects = (link, state) => (dispatch) => {
  let get_url = link;
  if (state) {
    get_url = get_url + "?state=" + state;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_ORGPROJECTS,
      payload: res.data,
    });
  });
};
export const getOrgParticipants = (id, status) => (dispatch) => {
  dispatch({
    type: CONSTANTS.SET_ORGPARTICIPANTS,
    payload: "loading",
  });
  let get_url = "organizations/" + id + "/participants/";
  if (status) {
    get_url = get_url + "?status=" + status;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.GET_ORGPARTICIPANTS,
      payload: res.data,
    });
    // dispatch(getPendingInvites(id))
  });
};
export const loadMoreORGParticipants = (link, status) => (dispatch) => {
  let get_url = link;
  if (status) {
    get_url = get_url + "?status=" + status;
  }
  axiosInstance.get(get_url).then((res) => {
    dispatch({
      type: CONSTANTS.LOAD_MORE_ORGPARTICIPANTS,
      payload: res.data,
    });
  });
};
// export const getPendingInvites = (id) => (dispatch) => {
//   axiosInstance.get("groups/" + id + "/participants/?status=IN").then((res) => {
//     dispatch({
//       type: CONSTANTS.GET_INITIAL_PENDING,
//       payload: res.data,
//     });
//   });
// };
// export const upLoadcreateGroup = (imageRaw, data) => (dispatch) => {
//   axiosInstance
//     .get("notifications/")
//     .then((result) => {
//       dispatch({
//         type: CONSTANTS.GET_NOTIFICATIONS,
//         payload: result.data,
//       });

//       dispatch(timeoutError(false));
//   dispatch(setMenu(true, 5));
//   dispatch(updateProgress(0,1));
//   if (imageRaw) {
//     const formData = new FormData();
//     formData.append("upload", imageRaw);
//     formData.append("alt_text", "temp");
//     axiosInstance({
//       method: "post",
//       url: "media/",
//       data: formData,
//       headers: { "Content-Type": "multipart/form-data" },
//       onUploadProgress: progressEvent => {
//         dispatch(updateProgress(progressEvent.loaded,progressEvent.total));
//       }
//     })
//       .then((response) => {
//         //console.log("got it");
//         //console.log(response.data);
//         const imageid = response.data.id;
//         data.image = imageid;
//         dispatch(createGroup(data));
//       })
//       .catch(function (response) {
//         //handle error
//         if (response === "timeout"){
//           dispatch(timeoutError(true));
//         }
//       });
//   }



//     })
//     .catch((err) => {
//       dispatch({
//         type: CONSTANTS.GET_ERRORS,
//         payload: err,
//       });
//     });



// };
// export const createGroup = (data) => (dispatch) => {
//   //   const data = {
//   //     "name": name

//   //  }
//   axiosInstance.post("groups/", data).then((res) => {
//     dispatch(setMenu(false, 5));
//     window.location = "/group/" + res.data.id + "/" + res.data.name;
//     dispatch({
//       type: CONSTANTS.CREATE_NEW_GROUP,
//       payload: res.data,
//     });
//   });
// };
// export const addMember = (id, username, currentmembers, name) => (dispatch) => {
//   axiosInstance
//     .get("users/by-username/" + username + "/")
//     .then((result) => {
//       //console.log(result);
//       dispatch({
//         type: CONSTANTS.NEW_MEMBER,
//         payload: result.data[0],
//       });
//       // add person here to data
//       // const data ={'members': currentmembers, 'name': name}
//       // data.members.push(result.data[0].id)
//       // //console.log(data)
//       // dispatch(saveGroup(id, data))
//     })
//     .catch((err) => {
//       dispatch({
//         type: CONSTANTS.GET_ERRORS,
//         payload: err,
//       });
//     });
// };
async function upload(file, dispatch) {
  let blob = file.slice(0, file.size, file.type);
  let split = file['name'].split('.');
  let filename = split[0];
  var extension = split.pop()
  console.log(split)

  if (filename.length > 90) {
    filename = filename.substring(0, 90);
  }

  let tempname = filename + '.' + extension;
  let newFile = new File([blob], tempname, { type: file.type })
  const formData = new FormData();
  formData.append("upload", newFile);
  formData.append("alt_text", "temp");
  dispatch(updateProgress(0, 1));
  return axiosInstance({
    method: "post",
    url: "media/",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: progressEvent => {
      dispatch(updateProgress(progressEvent.loaded, progressEvent.total));
    }
  });
}
export const uploadSaveOrg = (id, data, bannerRaw, logoRaw, coverRaw) => async (dispatch) => {

  dispatch(timeoutError(false));
  dispatch(setMenu(true, 5));

  if (logoRaw) {
    let logo = await upload(logoRaw, dispatch);
    data.logo = logo.data.id;
  }
  if (bannerRaw) {
    let banner = await upload(bannerRaw, dispatch);
    data.image = banner.data.id;
  }
  if (coverRaw) {
    // change to multiple images here
    let cover = await upload(coverRaw, dispatch);
    data.cover = cover.data.id;
  }
  dispatch(saveOrg(id, data));
};

export const saveOrg = (id, data) => (dispatch) => {
  //console.log(data);

  axiosInstance.put("organizations/" + id + "/", data).then((res) => {
    dispatch(setMenu(false, 5));
    dispatch(getOrgData(id));
  });
};

export const removeMemberOrg = (id, part_id, type) => (dispatch) => {
  axiosInstance
    .delete("/organizationparticipants/" + part_id + "/")
    .then((result) => {
      //console.log(result);
      dispatch({
        type: CONSTANTS.REMOVE_ORG_MEMBER,
        payload: { part_id: part_id, type: type },
      });
    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const changeRoleOrg = (value, part_id, type) => (dispatch) => {
  axiosInstance
    .patch("/organizationparticipants/" + part_id + "/", { role: value })
    .then((result) => {
      //console.log(result);

      dispatch({
        type: CONSTANTS.CHANGE_ORG_ROLE_INVITE,
        payload: { role: value, part_id: part_id, type: type },
      });


    })
    .catch((err) => {
      dispatch({
        type: CONSTANTS.ON_ERROR,
        payload: err,
      });
    });
};
export const sendOrgInvite = (data, groupinvite) => (dispatch) => {
  axiosInstance.post("organizationparticipants/", data).then((res) => {

    dispatch({
      type: CONSTANTS.SET_PENDING_ORG_INVITE,
      payload: { email: res.data.invite_email, id: res.data.id, role: res.data.role, status: res.data.status },
    });


  });
};
export const getAllOrgParticipants = (id, type) => (dispatch) => {
  axiosInstance.get("organizations/" + id + "/participants/?status=" + type).then((res) => {
    res.data.sub_type = type;
    dispatch({
      type: CONSTANTS.GET_ALL_ORG_PARTICIPANTS,
      payload: res.data,
    });
  });
};
export const loadMoreOrgParticipants = (link, type) => (dispatch) => {
  axiosInstance.get(link).then((res) => {
    res.data.sub_type = type
    dispatch({
      type: CONSTANTS.LOAD_MORE_ORG_PARTICIPANTS,
      payload: res.data,
    });
  });
};