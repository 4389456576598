import { createTheme,  } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

const theme = {
    components: {
        // MuiFormControlLabel: {
        //   styleOverrides: {
        //     label: {
        //       fontSize: 14,
        //     },
        //   }
        // },
        MuiInputLabel: {
            defaultProps: {
                // sx: {
                //     fontSize: "16px",
                //     top: 2,
                // },
            },
            styleOverrides: {
                shrink: ({ ownerState, theme }) => ({
                    ...(ownerState.shrink && {
                        fontSize: "1rem !important",
                        top: "-0 !important",
                    }),
                }),
            },
        },
        MuiInputBase: {
            defaultProps: {
                // sx: {
                   
                  
                //     borderRadius: "6px"
                // },
            },
            styleOverrides: {
                shrink: ({ ownerState, theme }) => ({
                    ...(ownerState.shrink && {
                        fontSize: "1rem !important",
                        top: "-0 !important",
                    }),
                }),
            },
        },
        MuiTextField: {
            defaultProps: {
                // sx: {
                //     fontSize: "2px",
                //     top: 2,
                //     borderRadius: "6px"
                // },
            },
            styleOverrides: {
                // root: sx({
                //     "& .MuiOutlinedInput-root": {
                //         "& > fieldset": {
                //             borderRadius: "6px",
                //             // background: "#fff",
                //         },
                //     },
                // }),
            },
        },
        MuiButton: {
            // defaultProps: {
            //     sx: {
            //         fontSize: "26px",
            //         top: 2,
            //     },
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: '16px',
                    // paddingTop: '7px',
                    // backgroundColor: '#008EFF',
                },
            },
            variants: [
                {
                    props: { variant: 'toolbarbutton' },
                    style: {
                        textTransform: 'none',
                        textDecoration: 'none',
                        fontSize: '14px',
                    },
                },
                {
                    props: { variant: 'bottombutton' },
                    style: {
                        borderRadius: '16px',
                        height: '32px',
                        fontSize: '14px',
                        color: '#fff',
                        backgroundColor: '#2196F3',
                    },
                },
            ],
        },
    },
    typography: {
        input: {
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            lineHeight: "20px",
            fontWeight: "400",
            // textTransform: "none",
        },

        button: {
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            lineHeight: "20px",
            fontWeight: "400",
            // textTransform: "none",
        },
        TextField: {
            fontSize: "14px",
            fontFamily: "Roboto, sans-serif",
            lineHeight: "20px",
            fontWeight: "400",
            textTransform: "none",
        },
        h3: {
            fontFamily: 'Roboto',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "120%",
        },
    },



    palette: {
        primary: {
            light: "#6ea8ec",
            main: "#008EFF",
            dark: "#6ea8ec",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ccc",
            main: "#dd4d25",
            dark: "#e16946",
            contrastText: "#fff",
        },
        black: {
            light: "#ccc",
            main: "#394b54",
            dark: "#757575",
            contrastText: "#fff",
        },
        background: {
            light: "#ccc",
            main: "#F6F6F6",
            dark: "#757575",
            contrastText: "#fff",
        },
        charcoal: {
            light: "#ccc",
            main: "#394b54",
            dark: "#757575",
            contrastText: "#fff",
        },
        notify: {
            light: "#42f551",
            main: "#42f551",
            dark: "#42f551",
            contrastText: "#42f551",
        },
        opaque: {
            light: "#eee",
            main: "#fff",
            dark: "#ccc",
            contrastText: "#000"
        },
        white: {
            light: "#eee",
            main: "#fff",
            dark: "#ccc",
            contrastText: "#008EFF"
        }
    },
}

export default theme;