import jwt_decode from "jwt-decode";
import axiosInstance from "./axiosApi";

export function tokenIsValid(resolve, reject, loop = 0) {
    // console.warn("check tokenIsValid loop", loop)

    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds

    // Decode token and get user info and exp
    const rtoken = localStorage.getItem("refresh_token");
    const atoken = localStorage.getItem("access_token");

    if (!rtoken || !atoken) {
        const err = new Error("Refresh token expired.")
        if (reject) { reject(err) }
        return false
    }

    let refresh, access;

    try {
        refresh = jwt_decode(rtoken);
        access = jwt_decode(atoken);
    } catch (err) {
        if (reject) { reject(err) }
        return false
    }


    if (refresh.exp < currentTime) {
        const err = new Error("Refresh token expired.")
        if (reject) { reject(err) }
        return false
    }

    // Decode token and get user info and exp

    if (access.exp > currentTime) {
        // if (resolve || reject) {
        //     axiosInstance.get("/token/test/").then(resp => {
        //         if (!resp.ok) {
        //             reject(new Error("Token is invalid."))
        //             return false
        //         }
        //         resolve(true)
        //         return true
        //     }).catch(err => {
        //         reject(err)
        //         throw err
        //     })
        // }
        if (resolve) { resolve(true) }
        return true
    }
    if (resolve || reject) {
        setTimeout(tokenIsValid, 250, resolve, reject, loop + 1)
    }
    return false
}

export function waitForAuth() {
    return new Promise(tokenIsValid)
}