import { CONSTANTS } from "../actions";
import { api_endpoint } from "../config";
const initialState = {
  groups_loading: true,
  group_loading: true,
  groups: { byID: {}, allIDS: [] },
  pending_invites: { byID: {}, allIDS: [] },
  pending_requests: { byID: {}, allIDS: [] },
  manage_participants: {
    AC:{
    results: [],
    next: null,
    previous: null,
    count: 0,
    loading: true
    },
    IN:{
      results: [],
      next: null,
      previous: null,
      count: 0,
      loading: true
      },
      RE:{
        results: [],
        next: null,
        previous: null,
        count: 0,
        loading: true
        }
  },
  search_results: [],
  current_group: { projects_loading: true, projects: { byID: {}, allIDS: [], no_results: 0 }, allmembers: { byID: {}, allIDS: [], loading: true } },
  projects_for_groups: {loading: true, projects:{results:[]}},
  people: { following: { byID: {}, allIDS: [], loading: true }, followers: { byID: {}, allIDS: [], loading: true }, shared_projects: { byID: {}, allIDS: [], loading: true } },
  error: null,
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.SET_PROJECTS_FOR_GROUPS_LOADING: {
      let new_current = { ...state.projects_for_groups };
      new_current.loading = true;

      return {
        ...state,
        projects_for_groups: new_current,
      };
    }
    case CONSTANTS.GET_PROJECTS_FOR_GROUPS: {
      let new_current = { ...state.projects_for_groups };
      new_current.projects = action.payload.data;
      new_current.loading = false;
      return {
        ...state,
        projects_for_groups: new_current,
      };
    }
    case CONSTANTS.LOAD_MORE_PROJECTS_FOR_GROUPS: {
      let new_current = { ...state.projects_for_groups };
      new_current.projects.count = action.payload.data.count;
      new_current.projects.next = action.payload.data.next;
      new_current.projects.previous = action.payload.data.previous;
      new_current.projects.results.push(...action.payload.data.results)
      new_current.loading = false;
      return {
        ...state,
        projects_for_groups: new_current,
      };
    }
    case CONSTANTS.SET_FOLLOWERS_LOADING: {
      let new_people = { ...state.people };
      new_people.followers.loading = true;

      return {
        ...state,
        people: new_people,
      };
    }

    case CONSTANTS.GET_ALL_PARTICIPANTS: {
     
      const prop = action.payload.sub_type
      let new_participants = {...state.manage_participants}
      new_participants[prop] = {...action.payload, loading: false,}
      
        return {
          ...state,
          manage_participants: new_participants
        }
     
    }
    case CONSTANTS.LOAD_MORE_PARTICIPANTS: {
     
      const prop = action.payload.sub_type
      console.log(prop)
      let new_participants = {...state.manage_participants}
      let prev_subs = state.manage_participants[prop].results;
      let new_subs = action.payload
      new_subs.results = prev_subs.concat(action.payload.results)
      new_participants[prop] = {...new_subs, loading: false,}
      
        return {
          ...state,
          manage_participants: new_participants
        }
     
    }
    
    case CONSTANTS.GET_FOLLOWERS: {
      let new_people = { ...state.people };

      let new_followers = { byID: {}, allIDS: [] };
      const Followers = action.payload.results;
      Followers.forEach((person) => {
        new_followers.allIDS.push(person.id);
        new_followers.byID[person.id] = person;
      });
      new_followers.count = action.payload.count;
      new_followers.previous = action.payload.previous;
      new_followers.next = action.payload.next;
      new_followers.loading = false;
      new_people.followers = new_followers
      return {
        ...state,
        people: new_people,
      };
    }
    case CONSTANTS.SET_SHARED_LOADING: {
      let new_people = { ...state.people };
      new_people.shared_projects.loading = true;

      return {
        ...state,
        people: new_people,
      };
    }
    case CONSTANTS.GET_SHARED: {
      let new_people = { ...state.people };

      let new_shared_projects = { byID: {}, allIDS: [] };
      const Projects = action.payload.results;
      Projects.forEach((person) => {
        new_shared_projects.allIDS.push(person.id);
        new_shared_projects.byID[person.id] = person;
      });
      new_shared_projects.count = action.payload.count;
      new_shared_projects.previous = action.payload.previous;
      new_shared_projects.next = action.payload.next;
      new_shared_projects.loading = false;
      new_people.shared_projects = new_shared_projects
      return {
        ...state,
        people: new_people,
      };
    }
    case CONSTANTS.SET_FOLLOWING_LOADING: {
      let new_people = { ...state.people };
      new_people.following.loading = true;

      return {
        ...state,
        people: new_people,
      };
    }
    case CONSTANTS.GET_FOLLOWING: {
      let new_people = { ...state.people };

      let new_following = { byID: {}, allIDS: [] };
      const Following = action.payload.results;
      Following.forEach((person) => {
        new_following.allIDS.push(person.id);
        new_following.byID[person.id] = person;
      });
      new_following.count = action.payload.count;
      new_following.previous = action.payload.previous;
      new_following.next = action.payload.next;
      new_following.loading = false;
      new_people.following = new_following
      return {
        ...state,
        people: new_people,
      };
    }
    case CONSTANTS.CHANGE_GROUP_REQUEST_STATUS: {
      let new_current = { ...state.current_group };
      new_current.viewer_status = "RE";

      return {
        ...state,
        current_group: new_current,
      };
    }
    case CONSTANTS.UPDATE_JOIN_LINK: {
      let new_current = { ...state.current_group };
      new_current.join_link = action.payload.join_link;

      return {
        ...state,
        current_group: new_current,
      };
    }
    case CONSTANTS.CHANGE_GROUP_FOLLOW_STATUS: {
      let new_current = { ...state.current_group };
      new_current.viewer_is_subscribed = action.payload;

      return {
        ...state,
        current_group: new_current,
      };
    }
    case CONSTANTS.NEW_MEMBER: {
      //console.log("new", action.payload);
      let new_current_group = { ...state.current_group };

      new_current_group.members.push(action.payload);
      new_current_group.allmembers = [];
      new_current_group.members.forEach((member) => {
        member.status = "member";
        new_current_group.allmembers.push(member);
      });
      new_current_group.admins.forEach((admin) => {
        admin.status = "admin";
        new_current_group.allmembers.push(admin);
      });
      new_current_group.invited.forEach((invited) => {
        invited.status = "invited";
        new_current_group.allmembers.push(invited);
      });
      new_current_group.allmembers.sort((a, b) =>
        a.username > b.username ? 1 : -1
      );
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.REMOVE_MEMBER: {
      let new_participants = {...state.manage_participants}
      let prev_subs = state.manage_participants[action.payload.type];
      const new_total = state.current_group.total_members-1
      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload.part_id){
          prev_subs.results.splice(index, 1);
          return;
        }
      })
      new_participants[action.payload.type] = {...prev_subs, loading: false,}
      
        return {
          ...state,
          current_group: {...state.current_group,total_members: new_total},
          manage_participants: new_participants
        }
    }
    case CONSTANTS.CHANGE_ROLE_INVITE: {
      // console.log("change invite", action.payload);
      let newinvites = [...state.pending_invites];
      newinvites.forEach((invitee, index) => {
        if (action.payload.id === invitee.id) {
          invitee.role = action.payload.role
        }
      });
      return { ...state, pending_invites: newinvites };
    }
    case CONSTANTS.CHANGE_ROLE: {
      let new_participants = {...state.manage_participants}
      let prev_subs = state.manage_participants[action.payload.type];
    
      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload.part_id){
          result.role = action.payload.role
          return;
        }
      })
      new_participants[action.payload.type] = {...prev_subs, loading: false,}
      
        return {
          ...state,
          manage_participants: new_participants
        }
    }
    case CONSTANTS.SET_GROUPS_LOADING: {
      return { ...state, groups_loading: true };
    }
    case CONSTANTS.SET_GROUP_LOADING: {
      return { ...state, group_loading: true };
    }
    case CONSTANTS.SET_PENDING_INVITE: {

      let newinvites = { ...state.pending_invites };
      newinvites.byID[action.payload.id] = { email: action.payload.email, id: action.payload.id, role: action.payload.role, status: action.payload.status };
      newinvites.allIDS.push(action.payload.id)
      return { ...state, pending_invites: newinvites };
    }
    case CONSTANTS.GET_INITIAL_PENDING: {
      let invites = { byID: {}, allIDS: [] };
      if (action.payload.results.length) {
        const People = action.payload.results;
        People.forEach((person) => {
          invites.allIDS.push(person.user.id);
          invites.byID[person.user.id] = person.user;
          invites.byID[person.user.id].role = person.role;
          invites.byID[person.user.id].part_id = person.id;
          invites.byID[person.user.id].status = person.status;
        });
      }
      return { ...state, pending_invites: invites };
    }
    case CONSTANTS.GET_INITIAL_REQUESTED: {
      let requests = { byID: {}, allIDS: [] };
      console.log("reqest")
      const People = action.payload.results;
      People.forEach((person) => {
        requests.allIDS.push(person.user.id);
        requests.byID[person.user.id] = person.user;
        requests.byID[person.user.id].role = person.role;
        requests.byID[person.user.id].part_id = person.id;
        requests.byID[person.user.id].status = person.status;
      });


      return { ...state, pending_requests: requests };
    }
    case CONSTANTS.REMOVE_PENDING_INVITE: {
      console.log("invite remove", action.payload)
      let newinvites = { ...state.pending_invites };
      console.log(newinvites)
      newinvites.allIDS.forEach((invitee, index) => {
        console.log(invitee)
        if (action.payload === invitee) {
          console.log("foind")
          newinvites.allIDS.splice(index, 1);
        }
      });




      return { ...state, pending_invites: newinvites };
    }
    case CONSTANTS.REMOVE_REQUEST: {
      let new_participants = {...state.manage_participants}
      let prev_subs = state.manage_participants["RE"];
    
      const new_total_r = state.current_group.total_requests-1
      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload){
          prev_subs.results.splice(index, 1);
          return;
        }
      })
      new_participants["RE"] = {...prev_subs, loading: false,}
      
        return {
          ...state,
          current_group: {...state.current_group, total_requests: new_total_r},
          manage_participants: new_participants
        }
    }
    case CONSTANTS.ACCEPT_REQUEST: {
     
      let new_participants = {...state.manage_participants}
      let prev_subs = state.manage_participants["RE"];
      const new_total = state.current_group.total_members+1
      const new_total_r = state.current_group.total_requests-1
     
      prev_subs.results.forEach((result, index) => {
        if (result.id === action.payload){
          prev_subs.results.splice(index, 1);
          return;
        }
      })
      new_participants["RE"] = {...prev_subs, loading: false,}
      
        return {
          ...state,
          current_group: {...state.current_group,total_members: new_total, total_requests: new_total_r},
          manage_participants: new_participants
        }
    }
    case CONSTANTS.GET_SEARCH_RESULTS: {
      let search_results = [];

      const SR = action.payload;
      SR.forEach((result) => {

        let img = null
        if (result.avatar) {
          img = api_endpoint + "/media/" + result.avatar.id + "/file?size=256"
        }
        const result_string = result.email + ',' + result.username + "," + result.fullname
        search_results.push({ 'id': result.id, 'data': result_string, 'img': img });

      });
      return { ...state, search_results: [...search_results] };
    }
    case CONSTANTS.GET_GROUPS: {
      let new_groups = { byID: {}, allIDS: [] };
      const Groups = action.payload.results;
      Groups.forEach((group) => {
        new_groups.allIDS.push(group.id);
        new_groups.byID[group.id] = group;
      });
      new_groups.count = action.payload.count;
      new_groups.previous = action.payload.previous;
      new_groups.next = action.payload.next;
      new_groups.total_active = action.payload.total_active;
      new_groups.total_invited = action.payload.total_invited;
      new_groups.total_requested = action.payload.total_requested;
      new_groups.total_planned = action.payload.total_planned;
      new_groups.total_completed = action.payload.total_completed;

      return { ...state, groups: new_groups, groups_loading: false };
    }
    case CONSTANTS.GET_MORE_GROUPS: {
      let new_groups = { ...state.groups };
      const Groups = action.payload.results;
      Groups.forEach((group) => {
        new_groups.allIDS.push(group.id);
        new_groups.byID[group.id] = group;
      });
      new_groups.count = action.payload.count;
      new_groups.previous = action.payload.previous;
      new_groups.next = action.payload.next;
      new_groups.total_active = action.payload.total_active;
      new_groups.total_invited = action.payload.total_invited;
      new_groups.total_requested = action.payload.total_requested;
      new_groups.total_planned = action.payload.total_planned;
      new_groups.total_completed = action.payload.total_completed;

      return { ...state, groups: new_groups, groups_loading: false };
    }
    case CONSTANTS.GET_GROUP_DATA: {
      let new_current_group = Object.assign(
        {},
        state.current_group,
        action.payload
      );
      new_current_group.allmembers = {
        byID: {},
        allIDS: [],
        loading: true
      };
      // new_current_group.members.forEach(member => {
      //   member.status="member"
      //   new_current_group.allmembers.push(member)
      // })
      // new_current_group.admins.forEach(admin => {
      //   admin.status="admin"
      //   new_current_group.allmembers.push(admin)
      // })
      // new_current_group.invited.forEach(invited => {
      //   invited.status="invited"
      //   new_current_group.allmembers.push(invited)
      // })
      // new_current_group.allmembers.sort((a, b) => (a.status > b.status) ? 1 : -1)

      // //console.log(new_current_group)

      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.SET_GROUPPARTICIPANTS: {
      //console.log("group participants");
      let new_current_group = { ...state.current_group };

      let new_participants = {
        byID: {},
        allIDS: [],
        loading: true
      };

      new_current_group.allmembers = new_participants;

      return {
        ...state,
        current_group: new_current_group,
      };
    }
    case CONSTANTS.GET_GROUPPARTICIPANTS: {
      //console.log("group participants");
      let new_current_group = { ...state.current_group };

      let new_participants = {
        byID: {},
        allIDS: [],
        count: action.payload.count,
        previous: action.payload.previous,
        next: action.payload.next,
        loading: false
      };
      const People = action.payload.results;
      People.forEach((person) => {
        new_participants.allIDS.push(person.id);
        if (person.user){
          new_participants.byID[person.id] = person.user;
        }else{
          new_participants.byID[person.id] = person;
          new_participants.byID[person.id].username = person.invite_email;
        }
        
        new_participants.byID[person.id].role = person.role;
        new_participants.byID[person.id].part_id = person.id;
        new_participants.byID[person.id].status = person.status;
      });

      new_current_group.allmembers = new_participants;

      return {
        ...state,
        current_group: new_current_group,
        group_loading: false,
      };
    }
    case CONSTANTS.SET_GROUPPROJECTS_LOADING: {
      let new_current_group = { ...state.current_group };
      //console.log(new_current_group);
      new_current_group.projects_loading = true;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.GET_GROUPPROJECTS_DATA: {
      //console.log("group projects");
      let new_current_group = { ...state.current_group };

      let new_projects = {
        byID: {},
        allIDS: [],
        count: action.payload.data.count,
        previous: action.payload.data.previous,
        next: action.payload.data.next,
        no_results: 0,
      };
      const Projects = action.payload.data.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      //console.log(action.payload.state);
      if (action.payload.data.results.length === 0) {
        new_projects.no_results = 2;
      }
      if (
        action.payload.data.results.length === 0 &&
        (action.payload.state === undefined || action.payload.state === false)
      ) {
        new_projects.no_results = 1;
      }
      new_current_group.projects = new_projects;
      new_current_group.projects_loading = false;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.LOAD_MORE_GROUPPROJECTS: {
      //console.log("group projects");
      let new_current_group = { ...state.current_group };

      let new_projects = { ...new_current_group.projects };
      const Projects = action.payload.results;
      Projects.forEach((project) => {
        new_projects.allIDS.push(project.id);
        new_projects.byID[project.id] = project;
      });
      new_projects.count = action.payload.count;
      new_projects.previous = action.payload.previous;
      new_projects.next = action.payload.next;
      new_current_group.projects = new_projects;
      new_current_group.projects_loading = false;
      return { ...state, current_group: new_current_group };
    }
    case CONSTANTS.CREATE_NEW_GROUP: {
      let newgroups = { ...state.groups };
      newgroups.allIDS.push(action.payload.id);
      newgroups.byID[action.payload.id] = action.payload;
      return { ...state, groups: newgroups };
    }
    case CONSTANTS.GET_GROUPS_ERROR: {
      return {
        ...initialState,
        groups_loading: false,
        error: action.payload,
      }
    }
    default:
      return state;
  }
}
